import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import { Button, Paper, InputBase, IconButton, Fade, Typography, Popper, AppBar, Tabs, Tab, InputLabel, MenuItem, FormControl, Select, Switch, Popover } from '@material-ui/core';
import translations from "./json/translations.json";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ClearAll from '@material-ui/icons/ClearAll';
import SelectAll from '@material-ui/icons/SelectAll';
import AddBox from '@material-ui/icons/AddBox';




class LetterPicker2D extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            open: false
        }

        this.handleSelChange = this.handleSelChange.bind(this);
        this.handlePopoverClick = this.handlePopoverClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleLetterButtonClicked = this.handleLetterButtonClicked.bind(this);
//        this.props.initialize({
//          languages: [
//            { name: "עברית", code: "he" },
//            { name: "English", code: "en" }
//          ],
//          translation: translations,
//          options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: "en" }
//        });
//
//        this.props.addTranslation(translations);
//        this.setLanguage = (code) => {props.setActiveLanguage(code)};
    }

     handleSelChange(event) {
         this.props.extSetValues(event.target.value)
     }

      handleLetterButtonClicked(letterId){
        //todo - fix this for letters
         var oldValues = this.props.pickerModel.values

         var newValues = oldValues
         if(letterId === 'selectAll'){
             newValues = []
             for(let i = 0; i < this.props.pickerModel.list.length; i++){
                 newValues.push(this.props.pickerModel.list[i].id);
              }
         } else if (letterId === 'clearAll'){
             newValues = []
         } else if (['sofiot','allKaf','allMem','allNun','allPeh','allTzadi'].includes(letterId)) {
                var newGroupValues = [];

                if (letterId === 'sofiot'){
                    newGroupValues = ['ך','ם','ן','ף','ץ']
                } else if (letterId === 'allKaf'){
                    newGroupValues = ['כ','ך']
                } else if (letterId === 'allMem'){
                    newGroupValues = ['מ','ם']
                } else if (letterId === 'allNun'){
                    newGroupValues = ['נ','ן']
                } else if (letterId === 'allPeh'){
                    newGroupValues = ['פ','ף']
                } else if (letterId === 'allTzadi'){
                    newGroupValues = ['צ','ץ']
                }

                for(let i = 0; i < newGroupValues.length; i++){
                   if(!oldValues.includes(newGroupValues[i])){
                       newValues.push(newGroupValues[i]);
                   }
                }
         } else {
             //remove selected value from newValues
             if (oldValues.includes(letterId)){
                 var letterIndex = oldValues.indexOf(letterId)
                 newValues = oldValues.slice(0,letterIndex).concat(oldValues.slice(letterIndex+1, oldValues.length))
             //add selected value to newValues
             } else {
                 newValues.push(letterId)
             }
         }
         this.props.extSetValues(newValues)
      }

     handlePopoverClick(event) {
       this.setState({anchorEl: event.currentTarget, open: true});
     }

     handleClose() {
       this.setState({anchorEl: null, open: false});
     }

     render() {
        const list = this.props.pickerModel.list;

        const selectStyle = this.props.selectStyle != null ? this.props.selectStyle : "";
        const icon = this.props.icon != null ? this.props.icon : null;
        const displayMultiple = this.props.displayMultiple;
        const rowHeight = {height: '40px'}; //style Wingdings 2 : tuvwxyz{|~ to rep 0 - 10
        var i = 0;

        const anchorEl = this.state.anchorEl;
        const id = this.state.open ? 'simple-popover' : undefined;

        const pickerModelValues = this.props.pickerModel.values;

        const fakeThis = this //fixed problem with 'this' not getting defined

        const select = this.props.translate("select");
        const selectAll = this.props.translate("selectAll");
        const removeAll = this.props.translate("removeAll");
        const selectPad = this.props.translate("selectPad.IncludeExclude");
        var letterList = []
        for(let i = 0; i < this.props.pickerModel.list.length; i++){
            letterList.push(this.props.pickerModel.list[i].id)
        }
        var allSofiotSelect = ''
        var allKafSelect = ''
        var allMemSelect = ''
        var allNunSelect = ''
        var allPehSelect = ''
        var allTzadiSelect = ''

        if (!letterList.includes("ך") || !letterList.includes("ם") || !letterList.includes("ן") || !letterList.includes("ף") || !letterList.includes("ץ")) {
            allSofiotSelect = <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0', minWidth: '143px'}}>

              </TableCell>
        } else {
            allSofiotSelect = <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                <MenuItem className="menuItem menuItemSpecial" style={{height: '30px'}} value={'sofiot'} selected={false} onClick={() => fakeThis.handleLetterButtonClicked('sofiot')}>
                  <span className="lntPickerSelectIcon"><AddBox style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">סופיות</span>&nbsp;<span className="lntPickerSelectIcon"></span>
                </MenuItem>
            </TableCell>
        }
        if (!letterList.includes("כ") || !letterList.includes("ך")) {
            allKafSelect = <></>
        } else {
            allKafSelect = <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                <MenuItem className="menuItem menuItemSpecial" style={{height: '30px', minWidth: '68px'}} value={'allKaf'} selected={false} onClick={() => fakeThis.handleLetterButtonClicked('allKaf')}>
                  <span className="lntPickerSelectIcon"><AddBox style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">כ/ך</span>
                </MenuItem>
            </TableCell>
        }
        if (!letterList.includes("מ") || !letterList.includes("ם")) {
            allMemSelect = <></>
        } else {
            allMemSelect = <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                 <MenuItem className="menuItem menuItemSpecial" style={{height: '30px', minWidth: '68px'}} value={'allMem'} selected={false} onClick={() => fakeThis.handleLetterButtonClicked('allMem')}>
                   <span className="lntPickerSelectIcon"><AddBox style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">מ/ם</span>
                 </MenuItem>
             </TableCell>
        }
        if (!letterList.includes("נ") || !letterList.includes("ן")) {
            allNunSelect = <></>
        } else {
            allNunSelect = <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                 <MenuItem className="menuItem menuItemSpecial" style={{height: '30px', minWidth: '63px'}} value={'allNun'} selected={false} onClick={() => fakeThis.handleLetterButtonClicked('allNun')}>
                   <span className="lntPickerSelectIcon"><AddBox style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">נ/ן</span>
                 </MenuItem>
             </TableCell>
        }
        if (!letterList.includes("פ") || !letterList.includes("ף")) {
            allPehSelect = <></>
        } else {
            allPehSelect = <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                 <MenuItem className="menuItem menuItemSpecial" style={{height: '30px', minWidth: '67px'}} value={'allPeh'} selected={false} onClick={() => fakeThis.handleLetterButtonClicked('allPeh')}>
                   <span className="lntPickerSelectIcon"><AddBox style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">פ/ף</span>
                 </MenuItem>
             </TableCell>
        }
        if (!letterList.includes("צ") || !letterList.includes("ץ")) {
            allTzadiSelect = <></>
        } else {
            allTzadiSelect = <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                   <MenuItem className="menuItem menuItemSpecial" style={{height: '30px', minWidth: '67px'}} value={'allTzadi'} selected={false} onClick={() => fakeThis.handleLetterButtonClicked('allTzadi')}>
                     <span className="lntPickerSelectIcon"><AddBox style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">צ/ץ</span>
                   </MenuItem>
               </TableCell>
        }











        return <span className="placementGridLine" style={rowHeight} style={{backgroundColor: '#d3d3d3'}}>
            <Button aria-describedby={id} variant="contained" onClick={this.handlePopoverClick} width='44px' className={fakeThis.props.extClassName} elevation={1}>
                        <ArrowDropDown style={{color: '#757575'}}/> <Typography variant="h6" style={{fontSize: '25px', fontWeight: 'bold', lineHeight: 1}}>{this.props.pickerModel.values}</Typography >
              </Button>

              <Popover
                id={id}
                open={this.state.open}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                elevation={1}
              >
                <Typography style={{direction: 'rtl', padding: '10px'}}>

                    <Table size="small">
                      <TableHead>
                        <TableRow style={{height: '30px'}}>
                          <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                                <MenuItem className="menuItem menuItemSpecial" style={{height: '30px', minWidth: '100px'}} value={'selectAll'} selected={false} onClick={() => fakeThis.handleLetterButtonClicked('selectAll')}>
                                <span className="lntPickerSelectIcon"><SelectAll style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">בחר הכל</span>
                                </MenuItem>
                            </TableCell>
                          <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                              <MenuItem className="menuItem menuItemSpecial" style={{height: '30px', minWidth: '100px'}} value={'clearAll'} selected={false} onClick={() => fakeThis.handleLetterButtonClicked('clearAll')}>
                              <span className="lntPickerSelectIcon"><ClearAll style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">נקה הכל</span>
                              </MenuItem>
                          </TableCell>
                          <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                                <Typography> </Typography>
                          </TableCell>
                            {allSofiotSelect}
                        </TableRow>
                      </TableHead>
                    </Table>
                    <Table size="small" style={{minWidth: '200px'}}>
                      <TableHead>
                        <TableRow style={{height: '30px'}}>
                            {allKafSelect}
                            {allMemSelect}
                            {allNunSelect}
                            {allPehSelect}
                            {allTzadiSelect}
                        </TableRow>
                      </TableHead>
                    </Table>
                    <Table size="small">
                      <TableBody style={{padding: '0px'}}>

                      <TableRow style={rowHeight}>
                              {list.slice(0,9).map(function(e){
                                  i = e.id + 1;;
                                  const letterStyle = {fontSize: '25px', fontWeight: 'bold', lineHeight: 1, height: '40px', maxWidth: '40px' };
                                  const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                  return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', maxWidth: '40px', margin: '0px'}}>
                                  <MenuItem className="menuItem" style={letterStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleLetterButtonClicked(e.id)}>
                                        <Typography variant="h5" style={{fontWeight:'bold', textAlign: 'center', fontSize: '28px'}} >&nbsp;{e.name}</Typography>
                                  </MenuItem>
                                  </TableCell>
                                  })}
                        </TableRow>
                          <TableRow style={rowHeight}>
                                  {list.slice(9,18).map(function(e){
                                      i = e.id + 1;;
                                      const nekudaStyle = {height: '40px'};
                                      const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                      return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                                          <MenuItem className="menuItem" style={nekudaStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleLetterButtonClicked(e.id)}>
                                             <Typography variant="h5" style={{fontWeight:'bold', textAlign: 'center', fontSize: '28px'}} >&nbsp;{e.name}</Typography>
                                          </MenuItem>
                                      </TableCell>
                                      })}
                            </TableRow>
                            <TableRow style={rowHeight}>
                                  {list.slice(18,27).map(function(e){
                                      i = e.id + 1;;
                                      const nekudaStyle = {height: '40px'};
                                      const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                      return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                                          <MenuItem className="menuItem" style={nekudaStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleLetterButtonClicked(e.id)}>
                                               <Typography variant="h5" style={{fontWeight:'bold', textAlign: 'center', fontSize: '28px'}} >&nbsp;{e.name}</Typography>
                                          </MenuItem>
                                      </TableCell>
                                      })}
                            </TableRow>
                      </TableBody>
                    </Table>
                </Typography>
              </Popover>
          </span>;
       }
}

export default withLocalize(LetterPicker2D);
