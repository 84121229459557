import React, { Component } from 'react';

class TermsOfService extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>
        <span style={{fontSize: 14}}>
          אתה לוקח אחריות מלאה על כל התוכן שנטען או מועבר, נוצר או זמין באמצעות השימוש שלך בתוכנה זו.
          תוכנה זו מורכבת מטבעה ולכן עשויה להיות חפה משגיאות.
          זה מסופק לך "כמו שהוא" ועם כל התקלות.
          השימוש שלך בתוכנה זו ובתכנים הנגישים באמצעות תוכנה זו, הוא על אחריותך בלבד, כולל אי דיוקים או שגיאות בתוכן.
        </span>
        
      </>
    } else if (langCode === "en") {
      retVal = <>
        <span style={{fontSize: 14}}>You assume full responsibility for all content loaded or transferred to, generated or made available through your use of this software.
          This software is inherently complex and may therefore not be free of errors. 
          It is provided to you "as is" and with all faults. 
          Your use of this software and the content accessible through this software, are entirely at your own risk, including inaccuracies or errors in the content.</span>
      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default TermsOfService;