import React, { Component } from 'react';

class FrequencyViewer_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שכיחות</span></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מטרה</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאפשר
            למשתמש לבחון
            רשימות של
            מילים או מונחים
            לפי מספר
            הפעמים שהם
            מופיעים
            בתנ"ך או בהיקף
            ספציפי של
            ספרים. זה גם
            מציע פרטים
            נוספים במקרים
            המעניינים
            שבהם יש רק
            התרחשות אחת
            או שתיים.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אפשרויות
                והגדרות</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ההגדרות
            הסטנדרטיות
            שתוארו קודם
            ישפיעו על מיקוד
            החיפוש
            מבחינת סוג
            המונח
            (אותיות, נקודות,
            טעמים, שורש,
            גמטריא, אתבש)
            וגם מבחינת
            היקף ספרי
            החיפוש.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בנוסף,
            למסך זה תהיה
            הגדרה נוספת
            ליד הכפתור
            "עדכן" שתקבע
            אם להציג מידע
            על מונחים המופיעים:
            <b>א)</b> פעם אחת </span><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ב</span></b><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>)</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
            פעמיים (<i>בחירת
              ברירת המחדל</i>) <b>ג)</b>
            שלוש פעמים או
            יותר.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={144} height={37} id="Picture 53" src="helpDialogImages/image048.png" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מונחים
                המתרחשים פעם
                אחת בלבד</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            בחירת אפשרות
            זו ולחיצה על הכפתור
            "עדכן" (בהנחה
            של חיפוש
            אותיות בלבד
            בכל ספרי
            התנ"ך), יופיע
            הבא:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={622} height={226} id="Picture 54" src="helpDialogImages/image049.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פרטי
                העמודה</span></u></b></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="RTL" /><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />#</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - מספר
            אינדקס
            המציין את
            מיקום המונח
            בסדר המיון
            הראשוני של
            הרשימה (סדר
            המראה בתנ"ך)</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בטוי</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            המונח שמופיע
            רק פעם אחת.
            עבור מילים
            גולמיות, זה
            יכול לכלול רק
            אותיות או
            אפילו נקודות
            וטעמים; עבור
            שורש, זו תהיה
            מילת השורש;
            בגמטריה ובאתבש
            זה יהיה מספר</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פסוק</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            מיקום המונח (</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ספר:פרק:פסוק</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>)</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>טקסט</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            הטקסט המלא של
            הפסוק בו
            מופיע המונח
            עם הדגשת
            המונח</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מונחים
                המתרחשים
                פעמיים בלבד</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            בחירת אפשרות
            זו ולחיצה על הכפתור
            "עדכן" (בהנחה
            של חיפוש
            אותיות בלבד
            בכל ספרי
            התנ"ך), יופיע
            הבא:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={624} height={227} id="Picture 55" src="helpDialogImages/image050.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פרטי
                העמודה</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אותם
            שדות <b>#, בטוי, פסוק,
              טקסט</b>
            המופיעים
            עבור האפשרות
            "מתרחש רק פעם
            אחת" ושדות
            פסוק וטקסט
            נוספים
            מופיעים
            מימין.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>זה
            יציג רשימה
            מפורטת של
            סוגי מונחים
            שצוינו המופיעים
            רק פעמיים
            בתנ"ך או
            בתחום החיפוש.
            הרשימה תוזמן
            בתחילה לפי
            הופעה
            לפסוקים המוצגים
            בצד ימין.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מונחים
                המתרחשים
                שלוש פעמים או
                יותר </span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            בחירת אפשרות
            זו ולחיצה על
            הכפתור "עדכן"
            (בהנחה של
            חיפוש אותיות
            בלבד בכל ספרי
            התנ"ך), יופיע
            הבא:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={624} height={227} id="Picture 56" src="helpDialogImages/image051.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פרטי
                העמודה</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אותם
            שדות <b>#, בטוי</b>
            שמופיעים
            עבור שתי
            האפשרויות
            האחרות. השדה
            הנוסף הופעות
            מציין את
            המספר הכולל
            של הפעמים שהמונח
            מופיע
            והרשימה תתמיין</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בתחילה
            לפי שדה זה
            בסדר יורד.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>סינון,
                מיון ושינוי
                גודל הרשימה</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>יתנהג
            באותו אופן
            כמו המסכים
            האחרים
            לתצוגות טבלה
            שהוזכרו כאן.</span></p>

      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b>Frequency Viewer / </b><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שכיחות</span></b></p>
        <p className="MsoNormal"><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p className="MsoNormal"><b><u>Goal</u></b></p>
        <p className="MsoNormal">To let the user examine listings of words or terms according
          to the number of times they appear in Tanach or in a specified scope of seforim.&nbsp;
          It also offers added detail in the interesting cases where there are only one
          or two occurrences.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Options and settings</u></b></p>
        <p className="MsoNormal">The standard settings described earlier will affect a
          search’s focus in terms of term type (letters, nekudos, taamim, shoresh, gematria,
          atbash) and in terms of the scope of seforim to search.&nbsp; </p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">Additionally, this screen will have an added setting near
          the “Update” button which will determine whether to display information on
          terms appearing: <b>a)</b> once&nbsp; <b>b)</b> twice <i><span style={{fontSize: '10.0pt'}}>(the default selection)</span></i>&nbsp; <b>c)</b> three or more times. </p>
        <p className="MsoNormal"><img width={144} height={37} id="Picture 53" src="helpDialogImages/image048.png" /></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Terms occurring only once</u></b></p>
        <p className="MsoNormal">Upon choosing this option and clicking the “Update” button
          (assuming letter-only search within all sifrei Tanach), the following will
          appear:</p>
        <p className="MsoNormal"><img width={623} height={226} id="Picture 54" src="helpDialogImages/image049.jpg" /></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Column Details</u></b></p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>#</b> - index number indicating the
          term’s position within the initial sort order of the list (order of appearance
          in Tanach)</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בטוי</span></b><span dir="LTR" /><span dir="LTR" /> – the term which appears only once.&nbsp; For raw words, it can
          include only letters or even nekudos and taamim; for shoresh, it will be the
          shoresh word; for gematria and atbash it will be a number</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פסוק</span></b><span dir="LTR" /><span dir="LTR" /> – the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ספר:פרק:פסוק</span><span dir="LTR" /><span dir="LTR" /> location of the term</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טקסט</span></b><span dir="LTR" /><span dir="LTR" /> – the full text of the pasuk in which the term appears with the
          term being highlighted</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Terms occurring only twice</u></b></p>
        <p className="MsoNormal">Upon choosing this option and clicking the “Update” button
          (assuming letter-only search within all sifrei Tanach), the following will
          appear:</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><img width={624} height={227} id="Picture 55" src="helpDialogImages/image050.jpg" /></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Column Details</u></b></p>
        <p className="MsoNormal">The same <span dir="RTL" /><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />#,
            בטוי, פסוק,
            טקסט</span><span dir="LTR" /><span dir="LTR" />
          fields that appear for the “occur only once” option and extra <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פסוק,
            טקסט</span><span dir="LTR" /> <span dir="LTR" />
          fields appearing to the right.&nbsp; This will present a detailed listing of
          specified types of terms which only occur twice in Tanach or in the search
          scope. The list will be initially ordered by appearance for the pesukim that
          are displayed on the right.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Terms occurring three or more times</u></b></p>
        <p className="MsoNormal">Upon choosing this option and clicking the “Update” button
          (assuming letter-only search within all sifrei Tanach), the following will
          appear:</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><img width={624} height={227} id="Picture 56" src="helpDialogImages/image051.jpg" /></p>
        <p className="MsoNormal"><b><u>Column Details</u></b></p>
        <p className="MsoNormal">The same <span dir="RTL" /><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />#,
            בטוי</span><span dir="LTR" /><span dir="LTR" />
          fields that appear for the other two options.&nbsp; The additional field <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הופעות</span><span dir="LTR" /><span dir="LTR" /> indicates the total number of times that
          the term appears and the list will be initially sorted by that field in
          descending order.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Filtering, sorting and resizing the list</u></b></p>
        <p className="MsoNormal">Will behave the same way as the other screens for grid
          displays mentioned here.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default FrequencyViewer_Instructions;