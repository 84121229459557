import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import { Button, Popover, Typography, MenuItem } from '@material-ui/core';
import translations from "./json/translations.json";
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

//PROPS USED: onChange, value, menuItems, activeLanguageCode, disabled, selectOrLoading, type

class PartsOfSpeechSelector2D extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      open: false
    }

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });
    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}

    this.handleSelect = this.handleSelect.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleSelect(event) {
    if(event.target.value === undefined){
      event.target.value = 0
    }
    this.props.onChange(event);
    this.handleClose();
  }

  handleOpen(e) {
    this.setState({anchorEl: e.currentTarget, open: true});
  } 
  
  handleClose(event, reason) {
    if (reason == null) //assume for now that this only occurs when a value is selected or deselected - in this case, we want the dropdown to remain open for further selecting
        return false;
    this.setState({anchorEl: null, open: false});
  }

  render() {
    const fakeThis = this;
    const anchorEl = this.state.anchorEl;
    var selectOrLoading = this.props.selectOrLoading
    var displayVal = selectOrLoading;
    var menuItems = this.props.menuItems
    // if(["placementRendererRangeSefer", "taamParserSefer"].includes(this.props.type)){
    //   menuItems.map((item, key) => {
    //     menuItems[key].id = menuItems[key].seferId;
    //     menuItems[key].value = menuItems[key].seferName;
    //   })
    // } else if (["placementRendererRangePerek", "taamParserPerek"].includes(this.props.type)) {
    //   menuItems.map((item, key) => {
    //     menuItems[key].id = menuItems[key].perekNum;
    //     menuItems[key].value = menuItems[key].perekValue;
    //   })
    // } else if (["placementRendererRangePasuk", "taamParserPasuk"].includes(this.props.type)) {
    //   menuItems.map((item, key) => {
    //     menuItems[key].id = menuItems[key].pasukNum;
    //     menuItems[key].value = menuItems[key].pasukValue;
    //   })
    // }

    var isHebrew = localStorage.getItem("language") === 'he';

    const value = this.props.value
    if (value == null) return "";
    // console.log(value)
    var selectedItems = [];
    menuItems.map(item => {
      if(value.includes(item.id)){
        selectedItems.push(item);
      }
    })
    if(selectedItems){
      displayVal = selectedItems.map(item => {
        return isHebrew ? item.name : item.name_en;
      }).join(", ")
    }
    var directionStyling = {
      direction: 'ltr',
      textAlign: 'left'
    }

    if(isHebrew){
      directionStyling = {
        direction: 'rtl',
        textAlign: 'right'
      }
    }

    var labelClassName = "placement-selector-2D-label";

    var rowCount = 1;
    // if(isLetterLabel){
    //   rowCount = Math.floor(Math.sqrt(menuItems.length));
    // } else {
      rowCount = Math.ceil(Math.sqrt(menuItems.length*4));
    // }
    if(this.props.isTextComparator){
      rowCount = 10;
    }
    if(rowCount > 20){
      rowCount = 20;
    }

    return <span className="placement-selector-2D-selector" style={{direction: directionStyling.direction}}>
      <Button className="parts-of-speech-selector-2D-button" onClick={(e) => {this.handleOpen(e)}} disabled={this.props.disabled}>
        <table width='100%'>
          <tbody>
            <tr>
              <td>
              <Typography
                variant="h6"
                className={labelClassName}
                style={{
                  color: `${this.props.disabled ? "#8a999e" : "black"}`,
                  textAlign: directionStyling.textAlign,
                  fontWeight: 400,
                  width: 'calc(100% - 24px)'
                }}
              >
                {displayVal}</Typography >
              </td>
              <td width='24px'>
                <ArrowDropDown className="placement-selector-2D-icon" style={{color: '#757575'}}/>
              </td>
            </tr>
          </tbody>
        </table>

      </Button>

          <Popover
            open={this.state.open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            elevation={1}
            className="placement-selector-2D-popover"
          >
            {selectOrLoading && <MenuItem className="menuItemHeader" value={0} onClick={(e) => {fakeThis.handleSelect(e)}}>
              {selectOrLoading}
            </MenuItem>}
            <div className={"placement-selector-2D-columns-parent-" + directionStyling.direction}>
                {menuItems.map((menuItem, key) => {
                  var menuItemsArr = [];
                  for(let i = 0; i < rowCount; i++){
                    if(menuItems[key+i]){
                      menuItemsArr.push(
                        <MenuItem className={"placement-selector-2D-menuItem-" + directionStyling.direction} value={menuItems[key+i].id} onClick={(e) => {fakeThis.handleSelect(e)}}
                          style={{
                            direction: `${directionStyling.direction}`,
                            backgroundColor: `${value.includes(menuItems[key+i].id) ? "lightgrey" : "" }`,
                          }}
                        >{isHebrew ? menuItems[key+i].name : menuItems[key+i].name_en}</MenuItem>
                      )
                    }
                  }
                  if(key%rowCount === 0){
                    return <div>
                      {menuItemsArr}
                    </div>;
                  }
                  
                })}
            </div>
          </Popover>
        </span>;
       }
}

export default withLocalize(PartsOfSpeechSelector2D);
