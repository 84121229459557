import React, { Component } from 'react';

class ObjectCloudViewer_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal"><b><u>מטרה</u></b></p>

        <p className="MsoNormal">מבט שונה במציג השכיחות כאשר במקום רשת ארוכה של שורות ועמודות, היא מספקת דרך
        להציג תוצאות כתמונה חזותית אחת. המיקוד כאן הוא לקבל מידע רב השפעה
        במהירות לעומת הצגת נתונים סטטיסטיים מפורטות. בכוונה, מה שמוצג כאן לא
        מכונה "ענני מילים" או "ענני תגיות" מכיוון שהעננים ב<b>בייס-הספר</b> יכולים
        אפילו להציג מגוון רחב של מידע על תדירות שימוש על שורשים, גימטריות, נקודות וטעמי המקרה.
        </p>

        <p className="MsoNormal">&nbsp;</p>

        <p className="MsoNormal"><i>היכולת להציג עננים זמינה במציג המתמחה הנפרד
        המתואר כאן. זה זמין גם כדרך חלופית להביט
        בדברים ב<b>דפדפן התנ"ך</b> וב<b>משווה טקסטים</b> (כמתואר במקומות אחרים בתיעוד).
        </i></p>

        <p className="MsoNormal">&nbsp;</p>

        <p className="MsoNormal"><b><u>השקת מציג ענני אבייקטים</u></b></p>

        <p className="MsoListParagraphCxSpFirst" style={{marginRight:'.5in', textIndent:'-.25in'}}><span
        style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>כאמור במקום אחר, ניתן להפעיל חלון זה מאייקון בתפריט הראשי</p>

        <p className="MsoListParagraphCxSpFirst" style={{marginRight:'.5in', textIndent:'-.25in'}}><span
        style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>בחר את סוג האובייקט לניתוח על ידי בחירה מהגדרות החיפוש בלוח בצד ימין של המסך, למשל: אותיות, נקודות, טעמים, שורש, גמטריא, אתבש</p>

        <p className="MsoListParagraphCxSpLast" style={{marginRight:'.5in', textIndent:'-.25in'}}><span
        style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>לאחר בחירת קטעי קריאה ו/או טווחי פסוקים (<i>דוגמה זו מפרשת ויגש - עיין בהמשך להוראות נוספות</i>),
לחץ על כפתור <b>עדכן</b> (<i>למעלה</i>) כדי ליצור את הענן (<i>פרטים מדויקים לגבי מספר הפעמים שהביטוי/ אובייקט מופיע בפסוקים ניתן לגשת באמצעות ריחוף לאורך הביטוי</i>)
        </p>

        <p className="MsoNormal" align="center" style={{textAlign:'center'}}><img width={533} height={350} id="Picture 101" src="helpDialogImages/image074.jpg" /></p>

        <p className="MsoNormal" align="center" style={{textAlign:'center'}}><span
        style={{fontSize:'6.0pt'}}>&nbsp;</span></p>

        <p className="MsoNormal"><b><u>בחירת קטעים ופסוקים</u></b></p>

        <p className="MsoNormal">
        בחלקו העליון-ימין של מציג ענני אבייקטים, לחיצה על כפתור בחר קטעים תפתח חלון שייראה כמו בורר בכרטיסייה מיקום בהגדרות
        החיפוש המתקדם, שם ניתן לבחור קטעים מסויימים בתנ"ך עם האפשרות קטע <b>מסויים…</b>
        </p>

        <p className="MsoNormal"><img width={623} height={16} id="Picture 102" src="helpDialogImages/image067.jpg" /></p>

        <p className="MsoNormal">
... ו\או על ידי בחירת טווחי פסוקים ספציפיים עם האפשרות
<b>&nbsp;
טווח פסוקים
</b>&nbsp;
        </p>

        <p className="MsoNormal"><img width={624} height={16} id="Picture 104" src="helpDialogImages/image068.jpg" /></p>

        <p className="MsoNormal"><a name="_Hlk43991211">&nbsp;</a></p>

        <table className="MsoTableGrid" border={0} cellspacing={0} cellpadding={0} style={{borderCollapse:'collapse', border:'none'}}>
         <tr>
          <td width={312} valign="top" style={{width:'233.75pt', border:'none', borderRight:'solid windowtext 1.0pt', padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="MsoNormal"><b><u>פקדי חלון הדפדפן</u></b></p>
              <p className="MsoNormal">ישנם שלושה סמלי בקרה יחד בחלק העליון של הצופה. לחיצה על:</p>
              <table className="MsoTableGrid" border={0} cellspacing={0} cellpadding={0} style={{borderCollapse:'collapse', border:'none'}}>
               <tr style={{height:'.2in'}}>
                <td width={30} valign="top" style={{width:'22.25pt', padding:'0in 0in 0in 0in', height:'.2in'}}>
                <p className="MsoNormal"><img width={24} height={24} id="Picture 105" src="helpDialogImages/image069.png" /></p>
                </td>
                <td width={594} style={{width:'445.25pt', padding:'0in 0in 0in 0in', height:'.2in'}}>
                <p className="MsoNormal">סוגר את חלון הדפדפן</p>
                </td>
               </tr>
               <tr style={{height:'.2in'}}>
                <td width={30} valign="top" style={{width:'22.25pt', padding:'0in 0in 0in 0in', height:'.2in'}}>
                <p className="MsoNormal"><img width={24} height={24} id="Picture 107" src="helpDialogImages/image070.png" /></p>
                </td>
                <td width={594} style={{width:'445.25pt', padding:'0in 0in 0in 0in', height:'.2in'}}>
                <p className="MsoNormal">מרחיב את החלון לתצוגת מסך מלא</p>
                </td>
               </tr>
               <tr style={{height:'.2in'}}>
                <td width={30} valign="top" style={{width:'22.25pt', padding:'0in 0in 0in 0in', height:'.2in'}}>
                <p className="MsoNormal"><img width={24} height={24} id="Picture 108" src="helpDialogImages/image071.png" /></p>
                </td>
                <td width={594} style={{width:'445.25pt', padding:'0in 0in 0in 0in', height:'.2in'}}>
                <p className="MsoNormal">משנה את גודל התצוגה למסך מפוצל על ידי מילוי האזור ישירות מתחת לסרגל החיפוש והתוצאות</p>
                </td>
               </tr>
              </table>
              <p className="MsoNormal"></p>
          </td>
          <td width="312" valign="top" style={{width:'233.75pt', border:'none', padding:'0in 5.4pt 0in 5.4pt'}}>
          <p className="MsoNormal"><b><u>הגדרות</u></b></p>
          <p className="MsoNormal">
          לחיצה בכפתור
          <b>&nbsp;
          הגדרות חיפוש
          </b>&nbsp;

           (<i>
           למעלה
           </i>)
            ייצר חלון עם היכולת להגדיר:
          </p>
          <p className="MsoListParagraphCxSpFirst" style={{marginRight:'.5in', textIndent:'-.25in'}}><span
          style={{fontSize:'10.0pt', fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span>
          <b>
          מקס' אבייקטים
          </b>
           – קובע את מספר הביטויים המרבי להצגה
          </p>
          <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.5in', textIndent:'-.25in'}}><span
          style={{fontSize:'10.0pt', fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span>
          <b>
          מינ' שכיחות
          </b>
- קובע את מספר המופעים המינימלי כדי שיופיע הביטוי
          </p>
          <p className="MsoListParagraphCxSpLast" style={{marginRight:'.5in', textIndent:'-.25in'}}><span
          style={{fontSize:'10.0pt', fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span>
          <b>
         מילים מדלגות
          </b>
          – מציג חלונית נוספת המאפשרת לבחור בביטויים להתעלם מהם
          </p>
          <p className="MsoNormal"><span lang="HE" dir="RTL" style={{fontFamily:'"Arial",sans-serif'}}>&nbsp;</span></p>
          </td>
         </tr>
        </table>
         <br />
         <p className="MsoNormal" lang="HE" dir="RTL" style={{fontFamily:'"Arial",sans-serif'}}><b><u>הנפשה</u></b></p>
         <p><img width={600} height={40} id="Picture 109" src="helpDialogImages/playCloudAnimation.jpg" /></p>
          <p className="MsoNormal" lang="HE" dir="RTL" style={{fontFamily:'"Arial",sans-serif'}}>לחיצה על כפתור ההפעלה
          (<i>בימין</i>)
יתחיל הנפשה. הענן ירענן כדי להציג את הספר, הפרשה או הפרק הבא על סמך איזה מהם נבחר בפקדי המחוון (A).
 המשתמש יכול לשנות את מהירות ההנפשה על ידי לחיצה על החצים למעלה או למטה בתיבת זמן השהיה. אם כבר נבחרה בחירה ספציפית באמצעות בחירת הפסוק למעלה, ההנפשה תפעיל את הבחירה הזו ותמשיך מחזורה דרך הספרים,
  הפרשיות או הפרקים. אם כבר נבחרה בחירה בשם, ההנפשה תעבור על כל בחירה בקטגוריה הספציפית הזו. לדוגמה, אם המשתמש לוחץ בכפתור ההפעלה כשהארבע פרשיות ופרשת פרה כבר נבחרו,
  ההנפשה תופעל ותרענן פעם אחת עבור כל אחד מארבעת הפרשיות. באמצעות ההפעלה החוזרת בכפתור הסיום (B), המשתמש יכול לבחור שההנפשה תתחיל מחדש ולהמשיך מחזורה כך עד שהמשתמש ילחץ על עצירה.
  אם תבחר חזור בסוף (C), ההנפשה תבחר את הבחירה השנייה מהסוף במחזור ותמשיך לזרום לאחור עד שהיא תגיע להתחלה. לאחר מכן הוא יזרום קדימה וימשיך בתבנית זו עד שהמשתמש ילחץ על עצור.
           <br/>
         <br />
           -<b>צבע הרקע: </b>
            כפתור זה קובע את צבע הרקע של הענן.<br/>
           -<b>צבע הכותרת: </b>
            כפתור זה קובע את צבע הכותרת של הענן.<br/>
           -<b>תיבת סימון להצגת טעינה: </b>
            תיבת סימון זו מגדירה את השכבה שמעל לטעינה. אם המשתמש אינו רוצה שסימן הטעינה או שכבתה שלמעלה הכהה יופיעו בעת טעינת הענן, יש לסמן תיבה זו.<br/>
           -<b>תיבת סימון להצגת כותרת: </b>
            תיבת סימון זו קובעת אם הכותרת של הענן תופיע מעל הענן או לא. אם זה מסומן, הכותרת של הבחירה הזו תהיה מעל הענן בצבע שצוין.
           </p>
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b><u>Goal</u></b></p>
        
        <p className="MsoNormal">A different angle on a frequency viewer where instead of a
        lengthy grid of rows and columns, it provides a way of viewing results as a
        single visualized snapshot.  The focus here is on getting a quick gist of impactful
        information as opposed to presenting detailed statistics.  What is presented
        here is intentionally not called “word clouds” or “tag clouds” since the clouds
        in Base HaSefer can even display a wide array of usage frequency information on
        shorashim, gematrios, nekudos and taamei hamikra.</p>
        
        <p className="MsoNormal">&nbsp;</p>

        <p className="MsoNormal"><i>The ability to display clouds is available in the separate
        specialized viewer described here.  It is also available as an alternate way of
        looking at things in the <b>Tanach Viewer</b> and in the <b>Text Comparator </b>
        (as described elsewhere in the documentation).</i></p>

        <p className="MsoNormal">&nbsp;</p>

        <p className="MsoNormal"><b><u>Launching the Object Cloud viewer</u></b></p>

        <p className="MsoListParagraphCxSpFirst" style={{textIndent:'-.25in'}}><span
        style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>As mentioned elsewhere, one can launch this
        window from an icon in the Main Menu.</p>

        <p className="MsoListParagraphCxSpFirst" style={{textIndent:'-.25in'}}><span
        style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>Choose the type of object to analyze by selecting from the search settings in the panel on the right side of the screen, eg. אותיות, נקודות, טעמים, שורש, גמטריא, אתבש.
        </p>

        <p className="MsoListParagraphCxSpLast" style={{textIndent:'-.25in'}}><span
        style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>After selecting kria sections and/or pasuk
        ranges (<i>this example from </i><i><span lang="HE" dir="RTL" style={{fontFamily:'"Arial",sans-serif'}}>פ' ויגש</span> -</i> <i>see below for further instructions</i>), click the <b><span
        lang="HE" dir="RTL" style={{fontFamily:'"Arial",sans-serif'}}>עדכן</span></b><span
        lang="HE" dir="RTL" style={{fontFamily:'"Arial",sans-serif'}}> </span> button (<i>on top</i>) to generate the cloud (<i>exact details re:
        # of times the term/object appears within the pesukim, can be accessed by
        hovering over the term</i>)</p>

        <p className="MsoNormal" align="center" style={{textAlign:'center'}}><img width={533} height={350} id="Picture 101" src="helpDialogImages/image074.jpg" /></p>

        <p className="MsoNormal" align="center" style={{textAlign:'center'}}><span
        style={{fontSize:'6.0pt'}}>&nbsp;</span></p>

        <p className="MsoNormal"><b><u>Pasuk selection</u></b></p>

        <p className="MsoNormal">On upper right of the Object Cloud viewer, clicking the <b>בחר קטעים</b> button will open up a dialogue that will
        look like the Placement tab selection in the Advanced Search settings where one
        can select named sections of Tanach with the <b>קטע מסויים</b> option…..</p>

        <p className="MsoNormal"><img width={623} height={16} id="Picture 102" src="helpDialogImages/image067.jpg" /></p>

        <p className="MsoNormal">…..and/or by selecting specific ranges of pesukim with the <b>טווח פסוקים</b> option.</p>

        <p className="MsoNormal"><img width={624} height={16} id="Picture 104" src="helpDialogImages/image068.jpg" /></p>

        <p className="MsoNormal"><a name="_Hlk43991211">&nbsp;</a></p>

        <table className="MsoTableGrid" border={0} cellspacing={0} cellpadding={0} style={{borderCollapse:'collapse', border:'none'}}>
         <tr>
          <td width={312} valign="top" style={{width:'233.75pt', border:'none', borderRight:'solid windowtext 1.0pt', padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="MsoNormal"><b><u>Controlling the viewer window</u></b></p>
              <p className="MsoNormal">There are three control icons together on the top of the
              viewer. Clicking on:</p>
              <table className="MsoTableGrid" border={0} cellspacing={0} cellpadding={0} style={{borderCollapse:'collapse', border:'none'}}>
               <tr style={{height:'.2in'}}>
                <td width={30} valign="top" style={{width:'22.25pt', padding:'0in 0in 0in 0in', height:'.2in'}}>
                <p className="MsoNormal"><img width={24} height={24} id="Picture 105" src="helpDialogImages/image069.png" /></p>
                </td>
                <td width={594} style={{width:'445.25pt', padding:'0in 0in 0in 0in', height:'.2in'}}>
                <p className="MsoNormal">closes the viewer window</p>
                </td>
               </tr>
               <tr style={{height:'.2in'}}>
                <td width={30} valign="top" style={{width:'22.25pt', padding:'0in 0in 0in 0in', height:'.2in'}}>
                <p className="MsoNormal"><img width={24} height={24} id="Picture 107" src="helpDialogImages/image070.png" /></p>
                </td>
                <td width={594} style={{width:'445.25pt', padding:'0in 0in 0in 0in', height:'.2in'}}>
                <p className="MsoNormal">expands the window to full-screen view</p>
                </td>
               </tr>
               <tr style={{height:'.2in'}}>
                <td width={30} valign="top" style={{width:'22.25pt', padding:'0in 0in 0in 0in', height:'.2in'}}>
                <p className="MsoNormal"><img width={24} height={24} id="Picture 108" src="helpDialogImages/image071.png" /></p>
                </td>
                <td width={594} style={{width:'445.25pt', padding:'0in 0in 0in 0in', height:'.2in'}}>
                <p className="MsoNormal">resizes to split-screen view by filling out the area
                directly under the search bar and results</p>
                </td>
               </tr>
              </table>
              <p className="MsoNormal"></p>
          </td>
          <td width="312" valign="top" style={{width:'233.75pt', border:'none', padding:'0in 5.4pt 0in 5.4pt'}}>
          <p className="MsoNormal"><b><u>Settings</u></b></p>
          <p className="MsoNormal"><span style={{fontSize:'10.0pt'}}>Clicking the <b>הגדרות חיפוש</b> button
          (<i>on top</i>) will generate a dialog where:</span></p>
          <p className="MsoListParagraphCxSpFirst" style={{textIndent:'-.25in'}}><span
          style={{fontSize:'10.0pt', fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><b><span lang="HE" dir="RTL" style={{fontSize:'10.0pt', fontFamily:'"Arial",sans-serif'}}>מקס' אבייקטים</span></b><b><span lang="HE" style={{fontSize:'10.0pt'}}>
          </span></b><span style={{fontSize:'10.0pt'}}> sets the maximum # of terms to
          display</span></p>
          <p className="MsoListParagraphCxSpMiddle" style={{textIndent:'-.25in'}}><span
          style={{fontSize:'10.0pt', fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><b><span lang="HE" dir="RTL" style={{fontSize:'10.0pt', fontFamily:'"Arial",sans-serif'}}>מינ' שכיחות</span></b><span
          style={{fontSize:'10.0pt'}}> sets the minimum # of
          occurrences a term needs in order to appear</span></p>
          <p className="MsoListParagraphCxSpLast" style={{textIndent:'-.25in'}}><span
          style={{fontSize:'10.0pt', fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><b><span lang="HE" dir="RTL" style={{fontSize:'10.0pt', fontFamily:'"Arial",sans-serif'}}>מילים מדלגות</span></b><span
          style={{fontSize:'10.0pt'}}> displays another dialog that
          lets one select terms to be ignored</span></p>
          <p className="MsoNormal"><span lang="HE" dir="RTL" style={{fontFamily:'"Arial",sans-serif'}}>&nbsp;</span></p>
          </td>
         </tr>
        </table>
         <br />
         <p className="MsoNormal"><b><u>Animation</u></b></p>
         <p><img width={600} height={40} id="Picture 109" src="helpDialogImages/playCloudAnimation.jpg" /></p>
          <p className="MsoNormal">Clicking the Play button
          (<i>on right</i>) will start an animation. The cloud will refresh to display the next Sefer, Parsha, or Perek based on which one is chosen in the slider controls (A).
           The user can change the speed of the animation by clicking the up or down arrows in the Time Delay box. If a specific selection is already chosen using the pasuk 
           selection on top, the animation will play that selection and continue cycling through the Seforim, Parshios, or Perakim. If a Named Selection is chosen already, 
           the animation will cycle through each selection in that specific category. For example if the user chooses the play button when the Four Parshios and
           Parshas Parah are selected, the animation will play and refresh once for each of the Four Parshios. Using the replay at the end button (B), the user can choose to have the animation
           start over and continue to play like that until the user presses stop. If Reverse At the End (C) is chosen, the animation will select the second to last selection in the 
           cycle and continue to play backwards until it gets to the beginning. It will then play forwards and continue in that pattern until the user presses stop.<br/>
         <br />
           -<b>Background Color:</b> This button sets the color of the background of the cloud.<br/>
           -<b>Title Color:</b> This button sets the color of the title of the cloud.<br/>
           -<b>Show Loading Checkbox:</b> This checkbox sets the loading overlay. If the user does not want the loading sign or the dark overlay to appear when the cloud is loading, this box should be checked.<br/>
           -<b>Show Title Checkbox:</b> This checkbox controls whether or not the title of the cloud will appear above the cloud or not. If it is checked, the title of that selection will be above the cloud
            in the specified color.
           </p>

      </>
    }
    return retVal;
  }

  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default ObjectCloudViewer_Instructions;