import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import { Button, Paper, InputBase, IconButton, Fade, Typography, Popper, AppBar, Tabs, Tab, InputLabel, MenuItem, FormControl, Select, Switch, Popover } from '@material-ui/core';
import translations from "./json/translations.json";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ClearAll from '@material-ui/icons/ClearAll';
import SelectAll from '@material-ui/icons/SelectAll';
import AddBox from '@material-ui/icons/AddBox';

//function convertSelected(selected, vals) {
//    var result = "";
//    selected.sort().map(function(e){
//         var i = vals.findIndex((v)=>{return v.id == e;});
//         result += (vals[i].icon + ' ');
//    });
//    return result.trim();
//}

//const numericIcons = ['', '', '➋', '➌', '➍', '➎', '➏', '➐', '➑', '➒', '➓', '⓫', '⓬', '⓭', '⓮', '⓯', '⓰', '⓱', '⓲', '⓳', '⓴'];
const numericIcons = ['', '', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫', '⑬', '⑭', '⑮', '⑯', '⑰', '⑱', '⑲', '⑳', '㉑', '㉒', '㉓', '㉔', '㉕', '㉖', '㉗', '㉘', '㉙', '㉚', '㉛', '㉜', '㉝', '㉞', '㉟', '㊱', '㊲', '㊳', '㊴', '㊵', '㊶', '㊷', '㊸', '㊹', '㊺', '㊻', '㊼', '㊽', '㊾', '㊿']

function convertSelected(selected, list, icon, displayMultiple) {
    const displayMulti = displayMultiple == null ? true : false;

    const len = selected.length;
    if (len == 1) {
        var lastId = list[list.length-1].id;
        var diff = selected[0] - lastId;
        if (diff >= 1 && diff <= 3) //will be 1, 2, or 3
            return ['X','?','*'][diff-1];
    }

    if (len > 1 && !displayMulti)
        return numericIcons[len];

    var result = "";
    selected.sort().map(function(e){
         var i = list.findIndex((v)=>{return v.id == e;});
         var displayIcon = icon == null ? list[i].icon : icon + util.right(list[i].icon,1);
         result += (displayIcon + ' ');
    });
    return result.trim();
}

class TaamPicker2D extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            open: false
        }

        this.handleSelChange = this.handleSelChange.bind(this);
        this.handlePopoverClick = this.handlePopoverClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleTaamButtonClicked = this.handleTaamButtonClicked.bind(this);

//        this.props.initialize({
//          languages: [
//            { name: "עברית", code: "he" },
//            { name: "English", code: "en" }
//          ],
//          translation: translations,
//          options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: "en" }
//        });
//
//        this.props.addTranslation(translations);
//        this.setLanguage = (code) => {props.setActiveLanguage(code)};
    }

     handleSelChange(event) {
         //this.props.extSetValues(event.target.value, convertSelected(event.target.value, this.props.pickerModel.list))
         this.props.extSetValues(event.target.value, convertSelected(event.target.value, this.props.pickerModel.list, this.props.icon, this.props.displayMultiple))
     }

     handleTaamButtonClicked(taamId){
         var oldValues = this.props.pickerModel.values
         var newValues = oldValues
         if(taamId === 'selectAll'){
             newValues = []
             for(let i = 1; i<=57; i++){
                newValues.push(i);
             }
         } else if (taamId === 'clearAll'){
             newValues = []
         } else if (['mafsikim','keisarim','melachim','mafsikimNosafim','mishniim','shilishim','meshartim'].includes(taamId)) {
             var newGroupValues = [];

             if (taamId === 'mafsikim'){
                 newGroupValues = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45]
             } else if (taamId === 'keisarim'){
                 newGroupValues = [1,2,29]
             } else if (taamId === 'melachim'){
                 newGroupValues = [3,4,5,6,7,8,30,31,32,33,34]
             } else if (taamId === 'mafsikimNosafim'){
                 newGroupValues = [35,36,37]
             } else if (taamId === 'mishniim'){
                 newGroupValues = [9,10,11,12,13,38,39,40,41,42]
             } else if (taamId === 'shilishim'){
                 newGroupValues = [14,15,16,17,18,19,43,44,45]
             } else if (taamId === 'meshartim'){
                 newGroupValues = [20,21,22,23,24,25,26,27,28,46,47,48,49,50,51,52,53,54,55]
             }

             for(let i = 0; i < newGroupValues.length; i++){
                if(!oldValues.includes(newGroupValues[i])){
                    newValues.push(newGroupValues[i]);
                }
             }
         } else if ([58,59,60].includes(taamId)){
            if(oldValues.includes(taamId)){
                newValues = [];
            } else {
                newValues = [taamId];
            }
         } else {
             //remove special values from newValues
             if(oldValues.includes(58) || oldValues.includes(59) || oldValues.includes(60)){
                 newValues = []
             }
            //remove selected value from newValues
             if (oldValues.includes(taamId)){
                 var taamIndex = oldValues.indexOf(taamId)
                 newValues = oldValues.slice(0,taamIndex).concat(oldValues.slice(taamIndex+1, oldValues.length))
             //add selected value to newValues
             } else {
                 newValues.push(taamId)
             }
         }
         this.props.extSetValues(newValues, convertSelected(newValues, this.props.pickerModel.list, this.props.icon, this.props.displayMultiple))
      }





     handlePopoverClick(event) {
         this.setState({anchorEl: event.currentTarget, open: true});
     }

     handleClose() {
         this.setState({anchorEl: null, open: false});
     }


     render() {
        if (this.props.pickerModel == null || this.props.pickerModel.list == null || this.props.pickerModel.list.length == 0)
             return null;

        const list = this.props.pickerModel.list;
        var wasEmetIndicated = false;
        var headerStyle = {backgroundColor: 'lightgray'};

        const selectStyle = this.props.selectStyle != null ? this.props.selectStyle : "";
        const icon = this.props.icon != null ? this.props.icon : null;
        const displayMultiple = this.props.displayMultiple;
        const rowHeight = {height: '40px'}; //style Wingdings 2 : tuvwxyz{|~ to rep 0 - 10
        var i = 0;

        var noTaam = "", anyTaam = "", anyTaamim = "";
        var specialTaamStyle = {color: 'red', height: '40px', padding: 0, align:'center!important'};

        const anchorEl = this.state.anchorEl;
        const id = this.state.open ? 'simple-popover' : undefined;

        const sel = this.props.translate("select");
        const selectPad = this.props.translate("selectPad.IncludeExclude");

        const pickerModelValues = this.props.pickerModel.values;
        const renderValue = convertSelected(this.props.pickerModel.values, list, icon, displayMultiple)
        const fakeThis = this //fixed problem with 'this' not getting defined

        if (["Letter", "General"].includes(selectStyle)) {
            noTaam = <MenuItem className="menuItem" style={specialTaamStyle} value={list[list.length-1].id+1} selected={this.props.pickerModel.values.includes(list[list.length-1].id+1)} onClick={() => fakeThis.handleTaamButtonClicked(list[list.length-1].id+1)}>
                <span className="lntPickerSelectIcon">X</span>&nbsp;<span className="lntPickerSelectName">ללא טעם</span>
            </MenuItem>;
        }
        if (["Letter", "General"].includes(selectStyle)) {
            anyTaam = <MenuItem className="menuItem" style={specialTaamStyle} value={list[list.length-1].id+2} selected={this.props.pickerModel.values.includes(list[list.length-1].id+2)} onClick={() => fakeThis.handleTaamButtonClicked(list[list.length-1].id+2)}>
                <span className="lntPickerSelectIcon">?</span>&nbsp;<span className="lntPickerSelectName">כל טעם</span>
            </MenuItem>;
        }
        if (["General"].includes(selectStyle)) {
            anyTaamim = <MenuItem className="menuItem" style={specialTaamStyle} value={list[list.length-1].id+3} selected={this.props.pickerModel.values.includes(list[list.length-1].id+3)} onClick={() => fakeThis.handleTaamButtonClicked(list[list.length-1].id+3)}>
                <span className="lntPickerSelectIcon">*</span>&nbsp;<span className="lntPickerSelectName">כל טעמים</span>
            </MenuItem>;
        }

        return <span className="placementGridLine" style={rowHeight} style={{backgroundColor: '#d3d3d3'}}>

              <Button aria-describedby={id} variant="contained" onClick={this.handlePopoverClick} width='44px' className={fakeThis.props.extClassName} elevation={1}>
                        <ArrowDropDown style={{color: '#757575'}}/> <Typography variant="h6" style={{fontSize: '25px', fontWeight: 'bolder', lineHeight: 1}}>{renderValue}</Typography >
              </Button>
              <Popover
                id={id}
                open={this.state.open}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                elevation={1}
              >
                <Typography style={{direction: 'rtl', padding: '10px'}}>

                  <Table size="small" >
                      <TableHead>
                        <TableRow style={{height: '30px'}}>
                          <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                                <MenuItem className="menuItem menuItemSpecial" style={{height: '30px'}} value={'selectAll'} selected={false} onClick={() => fakeThis.handleTaamButtonClicked('selectAll')}>
                                <span className="lntPickerSelectIcon"><SelectAll style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">בחר הכל</span>&nbsp;<span className="lntPickerSelectIcon"></span>
                                </MenuItem>
                          </TableCell>
                          <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                              <MenuItem className="menuItem menuItemSpecial" style={{height: '30px'}} value={'clearAll'} selected={false} onClick={() => fakeThis.handleTaamButtonClicked('clearAll')}>
                              <span className="lntPickerSelectIcon"><ClearAll style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">נקה הכל</span>&nbsp;<span className="lntPickerSelectIcon"></span>
                              </MenuItem>
                          </TableCell>

                          <TableCell colSpan={5} style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0', minWidth: '580px'}}>

                          </TableCell>
                        </TableRow>
                      </TableHead>
                  </Table >
                  <Table size="small" >
                      <TableHead>
                        <TableRow style={{height: '30px'}}>
                          <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                              <MenuItem className="menuItem menuItemSpecial" style={{height: '30px'}} value={'mafsikim'} selected={false} onClick={() => fakeThis.handleTaamButtonClicked('mafsikim')}>
                                  <span className="lntPickerSelectIcon"><AddBox style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">כל מפסיקים</span>&nbsp;<span className="lntPickerSelectIcon"></span>
                              </MenuItem>
                          </TableCell>
                          <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                              <MenuItem className="menuItem menuItemSpecial" style={{height: '30px'}} value={'keisarim'} selected={false} onClick={() => fakeThis.handleTaamButtonClicked('keisarim')}>
                                  <span className="lntPickerSelectIcon"><AddBox style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">קיסרים</span>&nbsp;<span className="lntPickerSelectIcon"></span>
                              </MenuItem>
                          </TableCell>
                          <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                              <MenuItem className="menuItem menuItemSpecial" style={{height: '30px'}} value={'melachim'} selected={false} onClick={() => fakeThis.handleTaamButtonClicked('melachim')}>
                                  <span className="lntPickerSelectIcon"><AddBox style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">מלכים</span>&nbsp;<span className="lntPickerSelectIcon"></span>
                              </MenuItem>
                          </TableCell>

                          <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                              <MenuItem className="menuItem menuItemSpecial" style={{height: '30px'}} value={'mishniim'} selected={false} onClick={() => fakeThis.handleTaamButtonClicked('mishniim')}>
                                  <span className="lntPickerSelectIcon"><AddBox style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">משניים</span>&nbsp;<span className="lntPickerSelectIcon"></span>
                              </MenuItem>
                          </TableCell>
                          <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                              <MenuItem className="menuItem menuItemSpecial" style={{height: '30px'}} value={'shilishim'} selected={false} onClick={() => fakeThis.handleTaamButtonClicked('shilishim')}>
                                  <span className="lntPickerSelectIcon"><AddBox style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">שלישים</span>&nbsp;<span className="lntPickerSelectIcon"></span>
                              </MenuItem>
                          </TableCell>
                          <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                              <MenuItem className="menuItem menuItemSpecial" style={{height: '30px'}} value={'mafsikimNosafim'} selected={false} onClick={() => fakeThis.handleTaamButtonClicked('mafsikimNosafim')}>
                                  <span className="lntPickerSelectIcon"><AddBox style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">מפסיקים נוספים</span>&nbsp;<span className="lntPickerSelectIcon"></span>
                              </MenuItem>
                          </TableCell>
                          <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                              <MenuItem className="menuItem menuItemSpecial" style={{height: '30px'}} value={'meshartim'} selected={false} onClick={() => fakeThis.handleTaamButtonClicked('meshartim')}>
                                  <span className="lntPickerSelectIcon"><AddBox style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">משרתים</span>&nbsp;<span className="lntPickerSelectIcon"></span>
                              </MenuItem>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                  </Table >
                  <div style={{overflow: 'auto', height: '400px', direction: 'ltr'}}>

                                      <Table size="small" style={{direction: 'rtl'}}>

                                        <TableHead>
                                          <TableRow style={rowHeight}>

                                            <TableCell colSpan={4} style={{padding: '0px', borderBottom: '0'}}>
                                                  <Typography variant="h6" style={{direction: 'rtl', padding: '10px', textAlign:'right'}}><b>טעמים רגילים</b></Typography>
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody style={{padding: '0px'}}>
                                              <TableRow style={rowHeight}>
                                                      {list.slice(0,5).map(function(e, key){
                                                          i = e.id + 1;;
                                                          const taamStyle = {height: '40px'};
                                                          const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                                          return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}} key={key}>
                                                              <MenuItem className="menuItem" style={taamStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleTaamButtonClicked(e.id)}>
                                                                  <span className="lntPickerSelectIcon">{e.icon}</span>&nbsp;
                                                                  <span className="lntPickerSelectType">{e.taamType}</span>&nbsp;
                                                                  <span className="lntPickerSelectName">{e.name}</span>
                                                              </MenuItem>
                                                          </TableCell>
                                                          })}
                                                </TableRow>
                                                  <TableRow style={rowHeight}>
                                                          {list.slice(5,10).map(function(e, key){
                                                              i = e.id + 1;;
                                                              const taamStyle = {height: '40px'};
                                                              const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                                              return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}} key={key}>
                                                                  <MenuItem className="menuItem" style={taamStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleTaamButtonClicked(e.id)}>
                                                                      <span className="lntPickerSelectIcon">{e.icon}</span>&nbsp;
                                                                      <span className="lntPickerSelectType">{e.taamType}</span>&nbsp;
                                                                      <span className="lntPickerSelectName">{e.name}</span>
                                                                  </MenuItem>
                                                              </TableCell>
                                                              })}
                                                    </TableRow>
                                                    <TableRow style={rowHeight}>
                                                          {list.slice(10,15).map(function(e, key){
                                                              i = e.id + 1;;
                                                              const taamStyle = {height: '40px'};
                                                              const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                                              return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}} key={key}>
                                                                  <MenuItem className="menuItem" style={taamStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleTaamButtonClicked(e.id)}>
                                                                      <span className="lntPickerSelectIcon">{e.icon}</span>&nbsp;
                                                                      <span className="lntPickerSelectType">{e.taamType}</span>&nbsp;
                                                                      <span className="lntPickerSelectName">{e.name}</span>
                                                                  </MenuItem>
                                                              </TableCell>
                                                              })}
                                                    </TableRow>
                                                    <TableRow style={rowHeight}>
                                                          {list.slice(15,20).map(function(e, key){
                                                              i = e.id + 1;;
                                                              const taamStyle = {height: '40px'};
                                                              const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                                              return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}} key={key}>
                                                                  <MenuItem className="menuItem" style={taamStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleTaamButtonClicked(e.id)}>
                                                                      <span className="lntPickerSelectIcon">{e.icon}</span>&nbsp;
                                                                      <span className="lntPickerSelectType">{e.taamType}</span>&nbsp;
                                                                      <span className="lntPickerSelectName">{e.name}</span>
                                                                  </MenuItem>
                                                              </TableCell>
                                                              })}
                                                    </TableRow>


                                                    <TableRow style={rowHeight}>
                                                          {list.slice(20,25).map(function(e, key){
                                                              i = e.id + 1;;
                                                              const taamStyle = {height: '40px'};
                                                              const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                                              return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}} key={key}>
                                                                  <MenuItem className="menuItem" style={taamStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleTaamButtonClicked(e.id)}>
                                                                      <span className="lntPickerSelectIcon">{e.icon}</span>&nbsp;
                                                                      <span className="lntPickerSelectType">{e.taamType}</span>&nbsp;
                                                                      <span className="lntPickerSelectName">{e.name}</span>
                                                                  </MenuItem>
                                                              </TableCell>
                                                              })}
                                                    </TableRow>
                                                    <TableRow style={rowHeight}>
                                                          {list.slice(25,29).map(function(e, key){
                                                              i = e.id + 1;;
                                                              const taamStyle = {height: '40px'};
                                                              const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                                              return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}} key={key}>
                                                                  <MenuItem className="menuItem" style={taamStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleTaamButtonClicked(e.id)}>
                                                                      <span className="lntPickerSelectIcon">{e.icon}</span>&nbsp;
                                                                      <span className="lntPickerSelectType">{e.taamType}</span>&nbsp;
                                                                      <span className="lntPickerSelectName">{e.name}</span>
                                                                  </MenuItem>
                                                              </TableCell>
                                                              })}
                                                    </TableRow>

                                                    <TableRow style={rowHeight}>
                                                          <TableCell colSpan={4} style={{padding: '0px', borderBottom: '0'}}>
                                                                <Typography variant="h6" style={{direction: 'rtl', padding: '10px', textAlign:'right', color: '#40A0FF'}}><b>טעמי אמ"ת</b></Typography>
                                                          </TableCell>

                                                    </TableRow>

                                                    <TableRow style={rowHeight}>
                                                          {list.slice(30,35).map(function(e, key){
                                                              i = e.id + 1;;
                                                              const taamStyle = {height: '40px', color: '#40A0FF'};
                                                              const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                                              return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}} key={key}>
                                                                  <MenuItem className="menuItem" style={taamStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleTaamButtonClicked(e.id)}>
                                                                      <span className="lntPickerSelectIcon">{e.icon}</span>&nbsp;
                                                                      <span className="lntPickerSelectType">{e.taamType}</span>&nbsp;
                                                                      <span className="lntPickerSelectName">{e.name}</span>
                                                                  </MenuItem>
                                                              </TableCell>
                                                              })}
                                                    </TableRow>
                                                    <TableRow style={rowHeight}>
                                                          {list.slice(35,40).map(function(e, key){
                                                              i = e.id + 1;;
                                                              const taamStyle = {height: '40px', color: '#40A0FF'};
                                                              const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                                              return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}} key={key}>
                                                                  <MenuItem className="menuItem" style={taamStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleTaamButtonClicked(e.id)}>
                                                                      <span className="lntPickerSelectIcon">{e.icon}</span>&nbsp;
                                                                      <span className="lntPickerSelectType">{e.taamType}</span>&nbsp;
                                                                      <span className="lntPickerSelectName">{e.name}</span>
                                                                  </MenuItem>
                                                              </TableCell>
                                                              })}
                                                    </TableRow>
                                                    <TableRow style={rowHeight}>
                                                          {list.slice(40,45).map(function(e, key){
                                                              i = e.id + 1;;
                                                              const taamStyle = {height: '40px', color: '#40A0FF'};
                                                              const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                                              return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}} key={key}>
                                                                  <MenuItem className="menuItem" style={taamStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleTaamButtonClicked(e.id)}>
                                                                      <span className="lntPickerSelectIcon">{e.icon}</span>&nbsp;
                                                                      <span className="lntPickerSelectType">{e.taamType}</span>&nbsp;
                                                                      <span className="lntPickerSelectName">{e.name}</span>
                                                                  </MenuItem>
                                                              </TableCell>
                                                              })}
                                                    </TableRow>
                                                    <TableRow style={rowHeight}>
                                                          {list.slice(45,50).map(function(e, key){
                                                              i = e.id + 1;;
                                                              const taamStyle = {height: '40px', color: '#40A0FF'};
                                                              const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                                              return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}} key={key}>
                                                                  <MenuItem className="menuItem" style={taamStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleTaamButtonClicked(e.id)}>
                                                                      <span className="lntPickerSelectIcon">{e.icon}</span>&nbsp;
                                                                      <span className="lntPickerSelectType">{e.taamType}</span>&nbsp;
                                                                      <span className="lntPickerSelectName">{e.name}</span>
                                                                  </MenuItem>
                                                              </TableCell>
                                                              })}
                                                    </TableRow>
                                                    <TableRow style={rowHeight}>
                                                          {list.slice(50,55).map(function(e, key){
                                                              i = e.id + 1;;
                                                              const taamStyle = {height: '40px', color: '#40A0FF'};
                                                              const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                                              return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}} key={key}>
                                                                  <MenuItem className="menuItem" style={taamStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleTaamButtonClicked(e.id)}>
                                                                      <span className="lntPickerSelectIcon">{e.icon}</span>&nbsp;
                                                                      <span className="lntPickerSelectType">{e.taamType}</span>&nbsp;
                                                                      <span className="lntPickerSelectName">{e.name}</span>
                                                                  </MenuItem>
                                                              </TableCell>
                                                              })}
                                                    </TableRow>
                                                    <TableRow style={rowHeight}>
                                                          {list.slice(55,58).map(function(e, key){
                                                              i = e.id + 1;;
                                                              const taamStyle = {height: '40px', color: '#40A0FF'};
                                                              const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                                              return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}} key={key}>
                                                                  <MenuItem className="menuItem" style={taamStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleTaamButtonClicked(e.id)}>
                                                                      <span className="lntPickerSelectIcon">{e.icon}</span>&nbsp;
                                                                      <span className="lntPickerSelectType">{e.taamType}</span>&nbsp;
                                                                      <span className="lntPickerSelectName">{e.name}</span>
                                                                  </MenuItem>
                                                              </TableCell>
                                                              })}
                                                    </TableRow>

                                              </TableBody>
                                            </Table>
                  </div>

                  <Table size="small">
                      <TableBody style={{padding: '0px'}}>
                          <TableRow style={rowHeight}>
                            <TableCell style={{padding: '0px', borderBottom: '0', backgroundColor: '#F8F5DE'}}>
                                {noTaam}
                            </TableCell>
                            <TableCell style={{padding: '0px', borderBottom: '0', backgroundColor: '#F8F5DE'}}>
                                {anyTaam}
                            </TableCell>
                            <TableCell style={{padding: '0px', borderBottom: '0', backgroundColor: '#F8F5DE'}}>
                                {anyTaamim}
                            </TableCell>
                          <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#F8F5DE' , minWidth:'202px'}}>
                                <Typography> </Typography>
                          </TableCell>
                          </TableRow>

                      </TableBody>
                  </Table >

                      </Typography>
                    </Popover>

          </span>
       }
}

export default withLocalize(TaamPicker2D);