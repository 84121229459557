import React, { Component } from 'react';

class HistoryHelp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p> עם סיום קיץ 2018 קיבלתי עזרה חשובה מהרב משה זילברמן ממונסי. הוא מזמן יצר בסיס נתונים של Microsoft Access של מידע על שורש וחלק דיבור עבור כל הפעלים בתנ"ך.
          מידע זה היווה את הבסיס לספר <b>עמלים בתורה</b>.
          הוא הציע להשאיל לי את מסד הנתונים שלו, אך, אבוי, תוכנת ההתקנה היחידה שעדיין הייתה אצלו כללה תקליטון פגום.
          הנחמה הייתה שבנוסף לקבל גרסה מודפסת של <b>עמלים בתורה</b> הוא נתן לי בחביבות את הספר הרחב בפורמט PDF.&nbsp;
          <i>
            (בנקודה מאוחרת יותר, יצרנו תוכנית לשריטת המידע על מסד הנתונים של משה מתכני ה- PDF שלו.
            בעתיד הקרוב אנו מקווים לשלב באופן מלא את המשאב הזה שיחזק את נתוני השורש וחלק הדיבור שלנו שזקוקים מאוד לשיפור.)
          </i></p>
        <p>בסתיו התחלתי להתחבר עם אנשים כדי לחקור את פוטנציאל השוק למוצר המתוכנן שלי. מאמץ זה הוביל אותי למימוש האתגר הכרוך במיקוד למגוון רחב 
          של סוגי משתמשים פוטנציאליים. זה גם יצר תחושת דחיפות ליצור אב-טיפוס פונקציונלי של המוצר 
          שלי, כדי שיהיה לי משהו מוחשי להראות לאנשים בזמן שאני משכנע אותם בערך המוצר ובצורך בו.</p>

      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <p>As the summer of 2018 was ending, I received important help from Rabbi Moshe Silberman of Monsey.  He had 
          long ago created a Microsoft Access database of shoresh and part of speech information for all the verbs in the Tanach. 
          That information formed the basis of his Sefer Ameilim BaTorah. He offered to lend me his database but alas 
          the only installation software he still had included a corrupted floppy disk. The consolation was 
          that in addition to receiving a print version of Ameilim BaTorah he kindly gave me the voluminous sefer in PDF format. 
          <i>(At a later point, we created a program to scrape Moshe's database information from his PDF content. In the near future, we 
            hope to fully integrate that resource which will bolster our shoresh and part of speech data that sorely need improvement.)</i></p>
        <p>In the fall, I began connecting with people to 
          research the market potential for my planned product. This effort led me to realize the challenge of having to target a wide ranging 
          set of potential user types.  It also created a sense the urgency to create a functional prototype of my product so that 
          I would have something tangible to show people while convincing them of product's value and need.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default HistoryHelp;