import React, { Component, useState } from 'react';
import './css/App.css';
import './css/SimpleSearch.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
//import { Button, Input } from 'reactstrap';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import { Paper, InputBase, IconButton, Fade, Button, Typography, Popper  } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import DeleteIcon from '@material-ui/icons/Delete';
import translations from "./json/translations.json";
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import HebrewKeyboard from './HebrewKeyboard';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import * as properties from './config/properties.js';
//import { create } from 'jss';
//import preset from 'jss-preset-default';
//import rtl from 'jss-rtl';
//import { StylesProvider, jssPreset } from '@material-ui/styles';
import * as auth from './Auth';
//import FuzzyPicker from './FuzzyPicker'
import { Select, MenuItem } from '@material-ui/core';
import Dictaphone from './Dictaphone';

function encodeFilterIds(nodes, nodeString) {
    var nodeStr = (nodeString != undefined) ? nodeString : "0".repeat(44);
    nodes.forEach((node)=>{
        if (node.tableName == "Sefer" && node.isChecked)
            nodeStr = util.stuff(nodeStr, "1", node.id-1, 1);
        if(node.children){
            nodeStr = encodeFilterIds(node.children, nodeStr);
        }
    })
    return nodeStr;
}

const styles = {
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 1315,
    maxWidth: 'calc(100vw - 570px)',
    minWidth: '798px'
  },
  input: {
    marginLeft: 8,
    flex: 1,
    fullWidth: true,
    fontSize: '28px',
    direction: 'rtl !important',
  },
  popper: {
      width: 650,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  }
};

const styles2 = theme => ({
  typography: {
    padding: theme.spacing.unit * 0,
  },
  toggleContainer: {
    height: 32,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row' ,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    margin: `${theme.spacing.unit}px 0`,
    background: theme.palette.background.default,
  },
});

const theme = createMuiTheme({ //can also use 2 toggleButton containers inside a Grid element to accomplish groups on same line as is done here https://material-ui.com/lab/toggle-button/
  overrides: {
    MuiToggleButtonGroup: {
      root: {
          display: 'inline',
      },
      selected: {
          display: 'inline',
      },
    },
  },
  typography: { useNextVariants: true },
});

const sliderParent = { verticalAlign: 'middle' }; //overflow: 'hidden'
const sliderStyle = { width: 0, height: 22, marginLeft: 5 };
const dotStyle = { width: 0, height: 0, bottom: -9999, marginLeft: -9999 };

const marks = {
  0: {label: <Translate id="advanced.caption" />},
  100: {label: <Translate id="simpleSearch.caption" />},
};

class SimpleSearch extends Component {

//webServiceURL = properties.env.webServiceURL;
////webServiceURL = "http://localhost:8080" - it originally was this but found out that when hitting site from outside, this wouldn't work sinc I guess this is loaded to client browser

 constructor(props) {
    super(props);
    this.state = {
      menuValue: 1,
//      terms: '',
//      scope: 'all',
//      seq: 'seq',
      isLoading: false,
      clientIP: '',
      getPesukimWithWords: [],
      extSearchDefData: '',
      defaultLanguage: '',
      anchorEl: null,
      open: false,
      closeAdvancedDialogIsOpen: false,
      searchSliderValue: 100,
      invalidQuoteDialogOpen: false,
      searchLocation:'tanach',

    };
    //this.handleKeyUp = this.handleKeyUp.bind(this); //see below that an attempt was made at this to enforce Hebrew chars when using English keyboard layout
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleScope = this.handleScope.bind(this);
    this.handleSeq = this.handleSeq.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.handleCloseAdvancedDialogOpen = this.handleCloseAdvancedDialogOpen.bind(this);
    this.handleCloseAdvancedDialogClose = this.handleCloseAdvancedDialogClose.bind(this);
    this.handleConfirmCloseAdvancedSearch = this.handleConfirmCloseAdvancedSearch.bind(this);
    this.handleLoadSearchResults = this.handleLoadSearchResults.bind(this);
    this.handleOpenInvalidQuoteDialog = this.handleOpenInvalidQuoteDialog.bind(this);
    this.handleCloseInvalidQuoteDialog = this.handleCloseInvalidQuoteDialog.bind(this);
    this.setSearchLocation = this.setSearchLocation.bind(this);
    this.findHighestZIndex = this.findHighestZIndex.bind(this);
//    this.exactnessPropsSet = this.exactnessPropsSet.bind(this);

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}
  }

    handleOpenInvalidQuoteDialog(){
        this.setState({invalidQuoteDialogOpen: true})
    }

    handleCloseInvalidQuoteDialog(){
        this.setState({invalidQuoteDialogOpen: false})
    }

  async handleScope(event, scope) {
      if(scope){
//          this.setState({ scope });
          this.props.extSetScope(scope)
      }
  }

   async handleSeq(event, seq) {
      if(seq){
//          this.setState({ seq });
          this.props.extSetSeq(seq)
      }
   }
    componentDidUpdate(prevProps,prevState){
      if(this.props.simpleSearchKb !== prevProps.simpleSearchKb){
        this.setState({open:false})
      }
    }

    handleClick = event => {
      const { currentTarget } = event;
      if(!this.state.open){
        this.props.closeLexiconKb()
      }
      setTimeout(()=>{
        this.setState(state => ({
          anchorEl: document.getElementById("terms"), //currentTarget,
          open: !state.open,
        }))},700)
      this.findHighestZIndex();
      this.setHelpTopic("simpleSearch");
    };

  async componentDidMount() {
    //console.log('about to fetch stuff upon screen load:');
      let language = localStorage.getItem("language"); //this is working more smoothly than sessionStorage
      if(language == null || language == ''){
          const response3 = this.state.defaultLanguage == '' ? await fetch(properties.env.webServiceURL + '/BaseHaSefer/getLocale') : null;
          const ipInfo = this.state.defaultLanguage == '' ? await response3.json() : null;
          this.setLanguage(ipInfo.country == "Israel" || ["07012", "07055"].includes(ipInfo.zip) ? "he" : "en");
      }

    //console.log('completed fetch.');

      this.setState({ isLoading: false });

  }

  async handleSubmit(event) {
    this.setHelpTopic("searchResults");

    event.preventDefault();
    if(this.props.hasInvalidQuote){
        this.setState({invalidQuoteDialogOpen: true})
    } else {
        this.props.setSearchIsLoading(true);


    //    const {terms, scope, seq} = this.state;
        const {terms, scope, seq} = this.props;

        var getval = terms.replace(/\s+/g, ",").replace(/^,/, ""); //replace all whitespace with commas and remove first remaining character if it's a comma

        //var getval = item.words1+','+item.words2+','+item.words3+','+item.words4+','+item.words5+','+item.words6

        //console.log('user submitted following words:'+getval);


    //    const ipify = require('ipify');
    //    await ipify().then(ip => {
    //        document.getElementById('clientIP').innerHTML = ip;
    //        //clientIP = ip;
    //        this.setState({clientIP: ip});
    //    	console.log('oogamatonne:'+ip);
    //    });

    //var clientIP = document.getElementById('clientIP').innerHTML;
    //alert(this.clientIP);
        var filterIds = encodeFilterIds(this.props.extFilterData);

        filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
        filterIds = util.right("00000000000"+filterIds,11);

        //const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPesukimWithWords?source=bhsWebApp&hexFilterIds=' +
        //    filterIds + '&morphInflections=' + this.props.extSearchDefData + '&scope='+ scope + '&seq=' + seq + '&words='+getval)//+'&clientIP='+this.clientIP);

    /*
        const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPesukimWithTerms', {method: 'post', body: JSON.stringify(this.state)});

        const body = await response.json();
    */
        var pasukIds = []

        if(this.props.searchWithinResults){
            pasukIds = this.props.extGetCurrentPasukIds()
        }

        if(!this.props.searchIsLoading){ // prevent lag on loading screen for first search
            this.props.extOpenSearchResults()
        }



        const body = await this.props.extSubmitSearch(terms, scope, seq, filterIds, pasukIds);

        //this.setState({ getPesukimWithWords: body, isLoading: false });
        this.props.extOpenSearchResults(body); //do this to update search results grid governed by Home.js
        this.props.setSearchIsLoading(false);
    /*
        await fetch('http://localhost:8080/BaseHaSefer/getPesukimWithWords', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(jstr)
        });
        */
        //this.props.history.push('/groups');cccccccc URLEncoder.encode(myjson, "UTF-8")
    }

  }
//      const open = React.useState(false);
//      const setOpen = React.useState(false);


    extTogglePopupCallback = () => {
        this.setState(state => ({
          open: false,
        }));
    }

    extVoiceInputBuffer = (data) => {
        this.props.extSetTerms(data)
    }

    extVoiceInputChange = (before, data, after) => {
        var el = document.getElementById("terms");
        var start = el.selectionStart;
        var end = el.selectionEnd;
        el.value = (before + data + after);
        el.selectionStart = el.selectionEnd = before.length + data.length;
        el.focus();
        this.setTermsValue(el.value);
    }

    extKBChange = (data) => {
        var el = document.getElementById("terms");
        var start = el.selectionStart;
        var end = el.selectionEnd;
        var text = el.value;
        var before = text.substring(0, start);
        var after  = text.substring(end, text.length);
        el.value = (before + data + after);
//        this.setState({ terms: el.value });
        this.props.extSetTerms(el.value)
        el.selectionStart = el.selectionEnd = start + data.length;
        el.focus();
        this.setTermsValue(el.value);
    }

    extKBBackspace = () => {
        var el = document.getElementById("terms");
        var start = el.selectionStart;
        if (start > 0) {
            var end = el.selectionEnd;
            var text = el.value;
            var before = text.substring(0, start-1);
            var after  = text.substring(end, text.length);
            el.value = (before + after);
//            this.setState({ terms: el.value });
            this.props.extSetTerms(el.value)
            el.selectionStart = el.selectionEnd = start-1;
        }
        el.focus();
        this.setTermsValue(el.value);
    }

    extKBDelete = () => {
        var el = document.getElementById("terms");
        var start = el.selectionStart;
        var end = el.selectionEnd;
        var text = el.value;
        if (end < text.length) {
            var before = text.substring(0, start);
            var after  = text.substring(end+1, text.length);
            el.value = (before + after);
//            this.setState({ terms: el.value });
            this.props.extSetTerms(el.value)
            el.selectionStart = el.selectionEnd = start;
        }
        el.focus();
        this.setTermsValue(el.value);
    }

/* mje Jan 26, 2021 - tried the following in order to enforce Hebrew characters to appear even when using English keyboard layout - undid this at the end since when typed quickly English characters were still appearing
    //needs this down below for <InputBase> id=terms: onKeyUp={this.handleKeyUp}
    //var he = require('convert-layout/he'); //this was used after the imports above from npm i convert-layout

    async handleKeyUp(event) {
        if ("ABCDEFGHIKLMNOPQRSTUVWXYZאבגדהוזחטיכלמנסעפצקרשתםןץףך".indexOf(event.key) >= 0) //want to still allow entry of upper case English letters
            return;

        var newKey = he.fromEn(event.key);

        if ("אבגדהוזחטיכלמנסעפצקרשתםןץףך".indexOf(newKey) < 0)
            return;

        var start = event.target.selectionStart;
        var end = event.target.selectionEnd;
        var oldValue = event.target.value;

        // replace point and change input value
        var newValue = oldValue.slice(0, start-1) + newKey + oldValue.slice(end)
        event.target.value = newValue;

        // replace cursor
        event.target.selectionStart = event.target.selectionEnd = start; // + 1;

        event.preventDefault();
    }
*/

    async handleChange(event) {
        //this.setHelpTopic("simpleSearch");
        const target = event.target;
        var value = target.value;
        //const name = target.name;
        //let words = {...this.state.words};
        //words[name] = value;
        this.setTermsValue(value);
    }

    async setTermsValue(value) {
//        this.setState({terms: value});
        this.props.extSetTerms(value)
        this.props.extDelayUpdateBituim(value);
    }

    async handleClear(event) {
        //document.getElementById("terms").value = '';
//        this.setState({ terms: '' });
        this.props.extSetTerms('')
        this.props.extDelayUpdateBituim('');
    }

    toggleSearch(value) {
        if (value < 100) {
            //if (!auth.loggedIn()) {alert('Only logged in users can use this feature!'); return;} //this is an example of controlling access via authorization
            this.setHelpTopic("advancedLayout");
            this.setState({ searchSliderValue: value })
            this.props.extOpenAdvancedSearch();
        } else {
            this.setHelpTopic("simpleSearch");
            this.handleCloseAdvancedDialogOpen()
        }
    }

    handleCloseAdvancedDialogOpen() {
        this.setState({ closeAdvancedDialogIsOpen: true })
    }

    handleCloseAdvancedDialogClose() {
        this.setState({ closeAdvancedDialogIsOpen: false })
    }

    handleConfirmCloseAdvancedSearch(){
        this.setState({ searchSliderValue: 100 })
        this.props.extCloseAdvancedSearch()
        this.handleCloseAdvancedDialogClose()
    }

    handleLoadSearchResults(e){
        this.props.extLoadSearchResults(e);
    }
    setSearchLocation(e){
      this.setState({searchLocation:e.target.value})
      if(e.target.value==="targum")this.props.extSetBeta()
      this.props.extSetTextSource(e.target.value)
    }

    async setHelpTopic(topic) {
        localStorage.setItem("helpDialogStartupTopic", topic);
    }
    findHighestZIndex(elem) {
      var elems = document.getElementsByTagName("*");
      var highest = 0;
      for (var i = 0; i < elems.length; i++){
        var zindex=document.defaultView.getComputedStyle(elems[i],null).getPropertyValue("z-index"); 
        if ((zindex > highest) && (zindex != 'auto')){//don't put the lexicon on top of the notifications==9998 or the wordTooltip==100000090
          highest = zindex;
        }
      }
      this.setState({zIndex:parseFloat(highest)});
    }


//    exactnessPropsSet(num){
//        this.props.exactnessLevel(num);
//    }

    render() {
//    const {scope, terms, seq} = this.state;
const isHebrew = this.props.activeLanguage && this.props.activeLanguage.code === "he";

    const {scope, terms, seq} = this.props;
    const {isLoading} = this.state;
    const termsDesc = this.props.translate("terms");
    const clear = this.props.translate("clear.caption");
    const hebrewKeyboard = this.props.translate("hebrewKeyboard");
    const search = this.props.translate("search.caption")
    const loadSearchResults = this.props.translate("loadSearchResults.caption")
    const { classes } = this.props;
    const { anchorEl, open } = this.state;
    const id = open ? 'simple-popper' : null;
    var directionStyling = {}
    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
        directionStyling = {direction: 'rtl', textAlign: 'right'}
    }
//variables for tranlatable toggle buttons and slider on top of simple search bar:
    const unordered = <Translate id="unOrdered.caption" />;
    const ordered = <Translate id="inOrder.caption" />;
    const everyWord = <Translate id="everyWord.caption" />;
    const partialMatch = <Translate id="partialMatch.caption" />;
//variables for translatable tooltips for toggle buttons and slider on top of simple search:
    const orderedTitle = this.props.translate("orderedTitle.caption") ;
    const unOrderedTitle = this.props.translate("unOrderedTitle.caption");
    const everyWordTitle = this.props.translate("everyWordTitle.caption");
    const partialMatchTitle = this.props.translate("partialMatchTitle.caption");

    const voiceInputOn = this.props.translate("voiceInputOn");
    const voiceInputOff = this.props.translate("voiceInputOff");

    const scopeTitle = <Translate id="scopeTitle.caption" />;
//    const fuzzySearchTitle = this.props.translate("fuzzySearchTitle.caption");  // TAKE THIS OUT(?) IF I HAVE TRANSLATABLE TOOLTIPS FOR EACH MARK

    return <MuiThemeProvider theme={theme}><form onSubmit={this.handleSubmit}>

                    <Dialog
                      open={this.state.closeAdvancedDialogIsOpen}
                      onClose={this.handleCloseAdvancedDialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      style={directionStyling}
                    >
                      <DialogTitle id="alert-dialog-title">{<Translate id="clearAdvancedSearchPopupTitle" />}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <Translate id="clearAdvancedSearchPopupContent" />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.handleCloseAdvancedDialogClose} color="primary">
                          <Translate id="cancel.caption" />
                        </Button>
                        <Button onClick={this.handleConfirmCloseAdvancedSearch} color="primary" autoFocus helpTopic="simpleSearch">
                          <Translate id="continue.caption" />
                        </Button>
                      </DialogActions>
                    </Dialog>

           <table align="center">
             <tbody className="main-body">
                 <tr className="searchLine">
                     <td colSpan="2">
                          <div className={classes.toggleContainer} >
                              {/*  <FuzzyPicker onChange={this.exactnessPropsSet} /> */}
                             <ToggleButtonGroup className="toggleButtonGroup" value={seq} exclusive onChange={this.handleSeq} >
                                 <ToggleButton value="noseq" title={unOrderedTitle}>{unordered}</ToggleButton>
                                 <ToggleButton value="seq" title={orderedTitle}>{ordered}</ToggleButton>
                             </ToggleButtonGroup>&nbsp;&nbsp;&nbsp;
                             <ToggleButtonGroup className="toggleButtonGroup" value={scope} exclusive onChange={this.handleScope} title={scopeTitle}>
                                 <ToggleButton value="any" title={partialMatchTitle}>{partialMatch}</ToggleButton>
                                 <ToggleButton value="all" title={everyWordTitle}>{everyWord} </ToggleButton>
                             </ToggleButtonGroup>&nbsp;&nbsp;&nbsp;
                             <Select className='searchLocation'
                                  value={this.props.textSource}
                                  onChange={(e) => {this.setSearchLocation(e)}}
                                  // name="pageSelect"
                                  variant='standard'
                                  autoWidth={true}
                              >
                                  <MenuItem className='searchLocationOption' value='tanach' >תנ"ך</MenuItem>
                                  <MenuItem className='searchLocationOption' title = 'תרגום אונקלוס' value='targum' >ת' אונקלוס</MenuItem>
                              </Select>&nbsp;
                              <span style={{color:'rgba(0, 0, 0, 0.54)'}}>{this.props.translate("source")}</span>
                         </div>
                     </td>
                 </tr>
                 <tr className="searchLine">
                     <td>
                         <Paper name="box1" id="box1" className={classes.root} elevation={3} >
                           <input
                                 accept=".json"
                                 className={classes.input}
                                 id="search-results-upload"
                                 helpTopic="loadSave"
                                 multiple
                                 type="file"
                                 style={{display: 'none'}}
                                 onChange={(e) => {this.handleLoadSearchResults(e)}}
                           />
                           <IconButton className={classes.iconButton} aria-label={loadSearchResults} title={loadSearchResults} helpTopic="loadSave">
                                <label htmlFor="search-results-upload" style={{margin: '0'}}>
                                    <img src={require("./images/icons8-opened-folder-500.png")} alt={loadSearchResults} width="24" height="24" style={{opacity: '0.6', filter: 'alpha(opacity=40)'}} />
                                </label>
                           </IconButton>
                          <IconButton type="submit" className={classes.iconButton} aria-label="Search" title={search} helpTopic="searchResults"><SearchIcon /></IconButton>
                          <InputBase name="terms" id="terms" placeholder={termsDesc} className={classes.input} value={terms || ''} onChange={this.handleChange} />
                          <IconButton className={classes.iconButton} aria-label="Clear" title={clear} onClick={this.handleClear}><DeleteIcon /></IconButton>
                          <IconButton className={classes.iconButton} aria-label="Keyboard" title={hebrewKeyboard} onClick={this.handleClick}><KeyboardIcon /></IconButton>
                          <Dictaphone voiceInputOn={voiceInputOn} voiceInputOff={voiceInputOff} size="medium" textId="terms" bufferCallback={this.extVoiceInputBuffer} changeCallback={this.extVoiceInputChange} />
                          </Paper>
        <Popper id={id} open={open} anchorEl={anchorEl}style={{zIndex:this.state.zIndex+1}} transition placement="bottom-end">
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <Typography className={classes.typography}>
                    <HebrewKeyboard changeCallback={this.extKBChange} backspaceCallback={this.extKBBackspace} deleteCallback={this.extKBDelete} togglePopupCallback={this.extTogglePopupCallback}/>
                </Typography>
              </Paper>
            </Fade>
          )}
        </Popper>
                     </td>
                     <td className="caption">
                        <div style={{height: 15}} />
                        <div helpTopic={this.state.searchSliderValue === 0 ? "advancedLayout" : "simpleSearch"}>
                          <Slider id="searchType" dotStyle={dotStyle} style={sliderStyle} vertical marks={marks} step={null} included={false} onChange={this.toggleSearch} value={this.state.searchSliderValue} />
                        </div>
                     </td>
                 </tr>
             </tbody>
           </table>
        </form>


        <Dialog
          open={this.state.invalidQuoteDialogOpen}
          onClose={this.handleCloseInvalidQuoteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={directionStyling}
        >
          <DialogTitle id="alert-dialog-title">{<Translate id="invalidQuote" />}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Translate id="fixQuotes" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseInvalidQuoteDialog} color="primary">
              <Translate id="continue.caption" />
            </Button>
          </DialogActions>
        </Dialog>

        </MuiThemeProvider>;
  }
}

SimpleSearch.propTypes = {
  classes: PropTypes.object.isRequired, //onClick={this.closeSearchResults}
};

export default withStyles(styles2)(withStyles(styles)(withLocalize(SimpleSearch)));

/*
                         <b>&nbsp;<Translate id="simpleSearch.caption" /></b>
                         <br /><br />
                         <span className="page-caption-italic" onClick={this.props.extOpenAdvancedSearch}><i>&nbsp;<Translate id="advancedSearch.caption" /></i></span>

                     <Input type="hidden" name="clientIP" id="clientIP" />

                     <td><Button color="secondary" type="button" className="bhsButton" onClick={this.handleClear}><Translate id="clear.caption" /></Button></td>

                     <td><Button color="primary" type="submit" className="bhsButtonLarge"><Translate id="search.caption" /></Button></td>

                     <Input type="input" name="words1" id="words1" placeholder={word + ' 1'} className="hebrewInput" value={item.words1 || ''} onChange={this.handleChange}/>

                     <td><Input type="input" name="words6" id="words6" placeholder={word + ' 6'} className="hebrewInput" value={item.words6 || ''} onChange={this.handleChange}/></td>
                     <td><Input type="input" name="words5" id="words5" placeholder={word + ' 5'} className="hebrewInput" value={item.words5 || ''} onChange={this.handleChange}/></td>
                     <td><Input type="input" name="words4" id="words4" placeholder={word + ' 4'} className="hebrewInput" value={item.words4 || ''} onChange={this.handleChange}/></td>
                     <td><Input type="input" name="words3" id="words3" placeholder={word + ' 3'} className="hebrewInput" value={item.words3 || ''} onChange={this.handleChange}/></td>
                     <td><Input type="input" name="words2" id="words2" placeholder={word + ' 2'} className="hebrewInput" value={item.words2 || ''} onChange={this.handleChange}/></td>
*/

