import React, { Component, useState } from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import { renderToStaticMarkup } from "react-dom/server";
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import WordCloud from 'react-wordcloud';

const styles = theme => ({
  toggleContainer: {
    backgroundColor: 'transparent',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    alignItems: 'center',
    margin: `auto`,
    background: theme.palette.background.default,
  },
});

class ObjectCloud extends Component {

 constructor(props) {
    super(props);
    this.state = {

    };

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}
 }

  getCloudData(){
    var data = [];
    if(this.props.data){
      data = this.props.data;
    } else if(this.props.text) {
      const text = this.props.text;
      var textArr = text.split(" ");
      textArr.map((word) => {
        var wordIndex = data.map(e => e.text).indexOf(word);
        if(wordIndex === -1){
          data.push({
            text: word,
            value: 1
          })
        } else {
          data[wordIndex].value++
        }
      })
    }

    data.sort((a, b) => {
      return b.value - a.value;
    })

    return data;
  }

  render() {

    const wordCloudOptions = {
      colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
      enableTooltip: true,
      enableOptimizations: true,
      deterministic: true,
      fontFamily: 'impact',
      fontSizes: [15, 100],
      fontStyle: 'normal',
      fontWeight: 'normal',
      padding: 1,
      rotations: 1,
      rotationAngles: [0, 90],
      scale: 'sqrt',
      spiral: 'archimedean',
      transitionDuration: this.props.transitionDuration ? this.props.transitionDuration : 1000, //if a value was passed, set it, otherwise default to one second
    };

    return (
      <div style={{height: this.props.height, width: this.props.width, 
      // backgroundColor:`rgba(${ this.props.backgroundColor.r }, ${ this.props.backgroundColor.g }, ${ this.props.backgroundColor.b }, ${ this.props.backgroundColor.a }`
      }} helpTopic="objectCloudViewer">
        <WordCloud
          options={wordCloudOptions} 
          words={this.getCloudData()}
        />
      </div>
    );
  }
}

ObjectCloud.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withLocalize(ObjectCloud));