import React, { Component, PureComponent, useState } from 'react';
import './css/App.css';
import AppNavBar from './AppNavBar';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import * as properties from './config/properties.js';
import { Typography, Paper, Button, Drawer, Divider, List, ListItem, ListItemIcon, ListItemText, Select, MenuItem, Dialog, DialogTitle, DialogContent, 
    DialogContentText, DialogActions } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {Spinner} from 'primereact/spinner';
import { AgGridReact } from 'ag-grid-react';
import LoadingOverlay from 'react-loading-overlay';

function getAlternateRowStyle(params) {
  if (params.node.rowIndex % 2 === 0) {
      return {background: '#F0F0F0'};
  }
}

class Admin extends Component {

 constructor(props) {
    super(props);
    this.state = {
      clientIP: '',
      currentSearchResultsExpireTime: 1,
      newSearchResultsExpireTime: 1,
      currentSearchResultsClearTime: 1,
      newSearchResultsClearTime: 1,
      userRequestColumnDefs: this.getUserRequestGridColumns(),
      userRequestRowData: [],
      requestGridIsLoading: true,
      controlsPage: "resultsCaching", //"resultsCaching", "requestLog"
      requestsToDisplay: 250,
      sortRequestsBy: "timeRequested",
      sortRequestsDirection: "desc",
      resultsCacheRowData: [],
      cacheGridIsLoading: true,
      resultsCacheColumnDefs: this.getResultsCacheGridColumns(),
      systemMemoryData: {
        freeMemory: "...",
        maxMemory: "...",
        totalMemory: "...",
      },
      clearResultsDialogIsOpen: false,
      resultSessionIdsToBeCleared: null,
      selectedCacheTableRowCount: 0,
      clearEntireCacheDialogIsOpen: false,
      clearLookupCachesDialogIsOpen: false,
    };

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}

  }

  updateControlsPage(e, newPage){
    this.setState({controlsPage: newPage})
  }

  updateSortRequestsBy(e){
    var value = e.target.value;
    if(value !== this.state.sortRequestsBy){
      this.setState({
        sortRequestsBy: value
      }, () => {
        this.refreshRequestLogData();
      })
    }
  }

  updateSortRequestsDirection(e){
    var value = e.target.value;
    if(value !== this.state.sortRequestsDirection){
      this.setState({
        sortRequestsDirection: value
      }, () => {
        this.refreshRequestLogData();
      })
    }
  }

  async updateRequestsToDispaly(e){
    var value = e.target.value;
    if(value !== this.state.requestsToDisplay){
      this.setState({
        requestGridIsLoading: true, requestsToDisplay: value
      }, () => {
        this.refreshRequestLogData();
      })
    }
  }

  getUserRequestGridColumns(){
    var fakeThis = this;
    return [
              {headerName: "#", field: "requestNumber", width: 60, cellClass: "row-header-center parsha-stats-row", filter: 'agNumberColumnFilter', pinned: 'left'},
              {headerName: "ID", field: "id", width: 60, cellClass: "number-column", pinned: 'left', filter: 'agNumberColumnFilter'} ,
              {headerName: "IP ID", field: "ipAddressId", width: 75, cellClass: "number-column", pinned: 'left', filter: 'agNumberColumnFilter'} ,
              {headerName: "IP", field: "ip", width: 105, cellClass: "", pinned: 'left', filter: 'agTextColumnFilter'} ,
              {headerName: "City", field: "city", width: 110, cellClass: "", pinned: 'left', filter: 'agTextColumnFilter'} ,
              {headerName: "Alias", field: "alias", width: 107, cellClass: "", filter: 'agTextColumnFilter', pinned: 'left'} ,
              {headerName: "Source", field: "source", width: 90, cellClass: "", filter: 'agTextColumnFilter'} ,
              {headerName: "Action", field: "action", width: 142, cellClass: "", filter: 'agTextColumnFilter'} ,
              {headerName: "Hex Filter Ids", field: "hexFilterIds", width: 103, cellClass: "", filter: 'agTextColumnFilter'},
              {headerName: "Morph Inflections", field: "morphInflections", width: 117, cellClass: "", filter: 'agTextColumnFilter'} ,
              {headerName: "Other Parameters", field: "otherParameters", width: 370, cellClass: "", filter: 'agTextColumnFilter'} ,
              {headerName: "Rows Returned", field: "resultsCount", width: 107, cellClass: "number-column", filter: 'agNumberColumnFilter'} ,
              {headerName: "Time Requested", field: "timeRequested", width: 133, cellClass: "", filter: 'agTextColumnFilter'} ,
              {headerName: "Time Completed", field: "timeCompleted", width: 133, cellClass: "", filter: 'agTextColumnFilter'},
              {headerName: "Duration", field: "duration", width: 105, cellClass: "number-column", filter: 'agNumberColumnFilter'}
           ];
}

getResultsCacheGridColumns(){
  var fakeThis = this;
  return [
            {headerName: "-/+", colId: 'toggleResultsButtonColumn', width: 24, cellClass: "row-header-center parsha-viewer-button-cell parsha-stats-row", filter: 'agNumberColumnFilter', cellRenderer: function (params) {return fakeThis.toggleButtonCellRenderer(params)}},
            {headerName: "#", field: "id", width: 60, cellClass: "row-header-center parsha-stats-row", filter: 'agNumberColumnFilter'},
            {headerName: "Session ID", field: "sessionId", width: 100, cellClass: "number-column", filter: 'agNumberColumnFilter'} ,
            {headerName: "Time Requested", field: "timestamp", width: 133, cellClass: "", filter: 'agNumberColumnFilter', cellRenderer: function(params) {return fakeThis.timestampCellToDateTime(params);}} ,
            {headerName: "Total Results", field: "resultsCount", width: 105, cellClass: "number-column", filter: 'agNumberColumnFilter'} ,
            {headerName: "Actions", width: 135, filter: 'agTextColumnFilter', cellClass: "row-header-center clear-from-cache-button-cell parsha-stats-row", cellRenderer: function (params) {return fakeThis.singleClearButtonCellRenderer(params)}},
         ];
}

toggleButtonCellRenderer = (params) => {
  var cellContents = document.createElement('div');
  if(!params.data.resultsAreOpen){
      cellContents.innerHTML = '+';
  } else if (params.data.resultsAreOpen){
      cellContents.innerHTML = '-';
  }
  cellContents.className = 'parsha-viewer-button-cell-text';
  cellContents.style.width = 16;
  return cellContents;
}

singleClearButtonCellRenderer = (params) => {
  var cellContents = document.createElement('div');
  cellContents.innerHTML = 'Clear from cache';
  cellContents.className = 'parsha-viewer-button-cell-text';
  cellContents.style.width = 16;
  return cellContents;
}

async handleResultsCacheCellClicked(e){
  if (e.colDef.headerName === "-/+"){
    this.toggleResultsRow(e);
  } else if (e.colDef.headerName === "Actions"){
    this.confirmClearSingleResult(e);
  }

 this.updateSelectedCacheTableRowCount();
}

updateSelectedCacheTableRowCount(){
  if(this.resultsCacheApi){
    var selectedCacheTableRowCount = 0;
    for(let i = 0; i < Object.keys(this.resultsCacheApi.selectionController.selectedNodes).length; i++){
      if(this.resultsCacheApi.selectionController.selectedNodes[Object.keys(this.resultsCacheApi.selectionController.selectedNodes)[i]]){
        selectedCacheTableRowCount++;
      }
    }
    this.setState({
      selectedCacheTableRowCount
    })
  }
}

confirmClearSingleResult(e){
  this.openClearResultsDialog();
  this.setState({
    resultSessionIdsToBeCleared: [e.data.sessionId]
  })
}

confirmClearMultipleResults(e){
  var sessionIds = [];
  var selectedNodes = this.resultsCacheApi.selectionController.selectedNodes;
  var selectedNodeKeys = Object.keys(selectedNodes);
  for(let i = 0; i < selectedNodeKeys.length; i++){
    if(selectedNodes[selectedNodeKeys[i]]){
      var sessionId = selectedNodes[selectedNodeKeys[i]].data.sessionId;
      sessionIds.push(sessionId)
    }
  }
  this.openClearResultsDialog();
  this.setState({
    resultSessionIdsToBeCleared: sessionIds
  })
}

openClearResultsDialog(){
  this.setState({
    clearResultsDialogIsOpen: true
  })
}

closeClearResultsDialog(){
  this.setState({
    clearResultsDialogIsOpen: false
  })
}

openClearEntireCacheDialog(){
  this.setState({
    clearEntireCacheDialogIsOpen: true
  })
}

closeClearEntireCacheDialog(){
  this.setState({
    clearEntireCacheDialogIsOpen: false
  })
}

openClearLookupCacheDialog(){
  this.setState({
    clearLookupCachesDialogIsOpen: true
  })
}

closeClearLookupCacheDialog(){
  this.setState({
    clearLookupCachesDialogIsOpen: false
  })
}

  async clearLookupCaches() {
      this.closeClearLookupCacheDialog();
      var x = await fetch(properties.env.webServiceURL + '/BaseHaSefer/clearLookupCaches');
  }

async clearEntireResultsCache(){
  this.closeClearEntireCacheDialog();
  var params = {
    sessionIds: [],
    willClearAll: [true]
  }
  const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/clearResultsFromCache', {method: 'post', body: JSON.stringify(params)});
  this.refreshCacheTable();
}

async clearResultsFromCache(){
  var params = {
    sessionIds: this.state.resultSessionIdsToBeCleared,
    willClearAll: [false]
  }
  const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/clearResultsFromCache', {method: 'post', body: JSON.stringify(params)});
  this.setState({
    resultSessionIdToBeCleared: null, clearResultsDialogIsOpen: false,
  }, () => {
    this.refreshCacheTable();
    this.updateSelectedCacheTableRowCount();
  })
}

async refreshCacheTable(){
  this.setState({
    cacheGridIsLoading: true
  }, async () => {
    var resultsCacheData = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getResultsCache');
    var resultsCacheDataBody = await resultsCacheData.json();
    for(let i = 0; i < resultsCacheDataBody.length; i++){
      var currResult = resultsCacheDataBody[i];
      currResult["id"] = i+1;
      currResult.resultsAreOpen = false;
      
    }
    this.setState({
      resultsCacheRowData: resultsCacheDataBody, cacheGridIsLoading: false
    }, () => {
      this.updateSelectedCacheTableRowCount();
    })
  })
}

cancelClearSingleResult(e){
  this.setState({
    resultSessionIdsToBeCleared: null
  }, () => {
    this.closeClearResultsDialog();
  })
}


toggleResultsRow(e){
  var clickedRowSessionId = e.data.sessionId;

  if(e.data.resultsAreOpen === false){
      //open the results
      var resultsRowData = {
        results: e.data.results,
        isResultsRow: true,
        sessionId: clickedRowSessionId,
        id: e.data.id,
        timestamp: e.data.timestamp,
        resultsCount: e.data.resultsCount,
      };

      var newRowData = this.state.resultsCacheRowData;
      for(let i = 0; i < newRowData.length; i++){
          let currData = newRowData[i];
          if(currData.sessionId === clickedRowSessionId && !currData.isResultsRow){
              currData.resultsAreOpen = true;

              var firstHalf = newRowData.slice(0,i);
              var secondHalf = newRowData.slice(i+1, newRowData.length);
              newRowData = firstHalf.concat(currData).concat(resultsRowData).concat(secondHalf);
          }
      }
      this.setState({
        // openResultsCount: this.state.openResultsCount+1,
        resultsCacheRowData: newRowData
      }, () => {
          this.resultsCacheApi.setRowData(this.state.resultsCacheRowData)
          this.resultsCacheApi.redrawRows();

      });
  } else if (e.data.resultsAreOpen === true) {
      //close the results
      var index = this.state.resultsCacheRowData.findIndex(item => {
        return item.isResultsRow === true && item.sessionId === clickedRowSessionId;
      });

      var newResultsInfoRow = this.state.resultsCacheRowData[index-1];
      newResultsInfoRow.resultsAreOpen = false;

      var firstHalf = this.state.resultsCacheRowData.slice(0, index-1);
      var secondHalf = this.state.resultsCacheRowData.slice(index+1, this.state.resultsCacheRowData.length);
      var newRowData = firstHalf.concat(newResultsInfoRow).concat(secondHalf);

      this.setState({
        // openResultsCount: this.state.openResultsCount-1,
        resultsCacheRowData: newRowData
      }, () => {
          this.resultsCacheApi.setRowData(this.state.resultsCacheRowData)
          this.resultsCacheApi.redrawRows();
      });
  }
}

timestampCellToDateTime(params){
  var milliseconds = params.value;
  var dateTime = this.millisecondsToDateTime(milliseconds)
  return dateTime;
}

millisecondsToDateTime(milliseconds){
  var dateTime = new Date(milliseconds);
  var day = dateTime.getDate();
  var month = dateTime.getMonth() + 1;
  var year =  dateTime.getFullYear();
  var time = dateTime.toString().split(" ")[4];
  if(day.toString().length === 1){
    day = "0" + day;
  }
  if(month.toString().length === 1){
    month = "0" + month;
  }
  var formattedDateTime = `${month}/${day}/${year} ${time}`;

  return formattedDateTime;
}

userRequestGridReady = params => {
  this.requestsApi = params.api;
  this.requestsColumnApi = params.columnApi;

  var fakeThis = this
  this.requestsApi.forEachNode( function (rowNode) {
      rowNode.setRowHeight(0);
  })

  this.requestsApi.forEachNode( function (rowNode) {
      rowNode.setRowHeight(fakeThis.state.resultsRowHeight);
  })
  this.requestsApi.onRowHeightChanged();

};

resultsCacheGridReady = params => {
  this.resultsCacheApi = params.api;
  this.resultsCacheColumnApi = params.columnApi;
  this.resultsCacheGridOptions = this.resultsCacheApi.gridOptionsWrapper.gridOptions;
  this.resultsCacheGridOptions.fullWidthCellRenderer = (params) => {return this.getResultsCacheResultsTable(params)}
  this.resultsCacheGridOptions.getRowHeight = (params)  => {return this.getResultsCacheRowHeight(params)};
  this.resultsCacheGridOptions.isFullWidthCell = (rowNode) => {return rowNode.data.isResultsRow};

  var fakeThis = this
  this.resultsCacheApi.forEachNode( function (rowNode) {
      rowNode.setRowHeight(0);
  })

  this.resultsCacheApi.forEachNode( function (rowNode) {
      rowNode.setRowHeight(fakeThis.state.resultsRowHeight);
  })
  this.resultsCacheApi.onRowHeightChanged();

};

getResultsCacheRowHeight(params){
  if(!params.data.isResultsRow){
    return 25;
  } else {
    return params.data.results.length*26 + 16;
  }
}

getResultsCacheResultsTable(params){

  var cellContents = document.createElement('div');
  var results = params.data.results;
  var resultsTable = '<table class="parsha-viewer-text-table">';
  for(let i = 0; i < results.length; i++){
      //removing spaces from location so they can be used as attribute in HTML
       var noSpaceLocation = results[i].location;
       while(noSpaceLocation.includes(" ")){
            noSpaceLocation = noSpaceLocation.replace(" ", "_");
       }
       var makorArray = results[i].location.split(':');
       var seferPerekPasuk = `${makorArray[0]}:${makorArray[1]}:${makorArray[2]}`
       var resultRow =    `<tr pasukId=${results[i].pasukId} class='text-cell-pasuk-row'>` +
                         `<td class="results-cache-result-table-makor" location=${noSpaceLocation} >${seferPerekPasuk}</td>` +
                         `<td class="parsha-viewer-text-table-text">${results[i].text}</td>` +
                       '</tr>';
       resultsTable += resultRow;
  }
  resultsTable += '</table>';
  cellContents.innerHTML = resultsTable;
  cellContents.className = `results-cache-results-cell`;
  cellContents.style.fontSize = '14px' //`${(this.state.fontSizeLevel * 2) + 12}px`; // ranges from 14 to 24
  // cellContents.oncontextmenu = (e) => {this.handleDoubleRightClick(e)};
  return cellContents;
}

  setNewSearchResultsExpireTimeState(e){
    if(!isNaN(e.value)){
      this.setState({
        newSearchResultsExpireTime: e.value
      })
    }
  }

  async updateSearchResultsExpireTime(){
    var newTime = this.state.newSearchResultsExpireTime;
    var newTimeResponse = await fetch(properties.env.webServiceURL + '/BaseHaSefer/setSearchResultsExpireTime?newTime=' + newTime);
    var newTimeBody = await newTimeResponse.json();
    this.setState({
      currentSearchResultsExpireTime: newTimeBody,
      newSearchResultsExpireTime: newTimeBody
    })
  }

  async setNewSearchResultsClearTimeState(e){
    if(!isNaN(e.value)){
      this.setState({
        newSearchResultsClearTime: e.value
      })
    }
  }

  async updateSearchResultsClearTime(){
    var newTime = this.state.newSearchResultsClearTime;
    var newTimeResponse = await fetch(properties.env.webServiceURL + '/BaseHaSefer/setSearchResultsClearTime?newTime=' + newTime);
    var newTimeBody = await newTimeResponse.json();
    this.setState({
      currentSearchResultsClearTime: newTimeBody,
      newSearchResultsClearTime: newTimeBody
    })
  }

  formatRequestDataBody(userRequestDataBody){
    for(let i = 0; i < userRequestDataBody.length; i++){
      var currRequest = userRequestDataBody[i]
      currRequest.requestNumber = i+1;

      //calculate duration before reformatting timeRequested, timeCompleted
      if(![currRequest["timeRequested"], currRequest["timeCompleted"]].includes(null)){

        var timeRequested = currRequest["timeRequested"];
        var timeCompleted = currRequest["timeCompleted"];
        var durationMilliseconds = Date.parse(timeCompleted) - Date.parse(timeRequested);
        var seconds = durationMilliseconds/1000;

        seconds = (Math.round(seconds * 100) / 100).toString();
        if(seconds.indexOf(".") === -1){
          seconds = seconds + ".00";
        }
        if(seconds.split(".")[1].length === 1){
          seconds = seconds + "0";
        }

        currRequest["duration"] = seconds;
      }

      for(let j = 0; j < Object.keys(currRequest).length; j++){
        var currKey = Object.keys(currRequest)[j];
        if(currRequest[currKey] === null){
          currRequest[currKey] = "NULL";
        }
        if (["timeRequested","timeCompleted"].includes(currKey)) {
          if(currRequest[currKey] !== "NULL"){
            var milliseconds = Date.parse(currRequest[currKey]);
            var dateTime = this.millisecondsToDateTime(milliseconds);
            currRequest[currKey] = dateTime;
          }
        }
      }
    }
    return userRequestDataBody
  }

  refreshCacheData(){
    this.setState({
      cacheGridIsLoading: true
    }, async () => {
      var searchResultsExpireTime = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getSearchResultsExpireTime');
      var searchResultsExpireTimeBody = await searchResultsExpireTime.json();
      this.setState({
        currentSearchResultsExpireTime: searchResultsExpireTimeBody,
        newSearchResultsExpireTime: searchResultsExpireTimeBody
      })
  
      var searcResultsClearTime = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getSearchResultsClearTime');
      var searcResultsClearTimeBody = await searcResultsClearTime.json();
      this.setState({
        currentSearchResultsClearTime: searcResultsClearTimeBody,
        newSearchResultsClearTime: searcResultsClearTimeBody
      })
  
      var resultsCacheData = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getResultsCache');
      var resultsCacheDataBody = await resultsCacheData.json();
      for(let i = 0; i < resultsCacheDataBody.length; i++){
        var currResult = resultsCacheDataBody[i];
        currResult["id"] = i+1;
        currResult.resultsAreOpen = false; 
      }

      var systemMemoryData = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getSystemMemoryData');
      var systemMemoryDataBody = await systemMemoryData.json();

      this.setState({
        resultsCacheRowData: resultsCacheDataBody, cacheGridIsLoading: false, systemMemoryData: systemMemoryDataBody
      }, () => {
        this.updateSelectedCacheTableRowCount();
      })
    })
  }

  refreshRequestLogData(){
    this.setState({
      requestGridIsLoading: true
    }, async () => {
      var url = properties.env.webServiceURL + '/BaseHaSefer/getUserRequestData';
      var requestsToDisplay = this.state.requestsToDisplay;
      if (typeof(requestsToDisplay) === "number") {
        url += "?requestCount=" + requestsToDisplay;
      } else if (typeof(requestsToDisplay) === "string") {
        url += "?getAllRequests=true";
      }
      url += "&sortBy=" + this.state.sortRequestsBy;
      url += "&sortDirection=" + this.state.sortRequestsDirection;
  
      var userRequestData = await fetch(url);
      var userRequestDataBody = await userRequestData.json();
      userRequestDataBody = this.formatRequestDataBody(userRequestDataBody);
  
      this.setState({
        userRequestRowData: userRequestDataBody, requestGridIsLoading: false
      })
    })
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if (prevState.controlsPage !== this.state.controlsPage) {
      if (this.state.controlsPage === "resultsCaching"){
        this.refreshCacheData();
      } else if (this.state.controlsPage === "requestLog"){
        this.refreshRequestLogData();
      }
    }
  }

  async componentDidMount() {

      let language = localStorage.getItem("language"); //this is working more smoothly than sessionStorage

      if (language == null || language == '') {
          const response3 = this.state.defaultLanguage == '' ? await fetch(properties.env.webServiceURL + '/BaseHaSefer/getLocale') : null;
          const ipInfo = this.state.defaultLanguage == '' ? await response3.json() : null;
          language = (ipInfo.country == "Israel" || ["07012", "07055"].includes(ipInfo.zip) ? "he" : "en");
          localStorage.setItem("language", language);
          this.setLanguage(language); //need to do this here for the site - individual child pages will do this on their own as they load
      }

      this.refreshCacheData();
      this.refreshRequestLogData();
  }



  render() {
    var directionStyling = {}
    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
        directionStyling = {direction: 'rtl', textAlign: 'right'}
    }

    const onlyAdmins = this.props.translate("onlyAdmins");
    const loginIfAdmin = this.props.translate("loginIfAdmin");
    const loadingText = this.props.translate("loading");

    var maxMemory = this.state.systemMemoryData.maxMemory;
    var maxMemoryMB = maxMemory/1000000;
    var maxMemoryMBRounded = !isNaN(Math.floor(maxMemoryMB * 100) / 100) ? Math.floor(maxMemoryMB * 100) / 100 : "...";
    var freeMemory = this.state.systemMemoryData.freeMemory;
    var freeMemoryMB = freeMemory/1000000;
    var freeMemoryMBRounded = !isNaN(Math.floor(freeMemoryMB * 100) / 100) ? Math.floor(freeMemoryMB * 100) / 100 : "...";
    var totalMemory = this.state.systemMemoryData.totalMemory;
    var totalMemoryMB = totalMemory/1000000;
    var totalMemoryMBRounded = !isNaN(Math.floor(totalMemoryMB * 100) / 100) ? Math.floor(totalMemoryMB * 100) / 100 : "...";

    var clearMultipleFromCacheEnabled = this.state.selectedCacheTableRowCount > 0;

    var isAdmin = this.props.userRole === "Administrator";
    // isAdmin = true

    var updateSearchResultsExpireTimeDisabled = this.state.currentSearchResultsExpireTime === this.state.newSearchResultsExpireTime; 
    var updateSearchResultsClearTimeDisabled = this.state.currentSearchResultsClearTime === this.state.newSearchResultsClearTime; 
    
    var controlsPageTitle = "";
    if(this.state.controlsPage === "resultsCaching"){
      controlsPageTitle = "Caches";
    } else if (this.state.controlsPage === "requestLog"){
      controlsPageTitle = "Request Log";
    }

    return (
    <div id='home-scroll-container' style={{height: '100vh', width: '100%', direction: 'rtl', overflowX: 'auto'}}><div id='home-component' style={{direction: 'ltr'}}>
        <AppNavBar checkAuthentication={this.props.checkAuthentication} login={this.props.login} authenticated={this.props.authenticated} userinfo={this.props.userinfo}
            logout={this.props.logout} extOpenTaamParser={this.openTaamParser} userInfo={this.props.userInfo} authenticated={this.props.authenticated} 
            profile={this.props.profile} userRole={this.props.userRole}
            toggleTanachViewer={((boolean, pasukId) => {this.toggleTanachViewer(boolean, pasukId)})} />
       
        {!isAdmin &&
          <div className="admin-controls-outer-container">
            <Paper elevation={0} className='admin-controls-login-paper'>
              <Typography variant="h6" gutterBottom className="admin-controls-login-message" style={{direction: directionStyling.direction}}>
                {onlyAdmins} <br />  {loginIfAdmin}
              </Typography>
            </Paper>
          </div>
        }
        {isAdmin && <>

          <Drawer
            className={'classes.drawer'}
            variant="permanent"
            classes={{
              paper: 'admin-drawer-paper',
            }}
          >
            <div className={'classes.toolbar'} />
            <Typography variant="h5" gutterBottom align="center" style={{margin: '17px', fontWeight: 600}}>
              Admin Controls
            </Typography>
            <Divider />
            <List>
              <ListItem button key={0} onClick={(e) => {this.updateControlsPage(e, "resultsCaching")}} selected={this.state.controlsPage === "resultsCaching"}>
                <ListItemIcon><KeyboardArrowRightIcon /></ListItemIcon>
                <ListItemText primary={"Caches"} />
              </ListItem>
              <ListItem button key={1} onClick={(e) => {this.updateControlsPage(e, "requestLog")}} selected={this.state.controlsPage === "requestLog"}>
                <ListItemIcon><KeyboardArrowRightIcon /></ListItemIcon>
                <ListItemText primary={"Request Log"} />
              </ListItem>
            </List>
          </Drawer>

          <div className="admin-controls-outer-container">        

            <div className="admin-controls-page-container">
            <Typography variant="h6" gutterBottom className="admin-controls-page-title" align='left'>
              {controlsPageTitle}
            </Typography>
              {this.state.controlsPage === "resultsCaching" && <>

            <span>
              <Button variant="outlined" size="small" onClick={(e) => {this.refreshCacheData(e)}}>
                Refresh Results Cache
              </Button>
              &nbsp;&nbsp;
              <Button variant="outlined" size="small" onClick={(e) => {this.openClearLookupCacheDialog()}}>
                Clear Lookup Caches
              </Button>
            </span>
              <Typography variant="body1" gutterBottom className="admin-controls-section-title" align='left'>
                Options
              </Typography>

                <Paper elevation={3} className='admin-controls-section-paper' style={{width: '650px'}}>

                    <div className='admin-controls-outer-question-container'>
                      <div className='admin-controls-inner-question-container'>
                        <Typography variant="body1" component="p">
                          Search results session limit: &nbsp;&nbsp;
                          <Spinner name="repetitions" id="admin-search-results-expiration-spinner" className="content-section implementation" value={this.state.newSearchResultsExpireTime} onChange={(e) => this.setNewSearchResultsExpireTimeState(e)} min={.025} step={0.25} />
                          &nbsp; minute(s) &nbsp;
                          <span
                            className="admin-controls-current-value"
                            style={{
                              visibility: `${updateSearchResultsExpireTimeDisabled ? 'hidden' : 'visible'}`
                            }}
                          >
                            current value: {this.state.currentSearchResultsExpireTime}
                          </span>
                        </Typography>
                      </div>
                      <div className='admin-controls-refresh-container'>
                        <Button onClick={() => {this.updateSearchResultsExpireTime()}} color="primary" variant="contained" disabled={updateSearchResultsExpireTimeDisabled}>
                          Update
                        </Button>
                      </div>
                    </div>
                    <hr />

                    <div className='admin-controls-outer-question-container'>
                      <div className='admin-controls-inner-question-container'>
                        <Typography variant="body1" component="p">
                          Clear expired search results every: &nbsp;&nbsp;
                          <Spinner name="repetitions" id="admin-search-results-expiration-spinner" className="content-section implementation" value={this.state.newSearchResultsClearTime} onChange={(e) => this.setNewSearchResultsClearTimeState(e)} min={.025} step={0.25} />
                          &nbsp; minute(s) &nbsp;
                          <span
                            className="admin-controls-current-value"
                            style={{
                              visibility: `${updateSearchResultsClearTimeDisabled ? 'hidden' : 'visible'}`
                            }}
                          >
                            current value: {this.state.currentSearchResultsClearTime}
                          </span>
                        </Typography>
                      </div>
                      <div className='admin-controls-refresh-container'>
                        <Button onClick={() => {this.updateSearchResultsClearTime()}} color="primary" variant="contained" disabled={updateSearchResultsClearTimeDisabled}>
                          Update
                        </Button>
                      </div>
                    </div>

                </Paper>

                <Typography variant="body1" gutterBottom className="admin-controls-section-title" align='left'>
                  Search Results Caches
                </Typography>

                <LoadingOverlay
                  active={this.state.cacheGridIsLoading}
                  spinner
                  text={loadingText}
                >
                  <div
                    className="ag-theme-balham"
                    id="user-requests-grid"
                    style={{
                      height: '400px',
                      margin: '8px 0px',
                    }}
                  >
                    <span className="results-cache-memory-data">
                      <b>Free Memory:</b> {freeMemoryMBRounded}MB &nbsp; <b>Max Memory:</b> {maxMemoryMBRounded}MB &nbsp; <b>Total Memory:</b> {totalMemoryMBRounded}MB
                    </span>
                    <AgGridReact
                      onGridReady={this.resultsCacheGridReady}
                      defaultColDef={{resizable: true}}
                      columnDefs={this.state.resultsCacheColumnDefs}
                      onCellClicked={(e) => {this.handleResultsCacheCellClicked(e)}}
                      rowData={this.state.resultsCacheRowData}
                      enableColResize={true}
                      enableSorting={true}
                      enableFilter={true}
                      suppressScrollOnNewData={true}
                      rowSelection={'multiple'}
                      rowMultiSelectWithClick={true}
                      >
                    </AgGridReact>
                  </div>
                </LoadingOverlay>
                <Button variant="outlined" size="small" className="admin-cache-option" disabled={!clearMultipleFromCacheEnabled} onClick={(e) => {this.confirmClearMultipleResults(e)}}>
                  Clear selected from cache
                </Button>
                <Button variant="outlined" size="small" className="admin-cache-option" disabled={this.state.resultsCacheRowData.length === 0} onClick={(e) => {this.openClearEntireCacheDialog(e)}}>
                  Clear entire cache
                </Button>
             </>}
              {this.state.controlsPage === "requestLog" && <>

                <Button variant="outlined" size="small" onClick={(e) => {this.refreshRequestLogData(e)}}>
                  Refresh
                </Button>

                <LoadingOverlay
                  active={this.state.requestGridIsLoading}
                  spinner
                  text={loadingText}
                >
                  <div
                    className="ag-theme-balham"
                    id="user-requests-grid"
                    style={{
                      height: '400px',
                      margin: '8px 0px',
                    }}
                  >
                    <AgGridReact
                      onGridReady={this.userRequestGridReady}
                      defaultColDef={{resizable: true}}
                      columnDefs={this.state.userRequestColumnDefs}
                      rowData={this.state.userRequestRowData}
                      getRowStyle = {getAlternateRowStyle}
                      enableColResize={true}
                      enableSorting={true}
                      enableFilter={true}
                      suppressScrollOnNewData={true}>
                    </AgGridReact>
                  </div>
                </LoadingOverlay>
                <Typography variant="body1" className="request-log-option">
                  Requests to display: &nbsp;&nbsp;
                  <Select
                    value={this.state.requestsToDisplay}
                    onChange={(e) => {this.updateRequestsToDisplay(e)}}
                    name="pageSelect"
                    displayEmpty
                    autoWidth={true}
                  >
                    <MenuItem value={250}>250</MenuItem>
                    <MenuItem value={500}>500</MenuItem>
                    <MenuItem value={1000}>1000</MenuItem>
                    <MenuItem value={2000}>2000</MenuItem>
                    <MenuItem value={"all"}>Show All</MenuItem>
                  </Select>
                </Typography>
                <Typography variant="body1" className="request-log-option">
                  Sort By: &nbsp;&nbsp;
                  <Select
                    value={this.state.sortRequestsBy}
                    onChange={(e) => {this.updateSortRequestsBy(e)}}
                    name="pageSelect"
                    displayEmpty
                    autoWidth={true}
                  >
                    <MenuItem value={"timeRequested"}>Time Requested</MenuItem>
                    <MenuItem value={"timeCompleted"}>Time Completed</MenuItem>
                    <MenuItem value={"duration"}>Duration</MenuItem>
                    <MenuItem value={"ip"}>IP</MenuItem>
                    <MenuItem value={"rowsReturned"}>Rows Returned</MenuItem>
                  </Select>
                </Typography>
                <Typography variant="body1" className="request-log-option">
                  Asc/Desc: &nbsp;&nbsp;
                  <Select
                    value={this.state.sortRequestsDirection}
                    onChange={(e) => {this.updateSortRequestsDirection(e)}}
                    name="pageSelect"
                    displayEmpty
                    autoWidth={true}
                  >
                    <MenuItem value={"desc"}>Descending</MenuItem>
                    <MenuItem value={"asc"}>Ascending</MenuItem>
                  </Select>
                </Typography>
              </>}
            </div>
          </div>

        </>}

        <Dialog
          open={this.state.clearResultsDialogIsOpen}
          onClose={(e) => {this.cancelClearSingleResult(e)}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The selected result set(s) will be cleared from the cache.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => {this.cancelClearSingleResult(e)}} color="primary">
              Cancel
            </Button>
            <Button onClick={(e) => {this.clearResultsFromCache(e)}} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.clearEntireCacheDialogIsOpen}
          onClose={(e) => {this.closeClearEntireCacheDialog(e)}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The entire results cache will be cleared.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => {this.closeClearEntireCacheDialog(e)}} color="primary">
              Cancel
            </Button>
            <Button onClick={(e) => {this.clearEntireResultsCache(e)}} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.clearLookupCachesDialogIsOpen}
          onClose={(e) => {this.closeClearLookupCacheDialog()}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The lookup caches will be cleared.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => {this.closeClearLookupCacheDialog()}} color="primary">
              Cancel
            </Button>
            <Button onClick={(e) => {this.clearLookupCaches()}} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>

      </div></div>
    );
  }
}

export default withLocalize(Admin);