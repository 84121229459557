import React, { Component } from 'react';

class HistoryBeta extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p>בסוף 2020, האתר הגיע לרמה של פונקציונליות וחוסן כדי להיחשב למהדורה מלאה.
         ככל שהאתר גדל ומשתפר, תוכן פונקציונלי שנמצא בשלב ניסוי ועדיין צריך להשתכלל יסומן באמצעות סמל "בטא" אדום שיופיע לזמן קצר בראש העמוד.</p>

      </>
    } else if (langCode === "en") {
      retVal = <>

        <p>In late 2020, the site attained a level of functionality and robustness so as to be considered a full release.
           As the site grows and improves, functional content that is in the experimental stage and still needs to be perfected will be indicated by a red "beta" symbol that will appear briefly at the top of the page.</p>

      </>
    }
    return retVal;
  }

  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default HistoryBeta;