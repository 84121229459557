import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { Link, withRouter } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import { Button as BootstrapButton } from 'reactstrap';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Paper } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
//import FinderHeader from './FinderHeader';
import { css } from '@emotion/core';
//import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import * as properties from './config/properties.js';
import { Menu, Item, Separator, Submenu, MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import ContextMenu from './ContextMenu';
import TaamParser from './TaamParser';
import { contextMenu } from 'react-contexify';
import HomeIcon from '@material-ui/icons/Home';
import LinkIcon from '@material-ui/icons/Link';
import { IconButton  } from '@material-ui/core';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const styles = theme => ({
  toggleContainer: {
    height: 48,
    backgroundColor: 'transparent',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: `${theme.spacing.unit}px 0`,
    background: theme.palette.background.default,
  },
});

//todo this is repeated elsewhere - combine it
function encodeFilterIds(nodes, nodeString) {
    var nodeStr = (nodeString != undefined) ? nodeString : "0".repeat(44);
    nodes.forEach((node)=>{
        if (node.tableName == "Sefer" && node.isChecked)
            nodeStr = util.stuff(nodeStr, "1", node.id-1, 1);
        if(node.children){
            nodeStr = encodeFilterIds(node.children, nodeStr);
        }
    })
    return nodeStr;
}

function getAlternateRowStyle(params) {
    if (params.node.rowIndex % 2 === 0) {
        return {background: '#F0F0F0'};
    }
}

function BaseParameters() {
  this.source = null;
  this.clientIP = null;
  this.morphInflections = null;
  this.filterIds = null;
  this.limit = 2100;
  this.minimumFrequency = 3;
  this.skipWords = [];
  this.nGrams = null;
  this.sortResultsBy = null;
  this.resultsToDisplay = null;
  this.namedLocations =  []; //NamedLocation()
  this.pasukRangeLocations = []; //PerekPasukRange()
  this.textSource = 1;
}

class FrequencyViewer extends Component {

//webServiceURL = properties.env.webServiceURL;

gridColumns1 = [ //, type: "numericColumn" merely used as trick for getting align-right until learn a better solution - align: "right"  text, loxcation, teiva, row/middle
    {headerName: "#", field: "rowNum", width: 60, headerClass: "ag-header-cell-center", cellClass: "row-header-center", sortable: "true", filter: 'agNumberColumnFilter'},
    {headerName: "בטוי", field: "testField", width: 130, cellClass: "row-header-large", sortable: "true", cellRenderer: function(params) {return util.decorateNekudosTaamim(params.data.testField);}},
    {headerName: "פסוק", field: "location", width: 100, cellClass: "row-thin-small", sortable: "true"} ,
    {headerName: "טקסט", field: "pasukText", width: 1170, cellClass: "row-thin", cellRenderer: function(params) {return util.decorateText(params.data.pasukText,params.data.pasukText.split(" "), params.data.matchIndex,null,null,true,null,null,null,null,true);}}
];

gridColumns2 = [ //, type: "numericColumn" merely used as trick for getting align-right until learn a better solution - align: "right"  text, loxcation, teiva, row/middle
    {headerName: "טקסט", field: "pasukText1", width: 535, headerClass: "ag-header-cell-left", cellClass: "row-thin-left", cellRenderer: function(params) {return util.decorateText(params.data.pasukText1,params.data.pasukText1.split(" "), params.data.matchIndex1,null,null,true,null,null,null,null,true);}} ,
    {headerName: "פסוק", field: "location1", width: 100, cellClass: "row-thin-small", sortable: "true"} ,
    {headerName: "#", field: "rowNum", width: 60, headerClass: "ag-header-cell-center", cellClass: "row-header-center", sortable: "true", filter: 'agNumberColumnFilter'},
    {headerName: "בטוי", field: "testField", width: 130, cellClass: "row-header-large", sortable: "true", cellRenderer: function(params) {return util.decorateNekudosTaamim(params.data.testField);}},
    {headerName: "פסוק", field: "location2", width: 100, cellClass: "row-thin-small", sortable: "true"} ,
    {headerName: "טקסט", field: "pasukText2", width: 535, cellClass: "row-thin", cellRenderer: function(params) {return util.decorateText(params.data.pasukText2,params.data.pasukText2.split(" "), params.data.matchIndex2,null,null,true,null,null,null,null,true);}}
];

gridColumns3plus = [ //, type: "numericColumn" merely used as trick for getting align-right until learn a better solution - align: "right"  text, loxcation, teiva, row/middle
    {headerName: "#", field: "rowNum", width: 60, headerClass: "ag-header-cell-center", cellClass: "row-header-center", sortable: "true", filter: 'agNumberColumnFilter'},
    {headerName: "בטוי", field: "testField", width: 160, cellClass: "row-header-super", sortable: "true", cellRenderer: function(params) {return util.decorateNekudosTaamim(params.data.testField);}},
    {headerName: "הופעות", field: "totalAppearances", width: 1300, cellClass: "row-thin-small", sortable: "true"}
];


 constructor(props) {
    super(props);
    this.state = {
      menuValue: 1,
      isLoading: true,
      clientIP: '',
      columnDefs: this.gridColumns2,
      rowData: [],
      defaultLanguage: '',
      extSearchDefData: 'L',  //initialize to teiva-only search
      selectedFreq: '2',
      currentFreq: '2',
      textToCopy: '',
      useKinuim: false,
      includeMekorot: false,
      searchAsQuote: false,
      taamParserOpen: false,
      taamParseData: {},
      rightClickDoubled: false,
      doubleOpeningContextMenu: true, // using this to force second right click when clicking on middle columns so taam parser gets disabled
      taamParserPasukId: null,
      taamParserLocation: null,
    };
    this.refresh = this.refresh.bind(this);
    this.handleFreq = this.handleFreq.bind(this);
    this.frequencyGridReady = this.frequencyGridReady.bind(this);
    this.onCellContextMenu = this.onCellContextMenu.bind(this);
    this.handleDoubleRightClick = this.handleDoubleRightClick.bind(this);
    this.toggleUseKinuim = this.toggleUseKinuim.bind(this);
    this.toggleIncludeMekorot = this.toggleIncludeMekorot.bind(this);
    this.toggleSearchAsQuote = this.toggleSearchAsQuote.bind(this);
    this.openTaamParser = this.openTaamParser.bind(this);
    this.closeTaamParser = this.closeTaamParser.bind(this);
    this.handleFrequencyCellClicked = this.handleFrequencyCellClicked.bind(this);
    this.handleFrequencyCellMouseDown = this.handleFrequencyCellMouseDown.bind(this);
    this.handleCopyLink = this.handleCopyLink.bind(this);


    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}
  }

//    extFinderHeaderCallback = (data) => {
//        this.setState({ extFinderHeaderData: data });
//    }

    frequencyGridReady = params => {
      this.frequencyApi = params.api;
      this.frequencyColumnApi = params.columnApi;
    };

    handleFrequencyCellMouseDown(e){
        if(e.event.shiftKey === true){
            // if holding shift key, unhighlight all text
            window.getSelection().removeAllRanges();
        }
    }
    handleFrequencyCellClicked(e){
        //prevent row selection/deselection if highlighting/unhighlighting text
        var highlightedText = "";
        if (window.getSelection) {
            highlightedText = window.getSelection().toString();
        }
        else if (document.selection && document.selection.type != "Control") {
            highlightedText = document.selection.createRange().text;
        }
        if(highlightedText != ""){ // if text was just highlighted, reverse selection
            e.node.setSelected(!e.node.selected)

            //set row as unselectable until after next mouse click (i.e. until text highlight is removed)
            e.node.selectable = false;
            var lastNodeHighlighted = e.node
            document.getElementById("home-component").addEventListener("click", function(){
                lastNodeHighlighted.selectable = true;
                // remove the event listener after first click
                document.getElementById("home-component").removeEventListener("click", function(){
                    lastNodeHighlighted.selectable = true;
                });
            });
        }
    }

    handleFrequencyCellDoubleClicked(e){
        var location = "";
        var colName = e.colDef.field;
        if(["pasukText1","location1","pasukText2","location2"].includes(colName) || this.state.currentFreq === "1"){
            if(["pasukText1","location1"].includes(colName)){
                location = e.data.location1;
            } else if (["pasukText2","location2"].includes(colName)) {
                location = e.data.location2;
            } else if ( this.state.currentFreq === "1"){
                location = e.data.location;
            }

            this.props.toggleTanachViewer(true, location, this.frequencyApi);

            if (window.getSelection) { // un-highlight the text that was clicked on
                window.getSelection().removeAllRanges();
            } else if (document.selection) {
                document.selection.empty();
            }
        }
    }

    async openTaamParser(pasukLocation){
        this.props.extOpenTaamParser(pasukLocation)
        const body = await this.props.extFetchTaamParseData(pasukLocation)
        this.setState({taamParseData: body})
        this.setState({taamParserOpen: true, taamParserPasukId: body.pasukId, taamParserLocation: body.pasukLocation, taamParserOpen: false}, () => {
            this.setState({taamParserOpen: true})
        })
    }

    closeTaamParser(){
        this.props.extCloseTaamParser()
        this.setState({taamParserOpen: false})
    }

    toggleUseKinuim(){
        this.setState({ useKinuim: !this.state.useKinuim })
    }
    toggleIncludeMekorot(){
        this.setState({ includeMekorot: !this.state.includeMekorot })
    }
    toggleSearchAsQuote(){
        this.setState({ searchAsQuote: !this.state.searchAsQuote })
    }
    handleDoubleRightClick(e){
        var element = document.elementFromPoint(e.event.clientX, e.event.clientY)
        if(this.state.doubleOpeningContextMenu){ // forcing a second right click so taam parse gets disabled when clicking on middle columns
            contextMenu.hideAll();
            this.setState({doubleOpeningContextMenu: false}, () => {
//                this.onCellContextMenu(e);
                var ev = new MouseEvent("contextmenu", {
                    bubbles: true,
                    cancelable: false,
                    view: window,
                    button: 2,
                    buttons: 0,
                    clientX: e.event.clientX,
                    clientY: e.event.clientY
                });
                element.dispatchEvent(ev);
            });
        } else {
            this.setState({doubleOpeningContextMenu: true}, () => {
                this.onCellContextMenu(e);
            });
        }
    }

    handleCopyLink() {
        this.props.handleCopyLink("frequency");
    }

    onCellContextMenu(e){
        contextMenu.show({
          id: 'frequency-viewer-context-menu',
          event: e.event,
        });
        this.setState({useKinuim: false, includeMekorot: false})
        const { innerWidth } = window;
        //style context menu
        var submenus = document.getElementsByClassName('react-contexify__submenu');
        for(let i = 0; i < submenus.length; i++){
            var submenu = submenus[i];
            var submenuWidth = parseInt(submenu.style.minWidth.slice(0, submenu.style.minWidth.length-2));
            if(this.props.activeLanguage.code === 'he'){
                if(e.event.clientX > submenuWidth){
                    submenu.style.left = '';
                    submenu.style.right = '100%';
                } else {
                    submenu.style.left = '100%';
                    submenu.style.right = '';
                }
            } else if(this.props.activeLanguage.code === 'en'){
                if(innerWidth-e.event.clientX-300 > submenuWidth){
                    submenu.style.left = '100%';
                    submenu.style.right = '';
                } else {
                    submenu.style.left = '';
                    submenu.style.right = '100%';
                }
            }
        }

        //set text to copy
        var textToCopy = '';

        var focusedCellRowIndex = null ;
        if(this.frequencyApi.getFocusedCell()){
            var focusedCellRowIndex = this.frequencyApi.getFocusedCell().rowIndex;
        }
        var focusedCellRowText = null;
        var focusedCellSelected = null;
        if((typeof(focusedCellRowIndex) === 'number') && this.frequencyApi.getModel().rowsToDisplay[focusedCellRowIndex]){
            focusedCellRowText = this.frequencyApi.getModel().rowsToDisplay[focusedCellRowIndex].data.text;
            focusedCellSelected = this.frequencyApi.getModel().rowsToDisplay[focusedCellRowIndex].selected;
        }

        //focused = row of focused cell; highlighted = highlighted text; selected = all selected rows;
        if(this.frequencyApi.getFocusedCell() && !window.getSelection().toString() && !focusedCellSelected){
            textToCopy = 'focused';
        } else if(window.getSelection().toString()){
            textToCopy = 'highlighted';
//            frequency rows are not selectable
            } else if (!!this.frequencyApi.getSelectedRows()) {
                textToCopy = 'selected';
        }
        var word = e.event.target.innerText.split(" ")
        word = word[1] && word[1].length>0? null : word.join("")

        this.setState({textToCopy,wordToCopy:word})
    }

  async componentDidMount() {
      this.setLanguage(localStorage.getItem("language"));
      if(this.props.extSearchDefData==="S")this.props.extSetBeta()
      var filterIds;
      if(typeof(this.props.extFilterData)==="string")filterIds = "FFFFFFFFFFF"
      else{
        filterIds = encodeFilterIds(this.props.extFilterData);
        filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
        filterIds = util.right("00000000000"+filterIds,11);
      }

      if (filterIds == "00000000000")  //assume that if got no seforim selected here then must be in error or maybe rightbar filter data didn't load yet
          filterIds = "FFFFFFFFFFF";

      var textSource=this.props.textSource==='targum'?2:1
      const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getOnlyTwoTeivos?source=bhsWebApp&hexFilterIds=' + filterIds + '&morphInflections=' + this.props.extSearchDefData+'&textSource='+textSource);
      const body = await response.json();

      this.setState({ rowData: body, isLoading: false });

      this.props.setHelpTopic("frequencyViewer");
  }

  async handleFreq(event, selectedFreq) {
      this.setState({ selectedFreq });
      //this.props.searchDefCallback(this.state.letters+nekudos+this.state.taamim+this.state.gematria);
  }

    getNewBaseParameters(){ //THIS NEEDS TO BE EDITTED!!
      var baseParams = new BaseParameters();
      var filterIds = encodeFilterIds(this.props.extFilterData);
      filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
      filterIds = util.right("00000000000"+filterIds,11);
      //baseParams.nGrams = this.state.nGrams;
      //baseParams.sortResultsBy = this.state.sortResultsByChosen;
      //baseParams.resultsToDisplay = this.state.resultsToDisplay;
      baseParams.source = 'bhsWebApp';
      baseParams.filterIds = filterIds;
      baseParams.morphInflections = this.props.extSearchDefData; //str
      baseParams.limit = 2100;
      baseParams.minimumFrequency = 3;
      baseParams.skipTerms = []; //this.state.wordsToSkip;
      //baseParams.isReverse = this.state.direction === 'reverse';
      baseParams.namedLocations = [];
      baseParams.pasukRangeLocations = [];
      return baseParams;
    }

  async refresh(event) {
    event.preventDefault();

    this.setState({ isLoading: true });

    var filterIds = encodeFilterIds(this.props.extFilterData);

    filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
    filterIds = util.right("00000000000"+filterIds,11);
//alert(filterIds + ":" + this.props.extSearchDefData);
    //morphInflections = LNTSAR
    const selectedFreq = this.state.selectedFreq;
    var colDefs;
    var methodName;
    var additionalParams = '';
    if (selectedFreq == '1') {
        methodName = 'getOnlyOneTeiva';
        colDefs = this.gridColumns1;
    }
    else if (selectedFreq == '2') {
        methodName = 'getOnlyTwoTeivos';
        colDefs = this.gridColumns2;
    }
    else if (selectedFreq == '3+') {
        methodName = 'getFrequencies';
        additionalParams = '&limit=2100&minimumFrequency=3&skipWords';
        colDefs = this.gridColumns3plus;
    }

    var response;
    var textSource=this.props.textSource==='targum'?2:1

    if (methodName == 'getFrequencies') {
      var baseParams = new BaseParameters();
      var filterIds = encodeFilterIds(this.props.extFilterData);
      filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
      filterIds = util.right("00000000000"+filterIds,11);
      baseParams.source = 'bhsWebApp';
      baseParams.filterIds = filterIds;
      baseParams.morphInflections = this.props.extSearchDefData; //str
      baseParams.limit = 2100;
      baseParams.minimumFrequency = 3;
      baseParams.skipTerms = []; //this.state.wordsToSkip;
      baseParams.namedLocations = [];
      baseParams.pasukRangeLocations = [];
      baseParams.textSource = textSource;
      response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getFrequencies', {method: 'post', body: JSON.stringify(baseParams)});
    }
    else{
      response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/' + methodName + '?source=bhsWebApp&hexFilterIds=' + filterIds + '&morphInflections=' + this.props.extSearchDefData +'&textSource='+textSource+ additionalParams)//+'&clientIP='+this.clientIP);
    }
    const body = await response.json();

    this.setState({ rowData: body, columnDefs: colDefs, isLoading: false, currentFreq: this.state.selectedFreq });
  }

  render() {
    const { classes } = this.props;
    const {item} = this.state;
    const {rowData, selectedFreq, isLoading} = this.state;
    const word = this.props.translate("word");
    const one = this.props.translate("freqOne");
    const two = this.props.translate("freqTwo");
    const threePlus = this.props.translate("freqThreePlus");
    const loadingText = this.props.translate("loading");
    const homePage = this.props.translate("homePage");
    const copyLink  = this.props.translate("copyLink");
    const fakeThis = this;

    var viewerTableHeightNum = parseInt(this.props.viewerHeight.substring(0, this.props.viewerHeight.length - 2)) - 66;
    if(this.props.openResults.length > 0){
        viewerTableHeightNum -= 12;
    }
    const viewerTableHeight = `${viewerTableHeightNum}px`

    var frequencyApi = null;
    if(this.frequencyApi){
        frequencyApi = this.frequencyApi;
    }

    var langDirection = 'ltr'
    var directionStyling = {}
    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
        langDirection = 'rtl';
        directionStyling = {direction: 'rtl', textAlign: 'right'}
    }

    var focusedCellRowIndex = null;
    if(this.frequencyApi && this.frequencyApi.getFocusedCell()){
        var focusedCellRowIndex = this.frequencyApi.getFocusedCell().rowIndex;
    }
    var focusedCellRowLocation = null;
    var focusedCellRowPasukId = null;
    if((typeof(focusedCellRowIndex) === 'number') && this.frequencyApi.getModel().rowsToDisplay[focusedCellRowIndex]){
        focusedCellRowPasukId = this.frequencyApi.getModel().rowsToDisplay[focusedCellRowIndex].data.pasukId;
        if (['location', 'pasukText'].includes(this.frequencyApi.getFocusedCell().column.colId)){
            focusedCellRowLocation = this.frequencyApi.getModel().rowsToDisplay[focusedCellRowIndex].data.location;
        } else if (['location1', 'pasukText1'].includes(this.frequencyApi.getFocusedCell().column.colId)){
            focusedCellRowLocation = this.frequencyApi.getModel().rowsToDisplay[focusedCellRowIndex].data.location1;
        } else if (['location2', 'pasukText2'].includes(this.frequencyApi.getFocusedCell().column.colId)){
            focusedCellRowLocation = this.frequencyApi.getModel().rowsToDisplay[focusedCellRowIndex].data.location2;
        }
    }

    return (<span helpTopic="frequencyViewer">
        <div className={classes.toggleContainer} style={{marginTop: "0px", display: "flex", justifyContent: "space-between"}} >

            <ToggleButtonGroup value={selectedFreq} exclusive onChange={this.handleFreq} title="select the frequency" style={{whiteSpace: "nowrap",marginLeft:"110px"}} >
              <ToggleButton value="3+" title={threePlus}>3+</ToggleButton>
              <ToggleButton value="2" title={two}>2</ToggleButton>
              <ToggleButton value="1"  title={one}>1</ToggleButton>
            </ToggleButtonGroup>

            <div>
                <BootstrapButton color="primary" type="submit" className="bhsButtonLarge" onClick={this.refresh}><Translate id="refresh" /></BootstrapButton>&nbsp;
                <span className="medCaption" align="center"><Translate id="frequencyViewer" /></span>
            </div>
            <div style={{direction: langDirection}}>
                <IconButton className="goHome" title={this.props.translate("homePage")} onClick={()=>{this.props.extCloseDockedViewer()}}><HomeIcon /></IconButton>
                <IconButton className="goHome" title={copyLink} onClick={()=>{this.handleCopyLink()}}><LinkIcon /></IconButton>
            </div>
        </div>
        <LoadingOverlay
          active={isLoading}
          spinner
          text={loadingText}
          >
          <MenuProvider className="context-menu" id='frequency-viewer-context-menu'
              style={{
                height: viewerTableHeight,
                width: '1400px',
                maxWidth: 'calc(100vw - 400px)',
                minWidth: '968px',
              }}
          >
            <div
              className="ag-theme-balham"
              style={{
                height: viewerTableHeight,
                width: '1400px',
                maxWidth: 'calc(100vw - 400px)',
                minWidth: '968px',
              }}
                >
                <AgGridReact
                    onGridReady={fakeThis.frequencyGridReady}
                    onCellContextMenu={(e) => {fakeThis.handleDoubleRightClick(e)}}
                    onCellClicked={(e) => {fakeThis.handleFrequencyCellClicked(e)}}
                    onCellDoubleClicked={(e) => {fakeThis.handleFrequencyCellDoubleClicked(e)}}
                    onCellMouseDown={(e) => {fakeThis.handleFrequencyCellMouseDown(e)}}
                    defaultColDef={{resizable: true}}
                    columnDefs={this.state.columnDefs}
                    rowData={this.state.rowData}
                    getRowStyle = {getAlternateRowStyle}
                    enableColResize={true}
                    enableSorting={true}
                    enableFilter={true}
                    suppressRowClickSelection={true}
                    enableRtl="true">
                </AgGridReact>
            </div>
         </MenuProvider>
         <ContextMenu menuId='frequency-viewer-context-menu' menuType='frequencyViewer' className='special-context-menu' resultsApi={frequencyApi}
                        textToCopy={fakeThis.state.textToCopy} extCopySelectedToSearchbar={fakeThis.props.extCopySelectedToSearchbar}
                        extSearchWithSelected={fakeThis.props.extSearchWithSelected} extToggleUseKinuim={fakeThis.toggleUseKinuim}
                        useKinuim={fakeThis.state.useKinuim} extToggleIncludeMekorot={fakeThis.toggleIncludeMekorot} includeMekorot={fakeThis.state.includeMekorot}
                        extOpenTaamParser={fakeThis.openTaamParser} currentFreq={this.state.currentFreq} hasRowData={fakeThis.state.rowData.length > 0}
                        resultsApi={this.frequencyApi} extToggleSearchAsQuote={fakeThis.toggleSearchAsQuote} searchAsQuote={fakeThis.state.searchAsQuote}
                        toggleTanachViewer={((boolean, pasukId, resultsApi) => {this.props.toggleTanachViewerViewer(boolean, pasukId, resultsApi)})} 
                        extSetTextSource={this.props.extSetTextSource} extOpenLexiconViewer={this.props.extOpenLexiconViewer} wordToCopy={this.state.wordToCopy}
                        morphInflections={this.props.extSearchDefData}/>
        </LoadingOverlay>

                {/* <Dialog
                  open={this.state.taamParserOpen}
                  onClose={this.closeTaamParser}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth="xl"
                >
                    <TaamParser data={this.state.taamParseData} open={this.state.taamParserOpen} closeTaamParser={this.closeTaamParser}
                            focusedCellRowLocation={focusedCellRowLocation} extOpenTaamParser={this.openTaamParser}
                            pasukId={this.state.taamParserPasukId} pasukLocation={this.state.taamParserLocation}
                            resultsApi={this.frequencyApi} />
                </Dialog> */}
        </span>
    );
  }
}

FrequencyViewer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withLocalize(FrequencyViewer));


/*
      <div className='sweet-loading'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={isLoading}
        />


      </div>
*/



