import React, { Component } from 'react';
import * as properties from '../../config/properties.js';

class DirectSiteLinks_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }

    var appURL = properties.env.applicationURL + "/#/";

    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>
        <b>קישורים ישירים לאתר</b><br />
קישורים ישירים (URL) מספקים גישה מיידית כמעט לכל הדפים והתכונות של האתר. מספר עמודים מאפשרים פרמטרים נוספים השולטים באתחול התוכן.
<br /><br />
זה יכול להקל על הפניות מהירות לתכונות האתר כאשר אדם רוצה להעביר מידע לאחרים מבלי לתת הוראות כיצד לנווט באתר מדף הבית שלו. אפשר לנצל זאת בתקשורת כמו דוא"ל והודעות טקסט וגם בהפניה לאתר ממאמרים.
<br /><br />
<b>קישורים בסיסיים</b>
<ul>
<li><b>חיפוש:</b> {appURL}<b><span style={{color: "red"}}>search</span></b> - טוען מיד חיפוש על סמך אפשרויות (<i>ראה מפרטי פרמטרים למטה</i>)</li>
<li><b>דפדפן התנ"ך:</b> {appURL}<b><span style={{color: "red"}}>tanach</span></b> - פותח תכונה זו עם מיקום פתיחה אופציונלי (<i>ראה פרמטרים</i>)</li>
<li><b>פרשיות התנ"ך:</b> {appURL}<b><span style={{color: "red"}}>parshaViewer</span></b> -  פותח תכונה זו (<i>ללא אפשרויות נוספות</i>)</li>
<li><b>לקסיקון:</b> {appURL}<b><span style={{color: "red"}}>lexicon</span></b> - פותח תכונה זו עם אפשרויות הפעלה שונות (<i>ראה פרמטרים</i>)</li>
<li><b>שכיחות בתנ"ך:</b> {appURL}<b><span style={{color: "red"}}>frequency</span></b> - פותח תכונה זו (<i>ללא אפשרויות נוספות</i>)</li>
<li><b>משווה טקסטים:</b> {appURL}<b><span style={{color: "red"}}>compare</span></b> -  פותח תכונה זו (<i>ללא אפשרויות נוספות</i>)</li>
<li><b>מנתח טעמים:</b> {appURL}<b><span style={{color: "red"}}>taamParser</span></b> - פותח תכונה זו עם פסוק פתיחה אופציונלי (<i>ראה פרמטרים</i>)</li>
<li><b>ענני אובייקטים:</b> {appURL}<b><span style={{color: "red"}}>clouds</span></b> -  פותח תכונה זו (<i>ללא אפשרויות נוספות</i>)</li>
<li><b>אילן יוחסין תנ"כי:</b> {appURL}<b><span style={{color: "red"}}>3Dtree</span></b> - פותח תכונה זו עם מידע הפעלה אופציונלי (<i>ראה פרמטרים</i>)</li>
<li><b>שירותי פרימיום:</b> {appURL}<b><span style={{color: "red"}}>premiumServices</span></b> - פותח חלון קופץ זה (<i>ללא אפשרויות נוספות</i>)</li>
</ul>
<b>קישורי מידע</b>
<ul>
<li><b>צור קשר אתנו:</b> {appURL}<b><span style={{color: "red"}}>contact</span></b> - פותח חלון קופץ עם מידע זה (<i>ללא אפשרויות נוספות</i>)</li>
<li><b>מידע:</b> {appURL}<b><span style={{color: "red"}}>about</span></b> -  פותח חלון קופץ זה עם כרטיסייה פתיחה אופציונלית (<i>ראה פרמטרים</i>)</li>
<li><b>עזרה:</b> {appURL}<b><span style={{color: "red"}}>help</span></b> - פותח חלון קופץ זה עם נושא פתיחה אופציונלי (<i>ראה פרמטרים</i>)</li>
<li><b>מפת האתר:</b> {appURL}<b><span style={{color: "red"}}>sitemap</span></b> - פותח את הדף הזה (<i>ללא אפשרויות נוספות</i>)</li>
</ul>
<br />
<b>פרמטרים</b> - כל אלה הם אופציונליים - ערכים בדרך כלל אינם תלויי אותיות סוף
<ul>
    <li><b>תחביר</b>: <u>כתובת האתר של הדף</u> כמתואר לעיל ואחריה <b>?</b><i>שם_הפרמטר<b>=</b>ערך_הפרמטר</i></li>
    <li>כשמשתמשים במספר פרמטרים, כל פרמטר עוקב מקודם ב-<b>&</b> במקום <b>?</b></li>
    <li>למשל: <span dir="LTR">{appURL}<b><span style={{color: "red"}}>search</span></b>?<b><span style={{color: "red"}}>terms</span></b>=כתב&<b><span style={{color: "red"}}>precision</span></b>=2</span></li>
</ul>
<b>פרמטרים כלליים המשמשים למספר מסכים/תכונות</b>
<ul>
<li><b><span style={{color: "red"}}>language</span>: כל המסכים</b>
    <ul>
        <li><b>מטרה</b>: מאתחל את ממשק המשתמש לשפה מסוימת</li>
        <li><b>ערכים</b>: EN, ENG, ENGLISH, HE, HEB, HEBREW (<i>שפת ברירת המחדל נקבעת לפי המיקום העולמי</i>)</li>
    </ul>
</li>
<li><b><span style={{color: "red"}}>fullscreen</span></b>: <i>דפדפן התנ"ך, מנתח טעמים, ענני אובייקטים, לקסיקון, אילן יוחסין תנ"כי, עזרה, אודות</i>
    <ul>
        <li><b>מטרה</b>: מפעיל את התכונות הללו במצב מסך מלא</li>
        <li><b>ערכים</b>: T או Y, F או N (<i>ברירת מחדל = F</i>)</li>
    </ul>
</li>
<li><b><span style={{color: "red"}}>location</span></b>: <i>חיפוש, דפדפן התנ"ך, מנתח טעמים</i>
    <ul>
        <li><b>מטרה</b>: גורם לתכונות קשורות להתמקד או לאתר בפסוק מסוים</li>
        <li><b>ערכים</b>: מיקום פסוק בעברית בצורה של ספר:פרק:פסוק, למשל: <b>שמות:כג:ד</b></li>
    </ul>
</li>
</ul>
<b>פרמטרים קשורים למסכים/מאפיינים מסוימים</b><br />
<b>חיפוש</b>
<ul>
<li><b><span style={{color: "red"}}>terms</span></b>: מילים\מונחים אחד לרבים מופרדים ברווחים. למרכאות מדויקות, אפשר להקיף בתווים " או להשתמש בפרמטר exact למטה</li>
<li><b><span style={{color: "red"}}>exact</span></b>: <span dir="RTL">T או Y, F או N</span> (<i>ברירת מחדל = F - קובע אם מספר מונחים יוצרים ציטוט מדויק</i>)</li>
<li><b><span style={{color: "red"}}>inorder</span></b>: <span dir="RTL">T או Y, F או N</span> (<i>ברירת מחדל = T - קובע אם מספר מונחים חייבים להופיע ברצף</i>)</li>
<li><b><span style={{color: "red"}}>precision</span></b>: <span dir="RTL">0, 1, 2, 3</span> (<i>ברירת מחדל = 0</i>) - כאשר אפס הוא המדויק ביותר ושלוש הוא המעורפל ביותר</li>
<li><b><span style={{color: "red"}}>format</span></b>: <span dir="RTL">L (<i>חיפוש לפי תבנית אותיות</i>), N (<i>לפי נקודות</i>), T (<i>לפי טעמים</i>), S (<i>לפ שורש</i>), G (<i>לפי גימטריה</i>), A (<i>לפי אתבש</i>)</span> - ברירת מחדל = L : ניתן לשלב את LNT</li>
</ul>
<b>לקסיקון</b>
<ul>
<li><b><span style={{color: "red"}}>word</span></b>: מילת התחלה או תבנית לחפש</li>
<li><b><span style={{color: "red"}}>root</span></b>: שורש או תבנית התחלה לחפש</li>
<li><b><span style={{color: "red"}}>gematria</span></b>: מספר לחפש</li>
<li><b><span style={{color: "red"}}>atbash</span></b>: מספר לחפש</li>
<li><b><span style={{color: "red"}}>targum</span></b>: מילת התחלה או תבנית בארמית לחפש</li>
</ul>
<b>אילן יוחסין תנ"כי</b>
<ul>
<li><b><span style={{color: "red"}}>name</span></b>: שם אדם בתנ"ך (<i>כמה שיותר מלא, יותר טוב, למשל משה בן עמרם</i>) - יגרום להציג זום תחילה לאותו אדם</li>
<li><b><span style={{color: "red"}}>name2</span></b>: שם אדם בתנ"ך (<i>כמה שיותר מלא, יותר טוב, למשל משה בן עמרם</i>) - יגרום להציג את הקשר בין name ל: name2</li>
<li><b><span style={{color: "red"}}>zoomDistance</span></b>: מספר (<i>ברירת מחדל = 300</i>) - כאשר name מוזן, המספר הזה קובע כמה רחוק להתקרב לתצוגה על אותו אדם</li>
<li><b><span style={{color: "red"}}>animate</span></b>: <span dir="RTL">T או Y, F או N</span> (<i>ברירת מחדל = F - כאשר name וname2 מוזנים, זה יפעיל מיד הנפשת ניווט היחס בין name ו: name2</i>)</li>
<li><b><span style={{color: "red"}}>darkmode</span></b>: <span dir="RTL">T או Y, F או N</span> (<i>ברירת מחדל = T</i>)</li>
</ul>
<b>עזרה</b>
<ul>
<li><b><span style={{color: "red"}}>topic</span></b>: שם נושא העזרה (<i>ברירת מחדל = פריסת האתר</i>)</li>
</ul>
<b>אודות</b>
<ul>
<li><b><span style={{color: "red"}}>tab</span></b>: <span dir="RTL">0 עד 10</span> (<i>ברירת מחדל = 0 - מציין את מיקום הכרטיסייה בחלון המוקפץ אודות</i>)</li>
</ul>
<br />
<b>כפתור העתק קישור</b>
<br />
דרך קלה ללכוד קישורים ישירים עם פרמטרים מוגדרים מראש המבוססים על הקשר.
<br /><br />
לא תמיד צריך להקליד ולבנות קישורי URL באופן ידני בעצמם. לכל תכונה ודף יהיה בדרך כלל סמל קישור ליד כפתור הסגירה שנראה כמו הבא:
<br />
<span dir="LTR"><img id="Picture 1" src="helpDialogImages/copyLinkButton.jpg" /></span>
<br /><br />
לחיצה על זה תעתיק את הקישור הרלוונטי למאגר ההעתקה שלך כך שתוכל להדביק את הקישור הבנוי במלואו בכל מקום.


        </p>

      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b>Direct site links</b><br />
Direct links (URL) provide immediate access to practically all pages and features of the site.  A number of the pages allow additional parameters which control the initialization of content.<br /><br />

This can facilitate quick references to site features when one wants to convey information to others without having to give instructions on how to navigate through the site from its home page.  One can take advantage of this in communications such as emails and text messages and also when referencing the site from articles.<br /><br />

<b>Basic links</b>
<ul>
<li><b>Search:</b> {appURL}<b><span style={{color: "red"}}>search</span></b> - immediately loads a search based on options (<i>see parameter specifications below</i>)</li>
<li><b>Tanach Viewer:</b> {appURL}<b><span style={{color: "red"}}>tanach</span></b> - opens this feature with an optional startup location (<i>see parameters</i>)</li>
<li><b>Parsha Viewer:</b> {appURL}<b><span style={{color: "red"}}>parshaViewer</span></b> - opens this feature (<i>no additional options</i>)</li>
<li><b>Lexicon:</b> {appURL}<b><span style={{color: "red"}}>lexicon</span></b> - opens this feature with various startup options (<i>see parameters</i>)</li>
<li><b>Frequency Viewer:</b> {appURL}<b><span style={{color: "red"}}>frequency</span></b> - opens this feature (<i>no additional options</i>)</li>
<li><b>Text Comparator:</b> {appURL}<b><span style={{color: "red"}}>compare</span></b> - opens this feature (<i>no additional options</i>)</li>
<li><b>Taam Parser:</b> {appURL}<b><span style={{color: "red"}}>taamParser</span></b> - opens this feature with an optional startup pasuk (<i>see parameters</i>)</li>
<li><b>Object Clouds:</b> {appURL}<b><span style={{color: "red"}}>clouds</span></b> - opens this feature (<i>no additional options</i>)</li>
<li><b>Tanach Family Tree:</b> {appURL}<b><span style={{color: "red"}}>3Dtree</span></b> - opens this feature with optional startup info (<i>see parameters</i>)</li>
<li><b>Premium Services:</b> {appURL}<b><span style={{color: "red"}}>premiumServices</span></b> - opens this popup window (<i>no additional options</i>)</li>
</ul>
<b>Informational links</b>
<ul>
<li><b>Contact us:</b> {appURL}<b><span style={{color: "red"}}>contact</span></b> - opens a popup window with this information (<i>no additional options</i>)</li>
<li><b>About:</b> {appURL}<b><span style={{color: "red"}}>about</span></b> -  opens this popup window with an optional initial tab (<i>see parameters</i>)</li>
<li><b>Help:</b> {appURL}<b><span style={{color: "red"}}>help</span></b> - opens this popup window with an optional initial topic (<i>see parameters</i>)</li>
<li><b>Site map:</b> {appURL}<b><span style={{color: "red"}}>sitemap</span></b> - opens this page (<i>no additional options</i>)</li>
</ul>
<br />
<b>Parameters</b> - all these are optional - values are typically case-insensitive
<ul>
    <li><b>syntax</b>: <u>page URL</u> as described above followed by <b>?</b><i>parameter_name<b>=</b>parameter_value</i></li>
    <li>when using multiple parameters, each subsequent parameter is prefaced with a <b>&</b> instead of a <b>?</b></li>
    <li>eg. {appURL}<b><span style={{color: "red"}}>search</span></b>?<b><span style={{color: "red"}}>terms</span></b>=כתב&<b><span style={{color: "red"}}>precision</span></b>=2</li>
</ul>
<b>General parameters used by multiple screens/features</b>
<ul>
<li><b><span style={{color: "red"}}>language</span>: all screens</b>
    <ul>
        <li><b>purpose</b>: initializes the user interface to a specific language</li>
        <li><b>values</b>: EN, ENG, ENGLISH, HE, HEB, HEBREW (<i>default language is determined by global location</i>)</li>
    </ul>
</li>
<li><b><span style={{color: "red"}}>fullscreen</span></b>: <i>Tanach Viewer, Taam Parser, Object Clouds Lexicon, Tanach Family Tree, Help Dialog, About Dialog</i>
    <ul>
        <li><b>purpose</b>: launches these features in full-screen mode</li>
        <li><b>values</b>: T or Y, F or N (<i>default = F</i>)</li>
    </ul>
</li>
<li><b><span style={{color: "red"}}>location</span></b>: <i>Search, TanachViewer, TaamParser</i>
    <ul>
        <li><b>purpose</b>: causes relevant features to focus or locate on a specific pasuk</li>
        <li><b>values</b>: a Hebrew pasuk location in the form of seferName:perek:pasukNumber, eg. <b>שמות:כג:ד</b></li>
    </ul>
</li>
</ul>
<b>Parameters specific to particular screens/features</b><br />
<b>Search</b>
<ul>
<li><b><span style={{color: "red"}}>terms</span></b>: one to many Hebrew terms separated by spaces.  For exact quotes, can surround with " characters or can use exact parameter below</li>
<li><b><span style={{color: "red"}}>exact</span></b>: T or Y, F or N (<i>default = F - determines whether multiple terms form an exact quote</i>)</li>
<li><b><span style={{color: "red"}}>inorder</span></b>:  T or Y, F or N (<i>default = T - determines whether multiple terms must occur in sequence</i>)</li>
<li><b><span style={{color: "red"}}>precision</span></b>: 0, 1, 2, 3 (<i>default = 0</i>) - where zero is most exact and three is most fuzzy</li>
<li><b><span style={{color: "red"}}>format</span></b>: L (<i>search by letter format</i>), N (<i>by nekuda</i>), T (<i>by taam</i>), S (<i>by shoresh</i>), G (<i>by gematria</i>), A (<i>by Atbash</i>) - default = L : LNT can be combined</li>
</ul>
<b>Lexicon</b>
<ul>
<li><b><span style={{color: "red"}}>word</span></b>: a Hebrew startup word or pattern for which to search</li>
<li><b><span style={{color: "red"}}>root</span></b>: a Hebrew startup root or pattern for which to search</li>
<li><b><span style={{color: "red"}}>gematria</span></b>: a number for which to search</li>
<li><b><span style={{color: "red"}}>atbash</span></b>: a number for which to search</li>
<li><b><span style={{color: "red"}}>targum</span></b>: an Aramaic startup word or pattern for which to search</li>
</ul>
<b>Tanach Family Tree</b>
<ul>
<li><b><span style={{color: "red"}}>name</span></b>: a person name in Tanach (<i>the fuller, the better, eg. Moshe ben Amram</i>) - will cause the viewer to initially zoom to that person</li>
<li><b><span style={{color: "red"}}>name2</span></b>: a person name in Tanach (<i>the fuller, the better, eg. Moshe ben Amram</i>) - will cause the viewer to chart the relationship between name and name2</li>
<li><b><span style={{color: "red"}}>zoomDistance</span></b>: a number (<i>default = 300</i>) - when name is entered, this number determines how far away to zoom the view on that person</li>
<li><b><span style={{color: "red"}}>animate</span></b>: T or Y, F or N (<i>default = F - when name and name2 are entered, this will immediately launch an animation of the relationship navigation between name and name2</i>)</li>
<li><b><span style={{color: "red"}}>darkmode</span></b>: T or Y, F or N (<i>default = T</i>)</li>
</ul>
<b>Help Dialog</b>
<ul>
<li><b><span style={{color: "red"}}>topic</span></b>: name of the help topic (<i>default = site layout</i>)</li>
</ul>
<b>About Dialog</b>
<ul>
<li><b><span style={{color: "red"}}>tab</span></b>: 0 thru 10 (<i>default = 0 indicates tab position in the About popup</i>)</li>
</ul>
<br />
<b>The Copy-Link button</b>
<br />
An easy way to capture direct links with pre-set parameters based on context.
<br /><br />
One doesn't always have to manually type and build URL links on their own.  Each feature and page will typically have a link icon near the close button that looks like the following:
<br />
<span dir="LTR"><img id="Picture 1" src="helpDialogImages/copyLinkButton.jpg" /></span>
<br /><br />
Clicking on this will copy the relevant link to your copy buffer so that you can paste the fully built link anywhere.

        </p>

      </>
    }
    return retVal;
  }

  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default DirectSiteLinks_Instructions;