import React, { Component } from 'react';

class AdvancedLayout_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>חיפוש
              מתקדם בתנ"ך</span></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>וידבר
              אל* לאמר </span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בסרגל
            החיפוש</span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לחץ
            על מתג החיפוש
            המתקדם ממש
            מימין לסרגל
            החיפוש כך
            שהוא ייראה כך
            במצב למטה:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={355} height={65} id="Picture 21" src="helpDialogImages/image014.png" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עכשיו
            תראה את
            דיאלוג
            החיפוש
            המתקדם נפתח:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={624} height={154} id="Picture 6" src="helpDialogImages/image015.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>סרגל
              המשימות</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>: אזור
            זה מכיל את
            הפקדים הבאים:</span></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>נקה
            את כל הגדרות חיפוש
            המתקדמות</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>נקה
            הגדרות
            מהכרטיסייה
            הנוכחית</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שמור
            את הגדרות
            חיפוש</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>טען
            הגדרות חיפוש</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שטח
              קבוע:</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
            תוכן זה יישאר
            תמיד בתצוגה. בצד
            ימין למטה יש
            בורר בטויי
            חיפוש. זה מציין
            איזה מונח יקבל
            שינויים בהגדרות
            חיפוש מתקדמות
            שנחשבות ספציפיות
            למונח יחיד (לא
            הגדרות השולטות
            בכל מונחי החיפוש).</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אזור
              הכרטיסיות: </span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כרגע
            יש ארבע
            כרטיסיות. הגדרות
            ותצוגות
            שונות יוצגו
            על סמך לחיצה
            על הכרטיסייה.
            מימין לשמאל
            הם:</span></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>(למונח
            מסוים) הגדרות
            להקצאת
            נקודות וטעמים</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="RTL" /><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />(למונח
            מסוים) מגדיר מרחק
            מותר לכל מונח
            מתחילת או סוף
            פסוק, פרשה
            (פתוחה או סתומה),
            או ספר</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="RTL" /><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />(למונח
            מסוים)
מגדיר ענייני דקדוק, חלקי דיבור ופרטים מספריים
</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="RTL" /><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />(כללי)
            מגדיר אילו
            אותיות,
            נקודות
            וטעמים חייבים
            להיכלל ו / או
            להימחק
            מפסוקים שיש
            למצוא. זה גם מגדיר
           הגדרות אחרות המוגדרות להגביל את תוצאות החיפוש באופן ספציפי יותר. לדוגמה, המשתמש יכול להגדיר כמה מילים כלולות, אותיות כלולות או מה צריכה להיות הגימטריא של הפסוקים המתקבלים.
            </span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="RTL" /><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />(כללי)
            מגדיר קטעים
            ספציפיים
            בתנ"ך לחיפוש
            על סמך קטעים
            מסוימים ו / או
            על סמך טווחי
            פסוקים. זה
            מספק סינון
            עדין בהרבה
            מתיבות
            הסימון לבחירת
            ספר בלוח
            הימני של המסך.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מספר
            הכרטיסיות
            כאן יגדל
            בעתיד ככל
            שתוסיפו אפשרויות
            חיפוש
            מתקדמות יותר.</span></p>
        <p className="MsoNormal">&nbsp;</p>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b>Advanced Tanach search</b></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">Enter <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>וידבר
            אל* לאמר</span><span dir="LTR" /><span dir="LTR" /> in the search bar.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">Click the advanced search switch just to the right of the
          search bar so that it looks like this in the down position:</p>
        <p className="MsoNormal"><img width={355} height={65} id="Picture 21" src="helpDialogImages/image014.png" /></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">You will now see the advanced search dialogue open up:</p>
        <p className="MsoNormal"><img width={624} height={154} id="Picture 105" src="helpDialogImages/image015.jpg" /></p>
        <p className="MsoNormal"><b>Task bar</b>: this area contains the following controls:</p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />Clear all advanced search settings</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />Clear setting from current tab</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />Save search settings</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />Load search settings</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b>Fixed area</b>: this content will always stay in view.&nbsp;There is a search term selector on the lower right. This specifies which term
          will receive changes to advanced search settings that are considered term-specific (not settings that govern all search terms).</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b>Tabbed area</b>: currently there are four tabs.&nbsp;
          Different settings and views will be presented based on which tab is clicked.&nbsp;
          From right to left they are:</p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />(per term) Settings for assigning nekudos
          and taamim</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />(per term) Defines how far each term can be
          from the beginning or end of a pasuk, parsha pesucha/stuma, or sefer</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />(per term) Specifications for grammar, part-of-speech and numeric details</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />(general) Defines which letters, nekudos and
          taamim must be included and/or excluded from pesukim that are to be found. It also defines other settings that are set to limit the search results more specifically. For example, the user can set how many 
          words included, letters included, or what the gematria of the resulting pesukim should be.</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />(general) Defines specific sections of
          Tanach to search based on named sections and/or based on pasuk ranges.&nbsp; This
          provides a much more fine-grained filtering than the sefer selection check
          boxes in the right side panel of the screen.</p>
        <p className="MsoNormal">The number of tabs here will grow in the future as more
          advanced search options are added.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default AdvancedLayout_Instructions;