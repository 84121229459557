import React, { Component } from 'react';

class HistoryTaryag extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p>
          באוגוסט 2017, לאחר מספר סבבי הצבעה עם משפחתי ודחיית כמה שמות מוזרות מאוד, יצרתי רשמית את חברת Taryag Analytics.
          זה היה לכבוד סבא רבא שלי, הרב יעקב אסקאלסקי זצ"ל שכתב ספר בשם <b>תרי"ג מצות</b>.
          http://www.taryaganalytics.com הושק כאתר אינטרנט פשוט לחברה.
        </p>

      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <p>In August 2017, after several rounds of voting with my family and rejecting some very off-the-wall names, 
        I formally created Taryag Analytics LLC.  This was a nod to my great-grandfather, Rav Yaakov 
        Eskolsky zt"l who authored a sefer named Taryag Mitzvos.  http://www.taryaganalytics.com was launched as a 
        simple web site for the company.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default HistoryTaryag;