import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { Link, withRouter } from 'react-router-dom';
import { IconButton  } from '@material-ui/core';

import { Button as BootstrapButton} from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
//import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import * as properties from './config/properties.js';
import PropTypes from 'prop-types';
import { Paper, Typography, MenuItem, Select, FormControl, Button, Grid, Dialog } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { render } from "react-dom";
import { MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import ContextMenu from './ContextMenu';
import TaamParser from './TaamParser';
import { contextMenu } from 'react-contexify';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import HomeIcon from '@material-ui/icons/Home';
import LinkIcon from '@material-ui/icons/Link';

const Gematria = require('gematria');

const styles = theme => ({
  toggleContainer: {
    backgroundColor: 'transparent',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    alignItems: 'center',
    margin: `auto`,
    background: theme.palette.background.default,
  },
});
function BaseParameters() {
    this.source = 'bhsWebApp';
    this.clientIP = null;
    this.morphInflections = null;
    this.filterIds = 'FFFFFFFFFFF'; //all seforim selected
    this.limit = 2100;
    this.minimumFrequency = 1;
    this.skipTerms = [];
    this.namedLocations = [];
    this.pasukRangeLocations = [];
  }
  
  function PerekPasukRange(seferId1, perekNum1, pasukNum1, seferId2, perekNum2, pasukNum2) {
    this.seferId1 =  seferId1; //int
    this.perekNum1 = perekNum1;  //int
    this.pasukNum1 =  pasukNum1; //int
    this.seferId2 = seferId2;  //int
    this.perekNum2 =  perekNum2; //int
    this.pasukNum2 = pasukNum2;  //int
  }
  

const viewerWidth = 1439;

//todo this is repeated elsewhere - combine it
function encodeFilterIds(nodes, nodeString) {
    var nodeStr = (nodeString != undefined) ? nodeString : "0".repeat(44);
    nodes.forEach((node)=>{
        if (node.tableName == "Sefer" && node.isChecked)
            nodeStr = util.stuff(nodeStr, "1", node.id-1, 1);
        if(node.children){
            nodeStr = encodeFilterIds(node.children, nodeStr);
        }
    })
    return nodeStr;
}

function getAlternateRowStyle(params) {
    if (params.node.rowIndex % 2 === 0) {
        return {background: '#F0F0F0'};
    }
}

class ParshaViewer extends Component {

//webServiceURL = properties.env.webServiceURL;

 constructor(props) {
    super(props);
    this.state = {
      menuValue: 1,
      isLoading: true,
      clientIP: '',
      columnDefs: this.getGridColumns(),
      rowData: [],
      defaultLanguage: '',
      textDisplayType: 'stream',
      fontSizeLevel: 1,
      openParshaCount: 0,
      openParshiot: [],
      textToCopy: '',
      useKinuim: false,
      includeMekorot: false,
      searchAsQuote: false,
      textCellPasukText: '',
      textCellPasukMakor: '',
      isStatsContextMenu: true,
      taamParserOpen: false,
      taamParseData: {},
      taamParserPasukId: null,
      taamParserLocation: null,
      doubleOpeningContextMenu: true, // using this to force second right click when clicking on cell padding so taam parser gets disabled
      contentDisplayed: ['tanach'],
      isFetchingParsha: false,
      rowsPerPage: 20,
      currentPage: 1,
      loadingPage: 1,
      totalRows: "...",
      searchedFilterIds: null, // using this to get correct results batches
    //   frameworkComponents: {fullWidthCellRenderer: ParshaViewerFullWidthCell },
    //   fullWidthCellRenderer: "fullWidthCellRenderer",
      cloudViewerOpen:[],
      closeObjectViewerWithNoParsha:false
    };
    this.refresh = this.refresh.bind(this);

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}

    this.handleCellClicked = this.handleCellClicked.bind(this);
    this.resultsGridReady = this.resultsGridReady.bind(this);
    // this.cloudResultsGridReady = this.cloudResultsGridReady.bind(this);
    this.buttonCellRenderer = this.buttonCellRenderer.bind(this);
    this.handleToggleTextDisplayType = this.handleToggleTextDisplayType.bind(this);
    this.increaseFontSize = this.increaseFontSize.bind(this);
    this.decreaseFontSize = this.decreaseFontSize.bind(this);
//    this.setStatisticsRowFontSize = this.setStatisticsRowFontSize.bind(this);
    this.getGridColumns = this.getGridColumns.bind(this);
//    this.setStatisticsColumnWidths = this.setStatisticsColumnWidths.bind(this);
    this.onFontSizeChange = this.onFontSizeChange.bind(this);
    this.closeAllParshiot = this.closeAllParshiot.bind(this);

    this.onCellContextMenu = this.onCellContextMenu.bind(this);
    this.onTextCellContextMenu = this.onTextCellContextMenu.bind(this);
    this.toggleUseKinuim = this.toggleUseKinuim.bind(this);
    this.toggleIncludeMekorot = this.toggleIncludeMekorot.bind(this);
    this.toggleSearchAsQuote = this.toggleSearchAsQuote.bind(this);
    this.closeTaamParser = this.closeTaamParser.bind(this);
    this.openTaamParser = this.openTaamParser.bind(this);
    this.handleDoubleRightClick = this.handleDoubleRightClick.bind(this);
    this.toggleCloudViewerOpen = this.toggleCloudViewerOpen.bind(this);
    this.goHome = this.goHome.bind(this);
    this.handleCopyLink = this.handleCopyLink.bind(this);
  }
      async openTaamParser(pasukLocation){
          this.props.extOpenTaamParser(pasukLocation)
          const body = await this.props.extFetchTaamParseData(pasukLocation)
          this.setState({taamParseData: body})
          this.setState({taamParserOpen: true, taamParserPasukId: body.pasukId, taamParserLocation: body.pasukLocation, taamParserOpen: false}, () => {
              this.setState({taamParserOpen: true})
          })
      }

      handleToggleContentDisplayed (event, contentTypes) {
        if(contentTypes.length > 0){
          this.setState({contentDisplayed: contentTypes}, () => {
                this.resultsApi.setRowData(this.getDisplayRows())
          });
        }
      }

      closeTaamParser(){
          this.props.extCloseTaamParser()
          this.setState({taamParserOpen: false})
      }

      toggleUseKinuim(){
          this.setState({ useKinuim: !this.state.useKinuim })
      }
      toggleIncludeMekorot(){
          this.setState({ includeMekorot: !this.state.includeMekorot })
      }

      toggleSearchAsQuote(){
        this.setState({ searchAsQuote: !this.state.searchAsQuote })
    }

    onTextCellContextMenu(event,columnClicked){
        contextMenu.hideAll();
        //set text to copy
        var textToCopy = '';

        if(window.getSelection().toString()){
            textToCopy = 'highlighted';
        } else {
            textToCopy = 'pasuk';
        }
        this.setState({textToCopy,columnClicked}, () => {
            //get text, makor from HTML element clicked on
            var textCellPasukText = null;
            var textCellPasukMakor = null;
            var wordToCopy = null;
            if(this.state.textDisplayType === 'table' && (event.path[0].localName=='span' ? event.path[2].className === 'text-cell-pasuk-row' : event.path[1].className === 'text-cell-pasuk-row')){
                var wordToCopy = event.target.innerText.split(" ")
                wordToCopy = wordToCopy[1] && wordToCopy[1].length>0? null : wordToCopy.join("")
                if(!util.isHebrew(wordToCopy))
                        wordToCopy=null
                textCellPasukText = event.path[0].localName=='span' ? event.path[2].children[1].innerText : event.path[1].children[1].innerText;
                textCellPasukMakor = event.path[0].localName=='span' ? event.path[2].children[0].attributes.location.value : event.path[1].children[0].attributes.location.value;
            } else if (this.state.textDisplayType === 'stream'){
                if(this.state.textToCopy === 'pasuk' && event.path[2].className === 'text-cell-pasuk-span'){
                    if(this.state.columnClicked==="תרגום"){
                        textCellPasukText = event.path[2].attributes.targum.value;
                    }else{
                        textCellPasukText = event.path[2].attributes.text.value;
                    }
                    textCellPasukMakor = event.path[2].attributes.location.value;
                }else if(this.state.textToCopy === 'pasuk' && event.path[0].className === 'word-span'){//hovering over a specific word
                    if(this.state.columnClicked==="תרגום"){
                        textCellPasukText = event.path[3].attributes.targum.value;
                    }else{
                        textCellPasukText = event.path[3].attributes.text.value;
                    }
                    wordToCopy = event.path[0].attributes.word.value;
                    textCellPasukMakor = event.path[3].attributes.location.value;
                } else {
                    textCellPasukText = window.getSelection().toString();

                    //check anchorNode and focusNode for makor - use the earlier one
                    var firstPasukLocation = '';
                    var anchorNodePasukId = parseInt(window.getSelection().anchorNode.parentNode.parentNode.parentNode.parentNode.attributes.pasukId?window.getSelection().anchorNode.parentNode.parentNode.parentNode.parentNode.attributes.pasukId.value : 0);
                    var focusNodePasukId = parseInt(window.getSelection().focusNode.parentNode.parentNode.parentNode.parentNode.attributes.pasukId?window.getSelection().focusNode.parentNode.parentNode.parentNode.parentNode.attributes.pasukId.value : 0);
                    if(focusNodePasukId==0 || anchorNodePasukId==0){
                        textCellPasukMakor=""
                    }else{
                        if(anchorNodePasukId < focusNodePasukId){
                            firstPasukLocation = window.getSelection().anchorNode.parentNode.parentNode.parentNode.parentNode.attributes.location.value;
                        } else {
                            firstPasukLocation = window.getSelection().focusNode.parentNode.parentNode.parentNode.parentNode.attributes.location.value;
                        }
                        textCellPasukMakor = firstPasukLocation;
                    }
                }
            }

            //removing underscores
            while(textCellPasukMakor.includes("_")){
                textCellPasukMakor = textCellPasukMakor.replace("_", " ")
            }
            while(textCellPasukText.includes("_")){
                textCellPasukText = textCellPasukText.replace("_", " ")
            }

            //open context menu
            this.setState({textCellPasukText, textCellPasukMakor, useKinuim: false, includeMekorot: false, isStatsContextMenu: false, wordToCopy}, () => {
                contextMenu.show({
                  id: 'parsha-viewer-context-menu',
                  event: event,
                });
                //style context menu
                var submenus = document.getElementsByClassName('react-contexify__submenu');
                for(let i = 0; i < submenus.length; i++){
                    var submenu = submenus[i];
                    var submenuWidth = parseInt(submenu.style.minWidth.slice(0, submenu.style.minWidth.length-2));
                    if(this.props.activeLanguage.code === 'he'){
                        if(event.clientX > submenuWidth){
                            submenu.style.left = '';
                            submenu.style.right = '100%';
                        } else {
                            submenu.style.left = '100%';
                            submenu.style.right = '';
                        }
                    } else if(this.props.activeLanguage.code === 'en'){
                        if(window.innerWidth-event.clientX-300 > submenuWidth){
                            submenu.style.left = '100%';
                            submenu.style.right = '';
                        } else {
                            submenu.style.left = '';
                            submenu.style.right = '100%';
                        }
                    }
                }
            });
        })

    }

    handleDoubleRightClick(event,column,word){
        var element = document.elementFromPoint(event.clientX, event.clientY)
        if(this.state.doubleOpeningContextMenu){ // forcing a second right click so taam parse gets disabled when clicking on cell padding
            contextMenu.hideAll();
            this.setState({doubleOpeningContextMenu: false}, () => {
                var ev = new MouseEvent("contextmenu", {
                    bubbles: true,
                    cancelable: false,
                    view: window,
                    button: 2,
                    buttons: 0,
                    clientX: event.clientX,
                    clientY: event.clientY
                });
                element.dispatchEvent(ev);
            });
        } else {
            this.setState({doubleOpeningContextMenu: true}, () => {
                this.onTextCellContextMenu(event,column);
            });
        }
    }

    onCellContextMenu(e){
        this.setState({useKinuim: false, includeMekorot: false, isStatsContextMenu: true});
        const { innerWidth } = window;
        //style context menu
        var submenus = document.getElementsByClassName('react-contexify__submenu');
        for(let i = 0; i < submenus.length; i++){
            var submenu = submenus[i];
            var submenuWidth = parseInt(submenu.style.minWidth.slice(0, submenu.style.minWidth.length-2));
            if(this.props.activeLanguage.code === 'he'){
                if(e.event.clientX > submenuWidth){
                    submenu.style.left = '';
                    submenu.style.right = '100%';
                } else {
                    submenu.style.left = '100%';
                    submenu.style.right = '';
                }
            } else if(this.props.activeLanguage.code === 'en'){
                if(innerWidth-e.event.clientX-300 > submenuWidth){
                    submenu.style.left = '100%';
                    submenu.style.right = '';
                } else {
                    submenu.style.left = '';
                    submenu.style.right = '100%';
                }
            }
        }

        //set text to copy
        var textToCopy = '';

        var focusedCellRowIndex = null ;
        if(this.resultsApi.getFocusedCell()){
            var focusedCellRowIndex = this.resultsApi.getFocusedCell().rowIndex;
        }
        var focusedCellRowText = null;
        var focusedCellSelected = null;
        if((typeof(focusedCellRowIndex) === 'number') && this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex]){
            focusedCellRowText = this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].data.text;
            focusedCellSelected = this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].selected;
        }

        //focused = row of focused cell; highlighted = highlighted text; selected = all selected rows;
        if(this.resultsApi.getFocusedCell() && !window.getSelection().toString() && !focusedCellSelected){
            textToCopy = 'focused';
        } else if(window.getSelection().toString()){
            textToCopy = 'highlighted';
//            tanach viewer rows are not selectable
            } else if (!!this.resultsApi.getSelectedRows()) {
                textToCopy = 'selected';
        }
        this.setState({textToCopy})
    }

    closeAllParshiot(){
        var newRowData = this.state.rowData;
        for(let i = 0; i < this.state.rowData.length; i++){
            if(newRowData[i] && newRowData[i].isTextRow){
                var newParshaInfoRow = newRowData[i-1];
                newParshaInfoRow.textIsOpen = false;

                var firstHalf = newRowData.slice(0, i-1);
                var secondHalf = newRowData.slice(i+1, newRowData.length);
                newRowData = firstHalf.concat(newParshaInfoRow).concat(secondHalf);
            }
        }
        this.setState({openParshaCount: 0, openParshiot: [], rowData: newRowData}, () => {
            this.resultsApi.setRowData(this.getDisplayRows())
            this.resultsApi.redrawRows();
        });
        if(this.state.closeObjectViewerWithNoParsha){
            this.setState({cloudViewerOpen:[]},()=>{
                this.props.toggleObjectCloudViewer(false,null)
            })
        }
    }

    increaseFontSize(){
        if(this.state.fontSizeLevel < 6){
            this.setState({fontSizeLevel: this.state.fontSizeLevel + 1}, () => {
                this.onFontSizeChange();
            })
        }
    }

    decreaseFontSize(){
        if(this.state.fontSizeLevel > 1){
            this.setState({fontSizeLevel: this.state.fontSizeLevel - 1}, () => {
                this.onFontSizeChange();
            })
        }
    }

    onFontSizeChange(){
//        this.setStatisticsRowFontSize();
//        this.setStatisticsColumnWidths();
        this.resultsApi.redrawRows();
        this.resultsApi.resetRowHeights();
    }

//    setStatisticsRowFontSize(){
//        // creating new stylesheet and adding new rule
//        // Using document.getElementsByClassName() then changing the style property doesn't affect rows which haven't rendered yet
//
//            //first disable any stylesheets that were previously created by this function
//            var styleSheets = document.styleSheets
//            for(let i = 0; i < styleSheets.length; i++){
//                if(styleSheets[i].ownerNode.className === 'parsha-viewer-font-change-stylesheet'){
//                    document.styleSheets[i].disabled = true
//                }
//            }
//
//        var styleEl = document.createElement('style');
//        styleEl.className = 'parsha-viewer-font-change-stylesheet'
//        document.head.appendChild(styleEl);
//        var styleSheet = styleEl.sheet;
//
//        styleSheet.insertRule('.parsha-stats-row span, .parsha-stats-row.row-thin, .parsha-stats-row.row-thin-bold, .parsha-stats-row.row-header-center' + `{
//                font-size: ${(this.state.fontSizeLevel * 2) + 12}px;
//            }`, styleSheet.cssRules.length);
//        styleSheet.insertRule('.parsha-viewer-button-cell' + `{
//                height: calc(100% - 6px) !important;
//                width: ${(this.state.fontSizeLevel * 2) + 16}px !important;
//                font-size: ${(this.state.fontSizeLevel * 2) + 16}px !important;
//
//            }`, styleSheet.cssRules.length);
//
//        this.resultsApi.onRowHeightChanged();
//    }

//    setStatisticsColumnWidths(){
//        var toggleTextButtonColumnWidth = ((this.state.fontSizeLevel * 2) + 22); // ranges from 24 to 34
//        var idColumnWidth = ((this.state.fontSizeLevel * 4) + 56); // ranges from 60 to 80
//        var seferColumnWidth = ((this.state.fontSizeLevel * 12) + 88); // ranges from 100 to 160
//        var kriaColumnWidth = ((this.state.fontSizeLevel * 11) + 89); // ranges from 100 to 155
//        var parshaTypeColumnWidth = ((this.state.fontSizeLevel * 4) + 76); // ranges from 80 to 100
//        var pasukRangeColumnWidth = ((this.state.fontSizeLevel * 10) + 90); // ranges from 100 to 150
//        var lettersColumnWidth = ((this.state.fontSizeLevel * 1) + 84); // ranges from 85 to 90
//        var gematriaColumnWidth = ((this.state.fontSizeLevel * 3) + 87); // ranges from 90 to 105
//        var firstTeivaColumnWidth = ((this.state.fontSizeLevel * 6) + 124); // ranges from 130 to 160
//        var lastTeivaColumnWidth = ((this.state.fontSizeLevel * 6) + 124); // ranges from 130 to 160
//        var topTeivaColumnWidth = ((this.state.fontSizeLevel * 6) + 124); // ranges from 130 to 160
//
//        var toggleTextButtonColumn = this.resultsColumnApi.getColumn("toggleTextButtonColumn");
//        var idColumn = this.resultsColumnApi.getColumn("id");
//        var seferColumn = this.resultsColumnApi.getColumn("sefer");
//        var kriaColumn = this.resultsColumnApi.getColumn("kria");
//        var parshaTypeColumn = this.resultsColumnApi.getColumn("parshaType");
//        var pasukRangeColumn = this.resultsColumnApi.getColumn("pasukRange");
//        var lettersColumn = this.resultsColumnApi.getColumn("letters");
//        var gematriaColumn = this.resultsColumnApi.getColumn("gematria");
//        var firstTeivaColumn = this.resultsColumnApi.getColumn("firstTeiva");
//        var lastTeivaColumn = this.resultsColumnApi.getColumn("lastTeiva");
//        var topTeivaColumn = this.resultsColumnApi.getColumn("topTeiva");
//
//        this.resultsColumnApi.setColumnWidth(toggleTextButtonColumn, toggleTextButtonColumnWidth);
//        this.resultsColumnApi.setColumnWidth(idColumn, idColumnWidth);
//        this.resultsColumnApi.setColumnWidth(seferColumn, seferColumnWidth);
//        this.resultsColumnApi.setColumnWidth(kriaColumn, kriaColumnWidth);
//        this.resultsColumnApi.setColumnWidth(parshaTypeColumn, parshaTypeColumnWidth);
//        this.resultsColumnApi.setColumnWidth(pasukRangeColumn, pasukRangeColumnWidth);
//        this.resultsColumnApi.setColumnWidth(lettersColumn, lettersColumnWidth);
//        this.resultsColumnApi.setColumnWidth(gematriaColumn, gematriaColumnWidth);
//        this.resultsColumnApi.setColumnWidth(firstTeivaColumn, firstTeivaColumnWidth);
//        this.resultsColumnApi.setColumnWidth(lastTeivaColumn, lastTeivaColumnWidth);
//        this.resultsColumnApi.setColumnWidth(topTeivaColumn, topTeivaColumnWidth);
//    }

      getGridColumns(){
          var fakeThis = this;
          return [
                    {headerName: "-/+", colId: 'toggleTextButtonColumn', width: 24, cellClass: "row-header-center parsha-viewer-button-cell parsha-stats-row", filter: 'agNumberColumnFilter', pinned: 'right', cellRenderer: function (params) {return fakeThis.buttonCellRenderer(params)}},
                    {headerName: "#", field: "id", width: 60, cellClass: "row-header-center parsha-stats-row", filter: 'agNumberColumnFilter', sortable: "true"},
                    {headerName: "ספר", field: "sefer", width: 100, cellClass: "row-thin parsha-stats-row", sortable: "true"} ,
                    {headerName: "קריאה", field: "kria", width: 100, cellClass: "row-thin parsha-stats-row", sortable: "true"} ,
                    {headerName: "סוג", field: "parshaType", width: 80, cellClass: "row-thin-bold parsha-stats-row", sortable: "true"} ,
                    {headerName: "מקום", field: "pasukRange", width: 100, cellClass: "row-thin parsha-stats-row", sortable: "true"} ,
                    {headerName: "פסוקים", field: "pesukim", width: 90, cellClass: "row-thin parsha-stats-row", filter: 'agNumberColumnFilter', sortable: "true"} ,
                    {headerName: "תיבות", field: "teivos", width: 85, cellClass: "row-thin parsha-stats-row", filter: 'agNumberColumnFilter', sortable: "true"} ,
                    {headerName: "אותיות", field: "letters", width: 85, cellClass: "row-thin parsha-stats-row", filter: 'agNumberColumnFilter', sortable: "true"} ,
                    {headerName: "גמטריה", field: "gematria", width: 90, cellClass: "row-thin parsha-stats-row", filter: 'agNumberColumnFilter', sortable: "true"},
                    {headerName: "תיבה ראשונה", field: "firstTeiva", width: 130, cellClass: "row-thin parsha-stats-row", sortable: "true"} ,
                    {headerName: "שורש ראשון", field: "firstShoresh", width: 130, cellClass: "row-thin parsha-stats-row", sortable: "true"} ,
                    {headerName: "תיבה אחרונה", field: "lastTeiva", width: 130, cellClass: "row-thin parsha-stats-row", sortable: "true"} ,
                    {headerName: "מילה מנחת", field: "topTeiva", width: 130, cellClass: "row-thin parsha-stats-row", sortable: "true"} ,
                    {headerName: "מקרים", field: "topTeivaCnt", width: 85, cellClass: "row-thin parsha-stats-row", filter: 'agNumberColumnFilter', sortable: "true"}
                 ];
      }

    buttonCellRenderer = (params) => {
        var cellContents = document.createElement('div');
        if(!params.data.textIsOpen){
            cellContents.innerHTML = '+';
        } else if (params.data.textIsOpen){
            cellContents.innerHTML = '-';
        }
        cellContents.className = 'parsha-viewer-button-cell-text';
        cellContents.style.width = 16;
        return cellContents;
    }

    handleToggleTextDisplayType(event, textDisplayType) {
        if(textDisplayType){
            this.setState({textDisplayType}, () => {
                this.resultsApi.redrawRows();
                this.resultsApi.resetRowHeights();
            });
        }
    }

    removeNikud(str){
        for (let i = str.length-1; i >= 0; i--){
            if('ְֱֲֳִֵֶַָֹֺֻּֽ־ׇֿׁׂׅׄ'.includes(str[i])){
                str = str.slice(0,i).concat(str.slice(i+1,str.length))
            }
        }
        return str;
    }

    removeTaamim(str){
        for (let i = str.length-1; i >= 0; i--){
            if('ֽ֑֖֛֢֣֤֥֦֧֪֚֭֮֒֓֔֕֗֘֙֜֝֞֟֠֡֨֩֫֬֯׀'.includes(str[i])){
                str = str.slice(0,i).concat(str.slice(i+1,str.length))
            }
        }
        return str;
    }

    getPasukGematria(pasukText){
        var gematriaStr = "";
        var pasukWords = this.removeNikud(this.removeTaamim(pasukText)).split(" ");
        for(let i = 0; i < pasukWords.length; i++){
            var currWord = pasukWords[i];
            var currGematria = Gematria(currWord).toMisparHaPanim();
            gematriaStr += `${currGematria} `;
        }
        return gematriaStr;
    }

    getPasukAtbash(pasukText){
        var atbashStr = "";
        var pasukWords = this.removeNikud(this.removeTaamim(pasukText)).split(" ");
        for(let i = 0; i < pasukWords.length; i++){
            var currWord = pasukWords[i];
            var currAtbash = this.getWordAtbash(currWord);
            atbashStr += `${currAtbash} `;
        }
        return atbashStr;
    }

    getWordAtbash(word){
        var atbashVal = 0;
        var atbashLetters = {
          "א": "ת",
          "ב": "ש",
          "ג": "ר",
          "ד": "ק",
          "ה": "צ",
          "ו": "פ",
          "ז": "ע",
          "ח": "ס",
          "ט": "נ",
          "י": "מ",
          "כ": "ל",
          "ך": "ל",
          "ל": "כ",
          "מ": "י",
          "ם": "י",
          "נ": "ט",
          "ן": "ט",
          "ס": "ח",
          "ע": "ז",
          "פ": "ו",
          "ף": "ו",
          "צ": "ה",
          "ץ": "ה",
          "ק": "ד",
          "ר": "ג",
          "ש": "ב",
          "ת": "א",
        }
    
        for(let i = 0; i < word.length; i++){
          var currLetter = word[i];
          var letterAtbashVal = Gematria(atbashLetters[currLetter]).toMisparHaPanim();
          atbashVal += letterAtbashVal;
        }
        return atbashVal;
    }

    getStreamCellContents(params){ // see comment below for attempt to get word-to-word hightlighting 
        var tanachTextDiv = document.createElement('div');
        var tanachTextStream = '<div class="parsha-viewer-text-stream">';
        var targumTextDiv = document.createElement('div');
        var targumTextStream = params.data.body[0].targum ? '<div class="parsha-viewer-text-stream">' : "";
        var stamTextDiv = document.createElement('div');
        var stamTextStream = '<div class="parsha-viewer-stam-stream">';
        var ivriTextDiv = document.createElement('div');
        var ivriTextStream = '<div class="parsha-viewer-ivri-stream">';
        var gematriaTextDiv = document.createElement('div');
        var gematriaTextStream = '<div class="parsha-viewer-gematria-stream">';
        var atbashTextDiv = document.createElement('div');
        var atbashTextStream = '<div class="parsha-viewer-atbash-stream">';


        for(let i = 0; i < params.data.body.length; i++){
            var currPasuk = params.data.body[i]
            //removing spaces from location, text so they can be used as attribute in HTML
            var noSpaceLocation = currPasuk.location;
            while(noSpaceLocation.includes(" ")){
                noSpaceLocation = noSpaceLocation.replace(" ", "_");
            }
            var noSpaceText = currPasuk.text;
            while(noSpaceText.includes(" ")){
                noSpaceText = noSpaceText.replace(" ", "_");
            }
            var makorArray = currPasuk.location.split(':');
            var perekPasuk = `${makorArray[1]}:${makorArray[2]}`
            var noNikkudTaamimText = currPasuk.text;
            noNikkudTaamimText = this.removeNikud(this.removeTaamim(noNikkudTaamimText))

            var tanach = currPasuk.text.split(" ").map(word=>{return`<span class='word-span' word=${word}>${word}</span>`})
            var stam = currPasuk.text.split(" ").map(word=>{return`<span class='word-span' word=${word}>${word}</span>`})
            var ivri = noNikkudTaamimText.split(" ").map(word=>{return`<span class='word-span' word=${word}>${word}</span>`})
            var gematria = this.getPasukGematria(currPasuk.text).split(" ").map(word=>{return`<span class='word-span' word=${word}>${word}</span>`})
            var atbash = this.getPasukAtbash(currPasuk.text).split(" ").map(word=>{return`<span class='word-span' word=${word}>${word}</span>`})
            
            tanachTextStream += `<span class='text-cell-pasuk-span' location=${noSpaceLocation} text=${noSpaceText} pasukId=${currPasuk.pasukId}><span><b>(${perekPasuk})</b><span> ${tanach.join(" ")} </span></span></span>`;
            if(params.data.body[0].targum) {
                var noSpaceTargum = currPasuk.targum;
                while(noSpaceTargum.includes(" ")){
                    noSpaceTargum = noSpaceTargum.replace(" ","_");
                }    
                var targum = currPasuk.targum.split(" ").map(word=>{return`<span class='word-span' word=${word}>${word}</span>`})
                targumTextStream += `<span class='text-cell-pasuk-span' location=${noSpaceLocation} text=${noSpaceText} targum=${noSpaceTargum} pasukId=${currPasuk.pasukId}><span><b>(${perekPasuk})</b><span> ${targum.join(" ")} </span></span></span>`;
            }
            stamTextStream += `<span class='text-cell-pasuk-span' location=${noSpaceLocation} text=${noSpaceText} pasukId=${currPasuk.pasukId}><span><span> ${stam.join(" ")} </span></span></span>`;
            ivriTextStream += `<span class='text-cell-pasuk-span ivri-pasuk-span' location=${noSpaceLocation} text=${noSpaceText} pasukId=${currPasuk.pasukId}><span><span> ${ivri.join(" ")} </span></span></span>`;
            gematriaTextStream += `<span class='text-cell-pasuk-span' location=${noSpaceLocation} text=${noSpaceText} pasukId=${currPasuk.pasukId}><span><b>(${perekPasuk})</b><span> ${gematria.join(" ")} </span></span></span>`;
            atbashTextStream += `<span class='text-cell-pasuk-span' location=${noSpaceLocation} text=${noSpaceText} pasukId=${currPasuk.pasukId}><span><b>(${perekPasuk})</b><span> ${atbash.join(" ")} </span></span></span>`;

        }

        //close the text streams, wrap them in parent divs
        tanachTextStream += '</div>';
        tanachTextDiv.innerHTML = tanachTextStream;
        tanachTextDiv.style.fontSize = `${(this.state.fontSizeLevel * 2) + 16}px`; // ranges from 18 to 28
        tanachTextDiv.oncontextmenu = (e) => {this.handleDoubleRightClick(e,"תנך")};

        if(params.data.body[0].targum){
            targumTextStream += '</div>';
            targumTextDiv.innerHTML = targumTextStream;
            targumTextDiv.style.fontSize = `${(this.state.fontSizeLevel * 2) + 16}px`; // ranges from 18 to 28
            targumTextDiv.oncontextmenu = (e) => {this.handleDoubleRightClick(e,"תרגום")};
        }

        stamTextStream += '</div>';
        stamTextDiv.innerHTML = stamTextStream;
        stamTextDiv.style.fontSize = `${(this.state.fontSizeLevel * 2) + 16}px`; // ranges from 18 to 28
        stamTextDiv.oncontextmenu = (e) => {this.handleDoubleRightClick(e,"סתם")};

        ivriTextStream += '</div>';
        ivriTextDiv.innerHTML = ivriTextStream;
        ivriTextDiv.style.fontSize = `${(this.state.fontSizeLevel * 2) + 16}px`; // ranges from 18 to 28
        ivriTextDiv.oncontextmenu = (e) => {this.handleDoubleRightClick(e,"עברי")};

        gematriaTextStream += '</div>';
        gematriaTextDiv.innerHTML = gematriaTextStream;
        gematriaTextDiv.style.fontSize = `${(this.state.fontSizeLevel * 2) + 16}px`; // ranges from 18 to 28
        gematriaTextDiv.oncontextmenu = (e) => {this.handleDoubleRightClick(e,"גמטריא")};

        atbashTextStream += '</div>';
        atbashTextDiv.innerHTML = atbashTextStream;
        atbashTextDiv.style.fontSize = `${(this.state.fontSizeLevel * 2) + 16}px`; // ranges from 18 to 28
        atbashTextDiv.oncontextmenu = (e) => {this.handleDoubleRightClick(e,"אתבש")};

        //append relevant divs to cellContents
        var cellContents = document.createElement('div')
        cellContents.addEventListener('mousedown',()=>{this.setState({lastClickedParsha:params.data.parshaId},()=>{
            if(this.state.cloudViewerOpen.includes("cloud")) this.props.toggleObjectCloudViewer(true,this.state.lastClickedParsha)
        })})
        cellContents.className = `parsha-viewer-text-cell parsha-id-${params.data.parshaId}`;
        if(this.state.contentDisplayed.includes('tanach')){
            cellContents.append(tanachTextDiv)
        }
        if(this.state.contentDisplayed.includes('targum') && params.data.body[0].targum){
            cellContents.append(targumTextDiv)
        }
        if(this.state.contentDisplayed.includes('ktavStam')){
            cellContents.append(stamTextDiv)
        }
        if(this.state.contentDisplayed.includes('ktavIvri')){
            cellContents.append(ivriTextDiv)
        }
        if(this.state.contentDisplayed.includes('gematria')){
            cellContents.append(gematriaTextDiv)
        }
        if(this.state.contentDisplayed.includes('atbash')){
            cellContents.append(atbashTextDiv)
        }

        return cellContents;
    }
    // cloudResultsGridReady = params=>{
    //     this.resultsApi = params.api;
    //     this.resultsColumnApi = params.columnApi;
    //     this.gridOptions = this.resultsApi.gridOptionsWrapper.gridOptions;
    //     this.gridOptions.isFullWidthCell = (rowNode) => {return rowNode.data.isTextRow};
    //     this.gridOptions.getRowHeight = (params)  => {
    //         if(!params.data.isTextRow){
    // //            return (this.state.fontSizeLevel * 3) + 22
    //             return 25;
    //         } else {
    //             return 150;
                
    //         }
    //     };
    //     this.resultsApi.resetRowHeights();
    //   };
    

  resultsGridReady = params => {
    this.resultsApi = params.api;
    this.resultsColumnApi = params.columnApi;
    this.gridOptions = this.resultsApi.gridOptionsWrapper.gridOptions;
    this.gridOptions.fullWidthCellRenderer = (params) => {
        if(this.state.textDisplayType === 'stream'){
            return this.getStreamCellContents(params);
        } else if(this.state.textDisplayType === 'table'){
                var cellContents = document.createElement('div');
                var text = params.data.text;
                var textTable = '<table class="parsha-viewer-text-table">';
                for(let i = 0; i < params.data.body.length; i++){
                    //removing spaces from location so they can be used as attribute in HTML
                    var currPasuk = params.data.body[i];
                    var pasukArray = currPasuk.text.split(" ");
                    for(let j=0; j<pasukArray.length;j++){
                        pasukArray[j] = `<span>${pasukArray[j]}</span>`
                    }
                     currPasuk.spannedText = pasukArray.join(" ")
                     var noSpaceLocation = currPasuk.location;
                     while(noSpaceLocation.includes(" ")){
                          noSpaceLocation = noSpaceLocation.replace(" ", "_");
                     }
                     var makorArray = currPasuk.location.split(':');
                     var perekPasuk = `${makorArray[1]}:${makorArray[2]}`
                     var pasukRow =    `<tr pasukId=${currPasuk.pasukId} class='text-cell-pasuk-row'>` +
                                       `<td class="parsha-viewer-text-table-makor" location=${noSpaceLocation} >${perekPasuk}</td>` +
                                       `<td class="parsha-viewer-text-table-text">${currPasuk.spannedText}</td>` +
                                     '</tr>';
                     textTable += pasukRow;
                }
                textTable += '</table>';
                cellContents.innerHTML = textTable;
                cellContents.className = `parsha-viewer-text-cell parsha-id-${params.data.parshaId}`;
                cellContents.addEventListener('mousedown',()=>{this.setState({lastClickedParsha:params.data.parshaId},()=>{
                    if(this.state.cloudViewerOpen.includes("cloud")) this.props.toggleObjectCloudViewer(true,this.state.lastClickedParsha)
                })})
                cellContents.style.fontSize = `${(this.state.fontSizeLevel * 2) + 12}px`; // ranges from 14 to 24
                cellContents.oncontextmenu = (e) => {this.handleDoubleRightClick(e)};
                return cellContents;
        }
    }
    this.gridOptions.isFullWidthCell = (rowNode) => {return rowNode.data.isTextRow};
    this.gridOptions.getRowHeight = (params)  => {
        if(!params.data.isTextRow){
//            return (this.state.fontSizeLevel * 3) + 22
            return 25;
        } else {
            if(this.state.textDisplayType === 'stream'){
                // todo - find better way to calculate row height for plain text rows; this is only an approximation
                var cellCharCount = params.data.text.length;
                var charToHeightRatio = 0;
                if(this.state.fontSizeLevel === 1){
                    charToHeightRatio = 11.8;
                } else if (this.state.fontSizeLevel === 2){
                    charToHeightRatio = 9.5;
                } else if (this.state.fontSizeLevel === 3){
                    charToHeightRatio = 7.9;
                } else if (this.state.fontSizeLevel === 4){
                    charToHeightRatio = 6.6;
                } else if (this.state.fontSizeLevel === 5){
                    charToHeightRatio = 5.6;
                } else if (this.state.fontSizeLevel === 6){
                    charToHeightRatio = 4.8;
                }
                var lineHeight = ((this.state.fontSizeLevel * 2) + 16) * 1.5;
                var approximatedHeight = cellCharCount/charToHeightRatio; // rough ratio of character count to the amount of space needed
                var roundedHeight = (Math.ceil(approximatedHeight/lineHeight) * lineHeight) + 16 //rounding to line height
                var totalDisplayedPanels = this.state.contentDisplayed.length;
                if(this.state.contentDisplayed.includes('targum') && !params.data.body[0].targum){
                    totalDisplayedPanels--
                }
                roundedHeight *= totalDisplayedPanels; // multiplying by number of panels displayed
                roundedHeight += 16; // adding 16 for padding, border around cell
                return roundedHeight;
            } else if (this.state.textDisplayType === 'table'){
                var pasukRowHeight = (this.state.fontSizeLevel * 3) + 23;
                var pasukTableHeight = ((params.data.body.length) * pasukRowHeight);
                var padding = 16;
                return pasukTableHeight + padding;
            }
        }
    };
    this.resultsApi.resetRowHeights();
  }

  toggleCloudViewerOpen(event, cloudOpen){
      this.setState({cloudViewerOpen:cloudOpen},()=>{
        if(this.state.cloudViewerOpen.includes("cloud")){
            if(event)this.setState({closeObjectViewerWithNoParsha:true})
            if(this.state.openParshaCount>0){
                this.props.toggleObjectCloudViewer(true,this.state.lastClickedParsha)
            }
        }else{
            this.props.toggleObjectCloudViewer(false,null)
        }
    })
  }

  async handleCellClicked(e){
    var clickedRowParshaId = e.data.id;
    var textIsOpen = e.data.textIsOpen;
    if (e.colDef.headerName === "-/+"){

        if(e.data.textIsOpen === false && this.state.isFetchingParsha === false){
            //open the parsha
            this.setState({
                isFetchingParsha: true
            }, async () => {
                const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getParsha?id=' + clickedRowParshaId);
                var body = await response.json();
                body = this.getRestructuredParshaData(body)
                var text = ''
                for(let i = 0; i < body.length; i++){
                    var makorArray = body[i].location.split(':');
                    var perekPasuk = `${makorArray[1]}:${makorArray[2]}`
                    text = text.concat(`(${perekPasuk}) `).concat(body[i].text).concat(': ');
                }
    
                var textRowData = {
                    isTextRow: true,
                    parshaId: clickedRowParshaId,
                    text: text,
                    firstShoresh: e.data.firstShoresh,
                    firstTeiva: e.data.firstTeiva,
                    gematria: e.data.gematria,
                    id: e.data.id + 'text',
                    kria: e.data.kria,
                    lastTeiva: e.data.lastTeiva,
                    letters: e.data.letters,
                    parshaType: e.data.parshaType,
                    pasukRange: e.data.pasukRange,
                    pesukim: e.data.pesukim,
                    sefer: e.data.sefer,
                    seferId: e.data.seferId,
                    teivos: e.data.teivos,
                    textIsOpen: true,
                    topTeiva: e.data.topTeiva,
                    topTeivaCnt: e.data.topTeivaCnt,
                    body: body,
                }
    
                var newRowData = this.state.rowData;
                for(let i = 0; i < newRowData.length; i++){
                    let currData = newRowData[i];
                    if(currData.id === clickedRowParshaId){
                        currData.textIsOpen = true;
    
                        var firstHalf = newRowData.slice(0,i);
                        var secondHalf = newRowData.slice(i+1, newRowData.length);
                        newRowData = firstHalf.concat(currData).concat(textRowData).concat(secondHalf)
                    }
                }

                var openParshiot = this.state.openParshiot;
                if(!openParshiot.includes(e.data.id)){
                    openParshiot.push(e.data.id)
                }

                this.setState({openParshaCount: this.state.openParshaCount+1, openParshiot: openParshiot, rowData: newRowData}, () => {
                    this.resultsApi.setRowData(this.getDisplayRows())
                    this.resultsApi.redrawRows();
                    this.setState({
                        isFetchingParsha: false
                    })
                });
            })

        } else if (e.data.textIsOpen === true) {
            //close the parsha
            var index = this.state.rowData.findIndex(item => {
                return item.isTextRow === true && item.parshaId === clickedRowParshaId;
            });

            var newParshaInfoRow = this.state.rowData[index-1];
            newParshaInfoRow.textIsOpen = false;

            var firstHalf = this.state.rowData.slice(0, index-1);
            var secondHalf = this.state.rowData.slice(index+1, this.state.rowData.length);
            var newRowData = firstHalf.concat(newParshaInfoRow).concat(secondHalf)

            var openParshiot = this.state.openParshiot;
            if(openParshiot.includes(e.data.id)){
                openParshiot = openParshiot.filter(function(parshaIndex){
                    return parshaIndex !== e.data.id;
                });
            }

            this.setState({openParshaCount: this.state.openParshaCount-1, openParshiot: openParshiot, rowData: newRowData}, () => {
                this.resultsApi.setRowData(this.getDisplayRows())
                this.resultsApi.redrawRows();
                if(this.state.openParshaCount==0 && this.state.closeObjectViewerWithNoParsha){
                    this.setState({cloudViewerOpen:[]},()=>{
                        this.props.toggleObjectCloudViewer(false,null)
                    })
                }
            });
        }
    }
    if((e.colDef.headerName === "-/+" && textIsOpen === false) || (e.colDef.headerName !== "-/+" && textIsOpen === true)){
        this.setState({lastClickedParsha:clickedRowParshaId},()=>{
            if(this.state.cloudViewerOpen.includes("cloud")) this.props.toggleObjectCloudViewer(true,this.state.lastClickedParsha)
        })
    }
  }

  getRestructuredParshaData(body){
        var newRows = body;
        for(let i = 0; i < newRows.length; i++){
          var currRow = newRows[i];
          //restructure targum map
          if(newRows[0] && newRows[0].targumMap && newRows[0].torahMap){
            var torahMap = currRow.torahMap;
            var torahMapArr = torahMap.split(";")
            var torahWordMapObj = {};
            for(let i = 0; i < torahMapArr.length; i++){
              var torahMapWordArr = torahMapArr[i].split(",");
              if(!torahWordMapObj[torahMapWordArr[0]]) {
                torahWordMapObj[torahMapWordArr[0]] = [torahMapWordArr[1]];
              } else {
                torahWordMapObj[torahMapWordArr[0]].push(torahMapWordArr[1]);
              }
            }
            var targumMap = currRow.targumMap;
            var targumMapArr = targumMap.split(";")
            var targumWordMapObj = {};
            for(let i = 0; i < targumMapArr.length; i++){
              var targumMapWordArr = targumMapArr[i].split(",");
              if(!targumWordMapObj[targumMapWordArr[0]]) {
                targumWordMapObj[targumMapWordArr[0]] = [targumMapWordArr[1]];
              } else {
                targumWordMapObj[targumMapWordArr[0]].push(targumMapWordArr[1]);
              }
            }
      
            var torahPhraseMapObj = Object.assign({}, torahWordMapObj);
            var targumPhraseMapObj = Object.assign({}, targumWordMapObj);
            var torahPhraseMapObjKeys = Object.keys(torahPhraseMapObj);
            var targumPhraseMapObjKeys = Object.keys(targumPhraseMapObj);
      
            for(let i = 0; i < torahPhraseMapObjKeys.length; i++){
              var currKey = torahPhraseMapObjKeys[i];
              var matchIds = torahPhraseMapObj[currKey];
              if(matchIds.length > 1){
                var targumIndices = [];
                for(let j = 0; j < matchIds.length; j++){
                  var matchId = matchIds[j]
                  var targumIndex = Object.keys(targumPhraseMapObj).find(key => targumPhraseMapObj[key].includes(matchId));
                  targumIndices.push(targumIndex)
                }
                for(let k = 0; k < targumIndices.length; k++){
                  var currTargumIndex = targumIndices[k]
                  targumPhraseMapObj[currTargumIndex] = targumPhraseMapObj[currTargumIndex].concat(matchIds.filter((item) => targumPhraseMapObj[currTargumIndex].indexOf(item) < 0))
                }
              }
            }
            for(let i = 0; i < targumPhraseMapObjKeys.length; i++){
              var currKey = targumPhraseMapObjKeys[i];
              var matchIds = targumPhraseMapObj[currKey];
              if(matchIds.length > 1){
                var torahIndices = [];
                for(let j = 0; j < matchIds.length; j++){
                  var matchId = matchIds[j]
                  var torahIndex = Object.keys(torahPhraseMapObj).find(key => torahPhraseMapObj[key].includes(matchId));
                  torahIndices.push(torahIndex)
                }
                for(let k = 0; k < torahIndices.length; k++){
                  var currTorahIndex = torahIndices[k]
                  torahPhraseMapObj[currTorahIndex] = torahPhraseMapObj[currTorahIndex].concat(matchIds.filter((item) => torahPhraseMapObj[currTorahIndex].indexOf(item) < 0))
                }
              }
            }
      
            currRow.torahWordMapObj = torahWordMapObj;
            currRow.targumWordMapObj = targumWordMapObj;
            currRow.torahPhraseMapObj = torahPhraseMapObj;
            currRow.targumPhraseMapObj = targumPhraseMapObj;
          }
        }
        return newRows;
  }
  componentDidUpdate(prevProps,prevState){
      if(this.props.isObjectCloudViewerOpen !== prevProps.isObjectCloudViewerOpen){
          if(this.state.cloudViewerOpen.includes("cloud") && !this.props.isObjectCloudViewerOpen){
            this.toggleCloudViewerOpen(null,[])
          }else if(!this.state.cloudViewerOpen.includes("cloud") && this.props.isObjectCloudViewerOpen){
              this.setState({closeObjectViewerWithNoParsha:false},()=>{
        //           this.toggleCloudViewerOpen(null,["cloud"])
              })
          }
      }
  }


  async componentDidMount() {
      this.setLanguage(localStorage.getItem("language"));

      const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getParshiosTanach?source=bhsWebApp&hexFilterIds=FFFFFFFFFFF');
      const body = await response.json()
      
      var parshaBatch = body.parshaBatch;

      for(let i = 0; i < parshaBatch.length; i ++){
        parshaBatch[i].textIsOpen = false;
        parshaBatch[i].isTextRow = false;
      }
      this.setState({ rowData: parshaBatch, searchedFilterIds: "FFFFFFFFFFF", isLoading: false, totalRows: body.totalParshiot }, () => {
        if(this.resultsApi){
            this.resultsApi.setRowData(this.getDisplayRows())
        }
        this.fixToggleColumnWidth();
      });

      this.props.setHelpTopic("parshaViewer");
    //   if(!this.state.cloudViewerOpen.includes("cloud") && this.props.isObjectCloudViewerOpen){
    //     this.toggleCloudViewerOpen(null,["cloud"])
    //   }
  }

  fixToggleColumnWidth(){
    //hack to prevent scroll bar from showing up over text toggle buttons and get text to take up full cell width on first load
    if(this.resultsColumnApi){
        var buttonColumn = this.resultsColumnApi.getColumn("toggleTextButtonColumn")
        this.resultsColumnApi.setColumnWidth(buttonColumn, 25)
        this.resultsColumnApi.setColumnWidth(buttonColumn, 24)
    }
  }

  async refresh(event) {
    event.preventDefault();
    this.setState({ isLoading: true });

    var filterIds = encodeFilterIds(this.props.extFilterData);

    filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
    filterIds = util.right("00000000000"+filterIds,11);
  //alert(filterIds + ":" + this.props.extSearchDefData);
    //morphInflections = LNTSAR
    var url = properties.env.webServiceURL + '/BaseHaSefer/getParshiosTanach?source=bhsWebApp';
    url += '&hexFilterIds=' + filterIds;
    url += '&batchNum=' + 1;
    const response = await fetch(url)//+'&clientIP='+this.clientIP);
    const body = await response.json();

    var parshaBatch = body.parshaBatch;

      for(let i = 0; i < parshaBatch.length; i ++){
        parshaBatch[i].textIsOpen = false;
        parshaBatch[i].isTextRow = false;
      }

    this.setState({ rowData: parshaBatch, searchedFilterIds: filterIds, isLoading: false, totalRows: body.totalParshiot }, () => {
        this.resultsApi.setRowData(this.getDisplayRows())
        this.fixToggleColumnWidth()
        this.closeAllParshiot();
        this.goToPage(1)
    });
  }

  
handleRowsPerPage(e){
    if(e.target.value !== this.state.rowsPerPage){
        if(e.target.value === "showAll"){
            this.handleShowAll();
        } else if (this.state.rowsPerPage === "showAll"){
            this.handleExitShowAll(e);
        } else {
            var prevFirstRow = this.state.rowsPerPage === "showAll" ? 1 : ((this.state.currentPage - 1) * this.state.rowsPerPage) + 1;
            var newPage = e.target.value === "showAll" ? 1 : Math.ceil(prevFirstRow / e.target.value);
            this.setState({
                rowsPerPage: e.target.value,
            }, () => {
                this.goToPage(newPage)
            })
        }
    }
}

async handleExitShowAll(e){
    this.setState({ isLoading: true, loadingPage: 1 });
    
    var filterIds = this.state.searchedFilterIds;

    var url = properties.env.webServiceURL + '/BaseHaSefer/getParshiosTanach?source=bhsWebApp';
    url += '&hexFilterIds=' + filterIds;
    url += '&batchNum=' + 1;
    const response = await fetch(url);
    const body = await response.json();

    var parshaBatch = body.parshaBatch;

    for(let i = 0; i < parshaBatch.length; i ++){
        parshaBatch[i].textIsOpen = false;
        parshaBatch[i].isTextRow = false;
    }

    var rowData = await this.fetchTextForOpenParshiot(parshaBatch)

    this.setState({ rowData: rowData, currentPage: 1, rowsPerPage: e.target.value, isLoading: false, totalRows: body.totalParshiot }, () => {
        this.resultsApi.setRowData(this.getDisplayRows())

        this.fixToggleColumnWidth()
    });
}

async handleShowAll(){
    this.setState({ isLoading: true });

    var url = properties.env.webServiceURL + '/BaseHaSefer/getParshiosTanach?source=bhsWebApp';
    url += '&hexFilterIds=' + this.state.searchedFilterIds;
    url += '&returnAll=' + true;
    const response = await fetch(url);
    const body = await response.json();

    var parshaBatch = body.parshaBatch;

    for(let i = 0; i < parshaBatch.length; i ++){
        parshaBatch[i].textIsOpen = false;
        parshaBatch[i].isTextRow = false;
    }

    var rowData = await this.fetchTextForOpenParshiot(parshaBatch)

    this.setState({ rowData: rowData, isLoading: false, rowsPerPage: "showAll", totalRows: body.totalParshiot }, () => {
        this.resultsApi.setRowData(this.state.rowData)
        this.fixToggleColumnWidth()
        this.goToPage(1)
    });
}

async fetchTextForOpenParshiot(rowData){ //using this to re-open parshiot after changing buffers;

    for(let i = rowData.length-1; i >= 0; i--){
        var currRow = rowData[i];
        var willFetchParsha = this.state.openParshiot.includes(currRow.id) && currRow.textIsOpen === false;
        if(willFetchParsha){
            const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getParsha?id=' + currRow.id);
            var body = await response.json();
            body = this.getRestructuredParshaData(body)
            var text = ''
            for(let i = 0; i < body.length; i++){
                var makorArray = body[i].location.split(':');
                var perekPasuk = `${makorArray[1]}:${makorArray[2]}`
                text = text.concat(`(${perekPasuk}) `).concat(body[i].text).concat(': ');
            }
            var textRowData = {
                isTextRow: true,
                parshaId: currRow.id,
                text: text,
                firstShoresh: currRow.firstShoresh,
                firstTeiva: currRow.firstTeiva,
                gematria: currRow.gematria,
                id: currRow.id + 'text',
                kria: currRow.kria,
                lastTeiva: currRow.lastTeiva,
                letters: currRow.letters,
                parshaType: currRow.parshaType,
                pasukRange: currRow.pasukRange,
                pesukim: currRow.pesukim,
                sefer: currRow.sefer,
                seferId: currRow.seferId,
                teivos: currRow.teivos,
                textIsOpen: true,
                topTeiva: currRow.topTeiva,
                topTeivaCnt: currRow.topTeivaCnt,
                body: body,
            }

            rowData.splice(i+1, 0, textRowData)

            rowData[i].textIsOpen = true;
        }
    }
    return rowData;
}

isNextPageDisabled(){
    var totalPages = Math.ceil(this.state.totalRows/this.state.rowsPerPage);
    return this.state.rowsPerPage === "showAll" || this.state.currentPage === totalPages;
}

getNextPage(){
    if(!this.isNextPageDisabled()){
        this.goToPage(this.state.currentPage + 1)
    }
}

getLastPage(){
    if(!this.isNextPageDisabled()){
        var lastPage = Math.ceil(this.state.totalRows/this.state.rowsPerPage);
        this.goToPage(lastPage);
    }
}

isPrevPageDisabled(){
    return this.state.rowsPerPage === "showAll" || this.state.currentPage === 1;
}

getPrevPage(){
    if(!this.isPrevPageDisabled()){
        this.goToPage(this.state.currentPage - 1)
    }
}

getFirstPage(){
    if(!this.isPrevPageDisabled()){
        this.goToPage(1);
    }
}

async goToPage(pageNum){
    const isNewBuffer = this.isNewBuffer(pageNum);
    if(!isNewBuffer){
        this.setState({
            currentPage: pageNum,
            loadingPage: pageNum
        }, () => {
            this.resultsApi.setRowData(this.getDisplayRows())
            this.fixToggleColumnWidth()
        })
    } else {
        this.setState({ isLoading: true, loadingPage: pageNum }, async () => {
            var filterIds = this.state.searchedFilterIds;
            var batchNum = null;
            const newFirstRow = ((pageNum - 1) * this.state.rowsPerPage) + 1;
            batchNum = Math.floor(((newFirstRow - 1) / 400) + 1);
    
            var url = properties.env.webServiceURL + '/BaseHaSefer/getParshiosTanach?source=bhsWebApp';
            url += '&hexFilterIds=' + filterIds;
            url += '&batchNum=' + batchNum;
            const response = await fetch(url);
            const body = await response.json();

            var parshaBatch = body.parshaBatch;
        
            for(let i = 0; i < parshaBatch.length; i ++){
                parshaBatch[i].textIsOpen = false;
                parshaBatch[i].isTextRow = false;
            }
              
            var rowData = await this.fetchTextForOpenParshiot(parshaBatch);

            this.setState({ rowData: rowData, currentPage: pageNum, isLoading: false, totalRows: body.totalParshiot }, () => {
                this.resultsApi.setRowData(this.getDisplayRows())
    
                this.fixToggleColumnWidth()
            });
        });
    }
}

  getPagePickerItems(){
      var menuItems = [];
      var totalPages;
      var totalRows = this.state.totalRows;
      if(this.state.rowsPerPage === "showAll"){
        totalPages = 1;
      } else {
        totalPages = Math.ceil(totalRows/this.state.rowsPerPage)
      }
      for(let i = 0; i < totalPages; i++){
          var menuItem = <MenuItem value={i+1}>{i+1}</MenuItem>;
          menuItems.push(menuItem)
      }
      return menuItems;
  }

  getDisplayRows(){
      var rowData = this.state.rowData;
      var displayRows = [];
      var firstFilterIndex = this.state.rowsPerPage === "showAll" ? 0 : ((this.state.loadingPage - 1) * this.state.rowsPerPage);
      
      if(rowData){
        const firstRowIndex = rowData.findIndex(e => e.filterIndex === firstFilterIndex);
        const firstRow = rowData[firstRowIndex]
    
        var rowCountPerPage = this.state.rowsPerPage === "showAll" ? this.state.totalRows : this.state.rowsPerPage
        var i = firstRowIndex;
        var currRow = firstRow;
        while(currRow && i < rowData.length && (typeof(currRow.id) === "string" || currRow.filterIndex < firstRow.filterIndex + rowCountPerPage)){ 
          displayRows.push(currRow)
  
          i++;
          currRow = rowData[i];
        }
      }
      return displayRows
  }

  isNewBuffer(newPageNum){
      var isNewBuffer = false;
    //   if the first id of the selected page is not in range
    //   curr buffer: first id to last id
    const currBufferFirstFilterIndex = this.state.rowData[0].filterIndex;
    const lastRowIsOpen = typeof(this.state.rowData[this.state.rowData.length-1].id) === "string";
    const currBufferLastFilterIndex = lastRowIsOpen ? this.state.rowData[this.state.rowData.length-2].filterIndex : this.state.rowData[this.state.rowData.length-1].filterIndex;

    const newFirstFilterIndex = ((newPageNum - 1) * this.state.rowsPerPage);
    if(newFirstFilterIndex < currBufferFirstFilterIndex || newFirstFilterIndex > currBufferLastFilterIndex){
        isNewBuffer = true;
    }
    return isNewBuffer;
  }

  goHome(){
    if(this.state.closeObjectViewerWithNoParsha){
        this.setState({cloudViewerOpen:[]},()=>{
            this.props.toggleObjectCloudViewer(false,null)
        })
    }
    this.props.extCloseDockedViewer();
  }

  handleCopyLink() {
    this.props.handleCopyLink("parshaViewer");
  }

  render() {
    const fakeThis = this;
    const {item} = this.state;
    const {rowData, isLoading} = this.state;
    const totalParshios = this.props.translate("totalParshios");
    const loadingText = this.props.translate("loading");
    const increaseFont = this.props.translate("increaseFont.caption");
    const decreaseFont = this.props.translate("decreaseFont.caption");
    const table = this.props.translate("table");
    const stream = this.props.translate("stream");
    const firstPage = this.props.translate("firstPage");
    const lastPage = this.props.translate("lastPage");
    const prevPage = this.props.translate("prevPage");
    const nextPage = this.props.translate("nextPage");
    const resultsPerPage = this.props.translate("resultsPerPage");
    const showAll = this.props.translate("showAll");
    const wordCloud = this.props.translate("wordCloud");
    const copyLink = this.props.translate('copyLink');
    const { classes } = this.props;

    var viewerTableHeightNum = parseInt(this.props.viewerHeight.substring(0, this.props.viewerHeight.length - 2)) - 64;
    if(this.props.openResults.length > 0){
        viewerTableHeightNum -= 12;
    }
    const viewerTableHeight = `${viewerTableHeightNum}px`

    var directionStyling = {direction: 'ltr'};
    var langDirection = 'ltr'
    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
        directionStyling = {direction: 'rtl', textAlign: 'center'};
        langDirection = 'rtl';
    }

    var increaseFontButton = <Button aria-label={increaseFont} title={increaseFont} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.increaseFontSize() }}>
                                  <img src={require("./images/icons8-increase-font-52.png")} alt={increaseFont} width="20" height="20" />
                              </Button>
    var decreaseFontButton = <Button aria-label={decreaseFont} title={decreaseFont} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.decreaseFontSize() }}>
                                  <img src={require("./images/icons8-decrease-font-52.png")} alt={decreaseFont} width="20" height="20" />
                              </Button>
    if(this.state.fontSizeLevel === 6){
        increaseFontButton = <Button className='disabled-font-icon' disabled aria-label={increaseFont} title={increaseFont} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.increaseFontSize() }}>
                                  <img src={require("./images/icons8-increase-font-52.png")} alt={increaseFont} width="20" height="20" />
                              </Button>
    }
    if(this.state.fontSizeLevel === 1){
        decreaseFontButton = <Button className='disabled-font-icon' disabled aria-label={decreaseFont} title={decreaseFont} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.decreaseFontSize() }}>
                                  <img src={require("./images/icons8-decrease-font-52.png")} alt={decreaseFont} width="20" height="20" />
                              </Button>
    }

    var textControlsDisplayStyle = (this.state.openParshaCount === 0) ? 'none' : 'inline';

    var hasTargum = (this.state.rowData[0] && this.state.rowData[0].targum !== null);
    var contentDisplayed = this.state.contentDisplayed;
    if(!hasTargum && contentDisplayed.includes('targum')){
      var targumIndex = contentDisplayed.indexOf('targum');
      contentDisplayed.splice(targumIndex, 1);
    }

    return (<span helpTopic="parshaViewer">

        <div style={{direction: langDirection}}>
             <span className="medCaption" align="center"><Translate id="parshaViewer" /></span>
             <div className='parsha-viewer-text-display-controls' style={{display: `${textControlsDisplayStyle}`}}>
                 <div className='parsha-viewer-select-text-display-type'>
                     <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}><Translate id="selectDisplay" /></Typography>&nbsp;
                     <div className={classes.toggleContainer} className="tanachViewerToggleButtonContainer">
                         <ToggleButtonGroup className="parshaViewerToggleButtonGroup" value={this.state.textDisplayType} exclusive onChange={this.handleToggleTextDisplayType}>
                            <ToggleButton className="parsha-viewer-toggle-button" value="stream" title={stream}>
                                <img src={require("./images/paragraph_icon.svg")} alt={stream} width="14" height="14" style={{transform: 'scaleX(-1)'}} />
                            </ToggleButton>
                            <ToggleButton className="parsha-viewer-toggle-button" value="table" title={table}>
                                <img src={require("./images/spreadsheet_icon.svg")} alt={table} width="14" height="14" style={{transform: 'scaleX(-1)'}} />
                            </ToggleButton>
                         </ToggleButtonGroup>
                         <ToggleButtonGroup className="parshaViewerToggleButtonGroup" value={this.state.cloudViewerOpen} onChange={this.toggleCloudViewerOpen}>
                            <ToggleButton className="tanach-viewer-display-toggle-button" value="cloud" title={wordCloud}>
                                <img src={require("./images/iconfinder_cloud_126565.png")} alt={wordCloud} width="14" height="14" style={{transform: 'scaleX(-1)'}} />
                            </ToggleButton>
                        </ToggleButtonGroup>
                     </div>
                 </div>

                 {decreaseFontButton}
                 {increaseFontButton}

                 <div className='parsha-viewer-select-text-display-type'>
                    <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}><Translate id="selectContent" />:</Typography>&nbsp;
                    <div className={classes.toggleContainer} style={{direction: 'rtl'}} className="tanachViewerToggleButtonContainer">
                      <ToggleButtonGroup className="tanachViewerToggleButtonGroup" value={contentDisplayed} onChange={(e, vals) => {this.handleToggleContentDisplayed(e, vals)}}>
                        <ToggleButton className="parsha-viewer-toggle-button" value="tanach">תנ"ך</ToggleButton>
                        <ToggleButton className="parsha-viewer-toggle-button" value="targum" disabled={(this.state.rowData[0] && !hasTargum) || this.state.textDisplayType=="table"}>תרגום</ToggleButton>
                        <ToggleButton className="parsha-viewer-toggle-button" value="ktavStam" disabled={this.state.textDisplayType=="table"}>כתב סת"ם</ToggleButton>
                        <ToggleButton className="parsha-viewer-toggle-button" value="ktavIvri" disabled={this.state.textDisplayType=="table"}>כתב עברי</ToggleButton>
                        <ToggleButton className="parsha-viewer-toggle-button" value="gematria" disabled={this.state.textDisplayType=="table"}>גימטריה</ToggleButton>
                        <ToggleButton className="parsha-viewer-toggle-button" value="atbash" disabled={this.state.textDisplayType=="table"}>אתב"ש</ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>

                  <Button variant="contained" type="submit" className="parsha-viewer-control-button" style={{margin: '0 5px'}} onClick={this.closeAllParshiot}><Translate id="closeAll" /></Button>
             </div>
           <Button variant="contained" color="primary" className="parsha-viewer-control-button" type="submit" style={{margin: '0 5px'}} onClick={this.refresh}><Translate id="refresh" /></Button>
            <IconButton className="goHome" title={this.props.translate("homePage")} onClick={this.goHome}><HomeIcon /></IconButton>
            <IconButton className="goHome" title={copyLink} onClick={this.handleCopyLink}><LinkIcon /></IconButton>

        </div>
        <div 
            style={{
                height: viewerTableHeight,
                width: viewerWidth,
                maxWidth: 'calc(100vw - 400px)',
                minWidth: '968px',
                margin: '8px 0px',
            }}
        >
        <LoadingOverlay
          active={isLoading}
          spinner
          text={loadingText}
          >
           <MenuProvider className="context-menu" id='parsha-viewer-context-menu' oncontextmenu={(e) => {console.log(e)}}
              style={{
                height: viewerTableHeight,
                width: viewerWidth,
                maxWidth: 'calc(100vw - 400px)',
                minWidth: '968px'
              }}
           >
               <div
                  className="ag-theme-balham parsha-viewer-grid"
                    onclick={(e) => {console.log(e)}}
                  style={{
                    height: viewerTableHeight,
                    width: viewerWidth,
                    maxWidth: 'calc(100vw - 400px)',
                    minWidth: '968px',
                    margin: '8px 0px',
                  }}
                    >
                    <AgGridReact
                        onGridReady={this.resultsGridReady}
                        defaultColDef={{resizable: true}}
                        columnDefs={this.state.columnDefs}
                        rowData={null}
                        getRowStyle = {getAlternateRowStyle}
                        enableColResize={true}
                        enableSorting={true}
                        enableFilter={true}
                        onCellClicked={(e) => {this.handleCellClicked(e)}}
                        onCellContextMenu={(e) => {fakeThis.onCellContextMenu(e)}}
                        suppressScrollOnNewData={true}
                        enableRtl="true"
                    />
                </div>
            </MenuProvider>
            <ContextMenu menuId='parsha-viewer-context-menu' menuType='parshaViewer' className='special-context-menu' resultsApi={this.resultsApi}
                        textToCopy={fakeThis.state.textToCopy} extCopySelectedToSearchbar={fakeThis.props.extCopySelectedToSearchbar}
                        extSearchWithSelected={fakeThis.props.extSearchWithSelected} extToggleUseKinuim={fakeThis.toggleUseKinuim}
                        useKinuim={fakeThis.state.useKinuim} extToggleIncludeMekorot={fakeThis.toggleIncludeMekorot} includeMekorot={fakeThis.state.includeMekorot}
                        extOpenTaamParser={fakeThis.openTaamParser} hasRowData={fakeThis.state.rowData.length > 0} textCellRowText={this.state.textCellPasukText}
                        textCellRowMakor={this.state.textCellPasukMakor} isStatsContextMenu={this.state.isStatsContextMenu} textDisplayType={this.state.textDisplayType} 
                        extToggleSearchAsQuote={fakeThis.toggleSearchAsQuote} searchAsQuote={fakeThis.state.searchAsQuote} columnClicked={this.state.columnClicked}
                        extSetTextSource={this.props.extSetTextSource} extOpenLexiconViewer={this.props.extOpenLexiconViewer} wordToCopy={this.state.wordToCopy}/>
            <div className='results-status-bar' style={{direction: 'rtl'}}>
                <Typography className='results-status-bar-text noHover' style={{top:'4px', right:'15px'}} variant="subtitle2">
                    <b>{totalParshios}: </b>{this.state.totalRows}
                </Typography>
                <Button title={firstPage} variant="contained" className= "search-results-page-button" onClick={(e) => {this.getFirstPage()}} disabled={this.isPrevPageDisabled()} style={{backgroundColor: 'RGB(224, 224, 224)'}}>
                    <LastPageIcon style={{fontSize: '15px'}} />
                </Button>
                <Button title={prevPage} variant="contained" className= "search-results-page-button" onClick={(e) => {this.getPrevPage()}} disabled={this.isPrevPageDisabled()} style={{backgroundColor: 'RGB(224, 224, 224)'}}>
                    <NavigateNextIcon style={{fontSize: '15px'}} />
                </Button>
                <Select
                    value={this.state.currentPage}
                    onChange={(e) => {this.goToPage(e.target.value)}}
                    name="pageSelect"
                    displayEmpty
                    autoWidth={true}
                    style={{
                        color: 'white'
                    }}
                >
                    {this.getPagePickerItems()}
                </Select>
                <Button title={nextPage} variant="contained" className= "search-results-page-button" onClick={(e) => {this.getNextPage()}} disabled={this.isNextPageDisabled()} style={{backgroundColor: 'RGB(224, 224, 224)'}}>
                    <NavigateBeforeIcon style={{fontSize: '15px'}} />
                </Button>
                <Button title={lastPage} variant="contained" className= "search-results-page-button" onClick={(e) => {this.getLastPage()}} disabled={this.isNextPageDisabled()} style={{backgroundColor: 'RGB(224, 224, 224)'}}>
                    <FirstPageIcon style={{fontSize: '15px'}} />
                </Button>
                <div style={{direction: directionStyling.direction}}>
                    <Typography className='results-per-page-select' variant="subtitle2">
                        {resultsPerPage}: &nbsp;
                    <Select
                        value={this.state.rowsPerPage}
                        onChange={(e) => {this.handleRowsPerPage(e)}}
                        name="sortOrder"
                        displayEmpty
                        autoWidth={true}
                        style={{
                            color: 'white',
                            fontWeight: 'normal',
                            fontSize: '12px'
                        }}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={200}>200</MenuItem>
                        <MenuItem value={"showAll"}>{showAll}</MenuItem>
                    </Select>
                    </Typography>
                </div>
            </div>
        </LoadingOverlay>
        </div>


                {/* <Dialog
                  open={this.state.taamParserOpen}
                  onClose={this.closeTaamParser}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth="xl"
                >
                    <TaamParser data={this.state.taamParseData} open={this.state.taamParserOpen} closeTaamParser={this.closeTaamParser}
                            focusedCellRowLocation={null} extOpenTaamParser={this.openTaamParser}
                            pasukId={this.state.taamParserPasukId} pasukLocation={this.state.taamParserLocation}
                            resultsApi={this.resultsApi} isParshaViewer={true} />
                </Dialog> */}

        </span>
    );
  }
}

ParshaViewer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withLocalize(ParshaViewer));


/*


ATTEMPT AT WORD-TO-WORD HIGHLIGHTING:
    getStreamCellContents(params){
        var tanachTextDiv = document.createElement('div');
        tanachTextDiv.className = "parsha-viewer-text-stream";

        var tanachTextStream = '<div class="parsha-viewer-text-stream">';
        var targumTextDiv = document.createElement('div');
        var targumTextStream = params.data.body[0].targum ? '<div class="parsha-viewer-text-stream">' : "";
        var stamTextDiv = document.createElement('div');
        var stamTextStream = '<div class="parsha-viewer-stam-stream">';
        var ivriTextDiv = document.createElement('div');
        var ivriTextStream = '<div class="parsha-viewer-ivri-stream">';


        for(let i = 0; i < params.data.body.length; i++){
            //removing spaces from location, text so they can be used as attribute in HTML
            var currPasuk = params.data.body[i]
            var noSpaceLocation = currPasuk.location;
            while(noSpaceLocation.includes(" ")){
                noSpaceLocation = noSpaceLocation.replace(" ", "_");
            }
            var noSpaceText = currPasuk.text;
            while(noSpaceText.includes(" ")){
                noSpaceText = noSpaceText.replace(" ", "_");
            }
            var makorArray = currPasuk.location.split(':');
            var perekPasuk = `${makorArray[1]}:${makorArray[2]}`
            var noNikkudTaamimText = currPasuk.text;
            noNikkudTaamimText = this.removeNikud(this.removeTaamim(noNikkudTaamimText))
            var targumIsOpen = this.state.contentDisplayed.includes('targum');
            var hasTargum = params.data.body[0].targum;
            var isMultiPanel = this.state.contentDisplayed.length > 1;
            if(this.state.contentDisplayed.length === 2 && targumIsOpen && !hasTargum){
                isMultiPanel = false;
            }
            var hoverPasukHighlightColor = 'powderblue';
            var hoverWordHighlightColor = 'deepskyblue';


            var tanachText = currPasuk.text;
            var words = tanachText.split(" ");
            var torahMapObj = (targumIsOpen && hasTargum) ? currPasuk.torahPhraseMapObj : currPasuk.torahWordMapObj;
            var tanachWordsParentSpan = document.createElement('span');
            var matchIds;
            for(let j = 0; j < words.length; j++){
                matchIds = [];
                if(torahMapObj){
                  matchIds = torahMapObj[j+1];
                }
                if(!matchIds){
                  matchIds = [];
                }
                if(!hasTargum){
                  matchIds = [j];
                }
                var isHoveredMatch = false;
                matchIds.map((matchId, k) => {
                  if(this.state.hoveredMatchIds && this.state.hoveredMatchIds.includes(matchId)){// && this.state.hoveredLocation === currPasuk.location){
                    isHoveredMatch = true;
                  }
                })
                var fakeThis = this
                var wordSpan = document.createElement('span');
                wordSpan.matchIds = matchIds
                wordSpan.isHoveredMatch = function () {
                    var isHoveredMatch = false;
                    this.matchIds.map((matchId, k) => {
                      if(fakeThis.state.hoveredMatchIds && fakeThis.state.hoveredMatchIds.includes(matchId)){// && this.state.hoveredLocation === currPasuk.location){
                        isHoveredMatch = true;
                      }
                    })
                    return isHoveredMatch;
                }
                wordSpan.onclick = function () { console.log(this.matchIds); };
                wordSpan.onmouseenter = function () {
                    fakeThis.setHoveredMatchIds(this.matchIds);
                };
                wordSpan.onmouseleave = function () { fakeThis.setHoveredMatchIds(null); };
                if(isHoveredMatch) {
                    wordSpan.style.backgroundColor = hoverWordHighlightColor;
                } else {
                    wordSpan.style.backgroundColor = 'unset';

                }
                wordSpan.className = 'text-cell-pasuk-span';
                wordSpan.innerHTML = words[j] + " ";
                tanachWordsParentSpan.append(wordSpan);
                // return  `<span
                //           matchIds=${matchIds}
                //           onmouseenter="{console.log('setting hovered match ids')}"
                //           onMouseLeave="{console.log('unsetting hovered match ids')}"
                //           style={{
                //             backgroundColor: ${isHoveredMatch && isMultiPanel ? hoverWordHighlightColor : 'unset'}
                //           }}
                //           className='text-cell-pasuk-span'
                //         >
                //           ${word + " "}
                //         </span>`
              }
              var tanachWordSpans = 'tanachWordSpans.join("")'
            var tanachPasukOuterSpan = document.createElement('span');
            tanachPasukOuterSpan.className = 'text-cell-pasuk-span'
            tanachPasukOuterSpan.location = noSpaceLocation;
            tanachPasukOuterSpan.text = noSpaceText;
            tanachPasukOuterSpan.pasukId = currPasuk.pasukId;
            var tanachPasukInnerSpan = document.createElement('span');
            var tanachLocation = document.createElement('b');
            tanachLocation.innerHTML = `(${perekPasuk}) `;
            var tanachWords = document.createElement('span');
            tanachWords.append(tanachWordsParentSpan)
            tanachPasukOuterSpan.append(tanachPasukInnerSpan)
            tanachPasukInnerSpan.append(tanachLocation);
            tanachPasukInnerSpan.append(tanachWords);

            tanachTextDiv.append(tanachPasukOuterSpan);

            tanachTextStream += `<span class='text-cell-pasuk-span' location=${noSpaceLocation} text=${noSpaceText} pasukId=${currPasuk.pasukId}><span><b>(${perekPasuk})</b><span> ${tanachWordSpans} </span></span></span>`;
            if(params.data.body[0].targum) {
                targumTextStream += `<span class='text-cell-pasuk-span' location=${noSpaceLocation} text=${noSpaceText} pasukId=${currPasuk.pasukId}><span><b>(${perekPasuk})</b><span> ${currPasuk.targum} </span></span></span>`;
            }
            stamTextStream += `<span class='text-cell-pasuk-span' location=${noSpaceLocation} text=${noSpaceText} pasukId=${currPasuk.pasukId}><span><span> ${currPasuk.text} </span></span></span>`;
            ivriTextStream += `<span class='text-cell-pasuk-span' location=${noSpaceLocation} text=${noSpaceText} pasukId=${currPasuk.pasukId}><span><span> ${noNikkudTaamimText} </span></span></span>`;
        }

        tanachTextStream += '</div>';
        tanachTextDiv.style.fontSize = `${(this.state.fontSizeLevel * 2) + 16}px`; // ranges from 18 to 28
        tanachTextDiv.oncontextmenu = (e) => {this.handleDoubleRightClick(e)};


        if(params.data.body[0].targum){
            targumTextStream += '</div>';
            targumTextDiv.innerHTML = targumTextStream;
            targumTextDiv.style.fontSize = `${(this.state.fontSizeLevel * 2) + 16}px`; // ranges from 18 to 28
            targumTextDiv.oncontextmenu = (e) => {this.handleDoubleRightClick(e)};
        }

        stamTextStream += '</div>';
        stamTextDiv.innerHTML = stamTextStream;
        stamTextDiv.style.fontSize = `${(this.state.fontSizeLevel * 2) + 16}px`; // ranges from 18 to 28
        stamTextDiv.oncontextmenu = (e) => {this.handleDoubleRightClick(e)};

        ivriTextStream += '</div>';
        ivriTextDiv.innerHTML = ivriTextStream;
        ivriTextDiv.style.fontSize = `${(this.state.fontSizeLevel * 2) + 16}px`; // ranges from 18 to 28
        var totalDisplayedPanels = this.state.contentDisplayed.length;
        if(this.state.contentDisplayed.includes('targum') && !hasTargum){
            totalDisplayedPanels--
        }
        ivriTextDiv.style.maxWidth = `calc(${100 / totalDisplayedPanels}%)`; // ranges from 18 to 28
        ivriTextDiv.oncontextmenu = (e) => {this.handleDoubleRightClick(e)};

        //append relevant divs to cellContents
        var cellContents = document.createElement('div')
        cellContents.className = `parsha-viewer-text-cell parsha-id-${params.data.parshaId}`;
        if(this.state.contentDisplayed.includes('tanach')){
            cellContents.append(tanachTextDiv)
        }
        if(this.state.contentDisplayed.includes('targum') && hasTargum){
            cellContents.append(targumTextDiv)
        }
        if(this.state.contentDisplayed.includes('ktavStam')){
            cellContents.append(stamTextDiv)
        }
        if(this.state.contentDisplayed.includes('ktavIvri')){
            cellContents.append(ivriTextDiv)
        }
        return cellContents;
    }

*/