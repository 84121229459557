import React, { Component, useState } from 'react';
import './css/App.css';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { Button, Paper, InputBase, IconButton, Fade, Typography, Popper, AppBar, Tabs, Tab, InputLabel, MenuItem, FormControl, Select, Switch, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import translations from "./json/translations.json";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

class DistancePicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            termsInSameQuote: false,
            displayArray:[" "," "," "]
//            madreigos: [{id: 1, name: "אותיות"}, {id: 2, name: "תיבות"}, {id: 3, name: "פסוקים"}, {id: 4, name: "פרשיות"}],
            //display: displayVal//"0" + " עד " + "∞",
        }

        this.handleMadreigaChange = this.handleMadreigaChange.bind(this);
        this.handleDirectionChange = this.handleDirectionChange.bind(this);
        this.handleDistanceChange = this.handleDistanceChange.bind(this);
        this.handleSingleDistanceChange = this.handleSingleDistanceChange.bind(this);
        this.checkCharacters = this.checkCharacters.bind(this);
    }

    handleMadreigaChange(event) {

        if(!this.props.isBituiToBitui){
            this.props.extSetMadreiga(event.target.value, this.props.sourceNum)
        } else {
            var termIndex1 = this.props.selectedTermNum;
            var termIndex2 = this.props.compareTermNum;
            if(this.props.selectedTermNum < this.props.compareTermNum){
                termIndex1 = this.props.compareTermNum;
                termIndex2 = this.props.selectedTermNum;
            }
            var termPairKey = `${termIndex1}-${termIndex2}`
            this.props.extSetMadreiga(event.target.value, termPairKey)
        }
    }

    handleDirectionChange(event, value) {
        if(value !== null){
            var termIndex1 = this.props.selectedTermNum;
            var termIndex2 = this.props.compareTermNum;
            if(this.props.selectedTermNum < this.props.compareTermNum){
                termIndex1 = this.props.compareTermNum;
                termIndex2 = this.props.selectedTermNum;
            }
            var termPairKey = `${termIndex1}-${termIndex2}`
            this.props.extSetDirection(value, termPairKey)
        }
    }

    handleSingleDistanceChange = (plusMinus, upperLower) => {
        if(this.props.direction === 'rtl'){
            plusMinus = plusMinus === 'plus' ? 'minus' : 'plus';
        }

        var val = [];
        if(plusMinus === 'plus' && upperLower === 'lower' && this.props.distance[0] !== this.props.distance[1]){ //don't change if equal and adding to lower
            val = [(this.props.distance[0]+1), (this.props.distance[1])]
            val[0] = val[0] > 100 ? 100 : val[0];
        } else if(plusMinus === 'minus' && upperLower === 'lower'){
            val = [(this.props.distance[0]-1), (this.props.distance[1])]
            val[0] = val[0] < 0 ? 0 : val[0];
        } else if(plusMinus === 'plus' && upperLower === 'upper'){
            val = [(this.props.distance[0]), (this.props.distance[1]+1)]
            val[1] = val[1] > 100 ? 100 : val[1];
        } else if(plusMinus === 'minus' && upperLower === 'upper' && this.props.distance[0] !== this.props.distance[1]){//don't change if equal and subtracting from higher
            val = [(this.props.distance[0]), (this.props.distance[1]-1)]
            val[1] = val[1] < 0 ? 0 : val[1];
        }

        if(typeof(val[0]) === 'number' && typeof(val[1]) === 'number'){
            this.handleDistanceChange(val,plusMinus)
        }
    }

    handleDistanceChange = (val,plusMinus) => {
        this.setState({click:false})
        if(val[0] != this.state.displayArray[0]){//if changed the min
            val[0] = val[0]>val[1]? val[1]: val[0]
            val[0] = val[0]<0? 0: val[0] //if less than min, set to min
        }
        if(val[1] != this.state.displayArray[2]){//if changed the max
            val[1] = val[1]<val[0]? val[0]: val[1]
            val[1] = val[1]>100? 100: val[1] //if greater than max, set to max
        }
        if(Number.isNaN(val[0])){
            val[0]=parseInt(this.state.displayArray[0])
        }
        if(Number.isNaN(val[1])){
            val[1]=parseInt(this.state.displayArray[2])?parseInt(this.state.displayArray[2]):100
        }
        if(val[0] == this.state.displayArray[0] && ((val[1] == 100 && this.state.displayArray[2] == '∞')|| val[1] == this.state.displayArray[2])){//if didn't change the numbers
            this.setState({displayArray:[]},()=>this.setState({displayArray:this.props.display.split(" ")}))
        }else{//if the numbers did change

            if(this.props.direction === 'rtl' && !plusMinus){
                val=[100-val[1],100-val[0]]
            }
            if(!this.props.isBituiToBitui){
                this.props.extSetContainerDistance(val, this.props.sourceNum)
            } else {
                var termIndex1 = this.props.selectedTermNum;
                var termIndex2 = this.props.compareTermNum;
                if(this.props.selectedTermNum < this.props.compareTermNum){
                    termIndex1 = this.props.compareTermNum;
                    termIndex2 = this.props.selectedTermNum;
                }
                var termPairKey = `${termIndex1}-${termIndex2}`
                this.props.extSetTermPairDistance(val, termPairKey)
            }
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(this.props.isBituiToBitui){
            var termIndex1 = this.props.selectedTermNum;
            var termIndex2 = this.props.compareTermNum;
            if(this.props.selectedTermNum < this.props.compareTermNum){
                termIndex1 = this.props.compareTermNum;
                termIndex2 = this.props.selectedTermNum;
            }
            var termPairKey = `${termIndex1}-${termIndex2}`;
            if(this.props.termDistances[termPairKey]){
                var termsInSameQuote = this.props.termDistances[termPairKey].termsInSameQuote
                if(termsInSameQuote !== this.state.termsInSameQuote){
                    this.setState({termsInSameQuote})
                }
            }
        }
        if(this.props.display.split(" ")[0] != this.state.displayArray[0] || ((this.props.display.split(" ")[2] != this.state.displayArray[2]))){//} && this.props.display.split(" ")[2] != '∞')){
            this.setState(this.setState({displayArray:this.props.display.split(" ")}))
        }

    }
    componentDidMount(){
        if(this.props.isBituiToBitui){
        // using this to force quoted terms to disable after closing and re-opening advanced search
            var termIndex1 = this.props.selectedTermNum;
            var termIndex2 = this.props.compareTermNum;
            if(this.props.selectedTermNum < this.props.compareTermNum){
                termIndex1 = this.props.compareTermNum;
                termIndex2 = this.props.selectedTermNum;
            }
            var termPairKey = `${termIndex1}-${termIndex2}`;
            if(this.props.termDistances[termPairKey]){
                var termsInSameQuote = this.props.termDistances[termPairKey].termsInSameQuote
                if(termsInSameQuote !== this.state.termsInSameQuote){
                    this.setState({termsInSameQuote})
                }
            }
        }
        this.setState({displayArray:this.props.display.split(" ")})
    }

    checkCharacters(evt){
        if(evt.which !== 8 && evt.currentTarget.innerText.length > 4){//if the number is too long
            evt.preventDefault();}
        if (evt.charCode === 13) {//if pressed enter
            evt.preventDefault();}
        if ((!(evt.charCode >= 48 && evt.charCode <= 57)) && (!parseFloat(evt.key)) && (evt.charCode !== 46)) { // 0-9 only and decimals
            evt.preventDefault();} 
    }

     render() {
        const madreigos = [{id: 1, name:this.props.translate("letterUnit")}, {id: 2, name: this.props.translate("wordUnit")},
                           {id: 3, name: this.props.translate("pasukUnit")}, {id: 4, name: this.props.translate("parshaUnit")}];
        var i = 0;
        const alignment = this.props.direction == "rtl" ? "left" : "right";
        const numMadreigos = this.props.numMadreigos;
        var captionWidth = '120px'
        if(this.props.isBituiToBitui === true){
            captionWidth = '133px';
        }

        var plusButtonValue = 1;
        var minusButtonValue = -1;
        if(this.props.selectedTermNum > this.props.compareTermNum){
            var plusButtonValue = -1;
            var minusButtonValue = 1;
        }

        const caption = <td width={captionWidth} align={alignment} className={"row-header-" + alignment} style={{direction: 'rtl'}} >{this.props.caption}</td>;
        const selectMadreiga = <td align="center" className='boxDistancesCell' style={{minWidth: '145px', maxWidth: '145px'}}>
               <Paper name="termBox2" id="box1" className="boxDistances"  elevation={1} align="center">
                   <Select className="selectDistances" disableUnderline={true} value={this.props.madreiga} onChange={this.handleMadreigaChange} disabled={this.state.termsInSameQuote}>
                       {madreigos.map(function(e){
                           if (++i <= numMadreigos) {
                               const levelStyle = {height: '16px'};
                               return <MenuItem className="menuItem" style={levelStyle} value={e.id}>
                               <span className="lntPickerSelectName">{e.name}</span>
                               </MenuItem>;
                           }
                       })}
                   </Select>
               </Paper>
           </td>;
        const display = <td align="center" width='80px' style={{minWidth: '82px'}}>
                 <Paper className="distanceBox"  elevation={1}>

                     <div className="flex-container"dir="ltr">
                     <span onClick={()=>this.setState({click:true})} onBlur={(val)=>this.handleDistanceChange([parseInt(this.state.displayArray[0]),(parseInt(val.target.innerText))])}
                             style={{}} contentEditable={true}  
                            onKeyPress={this.checkCharacters} >{this.state.displayArray[2]=='∞'?this.state.click?100:'∞':this.state.displayArray[2]}</span>
                     <span dir="ltr">&nbsp;{"עד"}&nbsp;</span>
                     <span onBlur={(val)=>this.handleDistanceChange([parseInt(val.target.innerText),(parseInt(this.state.displayArray[2])?parseInt(this.state.displayArray[2]):100)])}
                             style={{}} contentEditable={true}  
                             onKeyPress={this.checkCharacters}>{this.state.displayArray[0]}</span>
                     </div>


                 </Paper>
            </td>;
        const pad = <td width='2px'>&nbsp;</td>
        const singleAddSubtractLower = <div style={{paddingTop: '1.5px', paddingBottom: '1.5px'}}>
                     <Fab color="default" aria-label="add" className="singleAddSubtractButton" onClick={() => {this.handleSingleDistanceChange('plus', 'lower')}}
                        style={{display:'block', fontSize: '9px', maxHeight:'10px', minHeight: '0', maxWidth:'10px', lineHeight: '0', padding: '0px 0px 0px 0px'}}
                        disabled={this.state.termsInSameQuote}
                     >
                       +
                     </Fab>
                     <div style={{minHeight: '1px'}}/>
                     <Fab color="default" aria-label="add" className="singleAddSubtractButton" onClick={() => {this.handleSingleDistanceChange('minus', 'lower')}}
                        style={{display:'block', fontSize: '9px', maxHeight:'10px', minHeight: '0', maxWidth:'10px', lineHeight: '0', padding: '0px 0px .5px 0px'}}
                        disabled={this.state.termsInSameQuote}
                     >
                       -
                     </Fab>
                </div>

        const singleAddSubtractUpper = <div style={{paddingTop: '1.5px', paddingBottom: '1.5px'}}>
                     <Fab color="default" aria-label="add" className="singleAddSubtractButton" onClick={() => {this.handleSingleDistanceChange('plus', 'upper')}}
                        style={{display:'block', fontSize: '9px', maxHeight:'10px', minHeight: '0', maxWidth:'10px', lineHeight: '0', padding: '0px 0px 0px 0px'}}
                        disabled={this.state.termsInSameQuote}
                    >
                       +
                     </Fab>
                     <div style={{minHeight: '1px'}}/>
                     <Fab color="default" aria-label="add" className="singleAddSubtractButton" onClick={() => {this.handleSingleDistanceChange('minus', 'upper')}}
                        style={{display:'block', fontSize: '9px', maxHeight:'10px', minHeight: '0', maxWidth:'10px', lineHeight: '0', padding: '0px 0px .5px 0px'}}
                        disabled={this.state.termsInSameQuote}
                     >
                       -
                     </Fab>
                </div>
        const distance = <td align="center" style={{paddingRight: '4px', paddingLeft: '15px', minWidth: '185px'}} className='distance-cell'>

                <Range step={1} included={false}  value={this.props.distance} allowCross={false} onChange={(val)=>this.handleDistanceChange(val,true)} disabled={this.state.termsInSameQuote} />

            </td>
        var togglePositiveNegative = <></>;
            if(this.props.isBituiToBitui === true){
                togglePositiveNegative = <>
                        {pad}
                        <td align="center" colspan="3" className="togglePositiveNegative">
                            <div>
                              <ToggleButtonGroup value={this.props.distDirection} exclusive onChange={this.handleDirectionChange} className={`${this.state.termsInSameQuote ? 'disabled-toggle' : ''}`}>
                                <ToggleButton value={plusButtonValue} className="toggleButton" style={{padding: 0, height: 'auto'}} disabled={this.state.termsInSameQuote} >
                                  +
                                </ToggleButton>
                                <ToggleButton value={0} className="toggleButton" style={{padding: 0, height: 'auto'}} disabled={this.state.termsInSameQuote} >
                                  +/-
                                </ToggleButton>
                                <ToggleButton value={minusButtonValue} className="toggleButton" style={{padding: 0, height: 'auto'}} disabled={this.state.termsInSameQuote} >
                                  -
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </div>
                        </td>
                        {pad}
                    </>;
            }

        if (this.props.direction == "rtl")
            return <>{togglePositiveNegative}{singleAddSubtractLower}{distance}{singleAddSubtractUpper}{display}{selectMadreiga}{caption}</>;
        else
            return <>{caption}{selectMadreiga}{display}{singleAddSubtractLower}{distance}{singleAddSubtractUpper}{}</>;
       }
}

export default withLocalize(DistancePicker);