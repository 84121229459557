import React, { Component, useState } from 'react';
import './css/App.css';
import './css/FuzzyPicker.css';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { Button, Paper, InputBase, IconButton, Fade, Typography, Popper, AppBar, Tabs, Tab, InputLabel, MenuItem, FormControl, Select, Switch, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Checkbox} from 'primereact/checkbox';
import {TriStateCheckbox} from 'primereact/tristatecheckbox';
import { PropTypes } from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import translations from "./json/translations.json";
//import Slider, { Range } from 'rc-slider';
import Slider, { createSliderWithTooltip } from 'rc-slider';

import 'rc-slider/assets/index.css';

//For tooltip for slider:
const SliderWithTooltip = createSliderWithTooltip(Slider);

export class FuzzyPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
//            exactnessLevel: 1,
//            sliderValue: this.props.sliderValue
        }
    //this.setExactnessLevel = this.setExactnessLevel.bind(this);
    }

    //setExactnessLevel(num){
    //    this.props.setSlider(num);
    //    };

     render() {
       const precise = <Translate id="preciseSearch.caption"/>;
       const fuzzy = <Translate id="fuzzySearch.caption"/>;
       const fuzzyTip0 = this.props.translate("fuzzyTip0.caption");
       const fuzzyTip1 = this.props.translate("fuzzyTip1.caption");
       const fuzzyTip2 = this.props.translate("fuzzyTip2.caption");
       const fuzzyTip3 = this.props.translate("fuzzyTip3.caption");

       const marks = {
                      0: precise,
                      1: "",
                      2: "",
                      3: fuzzy
                      };
       const tipFormatter = (value)=>{
            switch(value){
                case 0:
                    return fuzzyTip0;
                    break;
                case 1:
                    return fuzzyTip1;
                    break;
                case 2:
                    return fuzzyTip2;
                    break;
                case 3:
                    return fuzzyTip3;
                    break;
            }
       }

       return(
       <div className="exactnessSliderDiv">
            <SliderWithTooltip
              className="exactnessSlider"
              dots
              step={1}
//              defaultValue={0}
              value={this.props.sliderValue}
              min={0}
              max={3}
              marks={marks}
              onChange={this.props.setSlider}
              reverse={true}
              tipFormatter={tipFormatter}
              handleStyle={{borderColor: '#b87333'}}
              disabled={this.props.disabled}
             />
       </div>
      ) }
}

export default withLocalize(FuzzyPicker);

//title="select the precision of your search"