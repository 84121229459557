import React, { Component } from 'react';

class ObjectCloudComparator_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>
        <p className="MsoNormal"><b><u>אפשרויות ותוצאות ספציפיות לשיטת ההשוואה של ענני אבייקטים:</u></b></p>

        <p className="MsoNormal"><i>(ראה מקום אחר בתיעוד לקבלת אפשרויות עבור <b>מציג ענני אבייקטים</b>)</i></p>

        <p className="MsoNormal">&nbsp;</p>

        <p className="MsoNormal">לאחר שתלחץ על כפתור "עדכן", יופיע ענן הבאה, בעל תצוגות מקבילות, אם תשווה את תדירות השורשים המופיעים במגילת שיר השירים לזו של מגילת אסתר:</p>

        <p className="MsoNormal"><img width={624} height={229} id="Picture 89" src="helpDialogImages/image065.jpg" /></p>
      </>
    } else if (langCode === "en") {
      retVal = <>
        <p className="MsoNormal"><b><u>Options and results specific to the ענני אבייקטים comparison method:</u></b></p>

        <p className="MsoNormal"><i>(see elsewhere in the documentation for options for the <b>Object Cloud Viewer</b>)</i></p>

        <p className="MsoNormal">&nbsp;</p>

        <p className="MsoNormal">Once you click the “update” button the following type of parallel
        cloud display will appear if you compare frequency of shorashim used in מגילת שיר השירים to that of מגילת אסתר:</p>

        <p className="MsoNormal"><img width={624} height={229} id="Picture 89" src="helpDialogImages/image065.jpg" /></p>
      </>
    }
    return retVal;
  }

  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default ObjectCloudComparator_Instructions;