import React, { Component } from 'react';

class IssuesTechnical extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p>
          משתמשים בישראל יחוו ביצועים איטיים יותר עד לשיפור בארכיטקטורת המערכת שלנו.
        </p>
        <p>
          האתר פועל בצורה הטובה ביותר בדפדפני האינטרנט Chrome, Firefox ו- Edge במחשב שולחני או מחשב נייד.
          בטלפונים של אנדרואיד ו- iOS אך עדיין לא התאימנו את ממשק המשתמש לחווית מסך קטן.
        </p>
        <p>
          מספר מוגבל של תוצאות חיפוש - על מנת לחסוך במשאבים וכדי למנוע האטות בביצועים,
          רשימות התוצאות מכוסות כרגע בסביבות 2000 שורות וניתקו שם.
        </p>

      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <p>Israeli users will experience slower performance until our system architecture is improved.</p>
        <p>The site works best on Chrome, Firefox and Edge web browsers using a desktop or laptop computer.
          It will still function on Android and iOS phones but we haven't yet tailored the user interface to the small
          screen experience.</p>
        <p>Limited number of search results - in order to conserve resources and to avoid slowdowns in
          performance, lists of results are currently capped at around 2000 rows and will cut off there. </p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default IssuesTechnical;