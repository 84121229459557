import React, { Component } from 'react';

class LCS_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אפשרויות
                ותוצאות
                ספציפיות
                לשיטת
                ההשוואה של </span></u></b><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>סדרות
                משותפות</span></u></b><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>:</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תוכלו
            לבחור
            אפשרויות על
            ידי לחיצה על
            שני כפתורי
            ההגדרות
            הבאים</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={267} height={28} id="Picture 61" src="helpDialogImages/image058.png" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לחיצה
            על "<b>הגדרות
              חיפוש</b>" תביא
            לכך שהקופץ
            בצד ימין
            יופיע.</span><img width={385} height={227} src="helpDialogImages/image059.png" align="left" hspace={12} /></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כיוון</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            כיוון
            ההשוואה.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מוצגים
            שני כפתורים
            המאפשרים
            לבחור קדימה
            (חיצים
            מיושרים) </span><i><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>(ברירת
              המחדל)</span></i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> לעומת סדר
            הפוך (חיצים
            מנוגדים)
            המאפשרים איתור
            רצף כיאסטי
            משותף.</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מרחק
              התאמות</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - טווח
            מרחק מותר בין
            מילים ברצף.</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span dir="RTL" /><i><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />(תכונה זו
              כרגע לא תשפיע
              והיא מיועדת
              לשימוש עתידי)</span></i></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אורך
              מילים</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - טווח
            אורכי מילים
            מותרים על מנת
            שמילה תתקבל
            לרצף</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מילים
              מדלגות</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - מילים
            לדלג עליהם.
            אפשר לבחור
            מרשימה
            מוגדרת מראש
            או להוסיף מילים
            בצורת חופש
            לרשימה</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לחיצה
            על <b>הגדרות
              תצוגה</b> תגרום
            להופעת הגדרת
            גודל הגופן
            הבאה</span><img width={171} height={42} src="helpDialogImages/image060.png" align="left" hspace={12} /></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>זה
            רק יאפשר
            למשתמש
            להגדיל או
            להקטין את
            גודל הגופן של
            טבלת התוצאות
            אך זה לא
            ישפיע על
            ההשוואה בפועל.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            שתלחץ על הכפתור
            "עדכן", יופיע
            סוג הטבלה הבא
          </span><i><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>(החישוב
              ייקח זמן רב
              יותר להשוואה
              בין חלקים גדולים
              יותר):</span></i></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={623} height={187} id="Picture 63" src="helpDialogImages/image061.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בצד
            ימין למעלה
            ושמאל למעלה,
            תיבה תציג את
            ה<b>מספר הכולל
              של מונחים </b>הכלולים
            בבחירת
            הפסוקים של כל
            צד</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מתחת
            לזה, טבלה
            תציג את
            העמודות
            הבאות מימין לשמאל:</span></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>טקסט</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - טקסט
            הפסוקים
            המכיל מונחים
            מודגשים שהם
            חלק מהרצף
            הנפוץ הארוך
            ביותר.</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פסוק</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            המיקום
            (ספר:פרק:פסוק)
            של כל מונח
            ברצף</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תיבה</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            המונח הממשי
            שהוא חלק
            מהרצף</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מרחק</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            המרחק בין
            מונח זה למונח
            הקודם ברצף</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="RTL" /><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />#</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - מספר
            אינדקס
            המציין את
            מיקום המונח
            ברצף כולו</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="RTL" /><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />... <b>וארבע
              עמודות
              נוספות</b> בצד
            שמאל המשקפות
            מידע דומה
            לארבע
            העמודות הראשונות
            בצד ימין אך
            הנוגעות
            לבחירת
            הפסוקים בצד
            שמאל של המסך</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מיון</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לחיצה
            על אחת
            מהעמודות
            תמיין את
            השורות לפי סדר
            המיון
            האלפביתי או
            המספרי
            הרלוונטי של אותה
            עמודה. לחיצה
            נוספת על
            העמודה תהפוך
            את הסדר.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>המלצה</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>:
            נקודת פתיחה
            טובה לנסות את
            הפונקציונליות
            הזו תהיה
            להשוות כמעט
            כל פרשת השבוע
            להפטרה
            המקבילה שלה,
            מכיוון
            שבקלות תוכלו
            לזהות קווי
            דמיון.</span></p>

      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b><u>Options and results specific to the </u></b><b><u><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>סדרות
                משותפות</span></u></b><span dir="LTR" /><u><span lang="HE"><span dir="LTR" /> </span><b>comparison method:</b></u></p>
        <p className="MsoNormal">You will be able to select options by clicking on the
          following two settings buttons</p>
        <p className="MsoNormal"><img width={267} height={28} id="Picture 61" src="helpDialogImages/image058.png" /></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><img width={385} height={227} src="helpDialogImages/image059.png" align="left" hspace={12} />Clicking <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>חיפוש הגדרות</span></b>
        {/* <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}> <b>חיפוש</b></span><span dir="LTR" /><b><span lang="HE"><span dir="LTR" /> </span></b> */}
        &nbsp;(search
          settings) will cause this popup on the right to appear.</p>
        <p className="MsoListParagraphCxSpFirst" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כיוון</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– direction of the
          comparison.&nbsp; Two buttons are presented which lets one choose forward (arrows aligned)
          <i><span style={{fontSize: '10.0pt'}}>(the default)</span></i> vs. reverse order (arrows
          opposed) which enables the detection of a shared chiastic sequence.</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מרחק
              התאמות</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– distance range allowed between words in
          the sequence. <i><span style={{fontSize: '10.0pt'}}>(this feature currently won’t
              have an effect and is slated for future usage)</span></i></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>אורך
              מילים</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– allowed word length range for a word to
          be accepted into the sequence</p>
        <p className="MsoListParagraphCxSpLast" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מילים
              מדלגות</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– skip words.&nbsp; One can select from a
          preset list or add free-form words to the list</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">Clicking <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הגדרות
              תצוגה</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>(display settings) will cause the following font size
          setting to appear <img width={171} height={42} src="helpDialogImages/image060.png" align="left" hspace={12} /></p>
        <p className="MsoNormal">This will merely allow the user to increase or decrease the
          font size of the result grid but won’t have any effect on the actual
          comparison.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">Once you click the “update” button the following type of
          grid will appear <i><span style={{fontSize: '10.0pt'}}>(the calculation will take a
              longer time for a comparison of larger sections)</span></i>:</p>
        <p className="MsoNormal"><img width={623} height={187} id="Picture 63" src="helpDialogImages/image061.jpg" /></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">In the top-right and top-left, a box will display <b>total
            terms</b> contained in each side’s pasuk section</p>
        <p className="MsoNormal">Beneath that, a grid will display the following columns from
          right to left:</p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טקסט</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– the text of pesukim containing highlighted
          terms that are members of the longest common sequence.</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פסוק</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ספר:פרק:פסוק</span><span dir="LTR" /><span dir="LTR" /> location of each sequence term</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>תיבה</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– the actual term that is part of the
          sequence</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מרחק</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– the distance between this term and the
          previous term in the sequence</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><span dir="RTL" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />#</span></b><span dir="LTR" /><span dir="LTR" /> - index number indicating the term’s
          position within the entire sequence</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />…..<b>and four additional columns</b> on the
          left reflecting similar information as the first four columns on the right but
          pertaining to the selection of pesukim on the left side of the screen</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Sorting</u></b></p>
        <p className="MsoNormal">Clicking on any of the columns will sort the rows by the
          relevant alphabetical or numerical sort order of that column. Clicking the
          column again will reverse the order.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b>Recommendation:</b> A good starting point for trying out
          this functionality would be to compare just about any <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשת
            השבוע</span><span dir="LTR" /> <span dir="LTR" />
          to its corresponding <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הפטרה</span><span dir="LTR" /><span dir="LTR" /> as you will easily be able to detect
          similarities.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default LCS_Instructions;