import React, { Component, useState } from 'react';
import './css/App.css';
import './css/TaamParser.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { UncontrolledCollapse } from 'reactstrap';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import Treefold from 'react-treefold';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Paper, Select, MenuItem, FormControl } from '@material-ui/core';
import * as properties from './config/properties.js';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import PlacementSelector2D from './PlacementSelector2D';
import TaamParserContextMenu from './TaamParserContextMenu';
import { contextMenu } from 'react-contexify';
import { Menu, Item, Separator, Submenu, MenuProvider } from 'react-contexify';
import "react-contexify/dist/ReactContexify.min.css";
import { Rnd } from "react-rnd";
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ContextMenu from './ContextMenu';


class TaamParser extends Component {

 constructor(props) {
    super(props);
    this.state = {
        structuredNodes: [],
        open: this.props.open,
        pasukId: this.props.pasukId,
        parsedPasukLocation: this.props.pasukLocation,
        isLoading: true,
        valList1: [],
        valList2: [],
        valList3: [],
        val1: null,
        val2: null,
        val3: null,
        selectOrLoading: [this.props.translate('select'), this.props.translate('select'), this.props.translate('select')],
        selectPasukMessageOpen: false,
        pasukDisabled: false,
        widthNum:this.props.widthNum,
        heightNum:this.props.heightNum,
        x:this.props.x,
        y:this.props.y,
        zIndex:800,
    };

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.rnd = document.getElementById("TaamParserPopup")

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}

    this.createStructuredNodes = this.createStructuredNodes.bind(this);
    this.getFormattedText = this.getFormattedText.bind(this);
    this.toggleCustomIsExpanded = this.toggleCustomIsExpanded.bind(this);
    this.getToggledStructuresNodes = this.getToggledStructuresNodes.bind(this);
    this.parseNextPasuk = this.parseNextPasuk.bind(this);
    this.parsePreviousPasuk = this.parsePreviousPasuk.bind(this);
    this.parseNextResult = this.parseNextResult.bind(this);
    this.parsePreviousResult = this.parsePreviousResult.bind(this);
    this.getNextResultId = this.getNextResultId.bind(this);
    this.getPreviousResultId = this.getPreviousResultId.bind(this);
    this.handleRangeChange1 = this.handleRangeChange1.bind(this);
    this.handleRangeChange2 = this.handleRangeChange2.bind(this);
    this.handleRangeChange3 = this.handleRangeChange3.bind(this);
    this.loadSelectedPasuk = this.loadSelectedPasuk.bind(this);
    this.handleCloseSelectPasukMessage = this.handleCloseSelectPasukMessage.bind(this);
    this.openContextMenu = this.openContextMenu.bind(this);
    this.setContextMenuOff = this.setContextMenuOff.bind(this);
    // this.updateDimensions = this.updateDimensions.bind(this);
    // this.resizeRND = this.resizeRND.bind(this);
    // this.dragRND = this.dragRND.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.findHighestZIndex = this.findHighestZIndex.bind(this);
    this.handleCopyLink = this.handleCopyLink.bind(this);

  }

  findHighestZIndex(elem) {
    var elems = document.getElementsByTagName("*");
    var highest = 0;
    for (var i = 0; i < elems.length; i++){
      var zindex=document.defaultView.getComputedStyle(elems[i],null).getPropertyValue("z-index");
      if ((zindex > highest) && (zindex != 'auto') && (zindex != 9998) && (zindex != 100000090))
      {
        highest = zindex;
      }
    }
    this.setState({zIndex:parseFloat(highest)+1});
  }


    async loadSefarim() {
        const url = properties.env.webServiceURL + "/BaseHaSefer/getSeferPerekInfo?source=bhsWebApp";

        const response = await fetch(url);
        const body = await response.json();

        this.setState({ val1: 0, val2: 0, val3: 0, valList1: body});
    }

    handleCopyLink() {
        var params = ["location=" + this.state.parsedPasukLocation];
        this.props.handleCopyLink("taamParser", params, this.props.isFullScreen(this.props.x, this.props.y, this.props.heightNum,this.props.widthNum));
    }

    handleRangeChange1(event) {
       if (event.target.value == this.state.val1) return false;
       this.loadList2(event.target.value);
     }

    handleRangeChange2(event) {
       if (event.target.value == this.state.val2) return false;
       this.loadList3(event.target.value);
     }

    handleRangeChange3(event) {
       if (event.target.value == this.state.val3) return false;
       this.setState({ val3: event.target.value}, () => {
            this.loadSelectedPasuk();
       });
    }

    async loadList2(val) {
          const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPerekInfo?source=bhsWebApp&seferId='+val);
          const body = await response.json();
          const perakim = body.map(function(e) {return {perekNum: e.perek, perekValue: util.gematriya(e.perek, {punctuate: false}), pesukim: e.pesukim }});
          this.setState({ val1: val, val2: 0, val3: 0, valList2: perakim});
    }

  async loadList3(val) {
          const perek = this.state.valList2.find(function(e){return e.perekNum == val;});
          const body = Array(perek.pesukim).fill().map((x,i)=>({pasukNum: i+1, pasukValue: util.gematriya(i+1, {punctuate: false}) }));

          this.setState({ val2: val, val3: 0, valList3: body});
  }

  loadSelectedPasuk(){
      if(!(this.state.val3)){ // make sure a pasuk is selected before continuing (val3 is not 0 or null)
          this.setState({selectPasukMessageOpen: true});
      } else {
          var seferId = this.state.val1;
          var perekNum = this.state.val2;
          var pasukNum = this.state.val3;
          var seferObj = this.state.valList1.filter(sefer => {
            return sefer.seferId === seferId
          })[0]
          var seferName = seferObj.seferName;
          var perekLetter = util.gematriya(perekNum, {punctuate: false});
          var pasukLetter = util.gematriya(pasukNum, {punctuate: false});

          var location = `${seferName}:${perekLetter}:${pasukLetter}`;
          this.props.extOpenTaamParser(location,true);
      }
  }

  handleCloseSelectPasukMessage(){
    this.setState({selectPasukMessageOpen: false})
  }

    parseNextPasuk(){
        if( this.props.pasukId < 23249){ //prevent for last pasuk
            this.props.extOpenTaamParser(this.props.pasukId + 1,true);
        }
    }

    parsePreviousPasuk(){
        if( this.props.pasukId > 1){ //prevent for first pasuk
            this.props.extOpenTaamParser(this.props.pasukId - 1,true);
        }
    }

    parseNextResult(){
        //todo - handle last result
        var nextResultId = this.getNextResultId(this.props.pasukId);
        this.props.extOpenTaamParser(nextResultId,true);
    }

    parsePreviousResult(){
        //todo - handle first result
        var previousResultId = this.getPreviousResultId(this.props.pasukId);
        this.props.extOpenTaamParser(previousResultId,true);
    }

    getNextResultId(pasukId){
          //change focused cell (unless at last result)
          var lastRowIndex = this.props.resultsApi.getModel().rowsToDisplay.length-1;
          var currentRowIndex = this.props.resultsApi.getFocusedCell().rowIndex;

          if(currentRowIndex < lastRowIndex){ // prevent if at last result
            this.props.resultsApi.setFocusedCell((this.props.resultsApi.getFocusedCell().rowIndex + 1), this.props.resultsApi.getFocusedCell().column.colDef.field)
          }

          //get pasukId of new focused cell
          var selectedList = 0;
          if(['location1', 'pasukText1'].includes(this.props.resultsApi.getFocusedCell().column.colDef.field)){
            selectedList = 1;
          } else if (['location2', 'pasukText2'].includes(this.props.resultsApi.getFocusedCell().column.colDef.field)){
            selectedList = 2;
          }
          var nextResultData = this.props.resultsApi.getModel().rowsToDisplay[this.props.resultsApi.getFocusedCell().rowIndex].data;

          if(selectedList === 0){
                return nextResultData.pasukId;
          } else if (selectedList === 1){
                return nextResultData.pasukId1;
          } else if (selectedList === 2){
                return nextResultData.pasukId2;
          }
    }

     getPreviousResultId(pasukId){
          //change focused cell (unless at first result)
          if(this.props.resultsApi.getFocusedCell().rowIndex > 0){
                this.props.resultsApi.setFocusedCell((this.props.resultsApi.getFocusedCell().rowIndex - 1), this.props.resultsApi.getFocusedCell().column.colDef.field)
          }

          //get pasukId of new focused cell
          var selectedList = 0;
          if(['location1', 'pasukText1'].includes(this.props.resultsApi.getFocusedCell().column.colDef.field)){
            selectedList = 1;
          } else if (['location2', 'pasukText2'].includes(this.props.resultsApi.getFocusedCell().column.colDef.field)){
            selectedList = 2;
          }
          var nextResultData = this.props.resultsApi.getModel().rowsToDisplay[this.props.resultsApi.getFocusedCell().rowIndex].data;

          if(selectedList === 0){
                return nextResultData.pasukId;
          } else if (selectedList === 1){
                return nextResultData.pasukId1;
          } else if (selectedList === 2){
                return nextResultData.pasukId2;
          }
      }

  getFormattedText(startPasukSequenceNum, endPasukSequenceNum){
      var formattedWords = [];
      var lastFormat = {}; // using this to format mukaf
      for(let i = endPasukSequenceNum; i >= startPasukSequenceNum; i--){
        var element = this.props.data.elements.find((element) => {
             return element.pasukSequenceNum === i;
        });
        var elementText = element.text;
        if(i !== endPasukSequenceNum){
            elementText = elementText.concat(' ')
        }
        var formattedWord = <></>
        if(['קיסר'].includes(element.taamTypeName)){
            lastFormat = {color: '#000000', display: 'inline', fontWeight: '800'}
            formattedWord = <p style={lastFormat}>{elementText}</p>
        } else if (['מלך'].includes(element.taamTypeName)) {
            lastFormat = {color: '#000000', display: 'inline', fontWeight: '600'}
            formattedWord = <p style={lastFormat}>{elementText}</p>
        } else if (['משנה', 'מפסיק נוסף'].includes(element.taamTypeName)) {
            lastFormat = {color: '#525252', display: 'inline', fontWeight: '600'}
            formattedWord = <p style={lastFormat}>{elementText}</p>
        } else if (['שליש'].includes(element.taamTypeName)) {
            lastFormat = {color: '#6D6D6D', display: 'inline', fontWeight: '600'}
            formattedWord = <p style={lastFormat}>{elementText}</p>
        } else if (['משרת'].includes(element.taamTypeName)) {
            lastFormat = {color: '#878787', display: 'inline', fontWeight: '600'}
            formattedWord = <p style={lastFormat}>{elementText}</p>
        } else {
            formattedWord = <p style={lastFormat}>{elementText}</p>
        }
        formattedWords.unshift(formattedWord)
      }
      return formattedWords;
  }

    getToggledStructuresNodes(node, nodeTree, cascadeClose){
        var newTree = [];
        for(let i = 0; i < nodeTree.length; i++){
            var currentChild = nodeTree[i];
            if(currentChild.id === node.id){
                currentChild.customIsExpanded = !currentChild.customIsExpanded;
                if (currentChild.customIsExpanded === false){
                    cascadeClose = true;
                }
            } else if (cascadeClose && currentChild.startPasukSequenceNum >= node.startPasukSequenceNum && currentChild.endPasukSequenceNum <= node.endPasukSequenceNum){
                currentChild.customIsExpanded = false;
            }
            if (currentChild.children){
                currentChild.children = this.getToggledStructuresNodes(node, currentChild.children, cascadeClose);
            }
            newTree.push(currentChild);
        }
        return newTree;
    }

    toggleCustomIsExpanded(node, nodeTree){
        var newTree = this.getToggledStructuresNodes(node, nodeTree);
        this.setState({structuredNodes: newTree});
    }

    styleContextMenu(e){

      // var element = document.elementFromPoint(e.clientX, e.clientY)
      //     contextMenu.hideAll();
      //     this.setState({doubleOpeningContextMenu: false}, () => {
      //         var ev = new MouseEvent("contextmenu", {
      //             bubbles: true,
      //             cancelable: false,
      //             view: window,
      //             button: 2,
      //             buttons: 0,
      //             clientX: e.clientX,
      //             clientY: e.clientY
      //         });
      //         element.dispatchEvent(ev);
      //     });




      const { innerWidth } = window;
      var submenus = document.getElementsByClassName('react-contexify__submenu');
      for(let i = 0; i < submenus.length; i++){
          var submenu = submenus[i];
          var submenuWidth = parseInt(submenu.style.minWidth.slice(0, submenu.style.minWidth.length-2));
          if(this.props.activeLanguage.code === 'he'){
              if(e.clientX > submenuWidth){
                  submenu.style.left = '';
                  submenu.style.right = '100%';
              } else {
                  submenu.style.left = '100%';
                  submenu.style.right = '';
              }
          } else if(this.props.activeLanguage.code === 'en'){
              if(innerWidth-e.clientX-300 > submenuWidth){
                  submenu.style.left = '100%';
                  submenu.style.right = '';
              } else {
                  submenu.style.left = '';
                  submenu.style.right = '100%';
              }
          }
      }
    }
  
  openContextMenu(event){
      event.preventDefault();
      this.styleContextMenu(event)
      this.setState({phrase:event.currentTarget,showContextMenu:true})
      contextMenu.show({id:'TaamParserContextMenu',event:event})
        // var x = event.clientX+10-this.props.x;
        // var y = event.clientY+10-this.props.y;
        // this.setState({xPos:x,yPos:y},()=>{
        //   // this.setState({showContextMenu:true})
        // })
    }
  
  
    setContextMenuOff(){
      this.props.handleSplitScreen()
      this.setState({showContextMenu:false})
      contextMenu.hideAll()
    }
  



  // setContextMenuOff(){
  //   this.props.setContextMenuOff()
  //   // this.setState({showContextMenu:false})
  // }
    
  createStructuredNodes(nodes){
      for(let i = nodes.length-1; i > 0; i--){
          var currentChild = nodes[i];
          currentChild.formattedWords = this.getFormattedText(currentChild.startPasukSequenceNum, currentChild.endPasukSequenceNum);
          var lastWordData = this.props.data.elements.find((element) => {
               return element.pasukSequenceNum === currentChild.endPasukSequenceNum;
          });
          currentChild.taamTypeName = lastWordData.taamTypeName;
          var parentIndex = nodes.findIndex((node) => {
              return (node.level === currentChild.level-1 && node.startPasukSequenceNum <= currentChild.startPasukSequenceNum && node.endPasukSequenceNum >= currentChild.endPasukSequenceNum)
          })
          if(!nodes[parentIndex].children){
              nodes[parentIndex].children = []
          }
          nodes[parentIndex].children.push(currentChild)
          nodes.pop();
      }
      var structuredNodes = nodes;
      var lastWordData = this.props.data.elements.find((element) => {
           return element.pasukSequenceNum === structuredNodes[0].endPasukSequenceNum;
      });
      structuredNodes[0].taamTypeName = lastWordData.taamTypeName;
      structuredNodes[0].formattedWords = this.getFormattedText(structuredNodes[0].startPasukSequenceNum, structuredNodes[0].endPasukSequenceNum);
      if(this.state.structuredNodes !== structuredNodes){
            this.setState({structuredNodes: structuredNodes})
      }
  }

  async componentDidUpdate(prevProps){
      if(prevProps.x !== this.props.x || prevProps.y !== this.props.y ||
               prevProps.widthNum !== this.props.widthNum || prevProps.heightNum !== this.props.heightNum){

          var home = document.getElementById('home-scroll-container')
          var maxScrollLeft = home.scrollWidth - home.clientWidth;
          var scrolledLeft = maxScrollLeft - Math.abs(home.scrollLeft)
      
          // this.rnd.updateSize({width:this.props.widthNum, height:this.props.heightNum})
          // this.rnd.updatePosition({x:this.props.x-scrolledLeft, y:this.props.y-home.scrollTop})


      }
      if(this.state.structuredNodes.length === 0 && this.props.data.nodes){
        const propNodes = this.props.data.nodes;
        this.createStructuredNodes(propNodes);
      }
      if(this.props.pasukId !== this.state.pasukId){
          this.setState({pasukId: this.props.pasukId, parsedPasukLocation: this.props.pasukLocation}, async () => {

                var seferPerekPasuk = this.state.parsedPasukLocation.split(":");
                var parsedPasukSeferName = seferPerekPasuk[0];
                var parsedPasukPerekLetter = seferPerekPasuk[1];
                var parsedPasukPasukLetter = seferPerekPasuk[2];

                //default sefer selector to chosen sefer
                var defaultSeferObj = this.state.valList1.filter( item => {
                    return item.seferName === parsedPasukSeferName;
                })[0]
                var defaultVal1 = defaultSeferObj.seferId;
                await this.loadList2(defaultVal1);
                //default perek selector to chosen perek
                await this.loadList3(util.gematriya(parsedPasukPerekLetter));
                //default pasuk selector to chosen pasuk
                this.setState({ val3: util.gematriya(parsedPasukPasukLetter)});

          })
          if(this.props.data.nodes){
            var propNodes = this.props.data.nodes;
            this.createStructuredNodes(propNodes);
          }


      }

      if(!this.state.val2 && !this.state.pasukDisabled){
        this.setState({pasukDisabled: true})
      } else if (this.state.val2 && this.state.pasukDisabled){
        this.setState({pasukDisabled: false})
      }
  }

  async componentDidMount(){
    this.findHighestZIndex();
    await this.loadSefarim()
    this.rnd = document.getElementById("TaamParserPopup")
    // this.updateDimensions()

    // window.addEventListener('resize', this.updateDimensions);
    var home = document.getElementById('home-scroll-container')
    // home.addEventListener('scroll',this.updateDimensions)//()=>this.setState({scrollTop:home.scrollTop,scrollLeft:home.scrollLeft}))

    var seferPerekPasuk = this.state.parsedPasukLocation.split(":");
    var parsedPasukSeferName = seferPerekPasuk[0];
    var parsedPasukPerekLetter = seferPerekPasuk[1];
    var parsedPasukPasukLetter = seferPerekPasuk[2];

    //default sefer selector to chosen sefer
    var defaultSeferObj = this.state.valList1.filter( item => {
        return item.seferName === parsedPasukSeferName;
    })[0]
    var defaultVal1 = defaultSeferObj.seferId;
    await this.loadList2(defaultVal1);
    //default perek selector to chosen perek
    await this.loadList3(util.gematriya(parsedPasukPerekLetter));
    //default pasuk selector to chosen pasuk
    this.setState({ val3: util.gematriya(parsedPasukPasukLetter)});
  }
  handleClick(){
    this.findHighestZIndex();
    this.setState({showContextMenu:false})
  }

        //took out these 3 methods because the popup was shifting too much. Now it behaves like the tanachViewer
  // updateDimensions(){
  //   // var x = (document.getElementById('TaamParserPopup').getBoundingClientRect())
  //   var home = document.getElementById('home-scroll-container')
  //   var maxScrollLeft = home.scrollWidth - home.clientWidth;
  //   var scrolledLeft = maxScrollLeft - Math.abs(home.scrollLeft)

  //   // this.setState({xPos:this.state.xPos+x.left},()=>{
  //     var widthNum = this.props.widthNum;
  //     var heightNum = this.props.heightNum;
  //     if(widthNum>(window.innerWidth-150)){
  //       widthNum = window.innerWidth-150 > 419 ? window.innerWidth-150 : 420
  //     }
  //     if(heightNum>(window.innerHeight-150)){
  //       heightNum = window.innerHeight-150 > 279 ? window.innerHeight-150 : 280
  //     }
  //     var left = (window.innerWidth-widthNum)/2;
  //     var top = (window.innerHeight-heightNum)/2;

  //     this.props.setSize(widthNum,heightNum,scrolledLeft+left,home.scrollTop+top)

  // }
  // resizeRND(e, direction, ref, delta, position){
  //     // this.rnd.updateSize({width:ref.offsetWidth,height:ref.offsetHeight})
  //     // this.rnd.updatePosition({x:position.lastX,y:position.y})
  //       this.props.setSize(
  //         parseInt(ref.offsetWidth),//.style.width.slice(0, -1)),
  //         parseInt(ref.offsetHeight),//style.height.slice(0, -1)),
  //         position.x+10,
  //         position.y
  //       )

  // }
  // dragRND(d){
  //   // if(d.x!==d.lastX-10 || d.y!==d.lastY){
  //         this.props.setPosition(d.x+10, d.y)
  //   // }
  //   // this.rnd.updatePosition({x:d.lastX,y:d.y})
  // }


  render() {

    const fakeThis = this;
    const { classes } = this.props;

    const list1 = this.state.valList1;
    const list2 = this.state.valList2;
    const list3 = this.state.valList3;
    const sel = this.props.translate("select");
    const sel1 = this.state.selectOrLoading[0];
    const sel2 = this.state.selectOrLoading[1];
    const sel3 = this.state.selectOrLoading[2];
    const loading = this.props.translate("smallLoading");
    const selectPad = this.props.translate("selectPad.Placement");
    const blankPadEN = <span style={{fontSize:'1px'}}>c</span>; //tiny c

    const nextPasuk = this.props.translate("nextPasuk");
    const previousPasuk = this.props.translate("previousPasuk");
    const nextResult = this.props.translate("nextResult");
    const previousResult = this.props.translate("previousResult");

    var nextResultLocation = '';
    var previousResultLocation = '';

    var focusedCellRowIndex = null;
    var nextLocation = '--';
    var previousLocation = '--';
    if(this.props.resultsApi && this.props.resultsApi.getFocusedCell()){
        var focusedCellRowIndex = this.props.resultsApi.getFocusedCell().rowIndex;
    }
    var selectedList = 0;
    if(this.props.resultsApi && ['location1', 'pasukText1'].includes(this.props.resultsApi.getFocusedCell().column.colDef.field)){
      selectedList = 1;
    } else if (this.props.resultsApi && ['location2', 'pasukText2'].includes(this.props.resultsApi.getFocusedCell().column.colDef.field)){
      selectedList = 2;
    }
    if((typeof(focusedCellRowIndex) === 'number') && this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex]){
        if(this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex + 1]){
            if (selectedList === 0) nextLocation = this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex + 1].data.location;
            else if (selectedList === 1) nextLocation = this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex + 1].data.location1;
            else if (selectedList === 2) nextLocation = this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex + 1].data.location2;
        }
        if(this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex - 1]){
            if (selectedList === 0) previousLocation = this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex - 1].data.location;
            else if (selectedList === 1) previousLocation = this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex - 1].data.location1;
            else if (selectedList === 2) previousLocation = this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex - 1].data.location2;
        }
    }

    var directionStyling = {};
    var langDirection = 'ltr';
    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
        directionStyling = {direction: 'rtl', textAlign: 'right'};
        langDirection = 'rtl';
    }

    const renderNode = (node, isFolder) => {
      var taamClassName = node.taamTypeName;
      if(node.taamTypeName === 'מפסיק נוסף'){
        taamClassName = 'מפסיק-נוסף'
      }
      return (<span className={`node ${taamClassName}`}>
          {node.formattedWords.map(function(formattedWord){
              return formattedWord
          })}
      </span>)
    };
    var isHebrew = this.props.activeLanguage && this.props.activeLanguage.code === 'he';
    const fullScreen = this.props.translate("fullScreen");
    const splitScreen = this.props.translate("splitScreen");
    const copyLink = this.props.translate('copyLink');
    const close = this.props.translate('close');


    const isPreviousResultDisabled = (this.props.resultsNavDisabled
                                    || (this.props.resultsApi && !(this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex - 1]))
                                    || this.props.isParshaViewer || this.props.isTanachViewer);
    const isNextResultDisabled = (this.props.resultsNavDisabled
                                    || (this.props.resultsApi && !(this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex + 1]))
                                    || this.props.isParshaViewer || this.props.isTanachViewer);

    const isFirstPasuk = !!(this.props.pasukId === 1);
    const isLastPasuk = !!(this.props.pasukId === 23249);


    var nextButtons = <div className='taam-parser-left-nav-buttons'>
        <Button
            className='{classes.margin}'
            aria-label={nextPasuk}
            title={nextPasuk}
            style={{minWidth: 0, opacity: `${isLastPasuk ? .3 : 1}`}}
            onClick={(e) => { this.parseNextPasuk() }}
            disabled={isLastPasuk}
        >
            <img src={require("./images/icons8-back-96.png")} alt={nextPasuk} width="30" height="30" />
        </Button>
        <Button
            className='{classes.margin}'
            aria-label={`${nextResult} (${nextLocation})`}
            title={`${nextResult} (${nextLocation})`}
            style={{minWidth: 0, opacity: `${isNextResultDisabled ? .3 : 1}`}}
            onClick={(e) => { this.parseNextResult() }}
            disabled={isNextResultDisabled}
        >
            <img src={require("./images/icons8-double-left-48.png")} alt={`${nextResult} (${nextLocation})`} width="30" height="30" />
        </Button>
    </div>
    var previousButtons = <div className='taam-parser-right-nav-buttons'>
        <Button
            className='{classes.margin}'
            aria-label={`${previousResult} (${previousLocation})`}
            title={`${previousResult} (${previousLocation})`}
            style={{minWidth: 0, opacity: `${isPreviousResultDisabled ? .3 : 1}`}}
            onClick={(e) => { this.parsePreviousResult() }}
            disabled={isPreviousResultDisabled}
        >
            <img src={require("./images/icons8-double-right-48.png")} alt={`${previousResult} (${previousLocation})`} width="30" height="30" />
        </Button>
        <Button
            className='{classes.margin}'
            aria-label={previousPasuk}
            title={previousPasuk}
            style={{minWidth: 0, opacity: `${isFirstPasuk ? .3 : 1}`}}
            onClick={(e) => { this.parsePreviousPasuk() }}
            disabled={isFirstPasuk}
        >
            <img src={require("./images/icons8-forward-96.png")} alt={previousPasuk} width="30" height="30" />
        </Button>
    </div>

    if(this.props.activeLanguage && this.props.activeLanguage.code === 'en'){
        nextButtons = <div className='taam-parser-right-nav-buttons'>
            <Button
                className='{classes.margin}'
                aria-label={nextPasuk}
                title={nextPasuk}
                style={{minWidth: 0, opacity: `${isLastPasuk ? .3 : 1}`}}
                onClick={(e) => { this.parseNextPasuk() }}
                disabled={isLastPasuk}
            >
                <img src={require("./images/icons8-forward-96.png")} alt={nextPasuk} width="30" height="30" />
            </Button>
            <Button
                className='{classes.margin}'
                aria-label={`${nextResult} (${nextLocation})`}
                title={`${nextResult} (${nextLocation})`}
                style={{minWidth: 0, opacity: `${isNextResultDisabled || nextLocation === "--" ? .3 : 1}`}}
                onClick={(e) => { this.parseNextResult() }}
                disabled={isNextResultDisabled || nextLocation === "--"}
            >
                <img src={require("./images/icons8-double-right-48.png")} alt={`${nextResult} (${nextLocation})`} width="30" height="30" />
            </Button>
        </div>
        previousButtons = <div className='taam-parser-left-nav-buttons'>
            <Button
                className='{classes.margin}'
                aria-label={`${previousResult} (${previousLocation})`}
                title={`${previousResult} (${previousLocation})`}
                style={{minWidth: 0, opacity: `${isPreviousResultDisabled || previousLocation === "--" ? .3 : 1}`}}
                onClick={(e) => { this.parsePreviousResult() }}
                disabled={isPreviousResultDisabled || previousLocation === "--"}
            >
                <img src={require("./images/icons8-double-left-48.png")} alt={`${previousResult} (${previousLocation})`} width="30" height="30" />
            </Button>
            <Button
                className='{classes.margin}'
                aria-label={previousPasuk}
                title={previousPasuk}
                style={{minWidth: 0, opacity: `${isFirstPasuk ? .3 : 1}`}}
                onClick={(e) => { this.parsePreviousPasuk() }}
                disabled={isFirstPasuk}
            >
                <img src={require("./images/icons8-back-96.png")} alt={previousPasuk} width="30" height="30" />
            </Button>
        </div>
    }

    return (
      <Rnd id={'TaamParserPopup'}
      // ref={c => { this.rnd = c; }}
      helpTopic="taamParser"
      size={{ width: `${this.props.widthNum}px`,  height: `${this.props.heightNum}px` }}
      position={{ x:this.props.x, y: this.props.y }}
      // onDragStop={(e, d) => { 
      //     // this.handleClick(e)
      //     this.dragRND(d);
      // }}
      onDragStop={(e, d) => { 
        this.props.setPosition(d.x, d.y)
      }}
      onResize={(e, direction, ref, delta, position) => {
        this.props.setSize(
          parseInt(ref.style.width.slice(0, -1)),
          parseInt(ref.style.height.slice(0, -1)),
          position.x,
          position.y
        )
      }}
      // bounds={'window'}
      onClick={this.handleClick}
      // onResize={(e, direction, ref, delta, position) => {
      //   this.resizeRND(e, direction, ref, delta, position)
      // }}
      style = {{  // margin: 'auto',
        //width: '50%',
        backgroundColor: '#FFFFFF',
        visibility: this.props.open ? 'visible' : 'hidden',
        boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
        borderRadius: '5px',
        zIndex: this.state.zIndex
      }}
      cancel = '.LCS-open-settings-button, .MuiDialog-container-16, .object-cloud-selector, .tanach-viewer-content, .tanach-viewer-text-display-controls, .placement-selector-2D-selector, .placement-selector-2D-popover, .tanach-viewer-resize-buttons'
      minWidth={420}
      minHeight={280}
      bounds='#root'
      className="tanach-viewer-rnd"
    >
      <div className="tanach-viewer-resize-buttons"
        style={{
          backgroundColor:'#FFFFFF',
          position: 'absolute',
          right: isHebrew ? 'unset' : 0,
          left: isHebrew ? 0 : 'unset',
          direction: isHebrew ? 'ltr' : 'rtl',
          top: 0,
          borderBottomLeftRadius: isHebrew ? 0 : '3px',
          borderBottomRightRadius: isHebrew ? '3px' : 0,
        }}
      >
        <Button className="tanach-viewer-resize-button" style={{hover:{cursor:'pointer'}}} onClick={this.props.handleClose} title={close} helpTopic="layout">
          <CloseIcon />
        </Button>
        <Button className="tanach-viewer-resize-button" onClick={() => {this.handleCopyLink()}} title={copyLink}>
          <LinkIcon />
        </Button>
        <Button className="tanach-viewer-resize-button" onClick={this.props.handleSplitScreen}  title={splitScreen}>
          <HorizontalSplitIcon />
        </Button>
        <Button className="tanach-viewer-resize-button" onClick={this.props.handleFullscreen} title={fullScreen}>
          <FullscreenIcon />
        </Button>
      </div>
    
    
    
    
    
    {/* <MuiThemeProvider theme={theme}> */}
      {/* <div  className='taam-parser-dialog-content' helpTopic="taamParser">  */}
                  {/* {this.state.showContextMenu ? <div style={{top:this.state.yPos,left:this.state.xPos,position:'absolute'}}><TaamParserContextMenu closeContextMenu={this.setContextMenuOff} id="TaamParserContextMenu" menuId={'TaamParserContextMenu'} xPos={this.props.xPos} yPos={this.props.yPos}  /></div>:<div id="TaamParserContextMenu"></div>} */}
                    
                    <DialogTitle id="alert-dialog-title taam-parser-dialog-title" align='center' style={{paddingBottom:'5px'}}>
                        <div style={directionStyling}><div style={{textAlign: 'center'}}>
                            {previousButtons}

                            <div className='taam-parser-title' >
                                <span className="medCaption" align="center"><Translate id="taamParser" /></span>

                                <Paper elevation={0} className="taam-parser-selector-paper">
                                  <div  style={{display:'inline', whiteSpace: 'nowrap'}}>
                                    <Typography variant='body1' style={{display:'inline'}}>{this.props.translate("sefer")}:</Typography>
                                    <FormControl style={{margin: '0 5px', fontWeight: 'bold'}}>
                                      <PlacementSelector2D onChange={this.handleRangeChange1} value={this.state.val1} menuItems={list1} activeLanguageCode={localStorage.getItem("language")}
                                        disabled={false} selectOrLoading={sel} type="taamParserSefer"/>
                                    </FormControl>
                                  </div>

                                  <div className='taamParserSelectorBuffer'></div>

                                  <div  style={{display:'inline', whiteSpace: 'nowrap'}}>
                                    <Typography variant='body1' style={{display:'inline'}}>{this.props.translate("perek")}:</Typography>
                                    <FormControl style={{margin: '0 5px', fontWeight: 'bold'}}>
                                      <PlacementSelector2D onChange={this.handleRangeChange2} value={this.state.val2} menuItems={list2} activeLanguageCode={localStorage.getItem("language")}
                                        disabled={this.state.disabled2} selectOrLoading={sel} type="taamParserPerek"/>
                                    </FormControl>
                                  </div>

                                  <div className='taamParserSelectorBuffer'></div>

                                  <div  style={{display:'inline', whiteSpace: 'nowrap'}}>
                                    <Typography variant='body1' style={{display:'inline'}}>{this.props.translate("pasuk")}:</Typography>
                                    <FormControl style={{margin: '0 5px', fontWeight: 'bold'}}>
                                      <PlacementSelector2D onChange={this.handleRangeChange3} value={this.state.val3} menuItems={list3} activeLanguageCode={localStorage.getItem("language")}
                                        disabled={this.state.pasukDisabled} selectOrLoading={sel} type="taamParserPasuk"/>
                                    </FormControl>
                                  </div>
                                </Paper>
                            </div>
                            {nextButtons}
                        </div></div>

                        <Paper elevation={1} id='taam-parser-key'>
                            תחומים:
                                <Typography className='taam-parser-background-key-label קיסר' style={{}}>קיסר</Typography>
                                <Typography className='taam-parser-background-key-label מלך' style={{}}>מלך</Typography>
                                <Typography className='taam-parser-background-key-label משנה' style={{}}>משנה</Typography>
                                <Typography className='taam-parser-background-key-label מפסיק-נוסף' style={{}}>מפסיק נוסף</Typography>
                                <Typography className='taam-parser-background-key-label שליש' style={{}}>שליש</Typography>
                                <Typography style={{padding: '10px', display: 'inline'}}></Typography>

                            מדריגות:
                                <Typography className='taam-parser-font-key-label' id='keisar-font-key-label'>קיסר</Typography>
                                <Typography className='taam-parser-font-key-label' style={{color: '#000000', display: 'inline', fontWeight: '600'}}>מלך</Typography>
                                <Typography className='taam-parser-font-key-label' style={{color: '#525252', display: 'inline', fontWeight: '600'}}>משנה / מפסיק נוסף</Typography>
                                <Typography className='taam-parser-font-key-label' style={{color: '#6D6D6D', display: 'inline', fontWeight: '600'}}>שליש</Typography>
                                <Typography className='taam-parser-font-key-label' style={{color: '#878787', display: 'inline', fontWeight: '600'}}>משרת</Typography>
                        </Paper>

                        <div style={directionStyling}>
                            <Typography style={{marginTop: '5px', textAlign: 'center'}} >{<Translate id="clickBranchToOpen" />}</Typography>
                        </div>

                    </DialogTitle>
                    <DialogContent className="tanach-viewer-content" style={{
                          direction: 'rtl',
                          maxHeight: `${this.props.widthNum>=776?this.props.heightNum-130:this.props.widthNum>=496?this.props.heightNum-170:this.props.heightNum-190}px`,
                          minHeight: `${this.props.widthNum>=776?this.props.heightNum-130:this.props.widthNum>=496?this.props.heightNum-170:this.props.heightNum-190}px`,
                          padding: '0 0 0px',
                          margin: '0 24px',
                        }}>
                <DialogContentText style={{direction: 'rtl', textAlign: 'right'}}>

                          <div className="treeTaamParser" align='center'>
                            <ul>
                              <Treefold
                                nodes={this.state.structuredNodes}
                                render={({
                                  node,
                                  isFolder,
                                  isExpanded,
                                  getToggleProps,
                                  renderChildNodes,
                                }) => (
                                  <li>
                                   <MenuProvider className='noBorder' id='TaamParserContextMenu'>

                                    <div onContextMenu={(e)=>this.openContextMenu(e)}
                                      className={isFolder ? 'non-leaf' : 'leaf'}
                                      {...(isFolder ? getToggleProps() : {})}
                                      onClick={() => {this.toggleCustomIsExpanded(node, this.state.structuredNodes)}}
                                    >
                                      {node && renderNode(node, isFolder)}
                                      {isFolder && !node.customIsExpanded && <AddCircleOutlineIcon className='open-close-branch-icon'/>}
                                      {isFolder && node.customIsExpanded && <RemoveCircleOutlineIcon className='open-close-branch-icon'/>}
                                    </div>
                                    </MenuProvider>

                                    {((node.customIsExpanded && node.children)) && <ul>{renderChildNodes()}</ul>}
                                  </li>
                                )}
                              />
                            </ul>
                          </div>

                        </DialogContentText></DialogContent>

                        {/* <TaamParserContextMenu closeContextMenu={this.setContextMenuOff} menuId='TaamParserContextMenu'
                          search={(obj)=>{this.props.search(obj)}} phrase={this.state.phrase}/> */}
                        
                        {/* <DialogActions style={directionStyling}>
                          <Button onClick={this.props.closeTaamParser} color="primary" helpTopic="layout">
                            <Translate id="close" />
                          </Button>
                        </DialogActions> */}



                        <Dialog
                          open={this.state.selectPasukMessageOpen}
                          onClose={this.handleCloseSelectPasukMessage}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          style={directionStyling}
                          helpTopic="taamParser"
                        >
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              <Translate id="pleaseSelectPasuk" />
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={this.handleCloseSelectPasukMessage} color="primary">
                              <Translate id="close" />
                            </Button>
                          </DialogActions>
                        </Dialog>

            {/* </div> */}
        {/* </MuiThemeProvider> */}
        </Rnd>
    );
  }
}

const theme = createMuiTheme({
  overrides: {
    MuiList: {
      root: {
        paddingRight: '0px',
        paddingLeft: '0px',
      },
      padding: {
        paddingRight: '0px',
        paddingLeft: '0px',
      },
    },
  },
});

export default withLocalize(TaamParser);