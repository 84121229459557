import React, { Component, useState } from 'react';
import { Rnd } from "react-rnd";
import 'react-contexify/dist/ReactContexify.min.css';
import { withLocalize, Translate } from "react-localize-redux";
import { Paper, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, FormControl, Select, MenuItem } from '@material-ui/core';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import CloseIcon from '@material-ui/icons/Close';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import LinkIcon from '@material-ui/icons/Link';
import YuchsinSectionSelector from './YuchsinSectionSelector'
import PlacementRenderer from './PlacementRenderer'
import {  InputBase, IconButton, Fade, Popper  } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import DeleteIcon from '@material-ui/icons/Delete';
import HebrewKeyboard from './HebrewKeyboard';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import "./css/App.css";
import "./css/LexiconSuperTreeview.css"
import SuperTreeview from 'react-super-treeview';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import * as properties from './config/properties.js';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import LoadingOverlay from 'react-loading-overlay';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ToggleIconButton from './ToggleIconButton';
import includeFilterOff from './images/useSelections_off.jpg';
import includeFilterOn from './images/useSelections_on.jpg';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LexiconLegend from './LexiconLegend'
import Dictaphone from './Dictaphone';


const styles = {
    root: {
        margin:'0 0',
      padding: '2px 4px',
      display: 'flex',
      height:'50px',
      alignItems: 'center',
    //   width: 275,
    //   maxWidth: 'calc(100vw - 570px)',
    //   minWidth: '798px'
    },
    input: {
      marginLeft: 8,
      flex: 1,
      fullWidth: true,
      fontSize: '16px',
      direction: 'rtl !important',
    },
    popper: {
        width: 650,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      width: 1,
      height: 28,
      margin: 4,
    }
  };
  
  const styles2 = theme => ({
    typography: {
      padding: theme.spacing.unit * 0,
    },
    toggleContainer: {
      height: 32,
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'row' ,
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      margin: `${theme.spacing.unit}px 0`,
      background: theme.palette.background.default,
    },
  });
  
//   const theme = createMuiTheme({ //can also use 2 toggleButton containers inside a Grid element to accomplish groups on same line as is done here https://material-ui.com/lab/toggle-button/
//     overrides: {
//       MuiToggleButtonGroup: {
//         root: {
//             display: 'block',
//         },
//         selected: {
//             display: 'block',
//         },
//       },
//     },
//     typography: { useNextVariants: true },
//   });

var defaultGeneralNekudaList = [0,0,0,0,0,0,0,0,0,0,0,0,0]; //array len = 13
var defaultGeneralTaamList = [0,0,0,0,0,0,0,0]; //array len = 8
var fullAlefBeis = ["א", "ב", "ג", "ד", "ה", "ו", "ז", "ח", "ט", "י", "כ", "ל", "מ", "נ", "ס", "ע", "פ", "צ", "ק", "ר", "ש", "ת", "ם", "ן", "ץ", "ף", "ך"];

function LexiconSearchParams(){
  this.filterIds = "";
  this.sourceTargetPairs=[];
  this.bituiParameters = []; //TermParameters()
  this.idSelected = 0; //TermParameters()
  this.lastSearchedText = "";
  this.namedLocations = [];
  this.pasukRangeLocations = [];
  this.sourceType = "Teiva";
  this.targetType = "Targum";
  this.synonymPath = null;
  // this.ids = [];
  this.entryIds = [];
  this.equivalentIds = [];
  this.textSource=1;
  this.exactnessLevel = 0;
  this.sortOrder = 0;
  this.sortDirection = 0;
}
function NamedLocation(kriaId, aliyaId) {
  this.kriaId =  kriaId; //int
  this.aliyaId = aliyaId; //int
}

function PerekPasukRange(seferId1, perekNum1, pasukNum1, seferId2, perekNum2, pasukNum2) {
  this.seferId1 =  seferId1; //int
  this.perekNum1 = perekNum1;  //int
  this.pasukNum1 =  pasukNum1; //int
  this.seferId2 = seferId2;  //int
  this.perekNum2 =  perekNum2; //int
  this.pasukNum2 = pasukNum2;  //int
}

function TermParameters(){
  this.term = "";
}
function encodeFilterIds(nodes, nodeString, isLexiconOpenedFromURL) {
  //when this is first called upon page load (eg. lexicon loaded directly from URL), sefer filter data won't be available yet so just assume all 44 sifrei Tanach are checked
  if (isLexiconOpenedFromURL)
      return "1".repeat(44); //assume all sefarim are checked when site is first loaded

  var nodeStr = (nodeString != undefined) ? nodeString : "0".repeat(44);
  nodes.forEach((node)=>{
      if (node.tableName == "Sefer" && node.isChecked)
          nodeStr = util.stuff(nodeStr, "1", node.id-1, 1);
      if(node.children){
          nodeStr = encodeFilterIds(node.children, nodeStr);
      }
  })
  return nodeStr;
}


class Lexicon extends Component {

    constructor(props) {
       super(props);
       this.state = {
        textSelectorData: [{ type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: [] }],
        anchorEl: null,
        open: false,
        equivalentsTree:null,
        resultsTree:null,
        wordsToInclude:[],
        equivalentsToInclude:[],
        searchTerm:this.props.lexiconSearchText?this.props.lexiconSearchText:"",
        targetType:this.props.sourceType==="Teiva"?"Targum":"Teiva",
        sourceType:this.props.sourceType?this.props.sourceType:"Teiva",
        equivalentsTreeChanged:false,
        firstOpen:true,
        isOpen:true,
        searchTermFinal:"",
        filterIds:"FFFFFFFFFFF",
        equivLoading:false,
        includeFilterFromRightBar:true,
        sortBy:'byOccurrences',
        accuracyLevel:-10,
        sortOrder:"desc",
        isLoading:false,
        loadingMore:false,
        textSelectorDataFinal:[],
        synonymPath:"",//this.props.sourceType && this.props.sourceType!=="Targum"?"Targum":"Teiva",
        newSynonymPath:[],
        zIndex:810,
        lexiconKeyOpen:true,
        equivalentsKey: "",
        resultsKey: "",
       }
       this.initialState=this.state;

       this.handleClose = this.handleClose.bind(this);
       this.handleCopyLink = this.handleCopyLink.bind(this);
       this.handleFullscreen = this.handleFullscreen.bind(this);
       this.handleSplitScreen = this.handleSplitScreen.bind(this);
       this.getTextSelectorRenderVals = this.getTextSelectorRenderVals.bind(this);
       this.deleteTextSelectorRow = this.deleteTextSelectorRow.bind(this);
       this.addTextSelectorRow = this.addTextSelectorRow.bind(this);
       this.textSelectorGridReady = this.textSelectorGridReady.bind(this);
       this.modifyTextSelectorRow = this.modifyTextSelectorRow.bind(this);
       this.handleClick = this.handleClick.bind(this);
       this.handleChange = this.handleChange.bind(this);
       this.handleClear = this.handleClear.bind(this);
       this.extTogglePopupCallback = this.extTogglePopupCallback.bind(this);
       this.extKBBackspace = this.extKBBackspace.bind(this);
       this.extKBChange = this.extKBChange.bind(this);
       this.extKBDelete = this.extKBDelete.bind(this);
       this.searchTanach = this.searchTanach.bind(this);
       this.resetEquivalentsTree = this.resetEquivalentsTree.bind(this);
       this.resetResultsTree = this.resetResultsTree.bind(this);
       this.handleSearchTerm = this.handleSearchTerm.bind(this);
       this.onMenuAction = this.onMenuAction.bind(this);
       this.onMenuNodeToggle = this.onMenuNodeToggle.bind(this);
       this.setWordsToInclude = this.setWordsToInclude.bind(this);
       this.setEquivalentsToInclude = this.setEquivalentsToInclude.bind(this);
       this.handleEquivalentsChange = this.handleEquivalentsChange.bind(this);
       this.handleResultsChange = this.handleResultsChange.bind(this);
       this.clearEverything = this.clearEverything.bind(this);
       this.fetchResults = this.fetchResults.bind(this);
       this.populateEquivalents = this.populateEquivalents.bind(this);
       this.expandToggleCb = this.expandToggleCb.bind(this);
       this.expandEquivalents = this.expandEquivalents.bind(this);
       this.collapseEquivalents = this.collapseEquivalents.bind(this);
       this.selectAllEquivalents = this.selectAllEquivalents.bind(this);
       this.unselectAllEquivalents = this.unselectAllEquivalents.bind(this);
       this.collapseResults = this.collapseResults.bind(this);
       this.unselectAllResults = this.unselectAllResults.bind(this);
       this.cycleThroughTree = this.cycleThroughTree.bind(this);
       this.setUpParamsToSend = this.setUpParamsToSend.bind(this);
       this.toggleIncludeFilter = this.toggleIncludeFilter.bind(this);
       this.setSortBy = this.setSortBy.bind(this);
       this.flipSort = this.flipSort.bind(this);
       this.setAccuracyLevel = this.setAccuracyLevel.bind(this);
       this.sortEquivalents = this.sortEquivalents.bind(this);
       this.filterBasedOnAccuracyLevel = this.filterBasedOnAccuracyLevel.bind(this);
       this.readFilterIds = this.readFilterIds.bind(this);
       this.setUpEquivTree = this.setUpEquivTree.bind(this);
       this.swapResultsWithEquivalents = this.swapResultsWithEquivalents.bind(this);
       this.handleSynonymsChange =  this.handleSynonymsChange.bind(this);
       this.showOnTop = this.showOnTop.bind(this);
       this.checkAllOpenResults = this.checkAllOpenResults.bind(this);
       this.checkForEquivalents = this.checkForEquivalents.bind(this);
       this.checkAllResults = this.checkAllResults.bind(this);
       this.setUpTermForSearch = this.setUpTermForSearch.bind(this);
    }
    
    findHighestZIndex(elem) {
      var elems = document.getElementsByTagName("*");
      var highest = 0;
      for (var i = 0; i < elems.length; i++){
        var zindex=document.defaultView.getComputedStyle(elems[i],null).getPropertyValue("z-index"); 
        if ((zindex > highest) && (zindex != 'auto') && (zindex != 9998) && (zindex != 100000090)){//don't put the lexicon on top of the notifications==9998 or the wordTooltip==100000090
          highest = zindex;
        }
      }
      return parseFloat(highest);
    }

    showOnTop(){
      this.setState({
        zIndex: this.findHighestZIndex() + 1
      })
    }

    handleCopyLink() {
        var params = [];

        if (this.state.searchTerm) {
            var srcType = this.state.sourceType.toLowerCase();
            srcType = srcType == "teiva" ? "word" : (srcType == "shoresh" ? "root" : srcType);
            params.push(srcType + "=" + this.state.searchTerm);
            params.push("precision=" + this.props.ssExactnessLevel); //eg. 0,1,2,3 for exact..fuzzy
        }
        this.props.handleCopyLink("lexicon", params, this.props.isFullScreen(this.props.x, this.props.y, this.props.heightNum,this.props.widthNum));
    }

    handleFullscreen(){
        this.props.handleFullscreen();
    }
    
    handleSplitScreen(){
        this.props.handleSplitScreen();
    }

    handleClose(){
      var tarType = this.state.targetType;
      var srcType = this.state.sourceType;
      var synType = this.state.synonymPath;
      var newSynPath = [];
      if(srcType===tarType){
        newSynPath[this.state.sourceType]=this.state.newSynonymPath[this.state.sourceType]
      }this.setState({isOpen:false,open:false},()=>{
        this.setState(this.initialState,()=>{//{resultsTree:null,equivalentsTree:null,searchTerm:"",searchTermFinal:"",firstOpen:true},()=>{
          this.setState({isOpen:false,targetType:tarType,sourceType:srcType,synonymPath:synType,newSynonymPath:newSynPath},()=>{
            this.props.toggleLexicon(false)
          })
        })
      })
    }
    textSelectorGridReady = params => {
        this.setState({
          textSelectorApi: params.api,
          textSelectorColumnApi: params.columnApi
        }, () => {
          this.state.textSelectorApi.setRowData(this.state.textSelectorData);
    
          //following CSS classes seem to get overridden below in PlacementRenderer by placementGridLine and rangeGridLine
          this.state.textSelectorApi.setColumnDefs(
            [{
              headerName: this.props.translate("addLines"), headerClass: 'row-thin-center', field: "namedSection", width: 1168, cellClass: "row-thin-small", cellRendererFramework: PlacementRenderer, cellRendererParams:
              {
                placementRowData:this.props.placementRowData,
                extModifyPlacementRow: this.modifyTextSelectorRow,
                extAddPlacementRow: this.addTextSelectorRow,
                extDeletePlacementRow: this.deleteTextSelectorRow,
                translate: this.props.translate, //do I need to pass this here?
              },
              //cellClass: function(params) {return params.data.type ? 'placementGridLine' : 'rangeGridLine';},
            }]
          );
          if (this.state.textSelectorData.length > 0)
            this.state.textSelectorApi.setFocusedCell(0, "namedSection", null);
          //   var instances = this.refs.agGrid.gridOptions.api.getCellRendererInstances(params);
        })
      };
      addTextSelectorRow() {
        var newRow = { type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: [] };
        var newData = this.state.textSelectorData;
        newData.push(newRow);
        this.setState({textSelectorData:[]},()=>{
          this.state.textSelectorApi.updateRowData({ add: [newRow] });
          this.setState({ textSelectorData: newData, rowDataLength:newData.length-1 })
        })    
        // this.setState({ textSelectorData: newData }, () => { this.state.textSelectorApi.updateRowData({ add: [newRow] }) });
      }
    
      deleteTextSelectorRow(extState) {
        var selected = this.state.textSelectorApi.getFocusedCell();
        var i = selected.rowIndex;
        if (i == this.state.textSelectorApi.getLastDisplayedRow()) { //then just clear
          this.modifyTextSelectorRow(extState);
          //selected.setSelected(false);
        }
        else {
          var selectedData = this.state.textSelectorApi.getSelectedRows();
          var newData = this.state.textSelectorData;
          newData.splice(i, 1);
          this.setState({ textSelectorData: newData, rowDataLength:i }, () => { this.state.textSelectorApi.updateRowData({ remove: selectedData }) });
        }
      }
      modifyTextSelectorRow(extState) {
        var isAddRow = (extState.type && extState.val4 != this.state.textSelectorData.val4 && extState.val4 != 0) || (!extState.type && extState.val3 != this.state.textSelectorData.val3 && extState.val3 != 0 && extState.val6 != this.state.textSelectorData.val6 && extState.val6 != 0);
        var selected = this.state.textSelectorApi.getFocusedCell();
    
        var i = selected.rowIndex;
        var rowValues = {
          type: extState.type, val1: extState.val1, val2: extState.val2, val3: extState.val3, val4: extState.val4, val5: extState.val5, val6: extState.val6,
          valList1: extState.valList1, valList2: extState.valList2, valList3: extState.valList3, valList4: extState.valList4, valList5: extState.valList5, valList6: extState.valList6
        };
        var newData = this.state.textSelectorData;
        newData[i] = rowValues;
    
        this.setState({ textSelectorData: newData }, () => {
          if (i == this.state.textSelectorApi.getLastDisplayedRow() && isAddRow) {
            this.addTextSelectorRow();
          }else{
            this.setState({textSelectorData:[]},()=>{
              this.state.textSelectorApi.updateRowData({ update: [rowValues] });
              this.setState({ textSelectorData: newData })
            })
          }
        });
      }
      getTextSelectorRenderVals(textDataRow) {
        var renderVal1, renderVal2, renderVal3, renderVal4, renderVal5, renderVal6
        var renderVals
        if (textDataRow.type) { //is named section
          var selectedVal1 = textDataRow.valList1.filter(obj => { return obj.id === textDataRow.val1 });
          var selectedVal2 = textDataRow.valList2.filter(obj => { return obj.id === textDataRow.val2 });
          var selectedVal3 = textDataRow.valList3.filter(obj => { return obj.id === textDataRow.val3 });
          var selectedVal4 = textDataRow.valList4.filter(obj => { return obj.id === textDataRow.val4 });
          var selectedVal5 = textDataRow.valList5.filter(obj => { return obj.id === textDataRow.val5 });
    
          if (selectedVal1[0]) { renderVal1 = selectedVal1[0].value + ' > ' }
          if (selectedVal2[0]) { renderVal2 = selectedVal2[0].value + ' > ' }
          if (selectedVal3[0]) { renderVal3 = selectedVal3[0].value + ' > ' }
          if (selectedVal4[0]) { renderVal4 = selectedVal4[0].value + ' > ' }
          if (selectedVal5[0]) { renderVal5 = selectedVal5[0].value + ' > ' }
    
          var renderVals = [renderVal1, renderVal2, renderVal3, renderVal4, renderVal5]
    
          for (let i = 4; i >= 0; i--) {
            if (renderVals[i]) {
              renderVals[i] = renderVals[i].substring(0, renderVals[i].length - 3)
              break
            }
            //if it is defined, set the one after it as the last value
          }
    
        } else { //is pasuk range
          var selectedVal1 = textDataRow.valList1.filter(obj => { return obj.seferId === textDataRow.val1 });
          var selectedVal2 = textDataRow.valList2.filter(obj => { return obj.perekNum === textDataRow.val2 });
          var selectedVal3 = textDataRow.valList3.filter(obj => { return obj.pasukNum === textDataRow.val3 });
          var selectedVal4 = textDataRow.valList4.filter(obj => { return obj.seferId === textDataRow.val4 });
          var selectedVal5 = textDataRow.valList5.filter(obj => { return obj.perekNum === textDataRow.val5 });
          var selectedVal6 = textDataRow.valList6.filter(obj => { return obj.pasukNum === textDataRow.val6 });
    
          if (selectedVal1[0]) { renderVal1 = selectedVal1[0].seferName }
          if (selectedVal2[0]) { renderVal2 = selectedVal2[0].perekValue }
          if (selectedVal3[0]) { renderVal3 = selectedVal3[0].pasukValue }
          if (selectedVal4[0]) { renderVal4 = selectedVal4[0].seferName }
          if (selectedVal5[0]) { renderVal5 = selectedVal5[0].perekValue }
          if (selectedVal6[0]) { renderVal6 = selectedVal6[0].pasukValue }
    
          renderVals = [renderVal1, renderVal2, renderVal3, renderVal4, renderVal5, renderVal6]
        }
    
        return renderVals
      }

      handleClick = event => {
        const { currentTarget } = event;
        if(!this.state.open){
          this.props.closeSimpleSearchKb()
        }
        setTimeout(()=>{
          this.setState(state => ({
            anchorEl: document.getElementById("lexiconTerms"), //currentTarget,
            open: !state.open,
          }))},700)
      };

      handleChange(event){
          this.setState({searchTerm:event.target.value})
      }
      handleClear(){
          this.setState({searchTerm:''})
      }
      extTogglePopupCallback() {
        this.setState({open: false,});
      }

      extVoiceInputBuffer = (data) => {
        this.setState({searchTerm:data});
      }

      extVoiceInputChange = (before, data, after) => {
          var el = document.getElementById("lexiconTerms");
          var start = el.selectionStart;
          var end = el.selectionEnd;
          el.value = (before + data + after);
          el.selectionStart = el.selectionEnd = before.length + data.length;
          this.setState({searchTerm:el.value});
          el.focus();
      }

      extKBChange = (data) => {
        var el = document.getElementById("lexiconTerms");
        var start = el.selectionStart;
        var end = el.selectionEnd;
        var text = el.value;
        var before = text.substring(0, start);
        var after  = text.substring(end, text.length);
        el.value = (before + data + after);
        el.selectionStart = start + data.length;
        el.selectionEnd = el.selectionStart
        this.setState({searchTerm:el.value});
        el.focus();
    }

    extKBBackspace = () => {
        var el = document.getElementById("lexiconTerms");
        var start = el.selectionStart;
        if (start > 0) {
            var end = el.selectionEnd;
            var text = el.value;
            var before = text.substring(0, start-1);
            var after  = text.substring(end, text.length);
            el.value = (before + after);
            el.selectionStart = el.selectionEnd = start-1;
        }
        this.setState({searchTerm:el.value});
        el.focus();
    }

    extKBDelete = () => {
        var el = document.getElementById("lexiconTerms");
        var start = el.selectionStart;
        var end = el.selectionEnd;
        var text = el.value;
        if (end < text.length) {
            var before = text.substring(0, start);
            var after  = text.substring(end+1, text.length);
            el.value = (before + after);
            el.selectionStart = el.selectionEnd = start;
        }
        this.setState({searchTerm:el.value});
        el.focus();
    }
    async searchTanach(){
        //do the search from here and then split the screen.
        this.props.extOpenSearchResults();
        this.props.extInitializeSearchState();
        this.props.setSearchIsLoading(true);

        this.handleSplitScreen();
      var filterIds = this.readFilterIds()
      if(this.state.targetType==="Targum")this.props.extSetTextSource("targum")
      else this.props.extSetTextSource("tanach")
      if(this.state.targetType==="Targum" || this.state.sourceType==="Targum" || this.state.synonymPath==="Targum") this.props.extSetContentDisplayed(["tanach","targum"])
      else this.props.extSetContentDisplayed(null);
      if(this.state.filterIds !== filterIds){
        this.props.sendNotification('You\'ve changed the seforim selected. They will not be applied to Lexicon Searches until the next time you update the word list in the Lexicon.', 5)
      }

        var lexiconSearchParams=this.setUpParamsToSend()//new LexiconSearchParams()//these params have to include more data so that the scrolling can work properly
        //because the scrolling calls a new fetch if need to get a new row/s
        // var pairs = this.state.equivalentsToInclude.map(equ=>{
        //   return {sourceId:equ.srcId, targetId:equ.tarId, targetText:equ.tarText}
        // })
        // // var filterIds = encodeFilterIds(this.props.extFilterData);

        // // filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
        // // filterIds = util.right("00000000000"+filterIds,11);

        // lexiconSearchParams.filterIds = this.state.filterIds//filterIds;

        // lexiconSearchParams.sourceTargetPairs=pairs//.push({sourceId:116836,targetId:117993,targetText:null})
        // var e, i, j, k;
        // for (i = 0; i < this.state.textSelectorData.length-1; i++) { //last position in textSelectorData is never populated
        //     e = this.state.textSelectorData[i];
        //     if (e.type)
        //         lexiconSearchParams.namedLocations.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
        //     else
        //         lexiconSearchParams.pasukRangeLocations.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
        // }
      
        var url=properties.env.webServiceURL + '/BaseHaSefer//getPesukimWithLexicon?source=bhsWebApp'
        const response = await fetch(url, {method: 'post', body: JSON.stringify(lexiconSearchParams)});   
        var responseBody = await response.json()
        const searchData = {
          results: responseBody,
          searchParams: lexiconSearchParams
      }
      this.props.extOpenSearchResults(searchData, "לקסיקון"); //do this to update search results grid governed by Home.js
  
      this.props.setSearchIsLoading(false);
  
    }

    setWordsToInclude(filterData, filterDataArrayInc,tarId,allBottomLeaves){
        var wordsToInclude=(filterDataArrayInc != undefined)?filterDataArrayInc:[];//array of words to include
        if(filterData){
          filterData.forEach((data)=>{
            if((allBottomLeaves && data.isExpanded && data.children) || (tarId && allBottomLeaves && data.isExpandable) || ((!allBottomLeaves) && data.children)){
              this.setWordsToInclude(data.children, wordsToInclude,tarId,allBottomLeaves);
            }else{
              if(((!allBottomLeaves) && data.isChecked) || (allBottomLeaves && data.checkable)){
                wordsToInclude.push(tarId? data.tarId: data.id);
              }
            } 
          })
        }
        return wordsToInclude;
      }
      setEquivalentsToInclude(filterData, filterDataArrayInc){
        var equivalentsToInclude=(filterDataArrayInc != undefined)?filterDataArrayInc:[];//array of words to include
        filterData.forEach((data)=>{
          if(data.children){
            this.setEquivalentsToInclude(data.children, equivalentsToInclude);
          }else{
            if(data.isChecked){
                equivalentsToInclude.push(data);
            }
          } 
        })
        this.setState({equivalentsToInclude})
      }
      
    resetEquivalentsTree(data){
        this.setEquivalentsToInclude(data)
        this.setState({equivalentsTree:data})
    }
    async resetResultsTree(data){
        var wordsToInclude = this.setWordsToInclude(data,undefined)
        var oldWordsToInclude = this.setWordsToInclude(this.state.resultsTree)
        if(wordsToInclude.sort((a, b) => a - b).toString() !== this.state.wordsToInclude.sort((a, b) => a - b).toString()){//wordsToInclude.sort((a, b) => a - b).toString() !== oldWordsToInclude.sort((a, b) => a - b).toString()){//
          this.setState({wordsToInclude}, async()=>{
            this.setState({equivLoading:true, equivalentsTree:await this.populateEquivalents()},()=>{
              this.setState({equivLoading:false})
            })
          })
        }
        this.setState({resultsTree:data})
    }

    async componentDidMount(){
        var resArray = await this.fetchResults("",0)
        this.setState({resultsTree:resArray})
        this.props.toggleIsLexiconOpenedFromURL(false);
    }
    async componentDidUpdate(prevProps,prevState){
      if(this.state.isOpen && prevProps.lexiconKeyboard!==this.props.lexiconKeyboard){
        this.setState({open:false})
      }
      if(this.state.rowDataLength !== prevState.rowDataLength){
        this.state.textSelectorApi.gridOptionsWrapper.gridOptions.api.ensureIndexVisible(this.state.rowDataLength,'bottom')
      }
        // if((this.props.isOpen !== prevProps.isOpen && this.props.isOpen && this.state.firstOpen) || (this.state.includeFilterFromRightBar && prevState.includeFilterFromRightBar==false)){
        //     var filterIds = this.readFilterIds();
        //     // this.setState({/*isLoading:true,*/filterIds,equivalentsTree:null,equivalentsToInclude:[]},async()=>{
        //       await this.handleSearchTerm()

        //       // var arr = await this.fetchResults("",0)
        //       this.setState({ /*resultsTree: arr,*/ firstOpen: false, isLoading: false })
        //     // })
        // }
        // else
        if(this.props.isOpen && !prevProps.isOpen){
          this.showOnTop();

          if (this.props.isFirstTimeOpened && this.props.initialFullScreen && "TY".indexOf(this.props.initialFullScreen.toUpperCase()) >= 0) {
              this.handleFullscreen();
              this.props.extSetFirstTimeOpened();
          }

          this.setState({isOpen:true}); //firstOpen:true})

          this.setResultsKey();
          this.setEquivalentsKey(this.synonymPath, this.targetType);
        }

        var isLexiconOpenedFromURL = this.props.isLexiconOpenedFromURL;

        if((this.props.isOpen !== prevProps.isOpen && this.props.isOpen) || (this.props.isOpen && this.state.firstOpen) || 
                (this.state.includeFilterFromRightBar && prevState.includeFilterFromRightBar==false && this.props.isOpen)){
                  if(this.state.firstOpen){
                    this.setState({ sourceType:this.props.sourceType?this.props.sourceType:"Teiva", targetType:(!this.props.sourceType || this.props.sourceType==="Teiva")?"Targum":"Teiva",searchTerm:this.props.lexiconSearchText,firstOpen:false},async()=>{
                      if((this.state.isOpen) && ((this.state.sourceType==="Targum" || this.state.targetType==="Targum") || (this.state.sourceType==="Shoresh" || this.state.targetType==="Shoresh") || this.state.synonymPath==="Shoresh" || this.state.synonymPath==="Targum")){
                        this.props.extSetBeta()
                        if((this.state.sourceType==="Targum" || this.state.targetType==="Targum" || this.state.synonymPath==="Targum") && encodeFilterIds(this.props.extFilterData,null,isLexiconOpenedFromURL).substring(5)!=="000000000000000000000000000000000000000" || !this.state.includeFilterFromRightBar){
                          this.props.sendNotification(this.props.translate("deselectNach"),3)
                        }
                      }
                      await this.handleSearchTerm()
                    })
                  }else{
                    if(this.props.isOpen && !prevProps.isOpen){
                      this.setState({isOpen:true},async()=>{
                        if((this.state.isOpen) && ((this.state.sourceType==="Targum" || this.state.targetType==="Targum") || (this.state.sourceType==="Shoresh" || this.state.targetType==="Shoresh") || this.state.synonymPath==="Shoresh" || this.state.synonymPath==="Targum")){
                          this.props.extSetBeta();
                          if((this.state.sourceType==="Targum" || this.state.targetType==="Targum" || this.state.synonymPath==="Targum") && encodeFilterIds(this.props.extFilterData,null,isLexiconOpenedFromURL).substring(5)!=="000000000000000000000000000000000000000" || !this.state.includeFilterFromRightBar){
                            this.props.sendNotification(this.props.translate("deselectNach"),3)
                          }
                        }
                      await this.handleSearchTerm()  
                    })
                    }
                  }
        }


        if(//((this.props.isOpen !== prevProps.isOpen && this.props.isOpen/* && this.state.firstOpen*/) || 
           // (this.state.includeFilterFromRightBar && prevState.includeFilterFromRightBar==false)) || 
            (this.props.sourceType !== prevProps.sourceType || this.props.lexiconSearchText !== prevProps.lexiconSearchText)){
                this.setState({sourceType:this.props.sourceType?this.props.sourceType:this.state.sourceType?this.state.sourceType:"Teiva", targetType:(!this.props.sourceType && this.state.targetType)?this.state.targetType:(!this.props.sourceType || this.props.sourceType==="Teiva")?"Targum":"Teiva",searchTerm:this.props.lexiconSearchText},async()=>{
                  await this.handleSearchTerm()
                  if(this.state.firstOpen)this.setState({firstOpen:false})
                  this.showOnTop()
                })
        }
        if(this.state.sourceType === this.state.targetType && (this.props.isOpen===true && prevProps.isOpen===true) && 
                  (this.state.sourceType !== prevState.sourceType || this.state.targetType !== prevState.targetType)){
          
          // if(this.state.synonymPath === this.state.sourceType){
            this.setState({synonymPath:this.state.newSynonymPath[this.state.sourceType]?this.state.newSynonymPath[this.state.sourceType]:this.state.sourceType==="Teiva" || this.state.sourceType==="Shoresh"?"Targum":this.state.sourceType==="Atbash" || this.state.sourceType==="Gematria"?"Shoresh":"Teiva"},()=>{
          // }
            if(this.state.isOpen && (this.state.synonymPath==="Targum" || this.state.synonymPath==="Shoresh")){
              this.props.extSetBeta();
              if(this.state.synonymPath==="Targum" && (encodeFilterIds(this.props.extFilterData,null,isLexiconOpenedFromURL).substring(5)!=="000000000000000000000000000000000000000" || !this.state.includeFilterFromRightBar)){
                this.props.sendNotification(this.props.translate("deselectNach"),3)
              }
            }
          })
        }
        if(this.state.sourceType!==prevState.sourceType || this.state.targetType!==prevState.targetType){
          if(this.state.sourceType!==this.state.targetType){
            this.setState({synonymPath:""})
          }
        }
        if((this.state.sourceType !== prevState.sourceType ) && (this.state.sortBy==="byGematria")){
          if(!(this.state.sourceType==="Gematria" || this.state.sourceType==="Atbash" )){
            this.setState({sortBy:"byOccurrences"})
          }
        }
        if((this.state.synonymPath !== prevState.synonymPath) && (this.state.sortBy==="byMiddleGematria")){
          if(!(this.state.synonymPath==="Gematria" || this.state.synonymPath==="Atbash")){
            this.setState({sortBy:"byOccurrences"})
          }
        }
        // if(this.state.includeFilterFromRightBar && prevState.includeFilterFromRightBar==false ){
        //   var filterIds = this.readFilterIds();
          // filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
          // filterIds = util.right("00000000000"+filterIds,11);  
          // this.setState({filterIds, isLoading:true, equivalentsTree:null},async()=>{
          //     var resArray = await this.fetchResults("",0)
          //     this.setState({resultsTree:resArray,isLoading:false})
          // })
        // }
    }

    readFilterIds(){
      if(this.state.includeFilterFromRightBar && this.props.extFilterData){
          var filterIds = encodeFilterIds(this.props.extFilterData, null, this.props.isLexiconOpenedFromURL);
          filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
          filterIds = util.right("00000000000"+filterIds,11);  
          return filterIds;
      }
      return "FFFFFFFFFFF"
    }
    getConvertedShemHashem(term){
      var shemHashem = "יהוה"; // rtl is messing this up in VSCode. 
      while(-1 !== term.indexOf("ה'")){
          term = term.replace("ה'", shemHashem) // rtl is messing this up in VSCode. This replaces ה' with shem hashem 
      }
      return term;
    }
    setUpTermForSearch(){
      var term = this.state.searchTerm
      if(term){
        if(-1 !== term.indexOf("ה'")){// writing this backwards to avoid rtl issues in VSCode
          term = this.getConvertedShemHashem(term);
        }
        if(this.state.includeFilterFromRightBar){
          if(this.props.exactnessLevel === 1){
            term = "*"+term+"*"
          }else if(this.props.exactnessLevel === 2){
            term = "*"+term.split("").join("*")+"*"
          }else if(this.props.exactnessLevel === 3){

          }
        }
      }
      return term;
    }

    async setResultsKey() {
        var text = 'palooka:' + this.state.sourceType;
        this.setState({resultsKey:text});
    }

    async setEquivalentsKey(synonymButtonName, equivalentsButtonName) {
        var text = 'shalooka:' + this.state.sourceType + ':' + synonymButtonName + ':' + equivalentsButtonName;
        this.setState({equivalentsKey:text});
    }

    async handleSearchTerm(e, checkAll,clearSearchTerm){
        if(e) e.preventDefault()
        if(this.state.isLoading || this.state.loadingMore || this.state.equivLoading || !this.state.isOpen) return false
        var filterIds = this.readFilterIds()
        var textSelectorDataFinal=[];
        this.state.textSelectorData.forEach(sub=>{
          textSelectorDataFinal.push(sub)
        })
        // var filterIds = encodeFilterIds(this.props.extFilterData, null, this.props.isLexiconOpenedFromURL);
        // filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
        // filterIds = util.right("00000000000"+filterIds,11);
        var term = this.state.sourceType==="Gematria" || this.state.sourceType==="Atbash" ? this.state.searchTerm : this.setUpTermForSearch()
        this.setState({resultsTree:null,wordsToInclude:[],searchTermFinal:clearSearchTerm?"":term,filterIds,textSelectorDataFinal, isLoading:true, equivalentsTree:null,equivalentsToInclude:[]},async()=>{
            var resArray = await this.fetchResults("",0)
            this.setState({resultsTree:resArray,isLoading:false},()=>{
              if(resArray && (resArray.length===1 || (resArray.length>1 && checkAll))){
                resArray.map(res=>{
                  return res.checkable?res.isChecked=true:res.isChecked=false;//can change this back to set it checked no matter what
                })
                this.expandToggleCb(resArray[0],0,false,true)
              }        
            })
        })

        // this.setState({searchTermFinal:this.state.searchTerm, /*filterIds,*/ isLoading:true},async()=>{
        //     var resArray = await this.fetchResults("",0)
        //     this.setState({resultsTree:resArray,equivalentsTree:null,isLoading:false})
        // })
    }


    onMenuNodeToggle = currentNode => {  }

    onMenuAction = ({ action, node }) => {  }

    handleResultsChange(event,res){
      if(this.state.isLoading || this.state.loadingMore || this.state.equivLoading || !res) return null
      if(res==="Targum" || res==="Shoresh")this.props.extSetBeta()
      var newTargetType = ((this.state.targetType === "Gematria" || this.state.targetType === "Atbash") && res === "Targum") || ((res === "Gematria" || res === "Atbash") && this.state.targetType === "Targum")
      var clearSearchTerm = (((res==="Gematria" || res==="Atbash") && (this.state.sourceType==="Teiva" || this.state.sourceType==="Shoresh" || this.state.sourceType==="Targum")) 
              || ((this.state.sourceType==="Gematria" || this.state.sourceType==="Atbash") && (res==="Teiva" || res==="Shoresh" || res==="Targum")))
        //ToDo:If there is a word in the search term for gematria or atbash, check and search for the number corresponding to that word
      this.setState({sourceType:res,searchTerm:clearSearchTerm?"":this.state.searchTerm,targetType:newTargetType?"Teiva":this.state.targetType},async()=>{
        if(res==="Targum" && (encodeFilterIds(this.props.extFilterData, null, this.props.isLexiconOpenedFromURL).substring(5)!=="000000000000000000000000000000000000000" || !this.state.includeFilterFromRightBar)){
          this.props.sendNotification(this.props.translate("deselectNach"),3)
        }
        await this.setResultsKey();
        await this.handleSearchTerm();
      })
    }

    handleEquivalentsChange(event,res){
      if(this.state.loadingMore || this.state.equivLoading || !res) return null;
      if(res==="Targum" || res==="Shoresh")this.props.extSetBeta()
      if(res==="Targum" && (encodeFilterIds(this.props.extFilterData, null, this.props.isLexiconOpenedFromURL).substring(5)!=="000000000000000000000000000000000000000" || !this.state.includeFilterFromRightBar)){
        this.props.sendNotification(this.props.translate("deselectNach"),3)
      }        
      if(this.state.wordsToInclude.length>0){ 
        if(this.state.sourceType===res){//if need a synonymPath, must do it before fetching equivalents
          this.setState({synonymPath:this.state.newSynonymPath[this.state.sourceType]?this.state.newSynonymPath[this.state.sourceType]:this.state.sourceType==="Teiva" || this.state.sourceType==="Shoresh"?"Targum":this.state.sourceType==="Atbash" || this.state.sourceType==="Gematria"?"Shoresh":"Teiva"},()=>{
            this.setState({targetType:res, equivalentsTree:null,equivalentsToInclude:[],equivLoading:true},async()=>{//load new equivalents for checked results
              await this.populateEquivalents().then(res=>{
                this.setState({equivalentsTree:res},()=>{
                this.setState({equivLoading:false})
              })})
            })
          })
        }else{
          this.setState({targetType:res, equivalentsTree:null,equivalentsToInclude:[],equivLoading:true},async()=>{//load new equivalents for checked results
            await this.populateEquivalents().then(res=>{
              this.setState({equivalentsTree:res},()=>{
              this.setState({equivLoading:false})
            })})
          })
        }     
      }else{
        this.setState({targetType:res, equivalentsTree:null,equivalentsToInclude:[]})
      }

      this.setEquivalentsKey(this.state.synonymPath, res);
    }

    handleSynonymsChange(event, res){
      if( this.state.loadingMore || this.state.equivLoading || !res) return null;
      if(res==="Targum" || res==="Shoresh")this.props.extSetBeta()
      if(res==="Targum" && (encodeFilterIds(this.props.extFilterData, null, this.props.isLexiconOpenedFromURL).substring(5)!=="000000000000000000000000000000000000000" || !this.state.includeFilterFromRightBar)){
        this.props.sendNotification(this.props.translate("deselectNach"),3)
      }
      var newSynonymPath = this.state.newSynonymPath;
      newSynonymPath[this.state.sourceType] = res;
      if(this.state.wordsToInclude.length>0){ 
        this.setState({synonymPath:res,newSynonymPath,equivalentsTree:null,equivalentsToInclude:[],equivLoading:true},async()=>{//load new equivalents for checked results
            await this.populateEquivalents().then(res=>{
              this.setState({equivalentsTree:res},()=>{
              this.setState({equivLoading:false})
            })})
        })
      }else{
        this.setState({synonymPath:res,newSynonymPath,equivalentsTree:null,equivalentsToInclude:[]})
      }

      this.setEquivalentsKey(res, this.state.targetType);
    }

    async clearEverything(){
      var filterIds = this.readFilterIds();
      var sourceType = this.state.sourceType;
      var targetType = this.state.targetType;
      this.setState(this.initialState,()=>{
        this.setState({filterIds,targetType,sourceType},async()=>{
        // this.setState({equivalentsTree:null,resultsTree:null,filterIds, isLoading:true,searchTerm:"",searchTermFinal:"",textSelectorData:[{ type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: [] }]},async()=>{
            // var res = await this.fetchResults("",0)
            // this.setState({resultsTree:res,isLoading:false})
            await this.handleSearchTerm();
       })})
    }
    async fetchResults(word,id,node){
      if(node && node.children){
        for(let i=0;i<node.children.length; i++){
          if(node.isChecked)node.children[i].isChecked=true
        }
        return node.children
      }
      var lexiconSearchParams = this.setUpParamsToSend(id,word);// new LexiconSearchParams()
      // lexiconSearchParams.lastSearchedText = this.state.searchTermFinal;
      // lexiconSearchParams.idSelected = id;
      // lexiconSearchParams.filterIds = this.state.filterIds;
      // var e, i, j, k;
      // for (i = 0; i < this.state.textSelectorData.length-1; i++) { //last position in textSelectorData is never populated
      //     e = this.state.textSelectorData[i];
      //     if (e.type)
      //         lexiconSearchParams.namedLocations.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
      //     else
      //         lexiconSearchParams.pasukRangeLocations.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
      // }

      // var termParameters = new TermParameters()
      // termParameters.term = this.state.searchTermFinal
      // lexiconSearchParams.bituiParameters = [termParameters]
      // lexiconSearchParams.textSelected = word;
      var url=properties.env.webServiceURL + '/BaseHaSefer//getLexiconEntries?source=bhsWebApp'
      const response = await fetch(url, {method: 'post', body: JSON.stringify(lexiconSearchParams)});   

      // const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer//getLexiconEntries?source=bhsWebApp&textSelected=' + word+'&id='+id)
      var body = await response.json();
      var numOfAppearancesTitle = this.props.translate("numberOfAppearances")
      var numOfWordsTitle = this.props.translate("numberOfWords")
      // if(body.length===0)return null;
      if(this.state.sourceType==="Gematria" || this.state.sourceType==="Atbash"){
        return body.map((val, i)=>{
          var dots=val.id>0?"":`${parseInt(val.roundDown)+val.level-1}...`
          return {id:val.id,depth:node?node.depth+1:0,text:val.level+"_"+val.roundDown,isChecked:false, checkable:val.id>0, count:val.count,title:null, name:<div title="">{dots}{parseInt(val.roundDown)} ({<b>{val.count}</b>})</div>,isExpandable:val.id===0,parentId:id}
        })  
      }
      return body.map((val, i)=>{
        var dots=val.id>0?"":"..."
        return {id:val.id,depth:node?node.depth+1:0,text:val.text,isChecked:node?node.isChecked:false, checkable:val.id>0, count:val.count,title:null, name:<div title="">{val.text}{dots} ({val.id>0?val.count:<b>{val.count}</b>})</div>,isExpandable:!val.isLeafFlag,parentId:id}
      })
    }
    setUpParamsToSend(id,word){
      var lexiconSearchParams = new LexiconSearchParams()
      var pairs = this.state.equivalentsToInclude.map(equ=>{
        return {sourceId:equ.srcId, targetId:equ.tarId, targetText:equ.tarText, secondaryTargetId:equ.secondaryId,synonymPathId:equ.synonymPathId}
      })
      lexiconSearchParams.sourceTargetPairs=pairs//searching tanach with the pairs
      lexiconSearchParams.lastSearchedText = this.state.searchTermFinal;//finding results
      lexiconSearchParams.idSelected = id ? id : 0;//expand terms in results
      var termParameters = new TermParameters()
      termParameters.term = this.state.searchTermFinal
      lexiconSearchParams.bituiParameters = [termParameters]
      lexiconSearchParams.textSelected = word ? word : "";
      lexiconSearchParams.filterIds = this.state.filterIds;
      // lexiconSearchParams.ids = this.state.idsForResults? this.state.idsForResults: this.state.wordsToInclude;//id	text 385324	זעירא
      lexiconSearchParams.entryIds = this.state.idsForResults;
      lexiconSearchParams.equivalentIds = this.state.wordsToInclude;
      lexiconSearchParams.sourceType = this.state.sourceType;
      lexiconSearchParams.targetType = this.state.targetType;
      lexiconSearchParams.synonymPath = this.state.sourceType===this.state.targetType? this.state.synonymPath : "";
      lexiconSearchParams.textSource = this.state.targetType==="Targum"?2:1;
      lexiconSearchParams.sortOrder = this.state.includeFilterFromRightBar?this.props.sortOrder:0;//the sorting from the right bar for searching Tanach
      lexiconSearchParams.exactnessLevel = this.state.includeFilterFromRightBar?this.props.exactnessLevel:0;//fuzzy picker from right bar
      lexiconSearchParams.sortDirection = this.state.includeFilterFromRightBar?this.props.sortDirection:0;//sortDirection from right bar
      lexiconSearchParams.sortedColumn = this.state.includeFilterFromRightBar?this.props.sortedColumn:19;
      var e, i, j, k;
      for (i = 0; i < this.state.textSelectorDataFinal.length-1; i++) { //last position in textSelectorData is never populated
          e = this.state.textSelectorDataFinal[i];
          if (e.type)
              lexiconSearchParams.namedLocations.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
          else
              lexiconSearchParams.pasukRangeLocations.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
      }
      return lexiconSearchParams;

    }
    async populateEquivalents(){
      if(this.state.wordsToInclude.length===0){
        this.setState({equivalentsTree:null,equivalentsToInclude:[]},()=>{
          return null
        })
        return null;
      }
      // this.setState({equivLoading:true})
      // var lexiconSearchParams = new LexiconSearchParams()
      var lexiconSearchParams = this.setUpParamsToSend()
      // lexiconSearchParams.filterIds = this.state.filterIds;
      // lexiconSearchParams.ids = this.state.wordsToInclude;
      // var e, i, j, k;
      // for (i = 0; i < this.state.textSelectorData.length-1; i++) { //last position in textSelectorData is never populated
      //     e = this.state.textSelectorData[i];
      //     if (e.type)
      //         lexiconSearchParams.namedLocations.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
      //     else
      //         lexiconSearchParams.pasukRangeLocations.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
      // }

      var url=properties.env.webServiceURL + '/BaseHaSefer//getLexiconEquivalents?source=bhsWebApp'
      const response = await fetch(url, {method: 'post', body: JSON.stringify(lexiconSearchParams)});   

      // const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer//getLexiconEquivalents?source=bhsWebApp&ids=' + this.state.wordsToInclude)
      var body = await response.json()
      this.setState({unfilteredEquivTreeBody:body})
        var equivTree = this.filterBasedOnAccuracyLevel(body)
        // =this.setUpEquivTree(body);
        // for(let i=0;i<body.length;i++){
        //   if(newResults[body[i].tarId+"_"+body[i].tarText]){
        //     newResults[body[i].tarId+"_"+body[i].tarText].push(body[i])
        //   } else{
        //     newResults[body[i].tarId+"_"+body[i].tarText]=[body[i]]
        //   }
        // }
        // for (const [key, value] of Object.entries(newResults)) {
        //   if(value.length>1){
        //     var sumCount=0;
        //     value.forEach(element => {
        //       sumCount = sumCount + element.count
        //     });
        //     equivalentsArray.push({id:key,
        //                           name:value[0].tarText+" ("+sumCount+")",
        //                           isExpandable:true,
        //                           count:sumCount,
        //                           isExpanded:false,
        //                           checkable:true,
        //                           children:value.map((val,i)=>{return{
        //                             id:val.srcId+"_"+val.tarId,
        //                             minimumRelationStrength:val.minimumRelationStrength,
        //                             tarId:val.tarId,
        //                             srcId:val.srcId,
        //                             tarText:val.tarText,
        //                             name:val.srcText+" ("+val.count+")",
        //                             isExpandable:false,
        //                             checkable:true
        //                           }})})
        //   }else{
        //     equivalentsArray.push({id:key,
        //                           tarId:value[0].tarId,
        //                           minimumRelationStrength:value[0].minimumRelationStrength,
        //                           tarText:value[0].tarText,
        //                           count:value[0].count,
        //                           srcId:value[0].srcId,
        //                           name:value[0].tarText+": "+value[0].srcText+" ("+value[0].count+")",
        //                           isExpandable:false,
        //                           checkable:true})
        //   }
        // }
        // var equivTree = this.sortEquivalents()
        return equivTree.length>0?equivTree:null;
    }
    setUpEquivTree(body){//set relation strength in query for G and A
      var equivalentsArray=[];
      // if(this.state.sourceType==="Gematria" || this.state.sourceType==="Atbash"){
      //   equivalentsArray = body.map(val=>{
      //     return{
      //       id:val.tarId,
      //       tarId:val.tarId,
      //       relationStrength:1,
      //       tarText:val.tarText,
      //       count:val.count,
      //       srcText:val.srcText,
      //       srcId:val.srcId,
      //       nameText:val.tarText+val.srcText,
      //       name:<span><b>{val.tarText}</b>: <span style={{fontSize:'14px'}}>{val.srcText}</span> ({val.count})</span>,
      //       isExpandable:false,
      //       checkable:true}
      //     })
      // }else{
        var hasSynonymPath = this.state.synonymPath != null && this.state.synonymPath.trim() != "";
        var newResults=new Object();
        //noSrc also SxAxS, S-Teiva-S 
        var noSrcText = (
                         (
                          (this.state.sourceType==="Gematria" || this.state.sourceType==="Atbash") &&
                          (this.state.targetType==="Atbash" || this.state.targetType==="Gematria") &&
                          (this.state.synonymPath==="Shoresh")
                         ) ||
                         (
                          (this.state.sourceType==="Teiva" || this.state.sourceType==="Shoresh") &&
                          (this.state.targetType==="Teiva" || this.state.targetType==="Shoresh") &&
                          this.state.synonymPath
                         )
                        );
        if (this.state.synonymPath == "Targum")
            noSrcText = false;

        var completelyNumeric = (this.state.sourceType==="Gematria" || this.state.sourceType==="Atbash") && (this.state.targetType==="Atbash" || this.state.targetType==="Gematria") && (this.state.synonymPath==="Atbash" || this.state.synonymPath==="Gematria")
        var numericOrShoreshToTeiva = (this.state.targetType==="Teiva" && this.state.sourceType==="Gematria") || (this.state.targetType==="Teiva" && this.state.sourceType==="Atbash") || (this.state.targetType==="Teiva" && this.state.sourceType==="Shoresh") || (this.state.sourceType==="Teiva" && this.state.targetType==="Teiva" && (this.state.synonymPath==="Anagram" || this.state.synonymPath==="Shoresh" || this.state.synonymPath==="Gematria" || this.state.synonymPath==="Atbash"))
        if(this.state.targetType==="Gematria" || this.state.targetType==="Atbash"){
          for(let i=0;i<body.length;i++){
            if(newResults[body[i].tarText]){
              newResults[body[i].tarText].push(body[i])
            } else{
              newResults[body[i].tarText]=[body[i]]
            }
          }
        }else if(numericOrShoreshToTeiva){
          for(let i=0;i<body.length;i++){
            if(newResults[body[i].noNekudos]){
              newResults[body[i].noNekudos].push(body[i])
            } else{
              newResults[body[i].noNekudos]=[body[i]]
            }
          }
        }else{
          for(let i=0;i<body.length;i++){
            if(newResults[body[i].tarId+"_"+body[i].tarText]){
              newResults[body[i].tarId+"_"+body[i].tarText].push(body[i])
            } else{
              newResults[body[i].tarId+"_"+body[i].tarText]=[body[i]]
            }
          }
        }
        for (const [key, value] of Object.entries(newResults)) {
          if(value.length>1){
            var sumCount=0;
            var gematriaSortLow=value[0].srcText;
            var middleGematriaSortLow=value[0].text;
            var gematriaSortHigh=value[0].srcText;
            var middleGematriaSortHigh=value[0].text;
            value.forEach(element => {
              sumCount = sumCount + element.count;
              if(element.srcText<gematriaSortLow)gematriaSortLow = element.srcText;
              if(element.srcText>gematriaSortHigh)gematriaSortHigh = element.srcText;
              if(element.srcText<middleGematriaSortLow)middleGematriaSortLow = element.text;
              if(element.srcText>middleGematriaSortHigh)middleGematriaSortHigh = element.text;
            });
            const collator = new Intl.Collator('he',value);  
            value = value.sort((a,b)=>collator.compare(a.srcText+":"+a.text,b.srcText+":"+b.text)); //this sorts the sub-listings in the equivalents list by א'ב.
  
            equivalentsArray.push({id:key,
                                  //COLOR FIX HERE
                                  name:numericOrShoreshToTeiva?
                                      <div title={value[0].noNekudos.length>40?`${value[0].noNekudos}: ${value[0].srcText}  (${sumCount})`:""}>
                                          <b>{value[0].noNekudos}</b>: <span style={{fontSize:'14px', color:hasSynonymPath ? 'red' : 'blue'}}>{value[0].srcText}</span> ({sumCount})
                                      </div>:
                                      <div title={value[0].tarText.length>40?`${value[0].tarText} (${sumCount})`:""}>
                                          <b>{value[0].tarText}</b> ({sumCount})
                                      </div>,
                                  nameText:value[0].tarText,
                                  tarText:value[0].tarText,
                                  isExpandable:true,
                                  count:sumCount,
                                  gematriaSortHigh:gematriaSortHigh,
                                  gematriaSortLow:gematriaSortLow,
                                  middleGematriaSortHigh:middleGematriaSortHigh,
                                  middleGematriaSortLow:middleGematriaSortLow,
                                  isExpanded:false,
                                  checkable:true,
                                  children:value.map((val,i)=>{return{
                                    id:val.srcId+"_"+val.tarId+"_"+val.text+"_"+val.secondaryId+"_"+val.synonymPathId,
                                    relationStrength:val.relationStrength,
                                    secondaryId:val.secondaryId,
                                    synonymPathId:val.synonymPathId,
                                    tarId:val.tarId,
                                    srcId:val.srcId,
                                    tarText:val.tarText,
                                    srcText:val.srcText,
                                    nameText:val.srcText,
                                    name:numericOrShoreshToTeiva?
                                        <div title={val.tarText.length>40?`${val.tarText} (${val.count})`:""}>
                                            <b>{val.tarText}</b> ({val.count})
                                        </div>:
                                        <div title={val.srcText.length>40?`${val.srcText} ${val.text?": "+val.text:""}${val.word?" : "+val.word:""}(${val.count})`:""}>
                                            <span style={{fontSize:'14px'}}>
                                                {<span style={{color:noSrcText?'red':'blue'}}>{val.srcText}</span>}
                                                {val.text?this.state.synonymPath && !noSrcText?
                                                    <><span>: </span><span style={{color:'red'}}>{val.text}</span></> :
                                                    ": "+val.text:""
                                                }
                                                {val.word?": "+val.word:""}
                                            </span> ({val.count})
                                        </div>,
                                    isExpandable:false,
                                    checkable:true
                                  }})})
          }else{
            equivalentsArray.push({id:key,
                                  tarId:value[0].tarId,
                                  relationStrength:value[0].relationStrength,
                                  tarText:value[0].tarText,
                                  count:value[0].count,
                                  srcText:value[0].srcText,
                                  secondaryId:value[0].secondaryId,
                                  synonymPathId:value[0].synonymPathId,
                                  middleGematriaSortHigh:value[0].text,
                                  middleGematriaSortLow:value[0].text,
                                  gematriaSortLow:value[0].srcText,
                                  gematriaSortHigh:value[0].srcText,
                                  srcId:value[0].srcId,
                                  nameText:value[0].tarText+value[0].srcText,
                                  name:
                                  <div title={value[0].tarText.length>40?`${value[0].tarText}: ${value[0].srcText} ${value[0].text?": "+value[0].text:""}${value[0].word?" : "+value[0].word:""}(${value[0].count})`:""}>
                                      <b>{value[0].tarText}</b>: <span style={{fontSize:'14px'}}> {<span style={{color:noSrcText?'red':'blue'}}>{value[0].srcText}</span>}
                                            {value[0].text?this.state.synonymPath && !noSrcText?
                                                <><span>: </span><span style={{color:'red'}}>{value[0].text}</span></> :
                                                ": "+value[0].text:""
                                            }
                                          {value[0].word?": "+value[0].word:""}
                                      </span> ({value[0].count})
                                  </div>,
                                  isExpandable:false,
                                  checkable:true})
          }
        // }
      }
      equivalentsArray.map((elem,j)=>{
        if(this.state.equivalentsTree){
          var thisElem = this.state.equivalentsTree[this.state.equivalentsTree.findIndex(el=>el.id===elem.id)];
            if(this.state.equivalentsTree && thisElem && thisElem.isChecked){
              elem.isChecked = true;
            }
            if(elem.children){
              elem.children.map((elemCh,i)=>{
                if(this.state.equivalentsToInclude.findIndex(elCh=>elCh.id===elemCh.id)>=0){//this.state.equivalentsTree && thisElem && thisElem.children && thisElem.children[thisElem.children.findIndex(elCh=>elCh.id===elemCh.id)].isChecked){
                  elemCh.isChecked = true;
                }
              },this,j,elem)
            }
        }
      },this)
      return equivalentsArray
    }
    async expandToggleCb(node,depth,isOpened,readyForEquivalents){
      this.setState({loadingMore:true}, async ()=>{
      var resultsTree = this.state.resultsTree
      
      if(node && node.isExpandable){ //if expandable, get the children. Otherwise go straight to getting equivalents
        var updatedData = -1;
        var updatedData2, updatedData3,updatedData4;
        await this.fetchResults(node.text,node.id,node).then(async res=>{
          if(node.depth===0){
            updatedData = resultsTree.findIndex(n=>(n.text===node.text && n.id===node.id));
            resultsTree[updatedData].isExpanded = true;
            // var children = await this.fetchResults(node.text,node.id,node);
            resultsTree[updatedData].children = res//children
            resultsTree[updatedData].isExpanded = isOpened
            resultsTree[updatedData].isChecked = node.isChecked
            resultsTree[updatedData].isChildrenLoading = false;
            // if(!readyForEquivalents)return resultsTree[updatedData];
          }else if(node.depth===1){
            updatedData = (this.state.sourceType==="Gematria" || this.state.sourceType==="Atbash")? resultsTree.findIndex(n=>(node.text.split("_")[1]>=n.text.split("_")[1]&&node.text.split("_")[1]<parseInt(n.text.split("_")[1])+parseInt(n.text.split("_")[0])))
                                                              :resultsTree.findIndex(n=>(n.text===node.text.substring(0,n.text.length) && n.id===node.parentId));
            updatedData2 = resultsTree[updatedData].children.findIndex(n=>(n.text===node.text && n.id===node.id));
            resultsTree[updatedData].children[updatedData2].isExpanded = true;
            //   var children = await this.fetchResults(node.text,node.id,node);
              resultsTree[updatedData].children[updatedData2].children = res//children
              resultsTree[updatedData].children[updatedData2].isExpanded = isOpened
              resultsTree[updatedData].children[updatedData2].isChecked = node.isChecked
            resultsTree[updatedData].children[updatedData2].isChildrenLoading = false;
            // if(!readyForEquivalents)return resultsTree[updatedData].children[updatedData2]
          }else if(node.depth===2){
            updatedData = resultsTree.findIndex(n=>(n.text===node.text.substring(0,n.text.length) && n.id===node.parentId));
            updatedData2 = resultsTree[updatedData].children.findIndex(n=>(n.text===node.text.substring(0,n.text.length) && n.id===node.parentId));
            updatedData3 = resultsTree[updatedData].children[updatedData2].children.findIndex(n=>(n.text===node.text && n.id===node.id));
            resultsTree[updatedData].children[updatedData2].children[updatedData3].isExpanded = true;
            // var children = await this.fetchResults(node.text,node.id,node);
            resultsTree[updatedData].children[updatedData2].children[updatedData3].children = res//children
            resultsTree[updatedData].children[updatedData2].children[updatedData3].isExpanded = isOpened
            resultsTree[updatedData].children[updatedData2].children[updatedData3].isChecked = node.isChecked
            resultsTree[updatedData].children[updatedData2].children[updatedData3].isChildrenLoading = false;
            // if(!readyForEquivalents)return resultsTree[updatedData].children[updatedData2].children[updatedData3]
          }else{
            updatedData = resultsTree.findIndex(n=>(n.text===node.text.substring(0,n.text.length) && n.id===node.parentId));
            updatedData2 = resultsTree[updatedData].children.findIndex(n=>(n.text===node.text.substring(0,n.text.length) && n.id===node.parentId));
            updatedData3 = resultsTree[updatedData].children[updatedData2].children.findIndex(n=>(n.text===node.text.substring(0,n.text.length) && n.id===node.parentId));
            updatedData4 = resultsTree[updatedData].children[updatedData2].children[updatedData3].children.findIndex(n=>(n.text===node.text && n.id===node.id));
            resultsTree[updatedData].children[updatedData2].children[updatedData3].children[updatedData4].isExpanded = true;
            // var children = await this.fetchResults(node.text,node.id,node);      
            resultsTree[updatedData].children[updatedData2].children[updatedData3].children[updatedData4].children = res//children
            resultsTree[updatedData].children[updatedData2].children[updatedData3].children[updatedData4].isExpanded = isOpened
            resultsTree[updatedData].children[updatedData2].children[updatedData3].children[updatedData4].isChecked = node.isChecked
            resultsTree[updatedData].children[updatedData2].children[updatedData3].children[updatedData4].isChildrenLoading = false;
            // if(!readyForEquivalents)return resultsTree[updatedData].children[updatedData2].children[updatedData3].children[updatedData4]
          }
          // if(readyForEquivalents){
           return await this.checkForEquivalents(resultsTree).then(res=>{
              this.setState({resultsTree:[]},()=>{this.setState({resultsTree,loadingMore:false})})      
            })
          // }
        })

      }
      // if(readyForEquivalents){
        await this.checkForEquivalents(resultsTree).then(res=>{
                  this.setState({resultsTree:[]},()=>{this.setState({resultsTree,loadingMore:false})})      

        })

                        // var wordsToInclude = this.setWordsToInclude(resultsTree)
                        // var oldWordsToInclude = this.setWordsToInclude(this.state.resultsTree)
                        // if(wordsToInclude.sort((a, b) => a - b).toString() !== this.state.wordsToInclude.sort((a, b) => a - b).toString()){
                        //   this.setState({wordsToInclude,isLoading:false}, async()=>{//wordsToInclude.sort((a, b) => a - b).toString() !== oldWordsToInclude.sort((a, b) => a - b).toString()){
                        //     this.setState({equivalentsTree:await this.populateEquivalents()},()=>{
                        //       this.setState({equivLoading:false})
                        //     })
                        //   })
                        // }
      // }
                            // console.log("setState resTree: in expandToggleCb ")
                            // console.log(resultsTree)
      // else this.setState({resultsTree:[]},()=>{this.setState({resultsTree,loadingMore:false})})      
      })

      // }
    }

    async checkForEquivalents(tree){
      var wordsToInclude = this.setWordsToInclude(tree)
      var oldWordsToInclude = this.setWordsToInclude(this.state.resultsTree)
      if(wordsToInclude.sort((a, b) => a - b).toString() !== this.state.wordsToInclude.sort((a, b) => a - b).toString()){
        this.setState({wordsToInclude,isLoading:false,equivLoading:true}, async()=>{//wordsToInclude.sort((a, b) => a - b).toString() !== oldWordsToInclude.sort((a, b) => a - b).toString()){
          await this.populateEquivalents().then(res=>{
            this.setState({equivalentsTree:res},()=>{
            this.setState({equivLoading:false})
          })})
        })
      }
    }
    async expandEquivalents(){
      if(!this.state.equivalentsTree || this.state.equivalentsTree.length===0) return null;
      var newTree = await this.cycleThroughTree(this.state.equivalentsTree,"expandAll")
      this.setState({equivalentsTree:null},()=>{
        this.resetEquivalentsTree(newTree)
      })
    }
    async collapseEquivalents(){
      if(!this.state.equivalentsTree || this.state.equivalentsTree.length===0) return null;
      var newTree = await this.cycleThroughTree(this.state.equivalentsTree,"closeAll")
      this.setState({equivalentsTree:null},()=>{
        this.resetEquivalentsTree(newTree)
      })
    }
    async selectAllEquivalents(){
      if(!this.state.equivalentsTree || this.state.equivalentsTree.length===0) return null;
      var newTree = await this.cycleThroughTree(this.state.equivalentsTree,"selectAll")
      this.setState({equivalentsTree:null},()=>{
        this.resetEquivalentsTree(newTree)
      })
    }
    async unselectAllEquivalents(){
      if(!this.state.equivalentsTree || this.state.equivalentsTree.length===0) return null;
      var newTree = await this.cycleThroughTree(this.state.equivalentsTree,"unselectAll")
      this.setState({equivalentsTree:null},()=>{
        this.resetEquivalentsTree(newTree)
      })
    }
    async collapseResults(){
      if(!this.state.resultsTree || this.state.resultsTree.length===0) return null;
      var newTree = await this.cycleThroughTree(this.state.resultsTree,"closeAll")
      this.setState({resultsTree:null},()=>{
        this.setState({resultsTree:newTree})
      })
    }
    async unselectAllResults(){
      if(!this.state.resultsTree || this.state.resultsTree.length===0) return null;
      var newTree = await this.cycleThroughTree(this.state.resultsTree,"unselectAll")
      this.setState({resultsTree:null,equivalentsTree:null},()=>{
        this.resetResultsTree(newTree);
      })
    }
    async swapResultsWithEquivalents(){
      var sourceType = this.state.sourceType;
      this.setState({sourceType:this.state.targetType, targetType:sourceType},async()=>{
        if(!this.state.equivalentsTree){
          await this.handleSearchTerm(null,false,true);
        }else{
          var idsForResults;
          var equivToInclude = this.state.equivalentsToInclude
          if(equivToInclude.length===0){
            idsForResults = this.setWordsToInclude(this.state.equivalentsTree,null,true,true)
          }else{
            idsForResults = this.setWordsToInclude(equivToInclude,null,true)
          }
          this.setState({idsForResults},async()=>{
            await this.handleSearchTerm(null,equivToInclude.length>0,true);
            this.setState({idsForResults:null})
            // if(this.state.resultsTree.length>1 && equivToInclude.length>0){
            //   var resArray = this.state.resultsTree;
            //   resArray.map(res=>{
            //     return res.isChecked=true
            //   })
            //   this.expandToggleCb(resArray[0],0,false)
            // }
          })
          

          // newResTree = newResTree.map(obj=>{
          //     return {
          //       id:obj.tarId,
          //       name:obj.tarText,
          //       checkable:true,
          //       isChecked:obj.isChecked,
          //     }
          //   })
          // this.setState({resultsTree:newResTree, equivalentsTree:null,equivalentsToInclude:[]},()=>{
          //   this.expandToggleCb(newResTree[0],0,false)
          // })
        }
      })
    }

    async checkAllResults(tree){
      return tree.map(async (obj)=>{
        if(obj.isExpanded){
          if(obj.isCheckable)obj.isChecked=true;
          await this.checkAllResults(obj.children)
        } 
        else if (obj.checkable){
          obj.isChecked = true;
          await this.expandToggleCb(obj, 0, obj.isExpanded,false).then(res=>{return res})
        }else{
          return obj;
        }
      })
    }

    async cycleThroughTree(tree, action,selectAllResults,depth){
      var depth=selectAllResults && depth?depth:0;
      tree.forEach(async(data)=>{
          if(action==="selectAll"){
            if(selectAllResults){
              if(data.isExpanded){
                await this.cycleThroughTree(data.children, action,selectAllResults,depth);
              }else if(data.checkable){
                data.isChecked=true
                await this.expandToggleCb(data,depth,data.isExpanded,true).then(res=>{
                  // return res

                })
              }
            }else if(data.checkable){
              data.isChecked=true  
            }
          }else if(action==="unselectAll"){
              data.isChecked=false
          }else if(action==="expandAll"){
              if(data.isExpandable){
                  data.isExpanded=true
              }
          }else if(action==="closeAll"){
            data.isExpanded=false
          }
        if(!selectAllResults && data.children){
          await this.cycleThroughTree(data.children, action,selectAllResults,depth);
        } 
      })
      return tree;
    }

    toggleIncludeFilter(){
      this.setState({includeFilterFromRightBar:!this.state.includeFilterFromRightBar})
      if(!this.state.includeFilterFromRightBar) this.setState({filterIds:"FFFFFFFFFFF"})
    }
    setSortBy(e){
      var sortOrder;
      if(e.target.value==="alphabetically"|| e.target.value==="byGematria" || e.target.value==="byMiddleGematria")
        sortOrder = "asc"
      else if(e.target.value==="byOccurrences")
        sortOrder = "desc"
      this.setState({sortBy:e.target.value,sortOrder},()=>{
        var equivTree = this.sortEquivalents()
        this.setState({equivalentsTree:null},()=>{this.setState({equivalentsTree:equivTree})})
      })
    }
    sortEquivalents(tree){
      var equivTree = tree?tree:this.state.equivalentsTree;
      if(!equivTree) return null;
      if(this.state.sortBy ==="alphabetically"){
        if(this.state.targetType==="Gematria" || this.state.targetType==="Atbash"){
          equivTree = equivTree.sort((a,b)=>(this.state.sortOrder==="asc"?a.tarText-b.tarText:b.tarText-a.tarText))
        }else{
          const collator = new Intl.Collator('he',equivTree);  
          equivTree = equivTree.sort((a,b)=>collator.compare(a.nameText,b.nameText)); 
        }
        if(this.state.sortOrder==='desc')equivTree = equivTree.reverse(); 
      }else if(this.state.sortBy ==="byOccurrences"){
        equivTree = equivTree.sort((a,b)=>(this.state.sortOrder==="asc"?a.count-b.count:b.count-a.count))
      }else if(this.state.sortBy ==="byGematria" || (this.state.sortBy ==="byMiddleGematria" && this.state.sourceType!=="Gematria" && this.state.sourceType!=="Atbash")){
        equivTree = equivTree.sort((a,b)=>(this.state.sortOrder==="asc"? a.gematriaSortLow-b.gematriaSortLow: b.gematriaSortHigh-a.gematriaSortHigh))
      }else if(this.state.sortBy ==="byMiddleGematria"){
        equivTree = equivTree.sort((a,b)=>(this.state.sortOrder==="asc"? a.middleGematriaSortLow-b.middleGematriaSortLow: b.middleGematriaSortHigh-a.middleGematriaSortHigh))
      }
      return equivTree
    }
    flipSort(){
      if(!this.state.equivalentsTree)return null
      var order = "";
      var equivTree;
      if(this.state.sortOrder==="asc"){
        order = "desc"
      }else if(this.state.sortOrder==="desc"){
        order = "asc"
      }
      this.setState({sortOrder:order},()=>{
        if(this.state.sortBy ==="byGematria" || this.state.sortBy==="byMiddleGematria")equivTree = this.sortEquivalents(this.state.equivalentsTree)
        else equivTree = this.state.equivalentsTree.reverse()
        this.setState({equivalentsTree:null},()=>this.setState({equivalentsTree:equivTree}))
      })
    }
    setAccuracyLevel(e){
      this.setState({accuracyLevel:e.target.value},()=>{
        if(this.state.equivalentsTree)this.filterBasedOnAccuracyLevel()
      })
    }
    filterBasedOnAccuracyLevel(tree){//make sure that this doesn't mess up the order
      var unsortedEquivTree = tree ? tree : this.state.unfilteredEquivTreeBody;
      var equivalentsToInclude = this.state.equivalentsToInclude
      unsortedEquivTree = unsortedEquivTree.filter((elem,i)=>{
        if(elem.relationStrength<this.state.accuracyLevel){
          var equToTakeOut = equivalentsToInclude.findIndex(x=>(x.id===elem.srcId+"_"+elem.tarId || x.id===elem.tarId+"_"+elem.tarText ))
          if(equToTakeOut>=0){//includes(elem)){
            equivalentsToInclude.splice(equToTakeOut,1)
          }
          this.setState({equivalentsToInclude})
        }
        return elem.relationStrength>=this.state.accuracyLevel
      },this)
      unsortedEquivTree = unsortedEquivTree.map(obj=>{
        return{
          tarId:obj.tarId,
          tarText:obj.tarText,
          srcText:obj.srcText,
          srcId:obj.srcId,
          count:obj.count,
          text:obj.text,
          relationStrength:obj.relationStrength,
          noNekudos:util.removeNikud(obj.tarText),
          secondaryId:obj.secondaryId,
          word:obj.word,
          synonymPathId:obj.pathId
        }
      })
      var equivTree = this.setUpEquivTree(unsortedEquivTree)
      equivTree = this.sortEquivalents(equivTree)
      this.setState({equivalentsTree:null},()=>this.setState({equivalentsTree:equivTree}))
      return equivTree;
    }
    async checkAllOpenResults(){
      if(this.state.isLoading || this.state.loadingMore || this.state.equivLoading) return null
      var resultsTree = this.state.resultsTree
      // var resTree = await this.cycleThroughTree(this.state.resultsTree,"selectAll",true)
      this.setState({isLoading:true},async()=>{
        // await this.checkAllResults(resultsTree).then(res=>{
        await this.cycleThroughTree(resultsTree,"selectAll",true).then(res=>{
          this.setState({resultsTree:null},async()=>{
            this.setState({resultsTree:res,isLoading:false},async()=>{
              // await this.expandToggleCb(null,0,false,true)
              // this.setState({readyForEquivalents:true})
              await this.checkForEquivalents(res)
            })
          })  }        
        )

      })

    }
    

    render(){
        const isHebrew = this.props.activeLanguage && this.props.activeLanguage.code === 'he';
        const close = this.props.translate('close');
        const copyLink = this.props.translate('copyLink');
        const fullScreen = this.props.translate("fullScreen");
        const splitScreen = this.props.translate("splitScreen");
        var directionStyling = { direction: 'ltr', textAlign: 'left', justifyContent: 'left' }
        if (this.props.activeLanguage && this.props.activeLanguage.code === "he") {
          directionStyling = { direction: 'rtl', textAlign: 'right', justifyContent: 'right' }
        }
        const { anchorEl, open } = this.state;
        const {scope, terms, seq} = this.props;
        const id = open ? 'simple-popper' : null;
        const searchTanach = this.props.translate("searchTanach")
        const dialogContentHeight = this.props.heightNum - 110;
        const { classes } = this.props;
        const loadSearchResults = this.props.translate("loadSearchResults.caption")
        const termsDesc = this.state.sourceType==="Gematria" || this.state.sourceType==="Atbash"?this.props.translate("lexiconTerms1")+this.props.translate("lexiconTerms3"):this.props.translate("lexiconTerms1")+this.props.translate("lexiconTerms2")+this.props.translate("lexiconTerms3");
        const clear = this.props.translate("clear.caption");
        const hebrewKeyboard = this.props.translate("hebrewKeyboard");
        const search = this.props.translate("search.caption");
        const loadingText = this.props.translate("loading");
        const alphabetically = this.state.targetType==="Gematria"? this.props.translate("byGematria"): this.state.targetType==="Atbash"?this.props.translate("byAtbash"):this.props.translate("alphabetically")
        const byGematria = this.state.sourceType==="Gematria" ? this.props.translate("bySourceGematria"): this.state.sourceType==="Atbash" ? this.props.translate("bySourceAtbash"):"";
        const byMiddleGematria = this.state.synonymPath==="Gematria" ? this.props.translate("byCommonGematria") : this.state.synonymPath==="Atbash" ? this.props.translate("byCommonAtbash"):""
        const fakeThis=this;
        const includeFilterOffTip = this.props.translate("includeFilterOff")//"Exclude Filters from Right Bar";
        const includeFilterOnTip = this.props.translate("includeFilterOn")//"Include Filters from Right Bar";
        const showExactnessDropdown = (this.state.targetType==="Targum" || this.state.sourceType==="Targum" || this.state.synonymPath==="Targum");
        const leaves = this.state.resultsTree?this.setWordsToInclude(this.state.resultsTree,null,false,true):101;
        const lessThan100Leaves = leaves.length<=100
        const synonymsAvailable = this.state.sourceType === this.state.targetType;
        const TeivaSynonymAvailable=(synonymsAvailable && this.state.sourceType === "Shoresh" || this.state.sourceType === "Targum");
        const ShoreshSynonymAvailable=(synonymsAvailable && (this.state.sourceType !== "Shoresh"));
        const GematriaSynonymAvailable=(synonymsAvailable && (this.state.sourceType != "Gematria" && this.state.sourceType != "Targum"));
        const AtbashSynonymAvailable=(synonymsAvailable && (this.state.sourceType != "Atbash" && this.state.sourceType != "Targum"));
        const TargumSynonymAvailable=(synonymsAvailable && (this.state.sourceType === "Teiva" || this.state.sourceType === "Shoresh"));
        const AnagramSynonymAvailable=(synonymsAvailable && (this.state.sourceType === "Teiva"));
        const atbash = this.props.translate("atbash");
        const teiva = this.props.translate("teiva");
        const root = this.props.translate("lexiconRoot");
        const targum = this.props.translate("targum");
        const anagram = this.props.translate("anagram");
        const gematria = this.props.translate("gematria");
        const results = this.state.sourceType==="Teiva"?this.props.translate("teivaSource"):
                        this.state.sourceType==="Shoresh"?this.props.translate("shoreshSource"):
                        this.state.sourceType==="Gematria"?this.props.translate("gematriaSource"):
                        this.state.sourceType==="Atbash"?this.props.translate("atbashSource"):
                        this.props.translate("targumSource");
        const resultsInstructions = this.props.translate("resultsInstructions");
        const equivalentsInstructions = this.props.translate("equivalentsInstructions");
        const showKey = this.props.translate('showLexiconKey');
        const hideKey = this.props.translate('hideLexiconKey');   
        const hide = this.props.translate("hide");

        const voiceInputOn = this.props.translate("voiceInputOn");
        const voiceInputOff = this.props.translate("voiceInputOff");

            return <Rnd
                helpTopic="lexicon"
                size={{ width: `${this.props.widthNum}px`,  height: `${this.props.heightNum}px` }}
                position={{ x: this.props.x, y: this.props.y }}
                onDragStop={(e, d) => { 
                this.props.setPosition(d.x, d.y)
                // this.setState({ x: d.x, y: d.y }) 
                }}
                onClick={(e) => {this.showOnTop()}}
                onResize={(e, direction, ref, delta, position) => {
                this.props.setSize(
                    parseInt(ref.style.width.slice(0, -1)),
                    parseInt(ref.style.height.slice(0, -1)),
                    position.x,
                    position.y
                )
                }}
                style = {{
                backgroundColor: 'rgb(255, 238, 223)',
                visibility: this.props.isOpen ? 'visible' : 'hidden',
                boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
                borderRadius: '5px',
                zIndex: this.state.zIndex
                }}
                cancel = '.tanach-viewer-content, .tanach-viewer-text-display-controls, .placement-selector-2D-selector, .placement-selector-2D-popover, .tanach-viewer-resize-buttons'
                minWidth={synonymsAvailable?1075:1044}
                minHeight={153}
                bounds='#root'
                className="tanach-viewer-rnd"
            >
                          <div className="tanach-viewer-resize-buttons"
            style={{
              position: 'absolute',
              right: isHebrew ? 'unset' : 0,
              left: isHebrew ? 0 : 'unset',
              direction: isHebrew ? 'ltr' : 'rtl',
              top: 0,
              borderBottomLeftRadius: isHebrew ? 0 : '3px',
              borderBottomRightRadius: isHebrew ? '3px' : 0,
            }}
          >
            <Button className="tanach-viewer-resize-button" onClick={() => {this.handleClose()}} title={close} helpTopic="layout">
              <CloseIcon />
            </Button>
            <Button className="tanach-viewer-resize-button" onClick={() => {this.handleCopyLink()}} title={copyLink}>
              <LinkIcon />
            </Button>
            <Button className="tanach-viewer-resize-button" onClick={() => {this.handleSplitScreen()}}  title={splitScreen}>
              <HorizontalSplitIcon />
            </Button>
            <Button className="tanach-viewer-resize-button" onClick={() => {this.handleFullscreen()}} title={fullScreen}>
              <FullscreenIcon />
            </Button>
          </div>

        <DialogTitle style={{
          textAlign: "center",
          maxWidth: 'calc(100% - 30px)',
          margin: 'auto',
          padding: '10px 0 3px',
        }}>
            <div style={{fontSize:'26px', fontWeight:"bold",paddingBottom:'5px'}}><Translate id="lexicon"/></div>
            <YuchsinSectionSelector
                textSelectorData={this.state.textSelectorData}
                textSelectorGridReady={this.textSelectorGridReady}
                addTextSelectorRow={this.addTextSelectorRow}
                deleteTextSelectorRow={this.deleteTextSelectorRow}
                modifyTextSelectorRow={this.modifyTextSelectorRow}
                getRenderVals={this.getTextSelectorRenderVals}
            /> &nbsp;<ToggleIconButton isPressed={this.state.includeFilterFromRightBar} onImage={includeFilterOn} offImage={includeFilterOff} offTip={includeFilterOnTip} onTip={includeFilterOffTip}
            height={30} width={28} toggleAction={this.toggleIncludeFilter} disabled={false} />
              </DialogTitle>
                <DialogContent className="tanach-viewer-content" style={{ maxHeight: `${dialogContentHeight}px`,
                  minHeight: `${dialogContentHeight}px`,
                  textAlign: "center",
                  maxWidth: 'calc(100% - 23px)',
                  margin: 'auto',
                  padding: '10px 0 0',
                }}>
                    <table style={{maxWidth:'500px', margin:'0 auto',height:`${dialogContentHeight-20}px`}} ><tbody style={{textAlign:'center'}}>
                <tr className="searchLine">
                     <td></td><td colSpan='3'>
                         <Paper name="box1" id="box1" style={{}}  className={classes.root} elevation={3} >
                          <IconButton onClick={this.handleSearchTerm}type="Search" className={classes.iconButton} aria-label="Search" title={search} helpTopic="searchResults"><SearchIcon /></IconButton>
                          <InputBase name="lexiconTerms" id="lexiconTerms" style={{fontSize:this.state.searchTerm && this.state.searchTerm.length>0?'28px':'16px'}} placeholder={termsDesc + results} className={classes.input} value={this.state.searchTerm || ''} onChange={this.handleChange} />
                          <IconButton className={classes.iconButton} aria-label="Clear" title={clear} onClick={this.handleClear}><DeleteIcon /></IconButton>
                          <IconButton className={classes.iconButton} aria-label="Keyboard" title={hebrewKeyboard} onClick={this.handleClick}><KeyboardIcon /></IconButton>
                          <Dictaphone className={classes.iconButton} voiceInputOn={voiceInputOn} voiceInputOff={voiceInputOff} size="small" textId="lexiconTerms" bufferCallback={this.extVoiceInputBuffer} changeCallback={this.extVoiceInputChange} />
                          </Paper>
        <Popper id={id} open={open} anchorEl={anchorEl}style={{zIndex:this.state.zIndex+1}} transition placement="bottom-end">
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <Typography className={classes.typography}>
                    <HebrewKeyboard changeCallback={this.extKBChange} backspaceCallback={this.extKBBackspace} deleteCallback={this.extKBDelete} togglePopupCallback={this.extTogglePopupCallback}/>
                </Typography>
              </Paper>
            </Fade>
          )}
        </Popper>
                     </td><td></td>
                 </tr>

                    <tr style={{}}>
                        <td style={{verticalAlign:'top',padding:'0 7px 0 0',minWidth:'122px'}}>    
                        {showExactnessDropdown&&<Select className='lexiconSort'
                                  style={{direction:isHebrew?'rtl':'ltr',marginTop:'14px',maxWidth:'110px',float:'right'}}
                                  value={this.state.accuracyLevel}
                                  onChange={(e) => {this.setAccuracyLevel(e)}}
                                  variant='standard'
                                  autoWidth={true}
                              >
                                  <MenuItem className='lexiconSortOption' value='-10' >{this.props.translate("noAccuracy")}</MenuItem>
                                  <MenuItem className='lexiconSortOption' value='.001' >{this.props.translate("lowAccuracy")}</MenuItem>                              
                                  <MenuItem className='lexiconSortOption' value='.25' >{this.props.translate("medAccuracy")}</MenuItem>
                                  <MenuItem className='lexiconSortOption' value='.5' >{this.props.translate("highAccuracy")}</MenuItem>
                              </Select>
                        }
                            <ToggleButtonGroup
                            orientation="vertical"
                            value={this.state.targetType}
                            exclusive
                            style={{marginTop:showExactnessDropdown?'3px':'45px',float:'right'}}
                            className="lexiconToggleGroup toggleButtonGroup"
                            onChange={this.handleEquivalentsChange}
                            >
                        {this.state.lexiconKeyOpen && <div className={"lexiconKeyVerticalPadding"} />}
                            <ToggleButton className={this.state.targetType==="Teiva"?"selectedToggleButton":""}style={isHebrew?{}:{fontSize:'11px'}} id="wordEqu" value="Teiva" >{teiva}</ToggleButton>
                            <ToggleButton className={this.state.targetType==="Shoresh"?"selectedToggleButton":""}style={isHebrew?{}:{fontSize:'11px'}}  id="rootEqu" value="Shoresh" >{root}</ToggleButton>
                            <ToggleButton className={this.state.targetType==="Gematria"?"selectedToggleButton":""}style={isHebrew?{}:{fontSize:'11px'}}  id="gematriaEqu" value="Gematria" disabled={this.state.sourceType==="Targum"}>{gematria}</ToggleButton>
                            <ToggleButton className={this.state.targetType==="Atbash"?"selectedToggleButton":""}style={isHebrew?{}:{fontSize:'11px'}}  id="atbashEqu" value="Atbash" disabled={this.state.sourceType==="Targum"}>{atbash}</ToggleButton>
                            <ToggleButton className={this.state.targetType==="Targum"?"selectedToggleButton":""}style={isHebrew?{}:{fontSize:'11px'}}  id="targumEqu" value="Targum" disabled={this.state.sourceType==="Gematria" || this.state.sourceType==="Atbash"}>{targum}</ToggleButton>
                            </ToggleButtonGroup>
                        </td>
                    <td  style={{verticalAlign:'top',padding:'0 9px'}}><div className="lexiconTreeLabel">
                    <Button
                        variant="contained"
                        title= {this.props.translate("reverseOrder")}
                        style={{margin: '4px 4px 4px 0',minWidth:'20px',maxWidth:'20px',padding:'0',outlineWidth:0,float:'left'}}
                        onClick={() => {this.flipSort()}}
                    >
                      <SwapVertIcon/>
                    </Button>
                    <Select className={'lexiconSort'} title={this.props.translate("lexiconSort")}
                                  style={{direction:isHebrew?'rtl':'ltr',float:'left',zIndex:this.state.zIndex+1}}
                                  value={this.state.sortBy}
                                  onChange={(e) => {this.setSortBy(e)}}
                                  variant='standard'
                                  autoWidth={true}
                              >
                                  <MenuItem className='lexiconSortOption'style={{zIndex:this.state.zIndex+1}} value='byOccurrences' >{this.props.translate("byOccurrences")}</MenuItem>
                                  {(this.state.sourceType==="Gematria" || this.state.sourceType==="Atbash") && <MenuItem style={{zIndex:this.state.zIndex+1}} className='lexiconSortOption'  value='byGematria' >{byGematria}</MenuItem>}
                                  {(this.state.sourceType===this.state.targetType && (this.state.synonymPath==="Gematria" || this.state.synonymPath==="Atbash")) && <MenuItem style={{zIndex:this.state.zIndex+1}} className='lexiconSortOption'  value='byMiddleGematria' >{byMiddleGematria}</MenuItem>}
                                  <MenuItem style={{zIndex:this.state.zIndex+1}} className='lexiconSortOption' value='alphabetically' >{alphabetically}</MenuItem>
                              </Select>
                      <span className="labelLexicon">{this.props.translate("equivalents")}</span>
                    <Button
                        variant="contained"
                        title={this.props.translate("checkAll")}
                        style={{margin: '4px 0 4px 4px',minWidth:'20px',maxWidth:'20px',maxHeight:'24px',minHeight:'24px',padding:'0',outlineWidth:0,float:'right'}}
                        onClick={() => {this.selectAllEquivalents()}}
                    >
                      {/* <CheckBoxOutlinedIcon/> */}
                      <img src={require("./images/check.png")} alt="Clear all settings" width="16" height="16" />
                    </Button>

                    <Button
                        variant="contained"
                        title= {this.props.translate("uncheckAll")}
                        style={{margin: '4px 4px',minWidth:'20px',maxWidth:'20px',maxHeight:'24px',minHeight:'24px',padding:'0',outlineWidth:0,float:'right'}}
                        onClick={() => {this.unselectAllEquivalents()}}
                    >
                      {/* &#9746; */}
                      {/* <IndeterminateCheckBoxOutlinedIcon/> */}
                      <img src={require("./images/x.png")} alt="Clear all settings" width="16" height="16" />
                    </Button>
                    <Button
                        variant="contained"
                        title= {this.props.translate("collapseAll")}
                        style={{margin: '4px 4px',minWidth:'20px',maxWidth:'20px',padding:'0',outlineWidth:0,float:'right'}}
                        onClick={() => {this.collapseEquivalents()}}
                    >
                      <ExpandLessIcon/>
                    </Button>
                    <Button
                        variant="contained"
                        title= {this.props.translate("expandAll")}
                        style={{margin: '4px 4px',minWidth:'20px',maxWidth:'20px',padding:'0',outlineWidth:0,float:'right'}}
                        onClick={() => {this.expandEquivalents()}}
                    >
                      <ExpandMoreIcon/>
                    </Button>
                    {this.state.lexiconKeyOpen && <div id="lexiconEquivalentsKey" className={isHebrew ? "lexiconKeyRTL" : "lexiconKeyLTR"}><ArrowDropUpIcon className="clickable" style={{float:isHebrew?'left':'right'}} onClick={()=>this.setState({lexiconKeyOpen:false})}/><span style={{float:isHebrew?'left':'right',fontSize:'12px'}}>{hide}</span>
                    <LexiconLegend isHebrew={isHebrew} side="left" sourceType={this.state.sourceType} synonymPath={this.state.synonymPath} targetType={this.state.targetType} /></div>}
                    <LoadingOverlay
                      active={false}//this.state.equivLoading}
                      spinner
                      style={{height:this.state.lexiconKeyOpen?`${dialogContentHeight-189}px`:`${dialogContentHeight-130}px`}}
                      text={<div style={{direction:isHebrew?'rtl':'ltr'}}>{loadingText}</div>}
                    ><div className="lexiconTreeBox" style={{height:this.state.lexiconKeyOpen?`${dialogContentHeight-189}px`:`${dialogContentHeight-130}px`,marginTop:'5px'}}><div className="superTreeviewLexicon" style={{overflowY:'auto',maxHeight:this.state.lexiconKeyOpen?`${dialogContentHeight-189}px`:`${dialogContentHeight-130}px`,minHeight:`100px`/*,fontWeight:'bold'*/}}>
                    {this.state.equivalentsTree?<SuperTreeview  
                          data={this.state.equivalentsTree}
                          noChildrenAvailableMessage={<span></span>}// style={{fontSize:'28px', color: 'dodgerblue'}}><i>{this.props.translate("noResults")}</i></span>}
                          isDeletable={() => { return true; }}
                          isExpandable={(node)=>{ return node.isExpandable}}
                          isCheckable={(node) => { return node.checkable; }}
                          onUpdateCb={(updatedData) => {
                            this.resetEquivalentsTree(updatedData)
                          }}
                          onCheckToggleCb={(nodes)=>{
                            const checkState = nodes[0].isChecked;
                            applyCheckStateTo(nodes);
                            function applyCheckStateTo(nodes){
                                nodes.forEach((node)=>{
                                    node.isChecked = checkState
                                    if(node.children){
                                        applyCheckStateTo(node.children);
                                    }
                                })
                            }
                        }}
                      />:<></>}</div></div></LoadingOverlay>
                      <span className="resultsInstructions">{equivalentsInstructions}</span></div></td>
                      <td>
                        {this.state.lexiconKeyOpen && <div className={"lexiconKeyVerticalPadding"} />}
                        {this.state.sourceType !== this.state.targetType && <Button
                        variant="contained" disabled={this.state.isLoading || this.state.equivLoading || this.state.loadingMore}
                        title= {this.props.translate("flipContent")}
                        style={{margin:'auto 0',minWidth:'35px',maxWidth:'35px',minHeight:'35px',maxHeight:'35px',padding:'0',outlineWidth:0,}}
                        onClick={() => {this.swapResultsWithEquivalents()}}
                        >
                        <SwapHorizIcon/>
                        </Button>}
                      
                      {synonymsAvailable && <div><b>{this.props.translate("path")}</b>
                      <ToggleButtonGroup
                            orientation="vertical"
                            value={this.state.synonymPath}
                            exclusive
                            style={{margin:'auto 0',maxWidth:'58px',minWidth:'58px'}}
                            className="lexiconToggleGroup toggleButtonGroup"
                            onChange={this.handleSynonymsChange}
                            >
                            {TeivaSynonymAvailable && <ToggleButton className={this.state.synonymPath==="Teiva"?"selectedToggleButton":""} style={{padding:'0',fontSize:isHebrew?'12px':'10px',}} id="wordSyn" value="Teiva">{teiva}</ToggleButton>}
                            {ShoreshSynonymAvailable && <ToggleButton className={this.state.synonymPath==="Shoresh"?"selectedToggleButton":""} style={{padding:'0',fontSize:isHebrew?'12px':'10px'}} id="rootSyn" value="Shoresh">{root}</ToggleButton>}
                            {GematriaSynonymAvailable && <ToggleButton className={this.state.synonymPath==="Gematria"?"selectedToggleButton":""} style={{padding:'0',fontSize:isHebrew?'12px':'10px'}} id="gematriaSyn" value="Gematria">{gematria}</ToggleButton>}
                            {AtbashSynonymAvailable && <ToggleButton className={this.state.synonymPath==="Atbash"?"selectedToggleButton":""} style={{padding:'0',fontSize:isHebrew?'12px':'10px'}} id="atbashSyn" value="Atbash">{atbash}</ToggleButton>}
                            {TargumSynonymAvailable && <ToggleButton className={this.state.synonymPath==="Targum"?"selectedToggleButton":""} style={{padding:'0',fontSize:isHebrew?'12px':'10px'}} id="targumSyn" value="Targum" >{targum}</ToggleButton>}
                            {AnagramSynonymAvailable && <ToggleButton className={this.state.synonymPath==="Anagram"?"selectedToggleButton":""} style={{padding:'0',fontSize:isHebrew?'12px':'10px'}} id="anagramSyn" value="Anagram" >{anagram}</ToggleButton>}
                            </ToggleButtonGroup></div>}
                    </td>
                      
                    <td  style={{verticalAlign:'top',padding:'0 9px'}}><div className="lexiconTreeLabel" >
                    {/* <span style={{textAlign:'center'}}> */}
                      {lessThan100Leaves &&<div style={{margin: '0 4px 0 4px',minWidth:'20px',maxWidth:'20px',maxHeight:'24px',minHeight:'24px',padding:'0',outlineWidth:0,display:'inline-block'}}>&nbsp;</div>}
                      <Button
                        variant="contained"
                        title= {this.props.translate("collapseAll")}
                        style={{margin: '4px 4px',minWidth:'20px',maxWidth:'20px',maxHeight:'24px',minHeight:'24px',padding:'0',outlineWidth:0,}}
                        onClick={() => {this.collapseResults()}}
                    >
                      <ExpandLessIcon/>
                    </Button>
                      <span className="labelLexicon">{results}</span>
                      <Button
                        variant="contained"
                        title= {this.props.translate("uncheckAll")}
                        style={{margin: '4px 4px',minWidth:'20px',maxWidth:'20px',maxHeight:'24px',minHeight:'24px',padding:'0',outlineWidth:0,}}
                        onClick={() => {this.unselectAllResults()}}
                    >
                      {/* <IndeterminateCheckBoxOutlinedIcon/> */}
                      <img src={require("./images/x.png")} alt="Clear all settings" width="16" height="16" />
                    </Button>
                    {/* </span> */}
                    {lessThan100Leaves && <Button
                        variant="contained"
                        title={this.props.translate("checkAll")}
                        style={{margin: '4px 4px',minWidth:'20px',maxWidth:'20px',maxHeight:'24px',minHeight:'24px',padding:'0',outlineWidth:0,}}
                        onClick={() => {this.checkAllOpenResults()}}
                    >
                      {/* <CheckBoxOutlinedIcon/> */}
                      <img src={require("./images/check.png")} alt="Clear all settings" width="16" height="16" />
                    </Button>}
                   {this.state.lexiconKeyOpen && <div id="lexiconResultsKey" className={isHebrew ? "lexiconKeyRTL" : "lexiconKeyLTR"} style={{margin:'1px 0 7px'}}><ArrowDropUpIcon className="clickable" style={{float:isHebrew?'left':'right'}} onClick={()=>this.setState({lexiconKeyOpen:false})}/><span style={{float:isHebrew?'left':'right',fontSize:'12px'}}>{hide}</span>
                    <LexiconLegend isHebrew={isHebrew} side="right" sourceType={this.state.sourceType} synonymPath={this.state.synonymPath} targetType={this.state.targetType} /></div>}
                   <LoadingOverlay
                      active={this.state.isLoading}
                      spinner
                      style={{height:this.state.lexiconKeyOpen?`${dialogContentHeight-189}px`:`${dialogContentHeight-130}px`}}
                      text={<div style={{direction:isHebrew?'rtl':'ltr'}}>{loadingText}</div>}
                    > <div className="lexiconTreeBox" style={{height:this.state.lexiconKeyOpen?`${dialogContentHeight-189}px`:`${dialogContentHeight-130}px`}}>
        
                <div className="superTreeviewLexicon" style={{overflowX:'auto',overflowY:'auto',maxHeight:this.state.lexiconKeyOpen?`${dialogContentHeight-189}px`:`${dialogContentHeight-130}px`,minHeight:`100px`/*,fontWeight:'bold'*/}}>
                   {this.state.resultsTree?
                     <SuperTreeview 
                           data={this.state.resultsTree}
                           isDeletable={() => { return false; }}
                           isExpandable={(node)=>{ return node.isExpandable}}
                           noChildrenAvailableMessage={<div style={{color:'dodgerblue',maxWidth:'300px', margin:'10px auto'}}><span style={{fontSize:'28px'}}><i>{this.props.translate("noResults")}</i></span><br/><span style={{fontStyle:'italic', fontSize:'16px',}}>{this.props.translate("suggestion")}<br/>{this.props.translate("noResultsDetailsLexicon")}</span></div>}
                           onExpandToggleCb={async (node, depth)=>{
                             if(node.isExpanded){
                                node.isChildrenLoading=true;
                                await this.expandToggleCb(node,depth,true,true)
                             }
                              }}
                           isCheckable={(sub) => { return sub.checkable; }}
                           onUpdateCb={async (updatedData) => {
                             this.resetResultsTree(updatedData)
                           }}
                           loadingElement={<div style={{direction:isHebrew?'rtl':'ltr'}}>{loadingText}</div>}
                           onCheckToggleCb={(nodes,depth)=>{
                            const checkState = nodes[0].isChecked;
                            applyCheckStateTo(nodes);
                            async function applyCheckStateTo(nodes){
                                nodes.forEach(async(node)=>{
                                    node.isChecked = checkState
                                    if(node.children){
                                        applyCheckStateTo(node.children);
                                    }else if(node.isExpandable){
                                      node.isChildrenLoading=true;
                                      await fakeThis.expandToggleCb(node,depth,false,true)
                                    }
                                })
                            }
                        }}
                    />
                      :<></>}</div></div></LoadingOverlay>
                      <span className="resultsInstructions">{resultsInstructions}</span></div></td>
                        <td  style={{verticalAlign:'top',padding:'0 0 0 7px',minWidth:'122px'}}>  
                        {!this.state.lexiconKeyOpen && <div  className="clickable showLexiconKey" onClick={() => this.setState({lexiconKeyOpen:!this.state.lexiconKeyOpen})} style={directionStyling} style={{fontSize:isHebrew?'13px':'14px',}}>
                          {this.state.lexiconKeyOpen ? hideKey : showKey}<ArrowDropDownIcon className="clickable"/>
                        </div>}
                            <ToggleButtonGroup
                            orientation="vertical"
                            value={this.state.sourceType}
                            exclusive style={{float:'left',marginTop:this.state.lexiconKeyOpen?'45px':'3px'}}
                            className="lexiconToggleGroup toggleButtonGroup"
                            onChange={this.handleResultsChange}
                            >
                            {this.state.lexiconKeyOpen && <div className={"lexiconKeyVerticalPadding"} />}
                            <ToggleButton className={this.state.sourceType==="Teiva"?"selectedToggleButton":""} id="wordRes" value="Teiva" style={isHebrew?{}:{fontSize:'11px'}}  /*disabled={this.state.targetType==="Teiva"}*/>{teiva}</ToggleButton>
                            <ToggleButton className={this.state.sourceType==="Shoresh"?"selectedToggleButton":""} id="rootRes" value="Shoresh" style={isHebrew?{}:{fontSize:'11px'}}  /*disabled={this.state.targetType==="Shoresh"}*/>{root}</ToggleButton>
                            <ToggleButton className={this.state.sourceType==="Gematria"?"selectedToggleButton":""} id="gematriaRes" value="Gematria" style={isHebrew?{}:{fontSize:'11px'}} /*disabled={this.state.targetType==="Targum"}*/>{gematria}</ToggleButton>
                            <ToggleButton className={this.state.sourceType==="Atbash"?"selectedToggleButton":""} id="atbashRes" value="Atbash" style={isHebrew?{}:{fontSize:'11px'}} /*disabled={this.state.targetType==="Targum"}*/>{atbash}</ToggleButton>
                            <ToggleButton className={this.state.sourceType==="Targum"?"selectedToggleButton":""} id="targumRes" value="Targum" style={isHebrew?{}:{fontSize:'11px'}} /*disabled={this.state.targetType==="Gematria" || this.state.targetType==="Atbash"}*/>{targum}</ToggleButton>
                            </ToggleButtonGroup>
                        </td>
                    </tr>
                </tbody></table></DialogContent>
                <div  style={{textAlign:"center"}}>
                <Button
                        variant="contained"
                        color="primary"
                        className="parsha-viewer-control-button"
                        type="Search"
                        style={{margin: '0 5px',width:'150px',fontSize:isHebrew?'16px':'14px',outlineWidth:0,}}
                        onClick={() => {this.clearEverything()}}
                    >
                        {clear}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className="parsha-viewer-control-button"
                        type="Search"
                        disabled={this.state.equivalentsToInclude.length===0}
                        style={{margin: '0 5px',width:'150px',fontSize:isHebrew?'16px':'14px',outlineWidth:0,}}
                        onClick={this.searchTanach}
                    >
                        {searchTanach}
                    </Button>
                </div>

            </Rnd>
    }
}    Lexicon.propTypes = {
        classes: PropTypes.object.isRequired, //onClick={this.closeSearchResults}
      };
export default withStyles(styles2)(withStyles(styles)(withLocalize(Lexicon)));