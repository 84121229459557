import React, { Component } from 'react';
import * as auth from './Auth';
import translations from "./json/translations.json";
import { renderToStaticMarkup } from "react-dom/server";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styles from './css/App.css'; //for customizing reactStrap styles
import {withRouter} from 'react-router-dom';
import { withLocalize, Translate, getTranslate, getActiveLanguage } from "react-localize-redux";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

//mje - Oct 29, 2019 - for obsolete code pertinent to trial and error changes to get login/authorization to work with Auth) and Okta in Oct 2019, see version history of this file in Git

class LoginButtons extends Component {

  constructor(props) {
    super(props);

    this.state = {
        signOutDialogIsOpen: false,
    };

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true }
    });

    this.props.addTranslation(translations);

//    this.setStateSynchronous = this.setStateSynchronous.bind(this);
    this.isAuthenticated = auth.loggedIn();
    this.openSignOutDialog = this.openSignOutDialog.bind(this);
    this.closeSignOutDialog = this.closeSignOutDialog.bind(this);
    this.logout = this.logout.bind(this);

  }
//    var signIn = null, signOut = null, userInfo = null;
    openSignOutDialog(e){
        e.preventDefault()
        this.setState({ signOutDialogIsOpen: true });
    }

    closeSignOutDialog(){
        this.setState({ signOutDialogIsOpen: false });
    }

    logout(e){
        localStorage.setItem("helpDialogStartupTopic", "login");
        e.preventDefault();
//        console.log(e)
        this.props.logout();
        this.closeSignOutDialog();
    }

    login(){
        localStorage.setItem("helpDialogStartupTopic", "login");
        this.props.login();
    }

    render(){
        const signIn = this.props.translate("signIn");
        const signOut = this.props.translate("signOut");
        const userInfo = this.props.userInfo;

        var directionStyling = {}
        if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
            directionStyling = {direction: 'rtl', textAlign: 'right'}
        }

//        var isAuthenticated = this.isAuthenticated;
        var isAuthenticated = this.props.authenticated;

        return (<span style={{marginRight: "10px", marginLeft: "20px"}} helpTopic="login">
          {
            (!isAuthenticated) &&
            <button className="btn btn-dark" onClick={() => {this.login()}}>{signIn}</button>
          }
          {
            (isAuthenticated) &&
            <span>
              <button className="btn btn-dark" onClick={(e) => {this.openSignOutDialog(e)}}>{signOut}</button>
              <label className="mr-2 text-white username-display">{auth.getUserInfo()}</label>
            </span>
          }


                  <Dialog
                    open={this.state.signOutDialogIsOpen}
                    onClose={this.closeSignOutDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={directionStyling}
                  >
                    <DialogTitle id="alert-dialog-title">{<Translate id="areYouSure.caption" />}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <Translate id="logoutWillClear" />
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeSignOutDialog} color="primary">
                        <Translate id="cancel.caption" />
                      </Button>
                      <Button onClick={(e) => {this.logout(e)}} color="primary">
                        <Translate id="continue.caption" />
                      </Button>
                    </DialogActions>
                  </Dialog>

          </span>
        );
    }


};

export default withStyles(styles)(withRouter(withLocalize(LoginButtons)));
