import React, { Component } from 'react';
import './css/App.css';
import AppNavBar from './AppNavBar';
import { Link } from 'react-router-dom';
import { Button, Container, Table } from 'reactstrap';

class Finders extends Component {
  render() {
    return (
      <div>
        <AppNavBar/>
        <Container fluid>
          <Table className="mt-4">
            <thead>
            <tr>
                <td>LCS finder</td>
            </tr>
            <tr>
                <td>Anagram finder</td>
            </tr>
            <tr>
                <td>Canadian side of Yellowstone National Park finder</td>
            </tr>
            </thead>
          </Table>
          <Button color="link"><Link to="/">דף הבית</Link></Button>
        </Container>
      </div>
    );
  }
}

export default Finders;