import React, { Component, useState } from 'react';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import * as properties from './config/properties.js';
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import { renderToStaticMarkup } from "react-dom/server";
import { Paper, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, FormControl, Select, MenuItem } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import RefreshIcon from '@material-ui/icons/Refresh';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Draggable from 'react-draggable';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Rnd } from "react-rnd";
import { MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import ContextMenu from './ContextMenu';
import { contextMenu } from 'react-contexify';
import TaamParser from './TaamParser';
import LoadingOverlay from 'react-loading-overlay';
import TanachViewerPlacementSelector from './TanachViewerPlacementSelector';
import ObjectCloud from './ObjectCloud';

const experimentalModeFlag = false; //when want to experiment eg. with slide show mode when advance position with single click of forward pasuk navigation button, then set this = true

const Gematria = require('gematria');

const styles = theme => ({
  toggleContainer: {
    backgroundColor: 'transparent',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    alignItems: 'center',
    margin: `auto`,
    background: theme.palette.background.default,
  },
});

function PaperComponent(props) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function BaseParameters() {
  this.source = 'bhsWebApp';
  this.clientIP = null;
  this.morphInflections = null;
  this.filterIds = 'FFFFFFFFFFF'; //all seforim selected
  this.limit = 2100;
  this.minimumFrequency = 1;
  this.skipTerms = [];
  this.namedLocations = [];
  this.pasukRangeLocations = [];
}

function NamedLocation(kriaId, aliyaId) {
  this.kriaId =  kriaId; //int
  this.aliyaId = aliyaId; //int
}

function PerekPasukRange(seferId1, perekNum1, pasukNum1, seferId2, perekNum2, pasukNum2) {
  this.seferId1 =  seferId1; //int
  this.perekNum1 = perekNum1;  //int
  this.pasukNum1 =  pasukNum1; //int
  this.seferId2 = seferId2;  //int
  this.perekNum2 =  perekNum2; //int
  this.pasukNum2 = pasukNum2;  //int
}

class TanachViewer extends Component {

 constructor(props) {
    super(props);
    this.state = {
      zIndex: 801,
      // columnDefs: this.columnDefs, //switched from ag grid to html table for easier editting
      gridFontSize: 14,
      gridRowHeight: 28,
      pasukColumnWidth: 140,
      rowData: [],
      textDisplayType: "stream",
      contentDisplayed: ["tanach"],
      contextSize: 21,
      fontSizeLevel: 1,
      pasukId: this.props.pasukId,
      location: '',
      placementData: {type: false, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, kriaSequenceNum: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []},
      type: false,
      valList1: [],
      valList2: [],
      valList3: [],
      valList4: [],
      valList5: [],
      valList6: [],
      val1: null,
      val2: null,
      val3: null,
      val4: null,
      val5: null,
      val6: null,
      kriaSequenceNum: 0, //this is used along with val4 (kriaId) when isAtNamedSection = T
      seferId1: 0, //the following six are used when isAtNamedSection = T
      perekNum1: 0,
      pasukNum1: 0,
      seferId2: 0,
      perekNum2: 0,
      pasukNum2: 0,
      selectOrLoading: [this.props.translate('select'), this.props.translate('select'), this.props.translate('select')],
      // widthNum: 800,
      // heightNum: 400,
      // x: (window.innerWidth - 800) / 2,
      // y: (window.innerHeight - 400) / 2,
      textToCopy: '',
      useKinuim: false,
      includeMekorot: false,
      searchAsQuote: false,
      taamParseData: {},
      taamParserOpen: false,
      taamParserPasukId: null,
      taamParserLocation: '',
      contextMenuText: '',
      savedSelection: null,
      isLoading: false,
      isAtNamedSection: false,
      hoveredLocation: null,
      hoveredMatchIds: null,
      loadedSectionVals: {},
      canFetchSection: false,
      wordCloudDiv: null,
      columnClicked:'תנך',
      open:this.props.isOpen
    };

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}
    // this.resultsGridReady = this.resultsGridReady.bind(this);  //switched from ag grid to html table for easier editting
    this.handleToggleTextDisplayType = this.handleToggleTextDisplayType.bind(this);
    this.handleToggleContentDisplayed = this.handleToggleContentDisplayed.bind(this);
    this.clickToggleContentDisplayed = this.clickToggleContentDisplayed.bind(this);
    this.increaseFontSize = this.increaseFontSize.bind(this);
    this.decreaseFontSize = this.decreaseFontSize.bind(this);
    this.setGridFontSize = this.setGridFontSize.bind(this);
    // this.getPasukColumWidth = this.getPasukColumWidth.bind(this);  //switched from ag grid to html table for easier editting
    this.handleRangeChange1 = this.handleRangeChange1.bind(this);
    this.handleRangeChange2 = this.handleRangeChange2.bind(this);
    this.handleRangeChange3 = this.handleRangeChange3.bind(this);
    this.loadList2 = this.loadList2.bind(this);
    this.loadList3 = this.loadList3.bind(this);
    this.updatePasuk = this.updatePasuk.bind(this);
    this.loadSelectedPasuk = this.loadSelectedPasuk.bind(this);
    this.fetchContext = this.fetchContext.bind(this);
    this.onCellContextMenu = this.onCellContextMenu.bind(this);
    this.toggleUseKinuim = this.toggleUseKinuim.bind(this);
    this.toggleIncludeMekorot = this.toggleIncludeMekorot.bind(this);
    this.toggleSearchAsQuote = this.toggleSearchAsQuote.bind(this);
    this.openTaamParser = this.openTaamParser.bind(this);
    this.closeTaamParser = this.closeTaamParser.bind(this);
    this.extModifyPlacementData = this.extModifyPlacementData.bind(this);
    this.modifyPlacementData = this.modifyPlacementData.bind(this);
    this.fetchSection = this.fetchSection.bind(this);
    this.refreshSelector = this.refreshSelector.bind(this);
    this.selectPasukByLocation = this.selectPasukByLocation.bind(this);
    this.setHoveredLocation = this.setHoveredLocation.bind(this);
    this.setHoveredMatchIds = this.setHoveredMatchIds.bind(this);
    this.getNextResult = this.getNextResult.bind(this);
    this.getPrevResult = this.getPrevResult.bind(this);
    this.getNextResultId = this.getNextResultId.bind(this);
    this.getPrevResultId = this.getPrevResultId.bind(this);
    this.getWordCloud = this.getWordCloud.bind(this);
 }

findHighestZIndex(elem) {
  var elems = document.getElementsByTagName("*");
  var highest = 0;
  for (var i = 0; i < elems.length; i++)
  {
    var zindex=document.defaultView.getComputedStyle(elems[i],null).getPropertyValue("z-index");
    if ((zindex > highest) && (zindex != 'auto') && (zindex != 9998) && (zindex != 100000090))
    {
      highest = zindex;
    }
  }
  return parseFloat(highest);
}

showOnTop(){
  this.setState({
    zIndex: this.findHighestZIndex() + 1
  })
}

handleClick(e){
  this.showOnTop();
}

getNextResult(){
  if(this.state.type){
    this.setState(this.state.oldState,()=>{this.setState({type:false},()=>{
      var nextResultId = this.getNextResultId();
      if(nextResultId)this.props.toggleTanachViewer(true, nextResultId, this.props.resultsApi);    
    })})
  }else{
    var nextResultId = this.getNextResultId();
    if(nextResultId)this.props.toggleTanachViewer(true, nextResultId, this.props.resultsApi);
  }
}

getPrevResult(){
  if(this.state.type){
    this.setState(this.state.oldState,()=>{this.setState({type:false},()=>{
      var prevResultId = this.getPrevResultId();
      if(prevResultId)this.props.toggleTanachViewer(true, prevResultId, this.props.resultsApi);
    })})
  }else{
    var prevResultId = this.getPrevResultId();
    if(prevResultId)this.props.toggleTanachViewer(true, prevResultId, this.props.resultsApi);
  }
}

getNextResultId(){
  if(!this.props.resultsApi) return null
  //change focused cell (unless at last result)
  var lastRowIndex = this.props.resultsApi.getModel().rowsToDisplay.length-1;
  var currentRowIndex = this.props.resultsApi.getFocusedCell().rowIndex;

  if(currentRowIndex < lastRowIndex){ // prevent if at last result
    this.props.resultsApi.setFocusedCell((this.props.resultsApi.getFocusedCell().rowIndex + 1), this.props.resultsApi.getFocusedCell().column.colDef.field)
  }

  //get pasukId of new focused cell
  var selectedList = 0;
  if(['location1', 'pasukText1'].includes(this.props.resultsApi.getFocusedCell().column.colDef.field) || this.props.resultsApi.getFocusedCell().column.colDef.field.includes("value1")){
    selectedList = 1;
  } else if (['location2', 'pasukText2'].includes(this.props.resultsApi.getFocusedCell().column.colDef.field) || this.props.resultsApi.getFocusedCell().column.colDef.field.includes("value2")){
    selectedList = 2;
  }
  var nextResultData = this.props.resultsApi.getModel().rowsToDisplay[this.props.resultsApi.getFocusedCell().rowIndex].data;

  var pasukId;

  if(selectedList === 0){
        return nextResultData.pasukId;
  } else if (selectedList === 1){
    if(this.props.resultsApi.getFocusedCell().column.colDef.field.includes("value1")){
      pasukId = nextResultData.value1.pasukId;
    } else {
      pasukId = nextResultData.pasukId1;
    }
  } else if (selectedList === 2){
    if(this.props.resultsApi.getFocusedCell().column.colDef.field.includes("value2")){
      pasukId = nextResultData.value2.pasukId;
    } else {
      pasukId = nextResultData.pasukId2;
    }
  }
  return pasukId
}


getPrevResultId(){
  if(!this.props.resultsApi) return null
  //change focused cell (unless at first result)
  if(this.props.resultsApi.getFocusedCell().rowIndex > 0){
        this.props.resultsApi.setFocusedCell((this.props.resultsApi.getFocusedCell().rowIndex - 1), this.props.resultsApi.getFocusedCell().column.colDef.field)
  }

  //get pasukId of new focused cell
  var selectedList = 0;
  if(['location1', 'pasukText1'].includes(this.props.resultsApi.getFocusedCell().column.colDef.field) || this.props.resultsApi.getFocusedCell().column.colDef.field.includes("value1")){
    selectedList = 1;
  } else if (['location2', 'pasukText2'].includes(this.props.resultsApi.getFocusedCell().column.colDef.field) || this.props.resultsApi.getFocusedCell().column.colDef.field.includes("value2")){
    selectedList = 2;
  }
  var prevResultData = this.props.resultsApi.getModel().rowsToDisplay[this.props.resultsApi.getFocusedCell().rowIndex].data;

  var pasukId;
  if(selectedList === 0){
    pasukId = prevResultData.pasukId;
  } else if (selectedList === 1){
    if(this.props.resultsApi.getFocusedCell().column.colDef.field.includes("value1")){
      pasukId = prevResultData.value1.pasukId;
    } else {
      pasukId = prevResultData.pasukId1;
    }
  } else if (selectedList === 2){
    if(this.props.resultsApi.getFocusedCell().column.colDef.field.includes("value2")){
      pasukId = prevResultData.value2.pasukId;
    } else {
      pasukId = prevResultData.pasukId2;
    }
  }
  return pasukId;
}

setHoveredMatchIds(matchIds){
  this.setState({hoveredMatchIds: matchIds})
}

setHoveredLocation(location){
  this.setState({hoveredLocation: location})
}

 removeNikudAndTaamim(text){
   var cleanedText = text;
   cleanedText = this.removeNikud(this.removeTaamim(text));

   return cleanedText;
 }

 removeNikud(str){
  for (let i = str.length-1; i >= 0; i--){
      if('ְֱֲֳִֵֶַָֹֺֻּֽ־ׇֿׁׂׅׄ'.includes(str[i])){
          str = str.slice(0,i).concat(str.slice(i+1,str.length))
      }
  }
  return str;
}

removeTaamim(str){
  for (let i = str.length-1; i >= 0; i--){
      if('ֽ֑֖֛֢֣֤֥֦֧֪֚֭֮֒֓֔֕֗֘֙֜֝֞֟֠֡֨֩֫֬֯׀'.includes(str[i])){
          str = str.slice(0,i).concat(str.slice(i+1,str.length))
      }
  }
  return str;
}

 extModifyPlacementData = (extState, willFetchPesukim, isRefresh,oldState,reloadPesukim) => {
  this.modifyPlacementData(extState, !!willFetchPesukim, !!isRefresh,oldState,reloadPesukim);
}

modifyPlacementData(extState, willFetchPesukim, isRefresh,oldState,reloadPesukim) {
  var rowValues = {type: extState.type, val1: extState.val1, val2: extState.val2, val3: extState.val3, val4: extState.val4, val5: extState.val5, val6: extState.val6, kriaSequenceNum: extState.kriaSequenceNum, 
                          valList1: extState.valList1, valList2: extState.valList2, valList3: extState.valList3, valList4: extState.valList4, valList5: extState.valList5, valList6: extState.valList6 };
  if(rowValues.type === false){ // is pasuk selector
    var isNewSefer = rowValues.val1 !== this.state.placementData.val1;
    if(isNewSefer){
      rowValues.valList3 = [];
    }
    if(willFetchPesukim){
      const event = {target: {value: rowValues.val3}}
      this.handleRangeChange3(event)
    }
  }
  var canFetchSection = false;
  var willRefreshSelector = false;
  if(!this.state.isAtNamedSection && this.state.type === true && rowValues.type === false){ // refresh selector to current selection after toggling type
    willRefreshSelector = true;
  }
  if(oldState)this.setState({oldState})
  this.setState({
    placementData: rowValues,
    type: rowValues.type,
    val1: rowValues.val1,
    val2: rowValues.val2,
    val3: rowValues.val3,
    val4: rowValues.val4,
    val5: rowValues.val5,
    kriaSequenceNum: rowValues.kriaSequenceNum,
    valList1: rowValues.valList1,
    valList2: rowValues.valList2,
    valList3: rowValues.valList3,
    valList4: rowValues.valList4,
    valList5: rowValues.valList5,
  }, () => {
    if(this.state.val4 > 0){
      canFetchSection = true;
    }
    if(canFetchSection && !isRefresh){ //isRefresh => preventing viewer from doing full reload when just toggling selector
      this.setState({
        canFetchSection: true
      }, () => {
        //if no sub-krias, automatically load kria
        var willLoad = false;
        if(this.state.kriaSequenceNum || this.state.valList5.length === 0 || reloadPesukim){
          willLoad = true;
        }
        if(willLoad){
          this.fetchSection()
        }
      })
    } else {
      this.setState({
        canFetchSection: false
      })
    }
    if(willRefreshSelector){
      this.refreshSelector()
    }
  });
}

refreshSelector(){
  if(!this.state.isAtNamedSection){
    this.selectPasukByLocation(this.state.location)
  } else if (this.state.isAtNamedSection) {
    var loadedSectionVals = this.state.loadedSectionVals;
    this.setState({
      val1: loadedSectionVals.val1,
      val2: loadedSectionVals.val2,
      val3: loadedSectionVals.val3,
      val4: loadedSectionVals.val4,
      val5: loadedSectionVals.val5,
      val6: loadedSectionVals.val6,
      kriaSequenceNum: loadedSectionVals.kriaSequenceNum,
      valList1: loadedSectionVals.valList1,
      valList2: loadedSectionVals.valList2,
      valList3: loadedSectionVals.valList3,
      valList4: loadedSectionVals.valList4,
      valList5: loadedSectionVals.valList5,
      valList6: loadedSectionVals.valList6,
    })
  }
}

selectPasukByLocation(location){
  var locationArray = location.split(":");
  var val1 = this.state.valList1.map(function(sefer) { return sefer.seferName; }).indexOf(locationArray[0]) + 1;
  var seferObjIndex = this.state.valList1.map(function(sefer) { return sefer.seferName; }).indexOf(locationArray[0]);
  var seferObj = this.state.valList1[seferObjIndex];
  var val1 = seferObj.seferId;
  var val1Event = {target: {value: val1}};

  this.handleRangeChange1(val1Event, null, () => {
    var val2 = this.state.valList2.map(function(perek) { return perek.perekValue; }).indexOf(locationArray[1]) + 1;
    var val2Event = {target: {value: val2}};
    this.handleRangeChange2(val2Event, null, () => {
      var val3 = this.state.valList3.map(function(pasuk) { return pasuk.pasukValue; }).indexOf(locationArray[2]) + 1;
      var val3Event = {target: {value: val3}};
      this.handleRangeChange3(val3Event);
    })
  })
}

fetchSection(){
  this.setState({
    isLoading: true,
    loadedSectionVals: this.state.placementData
  }, async () => {
    var kriaId = this.state.val4;
    var kriaSequenceNum = this.state.kriaSequenceNum;
    if(kriaSequenceNum === 0 || this.state.valList5.length === 1) {
      kriaSequenceNum = null
    }
  
    var url = properties.env.webServiceURL + "/BaseHaSefer/getKriaPesukim?source=bhsWebApp";
    url += "&kriaId="+kriaId;
    if(kriaSequenceNum){
      url += "&kriaSequenceNum="+kriaSequenceNum;
    }
    
    const response = await fetch(url);
    var body = await response.json();
    for(let i = 0; i < body.length; i++){
      body[i].text = body[i].t1;
      body[i].targum = body[i].t2;
    }

    var rowData = this.getRestructuredPasukContextRows(body);

    await this.getWordCloud().then( (wordCloud) =>
        this.setState({
          isAtNamedSection: true,
          rowData: rowData,
          location: null,
          wordCloudDiv: wordCloud,
          isLoading: false
        })
    )
  })
}

  async openTaamParser(pasukLocation){
    this.props.extOpenTaamParser(pasukLocation)
    const body = await this.props.extFetchTaamParseData(pasukLocation)
    this.setState({taamParseData: body})
    this.setState({taamParserOpen: true, taamParserPasukId: body.pasukId, taamParserLocation: body.pasukLocation, taamParserOpen: false}, () => {
        this.setState({taamParserOpen: true})
    })
  }

  closeTaamParser(){
    this.props.extCloseTaamParser()
    this.setState({taamParserOpen: false})
  }

  toggleUseKinuim(){
    if(this.state.savedSelection){
      this.restoreSelection(this.state.savedSelection)
    }
    this.setState({ useKinuim: !this.state.useKinuim })
  }
  toggleIncludeMekorot(){
    if(this.state.savedSelection){
      this.restoreSelection(this.state.savedSelection)
    }
    this.setState({ includeMekorot: !this.state.includeMekorot })
  }

  toggleSearchAsQuote(){
    if(this.state.savedSelection){
      this.restoreSelection(this.state.savedSelection)
    }
    this.setState({ searchAsQuote: !this.state.searchAsQuote })
  }

  saveSelection(){ // using this to save then restore text selection after clicking checkboxes in context menu
    if (window.getSelection) {
      var selection = window.getSelection();
      if (selection.getRangeAt && selection.rangeCount) {
        this.setState({
          savedSelection: selection.getRangeAt(0)
        })
        return selection.getRangeAt(0);
      }
    } else if (document.selection && document.selection.createRange) {
        this.setState({
          savedSelection: document.selection.createRange()
        })
        return document.selection.createRange();
    }
    return null;
  }

  restoreSelection(range) { // using this to save then restore text selection after clicking checkboxes in context menu
    if (range) {
        if (window.getSelection) {
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (document.selection && range.select) {
            range.select();
        }
    }
}

  styleContextMenu(e){
    const { innerWidth } = window;
    var submenus = document.getElementsByClassName('react-contexify__submenu');
    for(let i = 0; i < submenus.length; i++){
        var submenu = submenus[i];
        var submenuWidth = parseInt(submenu.style.minWidth.slice(0, submenu.style.minWidth.length-2));
        if(this.props.activeLanguage.code === 'he'){
            if(e.clientX > submenuWidth){
                submenu.style.left = '';
                submenu.style.right = '100%';
            } else {
                submenu.style.left = '100%';
                submenu.style.right = '';
            }
        } else if(this.props.activeLanguage.code === 'en'){
            if(innerWidth-e.clientX-300 > submenuWidth){
                submenu.style.left = '100%';
                submenu.style.right = '';
            } else {
                submenu.style.left = '';
                submenu.style.right = '100%';
            }
        }
    }
  }

  resetKinuimMekorot(){
    this.setState({
      useKinuim: false,
      includeMekorot: false,
    })
  }

  onStreamContextMenu(e,columnClicked,word){
    this.setState({columnClicked})
      this.resetKinuimMekorot();
      this.styleContextMenu(e);
      var location = '';
      var text = '';

      if(e.target.attributes.class && e.target.attributes.class.value === 'tanach-viewer-text-stream'){
        //is white space at the end of text stream => get last pasuk info
        var target = e.target
        var lastPasukSpan = target.lastElementChild;
        location = lastPasukSpan.attributes.location.value;
        text = lastPasukSpan.attributes.text.value;
      } else {
        var parentSpan = e.target.parentElement;
        while(parentSpan && !parentSpan.attributes.location){
          parentSpan = parentSpan.parentElement;
        }
        if(!parentSpan) return null
        location = parentSpan.attributes.location.value;
        text = parentSpan.attributes.text.value;
      }
      while(text.indexOf("_") >= 0){
        text = text.replace("_", " ");
      }
  
  
      //set text to copy
      var textToCopy = '';
      var contextMenuText = '';
      if(window.getSelection().toString()){
        this.saveSelection(); // using this to save then restore text selection after clicking checkboxes in context menu
        textToCopy = 'highlighted';
        contextMenuText = window.getSelection().toString();
      } else {
        this.setState({savedSelection: null})
        textToCopy = 'pasuk';
        contextMenuText = text;
      }
  
      this.setState({textToCopy, taamParserLocation: location, contextMenuText,wordToCopy:word})
  }


  onCellContextMenu(e,columnClicked,word){
    this.setState({columnClicked})
    this.styleContextMenu(e);
    this.resetKinuimMekorot();

    //set text to copy
    var textToCopy = '';

    // var focusedCellRowIndex = null ;
    // if(this.contextApi.getFocusedCell()){
    //     var focusedCellRowIndex = this.contextApi.getFocusedCell().rowIndex;
    // }
    var focusedCellSelected = null;
    var focusedCellLocation = null;
    var focusedCellText = null;
    // if((typeof(focusedCellRowIndex) === 'number') && this.contextApi.getModel().rowsToDisplay[focusedCellRowIndex]){
    //     focusedCellSelected = this.contextApi.getModel().rowsToDisplay[focusedCellRowIndex].selected;
    //     focusedCellLocation = this.contextApi.getModel().rowsToDisplay[focusedCellRowIndex].data.location;
    //     focusedCellText = this.contextApi.getModel().rowsToDisplay[focusedCellRowIndex].data.text;
    // }

    //focused = row of focused cell; highlighted = highlighted text;
    if(window.getSelection().toString()){
      this.saveSelection(); // using this to save then restore text selection after clicking checkboxes in context menu
      textToCopy = 'highlighted';
      focusedCellText = window.getSelection().toString();

      var parentElement = e.target;
      while(!parentElement.attributes.pasukId){
        parentElement = parentElement.parentElement;
      }
      console.log(e)
      focusedCellLocation = parentElement.attributes.nospacelocation.value;
      while(focusedCellLocation.indexOf("_") > -1){
        focusedCellLocation = focusedCellLocation.replace("_", " ");
      }
    } else {
      var parentElement = e.target;
      while(!parentElement.attributes.pasukId){
        parentElement = parentElement.parentElement;
      }
      this.setState({savedSelection: null})
      textToCopy = 'pasuk';
      if(columnClicked==="תרגום"){
        focusedCellText = parentElement.attributes.nospacetargumtext.value
      }else{
        focusedCellText = parentElement.attributes.nospacetext.value;
      }
      while(focusedCellText.indexOf("_") > -1){
        focusedCellText = focusedCellText.replace("_", " ");
      }
      focusedCellLocation = parentElement.attributes.nospacelocation.value;
      while(focusedCellLocation.indexOf("_") > -1){
        focusedCellLocation = focusedCellLocation.replace("_", " ");
      }
      console.log(focusedCellLocation)
    }

    this.setState({textToCopy, taamParserLocation: focusedCellLocation, contextMenuText: focusedCellText,wordToCopy:word})
  }
    // columnDefs = this.getColumnDefs();  //switched from ag grid to html table for easier editting

    // getColumnDefs(){ //switched from ag grid to html table for easier editting
    //   var fakeThis = this;

    //   var columnDefs = [
    //     {headerName: "פסוק", field: "location", width: this.getPasukColumWidth(), cellClass: "row-header context-results-row" },
    //   ]
    //   if(this.state){
    //     var totalTextColumns = 0;
    //     if(this.state.contentDisplayed.includes('tanach')) {
    //       totalTextColumns++;
    //     }
    //     if(this.state.contentDisplayed.includes('targum') && this.state.rowData[0].targum) {
    //       totalTextColumns++;
    //     }
    //     var textColumnWidth = (this.state.widthNum - 64 - this.getPasukColumWidth()) / totalTextColumns
    //     if(this.state.contentDisplayed.includes('tanach')){
    //       columnDefs.push(
    //         {headerName: "טקסט", field: "text", width: textColumnWidth, cellClass: "row-thin context-results-row", cellRenderer: function(params) {return fakeThis.cellRenderer(params, 'text');}},
    //       )
    //     }
    //     if(this.state.contentDisplayed.includes('targum')){
    //       columnDefs.push(
    //         {headerName: "תרגום", field: "targum", width: textColumnWidth, cellClass: "row-thin context-results-row", cellRenderer: function(params) {return fakeThis.cellRenderer(params, "targum");}} 
    //       )
    //     }

    //   }
    //   return columnDefs;
    // }

    setRowHeights(){
      const fakeThis = this
      var totalTextColumns = 0;
      if(this.state.contentDisplayed.includes('tanach')) {
        totalTextColumns++;
      }
      if(this.state.contentDisplayed.includes('targum') && this.state.rowData[0].targum) {
        totalTextColumns++;
      }
      var textColumnWidth = (this.props.widthNum - 68 - this.getPasukColumWidth()) / totalTextColumns
      this.contextApi.forEachNode( function (rowNode) {
        // console.log(rowNode)
        // console.log(rowNode.data.text)
        // console.log(rowNode.data.targum)
        // console.log(fakeThis.removeNikudAndTaamim(rowNode.data.text).length)
        // console.log(fakeThis.removeNikudAndTaamim(rowNode.data.targum).length)
        var tanachTextLength = fakeThis.removeNikudAndTaamim(rowNode.data.text).length;
        var targumTextLength = fakeThis.removeNikudAndTaamim(rowNode.data.targum).length;
        var longerLetters = tanachTextLength > targumTextLength ? tanachTextLength : targumTextLength;
        var gridFontSize = fakeThis.state.gridFontSize;
        var lettersPerLine = (textColumnWidth-24)/8;
        var linesPerCell = Math.ceil(longerLetters/lettersPerLine)
        var height = (longerLetters/lettersPerLine) * gridFontSize;
        var cellHeight = linesPerCell * (gridFontSize+13)
        console.log("cellHeight: " + cellHeight)
        console.log(fakeThis.state.gridRowHeight)

        //subtract 24 for cell padding
        //~8px per letter
        // rowNode.setRowHeight(fakeThis.state.gridRowHeight);
        rowNode.setRowHeight(cellHeight);
      })
    }

    //  cellRenderer(params, columnName){  //switched from ag grid to html table for easier editting
    //   var cellText = columnName === "text" ? params.data.text : params.data.targum;
    //   if(params.data.location === this.state.location){
    //     return `<b>${cellText}</b>`
    //   } else {
    //     return `${cellText}`
    //   }
    // }

    increaseFontSize(){
      if(this.state.fontSizeLevel < 6){
        this.setState({
          fontSizeLevel: this.state.fontSizeLevel + 1,
          gridFontSize: this.state.gridFontSize + 2,
          gridRowHeight: this.state.gridRowHeight + 3,
          pasukColumnWidth: this.state.pasukColumnWidth + 15,
        }, () => {
          this.setGridFontSize();
        })
      }
    }

     decreaseFontSize(){
         if(this.state.fontSizeLevel > 1){
             this.setState({
                fontSizeLevel: this.state.fontSizeLevel - 1,
                gridFontSize: this.state.gridFontSize - 2,
                gridRowHeight: this.state.gridRowHeight - 3,
                pasukColumnWidth: this.state.pasukColumnWidth - 15,
             }, () => {
                 this.setGridFontSize();
             })
         }
     }

     setGridFontSize(){
         // creating new stylesheet and adding new rule
         // Using document.getElementsByClassName() then changing the style property doesn't affect rows which haven't rendered yet
         var styleEl = document.createElement('style');
         document.head.appendChild(styleEl);
         var styleSheet = styleEl.sheet;

         styleSheet.insertRule('.context-results-row' + `{
                 font-size: ${this.state.gridFontSize}px !important;
             }`, styleSheet.cssRules.length);

         var fakeThis = this;
        //  if(this.contextApi){  //switched from ag grid to html table for easier editting
        //       this.contextApi.forEachNode( function (rowNode) {
        //           rowNode.setRowHeight(fakeThis.state.gridRowHeight);
        //       })
        //       this.contextApi.onRowHeightChanged();
        //  }

        // var newColumnDefs = this.getColumnDefs();  //switched from ag grid to html table for easier editting
        // this.setState({ columnDefs: newColumnDefs }, () => {
        //   // this.setRowHeights();
        // });
     }

    //  getPasukColumWidth(){  //switched from ag grid to html table for easier editting
    //      if(this.state){
    //          return this.state.pasukColumnWidth;
    //      } else {
    //          return 140
    //      }
    //  }

handleToggleTextDisplayType (event, textDisplayType) {
  if(textDisplayType){
    this.setState({textDisplayType}, () => {
      if(textDisplayType === 'table'){//remove ivri, stam; add tanach if nothing left
        var contentDisplayed = this.state.contentDisplayed;
        var stamIndex = contentDisplayed.indexOf('ktavStam')
        if(stamIndex > -1){
          contentDisplayed.splice(stamIndex, 1);
        }
        var ivriIndex = contentDisplayed.indexOf('ktavIvri')
        if(ivriIndex > -1){
          contentDisplayed.splice(ivriIndex, 1);
        }
        if(contentDisplayed.length === 0){
          contentDisplayed = ['tanach']
        }
        this.handleToggleContentDisplayed(null, contentDisplayed);
      } else if (textDisplayType === 'cloud') {
        this.handleToggleContentDisplayed(null, ['tanach']);
      }
    });
  }
}

handleToggleContentDisplayed (event, contentTypes) {
  if(contentTypes.length > 0){
    this.setState({contentDisplayed: contentTypes}, () => {
      // this.setState({ columnDefs: this.getColumnDefs() }, () => { //switched from ag grid to html table for easier editting
      //   // this.contextApi.setRowData(this.state.rowData)
      //   // this.setRowHeights()
      // })
    });
  }
}
clickToggleContentDisplayed(event, contentTypes){
  if(contentTypes.length > 0 && contentTypes.includes('targum') && !this.state.contentDisplayed.includes('targum')) this.props.extSetBeta();
  this.handleToggleContentDisplayed(event, contentTypes)
}

updateContextSize(e){
  var value = e.target.value;
  this.setState({contextSize: value}, () => {
    if(this.state.type){
      this.setState(this.state.oldState,()=>{this.setState({type:false})})
    }
    if(!this.state.isAtNamedSection){
      this.updatePasuk(this.state.location)
    }
  })
}

// resultsGridReady = params => {  //switched from ag grid to html table for easier editting
//   this.contextApi = params.api;
//   this.resultsColumnApi = params.columnApi;

//   var fakeThis = this
//   this.contextApi.forEachNode( function (rowNode) {
//     console.log(rowNode)
//     rowNode.setRowHeight(fakeThis.state.gridRowHeight);
//   })
//   this.contextApi.onRowHeightChanged();
// };

  async loadSefarim() {
    const url = properties.env.webServiceURL + "/BaseHaSefer/getSeferPerekInfo?source=bhsWebApp";
    const response = await fetch(url);
    var body = await response.json();

    var placementData = this.state.placementData;
    placementData.valList1 = body;

    this.setState({ val1: 0, val2: 0, val3: 0, valList1: body, placementData});
}

handleRangeChange1(event, val, callback) { //using callback to automatically fill in location for first load/navigation buttons
  var seferId = event.target.value;
   if (seferId == this.state.val1 && !callback) return false;
   this.loadList2(seferId, callback);
 }

handleRangeChange2(event, val, callback) { //using callback to automatically fill in location for first load/navigation buttons
   var perekNum = event.target.value; // gematria of perek letter
   if (perekNum == this.state.val2 && !callback) return false;
   this.loadList3(perekNum, callback);
 }

handleRangeChange3(event) {
  var pasukNum = event.target.value // gematria of pasuk letter
  if (pasukNum == this.state.val3) return false;
    var placementData = this.state.placementData;
    placementData.val3 = pasukNum;
    this.setState({ val3: pasukNum, placementData}, () => {
      this.loadSelectedPasuk();
    });
}

loadSelectedPasuk(){
  if(!(this.state.val3)){ // make sure a pasuk is selected before continuing (val3 is not 0 or null)
    this.setState({selectPasukMessageOpen: true});
  } else {
    var perekNum = this.state.val2;
    var pasukNum = this.state.val3;
    var seferObj = this.state.valList1.find(sefer => {
      return sefer.seferId === this.state.val1
    })
    var seferName = seferObj.seferName;
    var perekLetter = util.gematriya(perekNum, {punctuate: false});
    var pasukLetter = util.gematriya(pasukNum, {punctuate: false});

    var location = `${seferName}:${perekLetter}:${pasukLetter}`;
    if(location !== this.state.location){
      if(this.state.type){
        this.setState(this.state.oldState,()=>{this.setState({type:false},()=>{
          this.updatePasuk(location);
        })})
      }else{
        this.updatePasuk(location);
      }  
    }
  }
}

async loadList2(val, callback) {
  if(val === 0){
    var placementData = this.state.placementData;
    placementData.val1 = val;
    placementData.val2 = 0;
    placementData.val3 = 0;
    placementData.valList2 = [];
    placementData.valList3 = [];
    this.setState({ val1: val, val2: 0, val3: 0, valList2: [], valList3: [], placementData});
  } else {
    const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPerekInfo?source=bhsWebApp&seferId='+val);
    const body = await response.json();
    const perakim = body.map(function(e) {return {perekNum: e.perek, perekValue: util.gematriya(e.perek, {punctuate: false}), pesukim: e.pesukim }});
    var placementData = this.state.placementData;
    placementData.val1 = val;
    placementData.val2 = 0;
    placementData.val3 = 0;
    placementData.valList2 = perakim;
    placementData.valList3 = [];
    this.setState({ val1: val, val2: 0, val3: 0, valList2: perakim, valList3: [], placementData}, () => {
      if(callback){
        callback();
      }
    });
  }
}

async loadList3(val, callback) {
  if(val === 0){
    var placementData = this.state.placementData;
    placementData.val2 = 0;
    placementData.val3 = 0;
    placementData.valList3 = [];
    this.setState({ val2: 0, val3: 0, valList3: [], placementData});
  } else {
    const perek = this.state.valList2.find(function(e){return e.perekNum == val;});
    const body = Array(perek.pesukim).fill().map((x,i)=>({pasukNum: i+1, pasukValue: util.gematriya(i+1, {punctuate: false}) }));
    var placementData = this.state.placementData;
    placementData.val2 = val;
    placementData.val3 = 0;
    placementData.valList3 = body;
    this.setState({ val2: val, val3: 0, valList3: body, placementData}, () => {
      if(callback){
        callback();
      }
    });
  }
}


  async fetchContext(pasukId, seferId){
    if(pasukId < 1){
      pasukId = 1;
    } else if (pasukId > 23249){
      pasukId = 23249;
    }
    var url = properties.env.webServiceURL + '/BaseHaSefer/getPasukContext?source=bhsWebApp'
    if(typeof(pasukId) === 'number'){
      url += '&pasukId=' + pasukId;
    } else if (typeof(pasukId) === 'string') {
      url += '&location=' + pasukId;
    }
    if(!seferId){
      seferId = 0;
    }
    url += '&seferId=' + seferId;
    var contextSize = this.state.contextSize ? this.state.contextSize : "21";
    url += '&totalPesukim=' + contextSize;
    var response = await fetch(url);
    const body = await response.json();
    return body;
  }

  async updatePasuk(pasukIdorLocation, seferId){ // using seferId for nagivation purposes
    if(this.state.isLoading || this.state.type)return null
    this.setState({isLoading: true, loadedSectionVals: this.state.placementData
    })
    await this.fetchContext(pasukIdorLocation, seferId).then((pasukContextRows) => {
      var location;
      var pasukId;
      var pasukContentRow1 = pasukContextRows[0];
      var pasukContentRowN = pasukContextRows[pasukContextRows.length-1];
      if(typeof(pasukIdorLocation) === 'number'){
        pasukId = pasukIdorLocation;
        for(let i = 0; i < pasukContextRows.length; i++){
          var pasukContextRow = pasukContextRows[i]
          if(pasukContextRow.pasukId === pasukId){
            location = pasukContextRow.location;
          }
        }
        if(!location){ // using this for when user uses nav buttons near beginning/end of sefer
          if(pasukId < pasukContextRows[0].pasukId){
            pasukId = pasukContextRows[0].pasukId;
            location = pasukContextRows[0].location;
          } else if (pasukId > pasukContextRows[pasukContextRows.length-1].pasukId) {
            pasukId = pasukContextRows[pasukContextRows.length-1].pasukId;
            location = pasukContextRows[pasukContextRows.length-1].location;
          }
        }
      } else if (typeof(pasukIdorLocation) === 'string'){
        location = pasukIdorLocation
        for(let i = 0; i < pasukContextRows.length; i++){
          var pasukContextRow = pasukContextRows[i];
          if(pasukContextRow.location === location){
            pasukId = pasukContextRow.pasukId;
          }
        }
      }
      this.selectPasukByLocation(location)
      pasukContextRows = this.getRestructuredPasukContextRows(pasukContextRows);

      // this.getWordCloud().then((wordCloud) =>
          this.setState({
            rowData: pasukContextRows,
            pasukId: pasukId,
            location: location,
            isAtNamedSection: false,
            seferId1: pasukContentRow1.seferId, //the following six are used for Object Cloud boundary purposes
            perekNum1: pasukContentRow1.perekNum,
            pasukNum1: pasukContentRow1.pasukNum,
            seferId2: pasukContentRowN.seferId,
            perekNum2: pasukContentRowN.perekNum,
            pasukNum2: pasukContentRowN.pasukNum,},()=>{this.getWordCloud().then((wordCloud)=>this.setState({//moved this method call to here so the changes are made already
            wordCloudDiv: wordCloud,
            isLoading: false
          }, () => {
            this.setGridFontSize();
          }))}
      )
    })
  }

  getRestructuredPasukContextRows(pasukContextRows) {
    var newRows = pasukContextRows;
    for(let i = 0; i < newRows.length; i++){
      var currRow = newRows[i];
      //restructure targum map for each row but only if the row has both non-null info for targum and torah
      if(currRow && currRow.targumMap && currRow.torahMap){
        var torahMap = currRow.torahMap;
        var torahMapArr = torahMap.split(";")
        var torahWordMapObj = {};
        for(let i = 0; i < torahMapArr.length; i++){
          var torahMapWordArr = torahMapArr[i].split(",");
          if(!torahWordMapObj[torahMapWordArr[0]]) {
            torahWordMapObj[torahMapWordArr[0]] = [torahMapWordArr[1]];
          } else {
            torahWordMapObj[torahMapWordArr[0]].push(torahMapWordArr[1]);
          }
        }
        var targumMap = currRow.targumMap;
        var targumMapArr = targumMap.split(";")
        var targumWordMapObj = {};
        for(let i = 0; i < targumMapArr.length; i++){
          var targumMapWordArr = targumMapArr[i].split(",");
          if(!targumWordMapObj[targumMapWordArr[0]]) {
            targumWordMapObj[targumMapWordArr[0]] = [targumMapWordArr[1]];
          } else {
            targumWordMapObj[targumMapWordArr[0]].push(targumMapWordArr[1]);
          }
        }
  
        var torahPhraseMapObj = Object.assign({}, torahWordMapObj);
        var targumPhraseMapObj = Object.assign({}, targumWordMapObj);
        var torahPhraseMapObjKeys = Object.keys(torahPhraseMapObj);
        var targumPhraseMapObjKeys = Object.keys(targumPhraseMapObj);
  
        for(let i = 0; i < torahPhraseMapObjKeys.length; i++){
          var currKey = torahPhraseMapObjKeys[i];
          var matchIds = torahPhraseMapObj[currKey];
          if(matchIds.length > 1){
            var targumIndices = [];
            for(let j = 0; j < matchIds.length; j++){
              var matchId = matchIds[j]
              var targumIndex = Object.keys(targumPhraseMapObj).find(key => targumPhraseMapObj[key].includes(matchId));
              targumIndices.push(targumIndex)
            }
            for(let k = 0; k < targumIndices.length; k++){
              var currTargumIndex = targumIndices[k]
              targumPhraseMapObj[currTargumIndex] = targumPhraseMapObj[currTargumIndex].concat(matchIds.filter((item) => targumPhraseMapObj[currTargumIndex].indexOf(item) < 0))
            }
          }
        }
        for(let i = 0; i < targumPhraseMapObjKeys.length; i++){
          var currKey = targumPhraseMapObjKeys[i];
          var matchIds = targumPhraseMapObj[currKey];
          if(matchIds.length > 1){
            var torahIndices = [];
            for(let j = 0; j < matchIds.length; j++){
              var matchId = matchIds[j]
              var torahIndex = Object.keys(torahPhraseMapObj).find(key => torahPhraseMapObj[key].includes(matchId));
              torahIndices.push(torahIndex)
            }
            for(let k = 0; k < torahIndices.length; k++){
              var currTorahIndex = torahIndices[k]
              torahPhraseMapObj[currTorahIndex] = torahPhraseMapObj[currTorahIndex].concat(matchIds.filter((item) => torahPhraseMapObj[currTorahIndex].indexOf(item) < 0))
            }
          }
        }
  
        currRow.torahWordMapObj = torahWordMapObj;
        currRow.targumWordMapObj = targumWordMapObj;
        currRow.torahPhraseMapObj = torahPhraseMapObj;
        currRow.targumPhraseMapObj = targumPhraseMapObj;
      }

      //get pasukJump data
      var willShowSeferName = false;
      var willShowAliyaName = false;
      var isPasukJump = false;
      var isNamedSection = (typeof(currRow.seferId) === 'number') && // these vals are only passed for named sections
                           (typeof(currRow.seferSeqNum) === 'number') &&
                           (typeof(currRow.parshaSeqNum) === 'number')
      if(i === 0){
        willShowSeferName = true;
      } else {
        var prevRow = newRows[i - 1];
        if(prevRow.seferId !== currRow.seferId) {
          willShowSeferName = true;
        }
        if(isNamedSection) {
          //THIS WILL NOT CATCH JUMPS FROM THE MIDDLE OF ONE PARSHA TO THE BEGINNING OF THE ONE IMMEDIATELY AFTER
          isPasukJump = (currRow.seferId !== prevRow.seferId) || //different sefer OR
          //parsha out of sequence OR pasuk isn't 1st in the parsha OR
          (currRow.seferSeqNum != prevRow.seferSeqNum && //it's a different parsha AND
              (currRow.seferSeqNum - prevRow.seferSeqNum != 1 || currRow.parshaSeqNum != 1)) ||
          //same parsha but pasuk out of sequence
          (currRow.seferSeqNum == prevRow.seferSeqNum && currRow.parshaSeqNum - prevRow.parshaSeqNum != 1)
        }
      }
      if(typeof(currRow.aliya) === 'string' && currRow.seferId <= 5){
        if(i === 0){
          willShowAliyaName = true;
        } else if(prevRow.aliya !== currRow.aliya) {
          willShowAliyaName = true;
        }
      }
      currRow.willShowSeferName = willShowSeferName;
      currRow.willShowAliyaName = willShowAliyaName;
      currRow.isPasukJump = isPasukJump;
    }
    return newRows;
  }

  async getNextSection(){ //made this async in case in experimental mode and want to try out slide show feature for word cloud
    //var i;
    //var last = experimentalModeFlag ? 118 : 1;
    //for (i=1; i <= last; i++) {
        var middlePasuk = this.state.rowData[Math.floor(this.state.contextSize/2)];
        var nextMiddlePasukId = middlePasuk.pasukId + this.state.contextSize;
        var currSeferId = this.state.rowData[0].seferId;
        //if (experimentalModeFlag)
        //    util.sleep(1500); //give display time to breath for 1.5 seconds
        if(this.state.type){
          this.setState(this.state.oldState,()=>{this.setState({type:false},async()=>{
            await this.updatePasuk(nextMiddlePasukId, currSeferId);
          })})
        }
        if(!this.state.isAtNamedSection){    
          await this.updatePasuk(nextMiddlePasukId, currSeferId);
        }
    //}
  }

  async getPrevSection(){
    var middlePasuk = this.state.rowData[Math.floor(this.state.contextSize/2)];
    var prevMiddlePasukId = middlePasuk.pasukId - this.state.contextSize;
    var currSeferId = this.state.rowData[0].seferId;
    if(this.state.type){
      this.setState(this.state.oldState,()=>{this.setState({type:false},async()=>{
        await this.updatePasuk(prevMiddlePasukId, currSeferId);
      })})
    }
    if(!this.state.isAtNamedSection){
      await this.updatePasuk(prevMiddlePasukId, currSeferId);
    }
  }

  getParshaBreak(key, parshaSeqNum, isPasukJump){
    var parshaBreak = <></>;
    if(key !== 0 && parshaSeqNum === 1 && !isPasukJump){
      var parshaType = this.state.rowData[key].parshaType;
      if (parshaType === "פתוחה") {
        parshaBreak = <br />;
      } else if (parshaType === "סתומה") {
        parshaBreak = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
      }
    }
    return parshaBreak;
  }

  handleCopyLink() {
    var params = ["location=" + this.state.location];
    this.props.handleCopyLink("tanach", params, this.props.isFullScreen(this.props.x, this.props.y, this.props.heightNum,this.props.widthNum));
  }

  handleFullscreen(){
    this.props.handleFullscreen();
  }

  handleSplitScreen(){
    this.props.handleSplitScreen();
  }

  getAtbash(word){
    var atbashVal = 0;
    word = this.removeNikudAndTaamim(word);
    var atbashLetters = {
      "א": "ת",
      "ב": "ש",
      "ג": "ר",
      "ד": "ק",
      "ה": "צ",
      "ו": "פ",
      "ז": "ע",
      "ח": "ס",
      "ט": "נ",
      "י": "מ",
      "כ": "ל",
      "ך": "ל",
      "ל": "כ",
      "מ": "י",
      "ם": "י",
      "נ": "ט",
      "ן": "ט",
      "ס": "ח",
      "ע": "ז",
      "פ": "ו",
      "ף": "ו",
      "צ": "ה",
      "ץ": "ה",
      "ק": "ד",
      "ר": "ג",
      "ש": "ב",
      "ת": "א",
    }

    for(let i = 0; i < word.length; i++){
      var currLetter = word[i];
      var letterAtbashVal = Gematria(atbashLetters[currLetter]).toMisparHaPanim();
      atbashVal += letterAtbashVal;
    }
    return atbashVal;
  }

  async handleClose(){
    this.props.toggleTanachViewer(false);

    var valList1 = this.state.valList1;

    const wasNamedType = this.state.type;
    if(wasNamedType){//switch back to pasuk selector
      const url = properties.env.webServiceURL + "/BaseHaSefer/getSeferPerekInfo?source=bhsWebApp";

      const response = await fetch(url);
      const body = await response.json();
      valList1 = body;
    }

    var placementData = {type: false, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, kriaSequenceNum: 0, valList1: valList1, valList2: [], valList3: [], valList4: [], valList5: [], valList6: []};

    this.setState({
      type: false,
      rowData: [],
      val1: 0,
      val2: 0,
      val3: 0,
      valList1: valList1,
      placementData: placementData,
      isAtNamedSection: false,
      widthNum: 800,
      heightNum: 400,
      x: (window.innerWidth - 800) / 2,
      y: (window.innerHeight - 400) / 2,
      open:false,
    }, () =>{
      this.extModifyPlacementData(placementData);
    })
  }

  padWordCloudText(text, morphInflections) {
      var newText = '';
      for (let i = 0; i < text.length; i++)
          newText += (morphInflections == 'T' ? 'א' : 'ת') + text[i];
      return newText;
  }

  async getWordCloud(){
      var baseParams = new BaseParameters();
      baseParams.morphInflections = this.props.extSearchDefData;
      if (this.state.isAtNamedSection)
          baseParams.namedLocations.push(new NamedLocation(this.state.val4, this.state.kriaSequenceNum)); //kriaId, aliyaId
      else
          baseParams.pasukRangeLocations.push(new PerekPasukRange(this.state.seferId1, this.state.perekNum1, this.state.pasukNum1, this.state.seferId2, this.state.perekNum2, this.state.pasukNum2)); //int

      const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getFrequencies', {method: 'post', body: JSON.stringify(baseParams)});
      const serverData = await response.json();

      var cloudData = [];
      var wordCount = 0;

      if (baseParams.morphInflections != 'N' && baseParams.morphInflections != 'T' && baseParams.morphInflections != 'NT') {
          serverData.map((entry) => {
              //if (!experimentalModeFlag || ["אל", "על", "גם", "כי", "את", "כל", "אשר", "ואל", "ועל", "וגם", "וכי", "ואת", "וכל", "ואשר"].indexOf(entry.testField) < 0) { //extra test if in experimentalMode to exclude common words
                  cloudData.push({
                    text: entry.testField,
                    value: entry.totalAppearances
                  })
                  wordCount += entry.totalAppearances;
              //}
          })
      }
      else {
          serverData.map((entry) => {
              cloudData.push({
                text: this.padWordCloudText(entry.testField, baseParams.morphInflections),
                value: entry.totalAppearances
              })
              wordCount += entry.totalAppearances;
          })
      }

    const wordCloud = <ObjectCloud
        data={cloudData}
        height={this.props.heightNum - 150}
        width={this.props.widthNum - 100}
        //transitionDuration={experimentalModeFlag ? 1500 : null} //might want to run slide show mode where 1.5 second transition is better
      />;

    return <div className="object-cloud-parent" style={{margin: "auto"}}>{wordCloud}</div>; //avoid rerendering caused by setState
  }

  async componentDidMount() {
    var fakeThis = this
    await this.loadSefarim()
      // var newColumnDefs = this.getColumnDefs()  //switched from ag grid to html table for easier editting
      // this.setState({ columnDefs: newColumnDefs });

  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.isOpen && !prevProps.isOpen){
      this.showOnTop();

      if (this.props.isFirstTimeOpened && this.props.initialFullScreen && "TY".indexOf(this.props.initialFullScreen.toUpperCase()) >= 0) {
          this.handleFullscreen();
          this.props.extSetFirstTimeOpened();
      }

      this.updatePasuk(this.props.pasukId)
    } else if (this.props.isOpen && prevProps.pasukId !== this.props.pasukId) {
      if(this.state.type){
        this.setState(this.state.oldState,()=>{this.setState({type:false},()=>{
          this.updatePasuk(this.props.pasukId)
        })})
      }else{
        this.updatePasuk(this.props.pasukId)
      }
  
    }
    if(this.props.isOpen && !prevProps.isOpen){
      this.setState({open:true})
    }else{
      if(!this.props.isOpen && prevProps.isOpen){
        this.setState({open:false})
      }
    }
    if(this.state.rowData[0] && this.state.rowData[0].targum !== null && this.props.isJustOpened ){
      var contentDisplayed = this.state.contentDisplayed;
      if(this.props.columnClicked==="תרגום"){    
        if(!contentDisplayed.includes("targum")){
          contentDisplayed.push("targum")
          
        }
      }else if(this.props.columnClicked==="טקסט"){
        if(!contentDisplayed.includes("tanach")){
          contentDisplayed.push("tanach")
        }
      }
      this.props.extSetJustOpened();
      this.handleToggleContentDisplayed(null,contentDisplayed)

    }else if(this.state.contentDisplayed.length===0){
      this.setState({contentDisplayed:['tanach']})
    }
  }


  render() {
    const { classes } = this.props;
    const fakeThis = this;
    const isHebrew = this.props.activeLanguage && this.props.activeLanguage.code === 'he';

     const close = this.props.translate('close');
     const tanachViewer = this.props.translate('tanachViewer');
     const increaseFont = this.props.translate("increaseFont.caption");
     const decreaseFont = this.props.translate("decreaseFont.caption");
     const nextSection = this.props.translate("nextSection");
     const prevSection = this.props.translate("previousSection");
     const sel = this.props.translate("select");
     const loadingText = this.props.translate("loading");
     const tanach = this.props.translate("tanach");
     const targum = this.props.translate("targum");
     const ktavStam = this.props.translate("ktavStam");
     const pesukimToDisplay = this.props.translate("pesukimToDisplay");
     const stream = this.props.translate("stream");
     const table = this.props.translate("table");
     const nextResult = this.props.translate("nextResult");
     const prevResult = this.props.translate("previousResult");
     const fullScreen = this.props.translate("fullScreen");
     const splitScreen = this.props.translate("splitScreen");
     const copyLink = this.props.translate('copyLink');
     const wordCloud = this.props.translate("wordCloud");

     const list1 = this.state.valList1;
     const list2 = this.state.valList2;
     const list3 = this.state.valList3;

     //todo - there are named sections to be displayed in Tanach Viewer where some pesukim have Targum (eg. Chumash) and some not (eg. Tehillim)
     //therefore logic below created in late 2019/early 2020 should really analyze targum presence on a pasuk by pasuk basis as opposed to assuming everything from the first pasuk
     //there are individual cases below where an IF check against hasTargum has been replaced with a check against item.targum which will be false if null
     var hasTargum = (this.state.rowData[0] && this.state.rowData[0].targum !== null);
     var contentDisplayed = this.state.contentDisplayed;
     if(!hasTargum && contentDisplayed.includes('targum')){
       var targumIndex = contentDisplayed.indexOf('targum');
       contentDisplayed.splice(targumIndex, 1);
     }

     var targumIsOpen = contentDisplayed.includes('targum');
     var tanachIsOpen = contentDisplayed.includes('tanach');

     var hoverPasukHighlightColor = 'powderblue';
     var hoverWordHighlightColor = 'deepskyblue';
     var isMultiPanel = this.state.contentDisplayed.length > 1;

    const isFirstPasuk = (this.state.rowData[0] && this.state.rowData[0].pasukId === this.state.pasukId);
    const isLastPasuk = (this.state.rowData[this.state.contextSize-1] && this.state.rowData[this.state.contextSize-1].pasukId === this.state.pasukId);

    const popupHeightNum = this.props.heightNum
    var dialogContentHeight = popupHeightNum - 132;

     var directionStyling = {direction: 'ltr', textAlign: 'left'}
     if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
         directionStyling = {direction: 'rtl', textAlign: 'right'}
     }

     var increaseFontButton = <Button aria-label={increaseFont} title={increaseFont} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.increaseFontSize() }}>
                                     <img src={require("./images/icons8-increase-font-52.png")} alt={increaseFont} width="20" height="20" />
                                 </Button>
       var decreaseFontButton = <Button aria-label={decreaseFont} title={decreaseFont} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.decreaseFontSize() }}>
                                     <img src={require("./images/icons8-decrease-font-52.png")} alt={decreaseFont} width="20" height="20" />
                                 </Button>
       if(this.state.fontSizeLevel === 6){
           increaseFontButton = <Button className='disabled-font-icon' disabled aria-label={increaseFont} title={increaseFont} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.increaseFontSize() }}>
                                     <img src={require("./images/icons8-increase-font-52.png")} alt={increaseFont} width="20" height="20" />
                                 </Button>
       }
       if(this.state.fontSizeLevel === 1){
           decreaseFontButton = <Button className='disabled-font-icon' disabled aria-label={decreaseFont} title={decreaseFont} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.decreaseFontSize() }}>
                                     <img src={require("./images/icons8-decrease-font-52.png")} alt={decreaseFont} width="20" height="20" />
                                 </Button>
       }

      var textDisplay = <></>
      if(this.state.textDisplayType === "table"){
        //not using AgGrid here to make it easier to set up multi-line cells and word-to-word hovering
        var pasukRows = !this.state ? <></> : <>
          {this.state.rowData.map(function(currPasuk, key){

            var noSpaceLocation = currPasuk.location;
            while(noSpaceLocation.includes(" ")){
              noSpaceLocation = noSpaceLocation.replace(" ", "_");
            }
            var noSpaceText = currPasuk.text;
            while(noSpaceText.includes(" ")){
              noSpaceText = noSpaceText.replace(" ", "_");
            }


            var noSpaceTargumText = currPasuk.targum != null ? currPasuk.targum : "";
            while(noSpaceTargumText.includes(" ")){
              noSpaceTargumText = noSpaceTargumText.replace(" ", "_");
            }

            var makorArray = currPasuk.location.split(':');
            var perekPasuk = `${makorArray[1]}:${makorArray[2]}`
            var seferPerekPasuk = `${makorArray[0]} ${makorArray[1]}:${makorArray[2]}`


            var text = currPasuk.text;
            var words = text.split(" ");
            var torahMapObj = targumIsOpen ? currPasuk.torahPhraseMapObj : currPasuk.torahWordMapObj;
            var wordSpans = words.map((word, key) => {
              var matchIds = [];
              if(torahMapObj){
                matchIds = torahMapObj[key+1];
              }
              if(!matchIds){
                matchIds = [];
              }
              if(!currPasuk.targum){
                matchIds = [key];
              }
              var isHoveredMatch = false;
              matchIds.map((matchId, key) => {
                if(fakeThis.state.hoveredMatchIds && fakeThis.state.hoveredMatchIds.includes(matchId)){// && fakeThis.state.hoveredLocation === currPasuk.location){
                  isHoveredMatch = true;
                }
              })
              //IF TARGUM VISIBLE - HIGHLIGHT GROUPS, OTHERWISE WORD BY WORD
              return  <span
                        matchIds={matchIds}
                        onMouseEnter={(e) => {fakeThis.setHoveredMatchIds(matchIds)}}
                        onMouseLeave={(e) => {fakeThis.setHoveredMatchIds(null)}}
                        style={{
                          backgroundColor: `${isHoveredMatch && isMultiPanel ? hoverWordHighlightColor : 'unset'}`
                        }}
                        onContextMenu={(e)=>fakeThis.onCellContextMenu(e,"תנך",word)}
                        className='text-cell-pasuk-span'
                      >
                        {word + " "}
                      </span>
            })

            var targumText = currPasuk.targum != null ? currPasuk.targum : "";
            var words = targumText.split(" ");
            var targumMapObj = currPasuk.targumPhraseMapObj;
            var targumWordSpans = words.map((word, key) => {
              var matchIds = [];
              if(targumMapObj){
                matchIds = targumMapObj[key+1];
              }
              if(!matchIds){
                matchIds = [];
              }
              var isHoveredMatch = false;
              matchIds.map((matchId, key) => {
                if(fakeThis.state.hoveredMatchIds && fakeThis.state.hoveredMatchIds.includes(matchId)){
                  isHoveredMatch = true;
                }
              })
             //IF TARGUM VISIBLE - HIGHLIGHT GROUPS, OTHERWISE WORD BY WORD
              return  <span
                        matchIds={matchIds}
                        onMouseEnter={(e) => {fakeThis.setHoveredMatchIds(matchIds)}}
                        onMouseLeave={(e) => {fakeThis.setHoveredMatchIds(null)}}
                        style={{
                          backgroundColor: `${isHoveredMatch && isMultiPanel ? hoverWordHighlightColor : 'unset'}`
                        }}
                        onContextMenu={(e)=>fakeThis.onCellContextMenu(e,"תרגום",word)}
                        className='text-cell-pasuk-span'
                      >
                        {word + " "}
                      </span>
            })


            var tanachText = <span
              onMouseEnter={(e) => {fakeThis.setHoveredLocation(currPasuk.location)}}
              onMouseLeave={(e) => {fakeThis.setHoveredLocation(null)}}
              style={{
                backgroundColor: `${fakeThis.state.hoveredLocation === currPasuk.location && isMultiPanel ? hoverPasukHighlightColor : 'unset'}`
              }}
            >{wordSpans}</span>;
            var targumText = <span
              onMouseEnter={(e) => {fakeThis.setHoveredLocation(currPasuk.location)}}
              onMouseLeave={(e) => {fakeThis.setHoveredLocation(null)}}
              style={{
                backgroundColor: `${fakeThis.state.hoveredLocation === currPasuk.location && isMultiPanel ? hoverPasukHighlightColor : 'unset'}`
              }}
            >{targumWordSpans}</span>;
            if(currPasuk.location === fakeThis.state.location){
              tanachText = <b>{tanachText}</b>;
              targumText = <b>{targumText}</b>;
            }


            var pasukRow =  <tr key={key} pasukId={currPasuk.pasukId} class='text-cell-pasuk-row' noSpaceText={noSpaceText} noSpaceTargumText={noSpaceTargumText} noSpaceLocation={noSpaceLocation} >
                              <td class="tanach-viewer-text-table-makor" 
                              onContextMenu={(e) => {if(!fakeThis.state.hoveredMatchIds)fakeThis.onCellContextMenu(e)}} 
                              location={noSpaceLocation} >
                                {currPasuk.willShowAliyaName ? `[${currPasuk.aliya}] ` : ``}
                                <span
                                  style={{
                                    color : `${currPasuk.isPasukJump ? 'blue' : 'black'}`
                                  }}
                                >{currPasuk.willShowSeferName ? seferPerekPasuk : perekPasuk}</span>
                              </td>
                              {tanachIsOpen && 
                                <td class="parsha-viewer-text-table-text" 
                                onContextMenu={(e) => {if(!fakeThis.state.hoveredMatchIds)fakeThis.onCellContextMenu(e,"תנך")}} 
                                >{tanachText}</td>
                              }
                              {hasTargum && targumIsOpen && 
                                <td class="parsha-viewer-text-table-text" 
                                onContextMenu={(e) => {if(!fakeThis.state.hoveredMatchIds)fakeThis.onCellContextMenu(e,"תרגום")}}
                                 >{targumText}</td>
                              }
                            </tr>;
              return pasukRow
          })}
        </>
        var firstNoSpaceLocation;
        var firstNoSpaceText;
        var firstNoSpaceTargumText
        var firstPasukId;
        if(this.state.rowData[0]){
          var firstPasuk = this.state.rowData[0];
          firstNoSpaceLocation = firstPasuk.location;
          while(firstNoSpaceLocation.includes(" ")){
            firstNoSpaceLocation = firstNoSpaceLocation.replace(" ", "_");
          }
          firstNoSpaceText = firstPasuk.text;
          while(firstNoSpaceText.includes(" ")){
            firstNoSpaceText = firstNoSpaceText.replace(" ", "_");
          }

          firstNoSpaceTargumText = firstPasuk.targum != null ? firstPasuk.targum : "";
          while(firstNoSpaceTargumText.includes(" ")){
            firstNoSpaceTargumText = firstNoSpaceTargumText.replace(" ", "_");
          }

          firstPasukId = firstPasuk.pasukId
        }

        var cellContents = <table class="tanach-viewer-text-table"
          style={{
            fontSize: `${(this.state.fontSizeLevel * 2) + 12}px`
          }}
        > 
          <thead pasukId={firstPasukId} class='text-cell-pasuk-row' noSpaceText={firstNoSpaceText} noSpaceTargumText={firstNoSpaceTargumText} noSpaceLocation={firstNoSpaceLocation} >
            <tr class='text-cell-pasuk-row'>
              <th class="tanach-viewer-text-table-header" onContextMenu={(e) => {fakeThis.onCellContextMenu(e)}}>פסוק</th>
              {tanachIsOpen && 
                <th class="tanach-viewer-text-table-header" onContextMenu={(e) => {fakeThis.onCellContextMenu(e)}}>טקסט</th>
              }
              {hasTargum && targumIsOpen && 
                <th class="tanach-viewer-text-table-header" onContextMenu={(e) => {fakeThis.onCellContextMenu(e,"תרגום")}}>תרגום</th>
              }
            </tr>
          </thead>
          <tbody>
            {pasukRows}
          </tbody>
        </table>
        textDisplay =               <MenuProvider className="context-menu" id='tanach-viewer-table-context-menu' > 
                                      {cellContents}
                                            {/* <div
                                             className="ag-theme-balham context-results-table"
                                             style={{
                                               height: `${(this.state.rowData.length * (this.state.gridRowHeight+2) - 8)}px`,
                                               width: `${this.state.widthNum - 45}px`,
                                               resize: "both",
                                               overflow: 'auto',
                                             }}
                                            >
                                               <AgGridReact
                                                   className='context-results-table'
                                                   onGridReady={this.resultsGridReady}
                                                   columnDefs={this.state.columnDefs}
                                                   rowData={this.state.rowData}
                                                   enableFilter={true}
                                                   enableColResize={true}
                                                   rowMultiSelectWithClick={false}
                                                   onCellContextMenu={(e) => {fakeThis.onCellContextMenu(e)}}
                                                   frameworkComponents={this.state.frameworkComponents}
                                                   overlayNoRowsTemplate={'<span style="font-size:28px; color: dodgerblue;"><i>אין תוצאות</i></span>'}
                                                   enableRtl="true">
                                               </AgGridReact>
                                           </div> */}
                                          </MenuProvider>
       } else if (this.state.textDisplayType === "stream") {
            var tanachTextStream = this.state.rowData.map((item, key) => {
                            var noSpaceLocation = item.location;
                            while(noSpaceLocation.includes(" ")){
                              noSpaceLocation = noSpaceLocation.replace(" ", "_");
                            }
                            var noSpaceText = item.text;
                            while(noSpaceText.includes(" ")){
                              noSpaceText = noSpaceText.replace(" ", "_");
                            }
                            var makorArray = item.location.split(':');
                            var perekPasuk = `${makorArray[1]}:${makorArray[2]}`
                            var seferPerekPasuk = `${makorArray[0]} ${makorArray[1]}:${makorArray[2]}`

                            var streamFontSize = `${(this.state.fontSizeLevel * 2) + 16}px`; // font size ranges from 18 to 28

                            var text = item.text;
                            var words = text.split(" ");
                            var torahMapObj = targumIsOpen ? item.torahPhraseMapObj : item.torahWordMapObj;
                            var wordSpans = words.map((word, key) => {
                              var matchIds = [];
                              if(torahMapObj){
                                matchIds = torahMapObj[key+1];
                              }
                              if(!matchIds){
                                matchIds = [];
                              }
                              if(!item.targum){
                                matchIds = [key];
                              }
                              var isHoveredMatch = false;
                              matchIds.map((matchId, key) => {
                                if(this.state.hoveredMatchIds && this.state.hoveredMatchIds.includes(matchId) && this.state.hoveredLocation === item.location){
                                  isHoveredMatch = true;
                                }
                              })
                              //IF TARGUM VISIBLE - HIGHLIGHT GROUPS, OTHERWISE WORD BY WORD
                              return  <span
                                        matchIds={matchIds}
                                        onMouseEnter={(e) => {this.setHoveredMatchIds(matchIds)}}
                                        onMouseLeave={(e) => {this.setHoveredMatchIds(null)}}
                                        style={{
                                          backgroundColor: `${isHoveredMatch && isMultiPanel ? hoverWordHighlightColor : 'unset'}`
                                        }}
                                        onContextMenu={(e)=>fakeThis.onStreamContextMenu(e,"תנך",word)}
                                        className='text-cell-pasuk-span'
                                      >
                                        {word + " "}
                                      </span>
                            })

                            var parshaBreak = this.getParshaBreak(key, item.parshaSeqNum, item.isPasukJump)

                             return <> 
                                      {item.isPasukJump ? <><br /> <br /></> : <></>}
                                      <span
                                        className='text-cell-pasuk-span'
                                        onMouseEnter={(e) => {this.setHoveredLocation(item.location)}}
                                        onMouseLeave={(e) => {this.setHoveredLocation(null)}}
                                        location={noSpaceLocation}
                                        text={noSpaceText}
                                        pasukid={item.pasukId}
                                        style={{
                                          fontSize: streamFontSize,
                                          backgroundColor: `${this.state.hoveredLocation === item.location && isMultiPanel ? hoverPasukHighlightColor : 'unset'}`
                                        }} >
                                          {parshaBreak}
                                          <span>
                                            <span><b>{`${item.willShowAliyaName ? `[${item.aliya}] ` : ""}`}</b></span>
                                            <span
                                              style={{
                                                color : `${item.isPasukJump ? 'blue' : 'black'}`
                                              }}
                                            ><b>({`${item.willShowSeferName ? seferPerekPasuk : perekPasuk}`})</b></span>
                                            <span> {[item.pasukId, item.location].includes(this.state.pasukId) ? <b>{wordSpans}</b> : wordSpans} </span>
                                        </span>
                                     </span>
                                    </>
                          });
            var targumTextStream = null;
            if(hasTargum){
              targumTextStream = this.state.rowData.map((item, key) => {
                var noSpaceLocation = item.location;
                while(noSpaceLocation.includes(" ")){
                     noSpaceLocation = noSpaceLocation.replace(" ", "_");
                }
                var noSpaceTargum = item.targum;
                while(noSpaceTargum && noSpaceTargum.includes(" ")){
                     noSpaceTargum = noSpaceTargum.replace(" ", "_");
                }
                var makorArray = item.location.split(':');
                var perekPasuk = `${makorArray[1]}:${makorArray[2]}`
                var seferPerekPasuk = `${makorArray[0]} ${makorArray[1]}:${makorArray[2]}`

                var streamFontSize = `${(this.state.fontSizeLevel * 2) + 16}px`; // font size ranges from 18 to 28

                var text = item.targum;
                var words = [];
                if (text)
                    words = text.split(" ");
                var targumMapObj = item.targumPhraseMapObj;
                var wordSpans = words.map((word, key) => {
                  var matchIds = [];
                  if(targumMapObj){
                    matchIds = targumMapObj[key+1];
                  }
                  if(!matchIds){
                    matchIds = [];
                  }
                  var isHoveredMatch = false;
                  matchIds.map((matchId, key) => {
                    if(this.state.hoveredMatchIds && this.state.hoveredMatchIds.includes(matchId)){
                      isHoveredMatch = true;
                    }
                  })
                 //IF TARGUM VISIBLE - HIGHLIGHT GROUPS, OTHERWISE WORD BY WORD
                  return  <span
                            matchIds={matchIds}
                            onMouseEnter={(e) => {this.setHoveredMatchIds(matchIds)}}
                            onMouseLeave={(e) => {this.setHoveredMatchIds(null)}}
                            style={{
                              backgroundColor: `${isHoveredMatch && isMultiPanel ? hoverWordHighlightColor : 'unset'}`
                            }}
                            onContextMenu={(e)=>fakeThis.onStreamContextMenu(e,"תרגום",word)}
                            className='text-cell-pasuk-span'
                          >
                            {word + " "}
                          </span>
                })
                var parshaBreak = this.getParshaBreak(key, item.parshaSeqNum, item.isPasukJump)

                return  <>
                          {item.isPasukJump ? <><br /> <br /></> : <></>}
                          <span
                            className='text-cell-pasuk-span'
                            onMouseEnter={(e) => {this.setHoveredLocation(item.location)}}
                            onMouseLeave={(e) => {this.setHoveredLocation(null)}}
                            location={noSpaceLocation}
                            text={noSpaceTargum}
                            pasukid={item.pasukId}
                            style={{
                              fontSize: streamFontSize,
                              backgroundColor: `${this.state.hoveredLocation === item.location && isMultiPanel ? hoverPasukHighlightColor : 'unset'}`
                            }} >
                              {parshaBreak}
                              <span>
                                <span><b>{`${item.willShowAliyaName ? `[${item.aliya}] ` : ""}`}</b></span>
                                <span
                                  style={{
                                    color : `${item.isPasukJump ? 'blue' : 'black'}`
                                  }}
                                ><b>({item.willShowSeferName ? seferPerekPasuk : perekPasuk})</b></span>
                                <span> {[item.pasukId, item.location].includes(this.state.pasukId) ? <b>{wordSpans}</b> : wordSpans} </span>
                              </span>
                            </span>
                          </>
             });
            }
            var stamTextStream = this.state.rowData.map((item, key) => {
                            var noSpaceLocation = item.location;
                            while(noSpaceLocation.includes(" ")){
                                 noSpaceLocation = noSpaceLocation.replace(" ", "_");
                            }
                            var noSpaceText = item.text;
                            while(noSpaceText.includes(" ")){
                                 noSpaceText = noSpaceText.replace(" ", "_");
                            }
                            var makorArray = item.location.split(':');
                            var perekPasuk = `${makorArray[1]}:${makorArray[2]}`

                            var streamFontSize = `${(this.state.fontSizeLevel * 2) + 16}px`; // font size ranges from 18 to 28

                            var text = item.text;
                            var words = text.split(" ");
                            var torahMapObj = targumIsOpen ? item.torahPhraseMapObj : item.torahWordMapObj;
                            var wordSpans = words.map((word, key) => {
                              var matchIds = [];
                              if(torahMapObj){
                                matchIds = torahMapObj[key+1];
                              }
                              if(!matchIds){
                                matchIds = [];
                              }
                              if(!item.targum){
                                matchIds = [key];
                              }
                              var isHoveredMatch = false;
                              matchIds.map((matchId, key) => {
                                if(this.state.hoveredMatchIds && this.state.hoveredMatchIds.includes(matchId) && this.state.hoveredLocation === item.location){
                                  isHoveredMatch = true;
                                }
                              })
                             //IF TARGUM VISIBLE - HIGHLIGHT GROUPS, OTHERWISE WORD BY WORD
                              return  <span
                                       matchIds={matchIds}
                                       onMouseEnter={(e) => {this.setHoveredMatchIds(matchIds)}}
                                       onMouseLeave={(e) => {this.setHoveredMatchIds(null)}}
                                       style={{
                                         backgroundColor: `${isHoveredMatch && isMultiPanel ? hoverWordHighlightColor : 'unset'}`
                                       }}
                                       onContextMenu={(e)=>fakeThis.onStreamContextMenu(e,"סתם",word)}
                                       className='text-cell-pasuk-span'
                                     >
                                       {this.removeNikudAndTaamim(word) + " "}
                                     </span>
                            })
                            var parshaBreak = this.getParshaBreak(key, item.parshaSeqNum, item.isPasukJump)

                            return  <> 
                                      {item.isPasukJump ? <><br />
                                                          <span
                                                            style={{
                                                              fontSize: `${((this.state.fontSizeLevel * 2) + 16)*2.5}px`,
                                                              lineHeight: 0
                                                            }}
                                                          >
                                                            ...
                                                          </span> 
                                                          <br /></>
                                                          : <></>}
                                      <span
                                        className='text-cell-pasuk-span'
                                        onMouseEnter={(e) => {this.setHoveredLocation(item.location)}}
                                        onMouseLeave={(e) => {this.setHoveredLocation(null)}}
                                        location={noSpaceLocation}
                                        text={noSpaceText}
                                        pasukid={item.pasukId}
                                        style={{
                                          fontSize: streamFontSize,
                                          backgroundColor: `${this.state.hoveredLocation === item.location && isMultiPanel ? hoverPasukHighlightColor : 'unset'}`
                                        }} >
                                          {parshaBreak}
                                          <span>
                                            <span>{wordSpans} </span>
                                          </span>
                                        </span>
                                      </>
                         });

            var ivriTextStream = this.state.rowData.map((item, key) => {
                          var noSpaceLocation = item.location;
                          while(noSpaceLocation.includes(" ")){
                               noSpaceLocation = noSpaceLocation.replace(" ", "_");
                          }
                          var noSpaceText = item.text;
                          while(noSpaceText.includes(" ")){
                               noSpaceText = noSpaceText.replace(" ", "_");
                          }
                          var makorArray = item.location.split(':');
                          var perekPasuk = `${makorArray[1]}:${makorArray[2]}`

                          var streamFontSize = `${(this.state.fontSizeLevel * 2) + 16}px`; // font size ranges from 18 to 28
                          var ivriPaddingTop = `${(this.state.fontSizeLevel*(3/5))+(37/5)}px`;
                          var ivriPaddingBottom = `${(this.state.fontSizeLevel*(3/5))+(2/5)}px`;

                          var text = item.text;
                          var words = text.split(" ");
                          var torahMapObj = targumIsOpen ? item.torahPhraseMapObj : item.torahWordMapObj;
                          var wordSpans = words.map((word, key) => {
                            var matchIds = [];
                            if(torahMapObj){
                              matchIds = torahMapObj[key+1];
                            }
                            if(!matchIds){
                              matchIds = [];
                            }
                            if(!item.targum){
                              matchIds = [key];
                            }
                            var isHoveredMatch = false;
                            matchIds.map((matchId, key) => {
                              if(this.state.hoveredMatchIds && this.state.hoveredMatchIds.includes(matchId) && this.state.hoveredLocation === item.location){
                                isHoveredMatch = true;
                              }
                            })

                           //IF TARGUM VISIBLE - HIGHLIGHT GROUPS, OTHERWISE WORD BY WORD
                            return  <span
                                     matchIds={matchIds}
                                     onMouseEnter={(e) => {this.setHoveredMatchIds(matchIds)}}
                                     onMouseLeave={(e) => {this.setHoveredMatchIds(null)}}
                                     style={{
                                       backgroundColor: `${isHoveredMatch && isMultiPanel ? hoverWordHighlightColor : 'unset'}`,
                                       paddingTop: ivriPaddingTop,
                                       paddingBottom: ivriPaddingBottom
                                     }}
                                     onContextMenu={(e)=>fakeThis.onStreamContextMenu(e,"עברי",word)}
                                     className='text-cell-pasuk-span ivri-word-span'
                                   >
                                     {this.removeNikudAndTaamim(word) + " "}
                                   </span>
                          })
                          var parshaBreak = this.getParshaBreak(key, item.parshaSeqNum, item.isPasukJump)

                          return  <> 
                                    {item.isPasukJump ? <><br />
                                                        <span
                                                          style={{
                                                            fontSize: `${((this.state.fontSizeLevel * 2) + 16)*2.5}px`,
                                                            lineHeight: 0
                                                          }}
                                                        >
                                                          ...
                                                        </span> 
                                                        <br /></>
                                                        : <></>}
                                    <span
                                      className='text-cell-pasuk-span ivri-pasuk-span'
                                      onMouseEnter={(e) => {this.setHoveredLocation(item.location)}}
                                      onMouseLeave={(e) => {this.setHoveredLocation(null)}}
                                      location={noSpaceLocation}
                                      text={noSpaceText}
                                      pasukid={item.pasukId}
                                      style={{
                                        fontSize: streamFontSize,
                                        backgroundColor: `${this.state.hoveredLocation === item.location && isMultiPanel ? hoverPasukHighlightColor : 'unset'}`,
                                        paddingTop: ivriPaddingTop,
                                        paddingBottom: ivriPaddingBottom
                                      }} >
                                        {parshaBreak}
                                        <span>
                                          <span>{wordSpans} </span>
                                        </span>
                                      </span>
                                    </>
                       });


            var gematriaTextStream = this.state.rowData.map((item, key) => {
                        var noSpaceLocation = item.location;
                        while(noSpaceLocation.includes(" ")){
                          noSpaceLocation = noSpaceLocation.replace(" ", "_");
                        }
                        var noSpaceText = item.text;
                        while(noSpaceText.includes(" ")){
                          noSpaceText = noSpaceText.replace(" ", "_");
                        }
                        var makorArray = item.location.split(':');
                        var perekPasuk = `${makorArray[1]}:${makorArray[2]}`
                        var seferPerekPasuk = `${makorArray[0]} ${makorArray[1]}:${makorArray[2]}`

                        var streamFontSize = `${(this.state.fontSizeLevel * 2) + 16}px`; // font size ranges from 18 to 28

                        var text = item.text;
                        var words = text.split(" ");
                        var torahMapObj = targumIsOpen ? item.torahPhraseMapObj : item.torahWordMapObj;
                        var wordSpans = words.map((word, key) => {
                          var matchIds = [];
                          if(torahMapObj){
                            matchIds = torahMapObj[key+1];
                          }
                          if(!matchIds){
                            matchIds = [];
                          }
                          if(!item.targum){
                            matchIds = [key];
                          }
                          var isHoveredMatch = false;
                          matchIds.map((matchId, key) => {
                            if(this.state.hoveredMatchIds && this.state.hoveredMatchIds.includes(matchId) && this.state.hoveredLocation === item.location){
                              isHoveredMatch = true;
                            }
                          })

                          var gematria = Gematria(this.removeNikudAndTaamim(word)).toMisparHaPanim();
                          //IF TARGUM VISIBLE - HIGHLIGHT GROUPS, OTHERWISE WORD BY WORD
                          return  <span
                                    matchIds={matchIds}
                                    onMouseEnter={(e) => {this.setHoveredMatchIds(matchIds)}}
                                    onMouseLeave={(e) => {this.setHoveredMatchIds(null)}}
                                    style={{
                                      backgroundColor: `${isHoveredMatch && isMultiPanel ? hoverWordHighlightColor : 'unset'}`
                                    }}
                                    onContextMenu={(e)=>fakeThis.onStreamContextMenu(e,"גמטריא",gematria)}
                                    className='text-cell-pasuk-span'
                                  >
                                    {gematria + " "}
                                  </span>
                        })

                        var parshaBreak = this.getParshaBreak(key, item.parshaSeqNum, item.isPasukJump)

                         return <> 
                                  {item.isPasukJump ? <><br /> <br /></> : <></>}
                                  <span
                                    className='text-cell-pasuk-span'
                                    onMouseEnter={(e) => {this.setHoveredLocation(item.location)}}
                                    onMouseLeave={(e) => {this.setHoveredLocation(null)}}
                                    location={noSpaceLocation}
                                    text={noSpaceText}
                                    pasukid={item.pasukId}
                                    style={{
                                      fontSize: streamFontSize,
                                      backgroundColor: `${this.state.hoveredLocation === item.location && isMultiPanel ? hoverPasukHighlightColor : 'unset'}`
                                    }} >
                                      {parshaBreak}
                                      <span>
                                        <span><b>{`${item.willShowAliyaName ? `[${item.aliya}] ` : ""}`}</b></span>
                                        <span
                                          style={{
                                            color : `${item.isPasukJump ? 'blue' : 'black'}`
                                          }}
                                        ><b>({`${item.willShowSeferName ? seferPerekPasuk : perekPasuk}`})</b></span>
                                        <span> {[item.pasukId, item.location].includes(this.state.pasukId) ? <b>{wordSpans}</b> : wordSpans} </span>
                                    </span>
                                 </span>
                                </>
                      });
                      var atbashTextStream = this.state.rowData.map((item, key) => {
                        var noSpaceLocation = item.location;
                        while(noSpaceLocation.includes(" ")){
                          noSpaceLocation = noSpaceLocation.replace(" ", "_");
                        }
                        var noSpaceText = item.text;
                        while(noSpaceText.includes(" ")){
                          noSpaceText = noSpaceText.replace(" ", "_");
                        }
                        var makorArray = item.location.split(':');
                        var perekPasuk = `${makorArray[1]}:${makorArray[2]}`
                        var seferPerekPasuk = `${makorArray[0]} ${makorArray[1]}:${makorArray[2]}`

                        var streamFontSize = `${(this.state.fontSizeLevel * 2) + 16}px`; // font size ranges from 18 to 28

                        var text = item.text;
                        var words = text.split(" ");
                        var torahMapObj = targumIsOpen ? item.torahPhraseMapObj : item.torahWordMapObj;
                        var wordSpans = words.map((word, key) => {
                          var matchIds = [];
                          if(torahMapObj){
                            matchIds = torahMapObj[key+1];
                          }
                          if(!matchIds){
                            matchIds = [];
                          }
                          if(!item.targum){
                            matchIds = [key];
                          }
                          var isHoveredMatch = false;
                          matchIds.map((matchId, key) => {
                            if(this.state.hoveredMatchIds && this.state.hoveredMatchIds.includes(matchId) && this.state.hoveredLocation === item.location){
                              isHoveredMatch = true;
                            }
                          })

                          var atbash = this.getAtbash(word);
                          //IF TARGUM VISIBLE - HIGHLIGHT GROUPS, OTHERWISE WORD BY WORD
                          return  <span
                                    matchIds={matchIds}
                                    onMouseEnter={(e) => {this.setHoveredMatchIds(matchIds)}}
                                    onMouseLeave={(e) => {this.setHoveredMatchIds(null)}}
                                    style={{
                                      backgroundColor: `${isHoveredMatch && isMultiPanel ? hoverWordHighlightColor : 'unset'}`
                                    }}
                                    onContextMenu={(e)=>fakeThis.onStreamContextMenu(e,"אתבש",atbash)}
                                    className='text-cell-pasuk-span'
                                  >
                                    {atbash + " "}
                                  </span>
                        })

                        var parshaBreak = this.getParshaBreak(key, item.parshaSeqNum, item.isPasukJump)

                         return <> 
                                  {item.isPasukJump ? <><br /> <br /></> : <></>}
                                  <span
                                    className='text-cell-pasuk-span'
                                    onMouseEnter={(e) => {this.setHoveredLocation(item.location)}}
                                    onMouseLeave={(e) => {this.setHoveredLocation(null)}}
                                    location={noSpaceLocation}
                                    text={noSpaceText}
                                    pasukid={item.pasukId}
                                    style={{
                                      fontSize: streamFontSize,
                                      backgroundColor: `${this.state.hoveredLocation === item.location && isMultiPanel ? hoverPasukHighlightColor : 'unset'}`
                                    }} >
                                      {parshaBreak}
                                      <span>
                                        <span><b>{`${item.willShowAliyaName ? `[${item.aliya}] ` : ""}`}</b></span>
                                        <span
                                          style={{
                                            color : `${item.isPasukJump ? '#2a2aff' : '#545454'}`
                                          }}
                                        ><b>({`${item.willShowSeferName ? seferPerekPasuk : perekPasuk}`})</b></span>
                                        <span> {[item.pasukId, item.location].includes(this.state.pasukId) ? <b>{wordSpans}</b> : wordSpans} </span>
                                    </span>
                                 </span>
                                </>
                      });

            var tanachTextStreamDiv = <MenuProvider id='tanach-viewer-tanach-stream-context-menu' > 
                                        <div className="tanach-viewer-stream-panel" onContextMenu={(e) => {if(!fakeThis.state.hoveredMatchIds)this.onStreamContextMenu(e,"תנך")}}>{tanachTextStream}</div>
                                      </MenuProvider>
            var targumTextStreamDiv = <MenuProvider id='tanach-viewer-targum-stream-context-menu' > 
                                        <div className="tanach-viewer-stream-panel" onContextMenu={(e) => {if(!fakeThis.state.hoveredMatchIds)this.onStreamContextMenu(e,"תרגום")}}>{targumTextStream}</div>
                                      </MenuProvider>
            var stamTextStreamDiv = <MenuProvider id='tanach-viewer-stam-stream-context-menu' > 
                                        <div className="tanach-viewer-stream-panel tanach-viewer-stam-stream" onContextMenu={(e) => {if(!fakeThis.state.hoveredMatchIds)this.onStreamContextMenu(e,"סתם")}}>{stamTextStream}</div>
                                      </MenuProvider>

            var ivriTextStreamDiv = <MenuProvider id='tanach-viewer-stam-stream-context-menu'> 
                                      <div className="tanach-viewer-stream-panel tanach-viewer-ivri-stream"
                                        onContextMenu={(e) => {if(!fakeThis.state.hoveredMatchIds)this.onStreamContextMenu(e,"עברי")}}>{ivriTextStream}</div>
                                    </MenuProvider>
            var gematriaTextStreamDiv = <MenuProvider id='tanach-viewer-stam-stream-context-menu'> 
                                      <div className="tanach-viewer-stream-panel tanach-viewer-gematria-stream"
                                        onContextMenu={(e) => {if(!fakeThis.state.hoveredMatchIds)this.onStreamContextMenu(e,"גמטריא")}}>{gematriaTextStream}</div>
                                    </MenuProvider>
            var atbashTextStreamDiv = <MenuProvider id='tanach-viewer-stam-stream-context-menu'> 
                                      <div className="tanach-viewer-stream-panel tanach-viewer-gematria-stream tanach-viewer-atbash-stream"
                                        onContextMenu={(e) => {if(!fakeThis.state.hoveredMatchIds)this.onStreamContextMenu(e,"אתבש")}}>{atbashTextStream}</div>
                                    </MenuProvider>
            textDisplay = <div className="tanach-viewer-text-stream">
              {this.state.contentDisplayed.includes("tanach") && tanachTextStreamDiv}
              {this.state.contentDisplayed.includes("targum") && hasTargum && targumTextStreamDiv}
              {this.state.contentDisplayed.includes("ktavStam") && stamTextStreamDiv}
              {this.state.contentDisplayed.includes("ktavIvri") && ivriTextStreamDiv}
              {this.state.contentDisplayed.includes("gematria") && gematriaTextStreamDiv}
              {this.state.contentDisplayed.includes("atbash") && atbashTextStreamDiv}
            </div>
       } else if (this.state.textDisplayType === "cloud"){
        textDisplay = this.state.wordCloudDiv;
       }

    var focusedResultRowIndex = null;
    var nextLocation = '--';
    var prevLocation = '--';
    if(this.props.resultsApi && this.props.resultsApi.getFocusedCell()){
        var focusedResultRowIndex = this.props.resultsApi.getFocusedCell().rowIndex;
    }

    //set next/prev location
    var selectedList = 0;
    if(this.props.resultsApi && this.props.resultsApi.getFocusedCell() && (['location1', 'pasukText1'].includes(this.props.resultsApi.getFocusedCell().column.colDef.field) || this.props.resultsApi.getFocusedCell().column.colDef.field.includes("value1"))){
      selectedList = 1;
    } else if (this.props.resultsApi && this.props.resultsApi.getFocusedCell() && (['location2', 'pasukText2'].includes(this.props.resultsApi.getFocusedCell().column.colDef.field) || this.props.resultsApi.getFocusedCell().column.colDef.field.includes("value2"))){
      selectedList = 2;
    }
    if((typeof(focusedResultRowIndex) === 'number') && this.props.resultsApi.getModel() && this.props.resultsApi.getModel().rowsToDisplay[focusedResultRowIndex]){
        if(this.props.resultsApi.getModel() && this.props.resultsApi.getModel().rowsToDisplay[focusedResultRowIndex + 1]){
            if(this.props.resultsApi.getFocusedCell().column.colDef.field.includes("value1")){
              nextLocation = this.props.resultsApi.getModel() ? this.props.resultsApi.getModel().rowsToDisplay[focusedResultRowIndex + 1].data.value1.location:0;
            } else if (this.props.resultsApi.getFocusedCell().column.colDef.field.includes("value2")) {
              nextLocation = this.props.resultsApi.getModel() ? this.props.resultsApi.getModel().rowsToDisplay[focusedResultRowIndex + 1].data.value2.location:0;
            } else {
              if (selectedList === 0) nextLocation = this.props.resultsApi.getModel() ? this.props.resultsApi.getModel().rowsToDisplay[focusedResultRowIndex + 1].data.location:0;
              else if (selectedList === 1) nextLocation = this.props.resultsApi.getModel() ? this.props.resultsApi.getModel().rowsToDisplay[focusedResultRowIndex + 1].data.location1:0;
              else if (selectedList === 2) nextLocation = this.props.resultsApi.getModel() ? this.props.resultsApi.getModel().rowsToDisplay[focusedResultRowIndex + 1].data.location2:0;
            }
        }
        if(this.props.resultsApi.getModel() && this.props.resultsApi.getModel().rowsToDisplay[focusedResultRowIndex - 1]){
          if(this.props.resultsApi.getFocusedCell().column.colDef.field.includes("value1")){
            prevLocation = this.props.resultsApi.getModel() ? this.props.resultsApi.getModel().rowsToDisplay[focusedResultRowIndex - 1].data.value1.location:0;
          } else if (this.props.resultsApi.getFocusedCell().column.colDef.field.includes("value2")) {
            prevLocation = this.props.resultsApi.getModel() ? this.props.resultsApi.getModel().rowsToDisplay[focusedResultRowIndex - 1].data.value2.location:0;
          } else {
            if (selectedList === 0) prevLocation = this.props.resultsApi.getModel() ? this.props.resultsApi.getModel().rowsToDisplay[focusedResultRowIndex - 1].data.location:0;
            else if (selectedList === 1) prevLocation = this.props.resultsApi.getModel() ? this.props.resultsApi.getModel().rowsToDisplay[focusedResultRowIndex - 1].data.location1:0;
            else if (selectedList === 2) prevLocation = this.props.resultsApi.getModel() ? this.props.resultsApi.getModel().rowsToDisplay[focusedResultRowIndex - 1].data.location2:0;
          }
        }
    }
    var nextButtonsDisabled = isLastPasuk || this.state.isAtNamedSection || this.state.isLoading;
    var prevButtonsDisabled = isFirstPasuk || this.state.isAtNamedSection || this.state.isLoading;
    var isResultsList = !!this.props.resultsApi;
    var isLastResult = (this.props.resultsApi && this.props.resultsApi.getModel() && !(this.props.resultsApi.getModel().rowsToDisplay[focusedResultRowIndex + 1]));
    var isFirstResult = (this.props.resultsApi && this.props.resultsApi.getModel() && !(this.props.resultsApi.getModel().rowsToDisplay[focusedResultRowIndex - 1]));

    var nextResultDisabled = isLastResult || this.state.isLoading || (this.props.resultsApi && !this.props.resultsApi.getModel());
    var prevResultDisabled = isFirstResult || this.state.isLoading || (this.props.resultsApi && !this.props.resultsApi.getModel());

    var nextResultCaption = `${nextResult} (${nextLocation})`;
    var prevResultCaption = `${prevResult} (${prevLocation})`;

    var nextResultStyle = {
      minWidth: 0,
      display: `${isResultsList ? 'inline' : 'none'}`,
      opacity: `${nextResultDisabled ? .3 : 1}`
    };
    var prevResultStyle = {
      minWidth: 0,
      display: `${isResultsList ? 'inline' : 'none'}`,
      opacity: `${prevResultDisabled ? .3 : 1}`
    };

    var nextButtons = <div className='tanach-viewer-nav-buttons tanach-viewer-left-nav-buttons'>
      <Button
        className='{classes.margin}'
        aria-label={nextSection}
        title={nextSection}
        style={{minWidth: 0, opacity: `${nextButtonsDisabled ? .3 : 1}`}}
        onClick={(e) => { this.getNextSection() }}
        disabled={nextButtonsDisabled}
      >
        <img src={require("./images/icons8-back-96.png")} alt={nextSection} width="30" height="30" />
      </Button>
      <Button
        className='{classes.margin}'
        aria-label={nextResultCaption}
        title={nextResultCaption}
        style={nextResultStyle}
        onClick={(e) => { this.getNextResult() }}
        disabled={nextResultDisabled}
      >
        <img src={require("./images/icons8-double-left-48.png")} alt={nextResultCaption} width="30" height="30" />
      </Button>
   </div>
   var prevButtons = <div className='tanach-viewer-nav-buttons tanach-viewer-right-nav-buttons'>
      <Button
        className='{classes.margin}'
        aria-label={prevResultCaption}
        title={prevResultCaption}
        style={prevResultStyle}
        onClick={(e) => { this.getPrevResult() }}
        disabled={prevResultDisabled}
      >
        <img src={require("./images/icons8-double-right-48.png")} alt={prevResultCaption} width="30" height="30" />
      </Button>
      <Button
        className='{classes.margin}'
        aria-label={prevSection}
        title={prevSection}
        style={{minWidth: 0, opacity: `${prevButtonsDisabled ? .3 : 1}`}}
        onClick={(e) => { this.getPrevSection() }}
        disabled={prevButtonsDisabled}
      >
        <img src={require("./images/icons8-forward-96.png")} alt={prevSection} width="30" height="30" />
      </Button>
   </div>

   if(this.props.activeLanguage && this.props.activeLanguage.code === 'en'){
      nextButtons = <div className='tanach-viewer-nav-buttons tanach-viewer-right-nav-buttons'>
        <Button
          className='{classes.margin}'
          aria-label={nextSection}
          title={nextSection}
          style={{minWidth: 0, opacity: `${nextButtonsDisabled ? .3 : 1}`}}
          onClick={(e) => { this.getNextSection() }}
          disabled={nextButtonsDisabled}
        >
          <img src={require("./images/icons8-forward-96.png")} alt={nextSection} width="30" height="30" />
        </Button>
        <Button
        className='{classes.margin}'
        aria-label={nextResultCaption}
        title={nextResultCaption}
        style={nextResultStyle}
        onClick={(e) => { this.getNextResult() }}
        disabled={nextResultDisabled}
      >
        <img src={require("./images/icons8-double-right-48.png")} alt={nextResultCaption} width="30" height="30" />
      </Button>
      </div>
      prevButtons = <div className='tanach-viewer-nav-buttons tanach-viewer-left-nav-buttons'>
        <Button
          className='{classes.margin}'
          aria-label={prevResultCaption}
          title={prevResultCaption}
          style={prevResultStyle}
          onClick={(e) => { this.getPrevResult() }}
          disabled={prevResultDisabled}
        >
          <img src={require("./images/icons8-double-left-48.png")} alt={prevResultCaption} width="30" height="30" />
        </Button>
        <Button
          className='{classes.margin}'
          aria-label={prevSection}
          title={prevSection}
          style={{minWidth: 0, opacity: `${prevButtonsDisabled ? .3 : 1}`}}
          onClick={(e) => { this.getPrevSection() }}
          disabled={prevButtonsDisabled}
        >
          <img src={require("./images/icons8-back-96.png")} alt={prevSection} width="30" height="30" />
        </Button>
      </div>
   }


      return (
        <Rnd
          helpTopic="tanachViewer"
          size={{ width: `${this.props.widthNum}px`,  height: `${this.props.heightNum}px` }}
          position={{ x: this.props.x, y: this.props.y }}
          onDragStop={(e, d) => { 
            this.props.setPosition(d.x, d.y)
            // this.setState({ x: d.x, y: d.y }) 
          }}
          onClick={(e) => {this.handleClick()}}
          onResize={(e, direction, ref, delta, position) => {
            this.props.setSize(
              parseInt(ref.style.width.slice(0, -1)),
              parseInt(ref.style.height.slice(0, -1)),
              position.x,
              position.y
            )
            // this.setState({
            //   widthNum: parseInt(ref.style.width.slice(0, -1)),
            //   heightNum: parseInt(ref.style.height.slice(0, -1)),
            //   // columnDefs: this.getColumnDefs(),  //switched from ag grid to html table for easier editting
            //   ...position,
            // }, () => {
            //   // this.setRowHeights()
            // });
          }}
          style = {{
            backgroundColor: 'rgb(255, 238, 223)',
            visibility: this.props.isOpen ? 'visible' : 'hidden',
            boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
            borderRadius: '5px',
            zIndex: this.state.zIndex
          }}
          cancel = '.tanach-viewer-content, .tanach-viewer-text-display-controls, .placement-selector-2D-selector, .placement-selector-2D-popover, .tanach-viewer-resize-buttons'
          minWidth={740}
          minHeight={153}
          bounds='#root'
          className="tanach-viewer-rnd"
        >
          <div className="tanach-viewer-resize-buttons"
            style={{
              position: 'absolute',
              right: isHebrew ? 'unset' : 0,
              left: isHebrew ? 0 : 'unset',
              direction: isHebrew ? 'ltr' : 'rtl',
              top: 0,
              borderBottomLeftRadius: isHebrew ? 0 : '3px',
              borderBottomRightRadius: isHebrew ? '3px' : 0,
            }}
          >
            <Button className="tanach-viewer-resize-button" onClick={() => {this.handleClose()}} title={close} helpTopic="layout">
              <CloseIcon />
            </Button>
            <Button className="tanach-viewer-resize-button" onClick={() => {this.handleCopyLink()}} title={copyLink}>
              <LinkIcon />
            </Button>
            <Button className="tanach-viewer-resize-button" onClick={() => {this.handleSplitScreen()}}  title={splitScreen}>
              <HorizontalSplitIcon />
            </Button>
            <Button className="tanach-viewer-resize-button" onClick={() => {this.handleFullscreen()}} title={fullScreen}>
              <FullscreenIcon />
            </Button>
          </div>

              <DialogTitle style={{
                direction: directionStyling.direction, textAlign: "center",
                maxWidth: 'calc(100% - 120px)',
                margin: 'auto',
                padding: '10px 0',
              }}>
                <div style={{lineHeight: '2'}}> 
                  {prevButtons}
                  {nextButtons}
                  <div className="medCaption" align="center">{tanachViewer}</div>
                </div>
                <div className="tanach-viewer-placement-selector-parent">
                  <TanachViewerPlacementSelector tanachViewerOpen={this.state.open} extModifyPlacementData={this.extModifyPlacementData} extAddPlacementRow={this.extAddPlacementRow} extDeletePlacementRow={this.extDeletePlacementRow}
                      translate={this.props.translate} data={this.state.placementData}
                      val1={this.state.val1} valList1={this.state.valList1} val2={this.state.val2} valList2={this.state.valList2}
                      val3={this.state.val3} valList3={this.state.valList3} val4={this.state.val4} valList4={this.state.valList4}
                      val5={this.state.val5} valList5={this.state.valList5} val6={this.state.val6} valList6={this.state.valList6}
                      kriaSequenceNum={this.state.kriaSequenceNum} type={this.state.type} canFetchSection={this.state.canFetchSection}
                      extHandleRangeChange1={this.handleRangeChange1} extHandleRangeChange2={this.handleRangeChange2} extHandleRangeChange3={this.handleRangeChange3}
                      isAtNamedSection={this.state.isAtNamedSection} loadedSectionVals={this.state.loadedSectionVals} extRefreshSelector={this.refreshSelector}
                      extFetchSection={this.fetchSection} isLoading={this.state.isLoading}
                  />
                </div>

              </DialogTitle>
              <LoadingOverlay
                active={this.state.isLoading}
                spinner
                text={loadingText}
              >
                <DialogContent className="tanach-viewer-content" style={{
                  direction: 'rtl',
                  maxHeight: `${dialogContentHeight}px`,
                  minHeight: `${dialogContentHeight}px`,
                  padding: '0 0 0px',
                  margin: '0 24px',
                }}>
                  {/* <MenuProvider className="context-menu" id='tanach-viewer-context-menu' >  */}
                    <DialogContentText style={{direction: 'rtl', textAlign: 'right'}}>
                      <div id="tanach-viewer-text-display"
                        style={{
                          visibility: this.props.isOpen && this.state.rowData.length > 0 ? 'visible' : 'hidden'
                        }}
                      >
                        {textDisplay}
                      </div>
                    </DialogContentText>
                  {/* </MenuProvider> */}
                </DialogContent>
              </LoadingOverlay>
              <DialogActions
                style={{
                  direction: isHebrew ? 'ltr' : 'rtl',
                }}
              >
                <div className='tanach-viewer-text-display-controls' style={{ direction: directionStyling.direction, textAlign: directionStyling.textAlign, width:"calc(100%)", display: "inline-block", cursor: "text"}}>
                  <div className='tanach-viewer-select-text-display-type'>
                    <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}><Translate id="selectDisplay" /></Typography>&nbsp;
                    <div className={classes.toggleContainer} className="tanachViewerToggleButtonContainer" style={{verticalAalign:' -2px'}}>
                      <ToggleButtonGroup className="tanachViewerToggleButtonGroup" value={this.state.textDisplayType} exclusive onChange={(e, val) => {this.handleToggleTextDisplayType(e, val)}}>
                        <ToggleButton className="tanach-viewer-display-toggle-button" value="stream" title={stream}>
                          <img src={require("./images/paragraph_icon.svg")} alt={stream} width="14" height="14" style={{transform: 'scaleX(-1)'}} />
                        </ToggleButton>
                        <ToggleButton className="tanach-viewer-display-toggle-button" value="table" title={table}>
                          <img src={require("./images/spreadsheet_icon.svg")} alt={table} width="14" height="14" style={{transform: 'scaleX(-1)'}} />
                        </ToggleButton>
                        <ToggleButton className="tanach-viewer-display-toggle-button" value="cloud" title={wordCloud}>
                          <img src={require("./images/iconfinder_cloud_126565.png")} alt={wordCloud} width="14" height="14" style={{transform: 'scaleX(-1)'}} />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>

                  <div className='tanach-viewer-select-text-display-type'>
                    {decreaseFontButton}
                    {increaseFontButton}
                  </div>



                  <div className='tanach-viewer-select-text-display-type'>
                    <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}><Translate id="selectContent" />:</Typography>&nbsp;
                    <div className={classes.toggleContainer} style={{direction: 'rtl'}} className="tanachViewerToggleButtonContainer">
                      <ToggleButtonGroup className="tanachViewerToggleButtonGroup" value={contentDisplayed} onChange={(e, vals) => {this.clickToggleContentDisplayed(e, vals)}}>
                        <ToggleButton className="tanach-viewer-content-toggle-button" value="tanach">תנ"ך</ToggleButton>
                        <ToggleButton className="tanach-viewer-content-toggle-button" value="targum" disabled={this.state.rowData[0] && !hasTargum}>תרגום</ToggleButton>
                        <ToggleButton className="tanach-viewer-content-toggle-button" value="ktavStam" disabled={this.state.textDisplayType === 'table'}>כתב סת"ם</ToggleButton>
                        <ToggleButton className="tanach-viewer-content-toggle-button" value="ktavIvri" disabled={this.state.textDisplayType === 'table'}>כתב עברי</ToggleButton>
                        <ToggleButton className="tanach-viewer-content-toggle-button" value="gematria" disabled={this.state.textDisplayType === 'table'}>גימטריה</ToggleButton>
                        <ToggleButton className="tanach-viewer-content-toggle-button" value="atbash" disabled={this.state.textDisplayType === 'table'}>אתב"ש</ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>

                  <div className='tanach-viewer-select-text-display-type'>
                    <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{pesukimToDisplay}</Typography>&nbsp;
                    <Select
                      value={this.state.contextSize}
                      onChange={(e) => {this.updateContextSize(e)}}
                      name="sortOrder"
                      displayEmpty
                      disabled={this.state.isAtNamedSection}
                      autoWidth={true}
                      style={{
                        fontWeight: 'normal',
                      }}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={11}>11</MenuItem>
                      <MenuItem value={21}>21</MenuItem>
                      <MenuItem value={41}>41</MenuItem>
                      <MenuItem value={101}>101</MenuItem>
                    </Select>
                  </div>
                </div>
              </DialogActions>
              <ContextMenu menuId='tanach-viewer-table-context-menu' menuType='tanachViewer' className='special-context-menu' 
                        resultsApi={this.contextApi} extSetTextSource={this.props.extSetTextSource} columnClicked={this.state.columnClicked}
                        textToCopy={fakeThis.state.textToCopy} wordToCopy={fakeThis.state.wordToCopy} extCopySelectedToSearchbar={fakeThis.props.extCopySelectedToSearchbar}
                        extSearchWithSelected={fakeThis.props.extSearchWithSelected} extToggleUseKinuim={fakeThis.toggleUseKinuim}
                        useKinuim={fakeThis.state.useKinuim} extToggleIncludeMekorot={fakeThis.toggleIncludeMekorot} includeMekorot={fakeThis.state.includeMekorot}
                        extOpenTaamParser={fakeThis.openTaamParser} hasRowData={fakeThis.state.rowData.length > 0} textCellRowText={this.state.contextMenuText} 
                        isStatsContextMenu={false} textDisplayType={this.state.textDisplayType} 
                        extToggleSearchAsQuote={fakeThis.toggleSearchAsQuote} searchAsQuote={fakeThis.state.searchAsQuote}
                        pasukLocation={this.state.taamParserLocation} extOpenLexiconViewer={this.props.extOpenLexiconViewer}/>
              <ContextMenu menuId='tanach-viewer-tanach-stream-context-menu' menuType='tanachViewer' className='special-context-menu' 
                        resultsApi={this.contextApi} extSetTextSource={this.props.extSetTextSource} columnClicked={"תנך"}
                        textToCopy={fakeThis.state.textToCopy} wordToCopy={fakeThis.state.wordToCopy} extCopySelectedToSearchbar={fakeThis.props.extCopySelectedToSearchbar}
                        extSearchWithSelected={fakeThis.props.extSearchWithSelected} extToggleUseKinuim={fakeThis.toggleUseKinuim}
                        useKinuim={fakeThis.state.useKinuim} extToggleIncludeMekorot={fakeThis.toggleIncludeMekorot} includeMekorot={fakeThis.state.includeMekorot}
                        extOpenTaamParser={fakeThis.openTaamParser} hasRowData={fakeThis.state.rowData.length > 0} textCellRowText={this.state.contextMenuText} 
                        isStatsContextMenu={false} textDisplayType={this.state.textDisplayType} 
                        extToggleSearchAsQuote={fakeThis.toggleSearchAsQuote} searchAsQuote={fakeThis.state.searchAsQuote}
                        pasukLocation={this.state.taamParserLocation}extOpenLexiconViewer={this.props.extOpenLexiconViewer} />
              <ContextMenu menuId='tanach-viewer-targum-stream-context-menu' menuType='tanachViewer' className='special-context-menu' 
                        resultsApi={this.contextApi} extSetTextSource={this.props.extSetTextSource} columnClicked={"תרגום"}
                        textToCopy={fakeThis.state.textToCopy} wordToCopy={fakeThis.state.wordToCopy} extCopySelectedToSearchbar={fakeThis.props.extCopySelectedToSearchbar}
                        extSearchWithSelected={fakeThis.props.extSearchWithSelected} extToggleUseKinuim={fakeThis.toggleUseKinuim}
                        useKinuim={fakeThis.state.useKinuim} extToggleIncludeMekorot={fakeThis.toggleIncludeMekorot} includeMekorot={fakeThis.state.includeMekorot}
                        extOpenTaamParser={fakeThis.openTaamParser} hasRowData={fakeThis.state.rowData.length > 0} textCellRowText={this.state.contextMenuText} 
                        isStatsContextMenu={false} textDisplayType={this.state.textDisplayType} 
                        extToggleSearchAsQuote={fakeThis.toggleSearchAsQuote} searchAsQuote={fakeThis.state.searchAsQuote}
                        pasukLocation={this.state.taamParserLocation} extOpenLexiconViewer={this.props.extOpenLexiconViewer}/>
              <ContextMenu menuId='tanach-viewer-stam-stream-context-menu' menuType='tanachViewer' className='special-context-menu' 
                        resultsApi={this.contextApi} extSetTextSource={this.props.extSetTextSource} columnClicked={this.state.columnClicked}
                        textToCopy={fakeThis.state.textToCopy} wordToCopy={fakeThis.state.wordToCopy} extCopySelectedToSearchbar={fakeThis.props.extCopySelectedToSearchbar}
                        extSearchWithSelected={fakeThis.props.extSearchWithSelected} extToggleUseKinuim={fakeThis.toggleUseKinuim}
                        useKinuim={fakeThis.state.useKinuim} extToggleIncludeMekorot={fakeThis.toggleIncludeMekorot} includeMekorot={fakeThis.state.includeMekorot}
                        extOpenTaamParser={fakeThis.openTaamParser} hasRowData={fakeThis.state.rowData.length > 0} textCellRowText={this.state.contextMenuText} 
                        isStatsContextMenu={false} textDisplayType={this.state.textDisplayType} 
                        extToggleSearchAsQuote={fakeThis.toggleSearchAsQuote} searchAsQuote={fakeThis.state.searchAsQuote}
                        pasukLocation={this.state.taamParserLocation} extOpenLexiconViewer={this.props.extOpenLexiconViewer}/>
                {/* <Dialog
                  open={this.state.taamParserOpen}
                  onClose={this.closeTaamParser}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth="xl"
                >
                    <TaamParser data={this.state.taamParseData} open={this.state.taamParserOpen} closeTaamParser={this.closeTaamParser}
                            extOpenTaamParser={this.openTaamParser} resultsApi={this.contextApi} 
                            pasukId={this.state.taamParserPasukId} pasukLocation={this.state.taamParserLocation} isTanachViewer={true}/>
                </Dialog> */}
          </Rnd>

    );
  }
}

TanachViewer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withLocalize(TanachViewer));