import React, { Component } from 'react';
import maintLogo from './images/bhsMaintenance.jpg';
import './css/App.css';
import styles from './css/App.css'; //for customizing reactStrap styles
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { withLocalize, Translate } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import { Modal, Paper, Typography } from '@material-ui/core';
import translations from "./json/translations.json";
import { useTimer } from 'react-timer-hook';

function MyTimer({ expiryTimestamp, toggleKodeshMode, secondsRemaining }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => {if (secondsRemaining > 0) toggleKodeshMode(false)}}); //this tends to get rendered multiple times for some reason so need to check secondsRemaining for a proper value

  const _days = <Translate id="days"/>;
  const _hrs = <Translate id="hrs"/>;
  const _min = <Translate id="min"/>;
  const _sec = <Translate id="sec"/>;

  return (
    <div dir="ltr" style={{align: 'center', textAlign: 'center'}}>
        <div dir="ltr" style={{color: '#505050', fontSize: '16px'}}>
            <span dir="ltr" style={{width: '70px'}}>{_days}</span>
            <span dir="ltr" style={{width: '10px', fontSize: '10px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span dir="ltr" style={{width: '70px'}}>{_hrs}</span>
            <span dir="ltr" style={{width: '10px', fontSize: '10px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span dir="ltr" style={{width: '70px'}}>{_min}</span>
            <span dir="ltr" style={{width: '10px', fontSize: '10px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span dir="ltr" style={{width: '70px'}}>{_sec}</span>
        </div>
        <div dir="ltr" style={{color: '#80FF80', fontSize: '40px', fontWeight: 'bold'}}>
            <span style={{width: '70px', backgroundColor: 'black'}}>&nbsp;&nbsp;{days}&nbsp;</span>
            <span style={{width: '10px', fontSize: '10px'}}>&nbsp;</span>
            <span style={{width: '70px', backgroundColor: 'black'}}>&nbsp;{(''+hours).padStart(2, '0')}&nbsp;</span>
            <span style={{width: '10px', fontSize: '10px'}}>&nbsp;</span>
            <span style={{width: '70px', backgroundColor: 'black'}}>&nbsp;{(''+minutes).padStart(2, '0')}&nbsp;</span>
            <span style={{width: '10px', fontSize: '10px'}}>&nbsp;</span>
            <span style={{width: '70px', backgroundColor: 'black'}}>&nbsp;{(''+seconds).padStart(2, '0')}&nbsp;</span>
        </div>
    </div>
  );
}

class KodeshMode extends Component {

  constructor(props) {
    super(props);

    this.state = {
    };

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}
  }

  async componentDidMount() {
      this.setLanguage(localStorage.getItem("language"));
  }

  render() {
    var time = new Date();
    var secondsRemaining = 0, startType = "", startMsg = "", baseHaseferAvailable = "", endMsg = "", timeMsg1 = "", timeMsg2 = "";
    var isHebrew = localStorage.getItem("language") === 'he';
    var textStyle = {direction: isHebrew ? "rtl" : "ltr", fontSize: isHebrew ? '18px' : '16px'};
    var textDirection = isHebrew ? "rtl" : "ltr";
    if (this.props.kodeshModeSecondsRemaining != null) {
        secondsRemaining = this.props.kodeshModeSecondsRemaining
        startType = this.props.kodeshModeType == "SHABBOS" ? "Shabbos" : "Yom Tov";
        const isStartShabbos = this.props.kodeshModeType == "SHABBOS";
        const isEndShabbos = this.props.kodeshModeEndType == null || this.props.kodeshModeEndType == "SHABBOS";
        startMsg = this.props.translate(isStartShabbos ? "goodShabbos" : "goodYomTov");
        baseHaseferAvailable = this.props.translate("baseHaseferAvailable");
//        timeMsg1 = "kodeshStart=" + this.props.kodeshModeStart;
//        timeMsg2 = "kodeshEnd=" + this.props.kodeshModeEnd;
        endMsg = this.props.translate("atTheEndOf") + this.props.translate(isEndShabbos ? "shabbos" : "yomTov");
    }
    time.setSeconds(time.getSeconds() + secondsRemaining);
    const boxStyle = {direction: isHebrew ? "rtl" : "ltr", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 435, height: 300, border: '2px solid #000'};
    const popupStyle = {direction: isHebrew ? "rtl" : "ltr", width: 431, height: 296, textAlign: 'center', color: 'black', paddingTop: '57px'}; //padding: '50px',
    return (
        <Modal open={this.props.isOpen}>
            <div style={boxStyle}>
                <Paper style={popupStyle}>
                    <span dir={textDirection} style={textStyle}>{startMsg}<br /></span>
                    <span dir={textDirection} style={textStyle}>{baseHaseferAvailable}<br /></span>
                    <MyTimer expiryTimestamp={time} secondsRemaining={secondsRemaining} toggleKodeshMode={this.props.toggleKodeshMode} /><br />
                    <span dir={textDirection} style={textStyle}>{endMsg}</span>
                </Paper>
            </div>
        </Modal>
    );
  }

}

export default withLocalize(KodeshMode);

/*
        {timeMsg1}<br />
        {timeMsg2}<br />
*/