import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import styles from './css/App.css'; //for customizing reactStrap styles
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import { renderToStaticMarkup } from "react-dom/server";
import HierarchicalSelect from 'react-hierarchical-select';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
//import Autocomplete from '@material-ui/core';


class NameDropdown extends Component {
    constructor(props){
        super(props);
        this.state = {
            //nameSelected: null,
        }
        this.props.initialize({
          languages: [
            { name: "עברית", code: "he" },
            { name: "English", code: "en" }
          ],
          translation: translations,
          options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
        });
        this.onInputChange = this.onInputChange.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onInputChange(e){      // fires with each character typed into the input bar
    }

      onChange(currentNode, selectedNodes){  // fires when I choose one of the dropdown menu items
          if(currentNode.value == null){
            return false;
          };
          var treeNode = currentNode.value;
          //this.setState({nameSelected : currentNode.value.name});
          this.props.setFocusedNode(treeNode);
          currentNode.checked = true;
       }

       getMenuData(dropdownData){
         if(dropdownData){
          return dropdownData
            .filter((person) => isNaN(parseInt(person.name)))
            .map((person) => {
              const item = {
                label: util.removeNikud(person.name),
                value: person
              }
              return item;
            })
         } else {
           return [];
         }
       }

     render(){

//       const menuData = [
//             {label: "אברהם", value: "אברהם"},
//             {label: "יצחק", value: "יצחק"},
//             {label: "יעקב", value: "יעקב"},
//            ]
       const menuData2 = this.getMenuData([
             {index: 1, name: "אברהם"},
             {index: 2, name: "יצחק"},
             {index: 3, name: "יעקב"},
             {index: 4, name: "יע0קב"},
             {index: 5, name: "יע1קב"},
             {index: 6, name: "יע2קב"},
             {index: 7, name: "יע3קב"},
             {index: 8, name: "יע4קב"},
             {index: 9, name: "יע5קב"},
             {index: 10, name: "יע6קב"},
             {index: 11, name: "יע7קב"},
             {index: 12, name: "יע8קב"},
             {index: 13, name: "יע9קב"},
             {index: 14, name: "יעAקב"},
             {index: 15, name: "יעBקב"},
             {index: 16, name: "יעCקב"},
             {index: 17, name: "יעDקב"},
             {index: 18, name: "יעEקב"},
             {index: 19, name: "יעFקב"},
            ])
      //  const menuData = this.props.peopleData;
      const chooseAName = this.props.translate('chooseAName');
      const noMatchingName = this.props.translate('noMatchingName');
      var menuData = this.getMenuData(this.props.dropdownData)
//      var menuData = [];
//      var i;
//      if (menuData3 && menuData3.length > 0) {
//      for (i=0; i<104; i++) {
//          menuData.push(menuData3[i]);
//      }
//      }
      const nameSelected = (this.props.nodeSelected && this.props.nodeSelected.fullName) ? this.props.nodeSelected.fullName : this.props.translate('chooseAName') ;
     return(
     <div className="yuchsin-name-dropdown-container">
          <DropdownTreeSelect
            data={menuData}
            disabled={this.props.disabled}
            //clearSearchOnChange={true}
            onChange={this.onChange}
            //onInputChange={this.onInputChange}
            texts={{
                placeholder: nameSelected,
                noMatches: noMatchingName
            }}
            mode="simpleSelect"
            //hierarchical={false}
            //inlineSearchInput={true}
            //inlineSearchPlaceholder={"asff"}
//            Do I need value={} here??
            />
       {/*
         <Autocomplete
            options={menuData}
         /> */}
     </div>
     )}
}

// export default (NameDropdown);
export default withLocalize(NameDropdown);

/*
                <HierarchicalSelect
                    data={menuData}
                    onChange={(e) => {this.onMenuChange(e)}}
                    onNodeToggle={(e) => {this.onMenuNodeToggle(e)}}
                    onAction={(e) => {this.onMenuAction(e)}}
                    mode="hierarchical"
                    enforceSingleSelection={true}
                    highlightSearch={true}
                    className={`mdl-demo ${isHebrew ? "he" : "en"} help-dropdown`}
                    texts={{
                      placeholder: searchTR
                    }}
                    value={this.state.dropdownValue}
                />
*/





