import React, { Component } from 'react';

class TanachViewer_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>דפדפן
              התנ"ך</span></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מטרה</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>להציג
            מבט רב-פנים
            לקטעי התנ"ך.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>זה
            יכול לשמש
            כנקודת מוצא
            כאשר לומדים
            ישירות את
            התנ"ך או
            כנקודת
            התייחסות בעת
            חיפוש או ניתוח
            מילים
            ופסוקים
            במקומות
            אחרים באתר.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>למשתמשים
            עומדים
            לרשותם
            תצוגות
            מקבילות מרובות
            פאנלים של הפסוקים
            יחד עם תרגום,
            פריסת כתב
            סת"ם, כתב עברי
            ועוד. בנוסף,
            תהיה להם
            אפשרות
            להפעיל חיפוש וניתוח
            מכל מקום
            בתנ"ך.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
            השקת דפדפן התנ"ך
        </span></u></b></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כאמור
            במקום אחר,
            ניתן להפעיל
            חלון זה
            מהתפריט
            הראשי. הדפדפן
            יפתח לבראשית
            א:א.</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ניתן
            להפעיל אותו
            גם על ידי
            לחיצה כפולה
            על הפסוקים
            המוצגים בחלק
            גדול מהאתר,
            למשל תוצאות
            החיפוש.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הדפדפן
            ייפתח ישירות
            לפסוק זה עם
            הפסוק המודגש.</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כאמור
            במקום אחר,
            ניתן להשיג את
            אותו הדבר על ידי
            לחיצה ימנית
            איפשהו בפסוק
            והפעלת הדפדפן
            מתפריט ההקשר.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פריסה</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>החלקים
            הבאים
            מתייחסים
            לתרשים זה
            המייצג תצוגה מדגמית
            מסוף פרשת
            וישב.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={623} height={360} id="Picture 70" src="helpDialogImages/image066.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ניווט</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בחלקו
            העליון של
            דפדפן התנ"ך
            יופיע קטע (</span><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'red'}}><span dir="LTR" />1</span></b><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />)
            שייראה כמו
            בורר
            בכרטיסייה
            מיקום
            בהגדרות החיפוש
            המתקדם, שם
            ניתן לנווט
            למקום אחר
            בתנ"ך על ידי
            בחירת קטע מסויים
            בתנ"ך עם
            האפשרות <b>קטע
              מסויים</b>...</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={623} height={16} id="Picture 67" src="helpDialogImages/image067.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />... או על ידי
            בחירת טווחי
            פסוקים
            ספציפיים עם האפשרות
            <b>טווח פסוקים</b>.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={624} height={16} id="Picture 68" src="helpDialogImages/image068.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ניתן
            גם לעבור
            לקבוצת
            הפסוקים הבאה
            או הקודמת על
            ידי לחיצה על
            כפתורי החץ </span><b><span lang="HE" style={{fontSize: '16.0pt', fontFamily: '"Arial",sans-serif'}}>&lt;</span></b><span dir="LTR" /><b><span lang="HE" dir="LTR" style={{fontSize: '16.0pt'}}><span dir="LTR" />
            </span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>או</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span dir="RTL" /><b><span lang="HE" style={{fontSize: '16.0pt', fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />&gt;</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
          </span><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif'}}>(</span><b><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'red'}}>2</span></b><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif'}}>)</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אם
            הדפדפן הושק
            מטבלת תוצאות
            חיפוש או כל
            רשימת פסוקים
            אחרת, ניתן גם
            לעבור לפסוק
            התוצאה הבא או
            הקודם על ידי
            לחיצה על
            כפתורי החצים
            הכפולים </span><b><span lang="HE" style={{fontSize: '16.0pt', fontFamily: '"Arial",sans-serif'}}>&lt;&lt;</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> או </span><b><span lang="HE" style={{fontSize: '16.0pt', fontFamily: '"Arial",sans-serif'}}>&gt;&gt;</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif'}}>(</span><b><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'red'}}>2</span></b><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif'}}>)</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פקדי
                חלון הדפדפן</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ישנם
            שלושה סמלי
            בקרה יחד בחלק
            העליון של הצופה.
          </span><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif'}}>(</span><b><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'red'}}>3</span></b><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif'}}>)</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
            לחיצה על:</span></p>
        <table className="MsoTableGrid" border={0} cellSpacing={0} cellPadding={0} style={{borderCollapse: 'collapse', border: 'none'}}>
          <tbody><tr style={{height: '.2in'}}>
              <td width={36} valign="top" style={{width: '27.0pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={24} height={24} id="Picture 75" src="helpDialogImages/image069.png" /></span></p>
              </td>
              <td width={588} style={{width: '441.0pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>סוגר
                    את חלון הדפדפן</span></p>
              </td>
            </tr>
            <tr style={{height: '.2in'}}>
              <td width={36} valign="top" style={{width: '27.0pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={24} height={24} id="Picture 71" src="helpDialogImages/image070.png" /></span></p>
              </td>
              <td width={588} style={{width: '441.0pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מרחיב
                    את החלון
                    לתצוגת מסך
                    מלא</span></p>
              </td>
            </tr>
            <tr style={{height: '.2in'}}>
              <td width={36} valign="top" style={{width: '27.0pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={24} height={24} id="Picture 73" src="helpDialogImages/image071.png" /></span></p>
              </td>
              <td width={588} style={{width: '441.0pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>משנה
                    את גודל
                    התצוגה למסך
                    מפוצל על ידי
                    מילוי האזור
                    ישירות מתחת
                    לסרגל
                    החיפוש
                    והתוצאות</span></p>
              </td>
            </tr>
          </tbody></table>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אפשרות
                הצגה</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פריסת
              הפסוק</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> (זה מתואר
            גם במציג </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פרשיות
            התנ"ך</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>).
            לחיצה על: </span><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif'}}>(</span><b><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'red'}}>4</span></b><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif'}}>)</span></p>
        <table className="MsoTableGrid" border={0} cellSpacing={0} cellPadding={0} style={{borderCollapse: 'collapse', border: 'none'}}>
          <tbody><tr style={{height: '.2in'}}>
              <td width={36} valign="top" style={{width: '27.0pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={29} height={26} id="Picture 84" src="helpDialogImages/image038.png" /></span></p>
              </td>
              <td width={588} style={{width: '441.0pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מציג
                    את הפסוקים במצגת
                    זרם שבו פסוק
                    אחד זורם
                    מקודמו ללא
                    הפסקה</span></p>
              </td>
            </tr>
            <tr style={{height: '.2in'}}>
              <td width={36} valign="top" style={{width: '27.0pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={29} height={27} id="Picture 85" src="helpDialogImages/image039.png" /></span></p>
              </td>
              <td width={588} style={{width: '441.0pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מציג
                    את הפסוקים במצגת
                    זרם</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />(כתב
                    סת"ם וכתב
                    עברי לא יהיו
                    זמינים
                    בתצוגה זו)</span></p>
              </td>
            </tr>
            <tr style={{height: '.2in'}}>
              <td width={36} valign="top" style={{width: '27.0pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={29} height={27} id="Picture 85" src="helpDialogImages/image072.png" /></span></p>
              </td>
              <td width={588} style={{width: '441.0pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
מציג ענן תגים המבוסס על מילים, שורשים, גימטריא וכו' (<i>תלוי בהגדרות שנבחרו</i>)
                    </span></p>
              </td>
            </tr>
          </tbody></table>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כפתורי
          </span><b><span dir="LTR" style={{fontFamily: '"Franklin Gothic Heavy",sans-serif'}}>A+</span></b><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> ו</span><span dir="LTR" /><b><span lang="HE" dir="LTR" style={{fontFamily: '"Franklin Gothic Heavy",sans-serif'}}><span dir="LTR" /> </span></b><b><span dir="LTR" style={{fontFamily: '"Franklin Gothic Heavy",sans-serif'}}>A-</span></b><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> יגדילו או
            יכווצו את
            גודל הגופן של
            הפסוקים.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עמודות
              תוכן מקבילות
              מרובות</span></b><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif'}}> (</span><b><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'red'}}>5</span></b><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif'}}>)</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> ניתן
            לבחור לפחות
            אחד או כולם,
            כאשר
            האפשרויות הן:</span></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תנ"ך</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            פסוקים
            הכוללים
            נקודות
            וטעמים. תצוגת
            ברירת מחדל זו
            תופיע עם
            פתיחת הדפדפן
            לראשונה</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תרגום</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            תרגום
            אונקלוס (זמין
            רק לחומש)</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כתב
              סת"ם</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
            - האופן בו
            מופיעים
            פסוקים בספרי
            קודש בכתב יד</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><i><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></i><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כתב </span></b><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עברי</span></b><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
              תכונה
              ניסיונית -
              פרטים נוספים
              על זה בתיעוד
              על מציג
              פרשיות התנ"ך</span></i></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>גמטריא</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
זרם של מספרים המייצג את הגימטריות הרגילות של כל מילה בפסוק
            </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אתבש</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
זרם של מספרים עם ערכי גמטריית-אתבש
            </span>
        </p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מספר
              הפסוקים
              לתצוגה</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif'}}>(</span><b><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'red'}}>6</span></b><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif'}}>)</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>. בדרך
            כלל, הצופה
            יטען מהר יותר
            כאשר הוא צריך
            לטעון פחות
            פסוקים, אך
            לפעמים קל
            יותר לגלול
            ולנווט דרך
            המון פסוקים.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>על ידי
            לחיצה על
            התפריט הזה,
            ניתן לבחור
            להציג <b>5, 11, 21, 41 או 101</b>
            פסוקים בו
            זמנית.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>יישור
                מקביל של
                העמודות
                בתצוגה</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כאשר
            נבחרים יותר
            מעמודה
            מקבילה אחת
            להופיע, ריחוף
            העכבר מעל
            מילה או ביטוי
            יביא לכך שהמילה/הביטוי
            יודגש בצבע
            כהה יותר
            והפסוק שלה יודגש
            בצבע בהיר
            יותר.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
          </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>התוכן
            המקביל בכל
            העמודות
            המוצגות יקבל
            אותה הדגשה.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מכיוון
            שלעתים
            קרובות אין
            קשר אחד לאחד
            בין ניסוח של
            פסוק לניסוח
            תרגומו,</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ההדגשה
            תשאף ליישר את
            מערכות
            היחסים 1:</span><span dir="LTR">N</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> ו- </span><span dir="LTR">N</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />:1 </span><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif'}}>(</span><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'red'}}><span dir="LTR" />7</span></b><span dir="RTL" /><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />)</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>יש לראות
            בכך תכונה
            ניסיונית לעת
            עתה.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
          </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אמנם
            בדרך כלל זה
            מדויק בכ- 95%, אך
            הוא נוטה
            להיות הרבה
            פחות מדויק
            עבור קטעים פיוטיים
            יותר כמו ברכת
            יעקב, ברכת
            משה, שירת הים.</span></p>
        <p className="MsoNormal">&nbsp;</p>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b>Tanach Viewer / </b><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>דפדפן
              התנ"ך</span></b></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Goal</u></b></p>
        <p className="MsoNormal">To present a multi-faceted view into sections of Tanach.&nbsp; This
          can serve either a starting point when directly learning the Tanach or as a
          point of reference when searching or analyzing words and pesukim elsewhere on
          the site.&nbsp; Users will have at their disposal multi-paneled, parallel views of
          the pesukim along with Targum, <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כתב
            סת"ם</span><span dir="LTR" /><span dir="LTR" />
          layout, <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כתב
            עברי</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>and more and will also have the ability to launch search
          and analysis on the fly from anywhere in Tanach.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Launching the Tanach Viewer</u></b></p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />As mentioned elsewhere, one can launch this
          window from the Main Menu.&nbsp; The viewer will open up to <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בראשית
            א:א</span><span dir="LTR" /><span dir="LTR" />.</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />It can also be launched by double-clicking
          on pesukim displayed in much of the site eg. the search results.&nbsp; The viewer
          will open up directly at that pasuk with that pasuk in bold.</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />As mentioned elsewhere, the same can be
          accomplished by right-clicking somewhere in a pasuk and launching the viewer
          from the Context Menu.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Layout</u></b></p>
        <p className="MsoNormal">The following sections refer to this diagram representing a
          sample view from the end of <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשת
            וישב</span><span dir="LTR" /><span dir="LTR" />.</p>
        <p className="MsoNormal"><img width={623} height={360} id="Picture 70" src="helpDialogImages/image066.jpg" /></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Navigation</u></b></p>
        <p className="MsoNormal">On top of the Tanach Viewer, a section will appear <span style={{fontSize: '14.0pt'}}>(</span><b><span style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'red'}}>1</span></b><span style={{fontSize: '14.0pt'}}>) </span>
          that will look like the Placement tab selection in the Advanced Search settings
          where one can navigate to a different place in Tanach by selecting a named
          section of Tanach with the <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>קטע
              מסויים</span></b><span dir="LTR" /><span dir="LTR" /> option…..</p>
        <p className="MsoNormal"><img width={623} height={16} id="Picture 67" src="helpDialogImages/image067.jpg" /></p>
        <p className="MsoNormal">…..or by selecting a specific ranges of pesukim with the <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טווח
              פסוקים</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>option.</p>
        <p className="MsoNormal"><img width={624} height={16} id="Picture 68" src="helpDialogImages/image068.jpg" /></p>
        <p className="MsoNormal">One can also move to the next or previous set of pesukim by
          clicking the <b><span style={{fontSize: '16.0pt'}}>&lt;</span></b> or <b><span style={{fontSize: '16.0pt'}}>&gt;</span></b> arrow buttons <span style={{fontSize: '14.0pt'}}>(</span><b><span style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'red'}}>2</span></b><span style={{fontSize: '14.0pt'}}>)</span>.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">If the viewer has been launched from a search results grid
          or any other listing of pesukim then one can also move to the next or previous pasuk
          result by clicking the <b><span style={{fontSize: '16.0pt'}}>&lt;&lt;</span></b> or
          <b><span style={{fontSize: '16.0pt'}}>&gt;&gt;</span></b> double-arrow buttons <span style={{fontSize: '14.0pt'}}>(</span><b><span style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'red'}}>2</span></b><span style={{fontSize: '14.0pt'}}>)</span>.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Controlling the viewer window</u></b></p>
        <p className="MsoNormal">There are three control icons together on the top of the
          viewer. <span style={{fontSize: '14.0pt'}}>(</span><b><span style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'red'}}>3</span></b><span style={{fontSize: '14.0pt'}}>)</span>&nbsp; Clicking on:</p>
        <table className="MsoTableGrid" border={0} cellSpacing={0} cellPadding={0} style={{borderCollapse: 'collapse', border: 'none'}}>
          <tbody><tr style={{height: '.2in'}}>
              <td width={30} valign="top" style={{width: '22.25pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal"><img width={24} height={24} id="Picture 75" src="helpDialogImages/image069.png" /></p>
              </td>
              <td width={594} style={{width: '445.25pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal">closes the viewer window</p>
              </td>
            </tr>
            <tr style={{height: '.2in'}}>
              <td width={30} valign="top" style={{width: '22.25pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal"><img width={24} height={24} id="Picture 71" src="helpDialogImages/image070.png" /></p>
              </td>
              <td width={594} style={{width: '445.25pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal">expands the window to full-screen view</p>
              </td>
            </tr>
            <tr style={{height: '.2in'}}>
              <td width={30} valign="top" style={{width: '22.25pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal"><img width={24} height={24} id="Picture 73" src="helpDialogImages/image071.png" /></p>
              </td>
              <td width={594} style={{width: '445.25pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal">resizes to split-screen view by filling out the area
                  directly under the search bar and results</p>
              </td>
            </tr>
          </tbody></table>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Presentation options</u></b></p>
        <p className="MsoNormal"><b>Pasuk layout</b> (this is also described in the Parsha
          Viewer).&nbsp; Clicking on: <span style={{fontSize: '14.0pt'}}>(</span><b><span style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'red'}}>4</span></b><span style={{fontSize: '14.0pt'}}>)</span></p>
        <table className="MsoTableGrid" border={0} cellSpacing={0} cellPadding={0} style={{borderCollapse: 'collapse', border: 'none'}}>
          <tbody>
            <tr style={{height: '.2in'}}>
              <td width={30} valign="top" style={{width: '22.5pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal"><img width={29} height={26} id="Picture 84" src="helpDialogImages/image038.png" /></p>
              </td>
              <td width={594} style={{width: '445.25pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal">&nbsp;shows a stream view of the pesukim, where a pasuk flows from
                  the previous one without a break</p>
              </td>
            </tr>
            <tr style={{height: '.2in'}}>
              <td width={30} valign="top" style={{width: '22.5pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal"><img width={29} height={27} id="Picture 85" src="helpDialogImages/image039.png" /></p>
              </td>
              <td width={594} style={{width: '445.25pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal">&nbsp;shows a tabular grid view of the pesukim <i>(</i><i><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כתב
                      סת"ם</span><span dir="LTR" /><span dir="LTR" />
                    and </i><i><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כתב
                      עברי</span><span dir="LTR" /><span dir="LTR" />
                    won’t be available in this view)</i></p>
              </td>
            </tr>
            <tr style={{height: '.2in'}}>
              <td width={30} valign="top" style={{width: '22.5pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal"><img width={29} height={26} id="Picture 84" src="helpDialogImages/image072.png" /></p>
              </td>
              <td width={594} style={{width: '445.25pt', padding: '0in 0in 0in 0in', height: '.2in'}}>
                <p className="MsoNormal">&nbsp;shows a tag cloud based upon words, shorashim, gematria, etc. (<i>depending on selected settings</i>)</p>
              </td>
            </tr>
          </tbody></table>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">The <b><span style={{fontFamily: '"Franklin Gothic Heavy",sans-serif'}}>A+ </span></b>
          and <b><span style={{fontFamily: '"Franklin Gothic Heavy",sans-serif'}}>A- </span></b>
          buttons will enlarge or shrink the <b>font size</b> of the pesukim.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b>Multiple parallel content columns</b> <span style={{fontSize: '14.0pt'}}>(</span><b><span style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'red'}}>5</span></b><span style={{fontSize: '14.0pt'}}>) </span>
          at least one or all can be selected with the options being:</p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>תנ"ך</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– pesukim including <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>נקודות</span><span dir="LTR" /><span dir="LTR" /> and <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעמים</span><span dir="LTR" /><span dir="LTR" />. This default view will appear when the
          viewer is first opened</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>תרגום</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– the Targum Onkelos (available
          only for the Chumash)</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כתב סת"ם</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– the way pesukim
          appear in handwritten <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ספרי
            קודש</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כתב עברי</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– <i>experimental
            feature – more details on this in the documentation on Parsha Viewer</i></p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>גמטריא</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– a stream of numbers representing the standard gematria of each word in a pasuk</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>אתבש</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– a stream of numbers with the <i>atbash</i> gematria values</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b>Number of pesukim to display</b> <span style={{fontSize: '14.0pt'}}>(</span><b><span style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'red'}}>6</span></b><span style={{fontSize: '14.0pt'}}>)</span>.&nbsp; In general,
          the viewer will load faster when fewer pesukim need to load, but sometimes it’s
          easier to just scroll and navigate through lots of pesukim.&nbsp; By clicking on this
          dropdown, one can select to view <b>5, 11, 21, 41 or 101</b> pesukim at a time.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Parallel alignment of columns in the view</u></b></p>
        <p className="MsoNormal">When more than one parallel column is selected to appear,
          hovering the mouse over a word or phrase will cause the word/phrase to be
          highlighted in a darker color and its pasuk to be highlighted in a lighter
          color.&nbsp; The corresponding content across all tabs displayed will get the same
          highlighting.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">Since there is often not a one to one relationship between
          the wording of a pasuk and the wording of its targum, the highlighting will
          strive to align those 1:N and N:1 relationships <span style={{fontSize: '14.0pt'}}>(</span><b><span style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'red'}}>7</span></b><span style={{fontSize: '14.0pt'}}>)</span>.&nbsp; This should be considered an experimental
          feature for now.&nbsp; While this is normally about 95% accurate, it tends to be
          much less accurate for sections that wax poetic such as <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ברכת
            יעקב, ברכת
            משה, שירת הים</span><span dir="LTR" /><span dir="LTR" />.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default TanachViewer_Instructions;