import React, { Component } from 'react';

class Distances_Examples extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שימוש
                בכרטיסיית
                מרחקים</span></i></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>חפש
              פסוקים
              שמתחילים
              במילה "וידבר"
              שאינם הפסוק
              הראשון של
              פרשה</span></i></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>חיפוש
            <b>וידבר אל*
              לאמר</b>
            באמצעות
            החיפוש הפשוט
            יחזיר 114
            פסוקים מבראשית
            ח:טו לדברי
            הימים ב:י:יד.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><img width={386} height={62} src="helpDialogImages/image028.jpg" align="left" hspace={12} /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פתח
            את החיפוש
            המתקדם ולחץ
            על הכרטיסייה <b>מרחקים</b>.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כאשר <b>וידבר</b></span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>נבחר
            בבורר מונחי
            החיפוש, קבע
            את המרחק
            מהמונח לראש
            פסוק כ- 0 מילים
            בדיוק</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ואז
            הגדר מרחק לראש
            פרשה כטווח
            בין 1 ל- ∞
            (אינסוף). זה
            יחזיר תשעה
            פסוקים.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>(אזהרה
              - דוגמה זו
              גוזלת זמן
              כרגע והיא
              יכולה לארוך 90
              שניות ומעלה)</span></i></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span dir="LTR">&nbsp;</span></i></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>חפש
              פסוקים שיש
              בהם את המילה </span></i><span dir="LTR" /><i><span dir="LTR"><span dir="LTR" />"</span></i><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ישראל</span></i><span dir="LTR" /><i><span dir="LTR"><span dir="LTR" />"</span></i><span dir="RTL" /><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
              כמילה אחרונה
              של ספר.</span></i></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><img width={385} height={85} src="helpDialogImages/image029.jpg" align="left" hspace={12} /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הגדר
            את המרחק
            מהמונח לסוף
            פסוק כ- 0 מילים
            בדיוק ואז
            חפש. תקבלו את
            שני הפסוקים בראשית
            לד:יב ושמואל
            ב:כד:כה</span></p>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b><i>Using the Distances tab</i></b></p>
        <p className="MsoNormal"><i>Search for pesukim beginning with word </i><i><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>וידבר</span></i><span dir="LTR" /><i><span dir="LTR" /> that are not the first pasuk of a
            parsha</i></p>
        <p className="MsoNormal">Searching <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>וידבר
              אל* לאמר</span></b><span dir="LTR" /><span dir="LTR" /> with the simple search will return 114 pesukim from <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בראשית
            ח:טו</span><span dir="LTR" /><span dir="LTR" /> to <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>דברי
            הימים ב:י:יד</span><span dir="LTR" /><span dir="LTR" />.</p>
        <p className="MsoNormal"><img width={386} height={61} src="helpDialogImages/image028.jpg" align="right" hspace={12} />Open the
          advanced search and click on the <b>Distances</b> tab.&nbsp; With <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>וידבר</span></b><span dir="LTR" /><span dir="LTR" /> selected in the search term selector, set
          distance from the term to rosh pasuk as exactly 0 words and then set distance
          to rosh parsha in as a range from 1 to <span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: '#222222'}}>∞ </span>(infinity).&nbsp;
          This will return nine pesukim.&nbsp; </p>
        <p className="MsoNormal"><i><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>)</span>warning
            – this one is time consuming at the present and can take 90 seconds or more)</i></p>
        <p className="MsoNormal"><i>&nbsp;</i></p>
        <p className="MsoNormal"><i>Search for pesukim that have the word </i><i><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ישראל</span></i><span dir="LTR" /><i><span lang="HE"><span dir="LTR" /> </span></i><i>as the last
            word of a sefer</i></p>
        <p className="MsoNormal"><img width={385} height={84} src="helpDialogImages/image029.jpg" align="right" hspace={12} />Set
          distance from the term to sof pasuk as exactly 0 words and then search.&nbsp; You
          will get the two pesukim <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בראשית
            לד:יב</span><span dir="LTR" /><span dir="LTR" />
          &nbsp;and <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שמואל
            ב:כד:כה</span></p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default Distances_Examples;