import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { Link, withRouter } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import { Button as BootstrapButton } from 'reactstrap';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import LoadingOverlay from 'react-loading-overlay';
import { Fab, TextField, Popover, Paper, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, List, ListItem, ListItemText, FormControl, Select, MenuItem, FormHelperText, InputLabel, FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PlacementRenderer from './PlacementRenderer';
import {Spinner} from 'primereact/spinner';
import Bookmarks from '@material-ui/icons/Bookmarks';
import * as properties from './config/properties.js';
import {Checkbox} from 'primereact/checkbox';
import { Menu, Item, Separator, Submenu, MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import ContextMenu from './ContextMenu';
import PlacementSelector2D from './PlacementSelector2D';
import Slider from 'rc-slider';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SettingsIcon from '@material-ui/icons/Settings';
import TextComparatorSectionSelector from './TextComparatorSectionSelector';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const styles = theme => ({
  toggleContainer: {
    height: 28,
    backgroundColor: 'transparent',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    alignItems: 'center',
    margin: `auto`,
    background: theme.palette.background.default,
  },
});

//todo this is repeated elsewhere - combine it
function encodeFilterIds(nodes, nodeString) {
    var nodeStr = (nodeString != undefined) ? nodeString : "0".repeat(44);
    nodes.forEach((node)=>{
        if (node.tableName == "Sefer" && node.isChecked)
            nodeStr = util.stuff(nodeStr, "1", node.id-1, 1);
        if(node.children){
            nodeStr = encodeFilterIds(node.children, nodeStr);
        }
    })
    return nodeStr;
}

function getAlternateRowStyle(params) {
    if (params.node.rowIndex % 2 === 0) {
        return {background: '#F0F0F0'};
    }
}

function namedSection(params) {
  return '<Spinner name="ns" id="ns" value={1} min={1} max={10} />';
}

function pasukRange(params) {
  return <Spinner name="pr" id="pr"  value={1} min={1} max={10} />;
}

function BaseParameters() {
  this.source = null;
  this.clientIP = null;
  this.morphInflections = null;
  this.filterIds = null;
  this.nGrams = null;
  this.sortResultsBy = null;
  this.resultsToDisplay = null;
  this.namedLocations =  []; //NamedLocation()
  this.namedLocations2 =  []; //NamedLocation()
  this.pasukRangeLocations = []; //PerekPasukRange()
  this.pasukRangeLocations2 = []; //PerekPasukRange()
  this.textSource = 1;
}

function NamedLocation(kriaId, aliyaId) {
  this.kriaId =  kriaId; //int
  this.aliyaId = aliyaId; //int
}

function PerekPasukRange(seferId1, perekNum1, pasukNum1, seferId2, perekNum2, pasukNum2) {
  this.seferId1 =  seferId1; //int
  this.perekNum1 = perekNum1;  //int
  this.pasukNum1 =  pasukNum1; //int
  this.seferId2 = seferId2;  //int
  this.perekNum2 =  perekNum2; //int
  this.pasukNum2 = pasukNum2;  //int
}

class LCSViewer extends Component {

//webServiceURL = properties.env.webServiceURL;

 constructor(props) {
    super(props);
    this.state = {
      clientIP: '',

      defaultLanguage: '',
      text1DialogOpen: false,
      text2DialogOpen: false,

      nGrams: 1,
      sortResultsByChosen: 1,
      sortResultsByCurrent: 1,
      resultsToDisplay: 1,
      textToCopy: 'bitui',
      useKinuim: false,
      searchAsQuote: false,
      includeMekorot: false,
      wordsToSkipIsOpen: false,
      wordsToSkipAnchorEl: null,
      searchSettingsIsOpen: false,
      searchSettingsAnchorEl: null,
      displaySettingsIsOpen: false,
      displaySettingsAnchorEl: null,
      columnDefs: this.getGridColumns(),
      searchedMorphInflections: this.props.morphInflections,
      // isLoading: false, // moved these up to TextComparatorViewer
      // rowData: [],
      // matchIndicesByPasuk: {},
      // totalTerms1: 0,
      // totalTerms2: 0,
      // termTotal1Accurate: true,
      // termTotal2Accurate: true,
      // direction: 'forward',
      // matchDistance: [1, 100],
      // wordLength:  [1, 11],
      // wordsToSkip: [],
      // otherWordsToSkip: [""],
      // fontSizeLevel: 1
    };

    this.refresh = this.refresh.bind(this);
    this.text1GridReady = this.text1GridReady.bind(this);
    this.addText1Row = this.addText1Row.bind(this);
    this.extAddText1Row = this.extAddText1Row.bind(this);
    this.deleteText1Row = this.deleteText1Row.bind(this);
    this.extDeleteText1Row = this.extDeleteText1Row.bind(this);
    this.extModifyText1Row = this.extModifyText1Row.bind(this);
    this.modifyText1Row = this.modifyText1Row.bind(this);
    this.text2GridReady = this.text2GridReady.bind(this);
    this.addText2Row = this.addText2Row.bind(this);
    this.extAddText2Row = this.extAddText2Row.bind(this);
    this.deleteText2Row = this.deleteText2Row.bind(this);
    this.extDeleteText2Row = this.extDeleteText2Row.bind(this);
    this.extModifyText2Row = this.extModifyText2Row.bind(this);
    this.modifyText2Row = this.modifyText2Row.bind(this);
    this.getRenderVals = this.getRenderVals.bind(this);
    this.handleText1DialogOpen = this.handleText1DialogOpen.bind(this);
    this.handleText1DialogClose = this.handleText1DialogClose.bind(this);
    this.handleText2DialogOpen = this.handleText2DialogOpen.bind(this);
    this.handleText2DialogClose = this.handleText2DialogClose.bind(this);
    this.getGridColumns = this.getGridColumns.bind(this);
    this.onCellContextMenu = this.onCellContextMenu.bind(this);
    this.toggleUseKinuim = this.toggleUseKinuim.bind(this);
    this.toggleSearchAsQuote = this.toggleSearchAsQuote.bind(this);
    this.setMatchDistance = this.setMatchDistance.bind(this);
    this.setWordLength = this.setWordLength.bind(this);
    this.handleOpenWordsToSkip = this.handleOpenWordsToSkip.bind(this);
    this.handleCloseWordsToSkip = this.handleCloseWordsToSkip.bind(this);
    this.handleSelectWordToSkip = this.handleSelectWordToSkip.bind(this);
    this.setOtherWordsToSkip = this.setOtherWordsToSkip.bind(this);
    this.handleOpenSearchSettings = this.handleOpenSearchSettings.bind(this);
    this.handleCloseSearchSettings = this.handleCloseSearchSettings.bind(this);
    this.handleOpenDisplaySettings = this.handleOpenDisplaySettings.bind(this);
    this.handleCloseDisplaySettings = this.handleCloseDisplaySettings.bind(this);
    this.decreaseFontSize = this.decreaseFontSize.bind(this);
    this.increaseFontSize = this.increaseFontSize.bind(this);
    this.setResultsFontSize = this.setResultsFontSize.bind(this);
    this.saveText1Data = this.saveText1Data.bind(this);
    this.saveText2Data = this.saveText2Data.bind(this);


    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}
  }

  decreaseFontSize(){
    var fontSizeLevel = this.props.LCSData.fontSizeLevel;
    if(fontSizeLevel > 1){
      fontSizeLevel--;
      this.props.extUpdateLCSData({
        fontSizeLevel: fontSizeLevel
      }, () => {
        this.setResultsFontSize();
      })
    }
  }

  increaseFontSize(){
    var fontSizeLevel = this.props.LCSData.fontSizeLevel;
    if(fontSizeLevel < 6){
      fontSizeLevel++;
      this.props.extUpdateLCSData({
        fontSizeLevel: fontSizeLevel
      }, () => {
        this.setResultsFontSize();
      })
    }
  }

  setResultsFontSize(){
    var styleEl = document.createElement('style');
    document.head.appendChild(styleEl);
    var styleSheet = styleEl.sheet;

    var fontSize = (this.props.LCSData.fontSizeLevel+6)*2;
    var rowHeight = (this.props.LCSData.fontSizeLevel*3)+21;

    styleSheet.insertRule('.LCS-text-cell' + `{
            font-size: ${fontSize}px !important;
        }`, styleSheet.cssRules.length);

    var fakeThis = this;
    this.resultsApi.forEachNode( function (rowNode) {
        rowNode.setRowHeight(rowHeight);
    })
    this.resultsApi.onRowHeightChanged();

    var newGridColumns = this.getGridColumns()

    this.setState({ columnDefs: newGridColumns });
  }

  handleOpenDisplaySettings(e){
    this.setState({
      displaySettingsIsOpen: true,
      displaySettingsAnchorEl: e.currentTarget
    })
  }

  handleCloseDisplaySettings(){
    this.setState({
      displaySettingsIsOpen: false,
      displaySettingsAnchorEl: null
    })
  }

  handleOpenSearchSettings(e){
    this.setState({
      searchSettingsIsOpen: true,
      searchSettingsAnchorEl: e.currentTarget
    })
  }

  handleCloseSearchSettings(){
    this.setState({
      searchSettingsIsOpen: false,
      searchSettingsAnchorEl: null
    })
  }

  setOtherWordsToSkip(e){
    var wordsString = e.target.value;
    var wordsArr = wordsString.split(" ")
    //remove old words
    var oldWords = this.props.LCSData.otherWordsToSkip;
    oldWords.map(word => {
      if(!wordsArr.includes(word)){
        var e = {target: {attributes: {value: {value: word}}}}
        this.handleSelectWordToSkip(e)
      }
    })
    //add new words
    wordsArr.map(word => {
      if(!this.props.LCSData.wordsToSkip.includes(word)){
        var e = {target: {attributes: {value: {value: word}}}}
        this.handleSelectWordToSkip(e)
      }
    })
    this.props.extUpdateLCSData({
      otherWordsToSkip: wordsArr
    })
  }

  handleOpenWordsToSkip(e){
    this.setState({ wordsToSkipAnchorEl: e.currentTarget, wordsToSkipIsOpen: true })
  }

  handleCloseWordsToSkip(){
    this.setState({ wordsToSkipAnchorEl: null, wordsToSkipIsOpen: false })
  }

  handleSelectWordToSkip(e){
    var val = e.target.attributes.value.value // e.target.value isn't working properly here
    var wordsToSkip = this.props.LCSData.wordsToSkip;
    if(val === 'clearAll'){
      wordsToSkip = [];
      this.setOtherWordsToSkip({target:{value:""}})
    } else {
      if(wordsToSkip.includes(val)){
        var valIndex = wordsToSkip.indexOf(val);
        wordsToSkip.splice(valIndex, 1);
        //remove from other words
        if(this.props.LCSData.otherWordsToSkip.includes(val)){
          var otherWords = this.props.LCSData.otherWordsToSkip;
          var valIndex = otherWords.indexOf(val);
          otherWords.splice(valIndex, 1);
          this.props.extUpdateLCSData({
            otherWordsToSkip: otherWords
          })
        }
      } else {
        wordsToSkip.push(val);
      }
    }
    wordsToSkip = wordsToSkip.sort()
    this.props.extUpdateLCSData({ wordsToSkip })
  }

  getDisplayValue(value){
    if(value === 100){
      value = '∞'
    }
    return `${value}`
  }

  getDisplayVals(vals){
    var displayVals = vals;
    displayVals = displayVals.map(val => {
      if(val === 100){
        val = '∞'
      }
      return val;
    })
    return displayVals;
  }

  setTextDirection(e, val){
    if(val){
      this.props.extUpdateLCSData({ direction: val })
    }
  }

  setMatchDistance(vals){
    this.props.extUpdateLCSData({ matchDistance: vals })
  }

  setWordLength(vals){
    this.props.extUpdateLCSData({ wordLength: vals })
  }

  toggleSearchAsQuote(){
    this.setState({ searchAsQuote: !this.state.searchAsQuote })
  }

    getGridColumns(){
        var fakeThis = this
        return [
                  {headerName: "טקסט", field: "value1.pasukText", width: 473, cellClass: 'row-thin-small LCS-text-cell', cellRenderer: function (params) {return fakeThis.pasukTextCellRenderer(params, "value1")}},
                  {headerName: "פסוק", field: "value1.location", width: 97, cellClass: 'row-thin-small', cellRenderer: function (params) {return fakeThis.locationCellRenderer(params, "value1")}},
                  {headerName: "תיבה", field: "value1.text", width: 77, cellClass: 'row-thin-small'} ,
                  {headerName: "מרחק", field: "value1.distanceFromPrev", width: 82, cellClass: 'row-thin-small'} ,
                  {headerName: "#", field: "rowNum", width: 60, headerClass: "ag-header-cell-center", cellClass: "row-header-center", filter: 'agNumberColumnFilter'},
                  {headerName: "מרחק", field: "value2.distanceFromPrev", width: 82, cellClass: 'row-thin-small'} ,
                  {headerName: "תיבה", field: "value2.text", width: 77, cellClass: 'row-thin-small'} ,
                  {headerName: "פסוק", field: "value2.location", width: 97, cellClass: 'row-thin-small', cellRenderer: function (params) {return fakeThis.locationCellRenderer(params, "value2")}},
                  {headerName: "טקסט", field: "value2.pasukText", width: 473, cellClass: 'row-thin-small LCS-text-cell', cellRenderer: function (params) {return fakeThis.pasukTextCellRenderer(params, "value2")}},
               ];
    }

    locationCellRenderer(params, sideName){
      var location = "";
      if(params.rowIndex === 0){
        location = params.data[sideName].location
      } else {
        if(params.data[sideName].location !== this.props.LCSData.rowData[params.rowIndex - 1][sideName].location){
          location = params.data[sideName].location
        }
      }
      return `<b>${location}</b>`
    }

    pasukTextCellRenderer(params, sideName){
      var pasukText = "";
      if(params.rowIndex === 0 || params.data[sideName].location !== this.props.LCSData.rowData[params.rowIndex - 1][sideName].location){
        pasukText = params.data[sideName].pasukText;
      }
      var matchIndices = this.props.LCSData.matchIndicesByPasuk[sideName][params.data[sideName].location];


      var pasukTextArr = pasukText.split(" ");
      for(let i = 0; i < pasukTextArr.length; i++){
        var currWord = pasukTextArr[i];
        if(matchIndices[i+1]){
          //color word
          currWord = `<span style="font-weight: bold; color: ${matchIndices[i+1]};">${currWord}</span>`
        }else{
          currWord = `<span>${currWord}</span>`
        }
        pasukTextArr[i] = currWord;
      }
      pasukText = pasukTextArr.join(" ")

      if(sideName === "value1"){
        pasukText = `<div style="text-align: left; text-overflow: ellipsis; overflow: hidden; ">${pasukText}</div>`
      } else {
        pasukText = `<div style="text-align: right; text-overflow: ellipsis; overflow: hidden; ">${pasukText}</div>`
      }
      return pasukText;
    }

    resultsGridReady = params => {
      this.resultsApi = params.api;
      this.resultsColumnApi = params.columnApi;
      this.setResultsFontSize() // adding this here so ag-grid gets reformatted properly after changing font size, switching tabs, then switching back
    };

    text1GridReady = params => {
      this.props.text1GridReady(params);
//       this.text1Api = params.api;
//       this.text1ColumnApi = params.columnApi;

//       this.text1Api.setRowData(this.props.text1Data);

// //following CSS classes seem to get overridden below in PlacementRenderer by placementGridLine and rangeGridLine
//       this.text1Api.setColumnDefs(
//                [{headerName: this.props.translate("addLines"), headerClass: 'row-thin-center', field: "namedSection", width: 1168, cellClass: "row-thin-small", cellRendererFramework: PlacementRenderer, cellRendererParams:
//                    {
//                     extModifyPlacementRow: this.extModifyText1Row,
//                     extAddPlacementRow: this.extAddText1Row,
//                     extDeletePlacementRow: this.extDeleteText1Row,
//                     translate: this.props.translate, //do I need to pass this here?
//                 },
//           //cellClass: function(params) {return params.data.type ? 'placementGridLine' : 'rangeGridLine';},
//                 }]
//           );
//       if (this.props.text1Data.length > 0)
//           this.text1Api.setFocusedCell(0, "namedSection", null);
//       // var instances = this.refs.agGrid.gridOptions.api.getCellRendererInstances(params);
    };

    text2GridReady = params => {
      this.props.text2GridReady(params);
//       this.text2Api = params.api;
//       this.text2ColumnApi = params.columnApi;
//       this.text2Api.setRowData(this.props.text2Data);

// //following CSS classes seem to get overridden below in PlacementRenderer by placementGridLine and rangeGridLine
//       this.text2Api.setColumnDefs(
//                [{headerName: this.props.translate("addLines"), headerClass: 'row-thin-center', field: "namedSection", width: 1168, cellClass: "row-thin-small", cellRendererFramework: PlacementRenderer, cellRendererParams:
//                    {
//                     extModifyPlacementRow: this.extModifyText2Row,
//                     extAddPlacementRow: this.extAddText2Row,
//                     extDeletePlacementRow: this.extDeleteText2Row,
//                     translate: this.props.translate, //do I need to pass this here?
//                 },
//           //cellClass: function(params) {return params.data.type ? 'placementGridLine' : 'rangeGridLine';},
//                 }]
//           );
//       if (this.props.text2Data.length > 0)
//           this.text2Api.setFocusedCell(0, "namedSection", null);
//       // var instances = this.refs.agGrid.gridOptions.api.getCellRendererInstances(params);
    };

    addText1Row() {
      this.props.addText1Row();
        // var newRow = {type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []};
        // var newData = this.props.text1Data;
        // newData.push(newRow);
        // this.setState( {text1Data: newData}, () => {this.text1Api.updateRowData({ add: [newRow] })});
    }

    addText2Row() {
      this.props.addText2Row();
        // var newRow = {type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []};
        // var newData = this.props.text2Data;
        // newData.push(newRow);
        // this.setState( {text2Data: newData}, () => {this.text2Api.updateRowData({ add: [newRow] })});
    }

    extAddText1Row = () => {
        this.props.addText1Row();
    }

    extAddText2Row = () => {
        this.props.addText2Row();
    }

    deleteText1Row(extState) {
      this.props.deleteText1Row(extState);
        // this.props.extUpdateLCSData({termTotal1Accurate: false})
        // var selected = this.text1Api.getFocusedCell();
        // var i = selected.rowIndex;
        // if (i == this.text1Api.getLastDisplayedRow()) { //then just clear
        //     this.modifyText1Row(extState);
        //     //selected.setSelected(false);
        // }
        // else {
        //     var selectedData = this.text1Api.getSelectedRows();
        //     var newData = this.props.text1Data;
        //     newData.splice(i,1);
        //     this.setState( {text1Api: newData}, () => {this.text1Api.updateRowData({ remove: selectedData }) });
        // }
    }

    deleteText2Row(extState) {
      this.props.deleteText2Row(extState)
        // this.props.extUpdateLCSData({termTotal2Accurate: false})
        // var selected = this.text2Api.getFocusedCell();
        // var i = selected.rowIndex;
        // if (i == this.text2Api.getLastDisplayedRow()) { //then just clear
        //     this.modifyText2Row(extState);
        //     //selected.setSelected(false);
        // }
        // else {
        //     var selectedData = this.text2Api.getSelectedRows();
        //     var newData = this.props.text2Data;
        //     newData.splice(i,1);
        //     this.setState( {text2Api: newData}, () => {this.text2Api.updateRowData({ remove: selectedData }) });
        // }
    }

    extDeleteText1Row = (extState) => {
        this.props.deleteText1Row(extState);
    }

    extDeleteText2Row = (extState) => {
        this.props.deleteText2Row(extState);
    }

    extModifyText1Row = (extState) => {
        this.props.modifyText1Row(extState);
    }

    extModifyText2Row = (extState) => {
        this.props.modifyText2Row(extState);
    }

    modifyText1Row(extState) {
      this.props.modifyText1Row(extState);
        // this.props.extUpdateLCSData({termTotal1Accurate: false})

        // var isAddRow = (extState.type && extState.val4 != this.props.text1Data.val4 && extState.val4 != 0) || (!extState.type && extState.val3 != this.props.text1Data.val3 && extState.val3 != 0 && extState.val6 != this.state.text1Data.val6 && extState.val6 != 0);
        // var selected = this.text1Api.getFocusedCell();

        // var i = selected.rowIndex;
        // var rowValues = {type: extState.type, val1: extState.val1, val2: extState.val2, val3: extState.val3, val4: extState.val4, val5: extState.val5, val6: extState.val6,
        //                                       valList1: extState.valList1, valList2: extState.valList2, valList3: extState.valList3, valList4: extState.valList4, valList5: extState.valList5, valList6: extState.valList6 };
        // var newData = this.props.text1Data;
        // newData[i] = rowValues;

        // this.setState( {text1Data: newData}, () => {
        //     if (i == this.text1Api.getLastDisplayedRow() && isAddRow) {
        //         this.addText1Row();
        //     }
        // });

    }

    modifyText2Row(extState) {
      this.props.modifyText2Row(extState);
        // this.props.extUpdateLCSData({termTotal2Accurate: false})

        // var isAddRow = (extState.type && extState.val4 != this.props.text2Data.val4 && extState.val4 != 0) || (!extState.type && extState.val3 != this.props.text2Data.val3 && extState.val3 != 0 && extState.val6 != this.state.text2Data.val6 && extState.val6 != 0);
        // var selected = this.text2Api.getFocusedCell();

        // var i = selected.rowIndex;
        // var rowValues = {type: extState.type, val1: extState.val1, val2: extState.val2, val3: extState.val3, val4: extState.val4, val5: extState.val5, val6: extState.val6,
        //                                       valList1: extState.valList1, valList2: extState.valList2, valList3: extState.valList3, valList4: extState.valList4, valList5: extState.valList5, valList6: extState.valList6 };
        // var newData = this.props.text2Data;
        // newData[i] = rowValues;

        // this.setState( {text2Data: newData}, () => {
        //     if (i == this.text2Api.getLastDisplayedRow() && isAddRow) {
        //         this.addText2Row();
        //     }
        // });
    }

    handleText1DialogOpen() {
        this.setState({ text1DialogOpen: true })
    }

    handleText1DialogClose() {
        this.setState({ text1DialogOpen: false })
    }

    handleText2DialogOpen() {
        this.setState({ text2DialogOpen: true })
    }

    handleText2DialogClose() {
        this.setState({ text2DialogOpen: false })
    }

   getRenderVals(textDataRow){
        var renderVal1, renderVal2, renderVal3, renderVal4, renderVal5, renderVal6
        var renderVals
        if(textDataRow.type){ //is named section
            var selectedVal1 = textDataRow.valList1.filter(obj => {return obj.id === textDataRow.val1});
            var selectedVal2 = textDataRow.valList2.filter(obj => {return obj.id === textDataRow.val2});
            var selectedVal3 = textDataRow.valList3.filter(obj => {return obj.id === textDataRow.val3});
            var selectedVal4 = textDataRow.valList4.filter(obj => {return obj.id === textDataRow.val4});
            var selectedVal5 = textDataRow.valList5.filter(obj => {return obj.id === textDataRow.val5});

            if(selectedVal1[0]){ renderVal1 = selectedVal1[0].value+' > '}
            if(selectedVal2[0]){ renderVal2 = selectedVal2[0].value+' > '}
            if(selectedVal3[0]){ renderVal3 = selectedVal3[0].value+' > '}
            if(selectedVal4[0]){ renderVal4 = selectedVal4[0].value+' > '}
            if(selectedVal5[0]){ renderVal5 = selectedVal5[0].value+' > '}

            var renderVals = [renderVal1, renderVal2, renderVal3, renderVal4, renderVal5]

            for(let i = 4; i >= 0; i--){
                if(renderVals[i]){
                    renderVals[i] = renderVals[i].substring(0, renderVals[i].length - 3)
                    break
                }
                //if it is defined, set the one after it as the last value
            }

        } else { //is pasuk range
            var selectedVal1 = textDataRow.valList1.filter(obj => {return obj.seferId === textDataRow.val1});
            var selectedVal2 = textDataRow.valList2.filter(obj => {return obj.perekNum === textDataRow.val2});
            var selectedVal3 = textDataRow.valList3.filter(obj => {return obj.pasukNum === textDataRow.val3});
            var selectedVal4 = textDataRow.valList4.filter(obj => {return obj.seferId === textDataRow.val4});
            var selectedVal5 = textDataRow.valList5.filter(obj => {return obj.perekNum === textDataRow.val5});
            var selectedVal6 = textDataRow.valList6.filter(obj => {return obj.pasukNum === textDataRow.val6});

            if(selectedVal1[0]){ renderVal1 = selectedVal1[0].seferName}
            if(selectedVal2[0]){ renderVal2 = selectedVal2[0].perekValue}
            if(selectedVal3[0]){ renderVal3 = selectedVal3[0].pasukValue}
            if(selectedVal4[0]){ renderVal4 = selectedVal4[0].seferName}
            if(selectedVal5[0]){ renderVal5 = selectedVal5[0].perekValue}
            if(selectedVal6[0]){ renderVal6 = selectedVal6[0].pasukValue}

            renderVals = [renderVal1, renderVal2, renderVal3, renderVal4, renderVal5, renderVal6]
        }

        return renderVals
   }

  async componentDidMount() {
      this.setLanguage(localStorage.getItem("language"));
  }

  async refresh() {
    this.props.extUpdateLCSData({ isLoading: true }, async () => {
      var baseParams = new BaseParameters();
      // baseParams.nGrams = this.state.nGrams;
      // baseParams.sortResultsBy = this.state.sortResultsByChosen;
      // baseParams.resultsToDisplay = this.state.resultsToDisplay;
      baseParams.source = 'bhsWebApp';
  
      var filterIds = encodeFilterIds(this.props.extFilterData);
      filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
      filterIds = util.right("00000000000"+filterIds,11);
  
      baseParams.filterIds = filterIds;
      baseParams.morphInflections = this.props.extSearchDefData; //str
  
      var e, i;
      for (i = 0; i < this.props.text1Data.length-1; i++) { //last position in placementRowData is never populated
          e = this.props.text1Data[i];
          if (e.type)
              baseParams.namedLocations.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
          else
              baseParams.pasukRangeLocations.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
      }
  
      for (i = 0; i < this.props.text2Data.length-1; i++) { //last position in placementRowData is never populated
          e = this.props.text2Data[i];
          if (e.type)
              baseParams.namedLocations2.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
          else
              baseParams.pasukRangeLocations2.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
      }
  
      const colDefs = this.getGridColumns();

      baseParams.isReverse = this.props.LCSData.direction === 'reverse';
      baseParams.textSource = this.props.textSource==='targum'?2:1;
      // baseParams.matchDistance = this.props.LCSData.matchDistance;
      // baseParams.wordLength = this.props.LCSData.wordLength;
      baseParams.wordsToSkip = this.props.LCSData.wordsToSkip;
      baseParams.minGap = this.props.LCSData.matchDistance[0];
      baseParams.maxGap = this.props.LCSData.matchDistance[1];
      baseParams.minWordLength = this.props.LCSData.wordLength[0];
      baseParams.maxWordLength = this.props.LCSData.wordLength[1];
      const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getLCS', {method: 'post', body: JSON.stringify(baseParams)});
      const body = await response.json();
  
      var totalTerms1 = body.totalBlock1;
      var totalTerms2 = body.totalBlock2;
  
      var rowData = body.results;
      var matchIndicesByPasuk = {
        value1: {},
        value2: {},
      }
      //reformat
      var colors = ["red", "blue", "green", "brown", "darkorange", "blueviolet"];
      for(let i = 0; i < rowData.length; i++){
          rowData[i].rowNum = i+1;
          if(i === 0){
            rowData[i].value1.distanceFromPrev = 0;
            rowData[i].value2.distanceFromPrev = 0;
          } else {
            rowData[i].value1.distanceFromPrev = Math.abs(rowData[i].value1.teivosToRoshSefer - rowData[i-1].value1.teivosToRoshSefer);
            rowData[i].value2.distanceFromPrev = Math.abs(rowData[i].value2.teivosToRoshSefer - rowData[i-1].value2.teivosToRoshSefer);
          }

          //add word/color pairs to matchIndicesByPasuk
          //value1
          var currColor = colors[i%6]
          if(!matchIndicesByPasuk.value1[rowData[i].value1.location]){
            matchIndicesByPasuk.value1[rowData[i].value1.location] = {}
          }
          matchIndicesByPasuk.value1[rowData[i].value1.location][rowData[i].value1.pasukSequenceNum] = currColor;
          //value2
          if(!matchIndicesByPasuk.value2[rowData[i].value2.location]){
            matchIndicesByPasuk.value2[rowData[i].value2.location] = {}
          }
          matchIndicesByPasuk.value2[rowData[i].value2.location][rowData[i].value2.pasukSequenceNum] = currColor;

      }

      this.props.extUpdateLCSData({rowData: []}, () => {
        this.props.extUpdateLCSData({
          rowData: rowData,
          isLoading: false,
          totalTerms1: totalTerms1,
          totalTerms2: totalTerms2,
          termTotal1Accurate: true,
          termTotal2Accurate: true,
          matchIndicesByPasuk: matchIndicesByPasuk
        });
        this.setState({searchedMorphInflections: this.props.morphInflections})
      })
    })

  }

onCellContextMenu(e){
        this.setState({useKinuim: false, includeMekorot: false})
        const { innerWidth } = window;
        //style context menu
        var submenus = document.getElementsByClassName('react-contexify__submenu');
        for(let i = 0; i < submenus.length; i++){
            var submenu = submenus[i];
            var submenuWidth = parseInt(submenu.style.minWidth.slice(0, submenu.style.minWidth.length-2));
            if(this.props.activeLanguage.code === 'he'){
                if(e.event.clientX > submenuWidth){
                    submenu.style.left = '';
                    submenu.style.right = '100%';
                } else {
                    submenu.style.left = '100%';
                    submenu.style.right = '';
                }
            } else if(this.props.activeLanguage.code === 'en'){
                if(innerWidth-e.event.clientX-300 > submenuWidth){
                    submenu.style.left = '100%';
                    submenu.style.right = '';
                } else {
                    submenu.style.left = '';
                    submenu.style.right = '100%';
                }
            }
        }


        //set text to copy
        var textToCopy = '';

        var focusedCellRowIndex = null ;
        if(this.resultsApi.getFocusedCell()){
            var focusedCellRowIndex = this.resultsApi.getFocusedCell().rowIndex;
        }
        var focusedCellRowText = null;
        if((typeof(focusedCellRowIndex) === 'number') && this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex]){
            focusedCellRowText = this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].data.text;
        }

        //focused = row of focused cell; highlighted = highlighted text; selected = all selected rows;
        if(this.resultsApi.getFocusedCell() && !window.getSelection().toString()){
            textToCopy = 'bitui';
        } else if(window.getSelection().toString()){
            textToCopy = 'highlighted';
        }

        var word = e.event.target.innerText.split(" ")
        word = word[1] && word[1].length>0? null : word.join("")
        if(!util.isHebrew(word)) word=null
        this.setState({textToCopy,wordToCopy:word})
    }

    toggleUseKinuim(){
        this.setState({ useKinuim: !this.state.useKinuim })
    }

    handleResultsCellDoubleClicked(e){
      var location = "";
      var isText1 = e.colDef.field.includes("value1");
      var isText2 = e.colDef.field.includes("value2");
      if(isText1){
        location = e.data.value1.location
      } else if(isText2) {
        location = e.data.value2.location
      }
      if(isText1 || isText2){
        this.props.toggleTanachViewer(true, location, this.resultsApi);
        if (window.getSelection) { // un-highlight the text that was clicked on
            window.getSelection().removeAllRanges();
        } else if (document.selection) {
            document.selection.empty();
        }
      }

  }
  saveText1Data(data){
    this.setState({vals1:data})//saving to state to refresh the page when the data changes
  }
  saveText2Data(data){
    this.setState({vals2:data})//saving to state to refresh the page when the data changes
  }

  render() {
    const { classes } = this.props;
    const text1Data = this.props.text1Data;
    const text2Data = this.props.text2Data;

    const loadingText = this.props.translate("loading");
    const forward = this.props.translate("forward");
    const reverse = this.props.translate("reverse");
    const direction = this.props.translate("direction");
    const matchDistance = this.props.translate("matchDistance");
    const allowedWordLength = this.props.translate("allowedWordLength");
    const wordsToSkip = this.props.translate("wordsToSkip");
    const select = this.props.translate("select");
    const clearAll = this.props.translate("clearAll");
    const otherWords = this.props.translate("otherWords");
    const searchSettings = this.props.translate("searchSettings");
    const displaySettings = this.props.translate("displaySettings");
    const resultsFontSize = this.props.translate("resultsFontSize");
    const increaseFont = this.props.translate("increaseFont.caption");
    const decreaseFont = this.props.translate("decreaseFont.caption");
    const refreshDisabled = this.props.text1Data.length<2 || this.props.text2Data.length<2 || 
              (this.props.text1Data[0].type && this.props.text1Data[0].val4===0) || 
              (this.props.text2Data[0].type && this.props.text2Data[0].val4===0) || 
              ((!this.props.text1Data[0].type) && (this.props.text1Data[0].val3===0 || this.props.text1Data[0].val6===0)) || 
              ((!this.props.text2Data[0].type) && (this.props.text2Data[0].val3===0 || this.props.text2Data[0].val6===0))

    const fakeThis = this;
    const {isLoading, fontSizeLevel} = this.props.LCSData;
    const directionVal = this.props.LCSData.direction; //there is another term used in this component called direction so want to avoid confusion
    const {totalTerms1, totalTerms2, termTotal1Accurate, termTotal2Accurate} = this.props.wordCountData;

    var isHebrew = localStorage.getItem('language') === 'he';

    var viewerTableHeightNum = parseInt(this.props.viewerHeight.substring(0, this.props.viewerHeight.length - 2)) - 159;
    if(this.props.openResults.length > 0){
        viewerTableHeightNum -= 12;
    }
    const viewerTableHeight = `${viewerTableHeightNum}px`

    var directionStyling = {direction: 'ltr', textAlign: 'left'};
    var langDirection = 'ltr'
    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
        directionStyling = {direction: 'rtl', textAlign: 'right'};
        langDirection = 'rtl';
    }

    var totalTerms1Paper = <Paper elevation={1} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0'}}>
           <Typography variant='body1'>{this.props.translate("totalTerms")}: <b>{totalTerms1}</b></Typography>
       </Paper>
    if(!termTotal1Accurate){
        totalTerms1Paper = <Paper elevation={0} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0', backgroundColor: '#d7d7d7' }}>
           <Typography variant='body1' style={{color: "#9f9f9f"}} >{this.props.translate("totalTerms")}: <b>{totalTerms1}</b></Typography>
       </Paper>
    }

    var totalTerms2Paper = <Paper elevation={1} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0'}}>
           <Typography variant='body1'>{this.props.translate("totalTerms")}: <b>{totalTerms2}</b></Typography>
       </Paper>
    if(!termTotal2Accurate){
        totalTerms2Paper = <Paper elevation={0} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0', backgroundColor: '#d7d7d7' }}>
           <Typography variant='body1' style={{color: "#9f9f9f"}} >{this.props.translate("totalTerms")}: <b>{totalTerms2}</b></Typography>
       </Paper>
    }

    var matchDistanceVals = this.props.LCSData.matchDistance;
    var wordLengthVals = this.props.LCSData.wordLength;
    var wordsToSkipMenuItems = [
      { id: 'אל', value: 'אל' },
      { id: 'אשר', value: 'אשר' },
      { id: 'את', value: 'את' },
      { id: 'גם', value: 'גם' },
      { id: 'כי', value: 'כי' },
      { id: 'כל', value: 'כל' },
      { id: 'לא', value: 'לא' },
      { id: 'על', value: 'על' },
    ];
    const wordsToSkipVal = this.props.LCSData.wordsToSkip
    var noWordsToSkip = wordsToSkipVal.length === 0 || (wordsToSkipVal.length === 1 && wordsToSkipVal[0] === "");

    var searchSettingsInputCellStyle = {
      textAlign: isHebrew ? "left" : "right"
    }

    var searchSettingsLabelCellStyle = {
      textAlign: directionStyling.textAlign
    }

    var increaseFontButton = <Button aria-label={increaseFont} title={increaseFont} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.increaseFontSize() }}>
        <img src={require("./images/icons8-increase-font-52.png")} alt={increaseFont} width="20" height="20" />
    </Button>
    var decreaseFontButton = <Button aria-label={decreaseFont} title={decreaseFont} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.decreaseFontSize() }}>
        <img src={require("./images/icons8-decrease-font-52.png")} alt={decreaseFont} width="20" height="20" />
    </Button>
    if(fontSizeLevel === 6){
    increaseFontButton = <Button className='disabled-font-icon' disabled aria-label={increaseFont} title={increaseFont} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.increaseFontSize() }}>
        <img src={require("./images/icons8-increase-font-52.png")} alt={increaseFont} width="20" height="20" />
    </Button>
    }
    if(fontSizeLevel === 1){
    decreaseFontButton = <Button className='disabled-font-icon' disabled aria-label={decreaseFont} title={decreaseFont} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.decreaseFontSize() }}>
        <img src={require("./images/icons8-decrease-font-52.png")} alt={decreaseFont} width="20" height="20" />
    </Button>
    }


    return (<span helpTopic="lcs">
          <div className={classes.toggleContainer} style={{direction: langDirection, paddingTop: "0px"}}>
            <Paper elevation={0} style={{padding: '3px', display: 'block'}}>
              <Button variant="default" style={{ verticalAlign: '-8px'}} className="LCS-open-settings-button" onClick={(e) => {this.handleOpenSearchSettings(e)}}>
                <SettingsIcon/>&nbsp;{searchSettings}
              </Button>&nbsp;

              <Popover
                open={this.state.searchSettingsIsOpen}
                anchorEl={this.state.searchSettingsAnchorEl}
                onClose={this.handleCloseSearchSettings}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: `${isHebrew ? "right" : "left"}`,
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: `${isHebrew ? "right" : "left"}`,
                }}
                elevation={1}
                className="placement-selector-2D-popover"
                style={{
                  direction: `${isHebrew ? 'rtl' : 'ltr'}`,
                }}
                classes={{ paper: "LCS-search-settings-popover-paper" }}
              >

                <table className="LCS-search-setting-parent-table">
                  <tr>
                    <td style={searchSettingsLabelCellStyle}>
                      <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{direction}:</Typography>
                    </td>
                    <td style={searchSettingsInputCellStyle}>
                      <ToggleButtonGroup className="tanachViewerToggleButtonGroup" value={directionVal} exclusive onChange={(e, val) => {this.setTextDirection(e, val)}}>
                        <ToggleButton className="text-comparator-viewer-toggle-button" value="forward">
                          <img src={require("./images/same_direction.svg")} title={forward} alt={forward} width="15" height="15" />
                        </ToggleButton>
                        <ToggleButton className="text-comparator-viewer-toggle-button" value="reverse">
                          <img src={require("./images/reverse_direction.svg")} title={reverse} alt={reverse} width="15" height="15" />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </td>
                  </tr>
                  <tr>
                    <td style={searchSettingsLabelCellStyle}>
                      <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{matchDistance}:</Typography>
                    </td>
                    <td style={searchSettingsInputCellStyle}>
                      <Typography variant='body1' className='LCS-distance-label'>{this.getDisplayVals(matchDistanceVals)[0]}</Typography>
                      <Range reverse={isHebrew} className='LCS-distance-slider' min={1} step={1} included={false} tipFormatter={value => this.getDisplayValue(value)} value={matchDistanceVals} allowCross={false} onChange={this.setMatchDistance}
                        style={{
                          marginLeft: isHebrew ? '-4px' : '16px',
                          marginRight: isHebrew ? '12px' : '-10px',
                        }}
                      />
                      <Typography variant='body1' className='LCS-distance-label'>{this.getDisplayVals(matchDistanceVals)[1]}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td style={searchSettingsLabelCellStyle}>
                      <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{allowedWordLength}:</Typography>
                    </td>
                    <td style={searchSettingsInputCellStyle}>
                      <Typography variant='body1' className='LCS-distance-label'>{this.getDisplayVals(wordLengthVals)[0]}</Typography>
                      <Range reverse={isHebrew} className='LCS-word-length-slider' max={11} min={1} step={1} included={false} tipFormatter={value => this.getDisplayValue(value)} value={wordLengthVals} allowCross={false} onChange={this.setWordLength}
                        style={{
                          marginLeft: isHebrew ? '-4px' : '16px',
                          marginRight: isHebrew ? '12px' : '-10px',
                        }}
                      />
                      <Typography variant='body1' className='LCS-distance-label'>{this.getDisplayVals(wordLengthVals)[1]}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td style={searchSettingsLabelCellStyle}>
                      <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{wordsToSkip}:</Typography>
                    </td>
                    <td style={searchSettingsInputCellStyle}>
                      
                      <span style={{direction: directionStyling.direction}}>
                        <Button className="placement-selector-2D-button" onClick={(e) => {this.handleOpenWordsToSkip(e)}}>
                          <Typography
                            variant="h6"
                            className="placement-selector-2D-label"
                            style={{
                              color: `${this.props.disabled ? "#8a999e" : "black"}`,
                              textAlign: directionStyling.textAlign,
                              fontWeight: `${false ? "bold" : "400"}`
                            }}
                          >
                            {noWordsToSkip ? select : wordsToSkipVal.join(" ")}
                          </Typography >
                          <ArrowDropDownIcon className="placement-selector-2D-icon" style={{color: '#757575'}}/>
                        </Button>

                          <Popover
                            open={this.state.wordsToSkipIsOpen}
                            anchorEl={this.state.wordsToSkipAnchorEl}
                            onClose={this.handleCloseWordsToSkip}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: `${isHebrew ? "right" : "left"}`,
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: `${isHebrew ? "right" : "left"}`,
                            }}
                            elevation={1}
                            className="placement-selector-2D-popover"
                            style={{
                              direction: `${isHebrew ? 'rtl' : 'ltr'}`
                            }}
                            classes={{ paper: "LCS-search-settings-popover-paper" }}
                          >
                            <MenuItem className="LCS-select-menu-item" value="clearAll" onClick={(e) => {fakeThis.handleSelectWordToSkip(e)}}
                              style={{
                                direction: `${isHebrew ? 'rtl' : 'ltr'}`
                              }}
                            >
                              {clearAll}
                            </MenuItem>
                            <div className="placement-selector-2D-columns-parent-rtl">
                                {wordsToSkipMenuItems.map((menuItem, key) => {
                                  var menuItemsArr = [];
                                  for(let i = 0; i < 1; i++){
                                    if(wordsToSkipMenuItems[key+i]){
                                      menuItemsArr.push(
                                        <MenuItem className="placement-selector-2D-menuItem-rtl" value={wordsToSkipMenuItems[key+i].id} onClick={(e) => {fakeThis.handleSelectWordToSkip(e)}}
                                          style={{
                                            direction: `${this.props.isTextComparator && localStorage.getItem('language') === "en" ? "ltr" : "rtl" }`,
                                            backgroundColor: `${wordsToSkipVal.includes(wordsToSkipMenuItems[key+i].id) ? "lightgrey" : "" }`,
                                          }}
                                        >{wordsToSkipMenuItems[key+i].value}</MenuItem>
                                      )
                                    }
                                  }
                                  if(key%1 === 0){
                                    return <div>
                                      {menuItemsArr}
                                    </div>;
                                  }
                                  
                                })}
                            </div>
                            <Typography variant='body1' className='LCS-other-words-label'
                              style={{
                                direction: `${isHebrew ? 'rtl' : 'ltr'}`,
                                textAlign: `${isHebrew ? 'right' : 'left'}`
                              }}
                            >
                              {otherWords}:
                            </Typography>
                            <TextField
                              id="outlined-size-small"
                              variant="outlined"
                              size="small"
                              value={this.props.LCSData.otherWordsToSkip.join(" ")}
                              onChange={(e) => {this.setOtherWordsToSkip(e)}}
                              InputProps={{ classes: { input: 'LCS-words-to-skip-textfield' } }}
                            />
                          </Popover>
                        </span>
                    </td>
                  </tr>
                </table>
              </Popover>

              <Button variant="default" style={{ verticalAlign: '-8px'}} className="LCS-open-settings-button" onClick={(e) => {this.handleOpenDisplaySettings(e)}}>
                <SettingsIcon/>&nbsp;{displaySettings}
              </Button>

              <Popover
                open={this.state.displaySettingsIsOpen}
                anchorEl={this.state.displaySettingsAnchorEl}
                onClose={this.handleCloseDisplaySettings}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: `${isHebrew ? "right" : "left"}`,
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: `${isHebrew ? "right" : "left"}`,
                }}
                elevation={1}
                className="placement-selector-2D-popover"
                style={{
                  direction: `${isHebrew ? 'rtl' : 'ltr'}`,
                }}
                classes={{ paper: "LCS-search-settings-popover-paper" }}
              >

                <table className="LCS-search-setting-parent-table">
                  <tr>
                    <td style={searchSettingsLabelCellStyle}>
                      <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{resultsFontSize}:</Typography>
                    </td>
                    <td style={searchSettingsInputCellStyle}>
                      {decreaseFontButton}
                      {increaseFontButton}
                    </td>
                  </tr>
                </table>
              </Popover>

              <Button variant="contained" disabled={refreshDisabled} color="primary" className="parsha-viewer-control-button" type="submit" style={{margin: '0 5px'}} onClick={this.refresh}><Translate id="refresh" /></Button>
            </Paper>
          </div>

          <TextComparatorSectionSelector 
            isLoadingResults={isLoading}
            totalTerms1={totalTerms1}
            termTotal1Accurate={termTotal1Accurate}
            totalTerms2={totalTerms2}
            termTotal2Accurate={termTotal2Accurate}
            text1Data={this.props.text1Data}
            text2Data={this.props.text2Data}
            text1GridReady={this.text1GridReady}
            addText1Row={this.addText1Row}
            extAddText1Row={this.extAddText1Row}
            deleteText1Row={this.deleteText1Row}
            extDeleteText1Row={this.extDeleteText1Row}
            extModifyText1Row={this.extModifyText1Row}
            modifyText1Row={this.modifyText1Row}
            text2GridReady={this.text2GridReady}
            addText2Row={this.addText2Row}
            extAddText2Row={this.extAddText2Row}
            deleteText2Row={this.deleteText2Row}
            extDeleteText2Row={this.extDeleteText2Row}
            extModifyText2Row={this.extModifyText2Row}
            modifyText2Row={this.modifyText2Row}
            getRenderVals={this.getRenderVals}
            isFilingSelector={this.props.isFillingSelector}
            saveText1Data={this.saveText1Data}
            saveText2Data={this.saveText2Data}
          />

        <div style={{margin: 'auto', width: 'fit-content'}}>
          <LoadingOverlay
            active={isLoading}
            spinner
            text={loadingText}
          >
            <MenuProvider className="context-menu" id='text-comparator-viewer-context-menu'
                  style={{
                    height: viewerTableHeight,
                    width: '1520px',
                    maxWidth: 'calc(100vw - 400px)',
                    minWidth: '968px',
                  }}
            >
              <div
                className="ag-theme-balham"
                id="text-comparator-grid"
                style={{
                  height: viewerTableHeight,
                  width: '1520px',
                  maxWidth: 'calc(100vw - 400px)',
                  minWidth: '968px',
                }}

                  >
                  <AgGridReact
                      onGridReady={this.resultsGridReady}
                      defaultColDef={{resizable: true}}
                      columnDefs={this.state.columnDefs}
                      rowData={this.props.LCSData.rowData}
                      getRowStyle = {getAlternateRowStyle}
                      enableColResize={true}
                      enableSorting={true}
                      enableFilter={true}
                      onCellContextMenu={(e) => {fakeThis.onCellContextMenu(e)}}
                      onCellDoubleClicked={(e) => {fakeThis.handleResultsCellDoubleClicked(e)}}
                      enableRtl="true"
                      headerHeight={40}>
                  </AgGridReact>
              </div>
          </MenuProvider>
          <ContextMenu menuId='text-comparator-viewer-context-menu' menuType='textComparator' className='special-context-menu' resultsApi={this.resultsApi}
                          textToCopy={fakeThis.state.textToCopy} extCopySelectedToSearchbar={fakeThis.props.extCopySelectedToSearchbar}
                          extSearchWithSelected={fakeThis.props.extSearchWithSelected} extToggleUseKinuim={fakeThis.toggleUseKinuim}
                          useKinuim={fakeThis.state.useKinuim} extToggleIncludeMekorot={fakeThis.toggleIncludeMekorot} includeMekorot={fakeThis.state.includeMekorot}
                          extToggleSearchAsQuote={fakeThis.toggleSearchAsQuote} searchAsQuote={fakeThis.state.searchAsQuote} extSetTextSource={this.props.extSetTextSource}
                          extOpenTaamParser={fakeThis.openTaamParser} currentFreq={this.state.currentFreq} hasRowData={fakeThis.props.LCSData.rowData.length > 0}
                          extOpenLexiconViewer={this.props.extOpenLexiconViewer} morphInflections={this.state.searchedMorphInflections} wordToCopy={fakeThis.state.wordToCopy}/>
          </LoadingOverlay>
        </div>
        </span>
    );
  }
}

LCSViewer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withLocalize(LCSViewer));


/*
      <div className='sweet-loading'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={isLoading}
        />


      </div>
*/