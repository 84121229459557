import React, { Component, useState } from 'react';
import './css/App.css';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { Button, Paper, InputBase, IconButton, Fade, Typography, Popper, AppBar, Tabs, Tab, InputLabel, MenuItem, FormControl, Select, Switch, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import translations from "./json/translations.json";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

class PartsOfSpeechSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            displayArray:this.props.display.split(" "),
            step: this.props.step ? this.props.step : 1, //default to 1
            stepDecimals: this.props.stepDecimals ? this.props.stepDecimals : 0 //default to 1
        }

        this.handleDistanceChange = this.handleDistanceChange.bind(this);
        this.handleSingleDistanceChange = this.handleSingleDistanceChange.bind(this);
        this.checkCharacters = this.checkCharacters.bind(this);
    }

    handleSingleDistanceChange = (plusMinus, upperLower) => {
        var val = [];
        var step = this.state.step;
        var stepDecimals = this.state.stepDecimals;
        if(plusMinus === 'plus' && upperLower === 'lower' && this.props.distance[0] !== this.props.distance[1]){ //don't change if equal and adding to lower
            val = [parseFloat((this.props.distance[0]+step).toFixed(stepDecimals)), (this.props.distance[1])]
            val[0] = val[0] > this.props.range[1] ? this.props.range[1] : val[0];
        } else if(plusMinus === 'minus' && upperLower === 'lower'){
            val = [parseFloat((this.props.distance[0]-step).toFixed(stepDecimals)), (this.props.distance[1])]
            val[0] = val[0] < this.props.range[0] ? this.props.range[0] : val[0];
        } else if(plusMinus === 'plus' && upperLower === 'upper'){
            val = [(this.props.distance[0]), parseFloat((this.props.distance[1]+step).toFixed(stepDecimals))]
            val[1] = val[1] > this.props.range[1] ? this.props.range[1] : val[1];
        } else if(plusMinus === 'minus' && upperLower === 'upper' && this.props.distance[0] !== this.props.distance[1]){//don't change if equal and subtracting from higher
            val = [(this.props.distance[0]), parseFloat((this.props.distance[1]-step).toFixed(stepDecimals))]
            val[1] = val[1] < this.props.range[0] ? this.props.range[0] : val[1];
        }

        if(typeof(val[0]) === 'number' && typeof(val[1]) === 'number'){
            this.handleDistanceChange(val)
        }
    }

    handleDistanceChange = (val) => {
        this.setState({click:false})
        if(val[0] !== this.props.distance[0]){//if changed the min
            val[0] = val[0]>val[1]? val[1]: val[0]
            val[0] = val[0]<this.props.range[0]? this.props.range[0]: val[0] //if less than min, set to min
        }
        if(val[1] !== this.props.distance[1]){//if changed the max
            val[1] = val[1]<val[0]? val[0]: val[1]
            val[1] = val[1]>this.props.range[1]? this.props.range[1]: val[1] //if greater than max, set to max
        }
        if(Number.isNaN(val[0])){
            val[0]=this.props.distance[0]
        }
        if(Number.isNaN(val[1])){
            val[1]=this.props.distance[1]
        }
        if(val[0] == this.props.distance[0] && val[1] == this.props.distance[1]){
            this.setState({displayArray:[]},()=>this.setState({displayArray:this.props.display.split(" ")}))
        }else{
            val[0]=Number(val[0].toFixed(this.props.stepDecimals));
            val[1]=Number(val[1].toFixed(this.props.stepDecimals));
            this.props.setRange(val);
        }

    }
    checkCharacters(evt){
        if(evt.which !== 8 && evt.currentTarget.innerText.length > 4){//if the number is too long
            evt.preventDefault();}
        if (evt.charCode === 13) {//if pressed enter
            evt.preventDefault();}
        if ((!(evt.charCode >= 48 && evt.charCode <= 57)) && (!parseFloat(evt.key)) && (evt.charCode !== 46)) { // 0-9 only and decimals
            evt.preventDefault();} 
    }

    componentDidMount(){
        this.setState({displayArray:this.props.display.split(" ")})
    }
    componentDidUpdate(prevProps){
        if(this.props.display.split(" ")[0] != this.state.displayArray[0] || this.props.display.split(" ")[2] != this.state.displayArray[2]){
            this.setState({displayArray:this.props.display.split(" ")})//)
        }
    }
     render() {
        var displayArray=this.props.display.split(" ");
        var plusButtonValue = 1;
        var minusButtonValue = -1;

        const display = <div align="center" width='90px' style={{minWidth: '92px', display: 'inline-block'}}>
                 <Paper className="wideSliderDisplayBox"  elevation={1} >
                     <div dir="ltr" className="flex-container">
                     {/* <span >&#8203;&#8203;&#8203; */}
                         <span onClick={()=>this.setState({click:true})} onBlur={(val)=>this.handleDistanceChange([this.props.distance[0],parseFloat(val.target.innerText)])}
                            style={{}} contentEditable={true} 
                            onKeyPress={this.checkCharacters} >{this.state.click?this.props.distance[1]:this.state.displayArray[2]=='∞'?this.state.displayArray[2]:Number(this.state.displayArray[2]).toFixed(this.props.stepDecimals)}</span>
                            {/* &#8203;&#8203;&#8203;</span> */}
                     {/* <span dir="ltr">{" "+this.state.displayArray[1]+" "}</span> */}
                     <span dir="ltr">&nbsp;{"עד"}&nbsp;</span>
                     <span onBlur={(val)=>this.handleDistanceChange([parseFloat(val.target.innerText),this.props.distance[1]])}
                            style={{}} contentEditable={true} 
                            onKeyPress={this.checkCharacters}>{Number(this.state.displayArray[0]).toFixed(this.props.stepDecimals)}</span>
                     </div>
                     </Paper> 
            </div>;
        const pad = <div width='2px'>&nbsp;</div>
        const singleAddSubtractLower = <div style={{paddingTop: '1.5px', paddingBottom: '1.5px', display: 'inline-block'}}>
                     <Fab color="default" aria-label="add" className="singleAddSubtractButton" onClick={() => {this.handleSingleDistanceChange('plus', 'lower')}}
                        style={{display:'block', fontSize: '9px', maxHeight:'10px', minHeight: '0', maxWidth:'10px', lineHeight: '0', padding: '0px 0px 0px 0px'}}
                        disabled={this.state.termsInSameQuote}
                     >
                       +
                     </Fab>
                     <div style={{minHeight: '1px'}}/>
                     <Fab color="default" aria-label="add" className="singleAddSubtractButton" onClick={() => {this.handleSingleDistanceChange('minus', 'lower')}}
                        style={{display:'block', fontSize: '9px', maxHeight:'10px', minHeight: '0', maxWidth:'10px', lineHeight: '0', padding: '0px 0px .5px 0px'}}
                        disabled={this.state.termsInSameQuote}
                     >
                       -
                     </Fab>
                </div>

        const singleAddSubtractUpper = <div style={{paddingTop: '1.5px', paddingBottom: '1.5px', display: 'inline-block'}}>
                     <Fab color="default" aria-label="add" className="singleAddSubtractButton" onClick={() => {this.handleSingleDistanceChange('plus', 'upper')}}
                        style={{display:'block', fontSize: '9px', maxHeight:'10px', minHeight: '0', maxWidth:'10px', lineHeight: '0', padding: '0px 0px 0px 0px'}}
                        disabled={this.state.termsInSameQuote}
                    >
                       +
                     </Fab>
                     <div style={{minHeight: '1px'}}/>
                     <Fab color="default" aria-label="add" className="singleAddSubtractButton" onClick={() => {this.handleSingleDistanceChange('minus', 'upper')}}
                        style={{display:'block', fontSize: '9px', maxHeight:'10px', minHeight: '0', maxWidth:'10px', lineHeight: '0', padding: '0px 0px .5px 0px'}}
                        disabled={this.state.termsInSameQuote}
                     >
                       -
                     </Fab>
                </div>
        const distance = <div align="center" style={{paddingRight: '10px', paddingLeft: '10px', minWidth: this.props.sliderWidth+'px', display: 'inline-block'}} className='distance-cell'>

                <Range reverse={true} min={this.props.range[0]} max={this.props.range[1]} step={this.state.step} included={false}  value={this.props.distance} allowCross={false} onChange={this.handleDistanceChange} disabled={this.state.termsInSameQuote} />

            </div>


            return <>{display}{singleAddSubtractLower}{distance}{singleAddSubtractUpper}</>;
       }
}

export default withLocalize(PartsOfSpeechSlider);