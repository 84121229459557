import React, {Component, useState} from "react";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import "./css/App.css";
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil

//the goal here is to format the multilingual text of the legend but to leave it's surrounding controls to the parent Lexicon.js
class LexiconLegend extends Component {

    constructor(props){
        super(props);
        this.state={
            textDisplay: true,
        }
        this.setAboutTabIndex = this.setAboutTabIndex.bind(this);
    }

    setAboutTabIndex(i) {
        util.setForceOpenAboutDialog(true);
        this.props.extSetAboutTabIndex(i);
    }

    render() {
        var message = <></>;

        if (this.props.side == "right") {
            if (this.props.isHebrew) {
                if (this.props.sourceType == "Teiva") {
                    message = <>
                        התחלת תיבה (<b>סה"כ מילים</b>)
                        <br />
                        תיבה (מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Shoresh") {
                    message = <>
                        התחלת שורש (<b>סה"כ מילים</b>)
                        <br />
                        שורש (מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Gematria") {
                    message = <>
                        גימטריא (<b>סה"כ מילים</b>)
                    </>
                }
                else if (this.props.sourceType == "Atbash") {
                    message = <>
                        אתבש (<b>סה"כ מילים</b>)
                    </>
                }
                else if (this.props.sourceType == "Targum") {
                    message = <>
                        התחלת מילה (<b>סה"כ מילים</b>)
                        <br />
                        מילה (מס' הופעות)
                    </>
                }
            }
            else {
                if (this.props.sourceType == "Teiva") {
                    message = <>
                        Start of word (<b>total words</b>)
                        <br />
                        Word (# appearances)
                    </>
                }
                else if (this.props.sourceType == "Shoresh") {
                    message = <>
                        Start of root (<b>total roots</b>)
                        <br />
                        Word (# appearances)
                    </>
                }
                else if (this.props.sourceType == "Gematria") {
                    message = <>
                        Gematria (<b>total words</b>)
                    </>
                }
                else if (this.props.sourceType == "Atbash") {
                    message = <>
                        Atbash (<b>total words</b>)
                    </>
                }
                else if (this.props.sourceType == "Targum") {
                    message = <>
                        Start of word (<b>total words</b>)
                        <br />
                        Word (# appearances)
                    </>
                }
            }
        }
        else {
            if (this.props.isHebrew) {
                if (this.props.sourceType == "Teiva" && this.props.targetType == "Shoresh") {
                    message = <>
                        <b>שורש מקביל</b>
                        <br /><span style={{color: "blue"}}>
                        תיבת מקור
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Teiva" && this.props.targetType == "Gematria") {
                    message = <>
                        <b>גימטריא מקבילה</b>
                        <br /><span style={{color: "blue"}}>
                        תיבת מקור
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Teiva" && this.props.targetType == "Atbash") {
                    message = <>
                        <b>אתבש מקביל</b>
                        <br /><span style={{color: "blue"}}>
                        תיבת מקור
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Teiva" && this.props.targetType == "Targum") {
                    message = <>
                        <b>תרגום מקביל</b>
                        <br /><span style={{color: "blue"}}>
                        תיבת מקור
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Shoresh" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>תיבה מקבילה</b>
                        <br /><span style={{color: "blue"}}>
                        שורש מקור
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Shoresh" && this.props.targetType == "Gematria") {
                    message = <>
                        <b>גימטריא מקבילה</b>
                        <br /><span style={{color: "blue"}}>
                        שורש מקור
                        </span>:
                        תיבת מקור
                        &nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Shoresh" && this.props.targetType == "Atbash") {
                    message = <>
                        <b>אתבש מקביל</b>
                        <br /><span style={{color: "blue"}}>
                        שורש מקור
                        </span>:
                        תיבת מקור
                        &nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Shoresh" && this.props.targetType == "Targum") {
                    message = <>
                        <b>תרגום מקביל</b>
                        <br /><span style={{color: "blue"}}>
                        שורש מקור
                        </span>&nbsp;
                       (מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Gematria" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>תיבה מקבילה</b>
                        <br /><span style={{color: "blue"}}>
                        גימטריית מקור
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Gematria" && this.props.targetType == "Shoresh") {
                    message = <>
                        <b>שורש מקביל</b>
                        <br /><span style={{color: "blue"}}>
                        גימטריית מקור
                        </span>:
                        תיבת מקור
                        &nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Gematria" && this.props.targetType == "Atbash") {
                    message = <>
                        <b>אתבש מקביל</b>
                        <br /><span style={{color: "blue"}}>
                        גימטריית מקור
                        </span>:
                        תיבת מקור
                        &nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Atbash" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>תיבה מקבילה</b>
                        <br /><span style={{color: "blue"}}>
                        אתבש מקור
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Atbash" && this.props.targetType == "Shoresh") {
                    message = <>
                        <b>שורש מקביל</b>
                        <br /><span style={{color: "blue"}}>
                        אתבש מקור
                        </span>:
                        תיבת מקור
                        &nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Atbash" && this.props.targetType == "Gematria") {
                    message = <>
                        <b>גימטריא מקבילה</b>
                        <br /><span style={{color: "blue"}}>
                        אתבש מקור
                        </span>:
                        תיבת מקור
                        &nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Targum" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>תיבה מקבילה</b>
                        <br /><span style={{color: "blue"}}>
                        תרגום מקור
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Targum" && this.props.targetType == "Shoresh") {
                    message = <>
                        <b>שורש מקביל</b>
                        <br /><span style={{color: "blue"}}>
                        תרגום מקור
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Teiva" && this.props.synonymPath == "Shoresh" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>תיבה מקבילה</b>
                        <br /><span style={{color: "red"}}>
                        שורש משותף
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Teiva" && this.props.synonymPath == "Gematria" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>תיבה מקבילה</b>
                        <br /><span style={{color: "red"}}>
                        גימטריא משותפת
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Teiva" && this.props.synonymPath == "Atbash" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>תיבה מקבילה</b>
                        <br /><span style={{color: "red"}}>
                        אתבש משותף
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Teiva" && this.props.synonymPath == "Targum" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>תיבה מקבילה</b>
                        <br /><span style={{color: "blue"}}>
                        תיבת מקור
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Teiva" && this.props.synonymPath == "Anagram" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>תיבה מקבילה</b>
                        <br /><span style={{color: "red"}}>
                        אותיות משותפות
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Shoresh" && this.props.synonymPath == "Teiva" && this.props.targetType == "Shoresh") {
                    message = <>
                        <b>שורש מקביל</b>
                        <br /><span style={{color: "red"}}>
                        תיבה משותפת
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Shoresh" && this.props.synonymPath == "Gematria" && this.props.targetType == "Shoresh") {
                    message = <>
                        <b>שורש מקביל</b>
                        <br /><span style={{color: "red"}}>
                        גימטריא משותפת
                        </span>:
                        תיבה מקבילה
                        &nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Shoresh" && this.props.synonymPath == "Atbash" && this.props.targetType == "Shoresh") {
                    message = <>
                        <b>שורש מקביל</b>
                        <br /><span style={{color: "red"}}>
                        אתבש משותף
                        </span>:
                        תיבה מקבילה
                        &nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Shoresh" && this.props.synonymPath == "Targum" && this.props.targetType == "Shoresh") {
                    message = <>
                        <b>שורש מקביל</b>
                        <br /><span style={{color: "blue"}}>
                        שורש מקור
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Gematria" && this.props.synonymPath == "Shoresh" && this.props.targetType == "Gematria") {
                    message = <>
                        <b>גימטריא מקבילה</b>
                        <br /><span style={{color: "red"}}>
                        שורש משותף
                        </span>:
                        תיבה מקבילה
                        &nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Gematria" && this.props.synonymPath == "Atbash" && this.props.targetType == "Gematria") {
                    message = <>
                        <b>גימטריא מקבילה</b>
                        <br />
                        <span style={{fontSize: "13.5px"}}>
                        <span style={{color: "blue"}}>
                        גימטריית מקור
                        </span>:&nbsp;
                        <span style={{color: "red"}}>
                        אתבש משותף
                        </span>:
                        תיבה מקבילה
                        &nbsp;(מס' הופעות)
                        </span>
                    </>
                }
                else if (this.props.sourceType == "Atbash" && this.props.synonymPath == "Shoresh" && this.props.targetType == "Atbash") {
                    message = <>
                        <b>אתבש מקביל</b>
                        <br /><span style={{color: "red"}}>
                        שורש משותף
                        </span>:
                        תיבה מקבילה
                        &nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Atbash" && this.props.synonymPath == "Gematria" && this.props.targetType == "Atbash") {
                    message = <>
                        <b>אתבש מקביל</b>
                        <br />
                        <span style={{fontSize: "13.5px"}}>
                        <span style={{color: "blue"}}>
                        אתבש מקור
                        </span>:&nbsp;
                        <span style={{color: "red"}}>
                        גימטריא משותפת
                        </span>:
                        תיבה מקבילה
                        &nbsp;(מס' הופעות)
                        </span>
                    </>
                }
                else if (this.props.sourceType == "Targum" && this.props.synonymPath == "Teiva" && this.props.targetType == "Targum") {
                    message = <>
                        <b>תרגום מקביל</b>
                        <br /><span style={{color: "blue"}}>
                        תרגום מקור
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
                else if (this.props.sourceType == "Targum" && this.props.synonymPath == "Shoresh" && this.props.targetType == "Targum") {
                    message = <>
                        <b>תרגום מקביל</b>
                        <br /><span style={{color: "blue"}}>
                        תרגום מקור
                        </span>&nbsp;(מס' הופעות)
                    </>
                }
            }
            else { //ENGLISH
                if (this.props.sourceType == "Teiva" && this.props.targetType == "Shoresh") {
                    message = <>
                        <b>Equivalent root</b>
                        <br /><span style={{color: "blue"}}>
                        Source word
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Teiva" && this.props.targetType == "Gematria") {
                    message = <>
                        <b>Equivalent gematria</b>
                        <br /><span style={{color: "blue"}}>
                        Source word
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Teiva" && this.props.targetType == "Atbash") {
                    message = <>
                        <b>Equivalent atbash</b>
                        <br /><span style={{color: "blue"}}>
                        Source word
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Teiva" && this.props.targetType == "Targum") {
                    message = <>
                        <b>Equivalent targum</b>
                        <br /><span style={{color: "blue"}}>
                        Source word
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Shoresh" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>Equivalent word</b>
                        <br /><span style={{color: "blue"}}>
                        Source root
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Shoresh" && this.props.targetType == "Gematria") {
                    message = <>
                        <b>Equivalent gematria</b>
                        <br /><span style={{color: "blue"}}>
                        Source root
                        </span>:
                        Source word&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Shoresh" && this.props.targetType == "Atbash") {
                    message = <>
                        <b>Equivalent atbash</b>
                        <br /><span style={{color: "blue"}}>
                        Source root
                        </span>:
                        Source word&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Shoresh" && this.props.targetType == "Targum") {
                    message = <>
                        <b>Equivalent targum</b>
                        <br /><span style={{color: "blue"}}>
                        Source root
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Gematria" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>Equivalent word</b>
                        <br /><span style={{color: "blue"}}>
                        Source gematria
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Gematria" && this.props.targetType == "Shoresh") {
                    message = <>
                        <b>Equivalent root</b>
                        <br /><span style={{color: "blue"}}>
                        Source gematria
                        </span>:
                        Source word&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Gematria" && this.props.targetType == "Atbash") {
                    message = <>
                        <b>Equivalent atbash</b>
                        <br /><span style={{color: "blue"}}>
                        Source gematria
                        </span>:
                        Source word&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Atbash" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>Equivalent word</b>
                        <br /><span style={{color: "blue"}}>
                        Source atbash
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Atbash" && this.props.targetType == "Shoresh") {
                    message = <>
                        <b>Equivalent root</b>
                        <br /><span style={{color: "blue"}}>
                        Source atbash
                        </span>:
                        Source word&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Atbash" && this.props.targetType == "Gematria") {
                    message = <>
                        <b>Equivalent gematria</b>
                        <br /><span style={{color: "blue"}}>
                        Source atbash
                        </span>:
                        Source word&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Targum" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>Equivalent word</b>
                        <br /><span style={{color: "blue"}}>
                        Source targum
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Targum" && this.props.targetType == "Shoresh") {
                    message = <>
                        <b>Equivalent root</b>
                        <br /><span style={{color: "blue"}}>
                        Source targum
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Teiva" && this.props.synonymPath == "Shoresh" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>Equivalent word</b>
                        <br /><span style={{color: "red"}}>
                        Common root
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Teiva" && this.props.synonymPath == "Gematria" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>Equivalent word</b>
                        <br /><span style={{color: "red"}}>
                        Common gematria
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Teiva" && this.props.synonymPath == "Atbash" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>Equivalent word</b>
                        <br /><span style={{color: "red"}}>
                        Common atbash
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Teiva" && this.props.synonymPath == "Targum" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>Equivalent word</b>
                        <br /><span style={{color: "blue"}}>
                        Source word
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Teiva" && this.props.synonymPath == "Anagram" && this.props.targetType == "Teiva") {
                    message = <>
                        <b>Equivalent word</b>
                        <br /><span style={{color: "red"}}>
                        Common letters
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Shoresh" && this.props.synonymPath == "Teiva" && this.props.targetType == "Shoresh") {
                    message = <>
                        <b>Equivalent root</b>
                        <br /><span style={{color: "red"}}>
                        Common word
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Shoresh" && this.props.synonymPath == "Gematria" && this.props.targetType == "Shoresh") {
                    message = <>
                        <b>Equivalent root</b>
                        <br /><span style={{fontSize: "13.5px"}}><span style={{color: "red"}}>
                        Common gematria
                        </span>:
                        Equivalent word&nbsp;(# appearances)
                        </span>
                    </>
                }
                else if (this.props.sourceType == "Shoresh" && this.props.synonymPath == "Atbash" && this.props.targetType == "Shoresh") {
                    message = <>
                        <b>Equivalent root</b>
                        <br /><span style={{color: "red"}}>
                        Common atbash
                        </span>:
                        Equivalent word&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Shoresh" && this.props.synonymPath == "Targum" && this.props.targetType == "Shoresh") {
                    message = <>
                        <b>Equivalent root</b>
                        <br /><span style={{color: "blue"}}>
                        Source root
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Gematria" && this.props.synonymPath == "Shoresh" && this.props.targetType == "Gematria") {
                    message = <>
                        <b>Equivalent gematria</b>
                        <br /><span style={{color: "red"}}>
                        Common root
                        </span>:
                        Equivalent word
                        &nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Gematria" && this.props.synonymPath == "Atbash" && this.props.targetType == "Gematria") {
                    message = <>
                        <b>Equivalent gematria</b>
                        <br />
                        <span style={{fontSize: "10.5px"}}>
                        <span style={{color: "blue"}}>
                        Source gematria
                        </span>:&nbsp;
                        <span style={{color: "red"}}>
                        Common atbash
                        </span>:
                        Equivalent word
                        &nbsp;(# appearances)
                        </span>
                    </>
                }
                else if (this.props.sourceType == "Atbash" && this.props.synonymPath == "Shoresh" && this.props.targetType == "Atbash") {
                    message = <>
                        <b>Equivalent atbash</b>
                        <br /><span style={{color: "red"}}>
                        Common root
                        </span>:
                        Equivalent word
                        &nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Atbash" && this.props.synonymPath == "Gematria" && this.props.targetType == "Atbash") {
                    message = <>
                        <b>Equivalent atbash</b>
                        <br />
                        <span style={{fontSize: "10.5px"}}>
                        <span style={{color: "blue"}}>
                        Source atbash
                        </span>:&nbsp;
                        <span style={{color: "red"}}>
                        Common gematria
                        </span>:
                        Equivalent word
                        &nbsp;(# appearances)
                        </span>
                    </>
                }
                else if (this.props.sourceType == "Targum" && this.props.synonymPath == "Teiva" && this.props.targetType == "Targum") {
                    message = <>
                        <b>Equivalent targum</b>
                        <br /><span style={{color: "blue"}}>
                        Source targum
                        </span>&nbsp;(# appearances)
                    </>
                }
                else if (this.props.sourceType == "Targum" && this.props.synonymPath == "Shoresh" && this.props.targetType == "Targum") {
                    message = <>
                        <b>Equivalent targum</b>
                        <br /><span style={{color: "blue"}}>
                        Source targum
                        </span>&nbsp;(# appearances)
                    </>
                }
            }
        }

        return (
            <span>{message}</span>
        )
    }
}
export default withLocalize(LexiconLegend);


