import React, { Component } from 'react';

class POS_Examples extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal"><b><i>
שימוש בכרטיסיית חלק דיבור ועוד
        </i></b></p>

        <p className="MsoNormal"><i>
חפש לכל המופעים בתנ"ך שיש בהם פועל פסיבי עם סיומת במספר רבים והמילה מכילה לפחות שבע אותיות.
        </i></p>

        <p className="MsoNormal"><img width={623} height={128} id="Picture 96"
        src="helpDialogImages/image030.jpg" /></p>

        <p className="MsoNormal">
הזן * (כוכבית שמייצגת תו כללי) בסרגל החיפוש הראשי. ואז פתח את החיפוש המתקדם ולחץ על הכרטיסייה
         <b> <i>
חלקי דיבור ועוד
        </i> </b>
. אז בחר
        &nbsp;<u>
נפעל, הפעל, ופיעל
        </u>
        <b>&nbsp;
לבנין,
        </b>&nbsp;
רבים
        <b>&nbsp;
למספר של הסיומת, ולאותיות בתיבה
        </b>&nbsp;
החלק את כפתור המחוון הימיני עד שמגיע
        <b>&nbsp;
לשבע
        </b>.&nbsp;
לאחר מכן לחץ על כפתור החיפוש. תקבל את התוצאות הבאות: (יחזקאל א:כא)
        <b>&nbsp;
וּבְהִנָּשְׂאָם
        </b>&nbsp;
; (יחזקאל ו:ח)
        <b>&nbsp;
בְּהִזָּרוֹתֵיכֶם
        </b>&nbsp;
; (יחזקאל כט:ז)
        <b>&nbsp;
וּבְהִשָּׁעֲנָם
        </b>&nbsp;
; (דניאל יא:לד)
        <b>&nbsp;
וּבְהִכָּשְׁלָם
        </b>

        </p>

        <p className="MsoNormal">&nbsp;</p>

        <p className="MsoNormal"><i>
חפש לביטויים בתנ"ך שבהם אברהם (או אברם) עושה משהו למישהו אחר בו המילה הראשונה היא
        <b>&nbsp;
 פועל המתחיל עם אות ויו
        </b>.&nbsp;
 המילה השנייה היא
         <b>&nbsp;
 אברהם / אברם
        </b>&nbsp;
 .המילה השלישית היא <b>את</b> והמילה הרביעית היא <b>שם של איזה בן אדם</b>.
        </i></p>

        <p className="MsoListParagraphCxSpFirst" style={{marginRight:'.5in', textIndent: '-.25in'}}><span
        style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>
הזן <b>"ו* אבר*ם את *"</b> בסרגל החיפוש (<b>ויו עם כוכבית</b> לאפשר מגוון של פעלים, <b>אבר*ם עם כוכבית</b> לאפשר אברם ואברהם, <b>ביטוי אחרון ככוכבית</b> בכדי לאפשר לכל בן אדם - כולם מוקפים בסימני ציטוט כדי להבטיח שתקבלו משפט של ארבע מילים רצופות)
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.5in', textIndent: '-.25in'}}><span
        style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>
פתח את החיפוש המתקדם ולחץ על הכרטיסייה חלקי דיבור ועוד
        </p>

        <p className="MsoListParagraphCxSpLast" style={{marginRight:'.5in', textIndent: '-.25in'}}><span
        style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>
כאשר <b>ו*</b> נבחר <b>בבורר ביטויי החיפוש</b> בצד ימין: בחר <b>סוג מילה</b> = פועל, <b>גוף</b> = נסתר, <b>ומין</b> = זכר;
כאשר <b>אבר*ם</b> נבחר: <b>סוג אובייקטים</b> = בן אדם: איש (<i>כדי להבטיח שתבנית האותיות מוגבלת לשם גבר</i>); כאשר
הכוכבית האחרונה נבחרת: בחר <b>סוג אובייקטים</b> = בן אדם: איש, בן אדם: אשה (<i>לאפשר כל מין אדם</i>)
        </p>

        <p className="MsoNormal" style={{marginRight:'.5in', textIndent: '-.25in'}}>
לחץ על כפתור החיפוש ותקבל את חמש התוצאות הבאות:
        </p>

        <p className="MsoNormal" style={{marginRight:'.5in'}}><img width={416} height={130}
        id="Picture 98" src="helpDialogImages/image031.png" /></p>

      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b><i>Using the Parts of Speech++ tab</i></b></p>
        
        <p className="MsoNormal"><i>Search for all instances in Tanach that have a passive
        verb with a plural suffix and the word contains at least seven letters.</i></p>
        
        <p className="MsoNormal"><img width={623} height={128} id="Picture 96"
        src="helpDialogImages/image030.jpg" /></p>
        
        <p className="MsoNormal">Enter * (an asterisk to represent a wildcard) in the main
        search bar.  Then open the advanced search and click on the <b><i>Parts of Speech++
        </i></b> tab.  Then select <u>nif’al, huf’al and pi’el for <b>binyan</b></u>, <u>plural
        for <b>suffix number</b></u> and for <b>letters per word</b> slide the right-hand
        slider button to <u>7</u>.  Then click the search button. You will get the
        following for results: <span dir="RTL">
        (יחזקאל א:כא)&nbsp;
        <b>
         וּבְהִנָּשְׂאָם
        </b>
        ; (יחזקאל ו:ח)&nbsp;
        <b>
         בְּהִזָּרוֹתֵיכֶם
        </b>
        ; (יחזקאל כט:ז)&nbsp;
        <b>
         וּבְהִשָּׁעֲנָם
        </b>
        ; (דניאל יא:לד)&nbsp;
        <b>
         וּבְהִכָּשְׁלָם.
        </b>
        </span>
        </p>
        
        <p className="MsoNormal">&nbsp;</p>

        <p className="MsoNormal"><i>Search for phrases in Tanach where Avraham (or Avram)
        does something to someone else where the first word is a verb starting with
        vav, the second word is Avraham/Avram, the third word is את and the fourth word is any person’s
        name.</i></p>
        
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span
        style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>Enter <b><span dir="RTL">
"ו* אבר*ם את *"
        </span></b> in the
        search bar (<b>vav with asterisk</b> to allow for a range of verbs, <b>Avr*m
        with an asterisk</b> to allow for both Avraham and Avram variations, <b>final
        term as an asterisk</b> to allow for any person – all surrounded by quotes to
        ensure you will get a phrase of four consecutive words).</p>
        
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span
        style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>Then open the advanced search and click on
        the <b><i>Parts of Speech++ </i></b>tab.</p>
        
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span
        style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>With *ו selected in the <b>search term selector</b> on the right, select&nbsp;
        <b>word type</b> = verb, <b>person</b> = third and <b>gender</b> = male; With <b>
אבר*ם
        </b> selected, select <b>Objects</b> = Person: MAN
        (<i>to ensure that the letter pattern is limited to a man’s name</i>); With the
        final <b>*</b> selected select <b>Objects</b> = Person: MAN, Person: WOMAN (<i>to
        allow for any person</i>)</p>
        
        <p className="MsoNormal">Click the search button and you will get the following five
        results:</p>
        
        <p className="MsoNormal" style={{marginLeft:'.5in'}}><img width={416} height={130}
        id="Picture 98" src="helpDialogImages/image031.png" /></p>

      </>
    }
    return retVal;
  }

  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default POS_Examples;