import React, { Component } from 'react';

class ParshaViewer_Examples extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        דוגמאות לנושא זה טרם הוזנו
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        Examples for this topic have not yet been entered.

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default ParshaViewer_Examples;