import React, { Component } from 'react';

class HistoryBHS extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p>בסוף 2018 התחלתי לעשות מחקר על פלטפורמת הפיתוח שלי. קידוד עבור אב הטיפוס החל בינואר 2019.</p>
        <p>עכשיו החלטה נוספת. איזה שם לתת למוצר הציבורי? בחרתי ב<b>בייס-הספר</b> כמחזה על מילים
          בעלות מספר רב של משמעויות - מאגר ספרים, בית הספר או פשוט בית ספר או מקום למידה.
          מכאן, https://www.basehasefer.com הושק לאירוח מנוע החיפוש.</p>
        <p>עד פברואר היו לי כמה מסכים בסיסיים עובדים וכמה יכולות חיפוש הגונות. עד שבועות היו לי כמה
          תכונות חיפוש מתקדמות חזקות מאוד ומסכי תוכן מעניינים.</p>
        <p>כבר אז התחלתי להושיט יד לראות אם יש אינטרס בקרב סטודנטים המחפשים התמחות
          בקיץ. התמזל מזלי שהיו לי מתמחים מבריקים באותו קיץ ואיכות עבודתם חרגה בהרבה
          מהציפיות שלי. האיכות של ממשק המשתמש שלנו, מערך התכונות
          והמאגר הפנימי שלנו גדלה בקפיצות. עם תום תקופת ההתמחות,
          אחד המתמחים הסכים להישאר כמתכנת במשרה חלקית.</p>

      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <p>At the end of 2018, I began research into my development platform.  Coding for the prototype was begun in January 2019.</p>
        <p>Another decision now.  What to name the product for the public?  I chose Base HaSefer as a play on words 
          with multiple meanings - a database of seforim, the house of the book or simply a school or place of 
          learning.  Hence, https://www.basehasefer.com was launched for hosting the search engine.</p>
        <p>By February, I had some basic working screens and some decent search capabilities.  By Shavuos I had some
          very powerful advanced search features and interesting content screens.</p>
        <p>By then, I had begun to reach out to see if there was any interest among students looking for summer 
          internships.  I was very fortunate to have bright interns that summer and the quality of 
          their work far exceeded my expectations.  The quality of our user interface, feature set, and our 
          internal database grew by leaps and bounds.  As the internship period was ending, one 
          of the interns agreed to stay on as a part-time programmer.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default HistoryBHS;