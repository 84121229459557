import React, { Component } from 'react';

class HistoryBeta extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p>הידע על פרויקט בייס הספר הוגבל בעבר לכמה עשרות אנשים. במהלך הסתיו והחורף
          אתר האינטרנט הוחלף עד כדי כך שהוא משיג מסה קריטית כדי להעביר את הפרויקט מעבר
          לשלב האב-טיפוס. במרץ 2020 החלטתי להרחיב את המוצר שלנו לקהל גדול יותר עם ההכרזה על גרסת מבחן בטא.</p>

      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <p>Knowledge of the Base HaSefer project had previosuly been limited to a few dozen people.  Over the fall and winter, 
          the web site was fleshed out to the point where it was attaining a critical mass to move the project beyond 
          the prototype stage.  In March 2020, I decided to expand our product to a larger audience with the announcement of a beta test version.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default HistoryBeta;