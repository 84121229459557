import React, { Component } from "react";
import './css/App.css';
export default class FullWidthCellRenderer extends Component {
  constructor(props) {
    super(props);
  }

  getReactContainerStyle() {
    return {
      display: "block",
      height: "100%"
    };
  }
  removeNikudAndTaamim(text){
    var cleanedText = text;
    cleanedText = this.removeNikud(this.removeTaamim(text));
 
    return cleanedText;
  }
 
  removeNikud(str){
   for (let i = str.length-1; i >= 0; i--){
       if('ְֱֲֳִֵֶַָֹֺֻּֽ־ׇֿׁׂׅׄ'.includes(str[i])){
           str = str.slice(0,i).concat(str.slice(i+1,str.length))
       }
   }
   return str;
 }
 
 removeTaamim(str){
   for (let i = str.length-1; i >= 0; i--){
       if('ֽ֑֖֛֢֣֤֥֦֧֪֚֭֮֒֓֔֕֗֘֙֜֝֞֟֠֡֨֩֫֬֯׀'.includes(str[i])){
           str = str.slice(0,i).concat(str.slice(i+1,str.length))
       }
   }
   return str;
 }


  colorWord(word,letterWordTypes,color){
    letterWordTypes = letterWordTypes.split(",")
    var j = letterWordTypes.length-1;
    var coloredWord = "";
    var splitWord = word.split("");//array of each character including nikud and taam
    var cleanedText = this.removeNikudAndTaamim(word).split("");//splits the word into array of just letters
    for(let i=splitWord.length-1; i>=0; i--){
        if(letterWordTypes[j]!=4){//if the letter is not part of the shoresh
            coloredWord = "<span class='red'>"+splitWord[i]+"</span>"+coloredWord;
        }else{
            coloredWord = "<span class='"+color+"'>"+splitWord[i]+"</span>"+coloredWord;
        }
        if( splitWord[i]==cleanedText[j] ){
            j--;
        }
    }
    coloredWord = "<span>"+coloredWord+"</span>"
    return coloredWord
}

  render() {
    // return (
        // <table>
        //     <tr  class="word-stat-row">
        //         <td class="header-row-word-stats">#</td>
        //         <td class="header-row-word-stats">Word</td> 
        //         <td class="header-row-word-stats">Letters and Nekudos</td>
        //         <td class="header-row-word-stats">Shoresh</td>
        //         <td class="header-row-word-stats">Appearences Per Shoresh</td>
        //         <td class="header-row-word-stats">Atbash</td>
        //         <td class="header-row-word-stats">Atbash-Katan</td>
        //         <td class="header-row-word-stats">Gematria</td>
        //         <td class="header-row-word-stats">Katan</td>
        //         <td class="header-row-word-stats">Appearences Word + Nekudos</td>
        //         </tr>
        //         {this.props.node.data.body.map(currWord=>{
        //            return(<tr class="word-stat-row">
        //             <td class="parsha-viewer-text-table-makor" >{currWord.pasukSequenceNum}</td>
        //             <td class="parsha-viewer-text-table-text">{currWord.word}</td>
        //             <td class="parsha-viewer-text-table-text">{currWord.ln}</td>
        //             <td class="parsha-viewer-text-table-text">{currWord.shoresh}</td>
        //             <td class="parsha-viewer-text-table-text">{currWord.appearancesShoresh}</td>
        //             <td class="parsha-viewer-text-table-text">{currWord.atbash}</td>
        //             <td class="parsha-viewer-text-table-text">{currWord.atbashKatan}</td>
        //             <td class="parsha-viewer-text-table-text">{currWord.gematria}</td>
        //             <td class="parsha-viewer-text-table-text">{currWord.katan}</td>
        //             <td class="parsha-viewer-text-table-text">{currWord.appearancesLN}</td>
        //             </tr>)
        //         })}

        // </table>


                                                            return <table class="word-stat-row words-statistic-table">
                                                            <tr class="">
                                                              <th class="header-row-word-stats">#</th>
                                                              <th class="header-row-word-stats">{this.props.node.data.body.wordTitle}</th>
                                                                            <th class="header-row-word-stats">analysis</th>
                                                                            <th class="header-row-word-stats">כתב סת\"ם</th>
                                                                            <th class="header-row-word-stats">כתב עברי</th>
                                                                            <th class="header-row-word-stats">נטייה</th>
                                                                            <th class="header-row-word-stats">appearance</th>
                                                                            <th class="header-row-word-stats">root</th>
                                                                            <th class="header-row-word-stats">byAppearance</th>
                                                                            <th class="header-row-word-stats">תרגום</th>
                                                                            <th class="header-row-word-stats">taamSingular</th>
                                                                            <th class="header-row-word-stats">wordTypes</th>
                                                                            <th class="header-row-word-stats">changes</th>
                                                                            <th class="header-row-word-stats">letterAppearance</th>
                                                                            <th class="header-row-word-stats">unique</th>
                                                                            <th class="header-row-word-stats">numLettersPerWord</th>
                                                                            <th class="header-row-word-stats">nekudos</th>
                                                                            <th class="header-row-word-stats">tenuos</th>
                                                                            <th class="header-row-word-stats">gematria</th>
                                                                            <th class="header-row-word-stats">katan</th>
                                                                            <th class="header-row-word-stats">atbash</th>
                                                                            <th class="header-row-word-stats">atbashKatan</th>
                                                              </tr>
                                                            {this.props.node.data.body.map(currWord=>{

                                                                return <tr class="a">
                                                                                <td class="word-stat-id " >{currWord.pasukSequenceNum}</td>
                                                                                <td class="word-stat-text ">{currWord.word}</td>
                                                                                <td class="word-stat-text "><b>{this.colorWord(currWord.word,currWord.letterWordTypes,"black")}</b></td>
                                                                                <td class="word-stat-text stamAshkenazCLM">{currWord.onlyLetters}</td>
                                                                                <td class="word-stat-text hebrewPaleoGlezer">{currWord.onlyLetters}</td>
                                                                                <td class="word-stat-text ">{currWord.ln}</td>
                                                                                <td class="word-stat-text ">{currWord.appearancesLN}</td>
                                                                                <td class="word-stat-text ">{currWord.realShoresh}</td>
                                                                                <td class="word-stat-text ">{currWord.realAppearancesShoresh}</td>
                                                                                <td class="word-stat-text ">{currWord.fullTargum}</td>
                                                                                <td class="word-stat-text ">{currWord.fullTaam /*?currWord.taam:""} ${currWord.taamType?"&nbsp;<span class='smallerFont'>("+currWord.taamType+")</span>":""} ${currWord.mukafPhrase?"&nbsp;<b>"+currWord.mukafPhrase+"</b>":""*/}</td>
                                                                                <td class="word-stat-text ">{currWord.fullWordType}</td>
                                                                                <td class="word-stat-text ">{currWord.changes}</td>
                                                                                <td class="word-stat-text ">{currWord.sortedLetters}</td>
                                                                                <td class="word-stat-text ">{String.prototype.concat(...new Set(currWord.sortedLetters))}</td>
                                                                                <td class="word-stat-text ">{currWord.letters}</td>
                                                                                <td class="word-stat-text ">{currWord.nekudos}</td>
                                                                                <td class="word-stat-text ">{currWord.tenuos}</td>
                                                                                <td class="word-stat-text ">{currWord.gematria}</td>
                                                                                <td class="word-stat-text ">{currWord.katan}</td>
                                                                                <td class="word-stat-text ">{currWord.atbash}</td>
                                                                                <td class="word-stat-text ">{currWord.atbashKatan}</td>
                                                                </tr>
                                                
                                                            })}
                                                            </table>
                                                            // cellContents.className = parsha-viewer-text-cell parsha-id-${this.props.node.data.pasukId};
                                                            // // cellContents.style.fontSize = ${fakeThis.state.resultsFontSize}px;
                                                            // cellContents.style.fontSize = '12px'
                                                            // // cellContents.addEventListener("mousedown", function(e) {
                                                            // //     fakeThis.handleDoubleRightClick(e)
                                                            // //   });
                                                            // return cellContents;


// );
}
}
