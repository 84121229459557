import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import { Button, Paper, InputBase, IconButton, Fade, Typography, Popper, AppBar, Tabs, Tab, InputLabel, MenuItem, FormControl, Select, Switch } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import translations from "./json/translations.json";
import * as properties from './config/properties.js';
import PlacementSelector2D from './PlacementSelector2D';

function hasNumericValue(x, testVal) {
     var test = testVal == null ? 0 : testVal;
     return x != null & x > test;
}

function isEmptyList(list) {
    return list == null || list.length == 0 || list[0].id == null;
}

class PlacementRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isLoading: true,
          valList1: this.props.data.valList1, //[],
          valList2: this.props.data.valList2, //[],
          valList3: this.props.data.valList3, //[],
          valList4: this.props.data.valList4, //[],
          valList5: this.props.data.valList5,
          valList6: this.props.data.valList6,
          lastTenSelections:util.getFromLocalStorage("lastTenSelections"),
          type: this.props.data.type,//this.props.placementValues.type,
          val1: this.props.data.val1,//this.props.placementValues.val1,
          val2: this.props.data.val2,//this.props.placementValues.val2,
          val3: this.props.data.val3,//this.props.placementValues.val3,
          val4: this.props.data.val4,//this.props.placementValues.val4,
          val5: this.props.data.val5,//this.props.placementValues.val5,
          val6: this.props.data.val6,//this.props.placementValues.val5,

          //need to set the disabled stuff local to the component each time loads it in a tab
          disabled2: !hasNumericValue(this.props.data.val1) || (this.props.data.type && isEmptyList(this.props.data.valList2)),
          disabled3: !hasNumericValue(this.props.data.val2) || (this.props.data.type && isEmptyList(this.props.data.valList3)),
          disabled4: this.props.data.type && isEmptyList(this.props.data.valList4),
          disabled5: !hasNumericValue(this.props.data.val4),
          disabled6: this.props.data.type || !hasNumericValue(this.props.data.val5),
          selectOrLoading: [this.props.translate('select'), this.props.translate('select'), this.props.translate('select'), this.props.translate('select'), this.props.translate('select'), this.props.translate('select')]
        };

        this.handleSelChange = this.handleSelChange.bind(this);
        this.handleSelChange2 = this.handleSelChange2.bind(this);
        this.handleSelChange3 = this.handleSelChange3.bind(this);
        this.handleSelChange4 = this.handleSelChange4.bind(this);
        this.handleSelChange5 = this.handleSelChange5.bind(this);
        this.handleCloseLine = this.handleCloseLine.bind(this);
        this.toggleType = this.toggleType.bind(this);

        this.handleRangeChange1 = this.handleRangeChange1.bind(this);
        this.handleRangeChange2 = this.handleRangeChange2.bind(this);
        this.handleRangeChange3 = this.handleRangeChange3.bind(this);
        this.handleRangeChange4 = this.handleRangeChange4.bind(this);
        this.handleRangeChange5 = this.handleRangeChange5.bind(this);
        this.handleRangeChange6 = this.handleRangeChange6.bind(this);
        this.addFromLastTenSelections = this.addFromLastTenSelections.bind(this);
        this.modifyPlacementRow = this.modifyPlacementRow.bind(this);

        this.props.initialize({
          languages: [
            { name: "עברית", code: "he" },
            { name: "English", code: "en" }
          ],
          translation: translations,
          options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
        });

        this.props.addTranslation(translations);
        this.setLanguage = (code) => {props.setActiveLanguage(code)};
    }


//webServiceURL = properties.env.webServiceURL



  async componentDidMount() {
      if (this.state.valList1.length == 0) {

          const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPlacementOccasions?source=bhsWebApp');
          const body = await response.json();
          var lastTenSelections = util.getFromLocalStorage("lastTenSelections")?util.getFromLocalStorage("lastTenSelections"):[]
          this.setState({ valList1: body, isLoading: false, lastTenSelections});
      }
  }

  async loadList2(val) {
      if (this.state.type) {
          var selectOrLoading = this.state.selectOrLoading;
          selectOrLoading[1] = <b style={{color: '#0000ff'}}>{this.props.translate('smallLoading')}</b>;
          this.setState({selectOrLoading: selectOrLoading})

          const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPlacementOccasions?source=bhsWebApp&grandParentId='+val);
          const body = await response.json();
          var isEmptyList = (body.length == 0 || body[0].id == null);

          selectOrLoading[1] = this.props.translate('select');

          this.setState({ val1: val, val2: 0, val3: 0, val4: 0, val5: 0, valList2: body, selectOrLoading: selectOrLoading,
                          disabled2: isEmptyList, disabled3: true, disabled4: true, disabled5: true, isLoading: false,valList3:[],valList4:[],valList5:[]}, () => { //must do things this way to force sync since setState is async
              if (isEmptyList){
                this.loadList3(0);
              }else{
                this.modifyPlacementRow(this.state,true);
              }
          });
      }
      else {
          const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPerekInfo?source=bhsWebApp&seferId='+val);
          const body = await response.json();
          const perakim = body.map(function(e) {return {perekNum: e.perek, perekValue: util.gematriya(e.perek, {punctuate: false}), pesukim: e.pesukim }});
          this.setState({ val1: val, val2: 0, val3: 0, valList2: perakim, disabled2: false, disabled3: true}, () => { //must do things this way to force sync since setState is async
              this.modifyPlacementRow(this.state,true);
          });
      }
  }

  async loadList3(val) {
      if (this.state.type) {
          var selectOrLoading = this.state.selectOrLoading;
          selectOrLoading[2] = <b style={{color: '#0000ff'}}>{this.props.translate('smallLoading')}</b>;

          this.setState({selectOrLoading: selectOrLoading})

          const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPlacementOccasions?source=bhsWebApp&grandParentId='+this.state.val1+'&parentId='+val);
          const body = await response.json();
          var isEmptyList = (body.length == 0 || body[0].id == null);

          selectOrLoading[2] = this.props.translate('select');

          this.setState({ val2: val, val3: 0, val4: 0, val5: 0, valList3: body,valList4:[],valList5:[],  selectOrLoading: selectOrLoading, disabled3: isEmptyList, disabled4: true, disabled5: true, isLoading: false}, () => { //must do things this way to force sync since setState is async
              
              if (isEmptyList){
                this.loadList4(0);
              }else{
                this.modifyPlacementRow(this.state,true);
              }
          });
      }
      else {
          const perek = this.state.valList2.find(function(e){return e.perekNum == val;});
          const body = Array(perek.pesukim).fill().map((x,i)=>({pasukNum: i+1, pasukValue: util.gematriya(i+1, {punctuate: false}) }));

          this.setState({ val2: val, val3: 0, valList3: body, disabled3: false}, () => { //must do things this way to force sync since setState is async
              this.modifyPlacementRow(this.state,true);
          });
      }
  }

  async loadList4(val) {
        var selectOrLoading = this.state.selectOrLoading;
        selectOrLoading[3] = <b style={{color: '#0000ff'}}>{this.props.translate('smallLoading')}</b>;
        this.setState({selectOrLoading: selectOrLoading})

      const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPlacementOccasions?source=bhsWebApp&grandParentId='+this.state.val1+'&parentId='+this.state.val2+'&occasionId='+val);
      const body = await response.json();

        selectOrLoading[3] = this.props.translate('select');

      this.setState({ val3: val, val4: 0, val5: 0, kriaSequenceNum: 0, valList4: body, selectOrLoading: selectOrLoading, disabled4: false, disabled5: true, isLoading: false}, () => { //must do things this way to force sync since setState is async
          this.modifyPlacementRow(this.state,true);
      });
  }

  async loadList5(val) {
      if (this.state.type) {

          var selectOrLoading = this.state.selectOrLoading;
          selectOrLoading[4] = <b style={{color: '#0000ff'}}>{this.props.translate('smallLoading')}</b>;

          this.setState({selectOrLoading: selectOrLoading})

          const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPlacementOccasions?source=bhsWebApp&grandParentId='+this.state.val1+'&parentId='+this.state.val2+'&occasionId='+this.state.val3+'&kriaId='+val);
          const body = await response.json();

        selectOrLoading[4] = this.props.translate('select');

          this.setState({ val4: val, val5: 0, valList5: body,  selectOrLoading: selectOrLoading, disabled5: false, isLoading: false}, () => { //must do things this way to force sync since setState is async
              this.modifyPlacementRow(this.state,true);
          });
      }
      else {
          const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPerekInfo?source=bhsWebApp&seferId='+val);
          const body = await response.json();
          const perakim = body.map(function(e) {return {perekNum: e.perek, perekValue: util.gematriya(e.perek, {punctuate: false}), pesukim: e.pesukim }});

          this.setState({ val4: val, val5: 0, val6: 0, valList5: perakim, disabled5: false, disabled6: true}, () => { //must do things this way to force sync since setState is async
              this.modifyPlacementRow(this.state,true);
          });
      }
  }

  async loadList6(val) {
      const perek = this.state.valList5.find(function(e){return e.perekNum == val;});
      const body = Array(perek.pesukim).fill().map((x,i)=>({pasukNum: i+1, pasukValue: util.gematriya(i+1, {punctuate: false}) }));

      this.setState({ val5: val, val6: 0, valList6: body, disabled6: false}, () => { //must do things this way to force sync since setState is async
          this.modifyPlacementRow(this.state,true);
      });
  }

      handleSelChange(event) {
         if (event.target.value == this.state.val1) return false;
         this.loadList2(event.target.value);
       }

      handleSelChange2(event) {
         if (event.target.value == this.state.val2) return false;
         this.loadList3(event.target.value);
       }

      handleSelChange3(event) {
         if (event.target.value == this.state.val3) return false;
         this.loadList4(event.target.value);
       }

      handleSelChange4(event) {
         if (event.target.value == this.state.val4) return false;
         this.loadList5(event.target.value);
       }

      handleSelChange5(event) {
         if (event.target.value == this.state.val5) return false;
         this.setState({ val5: event.target.value }, () => {this.modifyPlacementRow(this.state,true)});
       }

      handleRangeChange1(event) {
         if (event.target.value == this.state.val1) return false;
         this.loadList2(event.target.value);
       }

      handleRangeChange2(event) {
         if (event.target.value == this.state.val2) return false;
         this.loadList3(event.target.value);
       }

      handleRangeChange3(event) {
         if (event.target.value == this.state.val3) return false;
         this.setState({ val3: event.target.value}, () => { this.modifyPlacementRow(this.state,true); });
      }

      handleRangeChange4(event) {
         if (event.target.value == this.state.val4) return false;
         this.loadList5(event.target.value);
       }

      handleRangeChange5(event) {
         if (event.target.value == this.state.val5) return false;
         this.loadList6(event.target.value);
       }

      handleRangeChange6(event) {
         if (event.target.value == this.state.val6) return false;
         this.setState({ val6: event.target.value }, () => {this.modifyPlacementRow(this.state,true)});
       }

      handleCloseLine() {
         this.props.node.setSelected(true, true); //make sure the row is selected for deletion

         this.setState({ type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0,
                         valList2: [], valList3: [], valList4: [], valList5: [], valList6: [],
                         disabled2: true, disabled3: true, disabled4: true, disabled5: true, disabled6: true
                       }, () => {this.props.extDeletePlacementRow(this.state)}); //do this in case only clearing the line and not actually deleting
       }

       addFromLastTenSelections(event){
         this.modifyPlacementRow(event.target.value,false)
       }

       modifyPlacementRow(row,addRow){
        var isAddRow = addRow;
        isAddRow = isAddRow && this.props.placementRowData.length === this.props.node.rowIndex+1; //need to check this 1st since this status will not have been attained yet for Tanach Viewer, Lexicon and 3D Tree - will cause an error otherwise
        isAddRow = isAddRow && ((row.type && row.val4 != this.props.placementRowData[this.props.node.rowIndex].val4 && row.val4 != 0) ||
              (!row.type && (row.val3 != this.props.placementRowData[this.props.node.rowIndex].val3 || row.val6 != this.props.placementRowData[this.props.node.rowIndex].val6) && row.val3 != 0 && row.val6 != 0))
        if(isAddRow){//take rowValues and add it to a local storage object to build the 20 last selections
          var rowValues = {type: row.type, val1: row.val1, val2: row.val2, val3: row.val3, val4: row.val4, val5: row.val5, val6: row.val6,
            valList1: row.valList1, valList2: row.valList2, valList3: row.valList3, valList4: row.valList4, valList5: row.valList5, valList6: row.valList6 }
          var lastTenSelections = util.getFromLocalStorage("lastTenSelections")?util.getFromLocalStorage("lastTenSelections"):[]
          lastTenSelections.unshift(rowValues)//add to the top
          if(lastTenSelections.length>20)lastTenSelections.pop()
          this.setState({lastTenSelections})
          util.saveToLocalStorage("lastTenSelections", lastTenSelections);
        }
        this.props.extModifyPlacementRow(row)
       }

      async toggleType(event) {
          const wasNamedType = this.state.type;
          const url = properties.env.webServiceURL + "/BaseHaSefer/" + ((wasNamedType) ? "getSeferPerekInfo" : "getPlacementOccasions") + "?source=bhsWebApp";

          const response = await fetch(url);
          const body = await response.json();
          const body2 = (wasNamedType) ? body : [];

          this.setState({ type: !wasNamedType, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: body, valList4: body2,
                          disabled2: true, disabled3: true, disabled4: !wasNamedType, disabled5: true, disabled6: true}, () => { //must do things this way to force sync since setState is async
              this.modifyPlacementRow(this.state,true);
          });
          //this.props.api.refreshCells();
      }

     render() {
        const list1 = this.state.valList1;
        const list2 = this.state.valList2;
        const list3 = this.state.valList3;
        const list4 = this.state.valList4;
        const list5 = this.state.valList5;
        const list6 = this.state.valList6;
        const del = this.props.translate("delete"); //this.props.transWords.del; //this.props.translate("delete"); // - todo language doesn't immediately auto-change on fly w/i ag-grid when toggle the lang
        const sel = this.props.translate("select");
        const sel1 = this.state.selectOrLoading[0];
        const sel2 = this.state.selectOrLoading[1];
        const sel3 = this.state.selectOrLoading[2];
        const sel4 = this.state.selectOrLoading[3];
        const sel5 = this.state.selectOrLoading[4];
        const loading = this.props.translate("smallLoading");
        const selectPad = this.props.translate("selectPad.Placement");
        const blankPadEN = <span style={{fontSize:'1px'}}>c</span>; //tiny c
        const lastTwentyDropdown = this.props.translate("lastTwentyDropdown")
        const dropdownInstructions = this.props.translate("dropdownInstructions")
        const isHebrew = localStorage.getItem("language")=="he"
        var lastTwentySelection = <Select title={lastTwentyDropdown} disabled={this.state.lastTenSelections?this.state.lastTenSelections.length==0:true} style={{maxWidth:'25px'}} value={""} onChange={this.addFromLastTenSelections} name="pageSelect" displayEmpty autoWidth={true}>
                                    <MenuItem style={{height: '10px',backgroundColor:'#ababab', opacity:'1',color:'black',padding:isHebrew?'11px 28%':'11px 20%'}} disabled><b>{dropdownInstructions}</b></MenuItem>
                                        {this.state.lastTenSelections?this.state.lastTenSelections.map(selection=>{
                                            return selection.type?
                                                <MenuItem style={{flexDirection: 'row-reverse',height: '10px'}} value={selection}><Typography style={{lineHeight: 1}} variant="subtitle1"><small>
                                                    {selection.valList1.find(row=>row.id===selection.val1).value} {selection.val2>0?">"+selection.valList2.find(row=>row.id===selection.val2).value:""}
                                                    {selection.val3>0?">"+selection.valList3.find(row=>row.id===selection.val3).value+">":">"} </small><b>{selection.val4>0?selection.valList4.find(row=>row.id===selection.val4).value:""}
                                                    {selection.val5>0?">"+selection.valList5.find(row=>row.id===selection.val5).value:""}</b></Typography></MenuItem>
                                                :
                                                <MenuItem style={{flexDirection: 'row-reverse',height: '10px'}} value={selection}><Typography style={{lineHeight: 1}} variant="subtitle1"><b>
                                                    {`${selection.val1>0?selection.valList1.find(row=>row.id===selection.val1).value : ''} ${selection.val2>0?selection.valList2.find(row=>row.id===selection.val2).value : ''}:
                                                    ${selection.val3>0?selection.valList3.find(row=>row.id===selection.val3).value : ''}`}</b><small> עד </small><b>
                                                    {`${selection.val4>0?selection.valList4.find(row=>row.id===selection.val4).value: ''} ${selection.val5>0?selection.valList5.find(row=>row.id===selection.val5).value : ''}:
                                                    ${selection.val6>0?selection.valList6.find(row=>row.id===selection.val6).value : ''}`}</b></Typography></MenuItem>
                                        }):<MenuItem></MenuItem>}
                                      </Select>

if (this.state.type) {
        return <span className="placementGridLine">
                {isHebrew? lastTwentySelection :<></>}
{this.props.translate("namedSection")}
           &nbsp;
           <Switch className="gridSwitch"
              checked={this.state.type}
              onChange={this.toggleType}
              value="type"
              color="primary"
            />&nbsp;
{this.props.translate("pasukRange")}:
            &nbsp;&nbsp;&nbsp;
{this.props.translate("sectionType")}
          <PlacementSelector2D onChange={this.handleSelChange} value={this.state.val1} menuItems={list1} activeLanguageCode={localStorage.getItem("language")}
             selectOrLoading={this.state.selectOrLoading[0]}/>
{this.props.translate("eventType")}
          <PlacementSelector2D onChange={this.handleSelChange2} value={this.state.val2} menuItems={list2} activeLanguageCode={localStorage.getItem("language")}
            disabled={this.state.disabled2} selectOrLoading={this.state.selectOrLoading[1]}/>

{this.props.translate("eventName")}
          <PlacementSelector2D onChange={this.handleSelChange3} value={this.state.val3} menuItems={list3} activeLanguageCode={localStorage.getItem("language")}
            disabled={this.state.disabled3} selectOrLoading={this.state.selectOrLoading[2]}/>

{this.props.translate("sectionName")}
          <PlacementSelector2D onChange={this.handleSelChange4} value={this.state.val4} menuItems={list4} activeLanguageCode={localStorage.getItem("language")}
            disabled={this.state.disabled4} selectOrLoading={this.state.selectOrLoading[3]}/>

{this.props.translate("aliya")}
          <PlacementSelector2D onChange={this.handleSelChange5} value={this.state.val5} menuItems={list5} activeLanguageCode={localStorage.getItem("language")}
            disabled={this.state.disabled5} selectOrLoading={this.state.selectOrLoading[4]}/>
{this.props.translate("namedSection.finalSpacer")}
                    <span><IconButton className="closeIcon" aria-label="Clear" title={del} onClick={this.handleCloseLine}><CloseIcon /></IconButton></span>{blankPadEN}
        {localStorage.getItem("language")=="en"?lastTwentySelection :<></>}
          </span>;
    }
    else { return <span className="rangeGridLine">
                {isHebrew?lastTwentySelection:<></>}
{this.props.translate("namedSection")}
                             &nbsp;
                             <Switch className="gridSwitch"
                                checked={this.state.type}
                                onChange={this.toggleType}
                                value="type"
                                color="primary"
                              />&nbsp;
{this.props.translate("pasukRange")}:
                              &nbsp;&nbsp;&nbsp;
{this.props.translate("sefer")}
          <PlacementSelector2D onChange={this.handleRangeChange1} value={this.state.val1} menuItems={list1} activeLanguageCode={localStorage.getItem("language")}
            disabled={false} selectOrLoading={this.state.selectOrLoading[0]} type='placementRendererRangeSefer'/>

{this.props.translate("perek")}
          <PlacementSelector2D onChange={this.handleRangeChange2} value={this.state.val2} menuItems={list2} activeLanguageCode={localStorage.getItem("language")}
            disabled={this.state.disabled2} selectOrLoading={this.state.selectOrLoading[1]} type='placementRendererRangePerek'/>

{this.props.translate("pasuk")}
          <PlacementSelector2D onChange={this.handleRangeChange3} value={this.state.val3} menuItems={list3} activeLanguageCode={localStorage.getItem("language")}
            disabled={this.state.disabled3} selectOrLoading={this.state.selectOrLoading[2]} type='placementRendererRangePasuk'/>

<span className="midRange">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.translate("to")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
{this.props.translate("sefer")}
          <PlacementSelector2D onChange={this.handleRangeChange4} value={this.state.val4} menuItems={list4} activeLanguageCode={localStorage.getItem("language")}
            disabled={this.state.disabled4} selectOrLoading={this.state.selectOrLoading[3]} type='placementRendererRangeSefer'/>

{this.props.translate("perek")}
          <PlacementSelector2D onChange={this.handleRangeChange5} value={this.state.val5} menuItems={list5} activeLanguageCode={localStorage.getItem("language")}
            disabled={this.state.disabled5} selectOrLoading={this.state.selectOrLoading[4]} type='placementRendererRangePerek'/>

{this.props.translate("pasuk")}
          <PlacementSelector2D onChange={this.handleRangeChange6} value={this.state.val6} menuItems={list6} activeLanguageCode={localStorage.getItem("language")}
            disabled={this.state.disabled6} selectOrLoading={this.state.selectOrLoading[5]} type='placementRendererRangePasuk'/>

{this.props.translate("pasukRange.finalSpacer")}
          <IconButton className="closeIcon" aria-label="Clear" title={del} onClick={this.handleCloseLine}><CloseIcon /></IconButton>{blankPadEN}
        {localStorage.getItem("language")=="en"?lastTwentySelection :<></>}
          </span>;
       }
    }
}

export default withLocalize(PlacementRenderer);
