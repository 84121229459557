import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { Link, withRouter } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import LoadingOverlay from 'react-loading-overlay';
import { Paper, Typography, Dialog, DialogActions, DialogContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PlacementRenderer from './PlacementRenderer';
import DiffViewerDisplaySettings from './DiffViewerDisplaySettings';
import TextComparatorSectionSelector from './TextComparatorSectionSelector';
import {Spinner} from 'primereact/spinner';
import * as properties from './config/properties.js';
// import {Checkbox} from 'primereact/checkbox';
import { Menu, Item, Separator, Submenu, MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import ContextMenu from './ContextMenu';
import TaamParser from './TaamParser';
import ReactDiffViewer from 'react-diff-viewer';

const DiffMethod = {
  CHARS: 'diffChars',
  WORDS: 'diffWords',
  WORDS_WITH_SPACE: 'diffWordsWithSpace',
  LINES: 'diffLines',
  TRIMMED_LINES: 'diffTrimmedLines',
  SENTENCES: 'diffSentences',
  CSS: 'diffCss',
}

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const styles = theme => ({
  toggleContainer: {
    height: 28,
    backgroundColor: 'transparent',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    alignItems: 'center',
    margin: `auto`,
    background: theme.palette.background.default,
  },
});

//todo this is repeated elsewhere - combine it
function encodeFilterIds(nodes, nodeString) {
    var nodeStr = (nodeString != undefined) ? nodeString : "0".repeat(44);
    nodes.forEach((node)=>{
        if (node.tableName == "Sefer" && node.isChecked)
            nodeStr = util.stuff(nodeStr, "1", node.id-1, 1);
        if(node.children){
            nodeStr = encodeFilterIds(node.children, nodeStr);
        }
    })
    return nodeStr;
}

function getAlternateRowStyle(params) {
    if (params.node.rowIndex % 2 === 0 && !isOutlier(params)) {
        return {background: '#F0F0F0'};
    } else if (params.node.rowIndex % 2 === 0 && isOutlier(params)){
        return {background: '#c4cfff'};
    } else if (params.node.rowIndex % 2 === 1 && isOutlier(params)){
        return {background: '#d9e0ff'};
    }
}

function isOutlier(params) {
    if(!params.data.termInstances1 || !params.data.termInstances2){
        return true;
    }
    return false;
}

function namedSection(params) {
  return '<Spinner name="ns" id="ns" value={1} min={1} max={10} />';
}

function pasukRange(params) {
  return <Spinner name="pr" id="pr"  value={1} min={1} max={10} />;
}

function BaseParameters() {
  this.source = null;
  this.clientIP = null;
  this.morphInflections = null;
  this.filterIds = null;
  this.nGrams = null;
  this.sortResultsBy = null;
  this.resultsToDisplay = null;
  this.namedLocations =  []; //NamedLocation()
  this.namedLocations2 =  []; //NamedLocation()
  this.pasukRangeLocations = []; //PerekPasukRange()
  this.pasukRangeLocations2 = []; //PerekPasukRange()
  this.textSource = 1;
}

function NamedLocation(kriaId, aliyaId) {
  this.kriaId =  kriaId; //int
  this.aliyaId = aliyaId; //int
}

function PerekPasukRange(seferId1, perekNum1, pasukNum1, seferId2, perekNum2, pasukNum2) {
  this.seferId1 =  seferId1; //int
  this.perekNum1 = perekNum1;  //int
  this.pasukNum1 =  pasukNum1; //int
  this.seferId2 = seferId2;  //int
  this.perekNum2 =  perekNum2; //int
  this.pasukNum2 = pasukNum2;  //int
}

class DiffViewer extends Component {

 constructor(props) {
    super(props);
    this.state = {
      clientIP: '',
      columnDefs: this.getGridColumns(),
      defaultLanguage: '',
      text1DialogOpen: false,
      text2DialogOpen: false,
      textToCopy: 'bitui',
      useKinuim: false,
      searchAsQuote: false,
      includeMekorot: false,
      contextMenuMakor: "",
      contextMenuText: "",
      taamParseData: null,
      taamParserOpen: false,
      taamParserPasukId: null,
      taamParserLocation: null,
      contextMenuIsAPasuk: false,
      // isLoading: false, // moved these up to TextComparatorViewer
      // termTotal1Accurate: true,
      // termTotal2Accurate: true,
      // diffUnit: 'word',
      // highlightCondition: 'different',
      // removeWhiteSpace: false,
      // removeLineBreaks: false,
      // isSplitView: true,
      // removeTaameiMikra: true,
      // removeNikud: true,
      // isIndependentScroll: false,
      // textBlock1Data: [],
      // textBlock2Data: [],
      // textBlock1: "",
      // textBlock2: "",
      // sourceList1: [],
      // sourceList2: [],
      // totalTerms1: 0,
      // totalTerms2: 0,
    };

    this.refresh = this.refresh.bind(this);
    this.text1GridReady = this.text1GridReady.bind(this);
    this.addText1Row = this.addText1Row.bind(this);
    this.extAddText1Row = this.extAddText1Row.bind(this);
    this.deleteText1Row = this.deleteText1Row.bind(this);
    this.extDeleteText1Row = this.extDeleteText1Row.bind(this);
    this.extModifyText1Row = this.extModifyText1Row.bind(this);
    this.modifyText1Row = this.modifyText1Row.bind(this);
    this.text2GridReady = this.text2GridReady.bind(this);
    this.addText2Row = this.addText2Row.bind(this);
    this.extAddText2Row = this.extAddText2Row.bind(this);
    this.deleteText2Row = this.deleteText2Row.bind(this);
    this.extDeleteText2Row = this.extDeleteText2Row.bind(this);
    this.extModifyText2Row = this.extModifyText2Row.bind(this);
    this.modifyText2Row = this.modifyText2Row.bind(this);
    this.getRenderVals = this.getRenderVals.bind(this);
    this.handleText1DialogOpen = this.handleText1DialogOpen.bind(this);
    this.handleText1DialogClose = this.handleText1DialogClose.bind(this);
    this.handleText2DialogOpen = this.handleText2DialogOpen.bind(this);
    this.handleText2DialogClose = this.handleText2DialogClose.bind(this);
    this.handleResultsToDisplayChange = this.handleResultsToDisplayChange.bind(this);
    this.handleNGramsChange = this.handleNGramsChange.bind(this);
    this.handleSortResultsByChosenChange = this.handleSortResultsByChosenChange.bind(this);
    this.getGridColumns = this.getGridColumns.bind(this);
    this.getGridColumnClass = this.getGridColumnClass.bind(this);
    this.isCurrentSortColumn = this.isCurrentSortColumn.bind(this);
    this.onCellContextMenu = this.onCellContextMenu.bind(this);
    this.toggleUseKinuim = this.toggleUseKinuim.bind(this);
    this.toggleIncludeMekorot = this.toggleIncludeMekorot.bind(this);
    this.toggleSearchAsQuote = this.toggleSearchAsQuote.bind(this);
    this.toggleTaameiMikra = this.toggleTaameiMikra.bind(this);
    this.toggleIndependentScroll = this.toggleIndependentScroll.bind(this);
    this.openTaamParser = this.openTaamParser.bind(this);
    this.closeTaamParser = this.closeTaamParser.bind(this);
    this.saveText1Data = this.saveText1Data.bind(this);
    this.saveText2Data = this.saveText2Data.bind(this);

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}
  }

  async openTaamParser(pasukLocation){
    this.props.extOpenTaamParser(pasukLocation)
    const body = await this.props.extFetchTaamParseData(pasukLocation)
    this.setState({taamParseData: body})
    this.setState({taamParserOpen: true, taamParserPasukId: body.pasukId, taamParserLocation: body.pasukLocation, taamParserOpen: false}, () => {
        this.setState({taamParserOpen: true})
    })
  }

  closeTaamParser(){
    this.props.extCloseTaamParser()
    this.setState({taamParserOpen: false})
  }

  setDiffUnit(e, val){
    if(val){
      this.props.extUpdateDiffData({
        diffUnit: val
      }, () => {
        this.formatDiffTable();
      })
    }
  }

  setHighlightCondition(e, val){
    if(val){
      this.props.extUpdateDiffData({
        highlightCondition: val
      }, () => {
        this.formatDiffTable();
      });
    }
  }

  formatDiffTable(){
    this.formatPerfectMatches();
    this.setSourceCells();
    this.formatEmptyCells();
    this.getHeaderRow();
    this.formatIndependentScrollTable();
    this.setHighlightColors();
    this.clearEmptyCells();
  }

  formatPerfectMatches(){
    var contentCells = document.getElementsByClassName("css-vl0irh-content");
    if(this.props.diffData.highlightCondition === 'same'){
      for(let i = 0; i < contentCells.length; i++){
        var currContentCell = contentCells[i]
        if(currContentCell.className === "css-vl0irh-content") { // is perfect match
          currContentCell.className = currContentCell.className.concat(" css-rq9a2a-diff-removed diff-perfect-match-cell")
        }
      }
    } else {
      for(let i = 0; i < contentCells.length; i++){
        var currContentCell = contentCells[i]
        if(currContentCell.className === "css-vl0irh-content css-rq9a2a-diff-removed diff-perfect-match-cell") { // is perfect match
          currContentCell.className = "css-vl0irh-content"
          currContentCell.style.setProperty("background", "unset")
        }
      }
    }
  }

  clearEmptyCells(){
    var emptyCells = document.getElementsByClassName("empty-diff-cell");
    for(let i = 0; i < emptyCells.length; i++){
      var currCell = emptyCells[i];
      currCell.style.backgroundColor = "unset";
      currCell.style.background = "unset";
    }
  }

  setHighlightColors(){
    var removedWords = document.getElementsByClassName("css-hf3w1f-word-removed");
    var addedWords = document.getElementsByClassName("css-1u4zuq6-word-added");
    var allWords = document.getElementsByClassName("css-cncyp1-word-diff");
    var gutterCells = document.getElementsByClassName("css-1ks9aww-gutter");
    var removedCells = document.getElementsByClassName("css-rq9a2a-diff-removed");
    var addedCells = document.getElementsByClassName("css-cnnxkz-diff-added");

    if(this.props.diffData.highlightCondition === "same"){

      for(let h = 0; h < removedCells.length; h++){
        var currCell = removedCells[h];
        if(!currCell.className.split(" ").includes("empty-diff-cell")){
          currCell.style.setProperty("background", "#fceeff", "important")
        }
      }
      for(let h = 0; h < addedCells.length; h++){
        var currCell = addedCells[h];
        if(!currCell.className.split(" ").includes("empty-diff-cell")){
          currCell.style.setProperty("background", "#fceeff", "important")
        }
      }
      for(let i = 0; i < allWords.length; i++){
        var currWord = allWords[i];
        if(currWord.innerHTML !== " "){
          currWord.style.background = '#d2b8fd';
          currWord.style.fontWeight = 'bold';
        }
      }
      for(let j = 0; j < addedWords.length; j++){
        var currWord = addedWords[j];
        currWord.style.setProperty("background", "unset", "important")
        currWord.style.fontWeight = 'normal';
      }
      for(let k = 0; k < removedWords.length; k++){
        var currWord = removedWords[k];
        currWord.style.setProperty("background", "unset", "important")
        currWord.style.fontWeight = 'normal';
      }
      for(let h = 0; h < gutterCells.length; h++){
        var currCell = gutterCells[h];
        if(!currCell.className.split(" ").includes("empty-diff-cell")){
          currCell.style.setProperty("background", "#f5cdff", "important")
        } else {
          currCell.style.setProperty("background", "unset", "important")
        }
      }

    } else {

      for(let i = 0; i < allWords.length; i++){
        var currWord = allWords[i];
        if(currWord.innerHTML !== " "){
          currWord.style.background = '';
          currWord.style.fontWeight = '';
        }
      }
      for(let j = 0; j < addedWords.length; j++){
        var currWord = addedWords[j];
        currWord.style.background = '';
        currWord.style.fontWeight = '';
      }
      for(let k = 0; k < removedWords.length; k++){
        var currWord = removedWords[k];
        currWord.style.background = '';
        currWord.style.fontWeight = '';
      }
      for(let h = 0; h < gutterCells.length; h++){
        var currCell = gutterCells[h];
        currCell.style.background = '';
      }
      for(let h = 0; h < removedCells.length; h++){
        var currCell = removedCells[h];
        currCell.style.background = '';
      }
      for(let h = 0; h < addedCells.length; h++){
        var currCell = addedCells[h];
        currCell.style.background = '';
      }
    }
  }

  toggleIndependentScroll(e){
    this.props.extUpdateDiffData({ isIndependentScroll: !this.props.diffData.isIndependentScroll }, () => {
      this.formatDiffTable();
    })
  }

  toggleRemoveWhiteSpace(e){
    this.props.extUpdateDiffData({ removeWhiteSpace: !this.props.diffData.removeWhiteSpace }, () => {
      this.formatDiffTable();
    })
  }

  toggleRemoveLineBreaks(e){
    this.props.extUpdateDiffData({ removeLineBreaks: !this.props.diffData.removeLineBreaks }, () => {
      this.formatDiffTable()
    })
  }

  toggleSplitView(e){
    this.props.extUpdateDiffData({ isSplitView: !this.props.diffData.isSplitView }, () => {
      this.formatDiffTable();
    })
  }

  toggleTaameiMikra(e){
    this.props.extUpdateDiffData({ removeTaameiMikra: !this.props.diffData.removeTaameiMikra }, () => {
      this.formatDiffTable();
    })
  }

  toggleNikud(e){
    this.props.extUpdateDiffData({ removeNikud: !this.props.diffData.removeNikud }, () => {
      this.formatDiffTable();
    })
  }

  toggleSearchAsQuote(){
    this.setState({ searchAsQuote: !this.state.searchAsQuote }, () => {
      this.formatDiffTable();
    })
  }

    isCurrentSortColumn(columnFieldName){
        if(this.state){
            if(this.state.sortResultsByCurrent === 1 && ['termInstances1', 'termInstances2', 'percentBlock1', 'percentBlock2'].includes(columnFieldName)){
                return true;
            } else if (this.state.sortResultsByCurrent === 2 && ['termInstancesTanach1', 'termInstancesTanach2', 'percentBlock1VsTanach', 'percentBlock2VsTanach'].includes(columnFieldName)) {
                return true;
            } else if (this.state.sortResultsByCurrent === 3 && ['termInstancesSeferGroup1', 'termInstancesSeferGroup2', 'percentBlock1VsSeferGroup', 'percentBlock2VsSeferGroup'].includes(columnFieldName)) {
                return true;
            }
        }
        return false;
    }

    getGridColumnClass(columnFieldName, params) {
        var cellClass = 'row-thin-small';
        if(columnFieldName === 'term'){
            var cellClass = 'row-header-large';
        } else if (columnFieldName === 'rowNum'){
            var cellClass = 'row-header-center';
        }
        if(this.isCurrentSortColumn(columnFieldName)){
            cellClass = cellClass.concat(' sort-column');
        }

        if(params){
            if(!params.data.termInstances1 || !params.data.termInstances2){
                cellClass = cellClass.concat(' outlier-row')

            }
        }
        return cellClass;
    }

    getGridColumns(){
        var fakeThis = this
        return [
                   {headerName: "% במסומנים", field: "percentBlock1VsSefer", width: 130, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock1VsSeferGroup", params)}} ,
                   {headerName: "בספרים המסומנים", field: "termInstancesSefer1", width: 121, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstancesSeferGroup1", params)}} ,
                   {headerName: "% בתנ\"ך", field: "percentBlock1VsTanach", width: 105, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock1VsTanach", params)}} ,
                   {headerName: "בתנ\"ך", field: "termInstancesTanach1", width: 84, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstancesTanach1", params)}} ,
                   {headerName: "% בקטעים", field: "percentBlock1", width: 117, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock1", params)}} ,
                   {headerName: "הופעות בקטעים", field: "termInstances1", width: 97, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstances1", params)}} ,
                   {headerName: "#", field: "rowNum", width: 60, headerClass: "ag-header-cell-center", cellClass: function(params) {return fakeThis.getGridColumnClass("rowNum", params)}, filter: 'agNumberColumnFilter'},
                   {headerName: "בטוי", field: "term", width: 130, cellClass: function(params) {return fakeThis.getGridColumnClass("term", params)}, cellRenderer: function(params) {return util.decorateNekudosTaamim(params.data.term);}},
                   {headerName: "הופעות בקטעים", field: "termInstances2", width: 97, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstances2", params)}} ,
                   {headerName: "% בקטעים", field: "percentBlock2", width: 117, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock2", params)}} ,
                   {headerName: "בתנ\"ך", field: "termInstancesTanach2", width: 84, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstancesTanach2", params)}} ,
                   {headerName: "% בתנ\"ך", field: "percentBlock2VsTanach", width: 105, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock2VsTanach", params)}} ,
                   {headerName: "בספרים המסומנים", field: "termInstancesSefer2", width: 121, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstancesSeferGroup2", params)}} ,
                   {headerName: "% במסומנים", field: "percentBlock2VsSefer", width: 130, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock2VsSeferGroup", params)}} ,
               ];
    }

    resultsGridReady = params => {
      this.resultsApi = params.api;
      this.resultsColumnApi = params.columnApi;
    };

    text1GridReady = params => {
      this.props.text1GridReady(params);
//       this.text1Api = params.api;
//       this.text1ColumnApi = params.columnApi;

//       this.text1Api.setRowData(this.props.text1Data);

// //following CSS classes seem to get overridden below in PlacementRenderer by placementGridLine and rangeGridLine
//       this.text1Api.setColumnDefs(
//                [{headerName: this.props.translate("addLines"), headerClass: 'row-thin-center', field: "namedSection", width: 1168, cellClass: "row-thin-small", cellRendererFramework: PlacementRenderer, cellRendererParams:
//                    {
//                     extModifyPlacementRow: this.extModifyText1Row,
//                     extAddPlacementRow: this.extAddText1Row,
//                     extDeletePlacementRow: this.extDeleteText1Row,
//                     translate: this.props.translate, //do I need to pass this here?
//                 },
//           //cellClass: function(params) {return params.data.type ? 'placementGridLine' : 'rangeGridLine';},
//                 }]
//           );
//       if (this.props.text1Data.length > 0)
//           this.text1Api.setFocusedCell(0, "namedSection", null);
//       // var instances = this.refs.agGrid.gridOptions.api.getCellRendererInstances(params);
    };

    text2GridReady = params => {
      this.props.text2GridReady(params);
//       this.text2Api = params.api;
//       this.text2ColumnApi = params.columnApi;
//       this.text2Api.setRowData(this.props.text2Data);

// //following CSS classes seem to get overridden below in PlacementRenderer by placementGridLine and rangeGridLine
//       this.text2Api.setColumnDefs(
//                [{headerName: this.props.translate("addLines"), headerClass: 'row-thin-center', field: "namedSection", width: 1168, cellClass: "row-thin-small", cellRendererFramework: PlacementRenderer, cellRendererParams:
//                    {
//                     extModifyPlacementRow: this.extModifyText2Row,
//                     extAddPlacementRow: this.extAddText2Row,
//                     extDeletePlacementRow: this.extDeleteText2Row,
//                     translate: this.props.translate, //do I need to pass this here?
//                 },
//           //cellClass: function(params) {return params.data.type ? 'placementGridLine' : 'rangeGridLine';},
//                 }]
//           );
//       if (this.props.text2Data.length > 0)
//           this.text2Api.setFocusedCell(0, "namedSection", null);
//       // var instances = this.refs.agGrid.gridOptions.api.getCellRendererInstances(params);
    };

    addText1Row() {
      this.props.addText1Row();
        // var newRow = {type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []};
        // var newData = this.props.text1Data;
        // newData.push(newRow);
        // this.setState( {text1Data: newData}, () => {this.text1Api.updateRowData({ add: [newRow] })});
    }

    addText2Row() {
      this.props.addText2Row();
        // var newRow = {type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []};
        // var newData = this.props.text2Data;
        // newData.push(newRow);
        // this.setState( {text2Data: newData}, () => {this.text2Api.updateRowData({ add: [newRow] })});
    }

    extAddText1Row = () => {
        this.props.addText1Row();
    }

    extAddText2Row = () => {
        this.props.addText2Row();
    }

    deleteText1Row(extState) {
      this.props.deleteText1Row(extState);
        // this.props.extUpdateDiffData({termTotal1Accurate: false})
        // var selected = this.text1Api.getFocusedCell();
        // var i = selected.rowIndex;
        // if (i == this.text1Api.getLastDisplayedRow()) { //then just clear
        //     this.modifyText1Row(extState);
        //     //selected.setSelected(false);
        // }
        // else {
        //     var selectedData = this.text1Api.getSelectedRows();
        //     var newData = this.props.text1Data;
        //     newData.splice(i,1);
        //     this.setState( {text1Api: newData}, () => {this.text1Api.updateRowData({ remove: selectedData }) });
        // }
    }

    deleteText2Row(extState) {
      this.props.deleteText2Row(extState);
        // this.props.extUpdateDiffData({termTotal2Accurate: false})
        // var selected = this.text2Api.getFocusedCell();
        // var i = selected.rowIndex;
        // if (i == this.text2Api.getLastDisplayedRow()) { //then just clear
        //     this.modifyText2Row(extState);
        //     //selected.setSelected(false);
        // }
        // else {
        //     var selectedData = this.text2Api.getSelectedRows();
        //     var newData = this.props.text2Data;
        //     newData.splice(i,1);
        //     this.setState( {text2Api: newData}, () => {this.text2Api.updateRowData({ remove: selectedData }) });
        // }
    }

    extDeleteText1Row = (extState) => {
        this.props.deleteText1Row(extState);
    }

    extDeleteText2Row = (extState) => {
        this.props.deleteText2Row(extState);
    }

    extModifyText1Row = (extState) => {
        this.props.modifyText1Row(extState);
    }

    extModifyText2Row = (extState) => {
        this.props.modifyText2Row(extState);
    }

    modifyText1Row(extState) {
      this.props.modifyText1Row(extState);
        // this.props.extUpdateDiffData({termTotal1Accurate: false})

        // var isAddRow = (extState.type && extState.val4 != this.props.text1Data.val4 && extState.val4 != 0) || (!extState.type && extState.val3 != this.props.text1Data.val3 && extState.val3 != 0 && extState.val6 != this.state.text1Data.val6 && extState.val6 != 0);
        // var selected = this.text1Api.getFocusedCell();

        // var i = selected.rowIndex;
        // var rowValues = {type: extState.type, val1: extState.val1, val2: extState.val2, val3: extState.val3, val4: extState.val4, val5: extState.val5, val6: extState.val6,
        //                                       valList1: extState.valList1, valList2: extState.valList2, valList3: extState.valList3, valList4: extState.valList4, valList5: extState.valList5, valList6: extState.valList6 };
        // var newData = this.props.text1Data;
        // newData[i] = rowValues;

        // this.setState( {text1Data: newData}, () => {
        //     if (i == this.text1Api.getLastDisplayedRow() && isAddRow) {
        //         this.addText1Row();
        //     }
        // });

    }

    modifyText2Row(extState) {
      this.props.modifyText2Row(extState);
        // this.props.extUpdateDiffData({termTotal2Accurate: false})

        // var isAddRow = (extState.type && extState.val4 != this.props.text2Data.val4 && extState.val4 != 0) || (!extState.type && extState.val3 != this.props.text2Data.val3 && extState.val3 != 0 && extState.val6 != this.state.text2Data.val6 && extState.val6 != 0);
        // var selected = this.text2Api.getFocusedCell();

        // var i = selected.rowIndex;
        // var rowValues = {type: extState.type, val1: extState.val1, val2: extState.val2, val3: extState.val3, val4: extState.val4, val5: extState.val5, val6: extState.val6,
        //                                       valList1: extState.valList1, valList2: extState.valList2, valList3: extState.valList3, valList4: extState.valList4, valList5: extState.valList5, valList6: extState.valList6 };
        // var newData = this.props.text2Data;
        // newData[i] = rowValues;

        // this.setState( {text2Data: newData}, () => {
        //     if (i == this.text2Api.getLastDisplayedRow() && isAddRow) {
        //         this.addText2Row();
        //     }
        // });
    }

    handleText1DialogOpen() {
        this.setState({ text1DialogOpen: true })
    }

    handleText1DialogClose() {
        this.setState({ text1DialogOpen: false })
    }

    handleText2DialogOpen() {
        this.setState({ text2DialogOpen: true })
    }

    handleText2DialogClose() {
        this.setState({ text2DialogOpen: false })
    }

   getRenderVals(textDataRow){
        var renderVal1, renderVal2, renderVal3, renderVal4, renderVal5, renderVal6
        var renderVals
        if(textDataRow.type){ //is named section
            var selectedVal1 = textDataRow.valList1.filter(obj => {return obj.id === textDataRow.val1});
            var selectedVal2 = textDataRow.valList2.filter(obj => {return obj.id === textDataRow.val2});
            var selectedVal3 = textDataRow.valList3.filter(obj => {return obj.id === textDataRow.val3});
            var selectedVal4 = textDataRow.valList4.filter(obj => {return obj.id === textDataRow.val4});
            var selectedVal5 = textDataRow.valList5.filter(obj => {return obj.id === textDataRow.val5});

            if(selectedVal1[0]){ renderVal1 = selectedVal1[0].value+' > '}
            if(selectedVal2[0]){ renderVal2 = selectedVal2[0].value+' > '}
            if(selectedVal3[0]){ renderVal3 = selectedVal3[0].value+' > '}
            if(selectedVal4[0]){ renderVal4 = selectedVal4[0].value+' > '}
            if(selectedVal5[0]){ renderVal5 = selectedVal5[0].value+' > '}

            var renderVals = [renderVal1, renderVal2, renderVal3, renderVal4, renderVal5]

            for(let i = 4; i >= 0; i--){
                if(renderVals[i]){
                    renderVals[i] = renderVals[i].substring(0, renderVals[i].length - 3)
                    break
                }
                //if it is defined, set the one after it as the last value
            }

        } else { //is pasuk range
            var selectedVal1 = textDataRow.valList1.filter(obj => {return obj.seferId === textDataRow.val1});
            var selectedVal2 = textDataRow.valList2.filter(obj => {return obj.perekNum === textDataRow.val2});
            var selectedVal3 = textDataRow.valList3.filter(obj => {return obj.pasukNum === textDataRow.val3});
            var selectedVal4 = textDataRow.valList4.filter(obj => {return obj.seferId === textDataRow.val4});
            var selectedVal5 = textDataRow.valList5.filter(obj => {return obj.perekNum === textDataRow.val5});
            var selectedVal6 = textDataRow.valList6.filter(obj => {return obj.pasukNum === textDataRow.val6});

            if(selectedVal1[0]){ renderVal1 = selectedVal1[0].seferName}
            if(selectedVal2[0]){ renderVal2 = selectedVal2[0].perekValue}
            if(selectedVal3[0]){ renderVal3 = selectedVal3[0].pasukValue}
            if(selectedVal4[0]){ renderVal4 = selectedVal4[0].seferName}
            if(selectedVal5[0]){ renderVal5 = selectedVal5[0].perekValue}
            if(selectedVal6[0]){ renderVal6 = selectedVal6[0].pasukValue}

            renderVals = [renderVal1, renderVal2, renderVal3, renderVal4, renderVal5, renderVal6]
        }

        return renderVals
   }

   handleResultsToDisplayChange(e){
        this.setState({resultsToDisplay: e.target.value})
   }

    handleNGramsChange(e){
        this.setState({nGrams: e.target.value})
    }

    handleSortResultsByChosenChange(e){
        this.setState({sortResultsByChosen: e.target.value})
    }

  async componentDidMount() {
      this.setLanguage(localStorage.getItem("language"));
      this.formatDiffTable();
  }

  getNewBaseParameters(){
    var baseParams = new BaseParameters();
    var filterIds = encodeFilterIds(this.props.extFilterData);
    filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
    filterIds = util.right("00000000000"+filterIds,11);
    baseParams.nGrams = this.state.nGrams;
    baseParams.sortResultsBy = this.state.sortResultsByChosen;
    baseParams.resultsToDisplay = this.state.resultsToDisplay;
    baseParams.source = 'bhsWebApp';
    baseParams.filterIds = filterIds;
    baseParams.morphInflections = this.props.extSearchDefData; //str
    baseParams.isReverse = this.state.direction === 'reverse';
    baseParams.rangesAndNamedLocations = [];
    baseParams.textSource = this.props.textSource==='targum'?2:1;

    return baseParams;
  }

  async refresh() {
    this.props.extUpdateDiffData({ isLoading: true }, async () => {
      var baseParams1 = this.getNewBaseParameters();
      var baseParams2 = this.getNewBaseParameters();

      var e, i;
      for (i = 0; i < this.props.text1Data.length-1; i++) { //last position in placementRowData is never populated
          e = this.props.text1Data[i];
          if (e.type) {
            // baseParams.namedLocations.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
            baseParams1.rangesAndNamedLocations.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
          }
          else {
            // baseParams.pasukRangeLocations.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
            baseParams1.rangesAndNamedLocations.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
          }
      }
  
      for (i = 0; i < this.props.text2Data.length-1; i++) { //last position in placementRowData is never populated
          e = this.props.text2Data[i];
          if (e.type) {
            // baseParams.namedLocations2.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
            baseParams2.rangesAndNamedLocations.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
          }
          else {
            // baseParams.pasukRangeLocations2.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
            baseParams2.rangesAndNamedLocations.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
          }
      }     
  
      const colDefs = this.getGridColumns();

      
      // baseParams.matchDistance = this.state.matchDistance;
      // baseParams.wordLength = this.state.wordLength;
      // baseParams.wordsToSkip = this.state.wordsToSkip;

      const response1 = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getTextBlock', {method: 'post', body: JSON.stringify(baseParams1)});
      const body1 = await response1.json();
      const response2 = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getTextBlock', {method: 'post', body: JSON.stringify(baseParams2)});
      const body2 = await response2.json();

      this.setTextBlock(body1, 1)
      this.setTextBlock(body2, 2)
  
      var rowData = body1;
  
      this.props.extUpdateDiffData({rowData: []}, () => {
        this.props.extUpdateDiffData({
          rowData: rowData,
          isLoading: false,
          termTotal1Accurate: true,
          termTotal2Accurate: true,
          textBlock1Data: body1,
          textBlock2Data: body2,
        }, () => {
          this.formatDiffTable();
        })
      })
    })
  }

    onCellContextMenu(e){
      this.setState({useKinuim: false, includeMekorot: false})
      this.styleContextMenu(e);
      this.setTextToCopy(e);
    }

    setTextToCopy(e){
      var textToCopy = '';
      var makor = '';
      var text = '';

      //bubble up until td
      var parentCell = e.target;
      while(parentCell.nodeName !== "TD"){
        parentCell = parentCell.parentNode
      }
      //find column
      var columnIndex = parentCell.cellIndex;
      //move to appropriate source cell
      var sourceCell = parentCell;
      if([0, 3].includes(columnIndex)){
        //move left
        sourceCell = parentCell.nextSibling;
      } else if ([5, 2].includes(columnIndex)) {
        //move right
        sourceCell = parentCell.previousSibling;
      }
      //get source
      makor = sourceCell.children[0].innerHTML;

      if(window.getSelection().toString()){
        textToCopy = 'highlighted';
        text = window.getSelection().toString();
      } else {
        textToCopy = 'pasuk';
        var textCell = parentCell;
        if([0, 3].includes(columnIndex)){
          //move 2 left
          textCell = parentCell.nextSibling.nextSibling;
        } else if ([4, 1].includes(columnIndex)) {
          //move 1 left
          textCell = parentCell.nextSibling;
        }
        if(textCell){
          text = textCell.textContent;
        }
      }

      this.setState({
        textToCopy,
        contextMenuMakor: makor,
        contextMenuText: text,
        contextMenuIsAPasuk: text.length > 0,
      })
    }

    styleContextMenu(e){
      const { innerWidth } = window;
      var submenus = document.getElementsByClassName('react-contexify__submenu');
      for(let i = 0; i < submenus.length; i++){
        var submenu = submenus[i];
        var submenuWidth = parseInt(submenu.style.minWidth.slice(0, submenu.style.minWidth.length-2));
        if(this.props.activeLanguage.code === 'he'){
          if(e.clientX > submenuWidth){
            submenu.style.left = '';
            submenu.style.right = '100%';
          } else {
            submenu.style.left = '100%';
            submenu.style.right = '';
          }
        } else if(this.props.activeLanguage.code === 'en'){
          if(innerWidth-e.clientX-300 > submenuWidth){
            submenu.style.left = '100%';
            submenu.style.right = '';
          } else {
            submenu.style.left = '';
            submenu.style.right = '100%';
          }
        }
      }
    }

    toggleUseKinuim(){
        this.setState({ useKinuim: !this.state.useKinuim })
    }
    toggleIncludeMekorot(){
      this.setState({ includeMekorot: !this.state.includeMekorot })
  }

    setTextBlock(textBlockData, sideNum){
      var retVal = "";
      var sourceList = [];
      for(let i = 0; i < textBlockData.length; i++) { // loop through sections
        var currSection = textBlockData[i];
        for(let j = 0; j < currSection.length; j++) { // loop through psukim in section
          var currPasuk = currSection[j];
          var pasukText = typeof(currPasuk.kriaName) === 'string' ? currPasuk.t1 : currPasuk.text;
          var location = currPasuk.location;
          sourceList.push(location)
          var locationArray = location.split(":");
          var seferName = locationArray[0];
          retVal += pasukText + " \n";
        }
      }

      var justWords = retVal;
      while(justWords.indexOf("\n") !== -1){
        justWords = justWords.replace("\n", "")
      }
      while(justWords.indexOf("  ") !== -1){
        justWords = justWords.replace("  ", " ")
      }

      var wordCount = justWords.split(" ").length - 1;
      if(sideNum === 1) {
        this.props.extUpdateDiffData({
          textBlock1: retVal,
          sourceList1: sourceList,
          totalTerms1: wordCount,
        }, () => {
          this.setSourceCells();
        })
      } else {
        this.props.extUpdateDiffData({
          textBlock2: retVal,
          sourceList2: sourceList,
          totalTerms2: wordCount,
        }, () => {
          this.setSourceCells();
        })
      }

      return retVal;
    }

    formatTextBlock(textBlock){
      var retVal = textBlock;
      if(this.props.diffData.removeLineBreaks){
        retVal = this.removeLineBreaks(retVal)
      }
      if(this.props.diffData.removeWhiteSpace){
        retVal = this.removeWhiteSpace(retVal)
      }
      if(this.props.diffData.removeTaameiMikra){
        retVal = this.removeTaamim(retVal)
      }
      if(this.props.diffData.removeNikud){
        retVal = this.removeNikud(retVal)
      }
      return retVal;
    }

    removeTaamim(str){
      for (let i = str.length-1; i >= 0; i--){
          if('ֽ֑֖֛֢֣֤֥֦֧֪֚֭֮֒֓֔֕֗֘֙֜֝֞֟֠֡֨֩֫֬֯׀'.includes(str[i])){
              str = str.slice(0,i).concat(str.slice(i+1,str.length))
          }
      }
      return str;
    }

    removeNikud(str){
      for (let i = str.length-1; i >= 0; i--){
          if('ְֱֲֳִֵֶַָֹֺֻּֽ־ׇֿׁׂׅׄ'.includes(str[i])){
              str = str.slice(0,i).concat(str.slice(i+1,str.length))
          }
      }
      return str;
    }

    removeLineBreaks(text){
      var retVal = text
      while(retVal.includes("\n")){
        retVal = retVal.replace("\n", " ")
      }
      return retVal;
    }

    removeWhiteSpace(text){
      while(text.includes("  ")){
        text = text.replace("  ", " ")
      }
      return text;
    }

    
    setSourceCells(){
      const isSplitView = this.props.diffData.isSplitView
      const isIndependentScroll = this.props.diffData.isIndependentScroll
      var plusMinusCells = document.getElementsByClassName("css-17vezug-marker");
      var currSourceIndex = 0;
      var linesAreOutOfSync = false; // using this to detect which text is on even/odd lines when split-view is off
      var finishedText1 = false; // using these to check if passed end of text when split-view is off
      var finishedText2 = false;
      if(!isIndependentScroll || !isSplitView){
        for(let i = 0; i < plusMinusCells.length; i++){
          var currCell = plusMinusCells[i];
  
          var isText1 = linesAreOutOfSync ? i%2 === 1 : i%2 === 0
          var currSourceList = isText1 ? this.props.diffData.sourceList1 : this.props.diffData.sourceList2;
          //check if passed end of text when split-view is off
          if(!isSplitView){
            if(!this.props.diffData.sourceList1[Math.floor(currSourceIndex/2)]){
              currSourceList = this.props.diffData.sourceList2;
              finishedText1 = true;
            }
            if(!this.props.diffData.sourceList2[Math.floor(currSourceIndex/2)]){
              currSourceList = this.props.diffData.sourceList1;
              finishedText2 = true;
            }
          }
  
          var currSource = currSourceList[Math.floor(currSourceIndex/2)] || "";
  
          var cellClasses = currCell.className;
          var cellClassesArr = cellClasses.split(" ");
          if(cellClassesArr.length === 1 && !isSplitView){ // is same text on both sides
            currSourceIndex++
            currSource += "/" + this.props.diffData.sourceList2[Math.floor(currSourceIndex/2)] || "";
            linesAreOutOfSync = !linesAreOutOfSync;
          }
          var sourceSpan = document.createElement("span");
          sourceSpan.innerHTML = currSource;
          currCell.innerHTML = "";
          currCell.appendChild(sourceSpan);
          currCell.className += " css-1ks9aww-gutter diff-viewer-location-cell";  
  
          currSourceIndex++;
          if(finishedText1 || finishedText2){
            currSourceIndex++
          }
        }
      } else {
        for(let i = 0; i < plusMinusCells.length/2; i++){
          var currCell = plusMinusCells[i];
          if(i%2 === 1 && plusMinusCells[plusMinusCells.length/2 + i]){
            var currCell = plusMinusCells[plusMinusCells.length/2 + i];
          }
  
          var isText1 = linesAreOutOfSync ? i%2 === 1 : i%2 === 0
          var currSourceList = isText1 ? this.props.diffData.sourceList1 : this.props.diffData.sourceList2;
          //check if passed end of text when split-view is off
          if(!isSplitView){
            if(!this.props.diffData.sourceList1[Math.floor(currSourceIndex/2)]){
              currSourceList = this.props.diffData.sourceList2;
              finishedText1 = true;
            }
            if(!this.props.diffData.sourceList2[Math.floor(currSourceIndex/2)]){
              currSourceList = this.props.diffData.sourceList1;
              finishedText2 = true;
            }
          }
  
          var currSource = currSourceList[Math.floor(currSourceIndex/2)] || "";
  
          var cellClasses = currCell.className;
          var cellClassesArr = cellClasses.split(" ");
          if(cellClassesArr.length === 1 && !isSplitView){ // is same text on both sides
            currSourceIndex++
            currSource += "/" + this.props.diffData.sourceList2[Math.floor(currSourceIndex/2)] || "";
            linesAreOutOfSync = !linesAreOutOfSync;
          }
          var sourceSpan = document.createElement("span");
          sourceSpan.innerHTML = currSource;
          currCell.innerHTML = "";
          currCell.appendChild(sourceSpan);
          currCell.className += " css-1ks9aww-gutter diff-viewer-location-cell";
  
          currSourceIndex++;
          if(finishedText1 || finishedText2){
            currSourceIndex++
          }
        }
      }

    }

    formatEmptyCells(){
      if(this.props.diffData.isSplitView){
        var emptyCells = document.getElementsByClassName("css-10regm7-empty-line");
        for(let i = 0; i < emptyCells.length; i++){
          let currCell = emptyCells[i];
          if(!currCell.className.includes("empty-diff-cell")){
            currCell.className += " empty-diff-cell"
          }
        }
        var emptyGutterCells = document.getElementsByClassName("css-1klnsbn-empty-gutter");
        for(let i = 0; i < emptyGutterCells.length; i++){
          let currCell = emptyGutterCells[i];
          if(!currCell.className.includes("empty-diff-cell")){
            currCell.className += " empty-diff-cell"
          }
        }
      }
    }

    getHeaderRow(){
      const isSplitView = this.props.diffData.isSplitView;
      const isIndependentScroll = this.props.diffData.isIndependentScroll;
      var textBlock1 = this.props.diffData.textBlock1;
      var textBlock2 = this.props.diffData.textBlock2;
      if(!isIndependentScroll){
        var table = document.getElementsByClassName("css-1n5o7vh-diff-container")[0];
        var row;
        if(document.getElementsByClassName("diff-viewer-header-row").length === 0){
          var header = table.createTHead();
          row = header.insertRow(0);    
          row.className = "diff-viewer-header-row"
        } else {
          row = document.getElementsByClassName("diff-viewer-header-row")[0]
        }
        row.innerHTML = "";
        
        if(isSplitView){
          if(textBlock1.length > 0 && textBlock2.length > 0){
            var headerCell1 = document.createElement("TH");
            var headerCell2 = document.createElement("TH");
            var headerCell3 = document.createElement("TH");
            var headerCell4 = document.createElement("TH");
            var headerCell5 = document.createElement("TH");
            var headerCell6 = document.createElement("TH");
  
            headerCell1.innerHTML = "#";
            headerCell2.innerHTML = "פסוק";
            headerCell3.innerHTML = "טקסט";
            headerCell4.innerHTML = "#";
            headerCell5.innerHTML = "פסוק";
            headerCell6.innerHTML = "טקסט";
  
            headerCell1.className = "diff-table-header-cell";
            headerCell2.className = "diff-table-header-cell";
            headerCell3.className = "diff-table-header-cell";
            headerCell4.className = "diff-table-header-cell";
            headerCell5.className = "diff-table-header-cell";
            headerCell6.className = "diff-table-header-cell";
  
            row.appendChild(headerCell1);
            row.appendChild(headerCell2);
            row.appendChild(headerCell3);
            row.appendChild(headerCell4);
            row.appendChild(headerCell5);
            row.appendChild(headerCell6);
  
          } else if(textBlock1.length === 0 && textBlock2.length > 0){
            var headerCell1 = document.createElement("TH");
            var headerCell4 = document.createElement("TH");
            var headerCell5 = document.createElement("TH");
            var headerCell6 = document.createElement("TH");
  
            headerCell4.innerHTML = "#";
            headerCell5.innerHTML = "פסוק";
            headerCell6.innerHTML = "טקסט";
  
            headerCell1.className = "empty-diff-cell";
            headerCell1.colSpan = 3;
            headerCell4.className = "diff-table-header-cell";
            headerCell5.className = "diff-table-header-cell";
            headerCell6.className = "diff-table-header-cell";
  
            row.appendChild(headerCell1);
            row.appendChild(headerCell4);
            row.appendChild(headerCell5);
            row.appendChild(headerCell6);
  
          } else if(textBlock2.length === 0 && textBlock1.length > 0){
            var headerCell1 = document.createElement("TH");
            var headerCell2 = document.createElement("TH");
            var headerCell3 = document.createElement("TH");
            var headerCell4 = document.createElement("TH");
  
            headerCell1.innerHTML = "#";
            headerCell2.innerHTML = "פסוק";
            headerCell3.innerHTML = "טקסט";
  
            headerCell1.className = "diff-table-header-cell"
            headerCell2.className = "diff-table-header-cell"
            headerCell3.className = "diff-table-header-cell"
            headerCell4.className = "empty-diff-cell"
            headerCell4.colSpan = 3;
  
            row.appendChild(headerCell1);
            row.appendChild(headerCell2);
            row.appendChild(headerCell3);
            row.appendChild(headerCell4);
  
          }
        } else if(textBlock2.length > 0 || textBlock1.length > 0) {
          var headerCell1 = document.createElement("TH");
          var headerCell2 = document.createElement("TH");
          var headerCell3 = document.createElement("TH");
  
          headerCell1.innerHTML = "#";
          headerCell2.innerHTML = "פסוק";
          headerCell3.innerHTML = "טקסט";
  
          headerCell1.className = "diff-table-header-cell";
          headerCell1.colSpan = 2;
          headerCell2.className = "diff-table-header-cell";
          headerCell3.className = "diff-table-header-cell";
  
          row.appendChild(headerCell1);
          row.appendChild(headerCell2);
          row.appendChild(headerCell3);
        }
      } else {

        if(!isSplitView && (textBlock1.length > 0 || textBlock2.length > 0)){ // split view and has text
          var table = document.getElementsByClassName("css-1n5o7vh-diff-container")[0];
          var row;
          if(document.getElementsByClassName("diff-viewer-header-row").length === 0){
            var header = table.createTHead();
            row = header.insertRow(0);    
            row.className = "diff-viewer-header-row"
          } else {
            row = document.getElementsByClassName("diff-viewer-header-row")[0]
          }
          row.innerHTML = "";

          var headerCell1 = document.createElement("TH");
          var headerCell2 = document.createElement("TH");
          var headerCell3 = document.createElement("TH");
  
          headerCell1.innerHTML = "#";
          headerCell2.innerHTML = "פסוק";
          headerCell3.innerHTML = "טקסט";
  
          headerCell1.className = "diff-table-header-cell";
          headerCell1.colSpan = 2;
          headerCell2.className = "diff-table-header-cell";
          headerCell3.className = "diff-table-header-cell";
  
          row.appendChild(headerCell1);
          row.appendChild(headerCell2);
          row.appendChild(headerCell3);
        } else { // is independent scroll
          var rightDiff = document.getElementsByClassName("css-1n5o7vh-diff-container")[0];
          var leftDiff = document.getElementsByClassName("css-1n5o7vh-diff-container")[1];

          var rightRow;
          if(document.getElementsByClassName("diff-viewer-header-row-right").length === 0){
            var rightHeader = rightDiff.createTHead();
            rightRow = rightHeader.insertRow(0);    
            rightRow.className = "diff-viewer-header-row-right"
          } else {
            rightRow = document.getElementsByClassName("diff-viewer-header-row-right")[0]
          }
          rightRow.innerHTML = "";

          var leftRow;
          if(document.getElementsByClassName("diff-viewer-header-row-left").length === 0){
            var leftHeader = leftDiff.createTHead();
            leftRow = leftHeader.insertRow(0);    
            leftRow.className = "diff-viewer-header-row-left"
          } else {
            leftRow = document.getElementsByClassName("diff-viewer-header-row-left")[0]
          }
          leftRow.innerHTML = "";

          if(textBlock1.length > 0 && textBlock2.length > 0){
            var headerCell1 = document.createElement("TH");
            var headerCell2 = document.createElement("TH");
            var headerCell3 = document.createElement("TH");
            var headerCell4 = document.createElement("TH");
            var headerCell5 = document.createElement("TH");
            var headerCell6 = document.createElement("TH");

            headerCell1.innerHTML = "#";
            headerCell2.innerHTML = "פסוק";
            headerCell3.innerHTML = "טקסט";
            headerCell4.innerHTML = "#";
            headerCell5.innerHTML = "פסוק";
            headerCell6.innerHTML = "טקסט";

            headerCell1.className = "diff-table-header-cell";
            headerCell2.className = "diff-table-header-cell";
            headerCell3.className = "diff-table-header-cell";
            headerCell4.className = "diff-table-header-cell";
            headerCell5.className = "diff-table-header-cell";
            headerCell6.className = "diff-table-header-cell";
  
            rightRow.appendChild(headerCell1);
            rightRow.appendChild(headerCell2);
            rightRow.appendChild(headerCell3);
            leftRow.appendChild(headerCell4);
            leftRow.appendChild(headerCell5);
            leftRow.appendChild(headerCell6);
          } else if (textBlock1.length > 0 && textBlock2.length === 0) {
            var headerCell1 = document.createElement("TH");
            var headerCell2 = document.createElement("TH");
            var headerCell3 = document.createElement("TH");

            headerCell1.innerHTML = "#";
            headerCell2.innerHTML = "פסוק";
            headerCell3.innerHTML = "טקסט";

            headerCell1.className = "diff-table-header-cell";
            headerCell2.className = "diff-table-header-cell";
            headerCell3.className = "diff-table-header-cell";

            rightRow.appendChild(headerCell1);
            rightRow.appendChild(headerCell2);
            rightRow.appendChild(headerCell3);
          } else if (textBlock1.length === 0 && textBlock2.length > 0) {
            var headerCell4 = document.createElement("TH");
            var headerCell5 = document.createElement("TH");
            var headerCell6 = document.createElement("TH");

            headerCell4.innerHTML = "#";
            headerCell5.innerHTML = "פסוק";
            headerCell6.innerHTML = "טקסט";

            headerCell4.className = "diff-table-header-cell";
            headerCell5.className = "diff-table-header-cell";
            headerCell6.className = "diff-table-header-cell";

            leftRow.appendChild(headerCell4);
            leftRow.appendChild(headerCell5);
            leftRow.appendChild(headerCell6);
          }
        }

      }

    }

    formatIndependentScrollTable(){
      if(this.props.diffData.isSplitView && this.props.diffData.isIndependentScroll){
        var rightDiff = document.getElementsByClassName("css-1n5o7vh-diff-container")[0];
        var leftDiff = document.getElementsByClassName("css-1n5o7vh-diff-container")[1];

        var rightBody = rightDiff.children[1]; // WILL NEED TO CHANGE THIS TO [1] AFTER ADDING HEADER
        var rightRows = rightBody.children;
        for(let i =0; i < rightRows.length; i++){
          var currRow = rightRows[i];
          currRow.children[3].style.display = "none"
          currRow.children[4].style.display = "none"
          currRow.children[5].style.display = "none"
        }
        var leftBody = leftDiff.children[1]; // WILL NEED TO CHANGE THIS TO [1] AFTER ADDING HEADER
        var leftRows = leftBody.children;
        for(let i =0; i < leftRows.length; i++){
          var currRow = leftRows[i];
          currRow.children[0].style.display = "none"
          currRow.children[1].style.display = "none"
          currRow.children[2].style.display = "none"
        }
      }
    }

    handleDoubleClick(e){
      var parentCell = e.target;
      while(parentCell.nodeName !== "TD" && parentCell.nodeName !== "TH" ){
        parentCell = parentCell.parentNode
      }
      if(parentCell.nodeName === "TD"){ // ignoring clicks on header cells
        var columnIndex = parentCell.cellIndex;
        var sourceCell = parentCell;
        if([0, 3].includes(columnIndex)){
          sourceCell = parentCell.nextSibling;
        } else if ([5, 2].includes(columnIndex)) {
          sourceCell = parentCell.previousSibling;
        }
        var location = sourceCell.children[0].innerHTML;
        if(location.length > 0){ //ignoring clicks on empty cells
          this.props.toggleTanachViewer(true, location, null);
          if (window.getSelection) { // un-highlight the text that was clicked on
              window.getSelection().removeAllRanges();
          } else if (document.selection) {
              document.selection.empty();
          }
        }
      }
    }
    saveText1Data(data){
      this.setState({vals1:data})//saving to state to refresh the page when the data changes
    }
    saveText2Data(data){
      this.setState({vals2:data})//saving to state to refresh the page when the data changes
    }

  render() {
    const { classes } = this.props;
    const text1Data = this.props.text1Data;
    const text2Data = this.props.text2Data;
    const {isLoading, textBlock1, textBlock2, diffUnit, highlightCondition, removeWhiteSpace, removeLineBreaks, isSplitView, removeTaameiMikra,
      removeNikud, isIndependentScroll} = this.props.diffData;
    const {totalTerms1, totalTerms2, termTotal1Accurate, termTotal2Accurate} = this.props.wordCountData;
    const loadingText = this.props.translate("loading");

    var compareMethod = DiffMethod.CHARS;
    if(diffUnit === "word"){
      compareMethod = DiffMethod.WORDS;
    } else if (diffUnit === "line") {
      compareMethod = DiffMethod.LINES;
    }

    const fakeThis = this;
    var isHebrew = localStorage.getItem('language') === 'he';

    var viewerTableHeightNum = parseInt(this.props.viewerHeight.substring(0, this.props.viewerHeight.length - 2)) - 159;
    if(this.props.openResults.length > 0){
        viewerTableHeightNum -= 12;
    }
    const viewerTableHeight = `${viewerTableHeightNum}px`

    var directionStyling = {direction: 'ltr', textAlign: 'left'};
    var langDirection = 'ltr'
    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
        directionStyling = {direction: 'rtl', textAlign: 'right'};
        langDirection = 'rtl';
    }

    var totalTerms1Paper = <Paper elevation={1} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0'}}>
           <Typography variant='body1'>{this.props.translate("totalTerms")}: <b>{totalTerms1}</b></Typography>
       </Paper>
    if(!termTotal1Accurate){
        totalTerms1Paper = <Paper elevation={0} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0', backgroundColor: '#d7d7d7' }}>
           <Typography variant='body1' style={{color: "#9f9f9f"}} >{this.props.translate("totalTerms")}: <b>{totalTerms2}</b></Typography>
       </Paper>
    }

    var totalTerms2Paper = <Paper elevation={1} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0'}}>
           <Typography variant='body1'>{this.props.translate("totalTerms")}: <b>{totalTerms2}</b></Typography>
       </Paper>
    if(!termTotal2Accurate){
        totalTerms2Paper = <Paper elevation={0} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0', backgroundColor: '#d7d7d7' }}>
           <Typography variant='body1' style={{color: "#9f9f9f"}} >{this.props.translate("totalTerms")}: <b>{totalTerms2}</b></Typography>
       </Paper>
    }
    const refreshDisabled = this.props.text1Data.length<2 || this.props.text2Data.length<2 || 
            (this.props.text1Data[0].type && this.props.text1Data[0].val4===0) || 
            (this.props.text2Data[0].type && this.props.text2Data[0].val4===0) || 
            ((!this.props.text1Data[0].type) && (this.props.text1Data[0].val3===0 || this.props.text1Data[0].val6===0)) || 
            ((!this.props.text2Data[0].type) && (this.props.text2Data[0].val3===0 || this.props.text2Data[0].val6===0))

    return (<span helpTopic="diff">
          <div className={classes.toggleContainer} style={{direction: langDirection, paddingTop: "0px"}}>
            <Paper elevation={0} style={{padding: '3px', display: 'block'}}>
              <DiffViewerDisplaySettings
                extSetDiffUnit={(e, val) => {this.setDiffUnit(e, val)}} diffUnit={diffUnit}
                extSetHighlightCondition={(e, val) => {this.setHighlightCondition(e, val)}} highlightCondition={highlightCondition}
                extToggleSplitView={(e) => {this.toggleSplitView(e)}} isSplitView={isSplitView}
                extToggleRemoveWhiteSpace={(e) => {this.toggleRemoveWhiteSpace(e)}} removeWhiteSpace={removeWhiteSpace}
                extToggleRemoveLineBreaks={(e) => {this.toggleRemoveLineBreaks(e)}} removeLineBreaks={removeLineBreaks}
                extToggleTaameiMikra={(e) => {this.toggleTaameiMikra(e)}} removeTaameiMikra={removeTaameiMikra}
                extToggleNikud={(e) => {this.toggleNikud(e)}} removeNikud={removeNikud}
                extToggleIndependentScroll={(e) => {this.toggleIndependentScroll(e)}} isIndependentScroll={isIndependentScroll}
              />
              <Button variant="contained" disabled={refreshDisabled} color="primary" className="parsha-viewer-control-button" type="submit" style={{margin: '0 5px'}} onClick={this.refresh}><Translate id="refresh" /></Button>
            </Paper>
          </div>
          <TextComparatorSectionSelector 
            isLoadingResults={isLoading}
            totalTerms1={totalTerms1}
            termTotal1Accurate={termTotal1Accurate}
            totalTerms2={totalTerms2}
            termTotal2Accurate={termTotal2Accurate}
            text1Data={this.props.text1Data}
            text2Data={this.props.text2Data}
            text1GridReady={this.text1GridReady}
            addText1Row={this.addText1Row}
            extAddText1Row={this.extAddText1Row}
            deleteText1Row={this.deleteText1Row}
            extDeleteText1Row={this.extDeleteText1Row}
            extModifyText1Row={this.extModifyText1Row}
            modifyText1Row={this.modifyText1Row}
            text2GridReady={this.text2GridReady}
            addText2Row={this.addText2Row}
            extAddText2Row={this.extAddText2Row}
            deleteText2Row={this.deleteText2Row}
            extDeleteText2Row={this.extDeleteText2Row}
            extModifyText2Row={this.extModifyText2Row}
            modifyText2Row={this.modifyText2Row}
            getRenderVals={this.getRenderVals}
            isFilingSelector={this.props.isFillingSelector}
            saveText1Data={this.saveText1Data}
            saveText2Data={this.saveText2Data}
          />

        <div
          style={{
            height: viewerTableHeight
          }}
          className="diff-viewer-loading-parent"
        >
          <LoadingOverlay
            active={isLoading}
            spinner
            text={loadingText}
            style={{
              height: '100%'
            }}
          >
            <MenuProvider className="context-menu" id='diff-viewer-context-menu'
              style={{
                maxHeight: viewerTableHeight,
                width: '1520px',
                maxWidth: 'calc(100vw - 400px)',
                minWidth: '968px',
              }}
            >
              <div
                style={{
                  textAlign: 'left',
                  maxHeight: viewerTableHeight,
                  overflowY: `${isIndependentScroll ? 'hidden' : 'auto'}`,
                  overflowX: 'hidden',
                  '&*': {
                    marginBottom: '10px'
                  } 
                }}
                onContextMenu={(e) => {this.onCellContextMenu(e)}}
                onDoubleClick={(e) => {this.handleDoubleClick(e)}}
              >
                {(!isIndependentScroll || (!isSplitView && (textBlock1.length > 0 || textBlock2.length > 0))) &&
                  <ReactDiffViewer
                    oldValue={this.formatTextBlock(textBlock1)}
                    newValue={this.formatTextBlock(textBlock2)}
                    splitView={isSplitView}
                    compareMethod={compareMethod}
                    showDiffOnly={false}
                    className="diff"
                  />
                }
                {(isIndependentScroll && isSplitView) && <>
                  <table className="independent-scroll-diff-parent-table">
                    <tbody>
                      <tr style={{width: "100%"}}>
                        <td valign="top" style={{width: "50%"}}>
                        <div
                          style={{
                            textAlign: 'left',
                            maxHeight: viewerTableHeight,
                            overflowY: `auto`,
                            overflowX: 'hidden',
                            direction: "ltr",
                            '&*': {
                              marginBottom: '10px'
                            } 
                          }}
                        >
                          <ReactDiffViewer
                            oldValue={this.formatTextBlock(textBlock1)}
                            newValue={this.formatTextBlock(textBlock2)}
                            splitView={isSplitView}
                            compareMethod={compareMethod}
                            showDiffOnly={false}
                            className="diff independent-scroll-diff-right"
                          />
                        </div>
                        </td>
                      <td valign="top" style={{width: "50%"}}>
                        <div
                          style={{
                            textAlign: 'left',
                            maxHeight: viewerTableHeight,
                            overflowY: `auto`,
                            overflowX: 'hidden',
                            direction: "ltr",
                            '&*': {
                              marginBottom: '10px'
                            } 
                          }}
                        >
                          <ReactDiffViewer
                            oldValue={this.formatTextBlock(textBlock1)}
                            newValue={this.formatTextBlock(textBlock2)}
                            splitView={isSplitView}
                            compareMethod={compareMethod}
                            showDiffOnly={false}
                            className="diff independent-scroll-diff-left"
                          />
                        </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>}

              </div>
          </MenuProvider>
          <ContextMenu menuId='diff-viewer-context-menu' menuType='diff' className='special-context-menu' resultsApi={this.resultsApi}
                          textToCopy={fakeThis.state.textToCopy} extCopySelectedToSearchbar={fakeThis.props.extCopySelectedToSearchbar}
                          extSearchWithSelected={fakeThis.props.extSearchWithSelected} extToggleUseKinuim={fakeThis.toggleUseKinuim}
                          useKinuim={fakeThis.state.useKinuim} extToggleIncludeMekorot={fakeThis.toggleIncludeMekorot} includeMekorot={fakeThis.state.includeMekorot}
                          extToggleSearchAsQuote={fakeThis.toggleSearchAsQuote} searchAsQuote={fakeThis.state.searchAsQuote}
                          extOpenTaamParser={fakeThis.openTaamParser} currentFreq={this.state.currentFreq} extSetTextSource={this.props.extSetTextSource}
                          makor={this.state.contextMenuMakor} text={this.state.contextMenuText} isAPasuk={this.state.contextMenuIsAPasuk}
                          toggleTanachViewer={((boolean, pasukId, resultsApi) => {fakeThis.props.toggleTanachViewer(boolean, pasukId, resultsApi)})}
                          extOpenLexiconViewer={this.props.extOpenLexiconViewer} morphInflections={this.props.morphInflections} hasRowData={fakeThis.props.diffData.textBlock1.length > 0}
                          diffData={this.props.diffData}/>
          </LoadingOverlay>
        </div>

        {/* <Dialog
          open={this.state.taamParserOpen}
          onClose={this.closeTaamParser}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
            <TaamParser data={this.state.taamParseData} open={this.state.taamParserOpen} closeTaamParser={this.closeTaamParser}
                    focusedCellRowLocation={null} extOpenTaamParser={this.openTaamParser}
                    pasukId={this.state.taamParserPasukId} pasukLocation={this.state.taamParserLocation}
                    resultsApi={this.frequencyApi} />
        </Dialog> */}
        </span>
    );
  }
}

DiffViewer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withLocalize(DiffViewer));
