import React, { Component } from 'react';

class TopBar_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <div className="WordSection1">
          <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>סרגל
                כותר וניווט</span></b></p>
          <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span dir="LTR">&nbsp;</span></b></p>
          <p className="MsoNormal" align="center" dir="RTL" style={{textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={342} height={54} id="Picture 49" src="helpDialogImages/topNavBar.png" /></span></p>
          <p className="MsoNormal" align="center" dir="RTL" style={{textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><b><span dir="LTR">&nbsp;</span></b></p>
          <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><i><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></i><span dir="RTL" /><img src="helpDialogImages/navigationBarDropdown.png" align="left" hspace={12} /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תפריט
                ראשי</span></b> <span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
              - ניתן לגשת
              לתפריט הראשי
              של המערכת
              מהסמל עם שלושה
              סרגלים
              אופקיים בחלק
              העליון של
              המסך. לחיצה
              על זה מאפשרת
              לנווט <b>לחמש
                אפשרויות
                שונות</b>. <i>שלושת
                הראשונים הם
                מסכי תוכן
                שייפתחו
                ישירות מתחת
                לאזור החיפוש
                והתוצאות:</i></span></p>
          <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פרשיות
                התנ"ך</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - כאמור
              לעיל, זהו דף
              הפתיחה
              המוגדר כברירת
              מחדל עבור
              המערכת. הוא
              מציג רשימה של
              פרשיות
              פתוחות
              וסתומות
              בתנ"ך עם שלל
              סטטיסטיקות סיכום
              על כל פרשה.</span></p>
          <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שכיחות
                בתנ"ך</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - מציג
              דיווחים
              נפרדים על
              ביטויים
              המופיעים רק
              פעם אחת בתנ"ך,
              פעמיים בתנ"ך
              ושלוש פעמים
              או יותר בתנ"ך.</span></p>
          <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>משווה
                טקסטים</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - מציע
              דרכים מרובות
              להשוות בין
              שתי קבוצות
              שונות של
              פסוקים על מנת
              למצוא קווי
              דמיון קשים
              לאיתור של
              חלקים שונים
              ומבדלים בין
              חלקים דומים
              מאוד.</span></p>
          <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שלש אחרים ייפתחו כחלונות קופצים הניתנים לגרירה ושינוי גודל:</span></i></p>
          <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>דפדפן
                התנ"ך</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - מציג
              תצוגות
              מקבילות
              מרובות
              פאנלים על
              קטעי תנ"ך יחד
              עם תרגום, כתב
              סת"ם וכתב
              עברי. יכול גם
              להפעיל חיפוש
              וניתוח מכל
              מקום בתנ"ך.</span></p>
          <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מנתח
                טעמים</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - ניתוח
              וניתוק
              ויזואלי של
              התחביר של
              פסוק על פי
              כללי טעמי
              המקרא.</span></p>
          <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ענני אבייקטים</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
מציג ענני תגיות מבוססים על תיבות, שורשים, גמטריות ועוד בעבור קטעים מתנ"ך הנבחרים ע"י המשתמש.
</span></p>
          <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
          האופציה האחרונה, <b>שירותי פרימיום</b>, מפרטת שירותים בתשלום מותאם אישית שניתן לבקש באמצעות דוא"ל.
          </span></i></p>

          <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
          <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כפתור
                עזרה</span></b> <span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
              - משמאל לסמל
              התפריט הראשי
              מופיע כפתור
              העזרה (<b>? </b>
              במעגל). לחיצה
              על זה תגרום
              לפתיחת חלון
              מוקפץ המכיל
              הוראות
              מפורטות לגבי:
              כיצד להשתמש
              בפונקציונליה
              האחרונה
              שהגישה אליה
              בין אם זו
              עזרה לחיפוש או
              עזרה לדף
              תוכן. דיאלוג
              העזרה יאפשר
              גם לנווט
              להוראות על
              אזורים אחרים
              במערכת.</span></p>
          <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
          <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כפתור
                אודות</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - משמאל
              ללחצן עזרה מופיע
              כפתור אודות (</span><b><span dir="LTR">i</span></b><span dir="RTL" /><b><span style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> </span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>במעגל).
              לחיצה על זה
              תגרום לפתיחת
              חלון קופץ המכיל
              תצוגה
              מרובת-כרטיסיות
              של מידע כללי
              על המערכת כמו
              משימה,
              היסטוריה,
              מידע על
              גרסאות, בעיות
              ידועות /
              באגים, פרטי
              קשר וכו'.</span></p>
          <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כפתור
                כניסה</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - משמאל
              לכפתור אודות.
              תאפשר למשתמש
              להתחבר למערכת,
              לרשום את עצמם
              כמשתמש,
              להתנתק
              מהמערכת. <i>פרטים
                נוספים בהמשך</i>.</span></p>
          <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בורר
                שפה</span> </b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
              - בורר יופיע
              משמאל ללחצן
              הכניסה /
              יציאה. לחיצה
              עליו תעבור
              בין שפות ממשק
              משתמש שונות
              (כיום עברית
              ואנגלית). אנא
              זכור כי בעוד
              שניתן לשנות
              את שפת הממשק,
              תוכן התנ"ך
              עצמו יישאר
              תמיד בעברית
              המקורית.</span></p>
          <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
          <p className="MsoNormal" align="center" dir="RTL" style={{marginRight: '.25in', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={97} height={50} id="Picture 12" src="helpDialogImages/image008.jpg" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <img width={90} height={50} id="Picture 13" src="helpDialogImages/image009.jpg" /></span></p>
          <p className="MsoNormal">&nbsp;</p>
        </div>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b>Title and navigation bar</b></p>
        <p className="MsoNormal" align="center" style={{textAlign: 'center'}}><b>&nbsp;</b></p>
        <p className="MsoNormal" align="center" style={{textAlign: 'center'}}><img width={342} height={54} id="Picture 49" src="helpDialogImages/topNavBar.png" /></p>
        <p className="MsoNormal" align="center" style={{textAlign: 'center'}}><b>&nbsp;</b></p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><img src="helpDialogImages/navigationBarDropdown.png" align="right" hspace={12} /><b>Main
            menu</b> – the main system menu can be accessed from the icon with three
          horizontal bars on the right-uppermost section of the screen.&nbsp; Clicking on this
          enables one to navigate to <b>five different options</b>.&nbsp; <i>The first three
            are content screens which will open up directly below the search and results
            area:</i></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '1.0in', textIndent: '-.25in'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשיות
              התנ"ך</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>- As stated above, this is the default
          opening page for the system.&nbsp; It displays a list of <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשיות
            פתוחות
            וסתומות</span><span dir="LTR" /><span dir="LTR" /> in Tanach with a whole bunch of summary statistics about each
          parsha.</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '1.0in', textIndent: '-.25in'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שכיחות בתנ"ך</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>- Displays separate
          reports about terms that occur only once in Tanach, twice in Tanach and three
          or more times in Tanach.</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '1.0in', textIndent: '-.25in'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>משווה
              טקסטים</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>- Offers multiple ways to compare two
          different sets of pesukim in order to find hard to detect similarities of
          disparate sections and discrepancies within very similar sections.</p>
        <p className="MsoListParagraphCxSpMiddle"><i>Three others will open up as popup windows that can be dragged and resized:</i></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '1.0in', textIndent: '-.25in'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>דפדפן
              התנ"ך</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>– Presents multi-paneled, parallel views of sections
          of Tanach along with Targum, <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כתב
            סת"ם</span><span dir="LTR" /> <span dir="LTR" />
          layout, <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כתב
            עברי</span><span dir="LTR" /><span dir="LTR" />.&nbsp;
          Also can launch search and analysis on the fly from anywhere in Tanach.</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '1.0in', textIndent: '-.25in'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מנתח טעמים</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>- Visually
          analyze and breakdown the syntax of a pasuk according to the rules of <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעמי
            המקרא</span><span dir="LTR" /><span dir="LTR" />.</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '1.0in', textIndent: '-.25in'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ענני אבייקטים</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>– Object cloud
                viewer which displays tag clouds based upon words, shorashim, gematria and more
                for areas of Tanach which the user selects.</p>
        <p className="MsoListParagraphCxSpMiddle"><i>The final option, <b>שירותי פרימיום</b>, lists custom paid services that can be requested via email.</i></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '1.0in'}}>&nbsp;</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>Help button</b> – to the left of the Main
          Menu icon appears the Help button (<b><span style={{fontSize: '14.0pt'}}>? </span></b>
          in a circle). Clicking this will cause a popup window to emerge containing
          detailed instructions re: how to use the most recently accessed functionality
          be it help for a search or help for a content page.&nbsp; The Help dialogue popup
          will also let one navigate to instructions on other areas of the system.</p>
        <p className="MsoListParagraphCxSpMiddle">&nbsp;</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>About button</b> – to the left of the Help
          button appears the About button (<b><span style={{fontSize: '14.0pt'}}>i </span></b>
          in a circle). Clicking this will cause a popup window to emerge containing a
          multi-tabbed view of general system information such as Mission, History,
          Version info, Known issues/bugs, Contact info, etc.</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>Login button</b> – to the left of the
          About button.&nbsp; Will enable one to login to the system, register themselves as a
          user, logout of the system.&nbsp; <i>More details below.</i></p>
        <p className="MsoListParagraphCxSpMiddle">&nbsp;</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>Language selector</b> – a selector will
          appear to the left of the login/logout button.&nbsp; Clicking on it will toggle
          between different user interface languages (currently Hebrew and English).&nbsp;
          Please keep in mind that while the interface language can be changed, the
          actual Tanach content will always remain in its original Hebrew.</p>
        <p className="MsoNormal" align="center" style={{marginLeft: '.25in', textAlign: 'center'}}><img width={97} height={50} id="Picture 12" src="helpDialogImages/image008.jpg" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <img width={90} height={50} id="Picture 13" src="helpDialogImages/image009.jpg" /></p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default TopBar_Instructions;