import React, { Component } from 'react';

class SearchResults_Examples extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>
      <p className="MsoNormal">
      הרמז צץ של מילה מציג נתונים סטטיסטיים שונים של המילה הספציפית שמרחפת עליה. כאן העכבר מרחף מעל המילה <b>ותקח</b>.
      </p>
      <img width={680} height={180} id="WSTEx1" src="helpDialogImages/wordStatTooltipEx.jpg" />
      <p className="MsoNormal">
רמזי צץ סטטיסטיים של פסוקים מציגים את הסטטיסטיים של כל הפסוק שמרחף עליו. כל הסטטיסטיקות כאן לפי מילה הן ממוצעות. שימו לב שהגימטריה למילה שונה מהגימטריא של התיאורים של מילים סטטיסטיות, מה שגימטריא לכל המילה היא שהספציפית למילה זו והרמז צץ של הפסוק הוא ממוצע של כל הפסוק.
      </p>
      <img width={680} height={180} id="WSTEx2" src="helpDialogImages/pasukStatTooltipEx.jpg" />
      <p className="MsoNormal">&nbsp;</p>
      <p className="MsoNormal">העתק והדבק פסוקים נבחרים עם סטטיסטיקה:</p>
      <p className="MsoNormal">
כדי להעתיק את הסטטיסטיקה של פסוק בערכת התוצאות, לחץ לחיצה ימנית על הפסוק או הפסוקים הרצויים. בחר העתק ללוח. סמן את התיבה שאומרת כלול סטטיסטיקה ובחר באיזו רמת העתקה תרצה. אם ברצונך שהמקור ייכלל בהדבקה, בחר כלול מקורות. אם תרצה ששם ה' יופיע בתור האות ה, סמן השתמש בכינוים. הנתונים הסטטיסטיים יועתקו בפורמט המוגדר בתפריט הנפתח עמודות סטטיסטיקה. כאן, 'שורת כותרת' היא אפשרות ההדבקה וכל הנתונים הסטטיסטיים יועתקו.
      </p>
      <img width={300} height={170} id="CPEx1" src="helpDialogImages/copyPasteEx1.jpg" />
      <br/>לאחר מכן, כך זה ייראה כאשר תדביק אותו ב- Excel.
      <img width={700} height={80} id="CPEx2" src="helpDialogImages/copyPasteEx2.jpg" />
      </>
    } else if (langCode === "en") {
      retVal = <>
      <p className="MsoNormal">The Word Statistics Tooltip shows different stats of the specific word that is being hovered. Here the mouse is hovered over the word <b>ותקח</b>.</p>
      <img width={680} height={180} id="WSTEx1" src="helpDialogImages/wordStatTooltipEx.jpg" />
      <p className="MsoNormal">The Pasuk Statistics Tooltip shows the stats of the whole pasuk that is hovered. All of the Statistics here that are per word are an avarage. Notice that the 
      Gematria per Word is different from the Word Statistics tooltip gematria, because the per word is specific to that word and the pasuk tooltip is an avarage of the whole pasuk.</p>
      <img width={680} height={180} id="WSTEx2" src="helpDialogImages/pasukStatTooltipEx.jpg" />
      <p className="MsoNormal">&nbsp;</p>
      <p className="MsoNormal">Copy and Paste Selected Pesukim with Statistics:</p>
      <p className="MsoNormal">To copy the statistics of a pasuk in the results set, right-click on the desired Pasuk or Pesukim.
        Select Copy to clipboard. Check the box that says Include Statistics and choose what level of copying you would like. 
        If you want the source to be included in the paste, select Include Mekorot. If you would like the Name of Hashem to appear as the letter ה, check Use Kinuim.
        The statistics will be copied in the format that is defined in the Statistics Columns dropdown.
        Here, 'Header Row' is the paste option and all the statistics will be copied.
        </p>
      <img width={300} height={170} id="CPEx1" src="helpDialogImages/copyPasteEx1.jpg" />
      <br/>Then, this is what it will look like when you paste it into Excel.
      <img width={700} height={80} id="CPEx2" src="helpDialogImages/copyPasteEx2.jpg" />

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default SearchResults_Examples;