import React, { Component } from 'react';

class ObjectCloudViewer_Examples extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

  <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span style={{fontSize: '14.0pt'}}>שימוש במציג ענני אובייקטים</span></b></p>
  <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>&nbsp;</p>
  <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b>הנפשות</b></p>
  <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>
  -לחץ על בחר קטעים. לסוג, בחר
  <b> קריאת התורה</b>.
   למין מאורע, בחר
   <b> ד' פרשיות</b>.
  עבור שם הקטע, בחר
  <b> פרשת זכור</b>.
  סגור את החלון הקופץ ולחץ על כפתור ההפעלה בצד ימין למטה. ההנפשה תתחיל לפעול ותרענן כדי להראות כל אחת מה<b> ד' פרשיות</b>.
  אם תלחץ על הפעל מחדש בסוף, ההנפשה תמשיך לעבור בין כל ארבע האפשרויות עד שתלחץ על השהה.
  </p>
  <p className="MsoNormal"><img width={250} height={180} id="Ex1" src="helpDialogImages/objectCloudEx4.jpg" />
  חוזר...<img width={250} height={180} id="Ex1" src="helpDialogImages/objectCloudEx3.jpg" /></p>
  <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed', fontSize:"5px"}}>&nbsp;</p>
  <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>
אם אתה רוצה לראות כל ספר בתנ"ך, נקה את כל הבחירות שהיו לך קודם והחלק את המחוון כדי להצביע על ספרים. לאחר מכן לחץ על הפעל. אם תרצה ששם הספר יופיע מעל הענן, סמן את התיבה שאומרת הצג כותרת.
  </p>
  <p className="MsoNormal"><img width={250} height={180} id="Ex1" src="helpDialogImages/objectCloudEx1.jpg" />
  ממשיך...<img width={250} height={180} id="Ex1" src="helpDialogImages/objectCloudEx2.jpg" /></p>

      </>
    } else if (langCode === "en") {
      retVal = <>

  <p className="MsoNormal"><b><span style={{fontSize: '14.0pt'}}>Using The Object Cloud Viewer</span></b></p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal"><b>Animations</b></p>
  <p className="MsoNormal">-Click Select Sections. For the type, select<b> קריאת התורה</b>. For the event, select<b> ד' פרשיות</b>.
  For the name, select<b> פרשת זכור</b>. Close the popup, and click the play button on the bottom right. The animation will start to
  play and refresh to show each of the<b> ד' פרשיות</b>. If you click Replay at the End, the animation will continue to cycle through all
  four options until you click pause.</p>
  <p className="MsoNormal"><img width={250} height={180} id="Ex1" src="helpDialogImages/objectCloudEx4.jpg" />
  Replaying...<img width={250} height={180} id="Ex1" src="helpDialogImages/objectCloudEx3.jpg" /></p>
  <p className="MsoNormal"style={{fontSize:"5px"}}>&nbsp;</p>
  <p className="MsoNormal">-If you want to view each Sefer in Tanach, clear any Selections that you had before and slide the slider to point to 
  ספרים. Then click play. If you would like the name of the sefer to appear above the cloud, check the box that says Show Title.</p>
  <p className="MsoNormal"><img width={250} height={180} id="Ex1" src="helpDialogImages/objectCloudEx1.jpg" />
  Continuing...<img width={250} height={180} id="Ex1" src="helpDialogImages/objectCloudEx2.jpg" /></p>

      </>
    }
    return retVal;
  }

  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default ObjectCloudViewer_Examples;