import React, { Component } from 'react';
import maintLogo from './images/bhsMaintenance.jpg';
import './css/App.css';
import styles from './css/App.css'; //for customizing reactStrap styles
import { withLocalize, Translate } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import translations from "./json/translations.json";

class Maintenance extends Component {

  constructor(props) {
    super(props);

    this.state = {

    };

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true }
    });

    this.props.addTranslation(translations);
  }

  render() {
    const { hebRows, engRows } = this.props;
    return (        <div width="100%" height="100%" className="maint-header-he">
        <div>{hebRows[0].toString()}</div>
        <div>{hebRows[1].toString()}</div>
            <img src={maintLogo} align="center" className="maint-logo" alt="maintLogo" align="center"/>
        <div className="maint-header-en">{engRows[0].toString()}</div>
        <div className="maint-header-en">{engRows[1].toString()}</div>
        </div>
    );
  }

}

export default withLocalize(Maintenance);