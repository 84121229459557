import React, { Component } from 'react';

class HakarasHatov extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <table width="100%"><tbody><tr><td style={{padding: 20}}>
                <p /><h6><b>לאשתי וילדיי</b></h6><p />
                <ul>
                  <li>
                    שסבלתם השנים הרבות שהכנסתי לזה כולל לילות מאוחרים וסופי שבוע. שהצעתם עזרה בנקודות שונות לאורך הדרך.
                    מנחם, שהיה לך הסקרנות והתובנה לשאול את השאלה המקורית שהשיקה את הפרויקט.
                  </li>
                </ul>
                <p /><h6><b>להורי דוב ז"ל ומרים ע"ה</b></h6><p />
                <ul>
                  <li>
                    שהם גידלו אותי עם חינוך שהובילה אותי לקחת את המיזם הזה.
                  </li>
                </ul>
                <p /><h6><b>למתמחים והעובדים הראשונים שלי על עזרתכם במאמצי פיתוח להתחיל בנקודת זמן קריטית</b></h6><p />
                <ul>
                  <li>ארי אברמוביץ, יעקב לוונשטיין וישראל לוונטל</li>
                </ul>
                <p /><h6><b>לאנשים הבאים שהציעו זמן ו / או משאבים משמעותיים לעזור במאמץ</b></h6><p />
                <ul>
                  <li>ר' אבי קדיש, חוד החנית של פרויקט הוויקיטקסט ששימש כמקור העיקרי לטקסט של התנ"ך ותרגום במאגר המידע שלי, שבלעדיו פרויקט זה היה מתקשה להתקדם.</li>
                  <li>ר' משה זילברמן, שהציע את תוכן ספרו <b>עמלים בתורה</b> לשמש כמקור עתידי למידע דקדוקי במאגר המידע שלי.</li>
                  <li>ראסל הנדל, טוביה רוטברג, טל בנשחר</li>
                </ul>
                <p /><h6><b>ולרבים אחרים שהציעו עידוד ועזרה.</b></h6><p />
                <p><i>משה אסקוט</i></p>
              </td></tr>
          </tbody>
        </table>


      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <table width="100%">
          <tbody>
            <tr>
              <td style={{padding: 20}}>
                <p /><h6><b>To my wife and children</b></h6><p />
              <ul>
                <li>
                  For putting up with the many years I have put into this including late nights and weekends. For offering help at different points along the way. 
                  Menachem, for having the curiosity and insight to ask the original question that launched the project.
                </li>
              </ul>
              <p /><h6><b>To my parents דוב ז"ל ומרים ע"ה</b></h6><p />
              <ul>
                <li>For raising me with a chinuch that led me to taking on this project</li>
              </ul>
              <p /><h6><b>To my initial interns and employees for helping to jumpstart development efforts at a critical point in time</b></h6><p />
              <ul>
                <li>Ari Abramowitz, Yaakov Lowenstein and Yisrael Lowenthal</li>
              </ul>
              <p /><h6><b>To the following people who have offered significant time and/or resources to help with the effort</b></h6><p />
              <ul>
                <li>R. Avi Kadish, spearhead of the Wikitext project which has served as the main source of the text of the Tanach and Targum on my database, without which this project would have struggled to move forward</li>
                <li>R. Moshe Silberman, who offered the content of his Sefer Ameilim BaTorah to be used as a future source for grammatical information in my database</li>
                <li>Russell Hendel, Tuvia Rotberg, Tal Benschar</li>
              </ul>
              <p /><h6><b>And to to many others who have offered encouragement and help.</b></h6><p />
              <p><i>Moshe Escott</i></p>
            </td></tr>
          </tbody>
        </table>


      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default HakarasHatov;