import React, { Component } from 'react';

class LNT_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פרטים
              עבור
              הכרטיסייה
              נקודות
              וטעמים:</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> <i>(הגדרות
              אלו ייחודיות
              לכל אחד
              מביטויי
              החיפוש)</i></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR" style={{fontFamily: '"Arial",sans-serif', color: 'black'}}><img width={624} height={110} id="Picture 29" src="helpDialogImages/image018.png" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שלוש
            שורות של
            הגדרות
            מופיעות כאן:</span></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>נקודות
              לפי אותיות</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>: תיבת
            בחירה לכל
            אות/תו במונח
            החיפוש שנבחר.
            אם לא הוזנו
            מונחים ו/או
            לא נבחר מונח
            לעריכת ההגדרות
            שלו, אז לא
            יופיעו תיבות
            בשורה זו. ניתן
            לבחור נקודות
            אחת-לרבות כדי
            לאפשר להופיע
            עם האות
            המדוברת. משמעות
            הדבר היא כי
            כל אחד
            מהנבחרים
            יכול להופיע
            עם האות
            המדוברת. ניתן
            גם לבחור ב-</span><b><span dir="LTR" style={{color: 'red'}}>X</span></b><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
            כדי לציין
            שאין להוסיף
            נקודות לאות
            זה. ניתן גם
            לבחור ב-</span><span dir="LTR" /><b><span dir="LTR" style={{color: 'red'}}><span dir="LTR" />?</span></b><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
            כדי לציין שכל
            נקודה מותרת לאות
            זה.</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ניקוד
              כללי</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>:
            זה מאפשר הגדרת
            רצף של נקודות
            למונח ללא קשר
            לאיזה אות כל
            נקודה קשורה. כל
            תיבת בחירה
            היא רב-בחירה
            שיכולה לאפשר
            נקודות שונות
            בכל מיקום
            טקסט בודד. ניתן
            גם לבחור ב-</span><span dir="LTR" /><b><span lang="HE" dir="LTR" style={{color: 'red'}}><span dir="LTR" />
            </span></b><b><span dir="LTR" style={{color: 'red'}}>X</span></b><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
            כדי לציין כי לאות
            הבא אין
            נקודות. ניתן
            גם לבחור ב-</span><span dir="LTR" /><b><span lang="HE" dir="LTR" style={{color: 'red'}}><span dir="LTR" />
            </span></b><b><span dir="LTR" style={{color: 'red'}}>?</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כדי
            לציין שכל
            נקודה מותרת
            לאות יחידה. ניתן
            גם לבחור ב-</span><b><span lang="HE" style={{fontFamily: '"Times New Roman",serif', color: 'red'}}>*</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> כדי
            לציין שכל
            נקודות
            מותרות לרצף
            רב-אותיות.</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הטעמה</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>: זה
            מאפשר הגדרת
            רצף של טעמי
            מקרא לכל מונח
            ללא קשר לאיזה
            אות כל טעם
            קשור. כרגע
            מונחים בדרך
            כלל מייצגים
            מונח יחיד. בדרך
            כלל מופיע טעם
            יחיד למילה
            יחידה ומדי פעם
            יש מילה עם
            שני טעמים. בעתיד
            הקרוב יהיה
            מותר להשתמש
            במונחים
            מרובי מילים (<i>למשל,
              זרם של מילים
              עוקבות לחפש
              בשילוב עם מונחים
              אחרים</i>).</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>במקרים
            אלה, הגדרה זו
            תהיה חזקה
            יותר, בה ניתן לבקש
            רק לאפשר
            התאמה במונח
            זה אם זרם
            המילים מכיל
            תבנית טעמים. בינתיים,
            בדומה
            לנקודות, אפשר
            לעשות רב-בחירות
            כדי לאפשר
            היגיון ו/או </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ואפשר
            לבחור ב-</span><b><span dir="LTR" style={{fontFamily: '"Arial",sans-serif', color: 'red'}}>X</span></b><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
            או ב-</span><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif', color: 'red'}}>?</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
            או ב-</span><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif', color: 'red'}}>*</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
            כדי לציין ללא
            טעם</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>,
            תו טעם חופשי
            בודד או רצף
            תווים חופשיים
            של טעמים מרובים.</span></p>
        <p className="MsoNormal">&nbsp;</p>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b><span style={{color: 'black'}}>Details for the Nekudos &amp;
              Taamim tab: </span></b><i><span style={{color: 'black'}}>(these settings are unique
              to each search term)</span></i></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Arial",sans-serif', color: 'black'}}><img width={624} height={110} id="Picture 29" src="helpDialogImages/image018.png" /></span></p>
        <p className="MsoNormal">Three rows of settings appear here:</p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>Nekudos per letter</b>: a selection box
          per each letter/character in the selected search term.&nbsp; If no terms have been
          entered and/or no term has been selected for editing its settings, then no
          boxes will appear in this row.&nbsp; You may select one to many nekudos to allow to
          appear with the letter in question.. This means that any of those selected can
          appear with the letter in question.&nbsp; You may also select <b><span style={{color: 'red'}}>X</span></b> to indicate no nekuda allowed for this letter.&nbsp;
          You may also select <b><span style={{color: 'red'}}>?</span></b> indicating that any
          nekuda is allowed for this letter.</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>General flow of nekudos</b>: this allows
          one to define a sequence of nekudos per term regardless of which letter each
          nekuda is connected with.&nbsp; Each select box is a multi-select that can allow
          different nekudos per single text position.&nbsp; You may also select <b><span style={{color: 'red'}}>X</span></b> to indicate that a letter follows possessing no
          nekudos.&nbsp; You may also select <b><span style={{color: 'red'}}>? </span></b>
          indicating that any nekuda is allowed for a single letter. You may also select <b><span style={{color: 'red'}}>*</span></b> indicating that any nekudos are allowed for a
          sequence of multiple letters.</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>General flow of taamim</b>: this allows
          one to define a sequence of taamim per term regardless of which letter each
          taam is connected with.&nbsp; Right now, terms typically represent a single term.&nbsp;
          Normally a single taam appears for a single word and once in a while there are
          two taamim per word.&nbsp; In the near future multiple word terms will be allowed <i>(eg.
            a stream of consecutive words search in conjunction with other terms)</i>.&nbsp; In
          those cases, this setting will be more powerful where one can request only to
          allow a match on that term if the stream of words contains a pattern of taamim.
          In the meantime, like nekudos, one can do multi-selects to allow either-or
          logic and on can select <b><span style={{color: 'red'}}>X</span></b> or <b><span style={{color: 'red'}}>?</span></b> or <b><span style={{color: 'red'}}>*</span></b> to
          indicate no taam, a single wildcard taam or a wildcard sequence of any multiple
          taamim.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default LNT_Instructions;