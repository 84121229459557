import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {withRouter, HashRouter as Router, Route, Switch} from 'react-router-dom';
import './css/App.css';
import './css/Admin.css';
import Home from './Home';
import Admin from './Admin';
import Maintenance from './Maintenance';
//import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { LocalizeProvider } from "react-localize-redux";
import SiteMap from './SiteMap';
import Finders from './Finders';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import * as properties from './config/properties.js';
//import Callback from './Callback';
import Auth0Lock from 'auth0-lock';
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import { renderToStaticMarkup } from "react-dom/server";

//mje - Oct 29, 2019 - for obsolete code pertinent to trial and error changes to get login/authorization to work with Auth) and Okta in Oct 2019, see version history of this file in Git

//function getQuery() {
//  return new URLSearchParams(useLocation().search);
//}

class AuthenticatedApp extends Component {

 constructor(props) {
    super(props);
    this.state = {
      isMaint: properties.env.environmentName == 'maint',
      isLoading: false,
      heb: ['האתר אינו זמין לצורך תחזוקה', '!נחזור בקרוב'],
      eng: ['Site down for maintenance', 'Will be back soon!'],
      authenticated: false,
      idToken: null,
      accessToken: null,
      profile: null,
      userInfo: null,
      userRole: null,
      isKodeshMode: false,
      kodeshModeInfo: {type: "CHOL"},
      kodeshModeType: null,
      kodeshModeEndType: null,
      kodeshModeStart: null,
      kodeshModeEnd: null,
      kodeshModeSecondsRemaining: null,
    };
    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });
    this.setLanguage = (code) => {props.setActiveLanguage(code)}

    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.toggleKodeshMode = this.toggleKodeshMode.bind(this);
    this.enterKodeshMode = this.enterKodeshMode.bind(this);
    this.enterCholMode = this.enterCholMode.bind(this);
  }

  handleAuthentication(authResult, isRedirect) {
    const fakeThis = this;

    this.lock.getUserInfo(authResult.accessToken, function(error, profile) {
        //alert("hunh?");
        if (error) {
          // Handle error
          return;
        }
    //idToken, state, refreshToken and idTokenPayload
        //alert(JSON.stringify(authResult.accessToken + ":::" + authResult.idToken + ":::" + authResult.state + ":::" + authResult.refreshToken + ":::" + authResult.idTokenPayload));

        fakeThis.getManagementAccessToken((managementAccessToken) => {

            var request = require("request");

            var options = {
                method: 'GET',
                url: properties.env.auth0.apiURL + '/users-by-email',
                qs: {email: profile.email},
                headers: {authorization: 'Bearer ' + managementAccessToken}
            };

            request(options, function (error, response, body) {
              if (error) throw new Error(error);

              const userData = JSON.parse(body);
              const userId = userData[0].identities[0].user_id;
              var request = require("request");

              var options = {
                  method: 'GET',
                  url: properties.env.auth0.apiURL + '/users/auth0|' + userId + '/roles',
                  qs: {email: profile.email},
                  headers: {authorization: 'Bearer ' + managementAccessToken}
              };
  
              request(options, function (error, response, body) {
                if (error) throw new Error(error);

                const roleData = JSON.parse(body);
                const userRole = roleData[0].name;

                //alert(JSON.stringify(jsonBody));
                localStorage.setItem("idToken", authResult.idToken);
                localStorage.setItem("accessToken", authResult.accessToken);
                localStorage.setItem("profile", JSON.stringify(profile));
                localStorage.setItem("userInfo", profile.email);
                localStorage.setItem("userRole", userRole);
              // Update DOM
                fakeThis.setState({
                    authenticated: true,
                    idToken: authResult.idToken,
                    accessToken: authResult.accessToken,
                    profile: JSON.stringify(profile),
                    userInfo: profile.email,
                    userRole: userRole,
                }, () => {
                  fakeThis.lock.hide();
                });

              });
            });

        });

    });

}


getManagementAccessToken(callback){
  var managementAccessToken = null;
  var lastTokenExpirationTime = localStorage.getItem("managementAccessTokenExpirationTime");
  var date = new Date();
  var currentTimestamp = date.getTime();
  if(lastTokenExpirationTime && lastTokenExpirationTime > currentTimestamp){

      //if last token is still good, use it
      var lastManagementToken = localStorage.getItem("managementAccessToken");
      managementAccessToken = lastManagementToken;
      callback(managementAccessToken);
  } else {
      //otherwise get a new token
      var request = require("request");
      var options = {
        method: 'POST',
        url: 'https://basehasefer.auth0.com/oauth/token',
        headers: {'content-type': 'application/x-www-form-urlencoded'},
        form: {
          grant_type: 'client_credentials',
          client_id: properties.env.auth0.managementAPIclientID,
          client_secret: properties.env.auth0.managementAPIclientSecret,
          audience: 'https://basehasefer.auth0.com/api/v2/'
        }
      };
      request(options, function (error, response, body) {
          const jsonBody1 = JSON.parse(body);
          localStorage.setItem("managementAccessToken", jsonBody1.access_token);
          var expirationTimestamp = currentTimestamp + jsonBody1.expires_in;
          localStorage.setItem("managementAccessTokenExpirationTime", expirationTimestamp);

          managementAccessToken = jsonBody1.access_token;
          callback(managementAccessToken);
      })
  }
}

login () {
  this.lock.show();//display the Lock popup widget
}

logout () {
//alert(localStorage.getItem("user_agent"));

  this.setState({ authenticated: false })

  // Clear user token and profile data from localStorage
      localStorage.removeItem('idToken');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('profile');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userRole');
//  }
    this.lock.logout({});
}

    toggleKodeshMode(boolean) {
      //alert(boolean + ":" + this.state.isKodeshMode);
      this.setState({isKodeshMode: boolean});
    }

componentDidUpdate(prevProps, prevState, snapshot){
  //setting this.lock here so it responds to language changes
  const fakeThis = this;
  const willUpdateLock = !!prevProps.activeLanguage && prevProps.activeLanguage.code !== this.props.activeLanguage.code
                          || !prevProps.activeLanguage && !!this.props.activeLanguage;
  if (!this.state.isMaint && willUpdateLock) {
      this.lock = new Auth0Lock(properties.env.auth0.clientID, properties.env.auth0.domain, {
       language: this.props.activeLanguage.code,
       auth: {redirect: false},
       additionalSignUpFields: [
        {name: "full_name", placeholder: this.props.translate("fullName"), validator: function(full_name) {return {valid: full_name.length >= 5, hint: "Must have 5 or more characters"};}},
        {name: "phone", placeholder: this.props.translate("phone"), validator: function(phone) {return {valid: phone.length >= 10, hint: "Must have 10 or more characters"};}},
        {name: "address1", placeholder: this.props.translate("address1"), validator: function(address1) {return {valid: address1.length >= 10, hint: "Must have 10 or more characters"};}},
        {name: "address2", placeholder: this.props.translate("address2"), validator: function(address2) {return {valid: address2.length >= 10, hint: "Must have 10 or more characters"};}}
      ],
        theme: {
          logo: 'https://taryaganalytics.weebly.com/uploads/1/1/0/9/110948629/published/bhslogotm_2.jpg',
          primaryColor: '#1E90FF'
        }
      });
      this.lock.on('authenticated', function(authResult) {fakeThis.handleAuthentication(authResult, true)});
  }
}

async fetchKodeshModeInfo(isPageLoad) {
  const params = new URLSearchParams(this.props.location.search);

  var offsetSecondsTest = 0;
  if (!isPageLoad) {
      offsetSecondsTest = Math.floor(0.5 + ((new Date()).getTime() - localStorage.getItem("pageLoadTime"))/1000); //get Seconds from milliseconds - + 0.5 to round up
  }

  var kodeshModeParams = "apiKey=" + params.get("apiKey") + "&ipAddress=" + params.get("ipAddress") + "&rawGMToffset=" + (new Date()).getTimezoneOffset() + "&dateTimeTest=" + params.get("dateTimeTest") + "&offsetSecondsTest=" + offsetSecondsTest; //dateTimeTest is optional

  var response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getKodeshModeInfo?' + kodeshModeParams)
  var kodeshModeInfo = await response.json();

  return kodeshModeInfo;
}

//popup need secondsRemaining, kodeshStart, kodeshEnd, endType
async enterKodeshMode(kodeshModeInfo, isPageLoad) { //can be via initialize or via exit chol
  if (kodeshModeInfo.secondsRemaining != null) {
    //console.log("KODESH:kodeshStart=" + kodeshModeInfo.kodeshStart + " kodeshEnd=" + kodeshModeInfo.kodeshEnd + " secondsRemaining=" + kodeshModeInfo.secondsRemaining);
    //alert("KODESH:kodeshStart=" + kodeshModeInfo.kodeshStart + " kodeshEnd=" + kodeshModeInfo.kodeshEnd + " secondsRemaining=" + kodeshModeInfo.secondsRemaining);

    var secondsRemaining = isPageLoad == null || !isPageLoad ? kodeshModeInfo.nextKodeshSecondsDuration : kodeshModeInfo.secondsRemaining;
    //for future
    this.timer = setTimeout(
        function() {
            console.log("kodesh over"); //now kodesh, launch future message when over
            this.enterCholMode(); //don't pass kodeshModeInfo in order to force fetchKodeshModeInfo()
        }.bind(this),
        (secondsRemaining + 5)*1000 //since next enterCholMode() call will fetch new kodeshModeInfo status for server
                                                            //and that timing by not be exactly in sync with client to the second, pad timeout with 5 extra seconds - might need to increase id need to account for long international lag times
    );

    //for now
    this.setState(state => ({
      isKodeshMode: true,
      kodeshModeType: kodeshModeInfo.startType ? kodeshModeInfo.startType : kodeshModeInfo.nextKodeshStartType, //source of the call to enterKodeshMode() might come from page load and might come from enterCholMode() timer
      kodeshModeEndType: kodeshModeInfo.endType ? kodeshModeInfo.endType : kodeshModeInfo.nextKodeshEndType,
      kodeshModeStart: kodeshModeInfo.kodeshStart,
      kodeshModeEnd: kodeshModeInfo.kodeshEnd,
      kodeshModeSecondsRemaining: secondsRemaining,
    }));
    }
}

async enterCholMode(_kodeshModeInfo) {
    var kodeshModeInfo = _kodeshModeInfo;
    if (kodeshModeInfo == null)
        kodeshModeInfo = await this.fetchKodeshModeInfo(false);

    //console.log("CHOL:nextKodeshStart=" + kodeshModeInfo.nextKodeshStart + " nextKodeshEnd=" + kodeshModeInfo.nextKodeshEnd + " secondsRemaining=" + kodeshModeInfo.secondsRemaining);
    //alert("CHOL:nextKodeshStart=" + kodeshModeInfo.nextKodeshStart + " nextKodeshEnd=" + kodeshModeInfo.nextKodeshEnd + " secondsRemaining=" + kodeshModeInfo.secondsRemaining);

    //for future
    this.timer = setTimeout(
        function(){
            console.log("chol over"); //now chol, launch future message when over
            this.enterKodeshMode(kodeshModeInfo);
        }.bind(this),
        kodeshModeInfo.secondsRemaining*1000
    );

    //for now
    this.setState(state => ({
      isKodeshMode: false,
      kodeshModeType: kodeshModeInfo.nextKodeshStartType,
      kodeshModeEndType: kodeshModeInfo.nextKodeshEndType,
      kodeshModeStart: kodeshModeInfo.nextKodeshStart,
      kodeshModeEnd: kodeshModeInfo.nextKodeshEnd,
      kodeshModeSecondsRemaining: kodeshModeInfo.nextKodeshSecondsDuration,
    }));
}

async componentDidMount(prevProps, prevState, snapshot){
  let language = localStorage.getItem("language"); //this is working more smoothly than sessionStorage
  if (language == null || language == '') {
      const response3 = this.state.defaultLanguage == '' ? await fetch(properties.env.webServiceURL + '/BaseHaSefer/getLocale') : null;
      const ipInfo = this.state.defaultLanguage == '' ? await response3.json() : null;
      language = (ipInfo != null && (('country' in ipInfo && ipInfo.country == "Israel") || ('zip' in ipInfo && ["07012", "07055"].includes(ipInfo.zip))) ? "he" : "en");
      localStorage.setItem("language", language);
  }
  this.setLanguage(language); //need to do this here for the site - individual child pages will do this on their own as they load

  clearTimeout(this.timer);
  localStorage.setItem("pageLoadTime", (new Date()).getTime()); //in milliseconds
  var kodeshModeInfo = await this.fetchKodeshModeInfo(true);
  if (kodeshModeInfo.type == "CHOL")
      this.enterCholMode(kodeshModeInfo);
  else //type == KODESH
      this.enterKodeshMode(kodeshModeInfo, true);
}

  //async componentDidMount() {
      //const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/ping');
      //const body = await response.text();
      //const newPings = this.state.pingResponse == null ? this.state.pings + 1 : 0;
//      const responsex = await fetch("./maintenanceMessage.txt");
//alert(responsex.json());
//      var result = null;
//      var xmlhttp = new XMLHttpRequest();
//      xmlhttp.open("GET", "./maintenanceMessage.txt", false); //this resides in the public folder
//      xmlhttp.send();
//      if (xmlhttp.status==200) {
//         result = xmlhttp.responseText;
//      }
      //var hebTxt = [], engTxt = [], isMaint = false;
//      if (result != null && util.left(result, 4) == "msg=") {
//          isMaint = true;
//          result = util.clipL(result, 4);
//          var langs = result.split(";");
//          hebTxt = langs[0].split(",");
//          engTxt = langs[1].split(",");
//          //xheb0 = xheb[0]; xheb1 = xheb[1]; xeng0 = xeng[0]; xeng1 = xeng[1];
//      }

//alert(heb0+heb1+eng0+eng1);
      //this.setState({ isMaintMode: isMaint, isLoading: false, heb: hebTxt, eng: engTxt });
  //}

  render() {
    //const {isMaintMode, heb, eng} = this.state;

    if (this.state.isMaint) {
      return <Maintenance hebRows={this.state.heb} engRows={this.state.eng} />;
    }

    const params = new URLSearchParams(this.props.location.search);
    //const startPage = params.get("page");
    const personFullName = params.get("name");
    const personFullName2 = params.get("name2");
    const zoomDistance = params.get("zoom");
    const animate = params.get("animate");
    const pasukLocation = params.get("location");
    const terms = params.get("terms");
    const isExactQuote = params.get("exact");
    const inOrder = params.get("inorder");
    const precision = params.get("precision");
    const word = params.get("word");
    const root = params.get("root");
    const gematria = params.get("gematria");
    const atbash = params.get("atbash");
    const targum = params.get("targum");
    const format = params.get("format");
    const topic = params.get("topic");
    const tab = params.get("tab");
    const language = params.get("language");
    const initialFullScreen = params.get("fullscreen")
    const initialDarkMode = params.get("darkmode")

    /* parameter usage by screen/feature

    language - all
    fullscreen - TanachViewer, TaamParser, ObjectCloudViewer, Lexicon, YuchsinTreeViewer, HelpDialog, AboutDialog
    location - Search, TanachViewer, TaamParser

    Search - terms, exact, inorder, precision, format
    Lexicon - word, root, gematria, atbash, targum
    YuchsinTreeViewer - name, name2, zoomDistance, animate, darkmode
    HelpDialog - topic
    AboutDialog - tab

    Screens without any unique parameters:
    TanachViewer, TaamParser, ObjectCloudViewer, PremiumServicesDialog, ParshaViewer, FrequencyViewer, TextComparatorViewer
    */

    //mje Nov 15, 2022 - the following if block is obsolete no that got nginx working in spring of 2022 so can go diectly to 3dtree from URL
//    if (startPage && startPage == "3Dtree") { //tried this live Nov. 9, 2020 and this also doesn't work when the site is wrapped with basehasefer.com similar to Route path='/3Dtree' below
//        return (
//            <Home login={this.login} authenticated={this.state.authenticated}
//                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} yuchsinTreeViewerOpen={true}/* set state tanachViewerOpen = true */ />
//        );
//    }
    //following script tag was done to enable jQuery for golden-layout as found on Home.js
    //some multiple path options specified below for backwards compatibility
    //todo - INCORRECT PATH SHOULD DEFAULT TO / - the root
    return (
      <LocalizeProvider>
          <Router>
            <Switch>
              <Route path='/' exact={true} render={(props) => <Home {...props} viewer={'StartMenu'} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language}
                  isKodeshMode={this.state.isKodeshMode}
                  kodeshModeType={this.state.kodeshModeType}
                  kodeshModeEndType={this.state.kodeshModeEndType}
                  kodeshModeStart={this.state.kodeshModeStart}
                  kodeshModeEnd={this.state.kodeshModeEnd}
                  kodeshModeSecondsRemaining={this.state.kodeshModeSecondsRemaining}
                  toggleKodeshMode={this.toggleKodeshMode} />}/>
              <Route path='/search' exact={true} render={(props) => <Home {...props} viewer={'StartMenu'} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language}
                  searchOpen={true} pasukLocation={pasukLocation} terms={terms} inOrder={inOrder} isExactQuote={isExactQuote} precision={precision} format={format} />}/>
              <Route path='/parshaViewer' exact={true} render={(props) => <Home {...props} viewer={'ParshaViewer'} login={this.login} authenticated={this.state.authenticated} 
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} />}/>
              <Route path={["/frequency", "/frequencyViewer"]} exact={true} render={(props) => <Home {...props} viewer={'FrequencyViewer'} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} />}/>
              <Route path={["/compare", "/textComparator"]} exact={true} render={(props) => <Home {...props} viewer={'TextComparatorViewer'} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} />}/>
              <Route path='/admin' exact={true} render={(props) => <Admin {...props} login={this.login} authenticated={this.state.authenticated} 
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} />}/>
              /* need to pass lots of stuff (similar to Home) to SiteMap since that page will contain AppNavBar which has some intricate functionality like language select (todo - any way to slim this down?) */
              <Route path='/sitemap' exact={true} render={(props) => <SiteMap {...props} viewer={'SiteMap'} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} />}/>
              <Route path='/finders' exact={true} component={Finders}/>
              <Route path='/maintenance' exact={true} render={(props) => <Maintenance hebRows={this.state.heb} engRows={this.state.eng} />}/>
              <Route path='/kodeshMode' exact={true} render={(props) => <Home {...props} hebRows={this.state.heb} engRows={this.state.eng}
                                        isKodeshMode={false}
                                        kodeshModeType={this.state.kodeshModeType}
                                        kodeshModeEndType={this.state.kodeshModeEndType}
                                        kodeshModeStart={this.state.kodeshModeStart}
                                        kodeshModeEnd={this.state.kodeshModeEnd}
                                        kodeshModeSecondsRemaining={this.state.kodeshModeSecondsRemaining}
                                        toggleKodeshMode={this.toggleKodeshMode} />}/>
              <Route path='/tanach' exact={true} render={(props) => <Home {...props} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} initialFullScreen={initialFullScreen}
                  tanachViewerOpen={true} pasukLocation={pasukLocation} />}/>
              <Route path='/taamParser' exact={true} render={(props) => <Home {...props} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} initialFullScreen={initialFullScreen}
                  taamParserOpen={true} pasukLocation={pasukLocation} />}/>
              <Route path='/clouds' exact={true} render={(props) => <Home {...props} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} initialFullScreen={initialFullScreen}
                  objectCloudViewerOpen={true} />}/>
              <Route path='/lexicon' exact={true} render={(props) => <Home {...props} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} initialFullScreen={initialFullScreen}
                  lexiconOpen={true} precision={precision} word={word} root={root} gematria={gematria} atbash={atbash} targum={targum} />}/>
              <Route path='/3Dtree' exact={true} render={(props) => <Home {...props} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} initialFullScreen={initialFullScreen}
                  yuchsinTreeViewerOpen={true} personFullName={personFullName} zoomDistance={zoomDistance} personFullName2={personFullName2} animate={animate} initialDarkMode={initialDarkMode}
                  /* set state tanachViewerOpen = true */ />}/>
              <Route path='/premiumServices' exact={true} render={(props) => <Home {...props} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} premiumServicesOpen={true} />}/>
              <Route path='/about' exact={true} render={(props) => <Home {...props} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} initialFullScreen={initialFullScreen}
                  aboutOpen={true} tab={tab} />}/>
              <Route path='/mission' exact={true} render={(props) => <Home {...props} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} initialFullScreen={initialFullScreen}
                  aboutOpen={true} tab={0} />}/>
              <Route path='/benefit' exact={true} render={(props) => <Home {...props} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} initialFullScreen={initialFullScreen}
                  aboutOpen={true} tab={1} />}/>
              <Route path='/features' exact={true} render={(props) => <Home {...props} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} initialFullScreen={initialFullScreen}
                  aboutOpen={true} tab={2} />}/>
              <Route path='/contact' exact={true} render={(props) => <Home {...props} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} initialFullScreen={initialFullScreen}
                  aboutOpen={true} tab={10} />}/>
              <Route path='/help' exact={true} render={(props) => <Home {...props} login={this.login} authenticated={this.state.authenticated}
                  userInfo={this.state.userInfo} logout={this.logout} profile={this.state.profile} userRole={this.state.userRole} language={language} initialFullScreen={initialFullScreen}
                  helpOpen={true} topic={topic} />}/>
            </Switch>
          </Router>
      </LocalizeProvider>
    );
  }
}

export default withLocalize(withRouter(AuthenticatedApp));

/*
 This might be used at some point for Auth0
 <Route path='/#/callback' exact={true} component={Callback}/>}/>
*/
