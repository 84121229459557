import React, { Component } from 'react';

class WhoWillBenefitInstitutions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p /><h5>מוסדות חינוך - ישיבות, מתיבתות, אוניברסיטאות, בתי ספר תיכוניים ויסודיים</h5><p />
        <ul>
          <li>משאב למידה מרכזי עבור מחנכים ותלמידיהם</li>
          <li>מחלקות טכנולוגיות חינוכיות (EdTech)
            <ul>
              <li>הפוך את הנושאים למרתקים יותר</li>
              <li>הציגו חזותיים להעברת ידע טוב יותר לתלמידים</li>
              <li>לספק למורים חומרים ורעיונות חינוכיים משופרים</li>
            </ul>
          </li>
        </ul>
        <p /><h5>מוציאים לאור תוכנות תורניות</h5><p />
        <ul>
          <li>הרחב את יכולות אתרים דרך השלחת הודעות בשירותי אינטרנט לשרת שלנו</li>
          <li>
            "קנה לעומת בנה" כדי להפחית את עלויות הפיתוח
          </li>
        </ul>
        <p /><h5>עורכים ומוציאים לאור של ספרי קודש</h5><p />
        <ul>
          <li>השתמש בכלי הערות ומחקר אוטומטי כדי לחסוך זמן ועלות בעת עריכת הערות שוליים ומפתחות
          </li>
        </ul>
        <p /><h5>ספריות מחקר (לאומי, מדינתי, אוניברסיטאי)</h5><p />
        <ul>
          <li>משכו תלמידים וחוקרים על ידי הצעת משאבים יקרי ערך</li>
          <li>אוטומציה של מחקר</li>
          <li>גש לפרטים וניתוח שלא ניתן היה להשיג בעבר</li>
          <li>שפר את הביצועים האקדמיים ואיכות העבודה</li>
          <li>פרופסורים וסטודנטים מתקדמים יכולים להמשיך במחקר ייחודי</li>
          <li>צמצם את הנטל על ספרנים ופרופסורים</li>
        </ul>
        
      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <p /><h5>Schools - yeshivos, mesivtos, universities, high schools, elementary schools</h5><p />
        <ul>
          <li>A central learning resource for rebbeim, faculty and students</li>
          <li>Educational Technology (EdTech) departments
            <ul>
              <li>Make subjects more engaging</li>
              <li>Present visuals to better communicate knowledge to students</li>
              <li>Provide teachers with improved educational materials and ideas</li>
            </ul>
          </li>
        </ul>
        <p /><h5>Publishers of Torah software</h5><p />
        <ul>
          <li>Expand site capabilities via web service calls to our server</li>
          <li>Buy vs. build to reduce development costs</li>
        </ul>
        <p /><h5>Editors and publishers of seforim</h5><p />
        <ul>
          <li>Use auto-annotation and research tools to save time and cost when compiling footnotes and mafteichos</li>
        </ul>
        <p /><h5>Research libraries (national, state, university)</h5><p />
        <ul>
          <li>Attract students and researchers by offering valuable resources</li>
          <li>Automate research</li>
          <li>Access previously unattainable details and analysis</li>
          <li>Improve academic performance and quality of work</li>
          <li>Professors and advanced students can pursue unique research</li>
          <li>Reduce burden on reference librarians and professors</li>
        </ul>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default WhoWillBenefitInstitutions;