import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { Link, withRouter } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import LoadingOverlay from 'react-loading-overlay';
import { Paper, Typography, Dialog, DialogActions, DialogContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const styles = theme => ({
  toggleContainer: {
    height: 28,
    backgroundColor: 'transparent',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    alignItems: 'center',
    margin: `auto`,
    background: theme.palette.background.default,
  },
});

class ObjectCloudSectionSelector extends Component {

 constructor(props) {
    super(props);
    this.state = {
      textSelectorDialogOpen: false,
      renderVals:{
        textSelector: [],
      },
      textSelectorData: [],
      continue:true,
    };

    this.textSelectorGridReady = this.textSelectorGridReady.bind(this);
    this.addTextSelectorRow = this.addTextSelectorRow.bind(this);
    this.extAddTextSelectorRow = this.extAddTextSelectorRow.bind(this);
    this.deleteTextSelectorRow = this.deleteTextSelectorRow.bind(this);
    this.extDeleteTextSelectorRow = this.extDeleteTextSelectorRow.bind(this);
    this.extModifyTextSelectorRow = this.extModifyTextSelectorRow.bind(this);
    this.modifyTextSelectorRow = this.modifyTextSelectorRow.bind(this);
    this.getRenderVals = this.getRenderVals.bind(this);
    this.handleTextSelectorDialogOpen = this.handleTextSelectorDialogOpen.bind(this);
    this.handleTextSelectorDialogClose = this.handleTextSelectorDialogClose.bind(this);
    this.isNewRowData = this.isNewRowData.bind(this);
    this.setRenderVals = this.setRenderVals.bind(this);
    this.goBack = this.goBack.bind(this);
    this.reallyClose = this.reallyClose.bind(this);

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}
  }

    textSelectorGridReady = params => {
      this.props.textSelectorGridReady(params);
    };

    addTextSelectorRow() {
      this.props.addTextSelectorRow();
    }

    extAddTextSelectorRow = () => {
        this.addTextSelectorRow();
    }

    deleteTextSelectorRow(extState) {
      this.props.deleteTextSelectorRow(extState);
    }

    extDeleteTextSelectorRow = (extState) => {
        this.deleteTextSelectorRow(extState);
    }

    extModifyTextSelectorRow = (extState) => {
        this.modifyTextSelectorRow(extState);
    }

    modifyTextSelectorRow(extState) {
      this.props.modifyTextSelectorRow(extState);
    }

    handleTextSelectorDialogOpen() {
        this.setState({ textSelectorDialogOpen: true })
    }

    async handleTextSelectorDialogClose() {
      await this.setRenderVals()
      var canContinue = true;
      for( let i = 0; i < this.state.textSelectorData.length; i++){
        var thisRow = this.state.textSelectorData[i];
        if(thisRow.type){
          canContinue = thisRow.val4 !== 0 || (((i===0 && this.state.textSelectorData.length===1) || i === this.state.textSelectorData.length-1) && (thisRow.val1 === 0 && thisRow.val2 === 0 && thisRow.val3 === 0 && thisRow.val4 === 0 && thisRow.val5 === 0))
        }else{
          canContinue = (thisRow.val1 !== 0 && thisRow.val2 !== 0 && thisRow.val3 !== 0 && thisRow.val4 !== 0 && thisRow.val5 !== 0 && thisRow.val6 !== 0) ||
                        (((i===0 && this.state.textSelectorData.length===1) || i === this.state.textSelectorData.length-1) && (thisRow.val1 === 0 && thisRow.val2 === 0 && thisRow.val3 === 0 && thisRow.val4 === 0 && thisRow.val5 === 0 && thisRow.val6 === 0))
        }
        if(!canContinue) break;
      }
      if(canContinue){
        this.setState({ textSelectorDialogOpen: false })
      } else{
        this.setState({continue:false})
      }
    }
    reallyClose(){
      var data=this.state.textSelectorData;
      var canContinue = true;
      for( let i = 0; i < data.length; i++){ //go through the rows to delete the one that is not full
        var thisRow = data[i];
        if(thisRow.type){
          canContinue = thisRow.val4 !== 0 || (((i===0 && this.state.textSelectorData.length===1) || i === this.state.textSelectorData.length-1) && thisRow.val1 === 0 && thisRow.val2 === 0 && thisRow.val3 === 0 && thisRow.val4 === 0 && thisRow.val5 === 0)
        }else{
          canContinue = (thisRow.val1 !== 0 && thisRow.val2 !== 0 && thisRow.val3 !== 0 && thisRow.val4 !== 0 && thisRow.val5 !== 0 && thisRow.val6 !== 0) ||
                        (((i===0 && this.state.textSelectorData.length===1) || i === this.state.textSelectorData.length-1) && thisRow.val1 === 0 && thisRow.val2 === 0 && thisRow.val3 === 0 && thisRow.val4 === 0 && thisRow.val5 === 0 && thisRow.val6 === 0)
        }
        if(!canContinue){
          if(i===data.length-1){
            data[i].val1=0;
            data[i].val2=0;
            data[i].val3=0;
            data[i].val4=0;
            data[i].val5=0;
            data[i].val6=0;
          }
          else{
            data.splice(i,1);
            i--;
          }
        }
      }
      this.setState({textSelectorData:data, continue:true},()=>{
        this.handleTextSelectorDialogClose()
      })
    }
    goBack(){
      this.setState({continue:true})
    }

    isNewRowData(){
      var prevTextSelectorDataStr = JSON.stringify(this.state.textSelectorData);
      var newTextSelectorDataStr = JSON.stringify(this.props.textSelectorData);
      var newTextSelectorData = prevTextSelectorDataStr !== newTextSelectorDataStr;
      if(newTextSelectorData){
        this.setState({
          textSelectorData: this.props.textSelectorData,
        })
        return true;
      } else {
        return false;
      }
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
      if(this.isNewRowData()){
        await this.setRenderVals();
      }
    }

    async setRenderVals(){
      const fakeThis = this;
      var renderVals = {
        textSelector: [],
      };

      await this.props.textSelectorData.map(async function(e, index){
        var currentRow = fakeThis.props.textSelectorData[index]
        var currRenderVals = await fakeThis.getRenderVals(currentRow)
        renderVals.textSelector.push(currRenderVals)
      })
      
      this.setState({
        renderVals: renderVals
      })

    }

   async getRenderVals(textDataRow){
     if(textDataRow){
    if(!!textDataRow.then){ // when filling in selector automatically from context menu, textDataRow is a promise and this needs to wait for the response
      textDataRow = await textDataRow;
     }
     var renderVals = this.props.getRenderVals(textDataRow)
    return renderVals;
     }
   }


  render() {
    const { classes } = this.props;
    const {totalTerms1, termTotal1Accurate, textSelectorData} = this.props;

    const isLoading = this.props.isLoadingResults;

    const fakeThis = this;
    var isHebrew = localStorage.getItem('language') === 'he';

    var directionStyling = {direction: 'ltr', textAlign: 'left'};
    var langDirection = 'ltr'
    if(isHebrew){
        directionStyling = {direction: 'rtl', textAlign: 'right'};
        langDirection = 'rtl';
    }

    var totalTerms1Paper = <Paper elevation={1} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0'}}>
           <Typography variant='body1'>{this.props.translate("totalTerms")}: <b>{totalTerms1}</b></Typography>
       </Paper>
    if(!termTotal1Accurate){
        totalTerms1Paper = <Paper elevation={0} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0', backgroundColor: '#d7d7d7' }}>
           <Typography variant='body1' style={{color: "#9f9f9f"}} >{this.props.translate("totalTerms")}: <b>{totalTerms1}</b></Typography>
       </Paper>
    }

    return (<span className="object-cloud-selector">
            <table style={{height: '100px'}}className={'objectCloudSelectionSelector'}>
              <tr width='1300px' style={{ maxWidth: 'calc(100vw - 400px)', minWidth: '968px' }}>
              <td width='170px' style={{textAlign: 'center', verticalAlign: 'top', paddingTop: '10px'}}>
                    <Button disabled={this.props.isPlaying} variant="contained" type="submit" className="bhsButtonLarge" style={{margin: 'auto', minWidth: '170px'}} onClick={this.handleTextSelectorDialogOpen}>{this.props.translate("selectSections")}</Button>
                    <LoadingOverlay
                      active={isLoading}
                      spinner
                      styles={{
                              spinner: (base) => ({
                                ...base,
                                width: '30px',
                                paddingTop: '5px',
                                '& svg circle': {
                                  stroke: 'white'
                                }
                              })
                            }}
                      >
                        {totalTerms1Paper}
                    </LoadingOverlay>
                </td>
                <td style={{width:'100%', paddingLeft: '5px'}}>
                    <Paper elevation={4} style={{height: '80px', margin: '5px', overflow:'auto', padding:' 10px 40px 10px 10px', width: '100%'}} id="text-comparator-text1-paper" rendervals={this.state.renderVals}>
                        <ul style={{marginBottom: 0}}>
                            {this.props.textSelectorData.map(function(e, index){
                                var currentRow = fakeThis.props.textSelectorData[index]
                                var renderVals = fakeThis.state.renderVals.textSelector[index] || [];

                                if(currentRow && currentRow.type && currentRow.val1){
                                    var renderString = ``;
                                    return(
                                        <li key={index} style={{direction:'rtl', lineHeight:'20px', textAlign:'right'}}>
                                                <Typography style={{lineHeight: 1}} variant="subtitle1"><small>{renderVals[0] || ''}{renderVals[1] || ''}{renderVals[2] || ''}</small><b>{renderVals[3] || ''}{renderVals[4] || ''}</b></Typography>
                                        </li>
                                    )
                                } else if (currentRow && currentRow.type === false) {
                                    return (
                                        <li key={index} style={{direction:'rtl', lineHeight:'20px', textAlign:'right'}}>
                                                <Typography style={{lineHeight: 1}} variant="subtitle1"><b>{`${renderVals[0] || ''} ${renderVals[1] || ''}:${renderVals[2] || ''}`}</b><small> עד </small><b>{`${renderVals[3] || ''} ${renderVals[4] || ''}:${renderVals[5] || ''}`}</b></Typography>
                                        </li>
                                    )
                                }
                          })}
                        </ul>
                    </Paper>
                </td>
              </tr>
            </table>

                   <Dialog
                      open={this.state.textSelectorDialogOpen}
                      onClose={this.handleTextSelectorDialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth={'xl'}
                      style={directionStyling}
                    >
                      <DialogContent>
                        <div
                          className="ag-theme-balham"
                          style={{
                            height: '205px',
                            width: '1170px',
                            maxWidth: 'calc(100vw - 150px)',
                           }}
                            >
                            <AgGridReact id="text1"
                                ref="agGrid"
                                rowData={textSelectorData}
                                onGridReady={this.textSelectorGridReady}
                                enableFilter={false}
                                frameworkComponents={this.state.frameworkComponents}
                                headerHeight={28}
                                rowHeight={40}
                                overlayNoRowsTemplate={'<span></span>'}
                                enableRtl="true">
                            </AgGridReact>
                        </div>

                      </DialogContent>
                      <DialogActions>
                        <Button variant="contained" onClick={this.handleTextSelectorDialogClose} autoFocus>
                          <Translate id="close" />
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog open={!this.state.continue} 
                      onClose={this.reallyClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth={'xl'}
                      style={directionStyling}>
                      <DialogContent>
                           {this.props.translate("dontCloseSelectionMessage")}
                      </DialogContent>
                      <DialogActions>
                        <Button color="primary" variant="contained" onClick={this.goBack} autoFocus>
                          <Translate id="goBack" />
                        </Button>
                        <Button color="secondary" variant="contained" onClick={this.reallyClose} autoFocus>
                          <Translate id="close" />
                        </Button>
                      </DialogActions>
                    </Dialog>
        </span>
    );
  }
}

ObjectCloudSectionSelector.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withLocalize(ObjectCloudSectionSelector));
