import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { Link, withRouter } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import LoadingOverlay from 'react-loading-overlay';
import { Paper, Typography, Dialog, DialogActions, DialogContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Spinner} from 'primereact/spinner';
import * as properties from './config/properties.js';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const styles = theme => ({
  toggleContainer: {
    height: 28,
    backgroundColor: 'transparent',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    alignItems: 'center',
    margin: `auto`,
    background: theme.palette.background.default,
  },
});

class TextComparatorSectionSelector extends Component {

 constructor(props) {
    super(props);
    this.state = {
      text1DialogOpen: false,
      text2DialogOpen: false,
      renderVals:{
        text1: [],
        text2: []
      },
      text1Data: [],
      text2Data: [],
      continueText1:true,
      continueText2:true,
    };

    this.text1GridReady = this.text1GridReady.bind(this);
    this.addText1Row = this.addText1Row.bind(this);
    this.extAddText1Row = this.extAddText1Row.bind(this);
    this.deleteText1Row = this.deleteText1Row.bind(this);
    this.extDeleteText1Row = this.extDeleteText1Row.bind(this);
    this.extModifyText1Row = this.extModifyText1Row.bind(this);
    this.modifyText1Row = this.modifyText1Row.bind(this);
    this.text2GridReady = this.text2GridReady.bind(this);
    this.addText2Row = this.addText2Row.bind(this);
    this.extAddText2Row = this.extAddText2Row.bind(this);
    this.deleteText2Row = this.deleteText2Row.bind(this);
    this.extDeleteText2Row = this.extDeleteText2Row.bind(this);
    this.extModifyText2Row = this.extModifyText2Row.bind(this);
    this.modifyText2Row = this.modifyText2Row.bind(this);
    this.getRenderVals = this.getRenderVals.bind(this);

    this.handleText1DialogOpen = this.handleText1DialogOpen.bind(this);
    this.handleText1DialogClose = this.handleText1DialogClose.bind(this);
    this.handleText2DialogOpen = this.handleText2DialogOpen.bind(this);
    this.handleText2DialogClose = this.handleText2DialogClose.bind(this);
    this.isNewRowData = this.isNewRowData.bind(this);
    this.setRenderVals = this.setRenderVals.bind(this);
    this.reallyCloseText1 = this.reallyCloseText1.bind(this);
    this.goBackText1 = this.goBackText1.bind(this);
    this.reallyCloseText2 = this.reallyCloseText2.bind(this);
    this.goBackText2 = this.goBackText2.bind(this);

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}
  }

    text1GridReady = params => {
      this.props.text1GridReady(params);
    };

    text2GridReady = params => {
      this.props.text2GridReady(params);
    };

    addText1Row() {
      this.props.addText1Row();
    }

    addText2Row() {
      this.props.addText2Row();
    }

    extAddText1Row = () => {
        this.addText1Row();
    }

    extAddText2Row = () => {
        this.addText2Row();
    }

    deleteText1Row(extState) {
      this.props.deleteText1Row(extState);
    }

    deleteText2Row(extState) {
      this.props.deleteText2Row(extState)
    }

    extDeleteText1Row = (extState) => {
        this.deleteText1Row(extState);
    }

    extDeleteText2Row = (extState) => {
        this.deleteText2Row(extState);
    }

    extModifyText1Row = (extState) => {
        this.modifyText1Row(extState);
    }

    extModifyText2Row = (extState) => {
        this.modifyText2Row(extState);
    }

    modifyText1Row(extState) {
      this.props.modifyText1Row(extState);
    }

    modifyText2Row(extState) {
      this.props.modifyText2Row(extState);
    }

    handleText1DialogOpen() {
        this.setState({ text1DialogOpen: true })
    }

    async handleText1DialogClose() {
      await this.setRenderVals()
        // this.setState({ text1DialogOpen: false })
      var canContinue = true;
      for( let i = 0; i < this.state.text1Data.length; i++){
        var thisRow = this.state.text1Data[i];
        if(thisRow.type){
          canContinue = thisRow.val4 !== 0 || (((i===0 && this.state.text1Data.length===1) || i === this.state.text1Data.length-1) && (thisRow.val1 === 0 && thisRow.val2 === 0 && thisRow.val3 === 0 && thisRow.val4 === 0 && thisRow.val5 === 0))
        }else{
          canContinue = (thisRow.val1 !== 0 && thisRow.val2 !== 0 && thisRow.val3 !== 0 && thisRow.val4 !== 0 && thisRow.val5 !== 0 && thisRow.val6 !== 0) ||
                        (((i===0 && this.state.text1Data.length===1) || i === this.state.text1Data.length-1) && (thisRow.val1 === 0 && thisRow.val2 === 0 && thisRow.val3 === 0 && thisRow.val4 === 0 && thisRow.val5 === 0 && thisRow.val6 === 0))
        }
        if(!canContinue) break;
      }
      if(canContinue){
        this.props.saveText1Data(this.state.text1Data)
        this.setState({ text1DialogOpen: false })
      } else{
        this.setState({continueText1:false})
      }
    }
    reallyCloseText1(){
      var data=this.state.text1Data;
      var canContinue = true;
      for( let i = 0; i < data.length; i++){ //go through the rows to delete the one that is not full
        var thisRow = data[i];
        if(thisRow.type){
          canContinue = thisRow.val4 !== 0 || (((i===0 && this.state.text1Data.length===1) || i === this.state.text1Data.length-1) && thisRow.val1 === 0 && thisRow.val2 === 0 && thisRow.val3 === 0 && thisRow.val4 === 0 && thisRow.val5 === 0)
        }else{
          canContinue = (thisRow.val1 !== 0 && thisRow.val2 !== 0 && thisRow.val3 !== 0 && thisRow.val4 !== 0 && thisRow.val5 !== 0 && thisRow.val6 !== 0) ||
                        (((i===0 && this.state.text1Data.length===1) || i === this.state.text1Data.length-1) && thisRow.val1 === 0 && thisRow.val2 === 0 && thisRow.val3 === 0 && thisRow.val4 === 0 && thisRow.val5 === 0 && thisRow.val6 === 0)
        }
        if(!canContinue){
          if(i===data.length-1){
            data[i].val1=0;
            data[i].val2=0;
            data[i].val3=0;
            data[i].val4=0;
            data[i].val5=0;
            data[i].val6=0;
          }
          else{
            data.splice(i,1);
            i--;
          }
        }
      }
      this.setState({text1Data:data},()=>{this.setState({continueText1:true},()=>{
        this.handleText1DialogClose()
        this.props.saveText1Data(data)
      },this)
      },this)
    }
    goBackText1(){
      this.setState({continueText1:true})
    }  

    handleText2DialogOpen() {
        this.setState({ text2DialogOpen: true })
    }

    async handleText2DialogClose() {
      await this.setRenderVals()
      var canContinue = true;
      for( let i = 0; i < this.state.text2Data.length; i++){
        var thisRow = this.state.text2Data[i];
        if(thisRow.type){
          canContinue = thisRow.val4 !== 0 || (((i===0 && this.state.text2Data.length===1) || i === this.state.text2Data.length-1) && (thisRow.val1 === 0 && thisRow.val2 === 0 && thisRow.val3 === 0 && thisRow.val4 === 0 && thisRow.val5 === 0))
        }else{
          canContinue = (thisRow.val1 !== 0 && thisRow.val2 !== 0 && thisRow.val3 !== 0 && thisRow.val4 !== 0 && thisRow.val5 !== 0 && thisRow.val6 !== 0) ||
                        (((i===0 && this.state.text2Data.length===1) || i === this.state.text2Data.length-1) && (thisRow.val1 === 0 && thisRow.val2 === 0 && thisRow.val3 === 0 && thisRow.val4 === 0 && thisRow.val5 === 0 && thisRow.val6 === 0))
        }
        if(!canContinue) break;
      }
      if(canContinue){
        this.props.saveText2Data(this.state.text2Data)
        this.setState({ text2DialogOpen: false })
      } else{
        this.setState({continueText2:false})
      }
        // this.setState({ text2DialogOpen: false })
    }
    reallyCloseText2(){
      var data=this.state.text2Data;
      var canContinue = true;
      for( let i = 0; i < data.length; i++){ //go through the rows to delete the one that is not full
        var thisRow = data[i];
        if(thisRow.type){
          canContinue = thisRow.val4 !== 0 || (((i===0 && this.state.text2Data.length===1) || i === this.state.text2Data.length-1) && thisRow.val1 === 0 && thisRow.val2 === 0 && thisRow.val3 === 0 && thisRow.val4 === 0 && thisRow.val5 === 0)
        }else{
          canContinue = (thisRow.val1 !== 0 && thisRow.val2 !== 0 && thisRow.val3 !== 0 && thisRow.val4 !== 0 && thisRow.val5 !== 0 && thisRow.val6 !== 0) ||
                        (((i===0 && this.state.text2Data.length===1) || i === this.state.text2Data.length-1) && thisRow.val1 === 0 && thisRow.val2 === 0 && thisRow.val3 === 0 && thisRow.val4 === 0 && thisRow.val5 === 0 && thisRow.val6 === 0)
        }
        if(!canContinue){
          if(i===data.length-1){
            data[i].val1=0;
            data[i].val2=0;
            data[i].val3=0;
            data[i].val4=0;
            data[i].val5=0;
            data[i].val6=0;
          }
          else{
            data.splice(i,1);
            i--;
          }
        }
      }
      this.setState({text2Data:data, continueText2:true},()=>{
        this.handleText2DialogClose()
        this.props.saveText2Data(data)
      },this)
    }
    goBackText2(){
      this.setState({continueText2:true})
    }

    isNewRowData(){
      var prevText1DataStr = JSON.stringify(this.state.text1Data);
      var prevText2DataStr = JSON.stringify(this.state.text2Data);
      var newText1DataStr = JSON.stringify(this.props.text1Data);
      var newText2DataStr = JSON.stringify(this.props.text2Data);
      var newText1Data = prevText1DataStr !== newText1DataStr;
      var newText2Data = prevText2DataStr !== newText2DataStr;
      if(newText1Data || newText2Data){
        this.setState({
          text1Data: this.props.text1Data,
          text2Data: this.props.text2Data,
        })
        return true;
      } else {
        return false;
      }
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
      if(this.isNewRowData()){
        await this.setRenderVals();
      }
    }

    async setRenderVals(){
      const fakeThis = this;
      var renderVals = {
        text1: [],
        text2: []
      };

      await this.props.text1Data.map(async function(e, index){
        var currentRow = fakeThis.props.text1Data[index]
        var currRenderVals = await fakeThis.getRenderVals(currentRow)
        renderVals.text1.push(currRenderVals)
      })

      await this.props.text2Data.map(async function(e, index){
        var currentRow = fakeThis.props.text2Data[index]
        var currRenderVals = await fakeThis.getRenderVals(currentRow)
        renderVals.text2.push(currRenderVals)
      })
      
      this.setState({
        renderVals: renderVals
      })

    }

   async getRenderVals(textDataRow){
     if(textDataRow){
    if(!!textDataRow.then){ // when filling in selector automatically from context menu, textDataRow is a promise and this needs to wait for the response
      textDataRow = await textDataRow;
     }
     var renderVals = this.props.getRenderVals(textDataRow)
    return renderVals;
     }
   }


  render() {
    const { classes } = this.props;
    const {totalTerms1, termTotal1Accurate, totalTerms2, termTotal2Accurate, text2Data, text1Data} = this.props;

    const isLoading = this.props.isLoadingResults;

    const fakeThis = this;
    var isHebrew = localStorage.getItem('language') === 'he';

    var directionStyling = {direction: 'ltr', textAlign: 'left'};
    var langDirection = 'ltr'
    if(isHebrew){
        directionStyling = {direction: 'rtl', textAlign: 'right'};
        langDirection = 'rtl';
    }

    var totalTerms1Paper = <Paper elevation={1} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0'}}>
           <Typography variant='body1'>{this.props.translate("totalTerms")}: <b>{totalTerms1}</b></Typography>
       </Paper>
    if(!termTotal1Accurate){
        totalTerms1Paper = <Paper elevation={0} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0', backgroundColor: '#d7d7d7' }}>
           <Typography variant='body1' style={{color: "#9f9f9f"}} >{this.props.translate("totalTerms")}: <b>{totalTerms1}</b></Typography>
       </Paper>
    }

    var totalTerms2Paper = <Paper elevation={1} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0'}}>
           <Typography variant='body1'>{this.props.translate("totalTerms")}: <b>{totalTerms2}</b></Typography>
       </Paper>
    if(!termTotal2Accurate){
        totalTerms2Paper = <Paper elevation={0} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0', backgroundColor: '#d7d7d7' }}>
           <Typography variant='body1' style={{color: "#9f9f9f"}} >{this.props.translate("totalTerms")}: <b>{totalTerms2}</b></Typography>
       </Paper>
    }

    return (<span>
      <div style={{
        height: '100px',
        maxWidth: 'calc(100vw - 400px)',
        minWidth: '968px'
      }}>
        <LoadingOverlay
          active={this.props.isFilingSelector}
          spinner
          text={null}
        >
            <table style={{height: '100px'}}>
              <tr width='1300px' style={{ maxWidth: 'calc(100vw - 400px)', minWidth: '968px' }}>
                <td width='170px' style={{textAlign: 'center', verticalAlign: 'top', paddingTop: '10px'}}>
                    <Button variant="contained" type="submit" className="bhsButtonLarge" style={{margin: 'auto', minWidth: '170px'}} onClick={this.handleText2DialogOpen}>{this.props.translate("selectSections")}</Button>
                    <LoadingOverlay
                      active={isLoading}
                      spinner
                      styles={{
                              spinner: (base) => ({
                                ...base,
                                width: '30px',
                                paddingTop: '5px',
                                '& svg circle': {
                                  stroke: 'white'
                                }
                              })
                            }}
                      >
                        {totalTerms2Paper}
                    </LoadingOverlay>
                </td>

                <td style={{width:'590px'}}>
                    <Paper elevation={4} style={{height: '80px', margin: '5px', overflow:'auto', padding:' 10px 40px 10px 10px'}}>
                        <ul style={{marginBottom: 0}}>
                            {this.state.text2Data.map(function(e, index){
                                var currentRow = fakeThis.props.text2Data[index]
                                var renderVals = fakeThis.state.renderVals.text2[index] || [];

                                if(currentRow && currentRow.type && currentRow.val1){
                                    var renderString = ``;
                                    return(
                                        <li style={{direction:'rtl', lineHeight:'20px', textAlign:'right'}}>
                                                <Typography style={{lineHeight: 1}} variant="subtitle1"><small>{`${renderVals[0] || ''}${renderVals[1] || ''}${renderVals[2] || ''}`}</small><b>{`${renderVals[3] || ''}${renderVals[4] || ''}`}</b></Typography>
                                        </li>
                                    )
                                } else if (currentRow && currentRow.type === false) {
                                    return (
                                        <li style={{direction:'rtl', lineHeight:'20px', textAlign:'right'}}>
                                                <Typography style={{lineHeight: 1}} variant="subtitle1"><b>{`${renderVals[0] || ''} ${renderVals[1] || ''}:${renderVals[2] || ''}`}</b><small> עד </small><b>{`${renderVals[3] || ''} ${renderVals[4] || ''}:${renderVals[5] || ''}`}</b></Typography>
                                        </li>
                                    )
                                }
                          })}
                        </ul>
                    </Paper>
                </td>

                <td style={{width:'590px'}}>
                    <Paper elevation={4} style={{height: '80px', margin: '5px', overflow:'auto', padding:' 10px 40px 10px 10px'}} id="text-comparator-text1-paper" rendervals={this.state.renderVals}>
                        <ul style={{marginBottom: 0}}>
                            {this.props.text1Data.map(function(e, index){
                                var currentRow = fakeThis.props.text1Data[index]
                                var renderVals = fakeThis.state.renderVals.text1[index] || [];

                                if(currentRow && currentRow.type && currentRow.val1){
                                    var renderString = ``;
                                    return(
                                        <li key={index} style={{direction:'rtl', lineHeight:'20px', textAlign:'right'}}>
                                                <Typography style={{lineHeight: 1}} variant="subtitle1"><small>{renderVals[0] || ''}{renderVals[1] || ''}{renderVals[2] || ''}</small><b>{renderVals[3] || ''}{renderVals[4] || ''}</b></Typography>
                                        </li>
                                    )
                                } else if (currentRow && currentRow.type === false) {
                                    return (
                                        <li key={index} style={{direction:'rtl', lineHeight:'20px', textAlign:'right'}}>
                                                <Typography style={{lineHeight: 1}} variant="subtitle1"><b>{`${renderVals[0] || ''} ${renderVals[1] || ''}:${renderVals[2] || ''}`}</b><small> עד </small><b>{`${renderVals[3] || ''} ${renderVals[4] || ''}:${renderVals[5] || ''}`}</b></Typography>
                                        </li>
                                    )
                                }
                          })}
                        </ul>
                    </Paper>
                </td>
                <td width='170px' style={{textAlign: 'center', verticalAlign: 'top', paddingTop: '10px'}}>
                    <Button variant="contained" type="submit" className="bhsButtonLarge" style={{margin: 'auto', minWidth: '170px'}} onClick={this.handleText1DialogOpen}>{this.props.translate("selectSections")}</Button>
                    <LoadingOverlay
                      active={isLoading}
                      spinner
                      styles={{
                              spinner: (base) => ({
                                ...base,
                                width: '30px',
                                paddingTop: '5px',
                                '& svg circle': {
                                  stroke: 'white'
                                }
                              })
                            }}
                      >
                        {totalTerms1Paper}
                    </LoadingOverlay>
                </td>
              </tr>
            </table>
            </LoadingOverlay>
          </div>

                   <Dialog
                      open={this.state.text1DialogOpen}
                      onClose={this.handleText1DialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth={'xl'}
                      style={directionStyling}
                    >
                      <DialogContent>
                        <div
                          className="ag-theme-balham"
                          style={{
                            height: '205px',
                            width: '1170px',
                            maxWidth: 'calc(100vw - 150px)',
                           }}
                            >
                            <AgGridReact id="text1"
                                ref="agGrid"
                                /* columnDefs={this.state.placementColumnDefs} */
                                rowData={text1Data} //this.state.rowData
                                onGridReady={this.text1GridReady}
                                /* localeTextFunc = {(key, defaultValue) => agTranslate(key, defaultValue)} */
                                enableFilter={false}
                                frameworkComponents={this.state.frameworkComponents}
                                headerHeight={28}
                                rowHeight={40}
                                overlayNoRowsTemplate={'<span></span>'}
                                enableRtl="true">
                            </AgGridReact>
                        </div>

                      </DialogContent>
                      <DialogActions>
                        <Button variant="contained" onClick={this.handleText1DialogClose} autoFocus>
                          <Translate id="close" />
                        </Button>
                      </DialogActions>
                    </Dialog>


                    <Dialog
                      open={this.state.text2DialogOpen}
                      onClose={this.handleText2DialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth={'xl'}
                      style={directionStyling}
                    >
                      <DialogContent>
                        <div
                          className="ag-theme-balham"
                          style={{
                            height: '205px',
                            width: '1170px',
                            maxWidth: 'calc(100vw - 150px)',
                          }}
                            >
                            <AgGridReact id="text2"
                                ref="agGrid"
                                /* columnDefs={this.state.placementColumnDefs} */
                                rowData={text2Data} //this.state.rowData
                                onGridReady={this.text2GridReady}
                                /* localeTextFunc = {(key, defaultValue) => agTranslate(key, defaultValue)} */
                                enableFilter={false}
                                frameworkComponents={this.state.frameworkComponents}
                                headerHeight={28}
                                rowHeight={40}
                                overlayNoRowsTemplate={'<span></span>'}
                                enableRtl="true">
                            </AgGridReact>
                        </div>

                      </DialogContent>
                      <DialogActions>
                        <Button variant="contained" onClick={this.handleText2DialogClose} autoFocus>
                          <Translate id="close" />
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <Dialog open={!this.state.continueText1} 
                      onClose={this.reallyCloseText1}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth={'xl'}
                      style={directionStyling}>
                      <DialogContent>
                           {this.props.translate("dontCloseSelectionMessage")}
                      </DialogContent>
                      <DialogActions>
                        <Button color="primary" variant="contained" onClick={this.goBackText1} autoFocus>
                          <Translate id="goBack" />
                        </Button>
                        <Button color="secondary" variant="contained" onClick={this.reallyCloseText1} autoFocus>
                          <Translate id="close" />
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <Dialog open={!this.state.continueText2} 
                      onClose={this.reallyCloseText2}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth={'xl'}
                      style={directionStyling}>
                      <DialogContent>
                           {this.props.translate("dontCloseSelectionMessage")}
                      </DialogContent>
                      <DialogActions>
                        <Button color="primary" variant="contained" onClick={this.goBackText2} autoFocus>
                          <Translate id="goBack" />
                        </Button>
                        <Button color="secondary" variant="contained" onClick={this.reallyCloseText2} autoFocus>
                          <Translate id="close" />
                        </Button>
                      </DialogActions>
                    </Dialog>
        </span>
    );
  }
}

TextComparatorSectionSelector.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withLocalize(TextComparatorSectionSelector));
