import React, { Component } from 'react';

class FeaturesGeneral extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <i><b>ניתן ללחוץ באמצעות לחצן העכבר הימני על כל מילות, ביטויי, שורות, ופסוקי תוצאה כדי להציג תפריט רגיש להקשר המאפשר:</b></i><br /><br />
        <ul>
          <li>הפעלת חיפוש אחר המילים שנבחרו</li>
          <li>הפעלת חיפוש כדי למצוא פסוקים עם נתונים סטטיסטיים ותכונות דומות</li>
          <li>שליחת המילים שנבחרו לסרגל החיפוש</li>
          <li>העתקת המילים שנבחרו ללוח המערכת שם ניתן להדביק לאפליקציות או מסמכים אחרים</li>
          <li><i>בעת פתיחת תפריט ההקשר של אחת משלוש האפשרויות שלמעלה, תקבלו כמה מהאפשרויות הנוספות הבאות:</i>
            <ul>
              <li>סמן את התיבה אם להעתיק / לחפש את הטקסט שנבחר כציטוט</li>
              <li>סמן את התיבה אם להציג את שם ה' ככינוי</li>
              <li>בחירה אם להעתיק / לחפש את הטקסט שנבחר כאותיות בלבד / אותיות + נקודות / אותיות + נקודות + טעמים</li>
            </ul>
          </li>
          <li> הצגת ה<b>מנתח טעמים</b> לפסוק שנבחר</li>
          <li> הצגת <b>דפדפן התנ"ך</b> כדי לראות את ההקשר של הפסוק שנבחר בתוך הפסוקים שמסביבו</li>
          <li> הצגת ה<b>לקסיקון</b> עבור המילה שנבחרה</li>
        </ul>
      </>
    } else if (langCode === "en") {
      retVal = <>

        <i><b>All result words, phrases, rows and pesukim can be right-clicked to display a context-sensitive menu which enables:</b></i><br /><br />
        <ul>
          <li>Firing a search on the selected words</li>
          <li>Firing a search to find pesukim with similar statistics and features</li>
          <li>Sending the selected words to the search bar</li>
          <li>Copying the selected words to the system clipboard where they can be pasted to other applications or documents</li>
          <li><i>When opening the context menu for any of the three options above, one will get some of the following additional options:</i>
            <ul>
              <li>Checkbox whether to copy/search for the selected text as a quote</li>
              <li>Checkbox whether to show shem H' as kinui form</li>
              <li>Selection whether to copy/search the selected text as letters-only / letter+nekudos / letter+nekudos+taamim</li>
            </ul>
          </li>
          <li>Displaying the <b>Taam Parser</b> for the selected pasuk</li>
          <li>Displaying the <b>Tanach Viewer</b> so one can see the context of the selected pasuk within its surrounding pesukim</li>
          <li>Displaying the <b>Lexicon</b> for the selected word</li>
        </ul>


      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default FeaturesGeneral;