import React, { Component } from 'react';

class FeaturesFuture extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p /><h5>מפת דרכים</h5><p />
        <br />
        <i>אנא צור איתנו קשר ב <b> info@taryaganalytics.com </b> עם בקשות לתכונות שכן אנו ניקח זאת בחשבון!</i>
        <br />
        <br />
        <p /><h6><b>תמחור</b></h6><p />
        <ul>
          <li>לגמרי בחינם כעת</li>
          <li>בשלב מסוים בעתיד, על מנת שיהיה בר-קיימא, האתר יצטרך לייצר הכנסות מסוימות. אנו שוקלים אפשרויות שונות כמו מודל freemium, פרסומות וכו '.</li>
        </ul>
        <p /><h6><b>שיפור תשתיות</b></h6><p />
        <ul>
          <li>הצעת שירות מהיר יותר למשתמשים בישראל ובמיקומים אחרים מחוץ לארצות הברית</li>
        </ul>
        <p /><h6><b>תקנת באגים</b></h6><p />
        <ul>
          <li><i>עיין ברשימה המלאה בכרטיסיית <b> בעיות </b></i></li>
        </ul>
        <p /><h6><b>הרחבת הגישה לבייס-הספר</b></h6><p />
        <ul>
          <li>שימוש ממדיה חיצונית כגון כונן אגודל ללא צורך בחיבור לאינטרנט</li>
          <li>תמיכה בטאבלט, אנדרואיד ו- iOS באמצעות אפליקציה ו / או HTML5</li>
          <li>תכונות SaaS כגון שירותי אינטרנט וממשקי API המציעים תוצאות קריאות במכונה</li>
        </ul>
        <p /><h6><b>שיפור ממשק המשתמש</b></h6><p />
        <ul>
          <li>הוראות / תיעוד טובות יותר</li>
          <li>כניסות / חשבונות משתמשים לרכישת תכונות מתקדמות</li>
          <li>הגדרות משתמש</li>
          <li>הודעות, התראות על נפילות מערכת</li>
        </ul>
        <p /><h6><b>שיפור הדיוק של התכונות הקיימות</b></h6><p />
        <ul>
          <li>שורשים וחלקי דיבור</li>
          <li>התאמה טובה יותר בין מילות תרגום למילות פסוק</li>
        </ul>
        <p /><h6><b>שיפור אפשרויות חיפוש ותכונות</b></h6><p />
        <ul>
          <li>תנאי ו / או, עם מספר בלתי מוגבל של ביטויים</li>
          <li>התנתק ממגבלות הפסוק הבודד לתחום החיפוש</li>
          <li>יכולת חיפוש אחר מילים נרדפות</li>
          <li>אפשרות לחפש בתוך קבוצה של תוצאות קיימת</li>
        </ul>
        <p /><h6><b>הוספת תוכן מעניין</b></h6><p />
        <ul>
          <li>דפי חיפוש נוספים</li>
          <li>ויזואליזציות, דוחות, תרשימים</li>
        </ul>
        <p /><h6><b>הוספת תוכן ספרים</b></h6><p />
        <ul>
          <li>תרגומי תנ"ך, מסורה, מפרשי תנ"ך כמו רש"י, וכו'</li>
          <li>משנה, תוספתא, גמרא (ירושלמי ובבלי), מדרשים, ומפרשים</li>
          <li>משנה תורה להרמב"ם</li>
          <li>טור / שולחן ערוך</li>
        </ul>
        <p /><h6><b>הוספת כלים</b></h6><p />
        <ul>
          <li>הערה אוטומטית של טקסט</li>
        </ul>

      </>
    } else if (langCode === "en") {
      retVal = <>

      <div>
        <p /><h5>Roadmap</h5><p />
        <br />
        <i>Please contact us at <b>info@taryaganalytics.com</b> with feature requests as we will take that into account!</i>
        <br />
        <br />
        <p /><h6><b>Pricing</b></h6><p />
        <ul>
          <li>Currently fully free</li>
          <li>At some future point, in order to be viable, the site will need to generate some revenue. Various options are being considered such as a freemium model, ads, etc.</li>
        </ul>
        <p /><h6><b>Improve infrastructure</b></h6><p />
        <ul>
          <li>Offer faster service for users in Israel and other locations outside the USA</li>
        </ul>
        <p /><h6><b>Fix bugs</b></h6><p />
        <ul>
          <li><i>See the full list on the <b>Issues and Bugs</b> tab</i></li>
        </ul>
        <p /><h6><b>Widen access to Base HaSefer</b></h6><p />
        <ul>
          <li>Runnable from external media such as thumb drives without need for an internet connection</li>
          <li>Tablet, Android and iOS support via an app and/or HTML5</li>
          <li>SaaS features such as web services and APIs offering machine-readable results</li>
        </ul>
        <p /><h6><b>Improve the user interface</b></h6><p />
        <ul>
          <li>Better instructions/documentation</li>
          <li>User logins/accounts for gaining advanced features</li>
          <li>User settings</li>
          <li>Notices, alerts for system down</li>
        </ul>
        <p /><h6><b>Improve accuracy of existing features</b></h6><p />
        <ul>
          <li>Shorashim and parts-of-speech</li>
          <li>Better alignment of Targum words to pasuk words</li>
        </ul>
        <p /><h6><b>Improve search options and features</b></h6><p />
        <ul>
          <li>AND / OR conditions, with unlimited number of terms</li>
          <li>Break out of restraints of single pasuk for search domain</li>
          <li>Ability to search on synonyms</li>
          <li>Ability to search within a set of existing results</li>
        </ul>
        <p /><h6><b>Add interesting content</b></h6><p />
        <ul>
          <li>Additional finder pages</li>
          <li>Visualizations, reports, charts</li>
        </ul>
        <p /><h6><b>Add sefer content</b></h6><p />
        <ul>
          <li>Targumei Tanach, masora, meforshei Tanach eg. Rashi, etc.</li>
          <li>Mishna, tosefta, gemara (yerushalmi and bavli), midrashim and meforshim</li>
          <li>Rambam's Mishne Torah</li>
          <li>Tur / shulchan aruch</li>
        </ul>
        <p /><h6><b>Add tools</b></h6><p />
        <ul>
          <li>Auto annotate text</li>
        </ul>
      </div>


      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default FeaturesFuture;