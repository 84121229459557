import React, { Component } from 'react';

class SearchResults_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

      <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>חלון
            תוצאות</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - לאחר
          לחיצה על
          כפתור החיפוש
          כדי להפעיל
          חיפוש, ייפתח
          חלון בין סרגל
          הזנת ביטויי
          החיפוש לתוכן
          העמוד. בזמן
          שתוצאות
          החיפוש
          נטענות, הוא יופיע
          בצבע אפור
          וייראה כך:</span></p>
      <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={624} height={85} id="Picture 9" src="helpDialogImages/image004.jpg" /></span></p>
      <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
          סיום הטעינה
          של התוצאות
          החלון עשוי
          להיראות כך:</span></p>
      <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={668} height={154} id="Picture 106" src="helpDialogImages/image005.jpg" /></span></p>
      <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>במקרה
          זה הביטוי היה
          "שדה" (כששורש
          נבחר בהגדרות
          החיפוש)
          ושורות רבות
          של פסוקים
          הוחזרו כתוצאות</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>. </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כאן
          (מימין לשמאל)
          העמודה
          הראשונה
          מציגה את מספר
          השורה של
          התוצאות,
          העמודה
          השנייה מציגה
          את מיקום
          הפסוק
          והעמודה
          השלישית
          מציגה את הטקסט
          של הפסוק
          התואם עצמו. בתוך
          הטקסט של כל
          פסוק, ביטויים
          התואמים את ביטויי
          החיפוש
          יודגשו
          בצבעים שונים.
          אפשר להניח את
          חלון התוצאות
          פתוח או
          לחלופין
          לסגור אותו על
          ידי לחיצה על
          סמל ה- </span><span dir="LTR">X</span> <span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
          בצד ימין
          למעלה.</span></p>
      <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
      <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
          תוכלו להבחין תכונות שונות באזור התוצאות: (ראה במספרים שבצילום למעלה)
          </span>
      </p>
      <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}>1.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;
        </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מציין
          אזור מימין
          לתוצאות
          המאפשר לך
          לנהל את התוצאות
          שלך</span></p>
      <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.75in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כפתורי
          "</span><b><span dir="LTR" style={{fontFamily: '"Franklin Gothic Heavy",sans-serif'}}>A+</span></b><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />" ו"</span><span dir="LTR" /><b><span lang="HE" dir="LTR" style={{fontFamily: '"Franklin Gothic Heavy",sans-serif'}}><span dir="LTR" /></span></b><b><span dir="LTR" style={{fontFamily: '"Franklin Gothic Heavy",sans-serif'}}>A-</span></b><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />" יגדילו או
          יכווצו את גודל
          הגופן של
          הפסוקים</span></p>
      <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.75in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>סמלי
          התיקיה
          והתקליטון
          השחורים
          מיועדים לטעינה
          ושמירה של
          תוצאות חיפוש
          (ראה להלן)</span></p>
      <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.75in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ניתן
          לבחור או להסיר
          את הבחירה
          בשורת תוצאות
          או מספר שורות
          של תוצאות על
          ידי לחיצה על
          שורות. לאחר
          שתעשה זאת,
          לחיצה על סמל
          פח האשפה תסיר
          את כל השורות
          שנבחרו מהתוצאות.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לחלופין
          תוכלו ללחוץ
          על סמלי התיבה
          הלבנה או השחורה
          ממש מימין
          לביטול
          הבחירה או
          לבחור בכל
          השורות.</span></p>
      <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.75in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תיבת
          הסימון
          הראשונה למטה
          כאשר היא
          מסומנת תנחה
          את החיפוש
          לטעון את
          התוצאות
          בכרטיסייה חדשה
          (ראה כרטיסיות
          תוצאות חיפוש
          בהמשך)</span></p>
      <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.75in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תיבת
          הסימון
          השנייה כאשר
          היא מסומנת,
          תנחה את
          החיפוש לסרוק
          כנגד התוכן של
          שורות תוצאות
          החיפוש
          הנוכחיות
          לעומת חיפוש בתוך
          התנ"ך כולו או
          כל הספרים
          שבחרת בסרגל
          הגדרות
          החיפוש.</span></p>
      <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}>2.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;
        </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מציין
          את סרגל
          הכרטיסיות של
          תוצאות
          החיפוש. אם
          תיבת הסימון
          שהוזכרה לעיל
          (פתח תוצאות
          הכרטיסייה
          חדשה) מסומנת,
          אז בכל לחיצה
          על כפתור
          החיפוש,
          התוצאות
          יטענו
          בכרטיסייה
          חדשה.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
        </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אפשר
          לנווט
          מכרטיסייה
          לכרטיסייה על
          ידי לחיצה
          עליהן וניתן
          למחוק
          כרטיסיות על
          ידי לחיצה על
          ה- </span><span dir="LTR">X</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
          שנמצא בכל
          כותרת
          כרטיסייה.</span></p>
      <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}>3.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;
        </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מציין
          את מספר
          השורות (בדרך
          כלל פסוקים)
          התואמים את ביטויי
          החיפוש</span></p>
      <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}>4.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;
        </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בקרות
          הניווט
          בתוצאות. כדי
          לשפר את
          הביצועים, החיפוש
          לא טוען את כל
          התוצאות
          לטבלה בבת
          אחת. תלוי
          בכמה תוצאות
          בעמוד
          מוגדרות
          להצגה, ניתן ללחוץ
          על הכפתורים
          הבאים כדי
          לנווט בין דפי
          התוצאה: &gt;| עבור
          לדף התוצאה
          האחרון, |&lt; עבור
          לדף התוצאה
          הראשונה, &gt;
          עבור לדף הבא, &lt;
          עבור לדף
          הקודם. באמצע
          יש בורר נפתח
          איתו אפשר
          לבחור דף
          ספציפי שאליו
          לעבור.</span></p>
      <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}>5.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;
        </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
        בורר תוצאות לדף.
          נפתח זה מאפשר
          לבחור בין: 10, 20, 50, 100, 200,
          כל התוצאות</span></p>
        <br />
        <p><img width={668} height={200} id="Picture 107" src="helpDialogImages/newViewOfSearchResults.jpg" /></p>
      <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
      <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
תוכלו להבחין בתכונות הנוספות שבאזור התוצאות: (ראה מספרים שבצילום למעלה)
          </span>
      </p>
      <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}>6.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;
        </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
תיבת סימון טיפים לסטטיסטיקה של פסוק. תיבת סימון זו מאפשרת לבחור אם רמז צץ הסטטיסטיקה של פסוק יוצג או לא כאשר המשתמש מרחף מעל השורות השונות של התוצאות.
        </span>
      </p>
      <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}>7.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;
        </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
תיבת סימון טיפים לסטטיסטיקה של מילים. תיבת סימון זו מאפשרת לבחור אם רמז צץ סטטיסטיקה של כל מילה אמור להופיע כאשר המשתמש מרחף מעל המילים השונות של הפסוקים בתוצאות.
        </span>
      </p>
      <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}>8.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;
        </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
כפתור +/-. כפתור זה מרחיב ומכווץ את שורת הסטטיסטיקה המבוססת על מילים עבור אותו פסוק. כאשר המשתמש לוחץ עליה כדי להרחיב את שורת הסטטיסטיקה, מופיעה שורה חדשה המציגה טבלה עם כל מילה בפסוק שנבחר והסטטיסטיקה הספציפית שלה.
        </span>
      </p>
      <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}>9.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;
        </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
התפריט הנפתח של עמודות סטטיסטיקה. כפתור זה מרחיב לוח בקרה שבו המשתמש יכול לציין פרטים שונים הרצויים עבור תוצאות החיפוש.
 כדי לכלול כל אחד מהנתונים הסטטיסטיים בשורות התוצאות, לחץ על נתון זה. אם זה לא רצוי יותר, לחץ שוב והוא יוסר. כדי לבחור את כל הנתונים הסטטיסטיים, לחץ על כפתור בחר הכל,
 וכדי להסיר את כל הנתונים הסטטיסטיים, לחץ על כפתור הסר הכל. אם תרצה לשים את עמודות הסטטיסטיקה הנוספות מימין או שמאל של עמודות הטקסט,
 בחר באפשרות הרצויה בתפריט הנפתח מיקום ליד טקסט (A). כדי לבחור אילו נתונים סטטיסטיים יוצגו ברמז צץ של פםוק, בחרו במידע על רחף (B).
 C הוא המקום שבו המשתמש יכול לבחור אילו נתונים סטטיסטיים יועתקו ויודבקו עם הטקסט אם כלול סטטיסטיקה מסומנת, ו-D מציין כיצד הפסוקים יודבקו.
 או שכל פסוק יופיע בשורה משלו עם הכותרות לפני כל תא מידע, או שתהיה שורה עם הכותרות וכל שורת פסוק תהיה מתחת לשורת הכותרת הזו.
        </span>
      </p>
      <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}>10.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;
        </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
כפתור סגור הכל. לחיצה על כפתור זה מסתירה את כל השורות המציגות את הסטטיסטיקה הספציפית של הפסוקים לכל מילה.
        </span>
      </p>
      <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}>11.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;
        </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
תצוגת תוכן. זה המקום שבו המשתמש יכול לבחור אילו תצוגות לכלול בחלון התוצאות. אתה יכול לבחור תנ"ך או תרגום או שניהם.
אם בתוצאות, המקבילות בפסוקים בין תרגום לתנ"ך מוצגות, תוכל לרחף מעל כל צד ולראות את המילים המתאימות בצד השני.
        </span>
      </p>
      <br/>
      <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>
-העתק והדבק סטטיסטיקה: כדי להעתיק את הסטטיסטיקה של פסוק בערכת התוצאות,
 לחץ לחיצה ימנית על הפסוק או הפסוקים הרצויים. בחר העתק ללוח.
 סמן את התיבה "כלול סטטיסטיקה" ובחר באיזו רמת העתקה תרצה.
 הנתונים הסטטיסטיים יועתקו בפורמט המוגדר בתפריט הנפתח "עמודי סטטיסטיקה".
      </p>
      <br/>
      <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>
-כאשר תוצאות החיפוש נטענות, תופיע תיבה לבנה הניתנת להרחבה בסרגל השמאלי.
 תיבה זו מפרטת את ההגדרות השונות שנכללו בחיפוש האחרון.
  אם תרצה להציג את ההגדרות ביתר פירוט, לחץ על כפתור ההרחבה בתחתית, ותופיע תיבה הניתנת להרחבה ולגרירה.
      </p>
        <table><tr><td>בסרגל השמאלי:</td><td>בחלון המוקפץ:</td></tr>
        <tr><td valign={'top'}><img width={150} height={300} id="searchSettingsList" src="helpDialogImages/searchSettingsList.jpg" /></td><td><img width={310} height={470} id="searchSettingsList" src="helpDialogImages/searchSettingsList2.jpg" /></td></tr></table>

      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b>Results window</b> – once the search button is clicked to
          fire a search, a window will open up between the search term entry bar and the
          page content.&nbsp; While the search results are loading, it will be grayed out and
          look like this: </p>
        <p className="MsoNormal"><img width={624} height={85} id="Picture 9" src="helpDialogImages/image004.jpg" /></p>
        <p className="MsoNormal">Once the results have finished loading the window might look
          like this:</p>
        <p className="MsoNormal"><img width={668} height={153} id="Picture 106" src="helpDialogImages/image005.jpg" /></p>
        <p className="MsoNormal">In this case, the term was <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שדה</span><span dir="LTR" /><span dir="LTR" /> (with Shoresh selected in the search settings)
          and many rows of pesukim were returned as results.&nbsp; Here (right-to-left)&nbsp; the
          first column displays the row number of the results, the second column displays
          the pasuk location and the third column displays the text of the actual
          matching pasuk.&nbsp; Within the text of each pasuk, those terms matching the search
          terms will be highlighted in different colors.&nbsp; You may keep the results window
          open or you may close it by clicking the X symbol on the upper right.</p>
        <p className="MsoNormal" style={{marginLeft: '.5in'}}>&nbsp;</p>
        <p className="MsoNormal">You will notice various features in the results area: (see numbers in image above)</p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}>1.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span dir="LTR" />Indicates an area to the right of the results which lets you manage
          your results</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.75in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />The <b><span style={{fontFamily: '"Franklin Gothic Heavy",sans-serif'}}>A+ </span></b>
          and <b><span style={{fontFamily: '"Franklin Gothic Heavy",sans-serif'}}>A- </span></b>
          buttons will enlarge or shrink the font size of the pesukim</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.75in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />The black folder and floppy disk icons are
          for loading and saving search results (see below)</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.75in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />One can select or deselect a single row of
          results or multiple rows of results by clicking on rows.&nbsp; Once this is done,
          clicking on the garbage can icon will remove all selected rows from the
          results.&nbsp; Alternatively you can click on the white or black box icons just to
          the right to deselect or select all rows.</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.75in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />The first checkbox below when checked will
          instruct the search to load the results in a new tab (see search result tabs
          below)</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.75in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />The second checkbox when checked will
          instruct the search to scan against the content of the current search result
          rows as opposed to searching within Tanach or whatever sefarim you have
          selected in the search settings bar.</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}>2.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span dir="LTR" />Indicates the search results tab bar.&nbsp; If the checkbox mentioned
          above (load the results in a new tab) is checked, then each time the search button
          is clicked, the results will load into a new tab.&nbsp; One navigates from tab to
          tab by clicking on them and one can delete tabs by clicking the <b>X </b>
          present on each tab header.</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}>3.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span dir="LTR" />Indicates the number of rows (typically pesukim) that match the
          search terms and conditions</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}>4.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span dir="LTR" />The results navigation controls.&nbsp; In order to improve
          performance, the search doesn’t load all the results into the grid at once.&nbsp;
          Depending on how many results per page are set to display, one can click on the
          following buttons to navigate among result pages: |&lt; go to last result page,
          &gt;| go to first result page, &lt; go to next page, &gt; go to previous page.&nbsp;
          In the middle there is a dropdown selector with which one can select a specific
          page to which to move.</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}>5.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span dir="LTR" />Results per page selector.&nbsp; This dropdown lets one select from:
          10, 20, 50, 100, 200, All results</p>
        <br />
        <p><img width={668} height={200} id="Picture 107" src="helpDialogImages/newViewOfSearchResults.jpg" /></p>
        <p className="MsoNormal">You will notice the following additional features in the results area: (see numbers in image above)</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}>6.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span dir="LTR" />Pasuk Statistics Tooltip Checkbox.&nbsp; This checkbox lets one select
          whether or not the pasuk statistics tooltip should show when the user hovers over the different rows of the results.</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}>7.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span dir="LTR" />Word Statistics Tooltip Checkbox.&nbsp; This checkbox lets one select
          whether or not the word statistics tooltip should show when the user hovers over the different words of the pesukim in the results.</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}>8.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span dir="LTR" />+/- Button.&nbsp; This button expands and contracts the word based
          statistics row for that pasuk. When the user clicks on it to expand the statistics row, a new row appears showing a table with each word in the selected pasuk and its specific statistics.</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}>9.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span dir="LTR" />Statistics Columns Dropdown.&nbsp; This button expands a control panel where the user can
        specify different details wanted for the search results. To include any of the statistics in the results rows, click on that statistic. If it is no longer wanted, click again and it will be removed. To select all of the statistics, click on the Select All button, and to remove all of the statistics, click the Remove All button.
        If you would like to put the extra statistics columns on the right or left of the text columns, choose the desired option in the Position near text dropdown (A). To choose which statistics get shown in the Pasuk Tooltip, choose the Hover Info (B).
        C is where the user can choose which statistics will be copied and pasted with the text if Include Statistics is checked, and D indicates how the Pesukim will be pasted. Either each pasuk will appear in it's own line with the headers before each cell of information, 
        or there will be a row with the headers and each pasuk row will be under that header row.</p>        
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}>10.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;</span><span dir="LTR" />Close All Button.&nbsp; Clicking this button hides all of the rows showing the pesukim's specific per word statistics.</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}>11.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;</span>Content View.&nbsp; This is where the user can choose which views to include in the results window. You can
        either choose תנ"ך or תרגום or both. If both the תרגום and the תנ"ך equivalents of the פסוקים results are shown, you can hover over either side and view the corresponding word/s in the other side.</p>
        <br/><p className="MsoNormal" >-Copy and Paste Statistics: To copy the statistics of a pasuk in the results set, right click on the desired Pasuk or Pesukim.
        Select copy to clipboard. Check the box that says Include Statistics and choose what level of copying you would like. The statistics will be copied in the format that is defined in the Statistics Columns dropdown.
        </p>
        <br/><p className="MsoNormal" >-When the search results are loaded, a white expandable box will appear in the left bar. This box lists the different settings that were included in most recent search.
        &nbsp;If you would like to view the settings in more detail, click the expand button on the bottom, and a draggable expandable box will appear.</p>
        <table><tr><td>In the left Bar:</td><td>In the Pop-Up:</td></tr>
        <tr><td valign={'top'}><img width={150} height={300} id="searchSettingsList" src="helpDialogImages/searchSettingsList.jpg" /></td><td><img width={310} height={470} id="searchSettingsList" src="helpDialogImages/searchSettingsList2.jpg" /></td></tr></table>
      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default SearchResults_Instructions;