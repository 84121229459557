import React, { Component } from 'react';

class SimpleSearch_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כאשר
            האתר נטען
            לראשונה, <b>בייס-הספר </b>
            נפתח עם הדף "<b>פרשיות
              התנ"ך</b>". לא
            משנה איזה דף
            ייבחר באתר,
            תמיד יהיה <b>סרגל
              חיפוש</b> שנראה
            כמו:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={624} height={67} id="Picture 97" src="helpDialogImages/image002.jpg" /></span></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שטח
              להקלדת ביטויי
              חיפוש</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>: הקלד בטווי
            חיפוש אחד או
            יותר מימין
            לשמאל עם רווח
            להפרדת כל
            בטוי חיפוש.
            ניתן לעשות
            זאת באמצעות
            מקלדת רגילה
            עם עברית (</span><span dir="LTR">HE</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />) שנבחרה
            כשפת הקלט או
            באמצעות <b>המקלדת
              הווירטואלית </b>
            (<i>פרטים בהמשך</i>).</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כפתור
              חיפוש</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> (סמל
            זכוכית
            מגדלת): לחיצה
            כאן תפעיל
            חיפוש לאחר
            הזנת בטויי
            חיפוש. זה
            יפתח חלון
            תוצאות (<i>פרטים
              בהמשך</i>) עם
            טבלת תוצאות
            או הודעה
            המציינת כי לא
            נמצאו תוצאות.</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כפתור
              טען תוצאות
              שנשמרו</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> (סמל
            התיקיה):
            לחיצה כאן
            תפתח דיאלוג
            של מערכת הקבצים
            שמבקש
            מהמשתמש
            לבחור בקובץ .</span><span dir="LTR">JSON</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> שנשמר
            מראש ובו
            קבוצה של
            תוצאות חיפוש.
            בחירת הקובץ
            תטען את
            התוצאות מבלי
            לשנות את ביטויי
            החיפוש
            וההגדרות
            הנוכחיים (<i>פרטים
              נוספים בהמשך</i>).</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כפתור
              מחק </span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
            (סמל פח זבל):
            פעולה זו תמחק
            את כל ביטויי
            החיפוש
            שהוזנו.</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כפתור
              מקלדת
              וירטואלית</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> (סמל
            מקלדת):
            משתמשים
            מסוימים
            ימצאו את זה
            נוח יותר
            להזנת אותיות
            בעברית. כל
            המשתמשים
            יצטרכו זאת
            לצורך הקלדת
            נקודות וטעמי
            מקרא. פעולה זו
            תפתח חלון
            קופץ עם חלון
            המקלדת הכחול
            הבא:</span></p>
        <p className="MsoNormal" align="center" dir="RTL" style={{textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={393} height={270} id="Picture 100" src="helpDialogImages/image003.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{marginRight: '.5in', textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שילוב
            נקודות וטעמים
            עם אותיות
            יכול להיות
            מסובך. על מנת
            לשייך דמות
            כזו לאות
            הרצויה לך, יש
            להקליד אותה
            אחרי האות ולא
            לפניה. למקשי
            אותיות
            מסוימים יש
            נקודות
            משותפות
            לנוחות. לאחר
            שתסיים
            להשתמש
            במקלדת
            הווירטואלית,
            תוכל לסגור
            אותה על ידי
            לחיצה על סמל
            ה- </span><span dir="LTR">X</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
            בצד ימין
            למעלה.</span></p>
        <p className="MsoNormal">&nbsp;</p>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal">No matter what page is selected and navigated on the web
          site, there will always be a <b>search bar</b> on top that looks like the
          following:</p>
        <p className="MsoNormal"><img width={624} height={67} id="Picture 97" src="helpDialogImages/image002.jpg" /></p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>Space for typing search terms</b>: type
          one or more search terms from right to left with a space to separate each
          search term. This can be done via a standard keyboard with Hebrew (HE) selected
          as the input language or via the <b>virtual keyboard</b> (<i>details below</i>).</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>Search button</b> (magnifying glass icon):
          clicking here will fire a search, once search terms are entered.&nbsp; This will
          open up a results window (<i>details below</i>) with either a grid of results
          or a message saying that no results were found.</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>Load saved results button </b>(folder
          icon): clicking here will open a file system dialog asking the user to select a
          pre-saved .JSON file containing a set of search results.&nbsp; Selecting the file
          will load the results without altering current search terms and definitions (<i>more
            details below</i>).</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>Erase button </b>(garbage can icon): this
          will erase all search terms entered.</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>Virtual keyboard button </b>(keyboard
          icon): certain users will find this more convenient for entering Hebrew
          letters.&nbsp; All users will need this for typing <i>nekudos</i> and <i>trop</i>.&nbsp;
          This will open a popup with the following blue keyboard window:</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>Virtual keyboard button </b>(keyboard
          icon): certain users will find this more convenient for entering Hebrew
          letters.&nbsp; All users will need this for typing <i>nekudos</i> and <i>trop</i>.&nbsp;
          This will open a popup with the following blue keyboard window:</p>
        <p className="MsoNormal" align="center" style={{textAlign: 'center'}}><img width={393} height={270} id="Picture 100" src="helpDialogImages/image003.jpg" /></p>
        <p className="MsoNormal" style={{marginLeft: '.5in'}}>Combining nekudos and trop with
          letters can get tricky.&nbsp; In order to associate such a character with a letter
          you desire, it must be typed after the letter and not before.&nbsp; Certain letter
          keys have common nekudos for convenience.&nbsp; Once you are done using the virtual
          keyboard, you can close it by clicking the X symbol on the upper right.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default SimpleSearch_Instructions;