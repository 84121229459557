import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { Link, withRouter } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import { Button as BootstrapButton } from 'reactstrap';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import LoadingOverlay from 'react-loading-overlay';
import { Paper, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, List, ListItem, ListItemText, FormControl, Select, MenuItem, FormHelperText, InputLabel, FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PlacementRenderer from './PlacementRenderer';
import {Spinner} from 'primereact/spinner';
import Bookmarks from '@material-ui/icons/Bookmarks';
import * as properties from './config/properties.js';
import {Checkbox} from 'primereact/checkbox';
import { Menu, Item, Separator, Submenu, MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import ContextMenu from './ContextMenu';
import PlacementSelector2D from './PlacementSelector2D';
import CommonWordFinder from './CommonWordFinder';
import LCSViewer from './LCSViewer';
import DiffViewer from './DiffViewer';
import TextComparatorCloudViewer from './TextComparatorCloudViewer';
import HomeIcon from '@material-ui/icons/Home';
import LinkIcon from '@material-ui/icons/Link';
import { IconButton  } from '@material-ui/core';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const styles = theme => ({
  toggleContainer: {
    height: 28,
    backgroundColor: 'transparent',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    alignItems: 'center',
    margin: `auto`,
    background: theme.palette.background.default,
  },
});

//todo this is repeated elsewhere - combine it
function encodeFilterIds(nodes, nodeString) {
    var nodeStr = (nodeString != undefined) ? nodeString : "0".repeat(44);
    nodes.forEach((node)=>{
        if (node.tableName == "Sefer" && node.isChecked)
            nodeStr = util.stuff(nodeStr, "1", node.id-1, 1);
        if(node.children){
            nodeStr = encodeFilterIds(node.children, nodeStr);
        }
    })
    return nodeStr;
}

function getAlternateRowStyle(params) {
    if (params.node.rowIndex % 2 === 0 && !isOutlier(params)) {
        return {background: '#F0F0F0'};
    } else if (params.node.rowIndex % 2 === 0 && isOutlier(params)){
        return {background: '#c4cfff'};
    } else if (params.node.rowIndex % 2 === 1 && isOutlier(params)){
        return {background: '#d9e0ff'};
    }
}

function isOutlier(params) {
    if(!params.data.termInstances1 || !params.data.termInstances2){
        return true;
    }
    return false;
}

function namedSection(params) {
  return '<Spinner name="ns" id="ns" value={1} min={1} max={10} />';
}

function pasukRange(params) {
  return <Spinner name="pr" id="pr"  value={1} min={1} max={10} />;
}

function BaseParameters() {
  this.source = null;
  this.clientIP = null;
  this.morphInflections = null;
  this.filterIds = null;
  this.nGrams = null;
  this.sortResultsBy = null;
  this.resultsToDisplay = null;
  this.namedLocations =  []; //NamedLocation()
  this.namedLocations2 =  []; //NamedLocation()
  this.pasukRangeLocations = []; //PerekPasukRange()
  this.pasukRangeLocations2 = []; //PerekPasukRange()
}

function NamedLocation(kriaId, aliyaId) {
  this.kriaId =  kriaId; //int
  this.aliyaId = aliyaId; //int
}

function PerekPasukRange(seferId1, perekNum1, pasukNum1, seferId2, perekNum2, pasukNum2) {
  this.seferId1 =  seferId1; //int
  this.perekNum1 = perekNum1;  //int
  this.pasukNum1 =  pasukNum1; //int
  this.seferId2 = seferId2;  //int
  this.perekNum2 =  perekNum2; //int
  this.pasukNum2 = pasukNum2;  //int
}

class TextComparatorViewer extends Component {

//webServiceURL = properties.env.webServiceURL;

 constructor(props) {
    super(props);
    this.state = {
        comparatorType: "commonWord",
        isLoading: false,
        clientIP: '',
        columnDefs: this.getGridColumns(),
        rowData: [],
        defaultLanguage: '',
        text1DialogOpen: false,
        text2DialogOpen: false,
        totalTerms1: 0,
        totalTerms2: 0,
        termTotal1Accurate: true,
        termTotal2Accurate: true,
        nGrams: 1,
        sortResultsByChosen: 1,
        sortResultsByCurrent: 1,
        resultsToDisplay: 1,
        textToCopy: 'bitui',
        useKinuim: false,
        searchAsQuote: false,
        includeMekorot: false,
        // wordCountData: {
        //     totalTerms1: 0,
        //     totalTerms2: 0,
        //     termTotal1Accurate: true,
        //     termTotal2Accurate: true,
        // },
        diffData: {
            isLoading: false,
            textBlock1Data: [],
            textBlock2Data: [],
            textBlock1: "",
            textBlock2: "",
            sourceList1: [],
            sourceList2: [],
            diffUnit: 'word',
            highlightCondition: 'different',
            removeWhiteSpace: false,
            removeLineBreaks: false,
            isSplitView: true,
            removeTaameiMikra: true,
            removeNikud: true,
            isIndependentScroll: false,
        },
        cloudViewerData: {
            isLoading: false,
            textBlock1Data: [],
            textBlock2Data: [],
            textBlock1: "",
            textBlock2: "",
            sourceList1: [],
            sourceList2: [],
            wordsToSkip: [],
            otherWordsToSkip: [""],
            maxObjects: 100,
            maxObjectsDisplay: '∞',
            minFrequency: 1,
            minFrequencyDisplay: 1,
            cloudData1: null,
            cloudData2: null,
        },
        LCSData: {
            isLoading: false,
            rowData: [],
            matchIndicesByPasuk: {},
            direction: 'forward',
            matchDistance: [1, 100],
            wordLength:  [1, 11],
            wordsToSkip: [],
            otherWordsToSkip: [""],
            fontSizeLevel: 1
        },
        commonWordsData: {
            isLoading: false,
            rowData: [],
            nGrams: 1,
            sortResultsByChosen: 1,
            sortResultsByCurrent: 1,
            resultsToDisplay: 1,
        },
        // text1Api: null,
        // text1ColumnApi: null,
        text2Api: null,
        text2ColumnApi: null,
    };

    this.refresh = this.refresh.bind(this);
    this.text1GridReady = this.text1GridReady.bind(this);
    this.addText1Row = this.addText1Row.bind(this);
    this.extAddText1Row = this.extAddText1Row.bind(this);
    this.deleteText1Row = this.deleteText1Row.bind(this);
    this.extDeleteText1Row = this.extDeleteText1Row.bind(this);
    this.extModifyText1Row = this.extModifyText1Row.bind(this);
    this.modifyText1Row = this.modifyText1Row.bind(this);
    this.text2GridReady = this.text2GridReady.bind(this);
    this.addText2Row = this.addText2Row.bind(this);
    this.extAddText2Row = this.extAddText2Row.bind(this);
    this.deleteText2Row = this.deleteText2Row.bind(this);
    this.extDeleteText2Row = this.extDeleteText2Row.bind(this);
    this.extModifyText2Row = this.extModifyText2Row.bind(this);
    this.modifyText2Row = this.modifyText2Row.bind(this);
    this.getRenderVals = this.getRenderVals.bind(this);
    this.handleCopyLink = this.handleCopyLink.bind(this);
    this.handleText1DialogOpen = this.handleText1DialogOpen.bind(this);
    this.handleText1DialogClose = this.handleText1DialogClose.bind(this);
    this.handleText2DialogOpen = this.handleText2DialogOpen.bind(this);
    this.handleText2DialogClose = this.handleText2DialogClose.bind(this);
    this.handleResultsToDisplayChange = this.handleResultsToDisplayChange.bind(this);
    this.handleNGramsChange = this.handleNGramsChange.bind(this);
    this.handleSortResultsByChosenChange = this.handleSortResultsByChosenChange.bind(this);
    this.getGridColumns = this.getGridColumns.bind(this);
    this.getGridColumnClass = this.getGridColumnClass.bind(this);
    this.isCurrentSortColumn = this.isCurrentSortColumn.bind(this);
    this.onCellContextMenu = this.onCellContextMenu.bind(this);
    this.toggleUseKinuim = this.toggleUseKinuim.bind(this);
    this.toggleSearchAsQuote = this.toggleSearchAsQuote.bind(this);
    this.setComparatorType = this.setComparatorType.bind(this);
    this.extUpdateLCSData = this.extUpdateLCSData.bind(this);

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}
  }

  setComparatorType(e, val){
    if(val){
      if (val === "commonWord")
          localStorage.setItem("helpDialogStartupTopic", "commonTerms");
      else if (val === "LCS")
          localStorage.setItem("helpDialogStartupTopic", "lcs");
      else if (val === "diff")
          localStorage.setItem("helpDialogStartupTopic", "diff");

      this.setState({
        comparatorType: val
      })
    }
  }

  toggleSearchAsQuote(){
    this.setState({ searchAsQuote: !this.state.searchAsQuote })
  }

    isCurrentSortColumn(columnFieldName){
        if(this.state){
            if(this.state.sortResultsByCurrent === 1 && ['termInstances1', 'termInstances2', 'percentBlock1', 'percentBlock2'].includes(columnFieldName)){
                return true;
            } else if (this.state.sortResultsByCurrent === 2 && ['termInstancesTanach1', 'termInstancesTanach2', 'percentBlock1VsTanach', 'percentBlock2VsTanach'].includes(columnFieldName)) {
                return true;
            } else if (this.state.sortResultsByCurrent === 3 && ['termInstancesSeferGroup1', 'termInstancesSeferGroup2', 'percentBlock1VsSeferGroup', 'percentBlock2VsSeferGroup'].includes(columnFieldName)) {
                return true;
            }
        }
        return false;
    }

    getGridColumnClass(columnFieldName, params) {
        var cellClass = 'row-thin-small';
        if(columnFieldName === 'term'){
            var cellClass = 'row-header-large';
        } else if (columnFieldName === 'rowNum'){
            var cellClass = 'row-header-center';
        }
        if(this.isCurrentSortColumn(columnFieldName)){
            cellClass = cellClass.concat(' sort-column');
        }

        if(params){
            if(!params.data.termInstances1 || !params.data.termInstances2){
                cellClass = cellClass.concat(' outlier-row')

            }
        }
        return cellClass;
    }

    getGridColumns(){
        var fakeThis = this
        return [
                   {headerName: "% במסומנים", field: "percentBlock1VsSefer", width: 130, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock1VsSeferGroup", params)}} ,
                   {headerName: "בספרים המסומנים", field: "termInstancesSefer1", width: 121, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstancesSeferGroup1", params)}} ,
                   {headerName: "% בתנ\"ך", field: "percentBlock1VsTanach", width: 105, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock1VsTanach", params)}} ,
                   {headerName: "בתנ\"ך", field: "termInstancesTanach1", width: 84, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstancesTanach1", params)}} ,
                   {headerName: "% בקטעים", field: "percentBlock1", width: 117, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock1", params)}} ,
                   {headerName: "הופעות בקטעים", field: "termInstances1", width: 97, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstances1", params)}} ,
                   {headerName: "#", field: "rowNum", width: 60, headerClass: "ag-header-cell-center", cellClass: function(params) {return fakeThis.getGridColumnClass("rowNum", params)}, filter: 'agNumberColumnFilter'},
                   {headerName: "בטוי", field: "term", width: 130, cellClass: function(params) {return fakeThis.getGridColumnClass("term", params)}, cellRenderer: function(params) {return util.decorateNekudosTaamim(params.data.term);}},
                   {headerName: "הופעות בקטעים", field: "termInstances2", width: 97, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstances2", params)}} ,
                   {headerName: "% בקטעים", field: "percentBlock2", width: 117, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock2", params)}} ,
                   {headerName: "בתנ\"ך", field: "termInstancesTanach2", width: 84, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstancesTanach2", params)}} ,
                   {headerName: "% בתנ\"ך", field: "percentBlock2VsTanach", width: 105, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock2VsTanach", params)}} ,
                   {headerName: "בספרים המסומנים", field: "termInstancesSefer2", width: 121, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstancesSeferGroup2", params)}} ,
                   {headerName: "% במסומנים", field: "percentBlock2VsSefer", width: 130, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock2VsSeferGroup", params)}} ,
               ];
    }

    resultsGridReady = params => {
      this.resultsApi = params.api;
      this.resultsColumnApi = params.columnApi;
    };

    text1GridReady = params => {
        this.props.text1GridReady(params);
    //     this.setState({
    //         text1Api: params.api,
    //         text1ColumnApi: params.columnApi
    //       }, () => {
    //         this.state.text1Api.setRowData(this.props.text1Data);

    //         //following CSS classes seem to get overridden below in PlacementRenderer by placementGridLine and rangeGridLine
    //               this.state.text1Api.setColumnDefs(
    //                        [{headerName: this.props.translate("addLines"), headerClass: 'row-thin-center', field: "namedSection", width: 1168, cellClass: "row-thin-small", cellRendererFramework: PlacementRenderer, cellRendererParams:
    //                            {
    //                             extModifyPlacementRow: this.extModifyText1Row,
    //                             extAddPlacementRow: this.extAddText1Row,
    //                             extDeletePlacementRow: this.extDeleteText1Row,
    //                             translate: this.props.translate, //do I need to pass this here?
    //                         },
    //                   //cellClass: function(params) {return params.data.type ? 'placementGridLine' : 'rangeGridLine';},
    //                         }]
    //                   );
    //               if (this.props.text1Data.length > 0)
    //                   this.state.text1Api.setFocusedCell(0, "namedSection", null);
    //             //   var instances = this.refs.agGrid.gridOptions.api.getCellRendererInstances(params);
    //       })
    // //   this.text1Api = params.api;
    // //   this.text1ColumnApi = params.columnApi;


    };

    text2GridReady = params => {
        this.props.text2GridReady(params);
        // this.setState({
        //     text2Api: params.api,
        //     text2ColumnApi: params.columnApi
        //   }, () => {
        //     this.state.text2Api.setRowData(this.props.text2Data);

        //     //following CSS classes seem to get overridden below in PlacementRenderer by placementGridLine and rangeGridLine
        //           this.state.text2Api.setColumnDefs(
        //                    [{headerName: this.props.translate("addLines"), headerClass: 'row-thin-center', field: "namedSection", width: 1168, cellClass: "row-thin-small", cellRendererFramework: PlacementRenderer, cellRendererParams:
        //                        {
        //                         extModifyPlacementRow: this.extModifyText2Row,
        //                         extAddPlacementRow: this.extAddText2Row,
        //                         extDeletePlacementRow: this.extDeleteText2Row,
        //                         translate: this.props.translate, //do I need to pass this here?
        //                     },
        //               //cellClass: function(params) {return params.data.type ? 'placementGridLine' : 'rangeGridLine';},
        //                     }]
        //               );
        //           if (this.props.text2Data.length > 0)
        //               this.state.text2Api.setFocusedCell(0, "namedSection", null);
        //         //   var instances = this.refs.agGrid.gridOptions.api.getCellRendererInstances(params);
        //   })


//       this.text2Api = params.api;
//       this.text2ColumnApi = params.columnApi;
//       this.text2Api.setRowData(this.props.text2Data);

// //following CSS classes seem to get overridden below in PlacementRenderer by placementGridLine and rangeGridLine
//       this.text2Api.setColumnDefs(
//                [{headerName: this.props.translate("addLines"), headerClass: 'row-thin-center', field: "namedSection", width: 1168, cellClass: "row-thin-small", cellRendererFramework: PlacementRenderer, cellRendererParams:
//                    {
//                     extModifyPlacementRow: this.extModifyText2Row,
//                     extAddPlacementRow: this.extAddText2Row,
//                     extDeletePlacementRow: this.extDeleteText2Row,
//                     translate: this.props.translate, //do I need to pass this here?
//                 },
//           //cellClass: function(params) {return params.data.type ? 'placementGridLine' : 'rangeGridLine';},
//                 }]
//           );
//       if (this.props.text2Data.length > 0)
//           this.text2Api.setFocusedCell(0, "namedSection", null);
//       var instances = this.refs.agGrid.gridOptions.api.getCellRendererInstances(params);
    };

    addText1Row() {
        this.props.addText1Row()
        // var newRow = {type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []};
        // var newData = this.props.text1Data;
        // newData.push(newRow);
        // this.setState( {text1Data: newData}, () => {this.state.text1Api.updateRowData({ add: [newRow] })});
    }

    addText2Row() {
        this.props.addText2Row();
        // var newRow = {type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []};
        // var newData = this.props.text2Data;
        // newData.push(newRow);
        // this.setState( {text2Data: newData}, () => {this.state.text2Api.updateRowData({ add: [newRow] })});
    }

    extAddText1Row = () => {
        this.props.addText1Row();
    }

    extAddText2Row = () => {
        this.props.addText2Row();
    }

    deleteText1Row(extState) {
        this.props.deleteText1Row(extState);
        // var newWordCountData = this.props.wordCountData;
        // newWordCountData.termTotal1Accurate = false;
        // this.props.updateWordCountData(newWordCountData)
        // // this.setState({termTotal1Accurate: false, wordCountData: newWordCountData})
        // var selected = this.state.text1Api.getFocusedCell();
        // var i = selected.rowIndex;
        // if (i == this.state.text1Api.getLastDisplayedRow()) { //then just clear
        //     this.modifyText1Row(extState);
        //     //selected.setSelected(false);
        // }
        // else {
        //     var selectedData = this.state.text1Api.getSelectedRows();
        //     var newData = this.props.text1Data;
        //     newData.splice(i,1);
        //     this.setState( {text1Data: newData}, () => {this.state.text1Api.updateRowData({ remove: selectedData }) });
        // }
    }

    deleteText2Row(extState) {
        this.props.deleteText2Row(extState);
        // var newWordCountData = this.props.wordCountData;
        // newWordCountData.termTotal2Accurate = false;
        // this.props.updateWordCountData(newWordCountData);
        // // this.setState({termTotal2Accurate: false, wordCountData: newWordCountData})
        // var selected = this.state.text2Api.getFocusedCell();
        // var i = selected.rowIndex;
        // if (i == this.state.text2Api.getLastDisplayedRow()) { //then just clear
        //     this.modifyText2Row(extState);
        //     //selected.setSelected(false);
        // }
        // else {
        //     var selectedData = this.state.text2Api.getSelectedRows();
        //     var newData = this.props.text2Data;
        //     newData.splice(i,1);
        //     this.setState( {text2Data: newData}, () => {this.state.text2Api.updateRowData({ remove: selectedData }) });
        // }
    }

    extDeleteText1Row = (extState) => {
        this.props.deleteText1Row(extState);
    }

    extDeleteText2Row = (extState) => {
        this.props.deleteText2Row(extState);
    }

    extModifyText1Row = (extState) => {
        this.props.modifyText1Row(extState);
    }

    extModifyText2Row = (extState) => {
        this.props.modifyText2Row(extState);
    }

    modifyText1Row(extState) {
        this.props.modifyText1Row(extState);
        // var newWordCountData = this.props.wordCountData;
        // newWordCountData.termTotal1Accurate = false;
        // // this.setState({termTotal1Accurate: false, wordCountData: newWordCountData})
        // this.props.updateWordCountData(newWordCountData);

        // var isAddRow = (extState.type && extState.val4 != this.props.text1Data.val4 && extState.val4 != 0) || (!extState.type && extState.val3 != this.props.text1Data.val3 && extState.val3 != 0 && extState.val6 != this.state.text1Data.val6 && extState.val6 != 0);
        // var selected = this.state.text1Api.getFocusedCell();

        // var i = selected.rowIndex;
        // var rowValues = {type: extState.type, val1: extState.val1, val2: extState.val2, val3: extState.val3, val4: extState.val4, val5: extState.val5, val6: extState.val6,
        //                                       valList1: extState.valList1, valList2: extState.valList2, valList3: extState.valList3, valList4: extState.valList4, valList5: extState.valList5, valList6: extState.valList6 };
        // var newData = this.props.text1Data;
        // newData[i] = rowValues;

        // this.setState( {text1Data: newData}, () => {
        //     if (i == this.state.text1Api.getLastDisplayedRow() && isAddRow) {
        //         this.addText1Row();
        //     }
        // });

    }

    modifyText2Row(extState) {
        this.props.modifyText2Row(extState);
        // var newWordCountData = this.props.wordCountData;
        // newWordCountData.termTotal2Accurate = false;
        // // this.setState({termTotal2Accurate: false, wordCountData: newWordCountData})
        // this.props.updateWordCountData(newWordCountData);

        // var isAddRow = (extState.type && extState.val4 != this.props.text2Data.val4 && extState.val4 != 0) || (!extState.type && extState.val3 != this.props.text2Data.val3 && extState.val3 != 0 && extState.val6 != this.state.text2Data.val6 && extState.val6 != 0);
        // var selected = this.state.text2Api.getFocusedCell();

        // var i = selected.rowIndex;
        // var rowValues = {type: extState.type, val1: extState.val1, val2: extState.val2, val3: extState.val3, val4: extState.val4, val5: extState.val5, val6: extState.val6,
        //                                       valList1: extState.valList1, valList2: extState.valList2, valList3: extState.valList3, valList4: extState.valList4, valList5: extState.valList5, valList6: extState.valList6 };
        // var newData = this.props.text2Data;
        // newData[i] = rowValues;

        // this.setState( {text2Data: newData}, () => {
        //     if (i == this.state.text2Api.getLastDisplayedRow() && isAddRow) {
        //         this.addText2Row();
        //     }
        // });
    }

    handleCopyLink() {
        this.props.handleCopyLink("compare");
    }

    handleText1DialogOpen() {
        this.setState({ text1DialogOpen: true })
    }

    handleText1DialogClose() {
        this.setState({ text1DialogOpen: false })
    }

    handleText2DialogOpen() {
        this.setState({ text2DialogOpen: true })
    }

    handleText2DialogClose() {
        this.setState({ text2DialogOpen: false })
    }

   getRenderVals(textDataRow){
        var renderVal1, renderVal2, renderVal3, renderVal4, renderVal5, renderVal6
        var renderVals
        if(textDataRow.type){ //is named section
            var selectedVal1 = textDataRow.valList1.filter(obj => {return obj.id === textDataRow.val1});
            var selectedVal2 = textDataRow.valList2.filter(obj => {return obj.id === textDataRow.val2});
            var selectedVal3 = textDataRow.valList3.filter(obj => {return obj.id === textDataRow.val3});
            var selectedVal4 = textDataRow.valList4.filter(obj => {return obj.id === textDataRow.val4});
            var selectedVal5 = textDataRow.valList5.filter(obj => {return obj.id === textDataRow.val5});

            if(selectedVal1[0]){ renderVal1 = selectedVal1[0].value+' > '}
            if(selectedVal2[0]){ renderVal2 = selectedVal2[0].value+' > '}
            if(selectedVal3[0]){ renderVal3 = selectedVal3[0].value+' > '}
            if(selectedVal4[0]){ renderVal4 = selectedVal4[0].value+' > '}
            if(selectedVal5[0]){ renderVal5 = selectedVal5[0].value+' > '}

            var renderVals = [renderVal1, renderVal2, renderVal3, renderVal4, renderVal5]

            for(let i = 4; i >= 0; i--){
                if(renderVals[i]){
                    renderVals[i] = renderVals[i].substring(0, renderVals[i].length - 3)
                    break
                }
                //if it is defined, set the one after it as the last value
            }

        } else { //is pasuk range
            var selectedVal1 = textDataRow.valList1.filter(obj => {return obj.seferId === textDataRow.val1});
            var selectedVal2 = textDataRow.valList2.filter(obj => {return obj.perekNum === textDataRow.val2});
            var selectedVal3 = textDataRow.valList3.filter(obj => {return obj.pasukNum === textDataRow.val3});
            var selectedVal4 = textDataRow.valList4.filter(obj => {return obj.seferId === textDataRow.val4});
            var selectedVal5 = textDataRow.valList5.filter(obj => {return obj.perekNum === textDataRow.val5});
            var selectedVal6 = textDataRow.valList6.filter(obj => {return obj.pasukNum === textDataRow.val6});

            if(selectedVal1[0]){ renderVal1 = selectedVal1[0].seferName}
            if(selectedVal2[0]){ renderVal2 = selectedVal2[0].perekValue}
            if(selectedVal3[0]){ renderVal3 = selectedVal3[0].pasukValue}
            if(selectedVal4[0]){ renderVal4 = selectedVal4[0].seferName}
            if(selectedVal5[0]){ renderVal5 = selectedVal5[0].perekValue}
            if(selectedVal6[0]){ renderVal6 = selectedVal6[0].pasukValue}

            renderVals = [renderVal1, renderVal2, renderVal3, renderVal4, renderVal5, renderVal6]
        }

        return renderVals
   }

   handleResultsToDisplayChange(e){
        this.setState({resultsToDisplay: e.target.value})
   }

    handleNGramsChange(e){
        this.setState({nGrams: e.target.value})
    }

    handleSortResultsByChosenChange(e){
        this.setState({sortResultsByChosen: e.target.value})
    }

    extUpdateDiffData(diffData, callback){
        var newDiffData = this.state.diffData;
        var newWordCountData = this.props.wordCountData;
        if(diffData.rowData !== undefined){
            newDiffData.rowData = diffData.rowData
        }
        if(diffData.isLoading !== undefined){
            newDiffData.isLoading = diffData.isLoading
        }
        if(diffData.textBlock1Data !== undefined){
            newDiffData.textBlock1Data = diffData.textBlock1Data
        }
        if(diffData.textBlock2Data !== undefined){
            newDiffData.textBlock2Data = diffData.textBlock2Data
        }
        if(diffData.textBlock1 !== undefined){
            newDiffData.textBlock1 = diffData.textBlock1
        }
        if(diffData.sourceList1 !== undefined){
            newDiffData.sourceList1 = diffData.sourceList1
        }
        if(diffData.textBlock2 !== undefined){
            newDiffData.textBlock2 = diffData.textBlock2
        }
        if(diffData.sourceList2 !== undefined){
            newDiffData.sourceList2 = diffData.sourceList2
        }
        if(diffData.diffUnit !== undefined){
            newDiffData.diffUnit = diffData.diffUnit
        }
        if(diffData.highlightCondition !== undefined){
            newDiffData.highlightCondition = diffData.highlightCondition
        }
        if(diffData.removeWhiteSpace !== undefined){
            newDiffData.removeWhiteSpace = diffData.removeWhiteSpace
        }
        if(diffData.removeLineBreaks !== undefined){
            newDiffData.removeLineBreaks = diffData.removeLineBreaks
        }
        if(diffData.isSplitView !== undefined){
            newDiffData.isSplitView = diffData.isSplitView
        }
        if(diffData.removeTaameiMikra !== undefined){
            newDiffData.removeTaameiMikra = diffData.removeTaameiMikra
        }
        if(diffData.removeNikud !== undefined){
            newDiffData.removeNikud = diffData.removeNikud
        }
        if(diffData.isIndependentScroll !== undefined){
            newDiffData.isIndependentScroll = diffData.isIndependentScroll
        }

        if(diffData.termTotal1Accurate !== undefined){
            newWordCountData.termTotal1Accurate = diffData.termTotal1Accurate
        }
        if(diffData.termTotal2Accurate !== undefined){
            newWordCountData.termTotal2Accurate = diffData.termTotal2Accurate
        }
        if(diffData.totalTerms1 !== undefined){
            newWordCountData.totalTerms1 = diffData.totalTerms1
        }
        if(diffData.totalTerms2 !== undefined){
            newWordCountData.totalTerms2 = diffData.totalTerms2
        }

        this.props.updateWordCountData(newWordCountData);
        this.setState({
            diffData: newDiffData,
            // wordCountData: newWordCountData
        }, () => {

            if(callback){
                callback();
            }
        })
    }

    
    extUpdateCloudViewerData(cloudViewerData, callback){
        var newCloudViewerData = this.state.cloudViewerData;
        var newWordCountData = this.props.wordCountData;
        if(cloudViewerData.rowData !== undefined){
            newCloudViewerData.rowData = cloudViewerData.rowData
        }
        if(cloudViewerData.isLoading !== undefined){
            newCloudViewerData.isLoading = cloudViewerData.isLoading
        }
        if(cloudViewerData.textBlock1Data !== undefined){
            newCloudViewerData.textBlock1Data = cloudViewerData.textBlock1Data
        }
        if(cloudViewerData.textBlock2Data !== undefined){
            newCloudViewerData.textBlock2Data = cloudViewerData.textBlock2Data
        }
        if(cloudViewerData.textBlock1 !== undefined){
            newCloudViewerData.textBlock1 = cloudViewerData.textBlock1
        }
        if(cloudViewerData.sourceList1 !== undefined){
            newCloudViewerData.sourceList1 = cloudViewerData.sourceList1
        }
        if(cloudViewerData.textBlock2 !== undefined){
            newCloudViewerData.textBlock2 = cloudViewerData.textBlock2
        }
        if(cloudViewerData.sourceList2 !== undefined){
            newCloudViewerData.sourceList2 = cloudViewerData.sourceList2
        }
        if(cloudViewerData.wordsToSkip !== undefined){
            newCloudViewerData.wordsToSkip = cloudViewerData.wordsToSkip
        }
        if(cloudViewerData.otherWordsToSkip !== undefined){
            newCloudViewerData.otherWordsToSkip = cloudViewerData.otherWordsToSkip
        }

        if(cloudViewerData.maxObjects !== undefined){
            newCloudViewerData.maxObjects = cloudViewerData.maxObjects
        }
        if(cloudViewerData.maxObjectsDisplay !== undefined){
            newCloudViewerData.maxObjectsDisplay = cloudViewerData.maxObjectsDisplay
        }
        if(cloudViewerData.minFrequency !== undefined){
            newCloudViewerData.minFrequency = cloudViewerData.minFrequency
        }
        if(cloudViewerData.minFrequencyDisplay !== undefined){
            newCloudViewerData.minFrequencyDisplay = cloudViewerData.minFrequencyDisplay
        }

        if(cloudViewerData.cloudData1 !== undefined){
            newCloudViewerData.cloudData1 = cloudViewerData.cloudData1
        }
        if(cloudViewerData.cloudData2 !== undefined){
            newCloudViewerData.cloudData2 = cloudViewerData.cloudData2
        }

        if(cloudViewerData.termTotal1Accurate !== undefined){
            newWordCountData.termTotal1Accurate = cloudViewerData.termTotal1Accurate
        }
        if(cloudViewerData.termTotal2Accurate !== undefined){
            newWordCountData.termTotal2Accurate = cloudViewerData.termTotal2Accurate
        }
        if(cloudViewerData.totalTerms1 !== undefined){
            newWordCountData.totalTerms1 = cloudViewerData.totalTerms1
        }
        if(cloudViewerData.totalTerms2 !== undefined){
            newWordCountData.totalTerms2 = cloudViewerData.totalTerms2
        }

        this.props.updateWordCountData(newWordCountData);
        this.setState({
            cloudViewerData: newCloudViewerData,
        }, () => {

            if(callback){
                callback();
            }
        })
    }

    extUpdateLCSData(LCSData, callback){
        var newLCSData = this.state.LCSData;
        var newWordCountData = this.props.wordCountData;
        if(LCSData.isLoading !== undefined){
            newLCSData.isLoading = LCSData.isLoading
        }
        if(LCSData.columnDefs !== undefined){
            newLCSData.columnDefs = LCSData.columnDefs
        }
        if(LCSData.rowData !== undefined){
            newLCSData.rowData = LCSData.rowData
        }
        if(LCSData.matchIndicesByPasuk !== undefined){
            newLCSData.matchIndicesByPasuk = LCSData.matchIndicesByPasuk
        }
        if(LCSData.direction !== undefined){
            newLCSData.direction = LCSData.direction
        }
        if(LCSData.matchDistance !== undefined){
            newLCSData.matchDistance = LCSData.matchDistance
        }
        if(LCSData.wordLength !== undefined){
            newLCSData.wordLength = LCSData.wordLength
        }
        if(LCSData.wordsToSkip !== undefined){
            newLCSData.wordsToSkip = LCSData.wordsToSkip
        }
        if(LCSData.otherWordsToSkip !== undefined){
            newLCSData.otherWordsToSkip = LCSData.otherWordsToSkip
        }
        if(LCSData.fontSizeLevel !== undefined){
            newLCSData.fontSizeLevel = LCSData.fontSizeLevel
        }

        if(LCSData.totalTerms1 !== undefined){
            newWordCountData.totalTerms1 = LCSData.totalTerms1
        }
        if(LCSData.totalTerms2 !== undefined){
            newWordCountData.totalTerms2 = LCSData.totalTerms2
        }
        if(LCSData.termTotal1Accurate !== undefined){
            newWordCountData.termTotal1Accurate = LCSData.termTotal1Accurate
        }
        if(LCSData.termTotal2Accurate !== undefined){
            newWordCountData.termTotal2Accurate = LCSData.termTotal2Accurate
        }

        this.props.updateWordCountData(newWordCountData);
        this.setState({
            LCSData: newLCSData
        }, () => {

            if(callback){
                callback();
            }
        })
    }

    extUpdateCommonWordsData(commonWordsData, callback){
        var newCommonWordsData = this.state.commonWordsData;
        var newWordCountData = this.props.wordCountData;
        if(commonWordsData.nGrams !== undefined){
            newCommonWordsData.nGrams = commonWordsData.nGrams
        }
        if(commonWordsData.sortResultsByChosen !== undefined){
            newCommonWordsData.sortResultsByChosen = commonWordsData.sortResultsByChosen
        }
        if(commonWordsData.sortResultsByCurrent !== undefined){
            newCommonWordsData.sortResultsByCurrent = commonWordsData.sortResultsByCurrent
        }
        if(commonWordsData.resultsToDisplay !== undefined){
            newCommonWordsData.resultsToDisplay = commonWordsData.resultsToDisplay
        }
        if(commonWordsData.isLoading !== undefined){
            newCommonWordsData.isLoading = commonWordsData.isLoading
        }
        if(commonWordsData.rowData !== undefined){
            newCommonWordsData.rowData = commonWordsData.rowData
        }

        if(commonWordsData.totalTerms1 !== undefined){
            newWordCountData.totalTerms1 = commonWordsData.totalTerms1
        }
        if(commonWordsData.totalTerms2 !== undefined){
            newWordCountData.totalTerms2 = commonWordsData.totalTerms2
        }
        if(commonWordsData.termTotal1Accurate !== undefined){
            newWordCountData.termTotal1Accurate = commonWordsData.termTotal1Accurate
        }
        if(commonWordsData.termTotal2Accurate !== undefined){
            newWordCountData.termTotal2Accurate = commonWordsData.termTotal2Accurate
        }
        
        this.props.updateWordCountData(newWordCountData);
        this.setState({
            commonWordsData: newCommonWordsData,
            // wordCountData: newWordCountData
        }, () => {

            if(callback){
                callback();
            }
        })
    }

    async componentDidMount() {
        if(this.props.extSearchDefData==="S") this.props.extSetBeta()
        this.setLanguage(localStorage.getItem("language"));
        this.props.setHelpTopic("textComparatorOptions");
    }

  async refresh() {
    this.setState({ isLoading: true })
    var baseParams = new BaseParameters();
    var bogusParams = new BaseParameters();
    baseParams.nGrams = this.state.nGrams;
    baseParams.sortResultsBy = this.state.sortResultsByChosen;
    baseParams.resultsToDisplay = this.state.resultsToDisplay;
    baseParams.source = 'bhsWebApp';

    var filterIds = encodeFilterIds(this.props.extFilterData);
    filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
    filterIds = util.right("00000000000"+filterIds,11);

    baseParams.filterIds = filterIds;
    baseParams.morphInflections = this.props.extSearchDefData; //str

    var e, i;
    for (i = 0; i < this.props.text1Data.length-1; i++) { //last position in placementRowData is never populated
        e = this.props.text1Data[i];
        if (e.type)
            baseParams.namedLocations.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
        else
            baseParams.pasukRangeLocations.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
    }

    for (i = 0; i < this.props.text2Data.length-1; i++) { //last position in placementRowData is never populated
        e = this.props.text2Data[i];
        if (e.type)
            baseParams.namedLocations2.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
        else
            baseParams.pasukRangeLocations2.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
    }

    const colDefs = this.getGridColumns();

    const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getTextComparison', {method: 'post', body: JSON.stringify(baseParams)});
    const body = await response.json();


    var totalTerms1 = this.state.totalTerms1;
    var totalTerms2 = this.state.totalTerms2;
    if(body[0]){
        totalTerms1 = body[0].totalBlock1;
        totalTerms2 = body[0].totalBlock2;
    }

    var rowData = body;
    //reformat
    for(let i = 0; i < rowData.length; i++){
        rowData[i].termInstances1 = (!!rowData[i].termInstances1) ? rowData[i].termInstances1 : 0;
        rowData[i].termInstances2 = (!!rowData[i].termInstances2) ? rowData[i].termInstances2 : 0;

        rowData[i].percentBlock1 = this.formatPercentages(rowData[i].percentBlock1);
        rowData[i].percentBlock2 = this.formatPercentages(rowData[i].percentBlock2);
        rowData[i].percentBlock1VsTanach = this.formatPercentages(rowData[i].percentBlock1VsTanach);
        rowData[i].percentBlock2VsTanach = this.formatPercentages(rowData[i].percentBlock2VsTanach);
        rowData[i].percentBlock1VsSefer = this.formatPercentages(rowData[i].percentBlock1VsSefer);
        rowData[i].percentBlock2VsSefer = this.formatPercentages(rowData[i].percentBlock2VsSefer);
    }

    this.setState({rowData: []}, () => {
        this.setState({ sortResultsByCurrent: baseParams.sortResultsBy, rowData: rowData, isLoading: false, totalTerms1: totalTerms1, totalTerms2: totalTerms2, termTotal1Accurate: true, termTotal2Accurate: true });
    })
  }

  formatPercentages(rawPercent){
        var roundedPercent = `${Math.round(100*rawPercent)/100}`


        if(roundedPercent.indexOf('.') === -1 || !roundedPercent){
            roundedPercent = `${roundedPercent || 0}.00%`
        } else if(roundedPercent.indexOf('.') === roundedPercent.length-2){
            roundedPercent = roundedPercent.concat('0%')
        } else {
            roundedPercent = roundedPercent.concat('%')
        }

        return roundedPercent
//        var decimalCount = 0;
//        for (let i = roundedPercent.length-1; i >= 0; i--){
//            if(roundedPercent[i] !== '.'){
//                decimalCount ++
//            } else if (roundedPercent[i] === '.'){
//                var zeroesToAdd = 2-decimalCount;
//                for(let j = 1; j <= zeroesToAdd; j++ ){
//                    roundedPercent = roundedPercent.concat('0');
//                }
//                roundedPercent = roundedPercent.concat('%');
//                return roundedPercent;
//            }
//        }
        //if no decimal
//        return `${roundedPercent || 0}.00%`
  }

onCellContextMenu(e){
        this.setState({useKinuim: false, includeMekorot: false})
        const { innerWidth } = window;
        //style context menu
        var submenus = document.getElementsByClassName('react-contexify__submenu');
        for(let i = 0; i < submenus.length; i++){
            var submenu = submenus[i];
            var submenuWidth = parseInt(submenu.style.minWidth.slice(0, submenu.style.minWidth.length-2));
            if(this.props.activeLanguage.code === 'he'){
                if(e.event.clientX > submenuWidth){
                    submenu.style.left = '';
                    submenu.style.right = '100%';
                } else {
                    submenu.style.left = '100%';
                    submenu.style.right = '';
                }
            } else if(this.props.activeLanguage.code === 'en'){
                if(innerWidth-e.event.clientX-300 > submenuWidth){
                    submenu.style.left = '100%';
                    submenu.style.right = '';
                } else {
                    submenu.style.left = '';
                    submenu.style.right = '100%';
                }
            }
        }


        //set text to copy
        var textToCopy = '';

        var focusedCellRowIndex = null ;
        if(this.resultsApi.getFocusedCell()){
            var focusedCellRowIndex = this.resultsApi.getFocusedCell().rowIndex;
        }
        var focusedCellRowText = null;
        if((typeof(focusedCellRowIndex) === 'number') && this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex]){
            focusedCellRowText = this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].data.text;
        }

        //focused = row of focused cell; highlighted = highlighted text; selected = all selected rows;
        if(this.resultsApi.getFocusedCell() && !window.getSelection().toString()){
            textToCopy = 'bitui';
        } else if(window.getSelection().toString()){
            textToCopy = 'highlighted';
        }

        this.setState({textToCopy})
    }

    toggleUseKinuim(){
        this.setState({ useKinuim: !this.state.useKinuim })
    }

  render() {
    const { classes } = this.props;
    const text1Data = this.props.text1Data;
    const text2Data = this.props.text2Data;
    const {rowData, isLoading} = this.state;
    const loadingText = this.props.translate("loading");
    const commonWords = this.props.translate("commonWords");
    const LCS = this.props.translate("LCS");
    const diff = this.props.translate("diff");
    const objectClouds = this.props.translate("objectClouds");
    const homePage = this.props.translate("homePage");
    const copyLink = this.props.translate('copyLink');
    const fakeThis = this;

    var viewerTableHeightNum = parseInt(this.props.viewerHeight.substring(0, this.props.viewerHeight.length - 2)) - 159;
    if(this.props.openResults.length > 0){
        viewerTableHeightNum -= 12;
    }
    const viewerTableHeight = `${viewerTableHeightNum}px`

    var directionStyling = {};
    var langDirection = 'ltr'
    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
        directionStyling = {direction: 'rtl', textAlign: 'center'};
        langDirection = 'rtl';
    }

    var totalTerms1Paper = <Paper elevation={1} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0'}}>
           <Typography variant='body1'>{this.props.translate("totalTerms")}: <b>{this.state.totalTerms1}</b></Typography>
       </Paper>
    if(!this.state.termTotal1Accurate){
        totalTerms1Paper = <Paper elevation={0} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0', backgroundColor: '#d7d7d7' }}>
           <Typography variant='body1' style={{color: "#9f9f9f"}} >{this.props.translate("totalTerms")}: <b>{this.state.totalTerms2}</b></Typography>
       </Paper>
    }

    var totalTerms2Paper = <Paper elevation={1} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0'}}>
           <Typography variant='body1'>{this.props.translate("totalTerms")}: <b>{this.state.totalTerms2}</b></Typography>
       </Paper>
    if(!this.state.termTotal2Accurate){
        totalTerms2Paper = <Paper elevation={0} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0', backgroundColor: '#d7d7d7' }}>
           <Typography variant='body1' style={{color: "#9f9f9f"}} >{this.props.translate("totalTerms")}: <b>{this.state.totalTerms2}</b></Typography>
       </Paper>
    }

    return (
      <span helpTopic="textComparatorOptions">
        <div style={{direction: langDirection}}>
          <span className="medCaption" align="center"><Translate id="textComparator.caption" /></span>
          <div className='parsha-viewer-text-display-controls' style={{display: `inline`}}>
            <div className='parsha-viewer-select-text-display-type'>
              <div className={classes.toggleContainer} className="tanachViewerToggleButtonContainer">
                <ToggleButtonGroup className="tanachViewerToggleButtonGroup" value={this.state.comparatorType} exclusive onChange={(e, val) => {this.setComparatorType(e, val)}}>
                  <ToggleButton className="text-comparator-viewer-toggle-button" value="commonWord" helpTopic="commonTerms">{commonWords}</ToggleButton>
                  <ToggleButton className="text-comparator-viewer-toggle-button" value="LCS" helpTopic="lcs">{LCS}</ToggleButton>
                  <ToggleButton className="text-comparator-viewer-toggle-button" value="diff" helpTopic="diff">{diff}</ToggleButton>
                  <ToggleButton className="text-comparator-viewer-toggle-button" value="clouds" helpTopic="objectCloudComparator">{objectClouds}</ToggleButton>
                </ToggleButtonGroup>
              </div>
              <IconButton className="goHome" title={this.props.translate("homePage")} onClick={()=>{this.props.extCloseDockedViewer()}}><HomeIcon /></IconButton>
              <IconButton className="goHome" title={copyLink} onClick={()=>{this.handleCopyLink()}}><LinkIcon /></IconButton>

            </div>
          </div>
        </div>

        {this.state.comparatorType === "commonWord" &&
          <CommonWordFinder extSearchDefData={this.props.extSearchDefData} extFilterData={this.props.extFilterData}
            text1Data={this.props.text1Data} text2Data={this.props.text2Data}
            extSearchDefData={this.props.extSearchDefData} extCopySelectedToSearchbar={this.props.extCopySelectedToSearchbar}
            extSearchWithSelected={this.props.extSearchWithSelected} viewerHeight={this.props.viewerHeight} openResults={this.props.openResults}
            extUpdateCommonWordsData={(commonWordsData, callback) => {this.extUpdateCommonWordsData(commonWordsData, callback)}} commonWordsData={this.state.commonWordsData} 
            wordCountData={this.props.wordCountData} morphInflections={this.props.extSearchDefData} isFillingSelector={this.props.isFillingSelector}
            extSetTextSource={this.props.extSetTextSource} textSource={this.props.textSource}
            text1GridReady={this.props.text1GridReady} text1Api={this.props.text1Api} addText1Row={this.props.addText1Row}
            deleteText1Row={this.props.deleteText1Row} modifyText1Row={this.props.modifyText1Row}
            text2GridReady={this.props.text2GridReady} text2Api={this.state.text2Api} addText2Row={this.props.addText2Row}
            deleteText2Row={this.props.deleteText2Row} modifyText2Row={this.props.modifyText2Row} extOpenLexiconViewer={this.props.extOpenLexiconViewer}
            />

        }

        {this.state.comparatorType === "LCS" &&
          <LCSViewer extSearchDefData={this.props.extSearchDefData} extFilterData={this.props.extFilterData}
            text1Data={this.props.text1Data} text2Data={this.props.text2Data}
            extSearchDefData={this.props.extSearchDefData} extCopySelectedToSearchbar={this.props.extCopySelectedToSearchbar}
            extSearchWithSelected={this.props.extSearchWithSelected} viewerHeight={this.props.viewerHeight} openResults={this.props.openResults}
            extUpdateLCSData={(LCSData, callback) => {this.extUpdateLCSData(LCSData, callback)}} LCSData={this.state.LCSData} 
            wordCountData={this.props.wordCountData} isFillingSelector={this.props.isFillingSelector}
            toggleTanachViewer={((boolean, pasukId, resultsApi) => {this.props.toggleTanachViewer(boolean, pasukId, resultsApi)})}
            extSetTextSource={this.props.extSetTextSource} morphInflections={this.props.extSearchDefData}  textSource={this.props.textSource}
            text1GridReady={this.props.text1GridReady} text1Api={this.props.text1Api} addText1Row={this.props.addText1Row}
            deleteText1Row={this.props.deleteText1Row} modifyText1Row={this.props.modifyText1Row}
            text2GridReady={this.props.text2GridReady} text2Api={this.state.text2Api} addText2Row={this.props.addText2Row}
            deleteText2Row={this.props.deleteText2Row} modifyText2Row={this.props.modifyText2Row} extOpenLexiconViewer={this.props.extOpenLexiconViewer}
            />
        }

        {this.state.comparatorType === "diff" &&
          <DiffViewer extSearchDefData={this.props.extSearchDefData} extFilterData={this.props.extFilterData}
            text1Data={this.props.text1Data} text2Data={this.props.text2Data} extFetchTaamParseData={this.props.extFetchTaamParseData}
            extSearchDefData={this.props.extSearchDefData} extCopySelectedToSearchbar={this.props.extCopySelectedToSearchbar}
            extSearchWithSelected={this.props.extSearchWithSelected} viewerHeight={this.props.viewerHeight} openResults={this.props.openResults} 
            toggleTanachViewer={((boolean, pasukId, resultsApi) => {this.props.toggleTanachViewer(boolean, pasukId, resultsApi)})} 
            extUpdateDiffData={(diffData, callback) => {this.extUpdateDiffData(diffData, callback)}} diffData={this.state.diffData} 
            wordCountData={this.props.wordCountData} isFillingSelector={this.props.isFillingSelector}  textSource={this.props.textSource}
            toggleTanachViewer={((boolean, pasukId, resultsApi) => {this.props.toggleTanachViewer(boolean, pasukId, resultsApi)})}
            extSetTextSource={this.props.extSetTextSource} extOpenTaamParser={this.props.extOpenTaamParser}
            text1GridReady={this.props.text1GridReady} text1Api={this.props.text1Api} addText1Row={this.props.addText1Row}
            deleteText1Row={this.props.deleteText1Row} modifyText1Row={this.props.modifyText1Row} morphInflections={this.props.extSearchDefData}
            text2GridReady={this.props.text2GridReady} text2Api={this.state.text2Api} addText2Row={this.props.addText2Row}
            deleteText2Row={this.props.deleteText2Row} modifyText2Row={this.props.modifyText2Row} extOpenLexiconViewer={this.props.extOpenLexiconViewer}
            />
        }

        {this.state.comparatorType === "clouds" &&
          <TextComparatorCloudViewer extOpenTaamParser={this.props.extOpenTaamParser}  extCloseTaamParser={this.props.extCloseTaamParser}
            extSearchDefData={this.props.extSearchDefData} extFilterData={this.props.extFilterData}
            text1Data={this.props.text1Data} text2Data={this.props.text2Data} extFetchTaamParseData={this.props.extFetchTaamParseData}
            extSearchDefData={this.props.extSearchDefData} extCopySelectedToSearchbar={this.props.extCopySelectedToSearchbar}
            extSearchWithSelected={this.props.extSearchWithSelected} viewerHeight={this.props.viewerHeight} openResults={this.props.openResults} 
            toggleTanachViewer={((boolean, pasukId, resultsApi) => {this.props.toggleTanachViewer(boolean, pasukId, resultsApi)})} 
            extUpdateCloudViewerData={(cloudViewerData, callback) => {this.extUpdateCloudViewerData(cloudViewerData, callback)}} cloudViewerData={this.state.cloudViewerData} 
            wordCountData={this.props.wordCountData} isFillingSelector={this.props.isFillingSelector}
            toggleTanachViewer={((boolean, pasukId, resultsApi) => {this.props.toggleTanachViewer(boolean, pasukId, resultsApi)})}
            extSetTextSource={this.props.extSetTextSource}  textSource={this.props.textSource}
            text1GridReady={this.props.text1GridReady} text1Api={this.props.text1Api} addText1Row={this.props.addText1Row}
            deleteText1Row={this.props.deleteText1Row} modifyText1Row={this.props.modifyText1Row}
            text2GridReady={this.props.text2GridReady} text2Api={this.state.text2Api} addText2Row={this.props.addText2Row}
            deleteText2Row={this.props.deleteText2Row} modifyText2Row={this.props.modifyText2Row} extOpenLexiconViewer={this.props.extOpenLexiconViewer}
            />
        }

      </span>
    );
  }
}

TextComparatorViewer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withLocalize(TextComparatorViewer));


/*
      <div className='sweet-loading'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={isLoading}
        />


      </div>
*/