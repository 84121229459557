import React, { Component } from 'react';

class TaamParser_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מנתח
              טעמים</span></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מטרה</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאפשר
            ניתוח חזותית
            ופירוט תחביר
            של פסוק על פי
            כללי טעמי
            המקרא.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>זה
            ישמש לסייע
            בהבנת
            המשמעות
            הפשוטה של
            פסוק.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
          </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בנוסף,
            באמצעות גדלי
            גופן והדגשת
            המפסיקים החזקים
            יותר של
            הפסוק, זה
            עלול גם לשפוך
            אור על דרשות
            חז"ל.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>השקת
                ה</span></u></b><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מנתח</span></u></b><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
                טעמים</span></u></b></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כאמור
            במקום אחר,
            ניתן להפעיל
            חלון זה
            מהתפריט
            הראשי.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הצופה
            יפתח ל</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בראשית</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> א:א.</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כאמור
            במקום אחר,
            ניתן להשיג את
            אותו הדבר על
            ידי לחיצה
            ימנית איפשהו
            בפסוק והפעלת
            הצופה מתפריט
            ההקשר.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תצוגה
            מדגמית ניתן
            לראות כאן:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={623} height={382} id="Picture 86" src="helpDialogImages/image073.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ניווט</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>זה
            דומה במקצת
            לדפדפן התנ"ך.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בחלק
            העליון של
            המנתח, יופיע
            קטע שייראה
            כמו מחצית
            מהאפשרות <b>טווח
              פסוקים</b>
            בכרטיסיית
            מיקום
            בהגדרות
            החיפוש
            המתקדם.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כאן קופצים
            למיקום חדש
            בתנ"ך על ידי
            בחירת ספר,
            פרק ופסוק.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בצד
            שמאל ומימין
            למעלה של
            המנתח (בדומה לדפדפן
            התנ"ך) ניתן
            לעבור לפסוק
            הבא או הקודם
            על ידי לחיצה
            על כפתורי </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>החץ </span><b><span lang="HE" style={{fontSize: '16.0pt', fontFamily: '"Arial",sans-serif'}}>&lt; </span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>או </span><b><span lang="HE" style={{fontSize: '16.0pt', fontFamily: '"Arial",sans-serif'}}>&gt;</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אם
            הצופה הושק
            מרשת תוצאות
            חיפוש או מכל
            רשימת פסוקים
            אחרת, ניתן
            לעבור גם
            לתוצאת הפסוק
            הבאה או
            הקודמת על ידי
            לחיצה על
            כפתורי החצים
            הכפולים </span><b><span lang="HE" style={{fontSize: '16.0pt', fontFamily: '"Arial",sans-serif'}}>&lt;&lt;</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> או </span><b><span lang="HE" style={{fontSize: '16.0pt', fontFamily: '"Arial",sans-serif'}}>&gt;&gt;</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אפשר
            לסגור את
            המנתח על ידי
            לחיצה על הכפתור
            "סגור" או על
            ידי לחיצה על
            מקום אחר על המסך
            מחוץ לחלון
            המנתח.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פריסה</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הגוף
            העיקרי של
            המנתח יכיל
            "עץ ניתוח" של
            פסוק בודד
            שמתחיל בפסוק
            שלם בחלקו
            העליון ואז מפרק
            אותו שוב ושוב
            לשני חלקים עד
            שתגיע ליחידה ברמה
            התחתונה
            שאינה יכולה
            להיפרק עוד
            יותר.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
          </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ניתן
            להרחיב את
            צמתי העץ על
            ידי לחיצה על
            צמתים עם סמל <b>+</b>
            וניתן לכווץ
            אותם על ידי
            לחיצה על
            צמתים עם סמל <b>-</b>.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מפתח
                להדגשה וגדלי
                גופן</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בהקדמה,
            מערכות של טעמי
            המקרא
            המשמשות ברוב
            תנ"ך ומשמשות
            בתהלים/משלי/איוב
            הן בעלות המושג
            שטעמים
            מסוימים
            נחשבים
            למפסיקים
            (למשל סוף פסוק
            ואתנחתא)
            המצביעים על
            הפסקה או
            הפרדה בין המילה
            המכילה את
            הטעם הזה והמילה
            הבאה וטעמים
            אחרים נחשבים
            למשרתים (למשל
            מרכא) המקשרים
            או מצטרפים
            למילה המכילה
            את הטעם הזה
            למילה הבאה.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>המפסיקים
            עצמם אינם
            מתפקדים
            באותה רמה אלא
            מחולקים
            לרמות שונות:
            מגבוה לנמוך
            הם קיסר, מלך,
            משנה, שליש
            ובמקרה של
            תהילים/משלי/איוב,
            יש את הרמה
            הנוספת,
            העצמאית של
            מפסיק נוסף
            לשמאול האתנחתא.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מקש
            הדגשת הצבעים
            בראש המנתח
            מציין את
            התחום או
            האזור של כל
            צומת בעץ
            שהאזור נמצא
            בבעלות הטעם
            השמאלי ביותר
            / הגבוה ביותר.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מקש
            גודל הגופן
            בראש הניתוח
            מציין את רמת
            הטעם המשויכת
            למילה המוצגת.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הערה:
              יש לראות בכך
              תכונה
              ניסיונית.</span></i><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span></i><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הניתוח
              שואף הכי טוב
              שאפשר לדבוק
              בחוקים המוגדרים
              בספר טעמי
              המקרא מאת רב
              מרדכי ברויאר,
              אך ישנם מקרים
              מיוחדים
              וחריגים
              מכללים שעדיין
              לא הובאו
              בחשבון כאן.</span></i><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span></i><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בנוסף,
              ישנן שיטות
              אחרות לפרש
              כיצד טעמי
              המקרא משפיע
              על התחביר של
              פסוקים (</span></i><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ראה
              אנציקלופדיה
              תלמודית כרך
              כ, ערך "טעמים"
              ו"נספח לערך
              טעמים"). </span></i><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>יש
              לקוות
              ששיפורים
              יבוצעו בעתיד.</span></i></p>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b>Taam Parser / </b><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מנתח
              טעמים</span></b></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Goal</u></b></p>
        <p className="MsoNormal">To visually analyze and breakdown the syntax of a pasuk
          according to the rules of <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעמי
            המקרא</span><span dir="LTR" /><span dir="LTR" />.
          This will serve to help one understand the simple meaning of a pasuk.&nbsp;
          Additionally, through the use of font sizes and highlighting the stronger mafsikim
          (breaking points) of the pasuk, it also might shed light on <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>דרשות
            חז"ל</span><span dir="LTR" /><span dir="LTR" />.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Launching the Taam Parser</u></b></p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />As mentioned elsewhere, one can launch this
          window from the Main Menu.&nbsp; The viewer will open up to <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בראשית
            א:א</span><span dir="LTR" /><span dir="LTR" />.</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />As mentioned elsewhere, the same can be
          accomplished by right-clicking somewhere in a pasuk and launching the viewer
          from the Context Menu.</p>
        <p className="MsoNormal"><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p className="MsoNormal">A sample view can be seen here:</p>
        <p className="MsoNormal"><img width={623} height={382} id="Picture 86" src="helpDialogImages/image073.jpg" /></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Navigation</u></b></p>
        <p className="MsoNormal">This is somewhat similar to the Tanach Viewer.&nbsp; On top of
          the parser, a section will appear that will look similar to the half of the <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טווח
              פסוקים</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>option in the Placement tab selection of
          the Advanced Search settings.&nbsp; Here one jumps to a new location in Tanach by
          selecting sefer, perek and pasuk. </p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">On the top left and right of the parser (similar to the
          Tanach Viewer), one can also move to the next or previous pasuk by clicking the
          <b><span style={{fontSize: '16.0pt'}}> &lt;</span></b> or <b><span style={{fontSize: '16.0pt'}}>&gt;</span></b> arrow buttons.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">If the viewer has been launched from a search results grid
          or any other listing of pesukim then one can also move to the next or previous pasuk
          result by clicking the <b><span style={{fontSize: '16.0pt'}}>&lt;&lt;</span></b> or
          <b><span style={{fontSize: '16.0pt'}}> &gt;&gt;</span></b> double-arrow buttons.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">One can close the parser by clicking on the <span dir="RTL" /><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />"סגור"</span><span dir="LTR" /><span dir="LTR" /> button or by simply clicking anywhere on
          the screen outside the parser window.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Layout</u></b></p>
        <p className="MsoNormal">The main body of the parser will contain a “parse-tree” of a
          single pasuk which starts with a complete pasuk at the top and then continually
          breaks things down again and again into two component sections where relevant
          until a bottom-level unit can’t be broken down anymore.&nbsp; The nodes of the tree
          can be expanded by clicking nodes with the <b><span style={{fontSize: '14.0pt'}}>+ </span></b>
          symbol and contracted by clicking nodes with the <b><span style={{fontSize: '14.0pt'}}>–</span></b> symbol.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Key to highlighting and font sizes</u></b></p>
        <p className="MsoNormal">In preface, the systems of <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעמי
            המקרא</span><span dir="LTR" /><span dir="LTR" />
          used in most of Tanach and used in Tehillim/Mishlei/Iyov possess the concept
          that certain <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעמים</span><span dir="LTR" /><span dir="LTR" /> are considered <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מפסיקים</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>(eg. <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}> סוף
            פסוק</span><span dir="LTR" /> <span dir="LTR" />
          and <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>אתנחתא</span><span dir="LTR" /><span dir="LTR" />) which indicate a pause or separation
          between the word containing that <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעם</span><span dir="LTR" /><span dir="LTR" /> and the following word and other <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעמים</span><span dir="LTR" /><span dir="LTR" /> are considered <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>משרתים</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>(eg. <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מרכא</span><span dir="LTR" /><span dir="LTR" />) which associate or join the word
          containing that <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעם</span><span dir="LTR" /><span dir="LTR" /> with the following word.&nbsp; The <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מפסיקים</span><span dir="LTR" /><span dir="LTR" /> themselves don’t function as the same
          level but are broken down into different levels: from high to low they are<span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>קיסר,
            מלך, משנה,
            שליש </span><span dir="LTR" /><span lang="HE"><span dir="LTR" />&nbsp;</span>and in the instance of Tehillim/Mishlei/Iyov, there is
          the added, standalone level of <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מפסיק
            נוסף לשמאול
            האתנחתא</span><span dir="LTR" /><span dir="LTR" />.&nbsp; </p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">The color highlighting key at the top of the parser indicates
          the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>תחום</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>or region of any node
          in the tree with the region being owned by its leftmost/highest level <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעם</span><span dir="LTR" /><span dir="LTR" />.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">The font-size key at the top of the parser indicates the
          level of <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעם</span><span dir="LTR" /><span dir="LTR" /> associated with the word displayed.</p>
        <p className="MsoNormal"><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p className="MsoNormal"><i>Note: This should be considered an experimental feature.&nbsp;
            The analysis/parsing strives as best as possible to stick to the rules defined
            in </i><i><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ספר
              טעמי המקרא
              מאת רב מרדכי
              ברויאר</span><span dir="LTR" /><span dir="LTR" />, but there are special cases and rule exceptions that are still
            unaccounted for here. Additionally, there are other methods of determining how </i><i><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעמי
              המקרא</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>affect the syntax of pesukim (</i><i><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ראה
              אנציקלופדיה
              תלמודית כרך
              כ, ערך "טעמים"
              ו"נספח לערך
              טעמים"</span><span dir="LTR" /><span dir="LTR" />). Improvements will hopefully be made in the future.</i></p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default TaamParser_Instructions;