import React, { Component } from 'react';

class HistoryReboot extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p>לאחר הפוגה, בתחילת 2017 החלטתי לעסוק בפרויקט במשרה מלאה מתוך מחשבה ליצור חזית אינטרנטית. באמצע אותה שנה 
        העניינים החלו להתקדם במלוא קדימה כשהתחלתי לאכלס את בסיס הנתונים שלי בטקסט התנ"ך 
        של פרויקט ויקיטקסט. בסתיו זה הושלם והיה לי מאגר נתונים טעון במלואו עם האותיות, 
        הנקודות וטעמי המקרא של התנ"ך והתרגשתי להיות מסוגל להריץ שאילתות אינפורמטיביות.</p>

      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <p>After a hiatus, in early 2017 I decided to engage in the project full time with the thought of creating a web front end. 
        By the middle of that year things started to move full steam ahead as I began 
        to populate my database with the Tanach text of the Wikitext project.  By the fall, that was 
        complete and I had a fully loaded database with the letters, nekudos and trop of Tanach and was excited to 
        be able to run informative queries.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default HistoryReboot;