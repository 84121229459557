import React, {Component} from 'react';
import { Button } from 'reactstrap';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import './css/App.css';
import "./css/keyboard.css"; //based upon example at https://codesandbox.io/s/github/hodgef/react-simple-keyboard-demos/tree/animated-theme/


class HebrewKeyboard extends Component {

  state = {
    layoutName: "default",
    input: ""
  };

  commonKeyboardOptions = {
//    onChange: input => this.onChange(input),
    onKeyPress: button => this.onKeyPress(button),

    theme: "hg-theme-default myTheme2",
    buttonTheme: [{class: "myTheme2b", buttons: "{bksp}"}],
    //theme: "simple-keyboard hg-theme-default hg-layout-default",
    physicalKeyboardHighlight: true,
    syncInstanceInputs: true,
    mergeDisplay: true,
    debug: true
  };

  keyboardOptions = {
    ...this.commonKeyboardOptions,
    /**
     * Layout by:
     * Sterling Butters (https://github.com/SterlingButters)
     */
    layout: {
      letters: [ //could have typed in Hebrew but the IDE editor was making it difficult with RTL issues so found it simpler to copy/paste unicode
        "\u05D0 \u05D1 \u05D1\u05BC \u05D2 \u05D3 \u05D4 \u05D4\u05BC {backspace}",
        "\u05D5 \u05D5\u05B9 \u05D5\u05BC \u05D6 \u05D7 \u05D8 \u05D9 {delete}",
        "\u05DB \u05DB\u05BC \u05DA \u05DA\u05BC \u05DC \u05DE \u05DD",
        "\u05E0 \u05DF \u05E1 \u05E2 \u05E4 \u05E4\u05BC \u05E3",
        "\u05E6 \u05E5 \u05E7 \u05E8 \u05E9 \u05E9\u05C1 \u05E9\u05C2 \u05EA \u05EA\u05BC"
      ],
      nekudos: [
        "ת\u05B7 ת\u05B8 ת\u05C7",
        "ת\u05B9 ת\u05BA ת\u05B4 ת\u05B5",
        "ת\u05B6 ת\u05BB ת\u05BC",
        "ת\u05B0 ת\u05B1 ת\u05B2 ת\u05B3",
        "{space}"
      ],
      taamim: [
        "א\u05C3 א\u05BD א\u0591",
        "א\u0592 א\u0593 א\u0594 א\u0595 {metiga} א\u0596",
        "א\u0597 א\u0598 א\u0599 א\u059A א\u059B",
        "א\u059C א\u059E א\u059F א\u05A0 א\u05A1 {munachLegarmei}",
        "א\u05A3 א\u05A4 א\u05A5 א\u05A6 א\u05A7 א\u05A8 א\u05A9 א\u05AA"//, "     "
      ],
      sifreiEmet: [
        "{olehVeyoreid} {azlaLegarmei} {reviaMugrash} {mahpachLegarmei}",
        "א\u05AE א\u05AD א\u05AC",
        " ",
        " ",
        " "
      ],
      special: [
        "4 3 2 1 0",
        "9 8 7 6 5",
        "+ - = < >",
        "* ? & | \\ \/",
        "( ) [ ] { }"
      ]
    },
    display: {
      "{backspace}": "⌫",
      "{delete}": "del",
      "{space}": "<span style=\"font-size: 14px;\">space</span>",
      "{metiga}": "א\u05A8א\u0594",
      "{munachLegarmei}": "א\u05A3א\u05C0",
      "{olehVeyoreid}": "א\u05ABא\u05A5",
      "{azlaLegarmei}": "א\u05A8א\u05C0",
      "{reviaMugrash}": "א\u0597א\u059D",
      "{mahpachLegarmei}": "א\u05A4א\u05C0",
      "{enter}": "enter ↵",
      "{shiftleft}": "shift ⇧",
      "{shiftright}": "shift ⇧",
    }
  };

  onChange = (input, keyboardType) => {
    //remove dummy letters if keyboardType is taamNekuda
    if(keyboardType === 'taamNekuda'){
        for(let i = 0; i < input.length; i++){
            let currChar = input[i];
            if(["ת", "א"].includes(currChar)){
                input = input.slice(0,i).concat(input.slice(i+1,input.length))
            }
        }
    }
    //let this.props.extInput = input;
    this.props.changeCallback(input);
    console.log("Input changed", input);
    this.keyboard.setInput("");  //flush the buffer to make way for new entries
  };

  onKeyPress = button => {
    console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{space}")
        this.onChange(" ");
    else if (button === "{backspace}")
        this.handleBackspace();
    else if (button === "{delete}")
        this.handleDelete();

    else if (
        button === "{shift}" ||
        button === "{shiftleft}" ||
        button === "{shiftright}" ||
        button === "{capslock}"
    ) {
      this.handleShift();
    }
  };

  handleBackspace = () => {
      this.props.backspaceCallback();
      this.keyboard.setInput("");  //flush the buffer to make way for new entries
  }


  handleDelete = () => {
      this.props.deleteCallback();
      this.keyboard.setInput("");  //flush the buffer to make way for new entries
  }

  handleShift = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default"
    });
  };

//  onChangeInput = event => {
//    let input = event.target.value;
//    this.setState(
//      {
//        input: input
//      },
//      () => {
//        this.keyboard.setInput(input);
//      }
//    );
//  };

  sendTogglePopup = () => {
    this.props.togglePopupCallback();
  };

  render(){
    return (
      <div>
        <div className="closeIcon"><Button close title="Close" onClick={this.sendTogglePopup} /></div>
        <div className={"keyboardContainer"}>
          <div className='regular-character-keyboard'>
              <Keyboard
                baseClass={"simple-keyboard-letters"}
                ref={r => (this.keyboard = r ? r.keyboard : null)}
                layoutName={"letters"}
                onChange={(input) => this.onChange(input, 'regular')}
                {...this.keyboardOptions}
              />
          </div>
          <div className='taam-nekuda-keyboard'>
              <Keyboard
                baseClass={"simple-keyboard-nekudos"}
                className='taamNekudaKeyboard'
                ref={r => (this.keyboard = r ? r.keyboard : null)}
                layoutName={"nekudos"}
                onChange={(input) => this.onChange(input, 'taamNekuda')}
                {...this.keyboardOptions}
              />
          </div>
          <div className='regular-character-keyboard'>
              <Keyboard
                baseClass={"simple-keyboard-special"}
                ref={r => (this.keyboard = r ? r.keyboard : null)}
                layoutName={"special"}
                onChange={(input) => this.onChange(input, 'regular')}
                {...this.keyboardOptions}
              />
          </div>
      </div>


      <div className={"keyboardContainer"}>
          <div className='taam-nekuda-keyboard'>
              <Keyboard
                baseClass={"simple-keyboard-taamim"}
                className='taamNekudaKeyboard'
                ref={r => (this.keyboard = r ? r.keyboard : null)}
                layoutName={"taamim"}
                onChange={(input) => this.onChange(input, 'taamNekuda')}
                {...this.keyboardOptions}
              />
          </div>
          <div className='taam-nekuda-keyboard'>
              <Keyboard
                baseClass={"simple-keyboard-sifreiEmet"}
                className='taamNekudaKeyboard'
                ref={r => (this.keyboard = r ? r.keyboard : null)}
                layoutName={"sifreiEmet"}
                onChange={(input) => this.onChange(input, 'taamNekuda')}
                {...this.keyboardOptions}
              />
          </div>
      </div>

</div>
    );
  }
}

export default HebrewKeyboard;
