import React, { Component } from 'react';

class Layout_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כאשר
            האתר נטען,
            ניתן לראות את
            הדברים הבאים:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={624} height={150} id="Picture 94" src="helpDialogImages/image001.jpg" /></span></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>סרגל
              עליון</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>: <b>הכותרת
              וסרגל הניווט </b>
            המכילים מידע
            על האתר,
            ניווט ובקרות
            כולל בחירת
            שפת ממשק,
            כניסה,
            דיאלוגי עזרה
            ואודות והתפריט
            הראשי.</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לוח
              שמאלי</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>: <b>הוראות </b>
              תלויות עמוד. כאשר
              יש תוצאות חיפוש פתוחות, תופיע חלונית מעל ההוראות המציגה למשתמש את הנתונים הסטטיסטיים וההגדרות הספציפיות שנבחרו עבור החיפוש האחרון. ניתן להרחיב את החלונית לחלונית נפרדת הניתנת לגרירה ולשינוי גודלה.
            </span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לוח
              ימין</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>: <b>
              הגדרות חיפוש</b>&nbsp;
            (<i>פרטים בהמשך</i>)</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>למעלה
              במרכז</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>: <b>סרגל
              חיפוש</b> להזנת ביטויי
            חיפוש</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אמצע
              מרכז</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>:
            פרטי <b>תוכן </b>
            העמוד</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כאשר
            האתר נטען
            לראשונה, <b>בייס-הספר </b>
            נפתח עם לוחות המקשרים לכל עמוד תכונה באתר. הלוחות ניתנים לגרירה כך שהמשתמש יכול לשים אותם לפי סדר העדפה. לא
            משנה איזה דף
            ייבחר באתר,
            תמיד יהיה <b>סרגל
              חיפוש</b></span></p>

      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal">When the site is loaded, the following will be seen:</p>
        <p className="MsoNormal"><img width={624} height={150} id="Picture 94" src="helpDialogImages/image001.jpg" /></p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>Top bar</b>: the <b>Title and navigation
            bar</b> containing site information, page navigation and controls including interface
          language selection, login, help and about dialogues, and the main menu </p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>Left panel</b>: page-dependent <b>instructions</b>. When there are search results open, a box will appear above the instructions showing the user the specific statistics and settings selected for the most recent search.
            The box can be expanded into a separate box that is draggable and resizable.</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>Right panel</b>: <b>search settings</b> (<i>details
            below</i>)</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>Center top</b>: a <b>search bar</b> for
          entering search terms</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>Center middle</b>: page <b>content</b> details</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">When the site is first loaded, <b>BaseHasefer</b> opens up
          with panels that link to each feature page in the site. The panels are draggable so that the user can put them in order of preference. No matter what page is selected and navigated on the web
          site, there will always be a <b>search bar</b> on top.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default Layout_Instructions;