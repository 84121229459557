import React, { Component } from 'react';

class AdvancedSettings_Examples extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif'}}>דוגמאות
              לחיפוש מתקדם</span></i><span dir="LTR" /><i><span dir="LTR" style={{fontSize: '14.0pt'}}><span dir="LTR" />:</span></i></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אזור
                הקבוע של
                דיאלוג חיפוש המתקדם
                (אזור הניתן
                לעריכה)</span></i></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></i></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שימוש
              בתיבת הסימון</span></i><span dir="LTR" /><i><span lang="HE" dir="LTR"><span dir="LTR" /> </span></i><b><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לשלול</span></i></b><span dir="LTR" /><i><span lang="HE" dir="LTR"><span dir="LTR" /> </span></i><i><span dir="LTR" style={{fontSize: '14.0pt'}}><img width={57} height={22} id="Picture 35" src="helpDialogImages/image025.jpg" /></span></i></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>חפש
              מקרים של "נבוכדנצר"
              בלי "נ"
              המתרחש לאחר
              ה"ד</span></i></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
          </span><span dir="LTR" /><b><span dir="LTR"><span dir="LTR" />*</span></b><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>דנ* *נב*כד*צ*ר.
            </span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עם</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />*דנ*</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> נבחר
            בבורר בטויי
            החיפוש, סמן
            את התיבה
            השוללת את
            הבטוי. החיפוש
            יחזיר 25
            פסוקים - כולם
            עם שימושים
            שונים של</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>נבוכד</span><b><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif', color: 'red'}}>ר</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אצר</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שימוש
              בתיבת הסימון <b>מבחן
                אותיות סוף</b></span></i><span dir="LTR"><img width={110} height={24} id="Picture 36" src="helpDialogImages/image026.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>חפש
              פסוקים
              המכילים מילה
              עם התבנית </span></i><span dir="LTR" /><i><span dir="LTR"><span dir="LTR" />*</span></i><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>יחנ<b><span style={{color: 'red'}}>כ</span></b></span></i><span dir="LTR" /><i><span dir="LTR"><span dir="LTR" />* </span></i><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אבל לא
              מכילים </span></i><b><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif', color: 'red'}}>ך</span></i></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
          </span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />*</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>יחנכ</span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />*</span><span dir="RTL" /><span style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> <span lang="HE">וחפש.&nbsp;
              שישה פסוקים
              יוחזרו מבראשית
              מג:כט</span></span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עד
            דברי הימים
            ב:ז:ה</span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />.
          </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עם </span><span dir="LTR" /><b><span dir="LTR"><span dir="LTR" />*</span></b><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>יחנכ</span></b><span dir="LTR" /><b><span dir="LTR"><span dir="LTR" />*</span></b><span dir="RTL" /><b><span style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> </span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>נבחר
            בבורר בטויי
            החיפוש, באזור
            הקבוע של דיאלוג
            חיפוש המתקדם,
            באזור הניתן
            לעריכה, סמן
            את התיבה
            שמציינת אם
            לבדוק </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>להמצאת </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אותיות
            סוף. זה יהפוך
            את הרשומה שלך
            במונחי החיפוש
            תלוי לרישיות.
            חיפוש כעת
            יחזיר רק את
            השלושה
            פסוקים שאינם מכילים</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כ
            סופית</span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שימוש
              בהגדרת <b>=&lt; מספר
                מופעים</b> </span></i><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" />&nbsp;</span><span dir="LTR"><img width={129} height={26} id="Picture 37" src="helpDialogImages/image027.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>חפש
              פסוקים
              המכילים את
              המילה</span></i><span dir="LTR" /><i><span lang="HE" dir="LTR"><span dir="LTR" /> </span></i><b><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>את </span></i></b><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שש
              פעמים ויותר
              (ללא קידומות
              או סיומות)</span></i></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
          </span><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>את</span></b><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ולהיכנס
            6 לשדה "מספר
            מופעים".&nbsp;
            חיפוש יחזיר את
            שני הפסוקים </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>יהושע
            ט:כד</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ויהושע
            י:כג</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></i></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>(בעדכון
              המערכת הבא התוכנית
              היא לאפשר
              לחיפוש להיות
              רגיש למרחקים
              בין מונחי חיפוש.&nbsp;
              כאשר תכונה זו
              קיימת, ניתן
              יהיה לחפש חזרות
              רצופות של
              מונחים כמו
              שורש מסוים או
              ביטוי מסוים
              המתרחש מספר
              פעמים
              ברציפות ללא
              הפרעות.)</span></i></p>
        <p className="MsoNormal">&nbsp;</p>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><i><span style={{fontSize: '14.0pt'}}>Advanced Search Examples:</span></i></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><i>Fixed area of advanced search dialogue (editable area)</i></b></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><i>Using the <b>Negation</b> checkbox</i><i><span style={{fontSize: '14.0pt'}}><img width={57} height={22} id="Picture 35" src="helpDialogImages/image025.jpg" /></span></i></p>
        <p className="MsoNormal"><i>Search for instances of Nevuchadnezzar without a nun
            occurring after the daled</i></p>
        <p className="MsoNormal">Enter <b>*</b><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>דנ* *נב*כד*צ*ר</span></b><span dir="LTR" /><b><span dir="LTR" />*</b>.&nbsp; With <span dir="RTL" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />*דנ*</span></b><span dir="LTR" /><b><span lang="HE"><span dir="LTR" /> </span></b>selected in
          the search term selector, check the box that negates the term.&nbsp; The search will
          return 25 pesukim – all with various usages of <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>נבוכד</span><b><span lang="HE" dir="RTL" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif', color: 'red'}}>ר</span></b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>אצר</span></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><i>Using the <b>Check end letters</b> checkbox</i><img width={110} height={24} id="Picture 36" src="helpDialogImages/image026.jpg" /></p>
        <p className="MsoNormal"><i>Search for pesukim containing a word with the pattern *</i><i><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>יחנ<b><span style={{color: 'red'}}>כ</span></b></span><span dir="LTR" /><span dir="LTR" />*
            but not containing </i><b><i><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif', color: 'red'}}>ך</span></i></b></p>
        <p className="MsoNormal">Enter *<span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>יחנכ</span><span dir="LTR" /><span dir="LTR" />* and search.&nbsp; Six pesukim will be returned
          from <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בראשית
            מג:כט</span><span dir="LTR" /><span dir="LTR" />
          to <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>דברי
            הימים ב:ז:ה</span><span dir="LTR" /><span dir="LTR" />.&nbsp; With <b>*</b><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>יחנכ</span><span dir="LTR" /><span dir="LTR" />*</b> selected in the search term selector,
          in the fixed area of the advanced search dialogue, in the editable area, check
          the box that indicates whether to check for end letters.&nbsp; This will make your
          entry in the search terms case sensitive.&nbsp; Searching now will return only the
          three pesukim that don’t contain <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כ
            סופית</span><span dir="LTR" /><span dir="LTR" />.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><i>Using the <b>Occurrences &gt;=</b> setting</i><img width={129} height={26} id="Picture 37" src="helpDialogImages/image027.jpg" /></p>
        <p className="MsoNormal">Search for pesukim containing the word <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>את</span></b><span dir="LTR" /><span dir="LTR" /> six times or more (without prefixes or
          suffixes)</p>
        <p className="MsoNormal">Enter <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>את</span></b><span dir="LTR" /><span dir="LTR" /> and enter 6 into the occurrences &gt;=
          field.&nbsp; A search will return the two pesukim <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>יהושע
            ט:כד</span><span dir="LTR" /><span dir="LTR" /> and <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>יהושע
            י:כג</span></p>
        <p className="MsoNormal"><span dir="LTR" /><i><span dir="LTR" />(In the next
            system update the plan is to enable the search to be sensitive to distances between
            search terms.&nbsp; When this feature is in place, one will be able to search for
            consecutive repetitions of terms such as a certain shoresh or a certain phrase
            occurring a number of consecutive times without interruption)</i></p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default AdvancedSettings_Examples;