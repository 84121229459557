import React, { Component } from 'react';

class CommonTerms_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אפשרויות
                ותוצאות
                ספציפיות
                לשיטת
                ההשוואה של <i>ביטויים
                  משותפים</i>:</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תוכל
            לבחור
            אפשרויות על
            ידי לחיצה על
            שלוש ההגדרות
            הבאות</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={622} height={110} id="Picture 50" src="helpDialogImages/image055.jpg" /></span></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מיין
              לפי שכיחות</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - מיין
            תוצאות לפי
            ירידה באחוז
            התדר של
            המונח/הביטוי
            בתוך:</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>התדר
            המינימלי של
            המונח/הביטוי
            בין שני החלקים
            שנבחרו (ברירת
            מחדל)</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תדירות
            המונח/הביטוי
            בתוך התנ"ך
            כולו</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תדירות
            המונח/הביטוי
            בתוך הספרים
            שנבחרו בסרגל
            הגדרות
            החיפוש</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ביטויים
              רצופים</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - בחרו
            את מספר ה- </span><span dir="LTR">n-gram</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> שיהיה
            נושא ההשוואה.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span dir="LTR">n-gram</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> הוא רצף של 1..</span><span dir="LTR">n</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> מונחים. לדוגמא,
          </span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />"</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אל
            משה לאמר</span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />"</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
            הוא </span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />3-gram</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />; רצף גמטריא
            של 50, 60, 48, 196 יהיה </span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />4-gram</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בחירת </span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />2-gram</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
            תבחן את קיומן
            של אותן שתי
            מילים רצופות
            המופיעות
            בשתי בחירות
            הפסוקים.</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />(ברירת
            מחדל = </span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />1-gram</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />.
            מונח בודד)</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תוצאות
              להציג</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - בחר את
            סוג התוצאות
            להצגה:</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הכל
            (ברירת מחדל)</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>רק
            המונחים/הביטויים
            המשותפים
            לשתי בחירות הפסוקים</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>רק
            המונחים/הביטויים
            שאינם
            משותפים לשתי בחירות
            הפסוקים</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            שתלחץ על הכפתור
            "עדכן", יופיע
            סוג הטבלה
            שלהלן</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span dir="RTL" /><i><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />(אזהרה
              - זה יכול
              להיות <b>איטי</b>
              באופן כללי אז
              היו סבלניים.</span></i><i><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}> </span></i><i><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>אם
              שורש נבחרה
              בהגדרות
              החיפוש
              הסטנדרטיות זה
              יהיה איטי
              במיוחד או לא
              יעבוד בכלל.</span></i><i><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}> </span></i><i><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>אם
              נבחרה גמטריא
              או אתבש,
              התוצאות לא
              יהיו מדויקות)</span></i></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={622} height={182} id="Picture 51" src="helpDialogImages/image056.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בפינה
            הימנית
            העליונה
            והשמאלית
            העליונה,</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תיבה תציג
            את המספר
            הכולל של
            מונחים
            הכלולים בבחירת
            הפסוקים של כל
            צד</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מתחת
            לזה, טבלה
            תציג את
            העמודות
            הבאות מימין לשמאל:</span></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="RTL" /><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />%
              במסומנים</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - הערך
            בעמודה "<b>הופעות
              בקטעים</b>"
            מחולק על ידי
            הערך בעמודה "<b>בספרים
              המסומנים</b>"
            כאחוז</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בספרים</span></b><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> המסומנים</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - סה"כ
            מספר המופעים
            של המונח /
            הביטוי
            המדובר בספרי
            הסימון
            שבסרגל
            ההגדרה
            הסטנדרטי</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="RTL" /><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />% בתנ"ך</span></b><span dir="LTR" /><b><span lang="HE" dir="LTR"><span dir="LTR" /> </span></b><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />- הערך
            בעמודה "<b>הופעות
              בקטעים</b>"
            מחולק על ידי
            הערך בעמודה "<b>בתנ"ך</b>"
            כאחוז</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בתנ"ך</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            המספר הכולל
            של המופעים של
            המונח /
            הביטוי המדובר
            בכל התנ"ך</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="RTL" /><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />% בקטעים</span></b><span dir="LTR" /><b><span lang="HE" dir="LTR"><span dir="LTR" /> </span></b><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />- הערך
            בעמודה "<b>הופעות
              בקטעים</b>"
            מחולק על ידי
            הערך בתיבת "</span><b><span lang="HE" style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: 'black', background: 'white'}}>סה"כ
              ביטויים</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>" בצד
            ימין כאחוז</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הופעות
              בקטעים</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - המספר
            הכולל של
            המופעים של
            המונח /
            הביטוי המדובר
            בבחירת
            הפסוקים בצד
            ימין</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="RTL" /><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />#</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - מספר
            אינדקס
            המציין את
            מיקום המונח
            בסדר המיון
            הראשוני של
            הרשימה</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ביטוי</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            המונח או
            הביטוי
            המדובר</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="RTL" /><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />... <b>ושש
              עמודות
              נוספות</b> בצד
            שמאל המשקפות
            מידע דומה כמו
            שש העמודות הראשונות
            בצד ימין אך
            הנוגעות לקטע
            הפסוקים שנבחר
            בצד שמאל של
            המסך</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תבחין
            ששתי העמודות
            המוצלות על רקע
            אפור כהה הן
            העמודות
            הרלוונטיות
            לבחירת ההגדרה
            "</span><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מיין
              לפי שכיחות"</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>,
            שבדוגמה זו
            היא "<b>ספרים
              המסומנים</b>"</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />(ספרים
            שנבחרו בסרגל
            הגדרת החיפוש
            הרגיל).</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ככלל,
            כאשר נתוני
            האחוזים
            בעמודת
            ההתאמה מצד ימין
            וגם משמאל הם
            גבוהים, זה
            מראה מידה
            חזקה של
            משמעות שבה
            המונח /
            הביטוי
            המדובר
            משמשים כקשר
            בין</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
          </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שני
            קטעי הפסוקים.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שימו
            לב גם
            כשמגלגלים
            למטה בשלב
            מסוים תאי הרשת
            יהיו מוצלים
            בכחול במקום
            אפור (ראה
            להלן).</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
          </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מנקודה
            זו ואילך,
            הרשימה כוללת
            מונחים /
            ביטויים
            המופיעים רק
            בבחירת
            הפסוקים
            מימין <u>או</u>
            בבחירה משמאל.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={623} height={95} id="Picture 52" src="helpDialogImages/image057.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מיון</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לחיצה
            על אחת מהעמודות
            תמיין את
            השורות לפי
            סדר המיון האלפביתי
            או המספרי
            הרלוונטי של
            אותה עמודה.
            לחיצה נוספת
            על העמודה
            תהפוך את
            הסדר.</span></p>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b><u>Options and results specific to the </u></b><b><u><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ביטויים
                משותפים</span><span dir="LTR" /><span dir="LTR" /> comparison method:</u></b></p>
        <p className="MsoNormal">You will be able to select options by clicking on the
          following three settings</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><img width={623} height={110} id="Picture 50" src="helpDialogImages/image055.jpg" /></p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מיין לפי
              שכיכות</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>– sort results by descending frequency
          percentage of the term/phrase within:</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '1.0in', textIndent: '-.25in'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
            </span></span><span dir="LTR" />The minimum frequency of the term/phrase
          among the two selected sections (default)</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '1.0in', textIndent: '-.25in'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
            </span></span><span dir="LTR" />The frequency of the term/phrase within the
          entire Tanach</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '1.0in', textIndent: '-.25in'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
            </span></span><span dir="LTR" />The frequency of the term/phrase within the
          seforim selected in the search setting bar</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ביטויים
              רצופים</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– choose the number of n-grams to be the
          subject of the comparison.&nbsp; An n-gram is a sequence of 1..n terms eg. <u><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>אל</span></u><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}> <u>משה</u>
            <u>לאמר</u></span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>is a 3-gram; a gematria sequence of <u>50</u>, <u>60</u>,
          <u>48</u>, <u>196</u> would be a 4-gram.&nbsp; Choosing 2-gram will test for the
          existence of the same two consecutive words appearing in both pasuk selections.&nbsp;
          (default = 1-gram. A single term)</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>תוצאות
              להציג</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>– choose the type of results to display:</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '1.0in', textIndent: '-.25in'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
            </span></span><span dir="LTR" />All (default)</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '1.0in', textIndent: '-.25in'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
            </span></span><span dir="LTR" />Only the terms/phrases that are shared by
          both pasuk selections</p>
        <p className="MsoListParagraphCxSpLast" style={{marginLeft: '1.0in', textIndent: '-.25in'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
            </span></span><span dir="LTR" />Only the terms/phrases that are not shared
          by both pasuk selections</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">Once you click the “update” button the following type of
          grid will appear <i><span style={{fontSize: '10.0pt'}}>(warning – this can be <b>slow</b>
              in general so be patient.&nbsp; If </span></i><i><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>שורש</span></i><span dir="LTR" /><i><span style={{fontSize: '10.0pt'}}><span dir="LTR" /> was
              selected in the standard search settings then this will be extremely slow or
              not work at all.&nbsp; If </span></i><i><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>גמטריא</span></i><span dir="LTR" /><i><span style={{fontSize: '10.0pt'}}><span dir="LTR" /> or </span></i><i><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>אתבש</span></i><span dir="LTR" /><i><span style={{fontSize: '10.0pt'}}><span dir="LTR" /> was selected
              then the results will be inaccurate)</span></i></p>
        <p className="MsoNormal"><img width={623} height={182} id="Picture 51" src="helpDialogImages/image056.jpg" /></p>
        <p className="MsoNormal">In the top-right and top-left, a box will display <b>total
            terms</b> contained in each side’s pasuk section</p>
        <p className="MsoNormal">Beneath that, a grid will display the following columns from
          right to left:</p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><span dir="RTL" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />% במסומנים</span></b><span dir="LTR" /><b><span lang="HE"><span dir="LTR" /> </span></b>– the “<b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הופעות
              בקטעים</span></b><span dir="LTR" /><span dir="LTR" />” column divided by the “<b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בספרים
              המסומנים</span></b><span dir="LTR" /><span dir="LTR" />” column as a percentage</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בספרים
              המסומנים</span></b><span dir="LTR" /><b><span lang="HE"><span dir="LTR" /> </span></b>– total occurrences
          of the term/phrase in question within the checked off sefarim in the standard
          setting bar</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><span dir="RTL" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />%
              בתנ"ך</span></b><span dir="LTR" /><b><span lang="HE"><span dir="LTR" /> </span></b>- the “<b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הופעות
              בקטעים</span></b><span dir="LTR" /><span dir="LTR" />” column divided by the “<b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בתנ"ך</span></b><span dir="LTR" /><span dir="LTR" />” column as a percentage</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בתנ"ך</span></b><span dir="LTR" /><b><span lang="HE"><span dir="LTR" /> </span></b>- total occurrences of the term/phrase
          in question in the entire Tanach</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><span dir="RTL" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />%
              בקטעים</span></b><span dir="LTR" /><b><span lang="HE"><span dir="LTR" /> </span></b>- the “<b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הופעות
              בקטעים</span></b><span dir="LTR" /><span dir="LTR" />” column divided by the “<b>total terms</b>” box on the right as
          a percentage</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הופעות
              בקטעים</span></b><span dir="LTR" /><b><span lang="HE"><span dir="LTR" /> </span></b>- total occurrences of the term/phrase
          in question within the pesukim of the right-side selection</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><span dir="RTL" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />#</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>- index number
          indicating the term’s position within the initial sort order of the list</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ביטוי</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– the term or phrase
          in question</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />…..<b>and six additional columns</b> on the
          left reflecting similar information as the first six columns on the right but
          pertaining the the section of pesukim selected on the left side of the screen</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">You will notice that the two columns getting shaded with a
          dark gray background are the columns pertinent to the selection of the setting <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מיין
              לפי שכיכות</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>which in this
          example is “<b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ספרים
              המסומנים</span></b><span dir="LTR" /><span dir="LTR" />” (seforim selected in the standard search
          setting bar).</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">In general, when percentage figures for the matching column
          on both the right and left are high, that shows a strong measure of significance
          where the term/phrase in question serves as a link between the two sections of
          pesukim.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">Also notice when scrolling down that at some point the cells
          of the grid will be shaded in blue instead of gray (see below).&nbsp; From this
          point onward, the list includes terms/phrases that only appear in the pesukim
          selection on the right OR the selection on the left.</p>
        <p className="MsoNormal"><img width={623} height={95} id="Picture 52" src="helpDialogImages/image057.jpg" /></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Sorting</u></b></p>
        <p className="MsoNormal">Clicking on any of the columns will sort the rows by the
          relevant alphabetical or numerical sort order of that column. Clicking the
          column again will reverse the order.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default CommonTerms_Instructions;