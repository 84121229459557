import React, { Component } from 'react';
import * as properties from '../../config/properties.js';

class DirectSiteLinks_Examples extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }

    var appURL = properties.env.applicationURL + "/#/";

    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>
        <b>שימוש בקישורים ישירים לאתר</b><br />
<br />
<b>חיפוש:</b>
    <ul>
        <li><span dir="LTR">{appURL}<b><span style={{color: "red"}}>search</span></b>?<b><span style={{color: "red"}}>location</span></b>=עמוס:ה:יב</span> - <i>טוען מיד חיפוש לאותו מיקום ומרחיב פירוט של כל המידע ברמת המילה עבור אותו פסוק</i></li>
        <li><span dir="LTR">{appURL}<b><span style={{color: "red"}}>search</span></b>?<b><span style={{color: "red"}}>terms</span></b>="דבר משה"&<b><span style={{color: "red"}}>format</span></b>=S</span> - <i>טוען מיד חיפוש של כל הפסוקים עם השורשים הסמוכים "דבר משה" (כפי שמצוין במירכאות הכפולות שמסביב)</i></li>
    </ul>
<b>דפדפן התנ"ך:</b> <span dir="LTR">{appURL}<b><span style={{color: "red"}}>tanach</span></b>?<b><span style={{color: "red"}}>location</span></b>=עמוס:ה:יב</span> - <i>פותח מיד חלון של דפדפן התנ"ך למיקום זה</i>
<br /><br />
<b>פרשיות התנ"ך:</b> <span dir="LTR">{appURL}<b><span style={{color: "red"}}>parshaViewer</span></b>?<b><span style={{color: "red"}}>language</span></b>=en</span> - <i>פותח מיד את פרשיות התנ"ך - ומגדיר את שפת ממשק המשתמש לאנגלית ללא קשר למיקום העולמי</i>
<br /><br />
<b>לקסיקון:</b>
    <ul>
        <li><span dir="LTR">{appURL}<b><span style={{color: "red"}}>lexicon</span></b>?<b><span style={{color: "red"}}>word</span></b>=כי</span> - <i>פותח מיד את הלקסיקון ומציג את כל מילות תרגום שמתאימות ל: כי</i></li>
        <li><span dir="LTR">{appURL}<b><span style={{color: "red"}}>lexicon</span></b>?<b><span style={{color: "red"}}>gematria</span></b>=444</span> - <i>פותח מיד את הלקסיקון ומציג את כל המילים התואמות לגימטריא 444</i></li>
        <li><span dir="LTR">{appURL}<b><span style={{color: "red"}}>lexicon</span></b>?<b><span style={{color: "red"}}>targum</span></b>=*סהד*</span> - <i>פותח מיד את הלקסיקון ומציג את כל המילים המתאימות לתבנית התרגום *סהד*</i></li>
    </ul>
<b>שכיחות בתנ"ך:</b> <span dir="LTR">{appURL}<b><span style={{color: "red"}}>frequency</span></b></span> - <i>פותח מיד תכונה זו</i>
<br />
<b>משווה טקסטים:</b> <span dir="LTR">{appURL}<b><span style={{color: "red"}}>compare</span></b></span> - <i>פותח מיד תכונה זו</i>
<br />
<b>מנתח טעמים:</b> <span dir="LTR">{appURL}<b><span style={{color: "red"}}>taamParser</span></b>?<b><span style={{color: "red"}}>location</span></b>=עמוס:ה:יב</span>- <i>פותח מיד חלון מנתח טעמים למיקום זה</i>
<br />
<b>ענני אובייקטים:</b> <span dir="LTR">{appURL}<b><span style={{color: "red"}}>clouds</span></b></span> - <i>פותח מיד תכונה זו</i>
<br />
<b>אילן יוחסין תנ"כי:</b> <span dir="LTR">{appURL}<b><span style={{color: "red"}}>3Dtree</span></b>?<b><span style={{color: "red"}}>name</span></b>=אדם&<b><span style={{color: "red"}}>name2</span></b>=זרבבל&<b><span style={{color: "red"}}>animate</span></b>=T&<b><span style={{color: "red"}}>darkmode</span></b>=F</span> - <i>פותח מיד חלון מציג עץ אילן יוחסין תנ"כי כדי להנפיש את נתיב היחסים בין אדם לזרבבל</i>
<br />
<b>שירותי פרימיום:</b> <span dir="LTR">{appURL}<b><span style={{color: "red"}}>premiumServices</span></b></span> - <i>פותח מיד חלון קופץ זה</i>
<br />
<br />
<br />
<b>קישורי מידע</b>
<br />
<br />
<b>צור קשר אתנו:</b> <span dir="LTR">{appURL}<b><span style={{color: "red"}}>contact</span></b></span> - <i>פותח מיד חלון קופץ עם מידע זה</i><br />
<b>אודות:</b> <span dir="LTR">{appURL}<b><span style={{color: "red"}}>about</span></b>?<b><span style={{color: "red"}}>tab</span></b>=3</span> -  <i>פותח מיד חלון קופץ זה המוגדר למרחק של שלוש כרטיסיות מהכרטיסייה הראשונה</i><br />
<b>עשזרה:</b> <span dir="LTR">{appURL}<b><span style={{color: "red"}}>help</span></b>?<b><span style={{color: "red"}}>topic</span></b>=searchResults</span> - <i>פותח מיד חלון קופץ זה המוגדר לנושא: תוצאות חיפוש</i><br />
<b>מפת האתר:</b> <span dir="LTR">{appURL}<b><span style={{color: "red"}}>sitemap</span></b></span> - <i>פותח מיד את הדף הזה</i>

        </p>
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b>Using direct site links</b><br />
<br />
<b>Search:</b>
    <ul>
        <li>{appURL}<b><span style={{color: "red"}}>search</span></b>?<b><span style={{color: "red"}}>location</span></b>=עמוס:ה:יב - <i>immediately loads a search to that location and expands a breakdown of all word-level information for that pasuk</i></li>
        <li>{appURL}<b><span style={{color: "red"}}>search</span></b>?<b><span style={{color: "red"}}>terms</span></b>="דבר משה"&<b><span style={{color: "red"}}>format</span></b>=S - <i>immediately loads a search for all pesukim with the adjacent roots דבר משה (as indicated by the surrounding double quotes)</i></li>
    </ul>
<b>Tanach Viewer:</b> {appURL}<b><span style={{color: "red"}}>tanach</span></b>?<b><span style={{color: "red"}}>location</span></b>=עמוס:ה:יב - <i>immediately opens a Tanach Viewer window to that location</i>
<br /><br />
<b>Parsha Viewer:</b> {appURL}<b><span style={{color: "red"}}>parshaViewer</span></b>?<b><span style={{color: "red"}}>language</span></b>=en - <i>immediately opens the Parsha Viewer and sets the user interface language to English regardless of global location</i>
<br /><br />
<b>Lexicon:</b>
    <ul>
        <li>{appURL}<b><span style={{color: "red"}}>lexicon</span></b>?<b><span style={{color: "red"}}>word</span></b>=כי - <i>immediately opens the Lexicon and displays all Targum words that match to כי</i></li>
        <li>{appURL}<b><span style={{color: "red"}}>lexicon</span></b>?<b><span style={{color: "red"}}>gematria</span></b>=444 - <i>immediately opens the Lexicon and displays all words that match the gematria 444</i></li>
        <li>{appURL}<b><span style={{color: "red"}}>lexicon</span></b>?<b><span style={{color: "red"}}>targum</span></b>=*סהד* - <i>immediately opens the Lexicon and displays all words that match to the targum pattern *סהד*</i></li>
    </ul>
<b>Frequency Viewer:</b> {appURL}<b><span style={{color: "red"}}>frequency</span></b> - <i>immediately opens this feature</i>
<br />
<b>Text Comparator:</b> {appURL}<b><span style={{color: "red"}}>compare</span></b> - <i>immediately opens this feature</i>
<br />
<b>Taam Parser:</b> {appURL}<b><span style={{color: "red"}}>taamParser</span></b>?<b><span style={{color: "red"}}>location</span></b>=עמוס:ה:יב - <i>immediately opens a Taam Parser window to that location</i>
<br />
<b>Object Clouds:</b> {appURL}<b><span style={{color: "red"}}>clouds</span></b> - <i>immediately opens this feature</i>
<br />
<b>Tanach Family Tree:</b> {appURL}<b><span style={{color: "red"}}>3Dtree</span></b>?<b><span style={{color: "red"}}>name</span></b>=אדם&<b><span style={{color: "red"}}>name2</span></b>=זרבבל&<b><span style={{color: "red"}}>animate</span></b>=T&<b><span style={{color: "red"}}>darkmode</span></b>=F - <i>immediately opens a Family Tree Viewer window to animate the relation path between אדם and זרבבל</i>
<br />
<b>Premium Services:</b> {appURL}<b><span style={{color: "red"}}>premiumServices</span></b> - <i>immediately opens this popup window</i>
<br />
<br />
<br />
<b>Informational links</b>
<br />
<br />
<b>Contact us:</b> {appURL}<b><span style={{color: "red"}}>contact</span></b> - <i>immediately opens a popup window with this information</i><br />
<b>About:</b> {appURL}<b><span style={{color: "red"}}>about</span></b>?<b><span style={{color: "red"}}>tab</span></b>=3 -  <i>immediately opens this popup window set to three tabs away from the first tab</i><br />
<b>Help:</b> {appURL}<b><span style={{color: "red"}}>help</span></b>?<b><span style={{color: "red"}}>topic</span></b>=searchResults - <i>immediately opens this popup window set to the Search Results topic</i><br />
<b>Site map:</b> {appURL}<b><span style={{color: "red"}}>sitemap</span></b> - <i>immediately opens this page</i>

        </p>

      </>
    }
    return retVal;
  }

  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default DirectSiteLinks_Examples;