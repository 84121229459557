import React, { Component } from 'react';

class SimpleSearch_Examples extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '14.0pt', fontFamily: '"Arial",sans-serif'}}>שימוש
              בבייס-הספר</span></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>חיפוש
              פשוט בתנ"ך</span></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>באמצעות
            מקלדת
            סטנדרטית עם
            עברית (</span><span dir="LTR">HE</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />) שנבחרה
            כשפת הקלט או
            באמצעות
            המקלדת
            הווירטואלית (<i>מתואר
              לעיל</i>), הקלד
            ביטוי חיפוש
            אחד או יותר
            מימין לשמאל עם
            רווח להפרדת
            כל ביטוי
            חיפוש.</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />&nbsp;</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>על
            ידי לחיצה על
            כפתור החיפוש
            בצד שמאל,
            חלון טעינה
            יופיע ואז
            יחשוף את חלון
            תוצאות
            החיפוש עם
            רשימה של
            פסוקים מודגשים
            המראים את
            ביטויי
            החיפוש
            התואמים.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>דוגמאות:</span></i></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
            את המונח <b>ויעבר </b>
            בסרגל החיפוש
            ולחץ על כפתור
            החיפוש. (בשלב
            זה <b>תיבה </b>
            תיבחר כהגדרת
            ברירת המחדל
            של חיפוש
            המציין חיפוש
            עם <b>אותיות </b>
            בלבד). תקבל
            חלון תוצאות
            המציג 48
            פסוקים החל מבראשית
            ח:א לדברי
            הימים ב:לו:כב.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עכשיו
            לחץ על תיבות
            הסימון של
            סינון הספרים
            בצד ימין, כך
            שנביאים
            וכתובים לא
            יבחרו ונבחר
            רק תורה. לחץ
            שוב על כפתור
            החיפוש ועכשיו
            תקבלו רק 7
            פסוקים של
            תוצאות החל מבראשית
            ח:א לשמות לד:ו.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            מכן לחץ על
            תיבות הסימון
            של סינון
            הספרים כך
            שכולם ייבחרו.
            לאחר מכן לחץ
            על הכפתור <b>נקודות </b>
            כך שעכשיו
            תבחר <a name="_Hlk35480834"><b>אותיות
              </b></a> ו<b>נקודות</b>.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עכשיו
            הזן את המונח </span><span lang="HE" style={{fontSize: '18.0pt', fontFamily: '"Arial",sans-serif'}}>וַיַּעֲבֹר</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />(הקפד
            להזין את כל
            הנקודות עם
            דגש אחרי פתח
            ב"י" ועם חולם
            מלא ב"ב") ולחץ
            על חיפוש. כעת
            תקבלו 36
            פסוקים של
            תוצאות החל מבראשית
            יב:ו לדברי
            הימים ב:כא:ט.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עכשיו
            לחץ על הכפתור
            <b> טעמים</b> כך
            שעכשיו <a name="_Hlk35480968"><b>אותיות
              </b></a> ו<b>טעמים</b> ו<b>נקודות </b>
            נבחרים.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
            &nbsp;</span><span lang="HE" style={{fontSize: '22.0pt', fontFamily: '"Arial",sans-serif'}}>וַֽיַּעֲבֹ֔ר</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> &nbsp;(הקפד
            להזין את <b>שני </b>
            סמלי
            הטעם/מבטא על
            ידי הקלדת ה<b>מתג </b>
            אחרי הפתח-ויו
            ועל ידי הקלדת
            <b> זקף קטן</b> אחרי
            החולם-בית).
            תקבלו 2 פסוקי
            תוצאות:
            בראשית לב:כג
            ושמואל
            א:טו:יב.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עכשיו
            לחץ על הכפתור
            <b> נקודות</b> כדי
            לבטל את הדגש
            על הבחירה כך
            שעכשיו
            נבחרים רק <b>אותיות
            </b> ו<b>טעמים</b>.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontSize: '22.0pt', fontFamily: '"Arial",sans-serif'}}>ויעבר֔</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> (ללא
            נקודות, <b>וזקף
              קטן</b> כתו
            הסופית).</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תקבלו 2
            פסוקי תוצאות:
            בראשית לב:כג
            ושמואל א:טו:יב.
            (<i>עיין בהערה
              הבאה לגבי
              הסיבה שטעם
              המקרא ממוקם
              בסוף התיבה
              כאן</i>).</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שימו
              לב שכאשר
              משתמשים
              בחיפוש פשוט
              וכוללים
              נקודות
              וטעמים,
              הדרישות
              הופכות להיות
              מאוד מדויקות
              כך שיש להזין
              את כל התווים
              הנכונים ללא
              יוצא מן הכלל
              וברצף הנכון.</span></i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>השימוש
              בחיפוש
              המתקדם למטרה
              זו הוא הרבה
              יותר קל, אך
              הגדרה שתצביע
              על כך שהמערכת
              צריכה לבצע
              חיפוש רגוע
              יותר בנקודות
              וטעמים עשויה
              להיכלל בעתיד.</span></i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כשמשלבים
              אותיות רק עם
              טעמים, הטעם
              מוזן ממש בסוף
              המילה לעומת
              המקום בו היא
              מתרחשת בתוך
              מילה מסיבות נוחות.</span></i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>התנהגות
              זו עשויה
              להשתנות
              בעתיד. באופן
              כללי אתמקד
              בגישתי
              לחיפוש טעמים
              יותר ברמה
              הרעיונית
              בניגוד
              לתווים
              הבסיסיות שכן ישנם
              1) טעמים מרובי
              תווים ו -2)
              טעמים שונים
              באופן מהותי
              שחולקים אותה
              תו. כרגע זה
              עדיין מבוסס
              על תווים ולכן
              הדברים עלולים
              להיות
              מבלבלים.</span></i></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עכשיו
            לחץ על הכפתור
            <b> טעמים</b> כדי
            לבטל את סימון
            הבחירה ואז
            לחץ על הכפתור
            <b> נקודות</b> כך
            שעכשיו <b>אותיות
            </b> ו<b>נקודות </b>
            נבחרים.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
          </span><span lang="HE" style={{fontSize: '18.0pt', fontFamily: '"Arial",sans-serif'}}> ??מֹשֶׁה </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
            ולחפש. תקבלו 2
            תוצאות, שמות
            יד:לא ובמדבר
            כא:ה, בגלל
            השימוש בתו כללי
            עבור אותיות בודדות,
          </span><span lang="HE" style={{fontSize: '18.0pt', fontFamily: '"Arial",sans-serif'}}> ?</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>,
            המציין חיפוש
            אחר השם משה
            שקדם לו שתי
            אותיות
            קידומת.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עכשיו
            לחץ על הכפתור
            <b> נקודות</b> כדי
            לבטל את הדגשת
            הבחירה כך
            שעכשיו תבחר
            רק <b>אותיות</b>.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
            *ת*לד*ת*. תקבלו 39
            פסוקי תוצאות
            מ</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בראשית</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> ב:ד
            לדברי הימים
            א:כו:לא.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בגלל
            התו כללי עבור
            אותיות רבים, </span><span lang="HE" style={{fontSize: '18.0pt', fontFamily: '"Arial",sans-serif'}}>*</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>, זה
            מניב את כל
            האיות
            האפשריות של
            המילה תולדות
            יחד עם כל
            הקידומות
            והסיומות.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
            <b> וידבר משה
              אהרן</b>. תקבלו 15
            פסוקי תוצאות
            משמות ו:יג לבמדבר
            יט:א.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span><span dir="RTL" /><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />"בדיקת
              שעטנז"</span></i></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
            *ש* *ע* *ט* *נ* *ז*.
            תקבלו 28 פסוקי
            תוצאות מבראשית
            מא:ט לדברי
            הימים ב: כט: כא -
            כל המקומות
            בתנ"ך שבהם
            הפסוק מכיל
            מילים ברורות
            עם אותיות שעטנז
            המתרחשות
            ברצף.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עכשיו
            מעל סרגל
            החיפוש בו
            מופיע הבא:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={389} height={43} id="Picture 19" src="helpDialogImages/image013.png" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לחץ
            על הכפתור בצד
            שמאל הקיצוני <b>לא
              לפי הסדר</b> כדי
            שהחיפוש
            יחזיר תואם
            למונחי
            החיפוש בכל סדר
            שהוא.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={387} height={43} id="Picture 20" src="helpDialogImages/image014.png" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עכשיו
            החיפוש יחזיר
            18 פסוקי
            תוצאות מבראשית
            יב:ח לדברי
            הימים ב: לד:ח.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עכשיו
            לחץ על הכפתור
            <b> שורש</b> כדי
            להדגיש
            אפשרות זו, כך
            שעכשיו נבחר
            רק <b>שורש</b>.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
            <b> שמר שמע עשה</b>.
            תקבלו 15 פסוקי
            תוצאות משמות
            טו: כו ל</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>קהלת</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> ד: יז.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עכשיו
            לחץ על הכפתור
            <b> לפי הסדר </b>
            למעלה כדי
            לציין שצריך
            למצוא את
            השרשים ברצף.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
          </span><span lang="HE" style={{fontSize: '18.0pt', fontFamily: '"Arial",sans-serif'}}> *</span><span lang="HE" style={{fontSize: '18.0pt', fontFamily: '"Arial",sans-serif'}}>עק*ב
            יצחק אברהם</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>.
            תקבלו 2
            פסוקים של
            תוצאות מ</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בראשית</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> לה:כז
            וויקרא כו:מב.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עכשיו
            לחץ על הכפתור
            <b> רגיל</b> בצד
            ימין למטה, כך
            שרק זה מודגש
            (חיפוש <b>גמטריא</b>).</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
            <b> לתפארת</b>.
            תקבלו 3
            פסוקים
            כתוצאות.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
            <b> 1111</b>. תקבלו את
            אותם 3 פסוקים
            שכן זהו ערך הגמטריא
            של <b>לתפארת</b>.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
            <b> 30 40 50 60</b>. תקבל פסוק
            בודד המכיל
            מילים עם הגימטריות
            האלה ברצף:
            במדבר ח:יז</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עכשיו
            לחץ על <b>אתבש </b>
            משמאל למטה כך
            שרק זה מודגש
            (חיפוש
            גימטריא <b>אתבש</b>).</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
            <b> תשמרו</b>. תקבלו
            2 פסוקים
            כתוצאה.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
            <b> 946</b>. תקבלו את
            אותם שני
            פסוקים שכן
            זהו ערך האתבש
            של <b>תשמרו</b>.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הזן
            <b> 60 70 80</b>. תקבלו 4
            פסוקים
            כתוצאה.</span></p>
        <p className="MsoNormal">&nbsp;</p>
      <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b>
      חיפוש בתרגום:
      </b></p>
      <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>
      כדי לחפש מילים או ביטויים בתרגום, לחץ על התפריט הנפתח שמציג את מקור החיפוש. בחר "תרגום". לאחר מכן חפש כל מילה שתרצה למצוא בתרגום.
      <img width={85} height={30} id="Picture 19" src="helpDialogImages/mikorDropdown.png" />
      </p>
      <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>
אם תחפשו את המילה <b>ית</b> בתרגום, תקבלו הרבה תוצאות כי <b>ית</b> היא מילה נפוצה מאוד. הוא מופיע ב1890 פסוקים. כאשר אתה מחפש בתרגום, התוצאות פותחות אוטומטית את עמודת תרגום כך שתוכל לעקוב בין טקסט לתרגום.
      </p>
      <img width={680} height={143} id="Picture 19" src="helpDialogImages/searchTargumExample.png" />
      </>
    } else if (langCode === "en") {
      retVal = <>

<div>
  <p className="MsoNormal"><b><span style={{fontSize: '14.0pt'}}>Using Base Hasefer</span></b></p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal"><b>Simple Tanach search</b></p>
  <p className="MsoNormal">With a standard keyboard with Hebrew (HE) selected as the
    input language or with the virtual keyboard (<i>described above</i>), type one
    or more search terms from right to left with a space to separate each search
    term.&nbsp; By clicking the search button on the left, a loading window will pop up
    and then reveal the search results window with a list of highlighted pesukim
    showing the matching search terms.</p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal"><i>Examples:</i></p>
  <p className="MsoNormal">Enter the term <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ויעבר</span></b><span dir="LTR" /><span dir="LTR" /> into the search bar and then click the search
    button. (at this point, the default search settings of <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>תיבה</span></b><span dir="LTR" /><span dir="LTR" /> will be selected indicating a letter-only
    search (L)).&nbsp; You will get a results window displaying 48 pesukim from <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בראשית
      יב:ו</span><span dir="LTR" /><span dir="LTR" /> to <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>דברי
      הימים ב:לו:כב</span><span dir="LTR" /><span dir="LTR" />.&nbsp; </p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Now click the sefer filter checkboxes on the right so that <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>נביאים
    </span><span dir="LTR" /><span dir="LTR" />&nbsp;and <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כתובים</span><span dir="LTR" /><span dir="LTR" /> are unselected and only <span dir="RTL" /><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />&nbsp;תורה</span><span dir="LTR" /><span dir="LTR" /> is selected.&nbsp; Click the search button
    again and now you will only get 7 pesukim of results ranging from <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בראשית
      יב:ו</span><span dir="LTR" /><span dir="LTR" /> to <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שמות
      לד:ו</span><span dir="LTR" /><span dir="LTR" />.</p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Next click the sefer filter checkboxes so that all are
    selected.&nbsp; Then click the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>נקודות</span><span dir="LTR" /><span dir="LTR" /> button so that now&nbsp; <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>אותיות</span></b><span dir="LTR" /><span dir="LTR" /> and <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>נקודות</span></b><span dir="LTR" /><span dir="LTR" /> are selected (L,N).</p>
  <p className="MsoNormal"><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
  <p className="MsoNormal">Now enter the term <span lang="HE" dir="RTL" style={{fontSize: '18.0pt', fontFamily: '"Arial",sans-serif'}}>וַיַּעֲבֹר</span><span dir="LTR" /><span dir="LTR" /> (being careful to enter <b><u>all</u></b>&nbsp;
    the nekudos with <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>דגש
    </span><span dir="LTR" /><span dir="LTR" />&nbsp;after <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פתח</span><span dir="LTR" /><span dir="LTR" /> in the yud and with <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>חולם
      מלא</span><span dir="LTR" /><span dir="LTR" /> at the
    beis) and click search.&nbsp; You will now get 36 pesukim of results ranging from <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>
בראשית יב:ו
      </span><span dir="LTR" />
      <span dir="LTR" /> to <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>
דברי הימים ב:כא:ט
      </span><span dir="LTR" /><span dir="LTR" />.</p>
  <p className="MsoNormal"><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
  <p className="MsoNormal">Now click the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעמים</span><span dir="LTR" /><span dir="LTR" /> button so that now&nbsp; <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>אותיות</span></b><span dir="LTR" /><span dir="LTR" /> and <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>נקודות</span></b><span dir="LTR" /><span dir="LTR" /> and <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעמים</span></b><span dir="LTR" /><span dir="LTR" /> are selected (L,N,T search).</p>
  <p className="MsoNormal">Enter<span style={{fontSize: '22.0pt'}}> </span><span lang="HE" dir="RTL" style={{fontSize: '22.0pt', fontFamily: '"Arial",sans-serif'}}>וַֽיַּעֲבֹ֔ר</span><span dir="LTR" /><span dir="LTR" />&nbsp; (being careful to enter <b><u>both</u></b>&nbsp;
    taam/accent symbols by entering the <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מתג</span></b><span dir="LTR" /><span dir="LTR" /> after the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פתח-ויו</span><span dir="LTR" /><span dir="LTR" /> and by entering the<b> </b><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>זקף
        קטן</span></b><span dir="LTR" /><span dir="LTR" />
    &nbsp;after the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>חולם-בית</span><span dir="LTR" /><span dir="LTR" />). You will get 2 pesukim of results: <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בראשית
      לב:כג</span><span dir="LTR" /><span dir="LTR" /> and&nbsp; <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שמואל
      א:טו:יב</span><span dir="LTR" /><span dir="LTR" />.</p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Now click the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>נקודות</span><span dir="LTR" /><span dir="LTR" /> button to un-highlight that selection so
    that now only <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>אותיות</span></b><span dir="LTR" /><span dir="LTR" /> and <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעמים</span></b><span dir="LTR" /><span dir="LTR" /> are selected (L,T search).</p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Enter <span lang="HE" dir="RTL" style={{fontSize: '22.0pt', fontFamily: '"Arial",sans-serif'}}>ויעבר֔</span><span dir="LTR" /><span dir="LTR" /> (no nekudos, and a single <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>זקף
        קטן</span></b><span dir="LTR" /><span dir="LTR" /> as
    the final character entered).&nbsp; You will get 2 pesukim of results: <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בראשית
      לב:כג</span><span dir="LTR" /><span dir="LTR" /> and&nbsp; <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שמואל
      א:טו:יב</span><span dir="LTR" /><span dir="LTR" />.
    (<i>see the following note as to why the trop character is placed at the end
      here</i>)</p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal"><i>Note that when using simple search and including nekudos
      and taamim (L, N, T search), the requirements get very exacting where all of
      the proper characters must be entered without exception and in the right sequence.&nbsp;
      Using the advanced search for this is much easier but a setting to indicate
      that the system should do a more relaxed search on N and T might be included in
      the future.&nbsp; When combining letters only with taamim (L, T search), the taam is
      entered a bit differently for convenience at the very end of the word as
      opposed to in the actual place it occurs within a word.&nbsp; This behavior might
      change in the future. &nbsp;In general I will be focusing my approach to searching
      taamim more on a conceptual level as opposed to the underlying characters since
      there are 1) multi-character taamim and 2) fundamentally different taamim that
      share the same character.&nbsp; Right now, it’s still character-based so things can
      get confusing.</i></p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Now click the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעמים</span><span dir="LTR" /><span dir="LTR" /> button to un-highlight that selection and
    then<span dir="RTL" /><span dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> </span>click the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>נקודות</span><span dir="LTR" /><span dir="LTR" /> button so that now&nbsp; <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>אותיות</span></b><span dir="LTR" /><span dir="LTR" /> and <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>נקודות</span></b><span dir="LTR" /><span dir="LTR" /> are selected (L,N).</p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Enter <span lang="HE" dir="RTL" style={{fontSize: '18.0pt', fontFamily: '"Arial",sans-serif'}}>מֹשֶׁה</span><span dir="LTR" /><span style={{fontSize: '18.0pt'}}><span dir="LTR" />??</span> and
    search.&nbsp; You will get 2 results of <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שמות יד:לא </span><span dir="LTR" /><span dir="LTR" />&nbsp;and <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>במדבר
      כא:ה</span><span dir="LTR" /><span dir="LTR" /> due to
    the use of the single letter wildcard character <span style={{fontSize: '18.0pt'}}>? </span>
    which indicates a search for the name Moshe preceded by two prefix letters.</p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Now click the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>נקודות</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>button to
    un-highlight that selection so that now only <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>אותיות</span></b><span dir="LTR" /><span dir="LTR" /> is selected (L search).</p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Enter <span dir="RTL" /><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />*ת*לד*ת*</span><span dir="LTR" /><span dir="LTR" />.&nbsp; You will get 39 pesukim of results from <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בראשית
      ב:ד</span><span dir="LTR" /><span dir="LTR" /> to <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>דברי
      הימים א:כו:לא</span><span dir="LTR" /><span dir="LTR" />.&nbsp; Due to the multi-letter wildcard
    character <span style={{fontSize: '18.0pt'}}>*</span>, this yields all the possible
    spellings of the word <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>תולדות</span><span dir="LTR" /><span dir="LTR" /> together with all prefixes and suffixes.</p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Enter <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><b>וידבר משה אהרן</b></span><span dir="LTR" /><span dir="LTR" />.&nbsp; You will get 15 pesukim of results from <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שמות
      ו:יג</span><span dir="LTR" /><span dir="LTR" /> to <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>במדבר
      יט:א</span></p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal"><i>“The shatnez check”</i></p>
  <p className="MsoNormal">Enter *<span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ש*
      *ע* *ט* *נ* *ז</span><span dir="LTR" /><span dir="LTR" />*.&nbsp; You will get 28 pesukim of results from <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בראשית
      מא:ט</span><span dir="LTR" /><span dir="LTR" /> to <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>דברי
      הימים ב:כט:כא</span><span dir="LTR" /><span dir="LTR" /> – all the places in Tanach where a pasuk
    contains distinct words with the letters of <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שעטנז</span><span dir="LTR" /><span dir="LTR" /> occurring in sequence.</p>
  <p className="MsoNormal"><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
  <p className="MsoNormal">Now above the search bar where the following appears:</p>
  <p className="MsoNormal"><img width={389} height={43} id="Picture 19" src="helpDialogImages/image013.png" /></p>
  <p className="MsoNormal">Click the button all the way on the left <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>לא
        לפי הסדר</span></b><span dir="LTR" /><span dir="LTR" /> so that the search will return matches where the search terms
    are in any order.</p>
  <p className="MsoNormal"><img width={387} height={43} id="Picture 20" src="helpDialogImages/image014.png" /></p>
  <p className="MsoNormal">Searching will now instead return 18 pesukim of results from&nbsp;
    <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שמות
      ד:ל</span><span dir="LTR" /><span dir="LTR" /> to <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>במדבר
      יט:א</span></p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Now click the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><b>שורש</b></span><span dir="LTR" /><span dir="LTR" /> button to highlight that selection so that
    now only <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שורש</span></b><span dir="LTR" /><span dir="LTR" /> is selected (S search).</p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Enter <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><b>שמר שמע עשה</b></span><span dir="LTR" /><span dir="LTR" />.&nbsp; You will get 15 pesukim of results from <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שמות
  טו:כו</span><span dir="LTR" /><span dir="LTR" />
      &nbsp;to <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>קהלת
      ד:יז</span></p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Now click the <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>לפי הסדר</span></b><span dir="LTR" /><span dir="LTR" /> button on top to indicate that the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שרשים</span><span dir="LTR" /><span dir="LTR" /> are to be found in sequence.</p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Enter <span dir="RTL" /><span lang="HE" dir="RTL" style={{fontSize: '18.0pt', fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />*</span><span lang="HE" dir="RTL" style={{fontSize: '18.0pt', fontFamily: '"Arial",sans-serif'}}>עק*ב
      יצחק אברהם</span><span dir="LTR" /><span dir="LTR" />.&nbsp; You will get 2 pesukim of results from <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בראשית
      לה:כז</span><span dir="LTR" /><span dir="LTR" />
    and <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ויקרא
      כו:מב</span></p>
  <p className="MsoNormal"><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
  <p className="MsoNormal"><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
  <p className="MsoNormal">Now click the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ללא
      אותיות</span><span dir="LTR" /><span dir="LTR" /> button to un-highlight <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שורש</span><span dir="LTR" /><span dir="LTR" /> and then click <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>רגיל</span><span dir="LTR" /><span dir="LTR" /> to the lower right so that only that is
    highlighted (G search (gematria)).</p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Enter <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>לתפארת</span></b><span dir="LTR" /><span dir="LTR" />.&nbsp; You will get 3 pesukim as results.</p>
  <p className="MsoNormal">Enter <b>1111</b>.&nbsp; You will get the same 3 pesukim since
    that is the gematria value of <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>לתפארת</span></p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Ente<b>r </b><span dir="RTL" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />30 40 50 60</span></b><span dir="LTR" /><span dir="LTR" />. You will get a single pasuk containing
    words with these gematrios in sequence: <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>במדבר ח:יז</span></p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Now click <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><b>אתבש</b></span><span dir="LTR" /><span dir="LTR" /> to the lower left so that only that is
    highlighted (A search (<b>atbash</b>)).</p>
  <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal">Enter <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>תשמרו</span></b><span dir="LTR" /><span dir="LTR" />.&nbsp; You will get 2 pesukim as results.</p>
  <p className="MsoNormal">Enter <b>946</b>.&nbsp; You will get the same 2 pesukim since
    that is the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>אתבש</span><span dir="LTR" /><span dir="LTR" /> value of <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><b>תשמרו<b /></b></span></p><b><b>
      <p className="MsoNormal">&nbsp;</p>
      <p className="MsoNormal">Enter<b> </b><span dir="RTL" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />60 70 80</span><span dir="LTR" /><span dir="LTR" />.&nbsp; </b>You will get 4 pesukim as results.</p>
    </b></b>
    <p className="MsoNormal">&nbsp;</p>
  <p className="MsoNormal"><b>Searching within Targum:</b></p>
  <p className="MsoNormal">To search words or phrases in Targum, click the dropdown that displays the source of the search. Choose תרגום. Then search for any word you would like to find in תרגום.<img width={85} height={30} id="Picture 19" src="helpDialogImages/mikorDropdown.png" /></p>
  <p className="MsoNormal">If you search for the word <b>ית</b> in the תרגום, you will get many results because ית is a very common word. It appears in 1890 pesukim. When you search from the תרגום, 
  the results automatically open the תרגום column so you can follow between טקסט and תרגום.</p>
  <img width={680} height={143} id="Picture 19" src="helpDialogImages/searchTargumExample.png" />
</div>


      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default SimpleSearch_Examples;