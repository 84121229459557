import React, { Component } from 'react';

class IssuesSpecific extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

          <p /><h5>חיפוש</h5><p />
          <ul>
            <li>
              אי אפשר לחזור על מילה בתיבת החיפוש
            </li>
            <li><h6>בלשונית החיפוש המתקדמת</h6>
              <ul>
                <li>תיבת הסימון כדי לשלול מונח חיפוש (בוליאני "NOT") פועלת באופן לא עקבי.</li>
                <li>האפשרות לציין נקודות וטעמים פועלת באופן אי-אינטואיטיבי או בכלל לא</li>
                </ul>
              </li>
          </ul>
          <p /><h5>פרשיות התנ"ך</h5><p />
          <ul>
            <li>אינו מציג בחינניות התרחשויות של פסקא באמצע פסוק</li>
          </ul>
          <p /><h5>דפדפן התנ"ך</h5><p />
          <ul>
            <li>
              ריחוף מעל מילה יש להדגיש את המילים המתאימות בין נוסח הפסוק
              לנוסח התרגום. יש לראות בכך תכונה ניסיונית לעת עתה. אמנם זה בדרך כלל
              כ95% מדויקים עבור מרבית הפסוקים, הוא נוטה להיות הרבה פחות מדויק עבור קטעים פיוטיות
              כמו ברכת יעקב, ברכת משה, שירת הים.
            </li>
          </ul>

      </>
    } else if (langCode === "en") {
      retVal = <>
        <p /><h5>Search</h5><p />
        <ul>
          <li>One can't repeat a word in the search box</li>
          <li><h6>In the advanced search tab</h6>
            <ul>
              <li>The check box to negate (boolean "NOT") a search term is working inconsistently</li>
              <li>The option to specify nekudos and taamim works either in a counterintuitive way or not at all</li>
            </ul>
          </li>
        </ul>
        <p /><h5>Parsha viewer</h5><p />
        <ul>
          <li>Doesn't gracefully display occurrences of פסקא באמצע פסוק</li>
        </ul>
        <p /><h5>Tanach Viewer</h5><p />
        <ul>
          <li>Hovering over a word(s) will highlight the corresponding word(s) between the pasuk wording and 
            the targum wording.  This should be considered an experimental feature for now.  While this normally is 
            about 95% accurate for most pesukim, it tends to be much less accurate for sections that wax poetic 
            such as ברכת יעקב, ברכת משה, שירת הים.</li>
        </ul>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default IssuesSpecific;