import React, { Component } from 'react';

class Mission extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p>ליצור ולספק <b>תוכנה ושירותים</b> עבור <b>מוסדות ויחידים</b> כדי שיוכלו להשיג תובנה, ניתוח והוקרה של התורה המעוררות את המחשבה, כדי להגדיל תורה ולהאדירה.</p>
        <p>זה מתחיל ב<b>בייס-הספר</b> - מנוע חיפוש אנליטי רב עוצמה עבור התנ"ך שמחבק באופן מלא <b>מודל מסד נתונים יחסי</b> עם טקסט מקורי בעברית וארמית.</p>
        <p>רוב כלי התוכנה התורניים האחרים נועדו לחפש טקסט תורני ולהפיק רשימות של מיקומים תואמים.</p>
        <p><b>בייס-הספר</b> מרחיק לכת בכך שהוא מאפשר למשתמשים לבצע שאילתות בבסיס הנתונים החזק שלנו. השאלות שלהם לא יוגבלו לטקסט גולמי ויכולות אפילו להתפרש על פני קטגוריות מרובות של תוכן ונתונים קשורים זה בזה. התוצאות והצפיות אינן מוגבלות ליצירת רשימות של מיקומי התאמה ויכולות אפילו לייצר פירוט סטטיסטי ודרכים חדשניות להצגת תוכן.</p>
        <p>אנשים יכולים כעת ללמוד ידע תורני שהיה יכול להיות חמקמק, מכיוון שהם <b>חוסכים שעות ושבועות של זמן</b> כאשר השאלות המורכבות שלהם נענות בלחיצת עכבר אחת.</p>

      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <p>To create and provide <b>software and services</b> for <b>institutions and individuals </b> 
          so they can gain thought-provoking insight, analysis and appreciation of the Torah, כדי להגדיל תורה ולהאדירה.</p>
        <p>It begins with <b>Base HaSefer</b> - a powerful analytical search engine for the Tanach that fully embraces a <b>relational database</b> model with original Hebrew and Aramaic text.</p>
        <p>Most other Torah software tools are designed to search Torah text and produce lists of matching locations.</p>
        <p><b>Base HaSefer</b> goes further by enabling users to <b>query our powerful database</b>.  Their questions 
          won't be limited to raw text and can even span across multiple categories of interrelated content and data. 
          The results and views aren't limited to generating lists of matching locations and can even produce statistical breakdowns 
          and innovative ways of presenting content.</p>
        <p>People can now pursue otherwise elusive Torah knowledge, as they <b>save hours and weeks of time</b> when their complex questions are answered 
          with a single mouse-click.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default Mission;