import React from "react";
import './css/App.css';
import { Dropdown, DropdownToggle} from 'reactstrap';
import { withLocalize, Translate } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import translations from "./json/translations.json";

let language;
const LanguageToggle = ({ languages, activeLanguage, setActiveLanguage }) => (
    <Dropdown direction="down" className="language-toggle">
        <DropdownToggle caret className="dropdownToggle-small"
                        onClick={() => {
                            setActiveLanguage(language = (activeLanguage.code == "he" ? "en" : "he"));
                            localStorage.setItem("language", language);
                            localStorage.setItem("helpDialogStartupTopic", "topBar");
                        }}>
          <Translate id="uiLanguage.caption" />&nbsp;
        </DropdownToggle>
    </Dropdown>
);

export default withLocalize(LanguageToggle);