import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { Link, withRouter } from 'react-router-dom';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Checkbox, Typography, Button, FormControlLabel, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as properties from './config/properties.js';
import SettingsIcon from '@material-ui/icons/Settings';

const styles = theme => ({
  toggleContainer: {
    height: 28,
    backgroundColor: 'transparent',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    alignItems: 'center',
    margin: `auto`,
    background: theme.palette.background.default,
  },
});

class DiffViewerDisplaySettings extends Component {

 constructor(props) {
    super(props);
    this.state = {
      diffUnit: 'word',
      highlightCondition: 'different',
      removeWhiteSpace: false,
      removeLineBreaks: false,
      isSplitView: true,
      isOpen: false,
      anchorEl: null,
      removeTaameiMikra: true,
      removeNikud: true,
      isIndependentScroll: false,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.toggleTaameiMikra = this.toggleTaameiMikra.bind(this);
    this.toggleIndependentScroll = this.toggleIndependentScroll.bind(this);


    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}
  }

  handleOpen(e){
    this.setState({
      isOpen: true,
      anchorEl: e.currentTarget
    })
  }

  handleClose(){
    this.setState({
      isOpen: false,
      anchorEl: null
    })
  }

  setDiffUnit(e, val){
    this.props.extSetDiffUnit(e, val);
  }

  setHighlightCondition(e, val){
    this.props.extSetHighlightCondition(e, val);
  }

  toggleIndependentScroll(e){
    this.props.extToggleIndependentScroll(e);
  }

  toggleRemoveWhiteSpace(e){
    this.props.extToggleRemoveWhiteSpace(e);
  }

  toggleRemoveLineBreaks(e){
    this.props.extToggleRemoveLineBreaks(e);
  }

  toggleSplitView(e){
    this.props.extToggleSplitView(e);
  }

  toggleTaameiMikra(e){
    this.props.extToggleTaameiMikra(e);
  }

  toggleNikud(e){
    this.props.extToggleNikud(e);
  }

  render() {
    const { classes } = this.props;
    const unit = this.props.translate("unit");
    const char = this.props.translate("char");
    const word = this.props.translate("word");
    const line = this.props.translate("line");
    const removeWhiteSpace = this.props.translate("removeWhiteSpace");
    const removeLineBreaks = this.props.translate("removeLineBreaks");
    const splitView = this.props.translate("splitView");
    const highlightIf = this.props.translate("highlightIf");
    const same = this.props.translate("same");
    const different = this.props.translate("different");
    const displaySettings = this.props.translate("displaySettings");
    const removeTaameiMikra = this.props.translate("removeTaameiMikra");
    const removeNikud = this.props.translate("removeNikud");
    const enableIndependentScroll = this.props.translate("enableIndependentScroll");

    var isHebrew = localStorage.getItem('language') === 'he';

    var directionStyling = {direction: 'ltr', textAlign: 'left'};
    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
      directionStyling = {direction: 'rtl', textAlign: 'right'};
    }

    var searchSettingsInputCellStyle = {
      textAlign: isHebrew ? "left" : "right"
    }

    var searchSettingsLabelCellStyle = {
      textAlign: directionStyling.textAlign
    }

    return (<span>
              <Button variant="default" style={{ verticalAlign: '-8px'}} className="LCS-open-settings-button" onClick={(e) => {this.handleOpen(e)}}>
                <SettingsIcon/>&nbsp;{displaySettings}
              </Button>

              <Popover
                open={this.state.isOpen}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: `${isHebrew ? "right" : "left"}`,
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: `${isHebrew ? "right" : "left"}`,
                }}
                elevation={1}
                className="placement-selector-2D-popover"
                style={{
                  direction: `${isHebrew ? 'rtl' : 'ltr'}`,
                }}
                classes={{ paper: "LCS-search-settings-popover-paper" }}
              >

                <table className="LCS-search-setting-parent-table">
                  <tbody>
                    <tr>
                      <td style={searchSettingsLabelCellStyle}>
                        <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{unit}:</Typography>&nbsp;
                      </td>
                      <td style={searchSettingsInputCellStyle}>
                        <ToggleButtonGroup className="tanachViewerToggleButtonGroup" value={this.props.diffUnit} exclusive onChange={(e, val) => {this.setDiffUnit(e, val)}}>
                          <ToggleButton className="text-comparator-viewer-toggle-button" value="character"> {char} </ToggleButton>
                          <ToggleButton className="text-comparator-viewer-toggle-button" value="word"> {word} </ToggleButton>
                          <ToggleButton className="text-comparator-viewer-toggle-button" value="line"> {line} </ToggleButton>
                        </ToggleButtonGroup>&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td style={searchSettingsLabelCellStyle}>
                        <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{highlightIf}:</Typography>&nbsp;
                      </td>
                      <td style={searchSettingsInputCellStyle}>
                        <ToggleButtonGroup className="tanachViewerToggleButtonGroup" value={this.props.highlightCondition} exclusive onChange={(e, val) => {this.setHighlightCondition(e, val)}}>
                          <ToggleButton className="text-comparator-viewer-toggle-button" value="same"> {same} </ToggleButton>
                          <ToggleButton className="text-comparator-viewer-toggle-button" value="different"> {different} </ToggleButton>
                        </ToggleButtonGroup>&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td style={searchSettingsLabelCellStyle} colSpan="2">
                        <FormControlLabel
                          className="diff-viewer-remove-white-space"
                          control={
                            <Checkbox
                              checked={this.props.isSplitView}
                              onChange={(e) => {this.toggleSplitView(e)}}
                              value="isSplitView"
                              color="primary"
                              size="small"
                              classes={{
                                root: "diff-viewer-remove-white-space-root",
                              }}
                            />
                          }
                          label={`${splitView}`}
                          classes={{
                            root: "diff-viewer-remove-white-space-label",
                          }}
                        />&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td style={searchSettingsLabelCellStyle} colSpan="2">
                        <FormControlLabel
                          className="diff-viewer-remove-white-space"
                          control={
                            <Checkbox
                              checked={this.props.removeWhiteSpace}
                              onChange={(e) => {this.toggleRemoveWhiteSpace(e)}}
                              value="removeWhiteSpace"
                              color="primary"
                              size="small"
                              classes={{
                                root: "diff-viewer-remove-white-space-root",
                              }}
                            />
                          }
                          label={`${removeWhiteSpace}`}
                          classes={{
                            root: "diff-viewer-remove-white-space-label",
                          }}
                        />&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td style={searchSettingsLabelCellStyle} colSpan="2">
                        <FormControlLabel
                          className="diff-viewer-remove-white-space"
                          control={
                            <Checkbox
                              checked={this.props.removeLineBreaks}
                              onChange={(e) => {this.toggleRemoveLineBreaks(e)}}
                              value="removeLineBreaks"
                              color="primary"
                              size="small"
                              classes={{
                                root: "diff-viewer-remove-white-space-root",
                              }}
                            />
                          }
                          label={`${removeLineBreaks}`}
                          classes={{
                            root: "diff-viewer-remove-white-space-label",
                          }}
                        />&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td style={searchSettingsLabelCellStyle} colSpan="2">
                        <FormControlLabel
                          className="diff-viewer-remove-white-space"
                          control={
                            <Checkbox
                              checked={this.props.removeTaameiMikra}
                              onChange={(e) => {this.toggleTaameiMikra(e)}}
                              value="isSplitView"
                              color="primary"
                              size="small"
                              classes={{
                                root: "diff-viewer-remove-white-space-root",
                              }}
                            />
                          }
                          label={`${removeTaameiMikra}`}
                          classes={{
                            root: "diff-viewer-remove-white-space-label",
                          }}
                        />&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td style={searchSettingsLabelCellStyle} colSpan="2">
                        <FormControlLabel
                          className="diff-viewer-remove-white-space"
                          control={
                            <Checkbox
                              checked={this.props.removeNikud}
                              onChange={(e) => {this.toggleNikud(e)}}
                              value="isSplitView"
                              color="primary"
                              size="small"
                              classes={{
                                root: "diff-viewer-remove-white-space-root",
                              }}
                            />
                          }
                          label={`${removeNikud}`}
                          classes={{
                            root: "diff-viewer-remove-white-space-label",
                          }}
                        />&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td style={searchSettingsLabelCellStyle} colSpan="2">
                        <FormControlLabel
                          className="diff-viewer-remove-white-space"
                          control={
                            <Checkbox
                              checked={this.props.isIndependentScroll}
                              onChange={(e) => {this.toggleIndependentScroll(e)}}
                              value="isSplitView"
                              color="primary"
                              size="small"
                              classes={{
                                root: "diff-viewer-remove-white-space-root",
                              }}
                            />
                          }
                          label={`${enableIndependentScroll}`}
                          classes={{
                            root: "diff-viewer-remove-white-space-label",
                          }}
                        />&nbsp;
                      </td>
                    </tr>

                  </tbody>
                </table>
              </Popover>
        </span>
    );
  }
}

DiffViewerDisplaySettings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withLocalize(DiffViewerDisplaySettings));
