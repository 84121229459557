import React, { Component } from 'react';
import styles from './css/App.css'; //for customizing reactStrap styles
import * as properties from './config/properties.js';
import { DialogTitle, DialogContent, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withLocalize, Translate } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import translations from "./json/translations.json";
import {withRouter} from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { Rnd } from "react-rnd";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './css/HelpDialog.css';
//import DropdownTreeSelect from 'react-dropdown-tree-select' //mje Mar 2, 2020 - this package needs to be installed but now that react-hierarchical-select is installed, don't need to import it and merely need its CSS file
import 'react-dropdown-tree-select/dist/styles.css'
import HierarchicalSelect from 'react-hierarchical-select';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ReactPlayer from 'react-player/youtube'

import AdvancedLayout_Examples from './dialogTexts/help/AdvancedLayout_Examples';
import AdvancedLayout_Instructions from './dialogTexts/help/AdvancedLayout_Instructions';
import Layout_Instructions from './dialogTexts/help/Layout_Instructions';
import Layout_Examples from './dialogTexts/help/Layout_Examples';
import TopBar_Instructions from './dialogTexts/help/TopBar_Instructions';
import TopBar_Examples from './dialogTexts/help/TopBar_Examples';
import RightBar_Instructions from './dialogTexts/help/RightBar_Instructions';
import RightBar_Examples from './dialogTexts/help/RightBar_Examples';
import DirectSiteLinks_Instructions from './dialogTexts/help/DirectSiteLinks_Instructions';
import DirectSiteLinks_Examples from './dialogTexts/help/DirectSiteLinks_Examples';
import Login_Instructions from './dialogTexts/help/Login_Instructions';
import Login_Examples from './dialogTexts/help/Login_Examples';
import SimpleSearch_Instructions from './dialogTexts/help/SimpleSearch_Instructions';
import SimpleSearch_Examples from './dialogTexts/help/SimpleSearch_Examples';
import LoadSaveSettings_Instructions from './dialogTexts/help/LoadSaveSettings_Instructions';
import LoadSaveSettings_Examples from './dialogTexts/help/LoadSaveSettings_Examples';
import AdvancedSettings_Instructions from './dialogTexts/help/AdvancedSettings_Instructions';
import AdvancedSettings_Examples from './dialogTexts/help/AdvancedSettings_Examples';
import LNT_Instructions from './dialogTexts/help/LNT_Instructions';
import LNT_Examples from './dialogTexts/help/LNT_Examples';
import Distances_Instructions from './dialogTexts/help/Distances_Instructions';
import Distances_Examples from './dialogTexts/help/Distances_Examples';
import POS_Instructions from './dialogTexts/help/POS_Instructions';
import POS_Examples from './dialogTexts/help/POS_Examples';
import IncludeExclude_Instructions from './dialogTexts/help/IncludeExclude_Instructions';
import IncludeExclude_Examples from './dialogTexts/help/IncludeExclude_Examples';
import Placement_Instructions from './dialogTexts/help/Placement_Instructions';
import Placement_Examples from './dialogTexts/help/Placement_Examples';
import SearchResults_Instructions from './dialogTexts/help/SearchResults_Instructions';
import SearchResults_Examples from './dialogTexts/help/SearchResults_Examples';
import LoadSave_Instructions from './dialogTexts/help/LoadSave_Instructions';
import LoadSave_Examples from './dialogTexts/help/LoadSave_Examples';
import ParshaViewer_Instructions from './dialogTexts/help/ParshaViewer_Instructions';
import ParshaViewer_Examples from './dialogTexts/help/ParshaViewer_Examples';
import FrequencyViewer_Instructions from './dialogTexts/help/FrequencyViewer_Instructions';
import FrequencyViewer_Examples from './dialogTexts/help/FrequencyViewer_Examples';
import TextComparatorOptions_Instructions from './dialogTexts/help/TextComparatorOptions_Instructions';
import TextComparatorOptions_Examples from './dialogTexts/help/TextComparatorOptions_Examples';
import CommonTerms_Instructions from './dialogTexts/help/CommonTerms_Instructions';
import CommonTerms_Examples from './dialogTexts/help/CommonTerms_Examples';
import LCS_Instructions from './dialogTexts/help/LCS_Instructions';
import LCS_Examples from './dialogTexts/help/LCS_Examples';
import Diff_Instructions from './dialogTexts/help/Diff_Instructions';
import Diff_Examples from './dialogTexts/help/Diff_Examples';
import TanachViewer_Instructions from './dialogTexts/help/TanachViewer_Instructions';
import TanachViewer_Examples from './dialogTexts/help/TanachViewer_Examples';
import TaamParser_Instructions from './dialogTexts/help/TaamParser_Instructions';
import TaamParser_Examples from './dialogTexts/help/TaamParser_Examples';
import ObjectCloudComparator_Instructions from './dialogTexts/help/ObjectCloudComparator_Instructions';
import ObjectCloudComparator_Examples from './dialogTexts/help/ObjectCloudComparator_Examples';
import ObjectCloudViewer_Instructions from './dialogTexts/help/ObjectCloudViewer_Instructions';
import ObjectCloudViewer_Examples from './dialogTexts/help/ObjectCloudViewer_Examples';

class HelpDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      started: false,
      sectionTextData: {},
      // instructionsHTML: "",
      // examplesHTML: "",
      panelIsOpenData: {
        versionInformation: false,
        instructions: false,
        examples: false,
      },
      zIndex: 801,
      dropdownValue: "",
      currTopic: ""
    };

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true }
    });

    this.props.addTranslation(translations);

    this.toggleHelpDialog = this.toggleHelpDialog.bind(this);

  }
  toggleHelpDialog(boolean) {
      this.setState({ helpDialogOpen: boolean });
  }

  toggleExpansionPanel(panelName){
    var panelIsOpenData = this.state.panelIsOpenData;
    panelIsOpenData[panelName] = !panelIsOpenData[panelName];
    this.setState({
      panelIsOpenData
    })
  }

  expandAll(){
    var panelIsOpenData = this.state.panelIsOpenData
    var panels = Object.keys(panelIsOpenData);
    for(let i = 0; i < panels.length; i++){
      panelIsOpenData[panels[i]] = true;
    }
    this.setState({
      panelIsOpenData
    })
  }

  collapseAll(){
    var panelIsOpenData = this.state.panelIsOpenData
    var panels = Object.keys(panelIsOpenData);
    for(let i = 0; i < panels.length; i++){
      panelIsOpenData[panels[i]] = false;
    }
    this.setState({
      panelIsOpenData
    })
  }
  
  // NO LONGER PULLING TEXTS FROM TXT FILES
  // fetchContentFile(topicName, sectionName, languageCode) {
  //   const file = require("./dialogTexts/help/" + topicName + "_" + sectionName + "_" + languageCode + ".txt"); //need an extension that's not HTML since in that case, the require() will generate an error since it's not expecting an HTML fragment

  //     var sectionTextData;
  //     var rawFile = new XMLHttpRequest();
  //     rawFile.open("GET", file, false);
  //     rawFile.onreadystatechange = () => {
  //         if (rawFile.readyState === 4) {
  //             if (rawFile.status === 200 || rawFile.status == 0)
  //                 sectionTextData = rawFile.responseText;
  //         }
  //     };
  //     rawFile.send(null);
  //     return sectionTextData;
  // }

  componentDidUpdate(prevProps, prevState, snapshot){
    this.setDropdownMutationObserver()
    if(prevProps.isOpen !== this.props.isOpen){
      this.selectTopic(this.props.startupTopic);
      if(!this.props.isOpen){
        this.collapseAll();
      } else {
        this.showOnTop();
        //if initialized in full screen mode, the next time the Help window is opened it will look like full screen mode unless the user resized the window since the window remembers its previous size
        if (this.props.isFirstTimeOpened && this.props.initialFullScreen && "TY".indexOf(this.props.initialFullScreen.toUpperCase()) >= 0) {
            this.handleFullscreen();
            this.props.extSetFirstTimeOpened();
        }
      }
    }
  }

  onClose(){
    this.setState({dropdownValue:" "})//this.state.dropdownValue.substring(0,0)})
    this.props.onClose();
  }

  handleCopyLink() {
    var params = ["topic=" + this.state.currTopic];
    this.props.handleCopyLink("help", params, this.props.isFullScreen(this.props.x, this.props.y, this.props.heightNum,this.props.widthNum));
  }

  handleFullscreen(){
    const x = 0;
    const y = 0;
    const heightNum = window.innerHeight;
    const widthNum = window.innerWidth;
    this.props.setSize(widthNum, heightNum, x, y)
  }

  handleRestoreScreenSize(){
    this.props.resetPosition()
  }

  handleTitleDoubleClick(e){
    //if clicked on dropdown, cancel
    var currElem = e.target;
    while(!currElem.id.split(" ").includes("help-dialog-title")){
      if(currElem.className.split(" ").includes("help-dropdown")){
        return false;
      }
      currElem = currElem.parentElement;
    }
    //change size
    const isFullscreen = this.props.widthNum === window.innerWidth && this.props.heightNum === window.innerHeight && this.props.x === 0 && this.props.y === 0;
    if(isFullscreen) {
      this.handleRestoreScreenSize();
    } else {
      this.handleFullscreen();
    }
  }

  findHighestZIndex(elem) {
    var elems = document.getElementsByTagName("*");
    var highest = 0;
    for (var i = 0; i < elems.length; i++)
    {
      var zindex=document.defaultView.getComputedStyle(elems[i],null).getPropertyValue("z-index");
      if ((zindex > highest) && (zindex != 'auto') && (zindex != 9998) && (zindex != 100000090))
      {
        highest = zindex;
      }
    }
    return parseFloat(highest);
  }

  showOnTop(){
    this.setState({
      zIndex: this.findHighestZIndex() + 1
    })
  }
  handleClick(e){
    this.showOnTop();
  }

  selectTopic(topic) {
      // var instructions, examples;
      // const languageCode = (this.props.activeLanguage && this.props.activeLanguage.code === "he") ? "he" : "en";

      // if (["layout", "topBar", "rightBar", "login", "simpleSearch", "advancedLayout", "loadSaveSettings", "advancedSettings", "lnt", "distances", "includeExclude", "placement",
      //      "searchResults", "loadSave", "parshaViewer", "frequencyViewer",
      //      "textComparatorOptions", "commonTerms", "lcs", "diff", "tanachViewer", "taamParser"].includes(topic)) {
      //     instructions = this.fetchContentFile(topic, "instructions", languageCode);
      //     examples = this.fetchContentFile(topic, "examples", languageCode);
      //     instructions = helpFiles[topic].instructions;
      //     examples = helpFiles[topic].examples;
      // }
      // else {
      //     instructions = "Enter or select a topic above.";
      //     examples = "Enter or select a topic above.";
      // }

      //localStorage.setItem("helpDialogStartupTopic", null);
      this.setState({
          // instructionsHTML: instructions,
          // examplesHTML: examples,
          currTopic: topic,
          started: true,
      })
  }

  onMenuChange = (currentNode, selectedNodes) => {
      //alert('onMenuChange');
//      const inputs = document.querySelectorAll("input.search");
//      if (inputs.length == 0 || inputs[0].value == null || (inputs[0].value == "" && !currentNode.checked))
//      //alert(inputs.length + ":" + inputs[0].value);
//          return;

      if (currentNode.value == null && (this.state.started || this.props.startupTopic == null))
          return false;

      const trigger = document.getElementById("rdts1_trigger");
      trigger.click(); // adding this so dropdown closes properly after menu item selection
      this.setState({
        dropdownValue: currentNode.label//" "
      }, () => {
        this.setState({
          dropdownValue: currentNode.label// ""
        })
      })



/*
tagName <pseudo:after>
*/

      this.selectTopic(currentNode.value != null ? currentNode.value : this.props.startupTopic);
      currentNode.checked = false;
      //inputs[0].value = "";
  }

  onMenuNodeToggle = currentNode => {

  }

  onMenuAction = ({ action, node }) => {
    
  }

  formatDropdown(){
    const isHebrew = this.props.activeLanguage && this.props.activeLanguage.code === "he";
    const helpDialog = document.getElementsByClassName("help-rnd")[0];
    if(isHebrew){
      const nodes = helpDialog.getElementsByClassName("node")
      for(let i = 0; i < nodes.length; i++){
        var currentNode = nodes[i]
        var nodeId = currentNode.id;
        var nodePosVal = nodeId.split("rdts1-")[1].split("_")[0];
        var nodeLevel = nodePosVal.split("-").length;
        currentNode.style.paddingLeft = '4px';
        currentNode.style.paddingRight = `${4 + 20*(nodeLevel-1)}px`;
        
      }
      
    } else {
      //ltr formatting is automatic
    }

    //set search match highlight color
    const dropdown = document.getElementsByClassName("dropdown-content")[0];
    if(dropdown){
      const dropdownSpans = dropdown.getElementsByTagName("span");
      for(let i = 0; i < dropdownSpans.length; i++){
        var currSpan = dropdownSpans[i]
        var spanColor = currSpan.style.color;
        if(spanColor === "rgb(235, 145, 16)"){
          currSpan.style.color = "dodgerblue";
        }
      }
    }


  }

  setDropdownMutationObserver(){
    const fakeThis = this;
    // using MutationObserver to detect changes in dropdown and format accordingly
    const targetNode = document.getElementsByClassName("dropdown-content")[0];
    if(targetNode){
      const config = { attributes: true, childList: true, subtree: true };
      const callback = function(mutationsList, observer) {
        fakeThis.formatDropdown();
      };
      const observer = new MutationObserver(callback);

      observer.observe(targetNode, config);
    }
  }

  componentDidMount(){
    const trigger = document.getElementById("rdts1_trigger");
    const fakeThis = this;
    if(trigger){ //adding conditional to prevent bug in admin page
      trigger.addEventListener("click", function(){
        fakeThis.formatDropdown();
      });
    }

    this.setDropdownMutationObserver();
  }


  render() {
    const { classes } = this.props;
    const helpTR = this.props.translate("help.caption");
    const instructionsTR = this.props.translate("instructions");
    const examplesTR = this.props.translate("examples");
    const tutorialsTR = this.props.translate("tutorials");
    const allTutorialsTR = this.props.translate("allTutorials");
    const generalLayoutUsage = this.props.translate("generalLayoutUsage");
    const layoutSite = this.props.translate("layoutSite");
    const navigationBarTop = this.props.translate("navigationBarTop");
    const settingsBarRight = this.props.translate("settingsBarRight");
    const directSiteLinks = this.props.translate("directSiteLinks");
    const loginRegister = this.props.translate("loginRegister");
    const searchTheTanach = this.props.translate("searchTheTanach");
    const simpleSearch = this.props.translate("simpleSearchLong");
    const advancedSearch = this.props.translate("advancedSearch.caption");
    const layoutAdvancedSearch = this.props.translate("layoutAdvancedSearch");
    const loadSaveSettings = this.props.translate("loadSaveSettings");
    const generalSettings = this.props.translate("generalSettings");
    const lettersNekudosTaamim = this.props.translate("advTabVowelizeCantillate");
    const distances = this.props.translate("distances");
    const pos = this.props.translate("pos");
    const pasukSettings = this.props.translate("advTabPasukSettings");
    const placementInTanach = this.props.translate("placementInTanach");
    const searchResults = this.props.translate("searchResults");
    const loadSaveResults = this.props.translate("loadSaveResults");
    const contentPages = this.props.translate("contentPages");
    const parshaViewer = this.props.translate("parshaViewer");
    const frequencyViewer = this.props.translate("frequencyMenuItem.caption");
    const textComparator = this.props.translate("textComparator.caption");
    const optionsAndSettings = this.props.translate("optionsAndSettings");
    const commonTerms = this.props.translate("commonTerms");
    const commonSubsequences = this.props.translate("commonSubsequences");
    const differences = this.props.translate("differences");
    const tanachViewer = this.props.translate("tanachViewer");
    const taamParser = this.props.translate("taamParser");
    const objectClouds = this.props.translate("objectClouds");
    const objectCloudViewer = this.props.translate("objectCloudViewer");
    const fullScreen = this.props.translate("fullScreen");
    const restore = this.props.translate("restore");
    const close = this.props.translate('close');
    const copyLink = this.props.translate('copyLink');
    const videoChannel = this.props.translate('videoChannel');
    const tutorialPlaylist = this.props.translate('tutorialPlaylist');
    const allTutorialsTxt1 = this.props.translate('allTutorialsTxt1');
    const allTutorialsTxt2 = this.props.translate('allTutorialsTxt2');
    const allTutorialsTxt3 = this.props.translate('allTutorialsTxt3');
    const noTutorialAvailable = this.props.translate('noTutorialAvailable');

    const isHebrew = this.props.activeLanguage && this.props.activeLanguage.code === "he";

    var directionStyling = {direction: 'ltr', textAlign: 'left'};
    if(isHebrew){
        directionStyling = {direction: 'rtl', textAlign: 'right'};
    }

    const searchTR = this.props.translate("helpSearch");

    const youTubeURL = "https://www.youtube.com/watch?v=";
    var tutorialURL1 = youTubeURL + "NsLIzvTgVn8";
    var tutorialURL2 = youTubeURL + "Ca4ZjPTTHFc";
    var tutorialURL3 = youTubeURL + "fwp7b_ACYBk";
    var tutorialURL4 = youTubeURL + "djgFsYDe5tU";
    var tutorialURL5 = youTubeURL + "4daiCYjFnM0";

    var channelURL = "https://www.youtube.com/channel/UC-fxXytYTbdbZg5ky5kFw1g";
    var playListURL = youTubeURL + "NsLIzvTgVn8&list=PL_mq8ftc97s46a2K00Rv8ytKOxU_NKp_n";

    var aspectRatio = 876/1899;

    const allTutorialsWidth = this.props.widthNum-72;
    const allTutorialsHeight = aspectRatio*(this.props.widthNum-72);

    var instructionsText, examplesText, tutorialsText;
    if (["layout", "topBar", "rightBar", "directSiteLinks", "login", "simpleSearch", "advancedLayout", "loadSaveSettings", "advancedSettings", "lnt", "distances", "pos", "pasukSettings", "placement",
        "searchResults", "loadSave", "parshaViewer", "frequencyViewer",
        "textComparatorOptions", "commonTerms", "lcs", "diff", "tanachViewer", "taamParser", "objectCloudViewer", "objectCloudComparator"].includes(this.state.currTopic)) {
      const helpFiles = {
        "layout": {instructions: <Layout_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <Layout_Examples langCode={isHebrew ? "he" : "en"} />, tutorials: <ReactPlayer url={tutorialURL1} width={this.props.widthNum-50} height={Math.min(9*((this.props.widthNum-50)/16), this.props.heightNum-200)} controls={true} loop={true} />},
        "topBar": {instructions: <TopBar_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <TopBar_Examples langCode={isHebrew ? "he" : "en"} />, tutorials: <ReactPlayer url={tutorialURL1} width={this.props.widthNum-50} height={Math.min(9*((this.props.widthNum-50)/16), this.props.heightNum-200)} controls={true} loop={true} />},
        "rightBar": {instructions: <RightBar_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <RightBar_Examples langCode={isHebrew ? "he" : "en"} />, tutorials: <ReactPlayer url={tutorialURL1} width={this.props.widthNum-50} height={Math.min(9*((this.props.widthNum-50)/16), this.props.heightNum-200)} controls={true} loop={true} />},
        "directSiteLinks": {instructions: <DirectSiteLinks_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <DirectSiteLinks_Examples langCode={isHebrew ? "he" : "en"} />},
        "login": {instructions: <Login_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <Login_Examples langCode={isHebrew ? "he" : "en"} />},
        "simpleSearch": {instructions: <SimpleSearch_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <SimpleSearch_Examples langCode={isHebrew ? "he" : "en"} />, tutorials: <ReactPlayer url={tutorialURL2} width={this.props.widthNum-50} height={Math.min(9*((this.props.widthNum-50)/16), this.props.heightNum-200)} controls={true} loop={true} />},
        "advancedLayout": {instructions: <AdvancedLayout_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <AdvancedLayout_Examples langCode={isHebrew ? "he" : "en"} />},
        "loadSaveSettings": {instructions: <LoadSaveSettings_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <LoadSaveSettings_Examples langCode={isHebrew ? "he" : "en"} />},
        "advancedSettings": {instructions: <AdvancedSettings_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <AdvancedSettings_Examples langCode={isHebrew ? "he" : "en"} />},
        "lnt": {instructions: <LNT_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <LNT_Examples langCode={isHebrew ? "he" : "en"} />},
        "distances": {instructions: <Distances_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <Distances_Examples langCode={isHebrew ? "he" : "en"} />},
        "pos": {instructions: <POS_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <POS_Examples langCode={isHebrew ? "he" : "en"} />, tutorials: <ReactPlayer url={tutorialURL4} width={this.props.widthNum-50} height={Math.min(9*((this.props.widthNum-50)/16), this.props.heightNum-200)} controls={true} loop={true} />},
        "pasukSettings": {instructions: <IncludeExclude_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <IncludeExclude_Examples langCode={isHebrew ? "he" : "en"} />, tutorials:
            <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                <ReactPlayer url={tutorialURL3} width={this.props.widthNum-50} height={Math.min(9*((this.props.widthNum-50)/16), this.props.heightNum-200)} controls={true} loop={true} /><br />
                <ReactPlayer url={tutorialURL5} width={this.props.widthNum-50} height={Math.min(9*((this.props.widthNum-50)/16), this.props.heightNum-200)} controls={true} loop={true} />
            </div>},
        "placement": {instructions: <Placement_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <Placement_Examples langCode={isHebrew ? "he" : "en"} />},
        "searchResults": {instructions: <SearchResults_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <SearchResults_Examples langCode={isHebrew ? "he" : "en"} />},
        "loadSave": {instructions: <LoadSave_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <LoadSave_Examples langCode={isHebrew ? "he" : "en"} />},
        "parshaViewer": {instructions: <ParshaViewer_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <ParshaViewer_Examples langCode={isHebrew ? "he" : "en"} />},
        "frequencyViewer": {instructions: <FrequencyViewer_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <FrequencyViewer_Examples langCode={isHebrew ? "he" : "en"} />},
        "textComparatorOptions": {instructions: <TextComparatorOptions_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <TextComparatorOptions_Examples langCode={isHebrew ? "he" : "en"} />},
        "commonTerms": {instructions: <CommonTerms_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <CommonTerms_Examples langCode={isHebrew ? "he" : "en"} />},
        "lcs": {instructions: <LCS_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <LCS_Examples langCode={isHebrew ? "he" : "en"} />},
        "diff": {instructions: <Diff_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <Diff_Examples langCode={isHebrew ? "he" : "en"} />},
        "tanachViewer": {instructions: <TanachViewer_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <TanachViewer_Examples langCode={isHebrew ? "he" : "en"} />},
        "taamParser": {instructions: <TaamParser_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <TaamParser_Examples langCode={isHebrew ? "he" : "en"} />},
        "objectCloudViewer": {instructions: <ObjectCloudViewer_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <ObjectCloudViewer_Examples langCode={isHebrew ? "he" : "en"} />},
        "objectCloudComparator": {instructions: <ObjectCloudComparator_Instructions langCode={isHebrew ? "he" : "en"} />, examples: <ObjectCloudComparator_Examples langCode={isHebrew ? "he" : "en"} />},
      }
      instructionsText = helpFiles[this.state.currTopic].instructions;
      examplesText = helpFiles[this.state.currTopic].examples;
      tutorialsText = helpFiles[this.state.currTopic].tutorials != null ? helpFiles[this.state.currTopic].tutorials : noTutorialAvailable;
    }
    else {
      instructionsText = "Enter or select a topic above.";
      examplesText = "Enter or select a topic above.";
      tutorialsText = "Enter or select a topic above.";
    }

    const menuData = [
            {
                label: generalLayoutUsage, disabled: true,
                children: [
                    {label: layoutSite, value: 'layout'},
                    {label: navigationBarTop, value: 'topBar'},
                    {label: settingsBarRight, value: 'rightBar'},
                    {label: directSiteLinks, value: 'directSiteLinks'},
                    {label: loginRegister, value: 'login'}
                ]
            },
            {
                label: searchTheTanach, disabled: true,
                children: [
                    {label: simpleSearch, value: 'simpleSearch'},
                    {label: advancedSearch, disabled: true,
                        children: [
                            {label: layoutAdvancedSearch, value: 'advancedLayout'},
                            {label: loadSaveSettings, value: 'loadSaveSettings'},
                            {label: generalSettings, value: 'advancedSettings'},
                            {label: lettersNekudosTaamim, value: 'lnt'},
                            {label: distances, value: 'distances'},
                            {label: pos, value: 'pos'},
                            {label: pasukSettings, value: 'pasukSettings'},
                            {label: placementInTanach, value: 'placement'}
                        ]
                    },
                    {label: searchResults, value: 'searchResults'},
                    {label: loadSaveResults, value: 'loadSave'}
                ]
            },
            {
                label: contentPages, disabled: true,
                children: [
                    {label: parshaViewer, value: 'parshaViewer'},
                    {label: frequencyViewer, value: 'frequencyViewer'},
                    {label: textComparator, disabled: true,
                        children: [
                            {label: optionsAndSettings, value: 'textComparatorOptions'},
                            {label: commonTerms, value: 'commonTerms'},
                            {label: commonSubsequences, value: 'lcs'},
                            {label: differences, value: 'diff'},
                            {label: objectClouds, value: 'objectCloudComparator'}
                        ]
                    },
                    {label: tanachViewer, value: 'tanachViewer'},
                    {label: taamParser, value: 'taamParser'},
                    {label: objectCloudViewer, value: 'objectCloudViewer'}
                ]
            }];

    return <Rnd id="helpDialog"
      size={{ width: `${this.props.widthNum}px`,  height: `${this.props.heightNum}px` }}
      position={{ x: this.props.x, y: this.props.y }}
      onDragStop={(e, d) => { 
        this.props.setPosition(d.x, d.y)
      }}
      onClick={(e) => {this.handleClick()}}
      onResize={(e, direction, ref, delta, position) => {
        this.props.setSize(
          parseInt(ref.style.width.slice(0, -1)),
          parseInt(ref.style.height.slice(0, -1)),
          position.x,
          position.y
        )
      }}

      style = {{
        background: 'black !important',
        visibility: this.props.isOpen ? 'visible' : 'hidden',
        boxShadow: '0 3px 6px rgba(0,0,0,0.48), 0 3px 6px rgba(0,0,0,0.69)',
        borderRadius: '5px',
        zIndex: this.state.zIndex,
        direction: directionStyling.direction,
        textAlign: directionStyling.textAlign
      }}
      className="help-rnd"
      cancel = '.help-dialog-content-root, .help-dialog-close-button, .mdl-demo, .help-dialog-resize-buttons'
      minWidth={375}
      minHeight={220}
      bounds='#root'
    >

        <div className="help-dialog-resize-buttons"
          style={{
            position: 'absolute',
            right: isHebrew ? 'unset' : 0,
            left: isHebrew ? 0 : 'unset',
            direction: isHebrew ? 'ltr' : 'rtl',
            top: 0,
            borderBottomLeftRadius: isHebrew ? 0 : '3px',
            borderBottomRightRadius: isHebrew ? '3px' : 0,
          }}
        >
          <Button size="small" onClick={() => {this.onClose()}} color="primary" className="help-dialog-resize-button" title={close}> 
            <CloseIcon />
          </Button>
          <Button className="help-dialog-resize-button" onClick={() => {this.handleCopyLink()}} title={copyLink}>
            <LinkIcon />
          </Button>
          <Button className="help-dialog-resize-button" onClick={() => {this.handleFullscreen()}} title={fullScreen}>
            <FullscreenIcon />
          </Button>
          <Button className="help-dialog-resize-button" onClick={() => {this.handleRestoreScreenSize()}} title={restore}> 
            <FullscreenExitIcon />
          </Button>
        </div>


      <div id="help-dialog-title"
        onDoubleClick={(e) => {this.handleTitleDoubleClick(e)}}
        classes={{root: "help-dialog-title"}}
      >
        <div style={{position: 'relative', margin: '25px'}}>
          <div className="help-dialog-title-text-parent">
            <Typography variant="h5">{helpTR}</Typography> 
                <HierarchicalSelect
                    data={menuData}
                    onChange={(e) => {this.onMenuChange(e)}}
                    onNodeToggle={(e) => {this.onMenuNodeToggle(e)}}
                    onAction={(e) => {this.onMenuAction(e)}}
                    mode="hierarchical"
                    enforceSingleSelection={true}
                    highlightSearch={true}
                    // clearSearchOnChange={true}
                    className={`mdl-demo ${isHebrew ? "he" : "en"} help-dropdown`}
                    texts={{
                      placeholder: searchTR
                    }}
                    value={this.state.dropdownValue}
                />
          </div>

        </div>

      </div>
      <DialogContent classes={{
        root: 'help-dialog-content-root'
      }}>

        <Tabs>
          <div className="help-dialog-tabs-container" style={{height: `${this.props.heightNum - 88}px`}}> 
            {/* Search for tab panels by id: "help-panel-{snake-name}"  */}
            <div>
              <TabList>
                <Tab>{instructionsTR}</Tab>
                <Tab>{examplesTR}</Tab>
                <Tab>{tutorialsTR}</Tab>
                <Tab>{allTutorialsTR}</Tab>
              </TabList>
            </div>

            <div className="help-dialog-tab-panels-parent">
              <TabPanel id="help-panel-instructions">

                <h3>{instructionsTR}</h3>
                <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                  {instructionsText}
                </div>

              </TabPanel>

              <TabPanel id="help-panel-examples">
                <h3>{examplesTR}</h3>
                <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                  {examplesText}
                </div>
              </TabPanel>

              <TabPanel id="help-panel-tutorials">
                <h3>{tutorialsTR}</h3>
                <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                  {tutorialsText}
                </div>
              </TabPanel>

              <TabPanel id="help-panel-allTutorials">
                <span style={{fontSize: "28px", fontWeight: 500}}>{allTutorialsTR}</span> &nbsp;
                <i>{allTutorialsTxt1} <a href={channelURL} target="_blank"><b>{videoChannel}</b></a> {allTutorialsTxt2} <a href={playListURL} target="_blank"><b>{tutorialPlaylist}</b></a>{allTutorialsTxt3}</i>
                <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                    <ReactPlayer url={tutorialURL1} width={allTutorialsWidth} height={allTutorialsHeight} controls={true} loop={true} /><br />
                    <ReactPlayer url={tutorialURL2} width={allTutorialsWidth} height={allTutorialsHeight} controls={true} loop={true} /><br />
                    <ReactPlayer url={tutorialURL3} width={allTutorialsWidth} height={allTutorialsHeight} controls={true} loop={true} /><br />
                    <ReactPlayer url={tutorialURL4} width={allTutorialsWidth} height={allTutorialsHeight} controls={true} loop={true} /><br />
                    <ReactPlayer url={tutorialURL5} width={allTutorialsWidth} height={allTutorialsHeight} controls={true} loop={true} />
                </div>
              </TabPanel>
            </div>
          </div>

        </Tabs>

        </DialogContent>
      </Rnd>

  }
}
HelpDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(withLocalize(HelpDialog)));