import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import { Button, Paper, InputBase, IconButton, Fade, Typography, Popper, AppBar, Tabs, Tab, InputLabel, MenuItem, FormControl, Select, Switch, Popover } from '@material-ui/core';
import translations from "./json/translations.json";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ClearAll from '@material-ui/icons/ClearAll';
import SelectAll from '@material-ui/icons/SelectAll';




const numericIcons = ['', '', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫', '⑬', '⑭', '⑮', '⑯', '⑰', '⑱', '⑲', '⑳', '㉑', '㉒', '㉓', '㉔', '㉕', '㉖', '㉗', '㉘', '㉙', '㉚', '㉛', '㉜', '㉝', '㉞', '㉟', '㊱', '㊲', '㊳', '㊴', '㊵', '㊶', '㊷', '㊸', '㊹', '㊺', '㊻', '㊼', '㊽', '㊾', '㊿']

function convertSelected(selected, list, icon, displayMultiple) {
    const displayMulti = displayMultiple == null ? true : false;

    const len = selected.length;
    if (len == 1) {
        var lastId = list[list.length-1].id;
        var diff = selected[0] - lastId;
        if (diff >= 1 && diff <= 3) //will be 1, 2, or 3
            return ['X','?','*'][diff-1];
    }

    if (len > 1 && !displayMulti)
        return numericIcons[len];

    var result = "";
    selected.sort().map(function(e){
         var i = list.findIndex((v)=>{return v.id == e;});
         var displayIcon = icon == null ? list[i].icon : icon + util.right(list[i].icon,1);
         result += (displayIcon + ' ');
    });
    return result.trim();
}

class NekudaPicker2D extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            open: false
        }

        this.handleSelChange = this.handleSelChange.bind(this);
        this.handlePopoverClick = this.handlePopoverClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleNekudaButtonClicked = this.handleNekudaButtonClicked.bind(this);

//        this.props.initialize({
//          languages: [
//            { name: "עברית", code: "he" },
//            { name: "English", code: "en" }
//          ],
//          translation: translations,
//          options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: "en" }
//        });
//
//        this.props.addTranslation(translations);
//        this.setLanguage = (code) => {props.setActiveLanguage(code)};
    }


     handleSelChange(event) {
         this.props.extSetValues(event.target.value, convertSelected(event.target.value, this.props.pickerModel.list, this.props.icon, this.props.displayMultiple))
     }

     handleNekudaButtonClicked(nekudaId){
        var oldValues = this.props.pickerModel.values
        var newValues = oldValues
        if(nekudaId === 'selectAll'){
            newValues = []
            for(let i = 1; i<=17; i++){
                newValues.push(i);
             }
        } else if (nekudaId === 'clearAll'){
            newValues = []
        } else if ([18,19,20].includes(nekudaId)){
            if(oldValues.includes(nekudaId)){
                newValues = [];
            } else {
                newValues = [nekudaId];
            }
        } else {
            //remove special values from newValues
            if (oldValues.includes(18) || oldValues.includes(19) || oldValues.includes(20)){
                newValues = []
            }
            //remove selected value from newValues
            if (oldValues.includes(nekudaId)){
                var nekudaIndex = oldValues.indexOf(nekudaId)
                newValues = oldValues.slice(0,nekudaIndex).concat(oldValues.slice(nekudaIndex+1, oldValues.length))
            //add selected value to newValues
            } else {
                newValues.push(nekudaId)
            }
        }
        this.props.extSetValues(newValues, convertSelected(newValues, this.props.pickerModel.list, this.props.icon, this.props.displayMultiple))
     }



//     const classes = useStyles();

       handlePopoverClick(event) {
         this.setState({anchorEl: event.currentTarget, open: true});
       }

       handleClose() {
         this.setState({anchorEl: null, open: false});
       }

       getAllowedNekudot(){
            var list = this.props.pickerModel.list;
            var nekudotToRemove = [];

            if(['א','ב','ג','ד','ז','ט','י','כ','ך','ל','מ','ם','נ','ן','ס','פ','ף','צ','ץ','ק','ר','ת'].includes(this.props.icon)){
                nekudotToRemove = [12,14,15,16]
            } else if (['ח','ע'].includes(this.props.icon)){
                nekudotToRemove = [12,13,14,15,16]
            } else if (['ה'].includes(this.props.icon)){
                nekudotToRemove = [12,15,16]
            } else if (['ו'].includes(this.props.icon)){
                nekudotToRemove = [13,14,15,16]
            } else if (['ש'].includes(this.props.icon)){
                nekudotToRemove = [12,14]
            }

            for(let i = nekudotToRemove.length-1; i >= 0 ; i--){ //loop backwards so indices in list stay the same after removing each nekuda
                var nekudaIndex = nekudotToRemove[i]
                list = list.slice(0,nekudaIndex).concat(list.slice(nekudaIndex+1, list.length))
            }
            return list
       }

     render() {
        if (this.props.pickerModel == null || this.props.pickerModel.list == null || this.props.pickerModel.list.length == 0)
             return null;

        var list = this.getAllowedNekudot()

        //var len = list.length; //GET tHIS TO DIFFERENT OBJECT SO DOESNT CHANGE STASTE!!!!!
        //var el = {id: len+1, name: 'ללא נקודה', icon: 'X'};
        //list.push(el);
        const selectStyle = this.props.selectStyle != null ? this.props.selectStyle : "";
        const icon = this.props.icon != null ? this.props.icon : null;
        const displayMultiple = this.props.displayMultiple;
        const rowHeight = {height: '40px'}; //style Wingdings 2 : tuvwxyz{|~ to rep 0 - 10
        var i = 0;

        var noNekuda = "", anyNekuda = "", anyNekudos = "";

        var specialNekudaStyle = {color: 'red', height: '40px', padding: 0};

        const anchorEl = this.state.anchorEl;
        const id = this.state.open ? 'simple-popover' : undefined;

        const sel = this.props.translate("select");
        const selectPad = this.props.translate("selectPad.IncludeExclude");
        const selectPad2 = (selectStyle == "General" && !displayMultiple) ? selectPad : ""; //if can assume we're on niku/hataama tab and in the general nekuda picker, then need to add some extra space

        const pickerModelValues = this.props.pickerModel.values;
        const renderValue = convertSelected(this.props.pickerModel.values, list, icon, displayMultiple)
        const fakeThis = this //fixed problem with 'this' not getting defined
        //TODO: FIX BUG: these 3 special characters don't have a set id=They need to be set for the funtionality because we check for 18,19,20 here and in searchCriteria.js
        if (["Letter", "General"].includes(selectStyle)) {
            noNekuda = <MenuItem className="menuItem" style={specialNekudaStyle} value={list[list.length-1].id+1} selected={this.props.pickerModel.values.includes(list[list.length-1].id+1)} onClick={() => fakeThis.handleNekudaButtonClicked(list[list.length-1].id+1)}>
                <span className="lntPickerSelectIcon">X</span>&nbsp;<span className="lntPickerSelectName">ללא נקודה</span>
            </MenuItem>;
        }
        if (["Letter", "General"].includes(selectStyle)) {
            anyNekuda = <MenuItem className="menuItem" style={specialNekudaStyle} value={list[list.length-1].id+2} selected={this.props.pickerModel.values.includes(list[list.length-1].id+2)} onClick={() => fakeThis.handleNekudaButtonClicked(list[list.length-1].id+2)}>
                <span className="lntPickerSelectIcon">?</span>&nbsp;<span className="lntPickerSelectName">כל נקודה</span>
            </MenuItem>;
        }
        if (["General"].includes(selectStyle)) {
            anyNekudos = <MenuItem className="menuItem" style={specialNekudaStyle} value={list[list.length-1].id+3} selected={this.props.pickerModel.values.includes(list[list.length-1].id+3)} onClick={() => fakeThis.handleNekudaButtonClicked(list[list.length-1].id+3)}>
                <span className="lntPickerSelectIcon">*</span>&nbsp;<span className="lntPickerSelectName">כל נקודות</span>
            </MenuItem>;
        }

        return <span className="placementGridLine" style={rowHeight} style={{backgroundColor: '#d3d3d3'}}>

              <Button aria-describedby={id} variant="contained" onClick={this.handlePopoverClick} width='44px' className={fakeThis.props.extClassName} elevation={1}>
                        <ArrowDropDown style={{color: '#757575'}}/> <Typography variant="h6" style={{fontSize: '25px', fontWeight: 'bold', lineHeight: 1}}>{renderValue}</Typography >
              </Button>
              <Popover
                id={id}
                open={this.state.open}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                elevation={1}
              >
                <Typography style={{direction: 'rtl', padding: '10px'}}>

                    <Table size="small" >
                      <TableHead>
                        <TableRow style={{height: '30px'}}>
                          <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                                <MenuItem className="menuItem menuItemSpecial" style={{height: '30px'}} value={'selectAll'} selected={false} onClick={() => fakeThis.handleNekudaButtonClicked('selectAll')}>
                                <span className="lntPickerSelectIcon"><SelectAll style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">בחר הכל</span>
                                </MenuItem>
                            </TableCell>
                          <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                              <MenuItem className="menuItem menuItemSpecial" style={{height: '30px'}} value={'clearAll'} selected={false} onClick={() => fakeThis.handleNekudaButtonClicked('clearAll')}>
                              <span className="lntPickerSelectIcon"><ClearAll style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName">נקה הכל</span>
                              </MenuItem>
                          </TableCell>
                          <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                                <Typography> </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{padding: '0px'}}>

                      <TableRow style={rowHeight}>
                              {list.slice(0,3).map(function(e){
                                  i = e.id + 1;;
                                  const nekudaStyle = {height: '40px'};
                                  const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                  return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                                  <MenuItem className="menuItem" style={nekudaStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleNekudaButtonClicked(e.id)}>
                                  <span className="lntPickerSelectIcon">{displayIcon}</span>&nbsp;<span className="lntPickerSelectName">{e.name}</span>
                                  </MenuItem>
                                  </TableCell>
                                  })}
                        </TableRow>
                          <TableRow style={rowHeight}>
                                  {list.slice(3,6).map(function(e){
                                      i = e.id + 1;;
                                      const nekudaStyle = {height: '40px'};
                                      const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                      return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                                      <MenuItem className="menuItem" style={nekudaStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleNekudaButtonClicked(e.id)}>
                                      <span className="lntPickerSelectIcon">{displayIcon}</span>&nbsp;<span className="lntPickerSelectName">{e.name}</span>
                                      </MenuItem>
                                      </TableCell>
                                      })}
                            </TableRow>
                            <TableRow style={rowHeight}>
                                  {list.slice(6,9).map(function(e){
                                      i = e.id + 1;;
                                      const nekudaStyle = {height: '40px'};
                                      const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                      return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                                      <MenuItem className="menuItem" style={nekudaStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleNekudaButtonClicked(e.id)}>
                                      <span className="lntPickerSelectIcon">{displayIcon}</span>&nbsp;<span className="lntPickerSelectName">{e.name}</span>
                                      </MenuItem>
                                      </TableCell>
                                      })}
                            </TableRow>
                            <TableRow style={rowHeight}>
                                  {list.slice(9,12).map(function(e){
                                      i = e.id + 1;;
                                      const nekudaStyle = {height: '40px'};
                                      const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                      return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                                      <MenuItem className="menuItem" style={nekudaStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleNekudaButtonClicked(e.id)}>
                                      <span className="lntPickerSelectIcon">{displayIcon}</span>&nbsp;<span className="lntPickerSelectName">{e.name}</span>
                                      </MenuItem>
                                      </TableCell>
                                      })}
                            </TableRow>
                            <TableRow style={rowHeight}>
                                  {list.slice(12,15).map(function(e){
                                      i = e.id + 1;;
                                      const nekudaStyle = {height: '40px'};
                                      const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                      return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                                      <MenuItem className="menuItem" style={nekudaStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleNekudaButtonClicked(e.id)}>
                                      <span className="lntPickerSelectIcon">{displayIcon}</span>&nbsp;<span className="lntPickerSelectName">{e.name}</span>
                                      </MenuItem>
                                      </TableCell>
                                      })}
                            </TableRow>
                            <TableRow style={rowHeight}>
                                  {list.slice(15,18).map(function(e){
                                      i = e.id + 1;;
                                      const nekudaStyle = {height: '40px'};
                                      const displayIcon = icon == null ? e.icon : icon + util.right(e.icon,1);
                                      return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                                      <MenuItem className="menuItem" style={nekudaStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleNekudaButtonClicked(e.id)}>
                                      <span className="lntPickerSelectIcon">{displayIcon}</span>&nbsp;<span className="lntPickerSelectName">{e.name}</span>
                                      </MenuItem>
                                      </TableCell>
                                      })}
                            </TableRow>
                          <TableRow style={rowHeight}>
                            <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#F8F5DE'}}>
                                {noNekuda}
                            </TableCell>
                            <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#F8F5DE'}}>
                                {anyNekuda}
                            </TableCell>
                            <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#F8F5DE'}}>
                                {anyNekudos}
                            </TableCell>
                          </TableRow>

                      </TableBody>
                    </Table>
                </Typography>
              </Popover>
          </span>;
       }
}

export default withLocalize(NekudaPicker2D);
