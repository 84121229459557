import React, { Component } from 'react';

class FeaturesContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p /><h5>פרשיות התנ"ך</h5><p />
        <ul>
          <li>לוח עם מידע סיכום וסטטיסטיקות מפורטות לכל הפרשיות (פתוחות וסתומות) בתנ"ך</li>
          <li>אפשרות להרחיב את השורות לבחינת טקסט הפרשה</li>
          <li>הטקסט המורחבת של הפרשה מאפשר תצפיות מקבילות של התרגום, הכתב סת"ם והכתב עברי</li>
          <li>לחיצות עכבר רגישות להקשר מאפשרות ביצועת חיפושים ותכונות על מילים, פסוקים ופרשיות רלוונטיות</li>
        </ul>
        <p /><h5>דפדפן התנ"ך</h5><p />
        <ul>
          <li>תצוגה רב-חלונית מאפשרת תצפיות מקבילות של התנ"ך, התרגום, כתב הסת"ם וכתב העברי</li>
          <li>מיישר חזותית מילות / ביטויים של החומש למילים / ביטויים של תרגום אונקלוס</li>
          <li>אפשר לנווט לפי ספר / פרק / פסוק או לפי קטע בשם כמו קריאה</li>
        </ul>
        <p /><h5>לקסיקון</h5><p />
        <ul>
          <li>
              דמיינו את המיפוי של מילים עבריות למילות תרגום ולהיפך.
              כאשר משתמשים במילות תרגום שונות לאותן מילים עבריות שוים, זה מספק לנו גוונים שונים של משמעות לאותה מילה עברית ויכול להעניק הבנה ביקורתית למשמעות של פסוק.
              כאשר ניתן להשתמש באותה מילת תרגום לתיאור מילים עבריות שונות, היא יכולה לשמש כמקור לזיהוי מילים נרדפות בין מילים עבריות ועוזרת לנו להרחיב את הבנתנו את המילים הללו.
          </li>
          <li>מפה מילים נרדפות של מילה-מילה בעברית ושורש-שורש על סמך השימוש שלהן בתרגום נפוץ</li>
          <li>מפה מילים ושורשים לערכי גמטריא ואתבש ולהיפך</li>
        </ul>
        <p /><h5>שכיחות בתנ"ך</h5><p />
        <ul>
          <li>לוח עם רשימה של בטויים תנכיים בהתאם לתדירות השימוש בהם</li>
          <li>בטויים יכולים להיות מילים (לפי אותיות, נקודות, וטעמים המצויינות), שורשים, וגימטריות</li>
          <li>יכולת סינון על ידי מבחר ספרי תנך</li>
          <li>ניתן לבחור להציג את אחד מתחומי ההתרחשות הבאים:
            <ul>
              <li><b>התרחשות אחת</b>: מציג את הפסוק המלא שבו הבטוי מתרחש</li>
              <li><b>שתי התרחשויות</b>: מציג את שני הפסוקים זה לצד זה שבהם הבטויים מתרחשים</li>
              <li><b>שלוש התרחשויות או יותר</b>: רשימה מסכמת של הבטויים ומספר ההתרחשויות הכולל</li>
            </ul>
          </li>
        </ul>
        <p /><h5>משווה טקסטים</h5><p />
        <i>שלוש דרכים שונות להשוות בין שתי קטעים מתחומים שונים בתנ"ך</i>
        <br /><br />
        <ul>
          <li>זיהוי מילים או ביטויים נפוצים עם הרבה סטטיסטיים קשורים</li>
          <li>זיהוי רצפי מילים ותבניות נפוצות בין חלקים שונים בתנ"ך</li>
          <li>זיהוי הבדלים קלים בין חלקים דומים בתנ"ך</li>
        </ul>
        <p /><h5>מנתח טעמים</h5><p />
        <ul>
          <li>יוצר מראה עץ אינטואיטיבי של כל פסוק בתנ"ך ומפרק את המשמעות כפי שהוגדרה על ידי טעמי המקרא </li>
          <li>אפילו מפרק את טעמי המקרא הפחות ידועים של ספרי אמ"ת</li>
          <li>נווט לכל מקום בתנ"ך</li>
          <li>הרחב או צמצם קטעי מבנה העץ לפי הצורך</li>
          <li>מציג משמעות ברקעים צבעוניים שונים ובגופנים בגודל שונה</li>
        </ul>
          <p /><h5>ענני אובייקטים</h5><p />
          <ul>
            <li>הצג חזותית לא רק של ענני מילים אלא אפילו ענני תדירות לשימוש בשורש, גימטריא, נקודה וטעמי המקרא בקטעים בתנ\"ך</li>
            <li>לחץ על כפתור "הפעל" כדי להנפיש התקדמות של עננים דרך קטעים שונים</li>
          </ul>
          <p /><h5>אילן יוחסין תנ"כי תלת-ממדי</h5><p />
          <ul>
            <li>המחשה תלת־ממדית של אילן יוחסין תנ"כי. מצוא מידע, חפש קשרים בין הדמיות, ולגלות הופעותם בתנ"ך</li>
            <li>צור הנפשות כדי לעשות סיור חזותי בנתיב היחסים בין אדם למשנהו</li>
          </ul>
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p /><h5>Parsha Viewer</h5><p />
          <ul>
            <li>A grid with detailed summary info and statistics for every parsha pesucha and stuma in Tanach</li>
            <li>Ability to expand the gridline to examine the parsha text</li>
            <li>The expanded parsha text allows for parallel views of the Targum, ksav Sta"m and ksav Ivri</li>
            <li>Context-sensitive mouse clicks allow one to fire searches and features on relevant words, pesukim and parshios</li>
          </ul>
          <p /><h5>Tanach viewer</h5><p />
          <ul>
            <li>Multi-pane view allows for parallel views of the Tanach, Targum, ksav Sta"m and ksav Ivri</li>
            <li>Visually aligns Chumash words/phrases to Targum Onkelos words/phrases</li>
            <li>Can navigate based on sefer/perek/pasuk or based on named section such as kria</li>
          </ul>
          <p /><h5>Lexicon</h5><p />
          <ul>
            <li>
                Visualize the mapping of Hebrew words to Targum words and vice versa.
                When different Targum words are used for the exact same Hebrew words, it provides us with different shades of meaning for the same Hebrew word and can lend critical understanding to the meaning of a פסוק.
                When same Targum word can be used to describe different Hebrew words, it can be used as a source for identifying synonyms among Hebrew words and helps us broaden our understanding of those words.
            </li>
            <li>Map Hebrew word-word and root-root synonyms based on their use of a common Targum</li>
            <li>Map words and roots to גמטריא and אתבש values and vice versa</li>
          </ul>
          <p /><h5>Frequency Viewer</h5><p />
          <ul>
            <li>A grid with a listing of Tanach terms according to their usage frequency</li>
            <li>Terms can be words (according to indicated letters, nekudos and taamim), shorashim and gematrios</li>
            <li>Capability to filter by a selection of sifrei Tanach</li>
            <li>Can select to view one of the following occurrence ranges:
              <ul>
                <li><b>One occurrence</b>: displays the full pasuk where the term occurs</li>
                <li><b>Two occurrences</b>: displays the two pesukim side by side where the terms occur</li>
                <li><b>Three or more occurrences</b>: a summary list of the terms and the number of total occurrences</li>
              </ul>
            </li>
          </ul>
          <p /><h5>Text Comparator</h5><p />
          <i>Three different ways to compare two selections from different areas of Tanach</i>
          <br /><br />
          <ul>
            <li>Identify common words or phrases with many associated statistics</li>
            <li>Identify common sequences of words and patterns among disparate sections of Tanach</li>
            <li>Identify slight differences among similar sections of Tanach</li>
          </ul>
          <p /><h5>Taam Parser</h5><p />
          <ul>
            <li>Creates an intuitive tree-view of every pasuk in Tanach and breaks down the meaning as defined by the trop</li>
            <li>Even breaks down the lesser-known trop of the ספרי אמ"ת</li>
            <li>Navigate to anywhere in Tanach</li>
            <li>Expand or contract sections of the tree structure as needed</li>
            <li>Presents significance in different color backgrounds and different size fonts</li>
          </ul>
          <p /><h5>Object Clouds</h5><p />
          <ul>
            <li>Visually display not only word clouds but even frequency clouds for shoresh, gematria, nekuda and trop usage for sections of Tanach</li>
            <li>Hit the "play" button to animate a progression of clouds through different sections</li>
          </ul>
          <p /><h5>3D Tanach Family Tree</h5><p />
          <ul>
            <li>An interactive 3D family tree of characters mentioned in Tanach. Find information, search relationships and find their appearances in Tanach</li>
            <li>Create animations to take a visual tour on the relationship path between one person and another</li>
          </ul>
      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default FeaturesContent;