import React, { Component } from 'react';

class LoadSave_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>טעינה
              ושמירה של
              הגדרות
              החיפוש</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>: זה
            דומה למדי
            לאופן בו
            טוענים
            ושומרים
            תוצאות חיפוש.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לחיצה
            על סמל<b>
            &nbsp;הטעינה </b>בבקרות
            תוצאות
            החיפוש בצד
            ימין (סמל
            התיקייה השחורה)
            או בסרגל
            החיפוש הראשי
            עצמו תגרום לפתיחת
            דיאלוג לטעינת
            קבצים שמבקש
            ממך לספק שם
            לקובץ ה- </span><span dir="LTR">JSON</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> שברצונך
            לטעון.</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>השמירה
            קצת יותר
            מורכבת
            מכיוון
            שכשלוחצים על סמל<b>
            &nbsp;השמירה</b> (סמל
            התקליטון),
            תופיע קופץ
            בהודעה "לחץ
            כאן לחיצה
            ימנית לשמור
            הגדרות".</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            שתלחץ לחיצה
            ימנית על
            הודעת הקופץ,
            בחר באפשרות
            "שמור קישור
            בשם" ואז
            יופיע דיאלוג לשמירת
            קבצים, המבקש
            ממך לבחור שם
            לקובץ תוצאות
            ה-</span><span dir="LTR">.JSON </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
            שלך ואז תלחץ
            כדי לשמור את
            הקובץ.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>זכור
            כי המערכת
            מעצבת קבצי </span><span dir="LTR">JSON</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> של תוצאות
            בדרת שונה
            מקבצי </span><span dir="LTR">JSON</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> של הגדרות,
            לכן היזהר שלא
            לערבב את שני
            הסוגים, אחרת
            המערכת תציג
            הודעת שגיאה
            בעת ניסיון לטעון
            את הקובץ הלא
            חוקי.</span></p>
        <p className="MsoNormal">&nbsp;</p>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b>Loading and saving and search results</b>: this is
          fairly similar to the way one loads and saves search settings.&nbsp; Clicking the <b>load </b>
          icon in the search results controls on the right (black folder icon) or in the
          main search bar itself, will cause a file load dialogue to open asking you to
          provide a name for the .JSON file you want to load.&nbsp; Saving is slightly
          trickier as when you click the <b>save</b> icon (floppy disk icon), a message
          will popup saying “right-click here to save settings”.&nbsp; Once you right-click on
          the popup message, select the “Save link as” option and then a file save
          dialogue will pop up, prompting you to select a name for your .JSON results
          file and then you will click to save the file.&nbsp; Remember that the system
          formats results JSON files different from settings JSON files so be careful not
          to mix the two types or the system will display an error message upon attempting
          to load the invalid file.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default LoadSave_Instructions;