import React, { Component } from 'react';

class Placement_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif', color: 'black'}}>פרטים
              עבור
              כרטיסיית
              מיקום:</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif', color: 'black'}}> (אלה
            הגדרות
            כלליות ואינן
            לכל ביטוי)</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR" style={{fontFamily: '"Arial",sans-serif', color: 'black'}}><img width={624} height={69} id="Picture 26" src="helpDialogImages/image022.png" alt="https://lh6.googleusercontent.com/B-18pth-edBN96zbua0MCLVYBx5HCty9g4s1kj0ZbATyN6OWaUVT7dfNzr4pmMGN8S0sLxlxlOBzOBy3OUw1yUmP5M1FhOzxH39-Wx0ULWChvb18xwaJKOKIR4ZegL-8FM1A5SXu" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אפילו
            בלי להגדיר
            שום דבר
            בכרטיסייה זו,
            ניתן כבר לסנן
            תוצאות לפי
            קבוצות ספרים
            בהגדרות החיפוש
            הרגילות
            בסרגל הימני. כאן
            אנו יכולים
            לכוונן את
            הדברים הרבה
            יותר על ידי
            יצירת פילטר
            המגביל את
            החיפוש
            לקבוצות
            אחד-לרבים של
            פסוקים
            בודדים לפי
            קטעים
            מסוימים
            בתנ"ך או לפי
            טווחי פסוקים
            מוגדרים
            מנקודת התחלה
            לנקודת סיום.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כדי
            להתחיל, בחר
            אם להגדיר קטע
            מסוים (ברירת
            מחדל) או טווח
            פסוקים על ידי
            הפניית המתג
            שנמצא מימין
            בממשק העברי
            ומשמאל לשפות
            ממשק מימין
            לשמאל.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>האפשרות
            <b>קטע מסויים</b>
            תגרום להצגת
            שורה כחולה
            בהירה</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={623} height={16} id="Picture 33" src="helpDialogImages/image023.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>עכשיו
            תחת <b>סוג</b> אתה
            יכול לבחור <b>קריאת
              התורה</b> או <b>הפטרה</b>.
            בחירה באחד
            מהם יאכלס את
            תיבת הבחירה
            הבאה עם
            אפשרויות
            רלוונטיות.</span><span dir="LTR" /><span dir="LTR"><span dir="LTR" /> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>נניח
            שהראשון נבחר,
            מה שיעלה תחת <b>מין
              מאורע</b> הוא
            הבחירה של <b>פרשת
              השבוע</b>, <b>סדרה</b>, <b>יום
              טוב</b>, <b>תענית</b>, <b>ד'
              פרשיות</b>.
            בחירת אחד
            מאלה תאכלס את
            תיבת הבחירה
            הבאה - <b>שם מאורע</b>
            אם הוא
            רלוונטי או <b>שם
              קטע</b>.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
          </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אם
            נבחרת <b>פרשת
              השבוע</b>,
            הבחירות
            הזמינות עבור <b>שם
              מאורע</b> יהיו <b>שחרית
              של שבת</b>, <b>מנחה
              של
              שבת\שני\חמישי</b>.
            אם <b>שם מאורע</b>
            מאוכלס אז
            בחירת אפשרות
            שם תביא
            לאוכלוסיית <b>שם
              קטע</b>. אם נבחר <b>שחרית
              של שבת</b>, תוצג
            רשימה של כל
            פרשיות השבוע.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            בחירת שם המאורע,
            שורת המסנן
            הוגדרה
            מבחינה טכנית
            ושורה ריקה נוספת
            כבר תיווצר
            לשימושך.</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אתה עדיין
            יכול להמשיך
            לבחור עלייה
            ספציפית עליה
            לסנן.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
          </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            מכן ניתן
            להוסיף
            מסננים
            נוספים
            בשורות חדשות
            המסופקות
            למשתמש.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אם אינך
            מעוניין
            בהגדרת פילטר
            מסוימת, פשוט
            לחץ על </span><b><span dir="LTR">X</span></b><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> בסוף
            השורה כדי
            למחוק את
            השורה.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אפשרות
            <b>טווח פסוקים</b>
            תגרום להצגת
            שורה כתומה
            בהירה</span><span dir="LTR"><img width={624} height={16} id="Picture 34" src="helpDialogImages/image024.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>יש
            להזין בחירות
            שונות מכל צד
            בשורה על מנת
            להקצות מגוון
            פסוקים על ידי
            הגדרת
            ספר/פרק/פסוק
            התחלה ו
            ספר/פרק/פסוק סוף.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תחת
            <b>ספר</b> אפשר
            לבחור כל ספר
            מהתנ"ך. בחירת
            ספר תאכלס את
            תיבת הבחירה
            הבאה ברשימת פרקים
            רלוונטית.</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בחירת <b>פרק</b>
            תאכלס את החלק
            הבא ברשימת
            פסוקים
            רלוונטית.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בחירת <b>פסוק</b>
            תשלים את
            ההגדרה.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בחירה זו
            בטווח הסיום
            תביא ליצירת
            שורה חדשה.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר מכן
            ניתן להוסיף
            מסננים
            נוספים
            בשורות חדשות
            המסופקות
            למשתמש.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אם אינך
            מעוניין
            בהגדרת פילטר,
            לחץ על </span><b><span dir="LTR">X</span></b><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> בסוף
            השורה כדי
            למחוק את
            השורה.</span></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">&nbsp;</p>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b><span style={{color: 'black'}}>Details for the Placement tab: </span></b><i><span style={{color: 'black'}}>(these are general settings and are not per term)</span></i></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Arial",sans-serif', color: 'black'}}><img width={624} height={69} id="Picture 26" src="helpDialogImages/image022.png" /></span></p>
        <p className="MsoNormal">Without even setting things on this tab, one can already
          filter results by sets of seforim in the standard search settings in the
          right-bar.&nbsp; Here we can fine-tune things much more by creating a filter that
          restricts the search to one to many sets of individual pesukim based on named
          sections of Tanach or based on defined ranges of pesukim from a start point to
          an end point.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">One begins by selecting whether one is defining a named
          section (default) vs. a pasuk range by flipping the switch that’s on the right
          in the Hebrew interface and on the left for left-to-right interface languages.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">The <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>קטע
              מסויים</span></b><span dir="LTR" /><span dir="LTR" /> option will cause a light blue row to be displayed</p>
        <p className="MsoNormal"><img width={623} height={16} id="Picture 33" src="helpDialogImages/image023.jpg" /></p>
        <p className="MsoNormal">Now under <b>type of section</b> you can select <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>קריאת
              התורה</span></b><span dir="LTR" /><span dir="LTR" /> or <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הפטרה</span></b><span dir="LTR" /><span dir="LTR" />.&nbsp; Selecting either will populate the next
          selection box with relevant choices. Just say the former is selected, what will
          come up under the <b>type of event</b> selection is a choice of <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשת
              השבוע, סדרה,
              יום טוב,
              תענית, ד' פרשיות
            </span></b><span dir="LTR" /><span dir="LTR" />.&nbsp; Selecting any of these
          will populate the next selection box – <b>name of event</b> if relevant or <b>name
            of section</b>.&nbsp; If <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><b>פרשת השבוע</b></span>
            <span dir="LTR" /><b><span lang="HE"><span dir="LTR" /> </span></b>was selected then the choices
          available for <b>name of event</b> will be <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שחרית
              של שבת, מנחה
              של
              שבת\שני\חמישי</span></b><span dir="LTR" /><span dir="LTR" />.&nbsp; If name of event is populated then
          selecting a choice there will cause name of section to be populated.&nbsp; If <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שחרית
              של שבת</span></b><span dir="LTR" /><span dir="LTR" /> is chosen then a list of every single parsha hashavua will be
          presented.&nbsp; Once selecting name of event, one is technically done defining the
          filter row and another blank row will already be created for your use.&nbsp; You may
          still go further and pick a specific aliya on which to filter.&nbsp; One may then
          add additional filters on new rows that are provided to the user.&nbsp; If one
          doesn’t desire a filter definition anymore then just click <b>X </b>on the end
          of the row to delete the row.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">The <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טווח
              פסוקים</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>option will cause a light orange row
          to be displayed</p>
        <p className="MsoNormal"><img width={624} height={16} id="Picture 34" src="helpDialogImages/image024.jpg" /></p>
        <p className="MsoNormal">One must now enter different selections on each side of the
          row in order to assign a pasuk range by defining the start sefer/perek/pasuk
          and the end sefer/perek/pasuk.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">Under <b>sefer</b> you can select any sefer of Tanach.&nbsp;
          Selecting a sefer will populate the next selection box with a relevant list of
          perakim. Selecting a <b>perek</b> will populate the next section with a
          relevant list of pesukim.&nbsp; Selecting a <b>pasuk</b> will complete the
          definition.&nbsp; Selecting this on the end range will cause a new row to be
          generated.&nbsp; One may then add additional filters on new rows that are provided
          to the user. &nbsp;If one doesn’t desire a filter definition anymore, just click <b>X </b>
          on the end of the row to delete the row.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default Placement_Instructions;