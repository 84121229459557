import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {withRouter} from 'react-router-dom';
import './css/App.css';
import './css/Admin.css';
import Home from './Home';
import Admin from './Admin';
import AuthenticatedApp from './AuthenticatedApp';
import Maintenance from './Maintenance';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { LocalizeProvider } from "react-localize-redux";
import Finders from './Finders';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import * as properties from './config/properties.js';
//import Callback from './Callback';
import AppNavBar from './AppNavBar';

//mje - Oct 29, 2019 - for obsolete code pertinent to trial and error changes to get login/authorization to work with Auth) and Okta in Oct 2019, see version history of this file in Git

class App extends Component {

 constructor(props) {
    super(props);
    this.state = {
      isMaint: false,
      isLoading: false,
      heb: [],
      eng: [],
    };
  }

  //async componentDidMount() {
      //const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/ping');
      //const body = await response.text();
      //const newPings = this.state.pingResponse == null ? this.state.pings + 1 : 0;
//      const responsex = await fetch("./maintenanceMessage.txt");
//alert(responsex.json());
//      var result = null;
//      var xmlhttp = new XMLHttpRequest();
//      xmlhttp.open("GET", "./maintenanceMessage.txt", false); //this resides in the public folder
//      xmlhttp.send();
//      if (xmlhttp.status==200) {
//         result = xmlhttp.responseText;
//      }
      //var hebTxt = [], engTxt = [], isMaint = false;
//      if (result != null && util.left(result, 4) == "msg=") {
//          isMaint = true;
//          result = util.clipL(result, 4);
//          var langs = result.split(";");
//          hebTxt = langs[0].split(",");
//          engTxt = langs[1].split(",");
//          //xheb0 = xheb[0]; xheb1 = xheb[1]; xeng0 = xeng[0]; xeng1 = xeng[1];
//      }

//alert(heb0+heb1+eng0+eng1);
      //this.setState({ isMaintMode: isMaint, isLoading: false, heb: hebTxt, eng: engTxt });
  //}

  async componentDidMount() {
    if (sessionStorage.getItem("version") == null) {
        var body = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getSystemVersion');
        var version = await body.text();
        sessionStorage.setItem("version", version);
    }
  }


  render() {
    //const {isMaintMode, heb, eng} = this.state;

    //if (isMaintMode) {
    //  return <Maintenance hebRows={heb} engRows={eng} />;
    //}

    //following script tag was done to enable jQuery for golden-layout as found on Home.js
    return (
      <LocalizeProvider>
          <AuthenticatedApp />
      </LocalizeProvider>
    );
  }
}

export default withRouter(App);

/*
 This might be used at some point for Auth0
 <Route path='/#/callback' exact={true} component={Callback}/>}/>
*/
