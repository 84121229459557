import React, { Component } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import './css/App.css';
import PremiumServicesDialog from './PremiumServicesDialog';
import {withLocalize, Translate } from "react-localize-redux";
import BarChartIcon from '@material-ui/icons/BarChart';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import RefreshIcon from '@material-ui/icons/Refresh';
import { IconButton  } from '@material-ui/core';
import translations from "./json/translations.json";
import { renderToStaticMarkup } from "react-dom/server";
import * as properties from './config/properties.js';


const ResponsiveGridLayout = WidthProvider(Responsive);
class StartMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // value: true,
            goodLayout:[],
            curBreakpoint:"md",
            cols:{md:5},//this is for making it switch when screen shrinks{ md: 4, sm: 3, xsm: 2, xxsm: 1},
            layouts: this.setUpLayoutBasedOnUserPref(),//this.getFromLocalStorage("Layouts")|| {
            userPreference: this.getFromLocalStorage("userPref") || ["a","b","c","d","e","f","g","h","i"],
            viewerHeight:this.props.viewerHeight.match(/(\d+)/)[0],
        };
        this.handleMenuSelection = this.handleMenuSelection.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.allowDrop = this.allowDrop.bind(this);
        this.getFromLocalStorage = this.getFromLocalStorage.bind(this);
        this.saveToLocalStorage = this.saveToLocalStorage.bind(this);
        this.setUpLayoutBasedOnUserPref = this.setUpLayoutBasedOnUserPref.bind(this);
        this.refreshPage = this.refreshPage.bind(this);

        this.props.initialize({
            languages: [
              { name: "עברית", code: "he" },
              { name: "English", code: "en" }
            ],
            translation: translations,
            options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
          });
      
          this.props.addTranslation(translations);
          this.setLanguage = (code) => {props.setActiveLanguage(code)}
      
    }

    componentDidMount(){
        // window.addEventListener("resize", () => {this.setBreakpoints()});
        this.setLanguage(localStorage.getItem("language"));
    }


    setUpLayoutBasedOnUserPref(){
        var toReturn = {
            md: [
                { i: "a", x: 4, y: 0, w: 1, h: 1 },
                { i: "b", x: 3, y: 0, w: 1, h: 1 },
                { i: "c", x: 2, y: 0, w: 1, h: 1 },
                { i: "d", x: 1, y: 0, w: 1, h: 1 },
                { i: "e", x: 0, y: 0, w: 1, h: 1 },
                { i: "f", x: 4, y: 1, w: 1, h: 1 },
                { i: "g", x: 3, y: 1, w: 1, h: 1 },
                { i: "h", x: 2, y: 1, w: 1, h: 1 },
                { i: "i", x: 1, y: 1, w: 1, h: 1 },
            ],
            sm: [
                { i: "a", x: 2, y: 0, w: 1, h: 1 },
                { i: "b", x: 1, y: 0, w: 1, h: 1 },
                { i: "c", x: 0, y: 0, w: 1, h: 1 },
                { i: "d", x: 2, y: 1, w: 1, h: 1 },
                { i: "e", x: 1, y: 1, w: 1, h: 1 },
                { i: "f", x: 0, y: 1, w: 1, h: 1 },
                { i: "g", x: 2, y: 2, w: 1, h: 1 },
                { i: "h", x: 1, y: 2, w: 1, h: 1 },
                { i: "i", x: 0, y: 2, w: 1, h: 1 },
            ],
            xsm: [
                { i: "a", x: 1, y: 0, w: 1, h: 1 },
                { i: "b", x: 0, y: 0, w: 1, h: 1 },
                { i: "c", x: 1, y: 1, w: 1, h: 1 },
                { i: "d", x: 0, y: 1, w: 1, h: 1 },
                { i: "e", x: 1, y: 2, w: 1, h: 1 },
                { i: "f", x: 0, y: 2, w: 1, h: 1 },
                { i: "g", x: 1, y: 3, w: 1, h: 1 },
                { i: "h", x: 0, y: 3, w: 1, h: 1 },
                { i: "i", x: 1, y: 4, w: 1, h: 1 },
            ],
            xxsm: [
                { i: "a", x: 0, y: 0, w: 1, h: 1 },
                { i: "b", x: 0, y: 1, w: 1, h: 1 },
                { i: "c", x: 0, y: 2, w: 1, h: 1 },
                { i: "d", x: 0, y: 3, w: 1, h: 1 },
                { i: "e", x: 0, y: 4, w: 1, h: 1 },
                { i: "f", x: 0, y: 5, w: 1, h: 1 },
                { i: "g", x: 0, y: 6, w: 1, h: 1 },
                { i: "h", x: 0, y: 7, w: 1, h: 1 },
                { i: "i", x: 0, y: 8, w: 1, h: 1 },
            ]
        }
        const userPref = this.getFromLocalStorage("userPref") || ["a","b","c","d","e","f","g","h","i"];
            
        for(let size in toReturn){
            for(let item in userPref){
                toReturn[size][item].i=userPref[item];
            }
        }
        this.setState({layouts: toReturn})
        return toReturn;
    }

    getFromLocalStorage(key) {
        let ls = {};
        if (global.localStorage) {
          try {
            ls = JSON.parse(global.localStorage.getItem(key));
          } catch (e) {
            ls=null;
          }
        }
        if(ls){
            if(key === "Layouts"){
                return ls.Layouts;
            }
            return ls.userPref
        }
        // if(JSON.stringify(ls[key])){
        //     return JSON.parse(JSON.stringify(ls[key]));
        // }
        return null;
    }
    
      saveToLocalStorage(key, value) {
        if (global.localStorage) {
          global.localStorage.setItem(
            key,
            JSON.stringify({
              [key]: value
            })
          );
        }
    }  
      
    onLayoutChange() {
        console.log(("layout changed"));
            this.setState({layouts:[]},
                ()=>{this.setState({layouts:this.setUpLayoutBasedOnUserPref()},
                    ()=>{this.setState({stateChanged:true},)})})
    }


    handleMenuSelection(menuSelection) {
        var fakeThis = this;
        if(menuSelection === 'Parsha Viewer'){
            localStorage.setItem("helpDialogStartupTopic", "parshaViewer");
            this.props.extOpenDockedViewer("ParshaViewer");
        }
        else if (menuSelection === 'Frequency Viewer'){
            localStorage.setItem("helpDialogStartupTopic", "frequencyViewer");
            this.props.extOpenDockedViewer("FrequencyViewer");
        }
        else if (menuSelection === 'textComparator') {
            localStorage.setItem("helpDialogStartupTopic", "textComparatorOptions");
            this.props.extOpenDockedViewer("TextComparatorViewer");
        }
        else if (menuSelection === 'taamParser'){
            localStorage.setItem("helpDialogStartupTopic", "taamParser");
            this.props.extOpenTaamParser();
        }
        else if (menuSelection === 'tanachViewer'){
            localStorage.setItem("helpDialogStartupTopic", "tanachViewer");
            this.props.toggleTanachViewer(true, 1);
        }
        else if (menuSelection === 'objectClouds'){
          localStorage.setItem("helpDialogStartupTopic", "objectCloudViewer");
          this.props.toggleObjectCloudViewer(true);
        }
        else if (menuSelection === 'yuchsinTree'){
          localStorage.setItem("helpDialogStartupTopic", "yuchsinTreeViewer");
          this.props.toggleYuchsinTreeViewer(true);
        }
        else if (menuSelection === 'adminControls'){
            window.location.href = properties.env.applicationURL+"/#/admin";
        }
        else if (menuSelection === 'premiumServices'){
            this.props.togglePremiumServices(true);
        }
        else if(menuSelection === 'lexicon'){
            this.props.toggleLexicon(true);
        }
      }
  
      allowDrop(a,oldDraggedElement,newDraggedElement,placeholder, e) {
        var lastLayout = this.state.layouts;
        var userPref = this.state.userPreference;
        //find which icon was in that new position before
        var pushedIconSub = lastLayout.md.findIndex(elem => elem.x === newDraggedElement.x && elem.y === newDraggedElement.y);
        if(lastLayout.md[pushedIconSub]){
            var pushedLetterSubInUserPref = pushedIconSub;// userPref.findIndex(elem=> elem === lastLayout.md[pushedIconSub].i);
            var posAvailInUserPref = userPref.indexOf(oldDraggedElement.i);
            var temp = userPref[pushedLetterSubInUserPref];
            userPref[pushedLetterSubInUserPref] = oldDraggedElement.i;
            userPref[posAvailInUserPref] = temp;
        }
        this.setState({ userPreference: userPref },
            ()=>{ this.setState({stateChanged:true},
                ()=>{ this.saveToLocalStorage("userPref", this.state.userPreference) })})
    }
      
    refreshPage(){
        localStorage.removeItem("userPref")
        this.setState({layouts:this.setUpLayoutBasedOnUserPref},
        ()=>{this.setState({userPreference: ["a","b","c","d","e","f","g","h"]},
            ()=>{
                this.setState({stateChanged:true})
            })})
    }
    
    componentDidUpdate() {
        if(this.state.stateChanged){
            console.log("state changed")
            {this.setState({stateChanged:false})}

        }
    }


    render() {
        const startPageHeader = this.props.translate("startPageHeader");
        var layouts =  this.state.layouts;//[this.state.curBreakpoint] ;
        var directionStyling={direciton:'ltr'};
        if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
            directionStyling = {direction: 'rtl'};
        }
        return (
            <div> 
                <div>
                    <IconButton title={this.props.translate("refreshHomeTooltip")} style={{float:"right", marginRight:"0px",outlineWidth:"0px"}}>
                                    <RefreshIcon onClick={this.refreshPage} ></RefreshIcon>
                    </IconButton>
                <div style={{paddingTop:"15px", fontSize: "22px", fontWeight:"bold", lineHeight: "normal"}}>{startPageHeader}
                                
                </div>
                </div>
                <div style={{fontSize: "16px", fontStyle:"italic", lineHeight: "normal",direction: directionStyling.direction}}><Translate id="startMenuTitle"/></div>
                <PremiumServicesDialog /* look into why this needs a separate entry here and can't just have the popup dialog enabled like the others */
                    isOpen={this.props.premiumServicesDialogOpen}
                    onClose={() => {this.props.togglePremiumServices(false)}}
                    handleCopyLink={this.props.handleCopyLink}
                />
                <div>
                    <ResponsiveGridLayout
                    className="layout"
                    layouts={layouts}
                    onDragStop={this.allowDrop}
                    cols={this.state.cols}
                    // onBreakpointChange={this.onBreakpointChange}
                    breakpoints={{ md: 1100}}//, sm: 800, xsm: 600, xxsm:200}}
                    onLayoutChange={this.onLayoutChange}    
                    rowHeight={250}
                    autoSize={false}
                    margin={[20,20]}
                    isResizable={false}
                    width={1100}
                    isBounded={true}
                    isDroppable={true}
                    compactType={"vertical"}
                    >

                    <div key="a" className={"parshaViewer panel"} >
                        <span className="center outlined" onClick={()=>this.handleMenuSelection("Parsha Viewer")}>
                            <div className="centerWords">
                                <div className='appMenuIcon'>
                                    <img src={require("./images/closed-book.svg")} width="21" height="21" />
                                </div>
                                &nbsp;&nbsp;&nbsp;
                                <Translate id="parshaViewer" className='appMenuItemText'/>
                                <div className={"subtitle"}style={{direction: directionStyling.direction}}>{this.props.translate("parshaViewerCaption")}</div>
                            </div>
                       </span>
                    </div>
                    <div key="b" className={"tanachViewer panel"}>
                        <span className="center outlined" onClick={()=>this.handleMenuSelection("tanachViewer")}>
                        <div className="centerWords">

                            <div className='appMenuIcon'>
                              <img src={require("./images/menu_book-24px.svg")} width="21" height="21" />
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <Translate id="tanachViewer" className='appMenuItemText'/><br/><br/>
                            <div className={"subtitle"}style={{direction: directionStyling.direction}}>{this.props.translate("tanachViewerCaption")}</div>
                        </div>
                        </span>
                    </div>
                    <div key="c" className={"lexicon panel"}>
                        <span className="center outlined" onClick={()=>this.handleMenuSelection("lexicon")}>
                        <div className="centerWords">
                            <div className='appMenuIcon'>
                                <img src={require("./images/lexicon.jpg")} width="21" height="17" />
                            </div>
                                  &nbsp;&nbsp;&nbsp;
                                  <Translate id="lexicon" className='appMenuItemText'/>
                                  <div className={"subtitle"}style={{direction: directionStyling.direction}}>{this.props.translate("lexiconCaption")}</div>
                                  </div>
                        </span>
                    </div>
                    <div key="d" className={"frequencyViewer panel"} >
                        <span className="center outlined" onClick={()=>this.handleMenuSelection("Frequency Viewer")}>
                        <div className="centerWords">
                        <div className='appMenuIcon'>
                                  <BarChartIcon />
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <Translate id="frequencyMenuItem.caption" className='appMenuItemText'/>
                              <div className={"subtitle"}style={{direction: directionStyling.direction}}>{this.props.translate("frequencyViewerCaption")}</div>
                              </div>
                        </span>
                    </div>
                    <div key="e" className={"textComparator panel"}>
                        <span className="center outlined" onClick={()=>this.handleMenuSelection("textComparator")}>
                        <div className="centerWords">
                        <div className='appMenuIcon'>
                                  <CompareArrowsIcon width="21" height="21"  />
                              </div>
                              &nbsp;&nbsp;
                              <Translate id="textComparator.caption" className='appMenuItemText'/>
                              <div className={"subtitle"}style={{direction: directionStyling.direction}}>{this.props.translate("textComparatorCaption")}</div>
                              </div>
                        </span>
                    </div>
                    <div key="f" className={"taamParser panel"}>
                        <span className="center outlined" onClick={()=>this.handleMenuSelection("taamParser")}>  
                        <div className="centerWords">                      
                        <div className='appMenuIcon'>
                                  <img src={require("./images/icons8-hierarchy-96.png")} width="21" height="21" />
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <Translate id="taamParser" className='appMenuItemText'/>
                              <div className={"subtitle"}style={{direction: directionStyling.direction}}>{this.props.translate("taamParserCaption")}</div>
                              </div>
                        </span>
                    </div>
                    <div key="g" className={"objectClouds panel"}>
                        <span className="center outlined" onClick={()=>this.handleMenuSelection("objectClouds")}>     
                        <div className="centerWords">                   
                        <div className='appMenuIcon'>
                                  <img src={require("./images/iconfinder_cloud_126565.png")} width="21" height="21" />
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <Translate id="objectClouds" className='appMenuItemText'/>
                              <div className={"subtitle"}style={{direction: directionStyling.direction}}>{this.props.translate("objectCloudsCaption")}</div>
                              </div>
                        </span>
                    </div>
                    <div key="h" className={"yuchsinTree panel"}>
                        <span className="center outlined" onClick={()=>this.handleMenuSelection("yuchsinTree")}>
                        <div className="centerWords">

                        <div className='appMenuIcon'>
                                  <img src={require("./images/3d-connections.png")} width="21" height="21" />
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <Translate id="yuchsinTree" className='appMenuItemText'/>
                              <div className={"subtitle"}style={{direction: directionStyling.direction}}>{this.props.translate("yuchsinTreeCaption")}</div>
                              </div>
                        </span>
                    </div>
                    <div key="i" className={"premiumServices panel"}>
                        <span className="center outlined" onClick={()=>this.handleMenuSelection("premiumServices")}>
                        <div className="centerWords">
                            <div className='appMenuIcon'>
                                <img src={require("./images/premiumServicesTransparent.png")} width="21" height="17" />
                            </div>
                                  &nbsp;&nbsp;&nbsp;
                                  <Translate id="premiumServices" className='appMenuItemText'/>
                                  <div className={"subtitle"}style={{direction: directionStyling.direction}}>{this.props.translate("premiumServicesCaption")}</div>
                                  </div>
                        </span>
                    </div>
                    </ResponsiveGridLayout>
                </div>
            </div>
        );
    }
}
export default (withLocalize(StartMenu));


//trying to map through the layouts in order to keep the priority when the sceen size changes
//   {layouts[this.state.curBreakpoint].map(item=>{
//          return(
//             <div key={item.i} style={{ border: "2px solid black", borderRadius: "25px" ,}} >
//                 <span className="center outlined" onClick={()=>this.handleMenuSelection(item.i)}>
//                       <Translate id="parshaViewer"/>
//                 </span>
//             </div>
//           )
//                     })} 


/*this is a different drop method that could be worked on if we want to switch to 
basic divs instead of the react-grid-layout*/
// drop(e) {
//     // e.preventDefault();
//     // const data = e.dataTransfer.getData("id");
//     // const el = document.getElementById(data);
//     // el && e.target.appendChild(document.getElementById(data));

//     var clone="";
//     var dragIndex=0;
//     e.preventDefault();
//     clone=e.target.cloneNode(true);
//     let data=e.dataTransfer.getData("text"); 
//     let nodelist=document.getElementById("parent").childNodes;
//     for(let i=0;i<nodelist.length;i++){
//         if(nodelist[i].id === data){
//             dragIndex=i;
//         }    
//     }
//     document.getElementById("parent").replaceChild(document.getElementById(data),e.target);
//     document.getElementById("parent").insertBefore(clone,document.getElementById("parent").childNodes[dragIndex]);

// }



    /*This sets the current Breakpoint to be either md, sm, xsm, or xxsm based on the width of the window.
    That would correspond to the keys in this.state.layouts to know which one to render.
    It also goes along with the breakpoints props that is in ResponsiveGridLayout.
    If we don't use the breakpoints, we could use this to render 1 of the 4 different layouts based on what curBreakpoint is*/

    // setBreakpoints(){
    //     var windowWidth = (window.innerWidth || document.documentElement.clientWidth);
    //     if(windowWidth>1100){
    //         this.setState({curBreakpoint:"md"});
    //     }else if(windowWidth>800){
    //         this.setState({curBreakpoint:"sm"});
    //     }else if(windowWidth>600){
    //         this.setState({curBreakpoint:"xsm"});
    //     }else{
    //         this.setState({curBreakpoint:"xxsm"});
    //     }
    // }

    // onBreakpointChange (breakpoint) {
    //     this.setState({
    //       curBreakpoint: breakpoint
    //     });
    //   };