import React, { Component } from 'react';
import '../../css/AboutDialog.css';

class Sources extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>
        <span style={{direction: 'ltr', textAlign: 'right', fontSize: 14}}>
          <p /><h5>&nbsp;&nbsp;התנ"ך (אותיות, נקודות, וטעמים) - טקסט מקורי בעברית וארמית</h5><p />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;גיליון אלקטרוני בפרויקט <a href="https://he.wikisource.org/wiki/משתמש:Dovi/מקרא_על_פי_המסורה#ראש" target="_blank"><b>מקרא על פי המסורה</b></a> מאת ויקיטקסט שזמין ב:<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{fontSize: 12}}>https://docs.google.com/spreadsheets/d/1mkQyj6by1AtBUabpbaxaZq9Z2X3pX8ZpwG91ZCSOEYs/edit#gid=920165745</span><br />
          <br />
          <p /><h5>&nbsp;&nbsp;תרגום אונקלוס על החומש (אותיות ונקודות) - טקסט מקורי בארמית</h5><p />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;פרויקט <a href="https://he.wikisource.org/wiki/תרגום_אונקלוס" target="_blank"><b>תרגום אונקלוס</b></a> מאת ויקיטקסט<br />
          <br />
          <p /><h5>&nbsp;&nbsp;קשרי יחסים בין אדם לאדם נגזרו מלהלן</h5><p />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- גיליון אלקטרוני שסופק על ידי נח סנטקרוז מ<a href='https://www.sefaria.org' target='_blank'><b>ספריא</b></a> שהופק מתוכן מוויקינתונים<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- קובץ Gedcom שנוצר על ידי אראל סגל-הלוי ורענן אייכלר עבור <a href='http://tora.us.fm' target='_blank'>אתר הניווט בתנ"ך</a> וזמין ב:<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{fontSize: 12}}>http://tora.us.fm/tnk1/dmut/adm.ged (<a href='http://creativecommons.org/licenses/by-sa/3.0/deed.he' target='_blank'>CC by-sa</a>)</span><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- המחקר שלנו
          <br /><br />
        </span>

      </>
    } else if (langCode === "en") {
      retVal = <>
        <span style={{direction: 'ltr', textAlign: 'left', fontSize: 14}}>
            <p /><h5>&nbsp;&nbsp;The Tanach (letters, nekudos and taamim) - original Hebrew and Aramaic text</h5><p />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A spreadsheet from <a href="https://he.wikisource.org/wiki/משתמש:Dovi/מקרא_על_פי_המסורה#ראש" target="_blank"><b>מקרא על פי המסורה</b></a>: The Mikra al Pi HaMasora project at WikiText/WikiSource available at:<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{fontSize: 12}}>https://docs.google.com/spreadsheets/d/1mkQyj6by1AtBUabpbaxaZq9Z2X3pX8ZpwG91ZCSOEYs/edit#gid=920165745</span><br />
            <br />
            <p /><h5>&nbsp;&nbsp;Targum Onkelos on the Chumash (letters and nekudos) - original Aramaic text</h5><p />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The <a href="https://he.wikisource.org/wiki/תרגום_אונקלוס" target="_blank"><b>Targum Onkelos</b></a> project at WikiText/WikiSource<br />
            <br />
            <p /><h5>&nbsp;&nbsp;Person to person relationship connections were derived from:</h5><p />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- A spreadsheet provided by Noah Santacruz of <a href='https://www.sefaria.org' target='_blank'><b>Sefaria</b></a> that was derived from Wikidata content<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- A Gedcom file created by Erel Segal-Halevi and Raanan Eichler for <a href='http://tora.us.fm' target='_blank'><b>אתר הניווט בתנ"ך</b></a> available at:<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{fontSize: 12}}>http://tora.us.fm/tnk1/dmut/adm.ged (<a href='https://creativecommons.org/licenses/by-sa/3.0/' target='_blank'>CC by-sa</a>)</span><br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Our own research
            <br /><br />
        </span>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default Sources;
