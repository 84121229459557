import React, { Component } from 'react';

class IssuesText extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p /><h5>&nbsp;&nbsp;תנ"ך</h5><p />
        <ul>
          <li><b>יהושע ח:לג</b> - את התיבה "נשאי" באמת צריך להיות אחריו לגרמיה במקום פסק - צריך להיות רק 3 פסקים בפסוק ולא 4</li>
          <li><b>שמואל א:ב:טז</b> - רק הכתיב מופיע בתיבה הארבע עשרה</li>
          <li><b>ישעיהו:סג:ט</b> - רק הכתיב מופיע בתיבה השלישית</li>
          <li><b>ירמיהו:ד:יט</b> - רק הכתיב מופיע בתיבה השלישית</li>
          <li><b>ירמיהו:מ:ח</b> - רק הכתיב מופיע בתיבה השש עשרה</li>
          <li><b>יחזקאל:לה:יב</b> - רק הכתיב מופיע בתיבה החמש עשרה</li>
          <li><b>תהלים יח:נא</b> - המילה "מגדל" משוכפלת</li>
          <li><b>תהלים:נו:ז</b> - רק הכתיב מופיע בתיבה השנית</li>
          <li><b>תהלים:עג:י</b> - רק הכתיב מופיע בתיבה השנית</li>
          <li><b>משלי כא:כט</b> - צריך להיות "הוא" ולא "וא" - גם יש בעיית קרי\כתיב עם "וּא יכין דרכיו דַּרְכּֽוֹ"</li>
          <li><b>איוב ז:א</b> - התיבה "ארץ" חסר</li>
          <li><b>עזרא ה:טו</b> - הפסוק מתחיל ב"ואמר ה" אבל צריך להיות "ואמר לה", גם נמצא בעיית קרי\כתיב </li>
        </ul>
        <p /><h5>&nbsp;&nbsp;תרגום אונקלוס</h5><p />
        <ul>
          <li><b>בראשית מא:מה</b> - טקסט נוסף שנראה כמו "", "{'{'}{'{'}.ת{'}'}{'}'}", "" מופיע בפסוקים הבאים ומשבש גם את ההתאמה בין מילות הפסוק למילות התרגום.</li>
          <li><b>במדבר יד:כד</b> - ""</li>
        </ul>
        <p /><h5>&nbsp;&nbsp;טיפול בטעמים חריגים</h5><p />
        <ul>
          <li>"זה ינחמנו" ב <b>בראשית ה:כט</b> - ב "זה", הגרשיים צריך להופיע מוקדם והתלישא מאוחר.
            לצורך חיפוש החלפתי אותם ושמתי התלישא מוקדם.</li>
        </ul>

      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <p /><h5>&nbsp;&nbsp;Tanach</h5><p />
        <ul>
          <li><b>יהושע ח:לג</b> - the word נשאי should really be followed by legarmei instead of paseq - there should only be 3 paseqs in the pasuk and not 4</li>
          <li><b>שמואל א:ב:טז</b> - only ksiv appears in 14th word</li>
          <li><b>ישעיהו:סג:ט</b> - only ksiv appears in 3rd word</li>
          <li><b>ירמיהו:ד:יט</b> - only ksiv appears in 3rd word</li>
          <li><b>ירמיהו:מ:ח</b> - only ksiv appears in 16th word</li>
          <li><b>יחזקאל:לה:יב</b> - only ksiv appears in 15th word</li>
          <li><b>תהלים יח:נא</b> - the word מגדל is duplicated</li>
          <li><b>תהלים:נו:ז</b> - only ksiv appears in 2nd word</li>
          <li><b>תהלים:עג:י</b> - only ksiv appears in 2nd word</li>
          <li><b>משלי כא:כט</b> - should be הוא instead of וא - also a kri ksiv issue with: וּא יכין דרכיו דַּרְכּֽוֹ</li>
          <li><b>איוב ז:א</b> - The word ארץ is missing</li>
          <li><b>עזרא ה:טו</b> - pasuk starts in "ואמר ה" but should say "ואמר לה", also a kri/ksiv issue there</li>
        </ul>
        <p /><h5>&nbsp;&nbsp;Targum Onkelos</h5><p />
        <ul>
          <li><b>בראשית מא:מה</b> - extra text looking like "", "{'{'}{'{'}.ת{'}'}{'}'}", "" appears in the following pesukim and also disrupts the alignment between pasuk words and targum words</li>
          <li><b>במדבר יד:כד</b> - ""</li>
        </ul>
        <p /><h5>&nbsp;&nbsp;Handling unusual taamim</h5><p />
        <ul>
          <li>זה ינחמנו in <b>בראשית ה:כט</b> - in זה, the gershayim should come first and the telisha gedola second. 
            For search purposes I have it reversed and somehow loaded it with the telisha first.</li>
        </ul>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default IssuesText;