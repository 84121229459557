import React, { Component, useState } from 'react';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import * as properties from './config/properties.js';
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import { renderToStaticMarkup } from "react-dom/server";
import { Paper, Button, DialogTitle, DialogContent, DialogContentText, Typography, TextField, Popover, MenuItem } from '@material-ui/core';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Rnd } from "react-rnd";
import { MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import ContextMenu from './ContextMenu';
import { contextMenu } from 'react-contexify';
import LoadingOverlay from 'react-loading-overlay';
import ObjectCloud from './ObjectCloud';
import ObjectCloudSectionSelector from './ObjectCloudSectionSelector';
import PlacementRenderer from './PlacementRenderer';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Slider from 'rc-slider';
// import HomeIcon from '@material-ui/icons/Home';
// import { IconButton  } from '@material-ui/core';
// import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import pause from './images/pause.jpg';
import play from './images/play.jpg';
import ToggleIconButton from './ToggleIconButton';
import repeatFromStartOn from './images/repeatFromStartOn.jpg';
import repeatFromStartOff from './images/repeatFromStartOff.jpg';
import repeatFromEndOn from './images/repeatFromEndOn.jpg';
import repeatFromEndOff from './images/repeatFromEndOff.jpg';
// import {Checkbox} from 'primereact/checkbox';
// import { HexColorPicker } from "react-colorful";
import { AlphaPicker, ChromePicker, HuePicker, SketchPicker, SliderPicker } from 'react-color';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';


const createSliderWithTooltip = Slider.createSliderWithTooltip;

const styles = theme => ({
  toggleContainer: {
    backgroundColor: 'transparent',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    alignItems: 'center',
    margin: `auto`,
    background: theme.palette.background.default,
  },
});

function encodeFilterIds(nodes, nodeString) {
  var nodeStr = (nodeString != undefined) ? nodeString : "0".repeat(44);
  nodes.forEach((node)=>{
      if (node.tableName == "Sefer" && node.isChecked)
          nodeStr = util.stuff(nodeStr, "1", node.id-1, 1);
      if(node.children){
          nodeStr = encodeFilterIds(node.children, nodeStr);
      }
  })
  return nodeStr;
}

function BaseParameters() {
  this.source = null;
  this.clientIP = null;
  this.morphInflections = null;
  this.filterIds = null;
  this.limit = 2100;
  this.minimumFrequency = 1;
  this.skipTerms = [];
  this.nGrams = null;
  this.sortResultsBy = null;
  this.resultsToDisplay = null;
  this.namedLocations =  []; //NamedLocation()
  this.namedLocations2 =  []; //NamedLocation()
  this.pasukRangeLocations = []; //PerekPasukRange()
  this.pasukRangeLocations2 = []; //PerekPasukRange()
  this.textSource = 1;
}

function NamedLocation(kriaId, aliyaId) {
  this.kriaId =  kriaId; //int
  this.aliyaId = aliyaId; //int
}

function PerekPasukRange(seferId1, perekNum1, pasukNum1, seferId2, perekNum2, pasukNum2) {
  this.seferId1 =  seferId1; //int
  this.perekNum1 = perekNum1;  //int
  this.pasukNum1 =  pasukNum1; //int
  this.seferId2 = seferId2;  //int
  this.perekNum2 =  perekNum2; //int
  this.pasukNum2 = pasukNum2;  //int
}

class ObjectCloudViewer extends Component {

 constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      zIndex: 801,
      cloudData: [],
      wordCountData: {
        totalTerms1: 0,
        termTotal1Accurate: true,
      },
      textSelectorData: [{ type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []}],
      textSelectorApi: null,
      textSelectorColumnApi: null,
      wordsToSkip: [],
      otherWordsToSkip: [],
      searchSettingsIsOpen: false,
      searchSettingsAnchorEl: null,
      wordsToSkipAnchorEl: null,
      wordsToSkipIsOpen: false,
      maxObjects: 100,
      maxObjectsDisplay: '∞',
      minFrequency: 1,
      minFrequencyDisplay: 1,
      playValue:0,
      isRepeatFromStart:false,
      isRepeatFromEnd:false,
      isPlaying:false,
      objectCloudPlay:false,
      isReversePlay:false,
      showLoadingOverlay:true,
      showTitle:false,
      backgroundColor:{
        r: '255',
        g: '238',
        b: '223',
        a: '1',
      },
      titleColor:{
        r: '0',
        g: '0',
        b: '0',
        a: '1',
      },
      displayColorPicker:false,
      displayTitleColorPicker:false,
      parshaId:null,
      parshaPasukLocationList:null,
      seforimInclude:[],
      animationSec:5.0,
      kriahIndex:0,
      title:''
    };

    var timerId;

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}
    this.modifyTextSelectorRow = this.modifyTextSelectorRow.bind(this);
    this.addTextSelectorRow = this.addTextSelectorRow.bind(this);
    this.deleteTextSelectorRow = this.deleteTextSelectorRow.bind(this);
    this.updateWordCountData = this.updateWordCountData.bind(this);
    this.refresh = this.refresh.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.handleOpenSearchSettings = this.handleOpenSearchSettings.bind(this);
    this.handleCloseSearchSettings = this.handleCloseSearchSettings.bind(this);
    this.handleOpenWordsToSkip = this.handleOpenWordsToSkip.bind(this);
    this.handleCloseWordsToSkip = this.handleCloseWordsToSkip.bind(this);
    this.setMaxObjects = this.setMaxObjects.bind(this);
    this.setMinFrequency = this.setMinFrequency.bind(this);
    this.toggleObjectPlayPause = this.toggleObjectPlayPause.bind(this);
    this.setPlayValue = this.setPlayValue.bind(this);
    this.resetTextSelectorData = this.resetTextSelectorData.bind(this);
    this.resetNamedSelection = this.resetNamedSelection.bind(this);
    this.setUpNamedSelectorData = this.setUpNamedSelectorData.bind(this);
    this.setUpTextSelectorData = this.setUpTextSelectorData.bind(this);
    this.toggleRepeatPlayFromEnd = this.toggleRepeatPlayFromEnd.bind(this);
    this.toggleRepeatPlayFromStart = this.toggleRepeatPlayFromStart.bind(this);
    this.toggleShowLoadingOverlay = this.toggleShowLoadingOverlay.bind(this);
    this.toggleShowTitle = this.toggleShowTitle.bind(this);
    this.changeBackgroundColor = this.changeBackgroundColor.bind(this);
    this.changeTitleColor = this.changeTitleColor.bind(this);
    this.clickOut = this.clickOut.bind(this);
    this.seforimToInclude = this.seforimToInclude.bind(this);
    this.checkIfSeferIndexIsValid = this.checkIfSeferIndexIsValid.bind(this);
    this.findStartingSefer = this.findStartingSefer.bind(this);
    this.changeSec = this.changeSec.bind(this);
    this.cancelBackgroundColorChange = this.cancelBackgroundColorChange.bind(this);
    this.cancelTitleColorChange = this.cancelTitleColorChange.bind(this);
 }

findHighestZIndex(elem) {
  var elems = document.getElementsByTagName("*");
  var highest = 0;
  for (var i = 0; i < elems.length; i++)
  {
    var zindex=document.defaultView.getComputedStyle(elems[i],null).getPropertyValue("z-index");
    if ((zindex > highest) && (zindex != 'auto') && (zindex != 9998) && (zindex != 100000090))
    {
      highest = zindex;
    }
  }
  return parseFloat(highest);
}

showOnTop(){
  this.setState({
    zIndex: this.findHighestZIndex() + 1
  })
}

handleClick(e){
  // if(e.target.className !== 'colorSwatch'){
  //   this.showOnTop();
  // }
}

  handleFullscreen(){
    this.props.handleFullscreen();
  }

  handleSplitScreen(){
    this.props.handleSplitScreen();
  }

  handleCopyLink() {
    this.props.handleCopyLink("clouds", null, this.props.isFullScreen(this.props.x, this.props.y, this.props.heightNum,this.props.widthNum));
  }

  async handleClose(){
    this.setState({isPlaying:false, objectCloudPlay:false,isReversePlay:false},()=>{
      this.props.toggleOpen(false);
      this.clearAll();
    })
  }

  clearAll(){
    this.setState({
      cloudData: [],
      wordCountData: {
        totalTerms1: 0,
        termTotal1Accurate: true,
      },
      textSelectorData: [{ type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []}],
      textSelectorApi: null,
      textSelectorColumnApi: null,
      wordsToSkip: [],
      otherWordsToSkip: [],
      maxObjects: 100,
      maxObjectsDisplay: '∞',
      minFrequency: 1,
      minFrequencyDisplay: 1,
      title:''
    })
  }

  getRenderVals(textDataRow){
    var renderVal1, renderVal2, renderVal3, renderVal4, renderVal5, renderVal6
    var renderVals
    if(textDataRow.type){ //is named section
        var selectedVal1 = textDataRow.valList1.filter(obj => {return obj.id === textDataRow.val1});
        var selectedVal2 = textDataRow.valList2.filter(obj => {return obj.id === textDataRow.val2});
        var selectedVal3 = textDataRow.valList3.filter(obj => {return obj.id === textDataRow.val3});
        var selectedVal4 = textDataRow.valList4.filter(obj => {return obj.id === textDataRow.val4});
        var selectedVal5 = textDataRow.valList5.filter(obj => {return obj.id === textDataRow.val5});

        if(selectedVal1[0]){ renderVal1 = selectedVal1[0].value+' > '}
        if(selectedVal2[0]){ renderVal2 = selectedVal2[0].value+' > '}
        if(selectedVal3[0]){ renderVal3 = selectedVal3[0].value+' > '}
        if(selectedVal4[0]){ renderVal4 = selectedVal4[0].value+' > '}
        if(selectedVal5[0]){ renderVal5 = selectedVal5[0].value+' > '}

        var renderVals = [renderVal1, renderVal2, renderVal3, renderVal4, renderVal5]

        for(let i = 4; i >= 0; i--){
            if(renderVals[i]){
                renderVals[i] = renderVals[i].substring(0, renderVals[i].length - 3)
                break
            }
            //if it is defined, set the one after it as the last value
        }

    } else { //is pasuk range
        var selectedVal1 = textDataRow.valList1.filter(obj => {return obj.seferId === textDataRow.val1});
        var selectedVal2 = textDataRow.valList2.filter(obj => {return obj.perekNum === textDataRow.val2});
        var selectedVal3 = textDataRow.valList3.filter(obj => {return obj.pasukNum === textDataRow.val3});
        var selectedVal4 = textDataRow.valList4.filter(obj => {return obj.seferId === textDataRow.val4});
        var selectedVal5 = textDataRow.valList5.filter(obj => {return obj.perekNum === textDataRow.val5});
        var selectedVal6 = textDataRow.valList6.filter(obj => {return obj.pasukNum === textDataRow.val6});

        if(selectedVal1[0]){ renderVal1 = selectedVal1[0].seferName}
        if(selectedVal2[0]){ renderVal2 = selectedVal2[0].perekValue}
        if(selectedVal3[0]){ renderVal3 = selectedVal3[0].pasukValue}
        if(selectedVal4[0]){ renderVal4 = selectedVal4[0].seferName}
        if(selectedVal5[0]){ renderVal5 = selectedVal5[0].perekValue}
        if(selectedVal6[0]){ renderVal6 = selectedVal6[0].pasukValue}

        renderVals = [renderVal1, renderVal2, renderVal3, renderVal4, renderVal5, renderVal6]
    }

    return renderVals
}

  textSelectorGridReady = params => {
    this.setState({
      textSelectorApi: params.api,
      textSelectorColumnApi: params.columnApi
    }, () => {
      this.state.textSelectorApi.setRowData(this.state.textSelectorData);

      //following CSS classes seem to get overridden below in PlacementRenderer by placementGridLine and rangeGridLine
            this.state.textSelectorApi.setColumnDefs(
                    [{headerName: this.props.translate("addLines"), headerClass: 'row-thin-center', field: "namedSection", width: 1168, cellClass: "row-thin-small", cellRendererFramework: PlacementRenderer, cellRendererParams:
                        {
                          placementRowData:this.props.placementRowData,
                          extModifyPlacementRow: this.modifyTextSelectorRow,
                          extAddPlacementRow: this.addTextSelectorRow,
                          extDeletePlacementRow: this.deleteTextSelectorRow,
                          translate: this.props.translate, //do I need to pass this here?
                      },
                //cellClass: function(params) {return params.data.type ? 'placementGridLine' : 'rangeGridLine';},
                      }]
                );
            if (this.state.textSelectorData.length > 0)
                this.state.textSelectorApi.setFocusedCell(0, "namedSection", null);
          //   var instances = this.refs.agGrid.gridOptions.api.getCellRendererInstances(params);
    })
  };

  updateWordCountData(newData){
    this.setState({
        wordCountData: newData
    })
}

  modifyTextSelectorRow(extState){
    var newWordCountData = this.state.wordCountData;
    newWordCountData.termTotal1Accurate = false;
    this.updateWordCountData(newWordCountData);
    // this.setState({termTotal1Accurate: false, wordCountData: newWordCountData})

    var isAddRow = (extState.type && extState.val4 != this.state.textSelectorData.val4 && extState.val4 != 0) || (!extState.type && extState.val3 != this.state.textSelectorData.val3 && extState.val3 != 0 && extState.val6 != this.state.textSelectorData.val6 && extState.val6 != 0);
    var selected = this.state.textSelectorApi.getFocusedCell();

    var i = selected.rowIndex;
    var rowValues = {type: extState.type, val1: extState.val1, val2: extState.val2, val3: extState.val3, val4: extState.val4, val5: extState.val5, val6: extState.val6,
                                          valList1: extState.valList1, valList2: extState.valList2, valList3: extState.valList3, valList4: extState.valList4, valList5: extState.valList5, valList6: extState.valList6 };
    var newData = this.state.textSelectorData;
    newData[i] = rowValues;

    this.setState( {textSelectorData: newData}, () => {
        if (i == this.state.textSelectorApi.getLastDisplayedRow() && isAddRow) {
            this.addTextSelectorRow();
        }else{
          this.setState({textSelectorData:[]},()=>{
            this.state.textSelectorApi.updateRowData({ update: [rowValues] });
            this.setState({ textSelectorData: newData })
          })
        }
    });
  }

  addTextSelectorRow(){
    var newRow = {type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []};
    var newData = this.state.textSelectorData;
    newData.push(newRow);
    this.setState({textSelectorData:[]},()=>{
      this.state.textSelectorApi.updateRowData({ add: [newRow] });
      this.setState({ textSelectorData: newData, rowDataLength:newData.length-1 })
    })
    // this.setState( {textSelectorData: newData}, () => {this.state.textSelectorApi.updateRowData({ add: [newRow] })});
  }

  deleteTextSelectorRow(extState){
    var newWordCountData = this.state.wordCountData;
    newWordCountData.termTotal1Accurate = false;
    this.updateWordCountData(newWordCountData)

    var selected = this.state.textSelectorApi.getFocusedCell();
    var i = selected.rowIndex;
    if (i == this.state.textSelectorApi.getLastDisplayedRow()) { //then just clear
        this.modifyTextSelectorRow(extState);
        //selected.setSelected(false);
    }
    else {
        var selectedData = this.state.textSelectorApi.getSelectedRows();
        var newData = this.state.textSelectorData;
        newData.splice(i,1);
        this.setState( {textSelectorData: newData, rowDataLength:i}, () => {this.state.textSelectorApi.updateRowData({ remove: selectedData }) });
    }
} 

getNewBaseParameters(){ //THIS NEEDS TO BE EDITTED!!
  var baseParams = new BaseParameters();
  var filterIds = encodeFilterIds(this.props.extFilterData);
  filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
  filterIds = util.right("00000000000"+filterIds,11);
  baseParams.nGrams = this.state.nGrams;
  baseParams.sortResultsBy = this.state.sortResultsByChosen;
  baseParams.resultsToDisplay = this.state.resultsToDisplay;
  baseParams.source = 'bhsWebApp';
  baseParams.filterIds = filterIds;
  baseParams.morphInflections = this.props.extSearchDefData; //str
  baseParams.isReverse = this.state.direction === 'reverse';
  baseParams.limit = this.state.maxObjects < 100 ? this.state.maxObjects : 2100;
  baseParams.minimumFrequency = this.state.minFrequency;
  baseParams.skipTerms = this.state.wordsToSkip;
  baseParams.namedLocations = [];
  baseParams.pasukRangeLocations = [];
  baseParams.textSource = this.props.textSource==='targum'?2:1;

  return baseParams;
}

seforimToInclude(filterData, filterDataArrayInc){
  var seforimInclude=(filterDataArrayInc != undefined)?filterDataArrayInc:[];//array of seforim to include
  filterData.forEach((data)=>{
    if(data.children){
      this.seforimToInclude(data.children, seforimInclude);
    }else{
      if(data.isChecked){
        seforimInclude.push(data);
      }
    } 
  })
  this.setState({seforimInclude})
}

refresh(){
  this.setState({ isLoading: true }, async () => {
    var baseParams = this.getNewBaseParameters();

    var e, i;
    for (i = 0; i < this.state.textSelectorData.length-1; i++) { //last position in placementRowData is never populated
        e = this.state.textSelectorData[i];
        if (e.type) {
          baseParams.namedLocations.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
        }
        else {
          baseParams.pasukRangeLocations.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
        }
    }

    //this should call a new web service (getObjectCloudData)
    // that should return a data object similar to the one returned by getTextBlock along with a "data" object formatted as follows:
    //        const data = [
    //          { text: 'Hey', value: 1000 },
    //          { text: 'lol', value: 200 },
    //          { text: 'first impression', value: 800 },
    //          { text: 'very cool', value: 1000000 },
    //          { text: 'duck', value: 10 },
    //        ];
    // cloudData1 and cloudData2 should be set equal to the appropriate cloud data objects
    // textBlock1Data and textBlock2Data should be set to the other obejct retuned by the service
    const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getFrequencies', {method: 'post', body: JSON.stringify(baseParams)});
    const serverData = await response.json();

  var data = [];
  var wordCount = 0;

  if (baseParams.morphInflections != 'N' && baseParams.morphInflections != 'T' && baseParams.morphInflections != 'NT') {
      serverData.map((entry) => {
          data.push({
            text: entry.testField,
            value: entry.totalAppearances
          })
          wordCount += entry.totalAppearances;
      })
  }
  else {
      serverData.map((entry) => {
          data.push({
            text: this.padWordCloudText(entry.testField, baseParams.morphInflections),
            value: entry.totalAppearances
          })
          wordCount += entry.totalAppearances;
      })
  }

    var cloudData = data; //await this.getCloudData(baseParams)

    var newWordCountData = {
      totalTerms1: wordCount, //this.getWordCountFromTextBlockData(textBlockData),
      termTotal1Accurate: true,
    }

    this.setState({
      isLoading: false,
      wordCountData: newWordCountData,
      cloudData: cloudData,
    })
    var timer = setTimeout(() => this.setUpTitle(), 900)
  })
}

padWordCloudText(text, morphInflections) {
    var newText = '';
    for (let i = 0; i < text.length; i++)
        newText += (morphInflections == 'T' ? 'א' : 'ת') + text[i];
    return newText;
}

getWordCountFromTextBlockData(textBlockData){
  var textStr = "";
  for(let i = 0; i < textBlockData.length; i++) { // loop through sections
    var currSection = textBlockData[i];
    for(let j = 0; j < currSection.length; j++) { // loop through psukim in section
      var currPasuk = currSection[j];
      var pasukText = typeof(currPasuk.kriaName) === 'string' ? currPasuk.t1 : currPasuk.text;
      textStr += pasukText;
      if(i !== textBlockData.length-1 && j !== currSection.length-1){
        textStr += " ";
      }
    }
  }
  var wordCount = textStr.split(" ").length;
  return wordCount;
}

//mje - looks like as of 6/22/2020 this doesn't get called anymore
async getCloudData(baseParams){
  const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getTextBlock', {method: 'post', body: JSON.stringify(baseParams)});
  const body = await response.json();

  var text = "";

  body.map((pesukimCollection) => {
    pesukimCollection.map((pasukData => {
      var pasukText = pasukData.t1 ? pasukData.t1 : pasukData.text;
      var pasukWordsArr = pasukText.split(" ");
      pasukWordsArr.map((word) => {
        var cleanedWord = this.removeTaamim(this.removeNikud(word));
        if(this.state.wordsToSkip.indexOf(cleanedWord) === -1){
          var formattedWord = word;
          if(baseParams.morphInflections.indexOf("L") === -1){
            formattedWord = this.removeLetters(formattedWord)
          }
          if(baseParams.morphInflections.indexOf("N") === -1){
            formattedWord = this.removeNikud(formattedWord)
          }
          if(baseParams.morphInflections.indexOf("T") === -1){
            formattedWord = this.removeTaamim(formattedWord)
          }
          text = text.concat(" " + formattedWord);
        }
      })
    }))
  })

  var data = [];
  var textArr = text.split(" ");
  textArr.map((word) => {
    var wordIndex = data.map(e => e.text).indexOf(word);
    if(wordIndex === -1){
      data.push({
        text: word,
        value: 1
      })
    } else {
      data[wordIndex].value++
    }
  })

  return data;
}

removeNikud(str){
  for (let i = str.length-1; i >= 0; i--){
      if('ְֱֲֳִֵֶַָֹֺֻּֽ־ׇֿׁׂׅׄ'.includes(str[i])){
          str = str.slice(0,i).concat(str.slice(i+1,str.length))
      }
  }
  return str;
}

removeTaamim(str){
  for (let i = str.length-1; i >= 0; i--){
      if('ֽ֑֖֛֢֣֤֥֦֧֪֚֭֮֒֓֔֕֗֘֙֜֝֞֟֠֡֨֩֫֬֯׀'.includes(str[i])){
          str = str.slice(0,i).concat(str.slice(i+1,str.length))
      }
  }
  return str;
}

removeLetters(str){ // replaces all letters with ת
  for (let i = str.length-1; i >= 0; i--){
      if("קראטוןםפשדגכעיחלךףזסבהנמצתץ".includes(str[i])){
          str = str.slice(0,i).concat("ת").concat(str.slice(i+1,str.length))
      }
  }
  return str;
}

handleOpenSearchSettings(e){
  this.setState({
    searchSettingsIsOpen: true,
    searchSettingsAnchorEl: e.currentTarget
  })
}

handleCloseSearchSettings(){
  this.setState({
    searchSettingsIsOpen: false,
    searchSettingsAnchorEl: null
  })
}

handleOpenWordsToSkip(e){
  this.setState({
    wordsToSkipAnchorEl: e.currentTarget,
    wordsToSkipIsOpen: true
  })
}

handleCloseWordsToSkip(){
  this.setState({
    wordsToSkipAnchorEl: null,
    wordsToSkipIsOpen: false
  })
}

handleSelectWordToSkip(e){
  var val = e.target.attributes.value.value // e.target.value isn't working properly here
  var wordsToSkip = this.state.wordsToSkip;
  if(val === 'clearAll'){
    wordsToSkip = [];
    this.setOtherWordsToSkip({target:{value:""}})
  } else {
    if(wordsToSkip.includes(val)){
      var valIndex = wordsToSkip.indexOf(val);
      wordsToSkip.splice(valIndex, 1);
      //remove from other words
      if(this.state.otherWordsToSkip.includes(val)){
        var otherWords = this.state.otherWordsToSkip;
        var valIndex = otherWords.indexOf(val);
        otherWords.splice(valIndex, 1);
        this.setState({
          otherWordsToSkip: otherWords
        })
      }
    } else {
      wordsToSkip.push(val);
    }
  }
  wordsToSkip = wordsToSkip.sort()
  this.setState({ wordsToSkip })
}

setOtherWordsToSkip(e){
  var wordsString = e.target.value;
  var wordsArr = wordsString.split(" ")
  //remove old words
  var oldWords = this.state.otherWordsToSkip;
  oldWords.map(word => {
    if(!wordsArr.includes(word)){
      var e = {target: {attributes: {value: {value: word}}}}
      this.handleSelectWordToSkip(e)
    }
  })
  //add new words
  wordsArr.map(word => {
    if(!this.state.wordsToSkip.includes(word)){
      var e = {target: {attributes: {value: {value: word}}}}
      this.handleSelectWordToSkip(e)
    }
  })
  this.setState({
    otherWordsToSkip: wordsArr
  })
}

  getDisplayValue(value){
    if(value === 100){
      value = '∞'
    }
    return `${value}`;
  }

  setMaxObjects(val){
      var dispVal = this.getDisplayValue(val);
      this.setState({maxObjects: val, maxObjectsDisplay: dispVal});
  }

  setMinFrequency(val){
      this.setState({minFrequency: val, minFrequencyDisplay: val});
  }

  /* todo
     if would want to cycle through kria segments instead of krios in animation,
     then for setUpNamedSelectorData() and resetNamedSelection() and possibly elsewhere
     would need to do something like the following and drastically modify the looping
     var detailSelection = namedData[0].val5 == 0 ? { list: namedData[0].valList4, val: namedData[0].val4 } : { list: namedData[0].valList5, val: namedData[0].val5 };
     var kriahIndex = detailSelection.list.findIndex((e) => e.id === detailSelection.val)

     can have two different functions for attacking kria-level looping vs. segment-level looping
  */

  async setUpNamedSelectorData(kriahIndex){//todo: make the named selection read the val5 if val4 only has the name (shabbos Shuva)- right now it just skips it
    var namedData = this.state.textSelectorData[0];
    // var kriah = this.state.textSelectorData[0].val4;
    // if(kriah === 244) kriah=245;//if it is the shabbos Shuva haftorah, set it to yom kippur because there are 2 ids that are 244
    var kriahList = this.state.textSelectorData[0].valList4;
    var kriahIndex = kriahIndex ? kriahIndex : this.state.kriahIndex// this.state.textSelectorData[0].valList4.findIndex((e) => e.id === kriah)
   
    var sefer = namedData.valList4[kriahIndex].value.split("(")
    sefer = this.findSeferOfNamedSelection(sefer)
    // if(sefer[1]) sefer = sefer[1].split(" ") //stops it from trying to split a selection that doesn't have any parenthasis(shabbos shuva haftorah) goes to next in cycle
    // if(['שמואל', 'מלכים'].includes(sefer[0]))sefer[0] = sefer[0] +" "+sefer[1] 
    // if(['דברי'].includes(sefer[0])) sefer[0] = sefer[0] +" "+sefer[1]+" "+sefer[2]

    var firstTime = true
    var lastKriahIndex = kriahIndex;
    var increment
    while(firstTime || this.state.seforimInclude.findIndex(e=>e.name == sefer[0])<0){
      increment = 1;
      if(kriahIndex < kriahList.length-1){
        if(!this.state.isReversePlay){
          if(kriahList[kriahIndex + 1].id === namedData.val4 || ([22,28,31,35,43,47,57].includes(kriahList[kriahIndex + 1].id))){
            increment = 2
          }
          kriahIndex = kriahIndex + increment;
        } else {
          if(kriahIndex >= 1){
            if(kriahList[kriahIndex - 1].id === namedData.val4 || ([22,28,31,35,43,47,57].includes(kriahList[kriahIndex - 1].id))){
              increment = 2
            }  
            kriahIndex = kriahIndex - increment;
          } else if(this.state.isRepeatFromEnd || this.state.isRepeatFromStart){
            if(kriahList[kriahIndex + 1].id === namedData.val4 || ([22,28,31,35,43,47,57].includes(kriahList[kriahIndex + 1].id))){
              increment = 2
            }  
            kriahIndex = kriahIndex + increment;
            this.setState({isReversePlay:false})
          } else{
            util.setIsPlaying(false)
            this.setState({objectCloudPlay:false, isPlaying:false, isReversePlay:false})
          }
        }
      } else if(this.state.isRepeatFromStart || this.state.inFirstCycle){
          kriahIndex = 0;
          this.setState({inFirstCycle:false})
      } else if(this.state.isRepeatFromEnd){
        if(kriahList[kriahIndex - 1].id === namedData.val4 || ([22,28,31,35,43,47,57].includes(kriahList[kriahIndex - 1].id))){
          increment = 2
        }  
          kriahIndex = kriahIndex - increment;
          this.setState({isReversePlay:true})
      } else {
        namedData = [{ type: true, val1: namedData.val1, val2: namedData.val2, val3: namedData.val3, val4: kriahList[lastKriahIndex].id, val5: 0, val6: 0,
                      valList1: namedData.valList1, valList2: namedData.valList2, valList3: namedData.valList3, valList4: namedData.valList4, valList5: namedData.valList5, valList6: namedData.valList6},
                      { type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []}]
          this.setState({isPlaying:false, objectCloudPlay:false, isReversePlay:false,noTitle:true},()=>{
            util.setIsPlaying(false)
            kriahIndex = lastKriahIndex
            // namedData.val4 = null
          });
          return namedData
      }
    sefer = namedData.valList4[kriahIndex].value.split("(")
    sefer = this.findSeferOfNamedSelection(sefer)
    // if(sefer[1]) sefer = sefer[1].split(" ") //stops it from trying to split a selection that doesn't have any parenthasis(shabbos shuva haftorah) goes to next in cycle
    // if(['שמואל', 'מלכים'].includes(sefer[0]))sefer[0] = sefer[0] +" "+sefer[1] 
    // if(['דברי'].includes(sefer[0])) sefer[0] = sefer[0] +" "+sefer[1]+" "+sefer[2]
    firstTime = false;
  }
    var kriah = kriahList[kriahIndex].id
    
    this.setState({kriahIndex,noTitle:false})
    namedData = [{ type: true,
              val1: namedData.val1,
              val2: namedData.val2,
              val3: namedData.val3,
              val4: kriah,
              val5: 0,
              val6: 0,
              valList1: namedData.valList1,
              valList2: namedData.valList2,
              valList3: namedData.valList3,
              valList4: namedData.valList4,
              valList5: namedData.valList5,
              valList6: namedData.valList6},{ type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []}]
      
    return namedData;
  }
  
  async resetNamedSelection(){
    var namedData = this.state.textSelectorData[0];
    namedData = [{ type: true,
      val1: namedData.val1,
      val2: namedData.val2,
      val3: namedData.val3,
      val4: namedData.val4,
      val5: 0,
      val6: 0,
      valList1: namedData.valList1,
      valList2: namedData.valList2,
      valList3: namedData.valList3,
      valList4: namedData.valList4,
      valList5: namedData.valList5,
      valList6: namedData.valList6},{ type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []}]
      var kriahIndex = namedData[0].valList4.findIndex((e) => e.id === namedData[0].val4)
      this.setState({kriahIndex})//,async()=>{
        var sefer = namedData[0].valList4[kriahIndex].value.split("(")
        sefer = this.findSeferOfNamedSelection(sefer);
        // if(sefer[1]) sefer = sefer[1].split(" ")
        // else sefer[0] = this.state.seforimInclude[0].name //stops it from trying to split a selection that doesn't have any parenthasis(shabbos shuva haftorah) goes to next in cycle
        // if(['שמואל', 'מלכים'].includes(sefer[0]))sefer[0] = sefer[0] +" "+sefer[1] 
        // if(['דברי'].includes(sefer[0])) sefer[0] = sefer[0] +" "+sefer[1]+" "+sefer[2]

        if( this.state.seforimInclude.findIndex(e=>e.name == sefer[0])<0 || ([22,28,31,35,43,47,57].includes(namedData[0].valList4[kriahIndex].id))){
          namedData = await this.setUpNamedSelectorData(kriahIndex);
        }
        return namedData;
      //})
      
  }

  findSeferOfNamedSelection(sefer){
    if(sefer[1]) sefer = sefer[1].split(" ")//stops it from trying to split a selection that doesn't have any parenthasis(shabbos shuva haftorah)
    else sefer[0] = this.state.seforimInclude[0].name //for now if it is a complex section, it assumes that the sefer is checked and will show it no matter what.
    if(['שמואל', 'מלכים'].includes(sefer[0]))sefer[0] = sefer[0] +" "+sefer[1] 
    if(['דברי'].includes(sefer[0])) sefer[0] = sefer[0] +" "+sefer[1]+" "+sefer[2]
    return sefer;
  }

  checkIfSeferIndexIsValid(seferIndex, sefer,findFirstSefer){
    var newSeferIndex = seferIndex;
    while(newSeferIndex < 0){
      if(!this.state.isReversePlay){
        if(sefer < 44){
          sefer++;
        } else if(this.state.isRepeatFromStart){
          sefer = 1;
        } else if(this.state.isRepeatFromEnd){
          sefer = sefer - 1;
          this.setState({isReversePlay:true})
        } else if(findFirstSefer){
          sefer = 1
        } else {
          util.setIsPlaying(false)
          this.setState({objectCloudPlay:false, isPlaying:false, isReversePlay:false})
        }        
      } else {
        if(sefer-1 >= 0){
          sefer = sefer - 1;
        } else if(this.state.isRepeatFromEnd || this.state.isRepeatFromStart){
          sefer = 1;
          this.setState({isReversePlay:false})
        } else {
          util.setIsPlaying(false)
          this.setState({objectCloudPlay:false, isPlaying:false, isReversePlay:false})
        }
      }
      if(this.state.objectCloudPlay){  newSeferIndex = this.state.seforimInclude.findIndex(e=>e.id === sefer)}
      else{ return -1}
    }
    return newSeferIndex;
  }

  async setUpTextSelectorData(){
    var changableTextSelectorData = this.state.textSelectorData;
    var pasukBeg = 1
    var pasukEnd = this.state.textSelectorData[0].val6;
    var perek = this.state.textSelectorData[0].val2;
    var perekEnd = this.state.textSelectorData[0].val5
    var sefer = this.state.textSelectorData[0].val1;
    var seferIndexList1 = this.state.textSelectorData[0].valList1.findIndex((e) => e.seferId=== sefer);
    var seferIndex = this.state.seforimInclude.findIndex(e=>e.id === sefer)
    var newSeferIndex ;
    var list1 = this.state.textSelectorData[0].valList1;
    var list2 = this.state.textSelectorData[0].valList2;
    var list3 = this.state.textSelectorData[0].valList3;
    var list4 = this.state.textSelectorData[0].valList4;
    var list5 = this.state.textSelectorData[0].valList5;
    var list6 = this.state.textSelectorData[0].valList6;
    const parshiosList=this.state.parshaPasukLocationList;
    var parshaId = this.state.parshaId;//parshaId is the place in the array that the player is up to.

    if(this.state.playValue === 0){//sefer
      newSeferIndex = this.checkIfSeferIndexIsValid(seferIndex, sefer);
      if(seferIndex === newSeferIndex){
        if(seferIndex < (this.state.seforimInclude.length-1)){
          if(!this.state.isReversePlay){
            seferIndex = seferIndex + 1;
          } else {
            if(seferIndex-1 >= 0){
              seferIndex = seferIndex - 1;
            } else if(this.state.isRepeatFromEnd || this.state.isRepeatFromStart){
              seferIndex = 1;
              this.setState({isReversePlay:false})
            } else {
              util.setIsPlaying(false)
              this.setState({objectCloudPlay:false, isPlaying:false, isReversePlay:false})
            }
          }
        } else if(this.state.isRepeatFromStart){
          seferIndex = 0;
        } else if(this.state.isRepeatFromEnd){
          seferIndex = seferIndex - 1;
          this.setState({isReversePlay:true})
        } else {
          util.setIsPlaying(false)
          this.setState({objectCloudPlay:false, isPlaying:false, isReversePlay:false})
        }
      } else{
        seferIndex = newSeferIndex;
      }
      if(newSeferIndex >= 0){
        sefer = this.state.seforimInclude[seferIndex].id
        list2 = await util.loadPerakim(sefer);
        perek = 1;
        perekEnd = list2[list2.length-1].perekNum;
        list3 = await util.loadPesukim(perek, list2)
        list5 = list2;//both perakim lists should be the same here
        list6 = await util.loadPesukim(perekEnd,list2);//pesukim in the last perek of the sefer
        pasukEnd = list6[list6.length-1].pasukNum
      } else return null

    } else if(this.state.playValue === 1){//parsha = set the seforim to work with the right bar
      newSeferIndex = this.checkIfSeferIndexIsValid(seferIndex, sefer);
      if(seferIndex === newSeferIndex){
        if(parshaId < parshiosList.length-1){
          if(!this.state.isReversePlay){
            parshaId ++;
          } else{
            if(parshaId >= 1){
              parshaId --;
            } else if(this.state.isRepeatFromEnd || this.state.isRepeatFromStart){
              parshaId ++;
              this.setState({isReversePlay:false})
            } else {
              util.setIsPlaying(false)
              this.setState({objectCloudPlay:false, isPlaying:false, isReversePlay:false})
            }
          }
        } else if(this.state.isRepeatFromStart){
          parshaId = 0;
        } else if(this.state.isRepeatFromEnd){
          parshaId --;
          this.setState({isReversePlay:true})
        } else {
          util.setIsPlaying(false)
          this.setState({objectCloudPlay:false, isPlaying:false, isReversePlay:false})
        }
        if(parshiosList[parshaId].seferId !== sefer){//if the new sefer is different from the last one
          newSeferIndex = this.checkIfSeferIndexIsValid(-1,sefer)
          sefer = this.state.seforimInclude[newSeferIndex].id
          parshaId = parshiosList.findIndex(e=>e.seferId === sefer)
          list2 = await util.loadPerakim(sefer);
        }
      } else if(newSeferIndex>=0){
        sefer = this.state.seforimInclude[newSeferIndex].id
        parshaId = parshiosList.findIndex(e=>e.seferId === sefer)
        list2 = await util.loadPerakim(sefer);
      }
      if(newSeferIndex>=0){
        perek = parshiosList[parshaId].perekAliasNum1
        list3 = await util.loadPesukim(perek, list2)
        list5 = list2;
        perekEnd = parshiosList[parshaId].perekAliasNum2
        list6 = await util.loadPesukim(perekEnd, list2)
        pasukBeg = parshiosList[parshaId].pasukAliasNum1
        pasukEnd = parshiosList[parshaId].pasukAliasNum2
      } 
        this.setState({parshaId:parshaId})

    } else if(this.state.playValue === 2){//perek
      newSeferIndex = this.checkIfSeferIndexIsValid(seferIndex, sefer)
      if(seferIndex ===  newSeferIndex){
        if(!this.state.isReversePlay){
            if(perek < (list1[seferIndexList1].perakim)){//if don't have to go to next sefer
              perek = perek + 1
            } else {
                if(seferIndex < (this.state.seforimInclude.length-1)){
                  perek = 1
                  seferIndex = seferIndex + 1;
                  sefer = this.state.seforimInclude[seferIndex].id
                  list2 = await util.loadPerakim(sefer);
                } else{
                  if(this.state.isRepeatFromStart){
                    perek = 1
                    sefer = 1;
                    seferIndex = 0;
                    list2 = await util.loadPerakim(sefer);
                  } else if(this.state.isRepeatFromEnd){ //go backwards
                    perek --;
                    this.setState({isReversePlay:true});
                  } else{
                    util.setIsPlaying(false)
                    this.setState({objectCloudPlay:false, isPlaying:false, isReversePlay:false})
                  }
                }
            }
        } else {//if in reverse
          if(perek>1){//if don't have to go to previous sefer
            perek --;
          } else{
            if(seferIndex>=1){
              seferIndex --;
              sefer = this.state.seforimInclude[seferIndex].id
              list2 = await util.loadPerakim(sefer);
              // list5=list2;
              perek = list2[list2.length-1].perekNum
            } else {
              if(this.state.isRepeatFromEnd || this.state.isRepeatFromStart){
                perek ++; 
                this.setState({isReversePlay:false}) 
              } else {
                util.setIsPlaying(false)
                this.setState({objectCloudPlay:false, isPlaying:false, isReversePlay:false})
              }
            }
          }
        }
      } else if(newSeferIndex>=0){//if already changed the seferIndex
        seferIndex = newSeferIndex;
        sefer = this.state.seforimInclude[seferIndex].id
        list2 = await util.loadPerakim(sefer);
        if(!this.state.isReversePlay){
          perek = 1;
        } else {
          perek = list2[list2.length-1].perekNum
        }
      }
      if(newSeferIndex>=0){
        this.setState({noTitle:false})
        list3 = await util.loadPesukim(perek, list2)//load pesukim for the new perek
        list6 = list3;//both pesukim lists should be the same here
        list5 = list2;
        pasukEnd = list3[list3.length-1].pasukNum;
        perekEnd = perek;
      } else{ this.setState({noTitle:true})}
    }
        changableTextSelectorData = [{ type: false,
                                    val1: sefer,
                                    val2: perek,
                                    val3: pasukBeg,
                                    val4: sefer,
                                    val5: perekEnd,
                                    val6: pasukEnd,
                                    valList1: list1,
                                    valList2: list2,
                                    valList3: list3,
                                    valList4: list4,
                                    valList5: list5,
                                    valList6: list6},{ type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []}]
  
      return changableTextSelectorData
  }

  toggleObjectPlayPause(){
    // var timerId;
    var changableTextSelectorData;
    this.setState({objectCloudPlay:!this.state.objectCloudPlay, isPlaying:!this.state.isPlaying,inFirstCycle:true}, async () => {
      util.setIsPlaying(true)
      if(this.state.objectCloudPlay && this.state.seforimInclude.length>0){
        // this.setState({isPlaying:true}, async () => {
            if(this.state.textSelectorData.length === 2){
              if(!this.state.textSelectorData[0].type){//if the user entered in one pasuk range
                changableTextSelectorData = await this.resetTextSelectorData();
                // if(!changableTextSelectorData){
                //   this.setState({isPlaying:false,objectCloudPlay:false,isReversePlay:false},()=>{
                //     util.setIsPlaying(false)
                //     clearInterval(timerId)
                //   })
                // }
                this.setState({textSelectorData:changableTextSelectorData}, ()=>{
                  if(this.state.objectCloudPlay) this.refresh()
                    this.timerId = setInterval(async () => {
                      if(this.state.objectCloudPlay && util.isPlaying){
                          changableTextSelectorData = await this.setUpTextSelectorData();
                          if(changableTextSelectorData){
                            this.setState({textSelectorData:changableTextSelectorData}, () => {
                              this.refresh()
                          })
                          }
                      } else{
                        this.setState({isPlaying:false,objectCloudPlay:false,isReversePlay:false},()=>{
                          util.setIsPlaying(false)
                          clearInterval(this.timerId)
                        })
                      }
                    }, this.state.animationSec*1000);
              
                })
              } else{ //if it is a named selection
                changableTextSelectorData = await this.resetNamedSelection();
                this.setState({textSelectorData:changableTextSelectorData,inFirstCycle:false}, ()=>{
                    if(this.state.objectCloudPlay)this.refresh()//only refresh if it is still playing and wasn't stopped by setupNamedSelectorData
                    this.timerId = setInterval(async () => {
                      if(this.state.objectCloudPlay && util.isPlaying){
                          changableTextSelectorData = await this.setUpNamedSelectorData();
                          if(changableTextSelectorData && this.state.objectCloudPlay){
                            this.setState({textSelectorData:changableTextSelectorData}, () => {
                              this.refresh()
                          })
                          }else{
                            this.setState({isPlaying:false,objectCloudPlay:false,isReversePlay:false},()=>{
                              util.setIsPlaying(false)
                              clearInterval(this.timerId)
                            })
                          }
                      } else{
                        this.setState({isPlaying:false,objectCloudPlay:false,isReversePlay:false},()=>{
                          util.setIsPlaying(false)
                          clearInterval(this.timerId)
                        })
                      }
                    }, this.state.animationSec*1000);
              
                })
              }
            } else if(this.state.textSelectorData.length < 2){//the user didn't enter any
                this.setState({ isPlaying:!this.state.isPlaying},async()=>{//set it back so that it'll go back to play when recursive
                  changableTextSelectorData = await this.resetTextSelectorData()
                    this.setState({objectCloudPlay:!this.state.objectCloudPlay,textSelectorData:changableTextSelectorData}, () => {
                      this.toggleObjectPlayPause()
                    })
                })
            }
      }else{
        this.setState({objectCloudPlay:false,isPlaying:false})
      }
    })
  }
  findStartingSefer(){
    var sefer = (this.state.textSelectorData[0].val1 !== 0) ? this.state.textSelectorData[0].val1 :this.state.seforimInclude[0].id;
    var seferIndex = this.checkIfSeferIndexIsValid(this.state.seforimInclude.findIndex((e) => e.id=== sefer),sefer,true);
    if(seferIndex>=0){
      sefer = this.state.seforimInclude[seferIndex].id;
    } else{
      sefer = this.state.seforimInclude[0].id
    }
    return sefer;
  }

  async resetTextSelectorData(){
    var selectorData = null;
    var seforim = (this.state.textSelectorData[0].valList1.length > 0)? this.state.textSelectorData[0].valList1 : await util.loadSeforim()
    var seforimEnd;
    var perakimEnd;
    var perek = (this.state.textSelectorData[0].val2 !== 0) ? this.state.textSelectorData[0].val2 : 1;
    var pesukimEnd;
    var sefer = this.findStartingSefer()
    var seferIndex = seforim.findIndex((e) => e.seferId=== sefer);
    var perakim = (this.state.textSelectorData[0].valList2.length > 0) ? this.state.textSelectorData[0].valList2 : await util.loadPerakim(seforim[seferIndex].seferId)//all perakim in first sefer
    var pesukim = (this.state.textSelectorData[0].valList3.length > 0) ? this.state.textSelectorData[0].valList3 : await util.loadPesukim(perakim[0].perekNum,perakim)//pesukim in the first perek in first sefer

        if(this.state.playValue===0){
          // pesukim = (this.state.textSelectorData[0].valList3.length > 0) ? this.state.textSelectorData[0].valList3 : await util.loadPesukim(perakim[0].perekNum,perakim)//pesukim in the first perek in first sefer
          pesukimEnd = await util.loadPesukim(perakim[perakim.length-1].perekNum,perakim)//pesukim in last perek in first sefer

          selectorData = [{ type: false, val1: seforim[seferIndex].seferId, val2: perakim[0].perekNum, val3: pesukim[0].pasukNum, val4: seforim[seferIndex].seferId, val5: perakim[perakim.length-1].perekNum, val6: pesukimEnd[pesukimEnd.length-1].pasukNum,
                                      valList1: seforim, valList2: perakim, valList3: pesukim, valList4: seforim, valList5: perakim, valList6: pesukimEnd}]

        }else if(this.state.playValue===1){
          var parshaPasukLocationList = this.state.parshaPasukLocationList//await util.getParshaPasukLocationList();
          var pasukId = (this.state.textSelectorData[0].val3 !== 0)? this.state.textSelectorData[0].val3 : 1;
          var parshaId =  await util.getParshaIdFromSeferPerekPasuk(sefer, perek, pasukId)
          parshaId = parshaPasukLocationList.findIndex(e=>e.parshaId == parshaId);
          console.log(parshaId)
          this.setState({/*parshaPasukLocationList,*/parshaId})

          perakim = await util.loadPerakim(parshaPasukLocationList[parshaId].seferId)
          pesukim = await util.loadPesukim(parshaPasukLocationList[parshaId].perekAliasNum1,perakim)
          pesukimEnd = await util.loadPesukim(parshaPasukLocationList[parshaId].perekAliasNum2,perakim)

          selectorData = [{ type: false, val1: parshaPasukLocationList[parshaId].seferId, val2: parshaPasukLocationList[parshaId].perekAliasNum1, val3: parshaPasukLocationList[parshaId].pasukAliasNum1, val4: parshaPasukLocationList[parshaId].seferId, val5: parshaPasukLocationList[parshaId].perekAliasNum2, val6: parshaPasukLocationList[parshaId].pasukAliasNum2,
                      valList1: seforim, valList2: perakim, valList3: pesukim, valList4: seforim, valList5: perakim, valList6: pesukimEnd}]

        }else if(this.state.playValue===2){
          // pesukim = (this.state.textSelectorData[0].valList3.length > 0) ? this.state.textSelectorData[0].valList3 : await util.loadPesukim(perakim[0].perekNum,perakim)//pesukim in the first perek in first sefer

          selectorData = [{ type: false, val1: seforim[seferIndex].seferId, val2: perek, val3: pesukim[0].pasukNum, val4: seforim[seferIndex].seferId, val5: perek, val6: pesukim[pesukim.length-1].pasukNum,
                                      valList1: seforim, valList2: perakim, valList3: pesukim, valList4: seforim, valList5: perakim, valList6: pesukim}]
        }   
        selectorData.push({ type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0,
                                        valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []})

    return selectorData;
  }
  setPlayValue(val){
    this.setState({playValue:val})
  }

  toggleRepeatPlayFromEnd(){
    this.setState({isRepeatFromEnd:!this.state.isRepeatFromEnd, isRepeatFromStart:false},()=>{
      if(!this.state.isRepeatFromEnd){
        this.setState({isReversePlay:false})
      }
    })
  }
  toggleRepeatPlayFromStart(){
    this.setState({isRepeatFromStart:!this.state.isRepeatFromStart, isRepeatFromEnd:false, isReversePlay:false})
  }
  toggleShowLoadingOverlay(){
    this.setState({showLoadingOverlay:!this.state.showLoadingOverlay})
  }
  toggleShowTitle(){
    this.setState({showTitle:!this.state.showTitle})
  }
  changeBackgroundColor(color){
    this.setState({backgroundColor:color.rgb})
  }
  changeTitleColor(color){
    this.setState({titleColor:color.rgb})
  }
  cancelTitleColorChange(){
    this.setState({titleColor:this.state.lastTitleColor,displayTitleColorPicker:false})
  }
  cancelBackgroundColorChange(){
    this.setState({backgroundColor:this.state.lastBackgroundColor,displayColorPicker:false})
  }
  setUpTitle(){
    var title=null;
    if(this.state.textSelectorData){
      var renderVals=this.getRenderVals(this.state.textSelectorData[0]);
      if(this.state.textSelectorData[0].type && (renderVals[3]) ){
        title = `${renderVals[3] || ''}`
        title = title.split(' (');
        title = title[0]
      } else if(!this.state.textSelectorData[0].type){
        if(this.state.playValue === 0){
            title = `${renderVals[0] || ''}`
        } else if(this.state.playValue === 1){
            title = `${renderVals[0] || ''} ${renderVals[1] || ''}:${renderVals[2] || ''} עד ${renderVals[3] || ''} ${renderVals[4] || ''}:${renderVals[5] || ''}`
        } else if(this.state.playValue === 2){
            title = `${renderVals[0] || ''} ${renderVals[1] || ''}`
        }
      } else title = ""
    } else title = ""
    this.setState({title})
  }
  clickOut(){
      if(this.state.displayColorPicker===true){
        this.setState({displayColorPicker:false})
      }
      if(this.state.displayTitleColorPicker===true){
        this.setState({displayTitleColorPicker:false})
      }
  }

  async componentDidMount(){
    var parshaPasukLocationList = await util.getParshaPasukLocationList()
    this.setState({parshaPasukLocationList})
  }
  async componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.isOpen && !prevProps.isOpen){
      this.showOnTop();

      if (this.props.isFirstTimeOpened && this.props.initialFullScreen && "TY".indexOf(this.props.initialFullScreen.toUpperCase()) >= 0) {
          this.handleFullscreen();
          this.props.extSetFirstTimeOpened();
      }
    }
    if(this.state.rowDataLength !== prevState.rowDataLength){
      this.state.textSelectorApi.gridOptionsWrapper.gridOptions.api.ensureIndexVisible(this.state.rowDataLength,'bottom')
    }
    if(this.props.isOpen !== prevProps.isOpen && !this.props.isOpen){
      this.clearAll()
    }
    if(this.props.objectCloudParshaId !== prevProps.objectCloudParshaId && this.state.parshaPasukLocationList){
      var parsha = this.state.parshaPasukLocationList[this.props.objectCloudParshaId-1]
      if(parsha){
        var list1 = await util.loadSeforim();
        var list2 = await util.loadPerakim(parsha.seferId);
        var list3 = await util.loadPesukim(parsha.perekId1, list2)
        var list6 = await util.loadPesukim(parsha.perekId2, list2)
        this.setState({textSelectorData:[{ type: false, val1: parsha.seferId, val2: parsha.perekId1, val3: parsha.pasukAliasNum1, val4: parsha.seferId, val5: parsha.perekId2, val6: parsha.pasukAliasNum2,
          valList1: list1, valList2: list2, valList3: list3, valList4: list1, valList5: list2, valList6: list6 },{ type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []}]})
        this.refresh()
      }
    }
    if(this.props.extFilterData !== prevProps.extFilterData){
      this.seforimToInclude(this.props.extFilterData);    
    }
  }
  changeSec(val){
    if(val.target.value != this.state.animationSec){
      if(val.target.value>10) val.target.value = 10
      else if(val.target.value<1) val.target.value = 1
      if(this.state.objectCloudPlay){
        clearInterval(this.timerId)
        this.toggleObjectPlayPause()
        this.setState({animationSec:val.target.value},()=>{this.toggleObjectPlayPause()})
      }else{
        this.setState({animationSec:val.target.value})
      }
    }
  }


  render() {
    const { classes } = this.props;
    const fakeThis = this;
    const {totalTerms1, termTotal1Accurate} = this.state.wordCountData;
    const {isLoading} = this.state;
    const isHebrew = this.props.activeLanguage && this.props.activeLanguage.code === 'he';

     const close = this.props.translate('close');
     const copyLink = this.props.translate('copyLink');
     const objectClouds = this.props.translate('objectClouds');
     const loadingText = this.props.translate("loading");
     const fullScreen = this.props.translate("fullScreen");
     const splitScreen = this.props.translate("splitScreen");
     const select = this.props.translate("select");
     const clearAll = this.props.translate("clearAll");
     const otherWords = this.props.translate("otherWords");
     const searchSettings = this.props.translate("searchSettings");
     const maxObjects = this.props.translate("maxObjects");
     const minFrequency = this.props.translate("minFrequency");
     const wordsToSkip = this.props.translate("wordsToSkip");
     const showLoading = this.props.translate("showLoading");
     const showTitle = this.props.translate("showTitle");
     const timeDelay = this.props.translate("timeDelay");
     const backgroundColor = this.props.translate("backgroundColor");
     const titleColor = this.props.translate("titleColor");


    const popupHeightNum = this.props.heightNum
    var dialogContentHeight = popupHeightNum - 150;

     var directionStyling = {direction: 'ltr', textAlign: 'left'}
     if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
         directionStyling = {direction: 'rtl', textAlign: 'right'}
     }

     var searchSettingsLabelCellStyle = {
      textAlign: directionStyling.textAlign
    }
    var searchSettingsInputCellStyle = {
      textAlign: isHebrew ? "left" : "right"
    }

     const wordsToSkipVal = this.state.wordsToSkip;
     var noWordsToSkip = wordsToSkipVal.length === 0 || (wordsToSkipVal.length === 1 && wordsToSkipVal[0] === "");
     var wordsToSkipMenuItems = [
      { id: 'אל', value: 'אל' },
      { id: 'אשר', value: 'אשר' },
      { id: 'את', value: 'את' },
      { id: 'גם', value: 'גם' },
      { id: 'כי', value: 'כי' },
      { id: 'כל', value: 'כל' },
      { id: 'לא', value: 'לא' },
      { id: 'על', value: 'על' },
    ];

    const marks = {
            0: "ספרים",
            1: "פרשיות",
            2: "פרקים",
      };

      const repeatFromStartTip = this.props.translate('repeatFromStartTip');
      const repeatFromEndTip = this.props.translate('repeatFromEndTip');
      const repetitionOffTip = this.props.translate('repetitionOffTip');

      var hidePlayButton=(this.state.textSelectorData.length >= 2 && ((this.state.textSelectorData[1].val1!==0) || (this.state.textSelectorData[1].val2!==0) ||
                                                                        (this.state.textSelectorData[1].val3!==0) || (this.state.textSelectorData[1].val4!==0)));
      var hidePlaySlider=(hidePlayButton || (this.state.textSelectorData[0] && this.state.textSelectorData[0].type && this.state.textSelectorData.length ===2) || this.state.textSelectorData.length>2)
      const playTip = this.props.translate('playTip');
      const pauseTip = this.props.translate('pauseTip');
  
      return (
        <Rnd
          helpTopic="objectCloudViewer"
          size={{ width: `${this.props.widthNum}px`,  height: `${popupHeightNum}px` }}
          position={{ x: this.props.x, y: this.props.y }}
          onDragStart={(event)=>{
            if(event.target.className!=='colorSwatch' || this.state.displayColorPicker || this.state.displayTitleColorPicker){
              event.stopPropagation()
              return false
            }
          }}
          disableDragging={this.state.displayTitleColorPicker===true || this.state.displayColorPicker===true}
          onDragStop={(e, d) => { 
            // if(!(this.state.displayColorPicker || this.state.displayTitleColorPicker)){
              this.props.setPosition(d.x, d.y)
            // }
            // this.setState({ x: d.x, y: d.y }) 
          }}
          onClick={(e) => {this.handleClick(e)}}
          onResize={(e, direction, ref, delta, position) => {
            this.props.setSize(
              parseInt(ref.style.width.slice(0, -1)),
              parseInt(ref.style.height.slice(0, -1)),
              position.x,
              position.y
            )
          }}
          style = {{
            backgroundColor: 'rgb(255, 238, 223)',
            visibility: this.props.isOpen ? 'visible' : 'hidden',
            boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
            borderRadius: '5px',
            zIndex: this.state.zIndex
          }}
          cancel = '.LCS-open-settings-button, .MuiDialog-container-16, .object-cloud-selector, .tanach-viewer-content, .tanach-viewer-text-display-controls, .placement-selector-2D-selector, .placement-selector-2D-popover, .tanach-viewer-resize-buttons'
          minWidth={740}
          minHeight={153}
          bounds='#root'
          className="tanach-viewer-rnd"
        >
          <div className="tanach-viewer-resize-buttons"
            style={{
              position: 'absolute',
              right: isHebrew ? 'unset' : 0,
              left: isHebrew ? 0 : 'unset',
              direction: isHebrew ? 'ltr' : 'rtl',
              top: 0,
              borderBottomLeftRadius: isHebrew ? 0 : '3px',
              borderBottomRightRadius: isHebrew ? '3px' : 0,
            }}
          >
            <Button className="tanach-viewer-resize-button" onClick={() => {this.handleClose()}} title={close}>
              <CloseIcon />
            </Button>
            <Button className="tanach-viewer-resize-button" onClick={() => {this.handleCopyLink()}} title={copyLink}>
              <LinkIcon />
            </Button>
            <Button className="tanach-viewer-resize-button" onClick={() => {this.handleSplitScreen()}}  title={splitScreen}>
              <HorizontalSplitIcon />
            </Button>
            <Button className="tanach-viewer-resize-button" onClick={() => {this.handleFullscreen()}} title={fullScreen}>
              <FullscreenIcon />
            </Button>
          </div>

            <DialogTitle style={{
              direction: directionStyling.direction, textAlign: "center",
              maxWidth: 'calc(100% - 30px)',
              margin: 'auto',
              padding: '10px 0',
            }}>
              <span className="medCaption" align="center">{objectClouds}</span>

              <Button disabled={this.state.isPlaying} variant="default" style={{ verticalAlign: '-8px'}} className="LCS-open-settings-button" onClick={(e) => {this.handleOpenSearchSettings(e)}}>
                <SettingsIcon/>&nbsp;{searchSettings}
              </Button>&nbsp;

              <Popover
                open={this.state.searchSettingsIsOpen}
                anchorEl={this.state.searchSettingsAnchorEl}
                onClose={this.handleCloseSearchSettings}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: `${isHebrew ? "right" : "left"}`,
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: `${isHebrew ? "right" : "left"}`,
                }}
                elevation={1}
                className="placement-selector-2D-popover"
                style={{
                  direction: `${isHebrew ? 'rtl' : 'ltr'}`,
                }}
                classes={{ paper: "LCS-search-settings-popover-paper" }}
              >

                <table className="LCS-search-setting-parent-table">
                  <tr>
                    <td style={searchSettingsLabelCellStyle}>
                      <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{maxObjects}:</Typography>
                    </td>
                    <td style={searchSettingsInputCellStyle}>
                      <Typography variant='body1' className='LCS-distance-label'>{1}</Typography>
                      <Slider reverse={isHebrew} className='LCS-distance-slider' min={1} max={100} step={1} included={false} tipFormatter={value => this.getDisplayValue(value)} value={this.state.maxObjects} onChange={this.setMaxObjects}
                        style={{
                          marginLeft: isHebrew ? '-4px' : '16px',
                          marginRight: isHebrew ? '12px' : '-10px',
                        }}
                      />
                      <Typography variant='body1' className='LCS-distance-label'>{this.state.maxObjectsDisplay}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td style={searchSettingsLabelCellStyle}>
                      <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{minFrequency}:</Typography>
                    </td>
                    <td style={searchSettingsInputCellStyle}>
                      <Typography variant='body1' className='LCS-distance-label'>{this.state.minFrequencyDisplay}</Typography>
                      <Slider reverse={isHebrew} className='LCS-distance-slider' min={1} max={100} step={1} included={false} tipFormatter={value => this.getDisplayValue(value)} value={this.state.minFrequency} onChange={this.setMinFrequency}
                        style={{
                          marginLeft: isHebrew ? '-4px' : '16px',
                          marginRight: isHebrew ? '12px' : '-10px',
                        }}
                      />
                      <Typography variant='body1' className='LCS-distance-label'>{100}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td style={searchSettingsLabelCellStyle}>
                      <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{wordsToSkip}:</Typography>
                    </td>
                    <td style={searchSettingsInputCellStyle}>
                      
                      <span style={{direction: directionStyling.direction}}>
                        <Button className="placement-selector-2D-button" onClick={(e) => {this.handleOpenWordsToSkip(e)}}>
                          <Typography
                            variant="h6"
                            className="placement-selector-2D-label"
                            style={{
                              color: `${this.props.disabled ? "#8a999e" : "black"}`,
                              textAlign: directionStyling.textAlign,
                              fontWeight: `${false ? "bold" : "400"}`
                            }}
                          >
                            {noWordsToSkip ? select : wordsToSkipVal.join(" ")}
                          </Typography >
                          <ArrowDropDownIcon className="placement-selector-2D-icon" style={{color: '#757575'}}/>
                        </Button>

                          <Popover
                            open={this.state.wordsToSkipIsOpen}
                            anchorEl={this.state.wordsToSkipAnchorEl}
                            onClose={this.handleCloseWordsToSkip}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: `${isHebrew ? "right" : "left"}`,
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: `${isHebrew ? "right" : "left"}`,
                            }}
                            elevation={1}
                            className="placement-selector-2D-popover"
                            style={{
                              direction: `${isHebrew ? 'rtl' : 'ltr'}`
                            }}
                            classes={{ paper: "LCS-search-settings-popover-paper" }}
                          >
                            <MenuItem className="LCS-select-menu-item" value="clearAll" onClick={(e) => {fakeThis.handleSelectWordToSkip(e)}}
                              style={{
                                direction: `${isHebrew ? 'rtl' : 'ltr'}`
                              }}
                            >
                              {clearAll}
                            </MenuItem>
                            <div className="placement-selector-2D-columns-parent-rtl">
                                {wordsToSkipMenuItems.map((menuItem, key) => {
                                  var menuItemsArr = [];
                                  for(let i = 0; i < 1; i++){
                                    if(wordsToSkipMenuItems[key+i]){
                                      menuItemsArr.push(
                                        <MenuItem className="placement-selector-2D-menuItem-rtl" value={wordsToSkipMenuItems[key+i].id} onClick={(e) => {fakeThis.handleSelectWordToSkip(e)}}
                                          style={{
                                            direction: `${this.props.isTextComparator && localStorage.getItem('language') === "en" ? "ltr" : "rtl" }`,
                                            backgroundColor: `${wordsToSkipVal.includes(wordsToSkipMenuItems[key+i].id) ? "lightgrey" : "" }`,
                                          }}
                                        >{wordsToSkipMenuItems[key+i].value}</MenuItem>
                                      )
                                    }
                                  }
                                  if(key%1 === 0){
                                    return <div>
                                      {menuItemsArr}
                                    </div>;
                                  }
                                  
                                })}
                            </div>
                            <Typography variant='body1' className='LCS-other-words-label'
                              style={{
                                direction: `${isHebrew ? 'rtl' : 'ltr'}`,
                                textAlign: `${isHebrew ? 'right' : 'left'}`
                              }}
                            >
                              {otherWords}:
                            </Typography>
                            <TextField
                              id="outlined-size-small"
                              variant="outlined"
                              size="small"
                              value={this.state.otherWordsToSkip.join(" ")}
                              onChange={(e) => {this.setOtherWordsToSkip(e)}}
                              InputProps={{ classes: { input: 'LCS-words-to-skip-textfield' } }}
                            />
                          </Popover>
                        </span>
                    </td>
                  </tr>
                </table>
              </Popover>

              
              <Button disabled={this.state.isPlaying} variant="contained" color="primary" className="parsha-viewer-control-button" type="submit" style={{margin: '0 5px'}} onClick={this.refresh}><Translate id="refresh" /></Button>
              {/* <IconButton className="" onClick={this.play}><PlayCircleOutline/></IconButton> */}
              {/* {hidePlayButton?<span></span>:<span><ToggleIconButton isPressed={this.state.objectCloudPlay} onImage={pause} offImage={play} onTip={"Pause"} offTip={"Play"}
                                             height={32} width={32} toggleAction={this.toggleObjectPlayPause} disabled={false} />
                                  &nbsp;&nbsp;
                                <ToggleIconButton isPressed={this.state.isRepeatFromStart} onImage={repeatFromStartOn} offImage={repeatFromStartOff} onTip={repetitionOffTip} offTip={repeatFromStartTip}
                                                  height={32} width={32} toggleAction={this.toggleRepeatPlayFromStart} disabled={false} />
                                &nbsp;<ToggleIconButton isPressed={this.state.isRepeatFromEnd} onImage={repeatFromEndOn} offImage={repeatFromEndOff} onTip={repetitionOffTip} offTip={repeatFromEndTip}
                                                  height={32} width={32} toggleAction={this.toggleRepeatPlayFromEnd} disabled={false} />
                                &nbsp;&nbsp;
                </span>}
               {hidePlaySlider?<div></div>:<Slider disabled={this.state.isPlaying} reverse={true} style={{zIndex:this.state.zIndex+1}} className={"objectCloudChoicesSliderDiv "} dots step={1} min={0}
                      marks={marks} max={2} handleStyle={{borderColor: '#b87333'}} value={this.state.playValue} onChange={this.setPlayValue}
                      ></Slider>} */}
                <ObjectCloudSectionSelector 
                  isPlaying={this.state.isPlaying}
                  isLoadingResults={isLoading && this.state.showLoadingOverlay}
                  totalTerms1={totalTerms1}
                  termTotal1Accurate={termTotal1Accurate}
                  textSelectorData={this.state.textSelectorData}
                  textSelectorGridReady={this.textSelectorGridReady}
                  addTextSelectorRow={this.addTextSelectorRow}
                  extAddTextSelectorRow={this.extAddTextSelectorRow}
                  deleteTextSelectorRow={this.deleteTextSelectorRow}
                  extDeleteTextSelectorRow={this.extDeleteTextSelectorRow}
                  extModifyTextSelectorRow={this.extModifyTextSelectorRow}
                  modifyTextSelectorRow={this.modifyTextSelectorRow}
                  getRenderVals={this.getRenderVals}
                  // isFilingSelector={this.props.isFillingSelector}
                />
            </DialogTitle>
              <DialogContent className="tanach-viewer-content" style={{
                direction: 'rtl',
                maxHeight: `${dialogContentHeight}px`,
                minHeight: `${dialogContentHeight}px`,
                padding: '0 0 0px',
                margin: '0 24px',
              }}>
                <DialogContentText style={{direction: 'rtl', textAlign: 'right'}}>
                  <div id="tanach-viewer-text-display" styles={{maxHeight:`${dialogContentHeight-70}px`}}>
            <LoadingOverlay
                  // styles={{
                  //   wrapper: {
                  //     height: `${dialogContentHeight-70}px`,
                  //   }
                  // }}            
              active={this.state.isLoading && this.state.showLoadingOverlay}
              spinner
              text={loadingText}
            > 
            <div style={{backgroundColor:`rgba(${ this.state.backgroundColor.r }, ${ this.state.backgroundColor.g }, ${ this.state.backgroundColor.b }, ${ this.state.backgroundColor.a }`,width:`${this.props.widthNum-50}`}}>
              {this.state.showTitle && !hidePlayButton ?<div className="wordCloudTitle" style={{minHeight:`80px`,fontSize:`${this.state.title.length>25?'50px':'72px'}`, color:`rgba(${ this.state.titleColor.r }, ${ this.state.titleColor.g }, ${ this.state.titleColor.b }, ${ this.state.titleColor.a }`}}>{this.state.title}</div>:<div></div>}
                      <ObjectCloud
                        data = {this.state.cloudData}
                        height={this.state.showTitle?dialogContentHeight-150:dialogContentHeight-70}
                        width={this.props.widthNum - 50}
                        backgroundColor={this.state.backgroundColor}
                      />
            </div>
            </LoadingOverlay>
                
              {/* </DialogContent> */}
                <div  style={{marginTop:'5px',textAlign:"center", direction:'rtl'}}>{hidePlayButton?<span></span>:<span ><ToggleIconButton isPressed={this.state.objectCloudPlay} onImage={pause} offImage={play} onTip={pauseTip} offTip={playTip}
                                             height={32} width={32} toggleAction={this.toggleObjectPlayPause} disabled={false} />
                                  &nbsp;&nbsp;
                                <ToggleIconButton isPressed={this.state.isRepeatFromStart} onImage={repeatFromStartOn} offImage={repeatFromStartOff} onTip={repetitionOffTip} offTip={repeatFromStartTip}
                                                  height={32} width={32} toggleAction={this.toggleRepeatPlayFromStart} disabled={false} />
                                &nbsp;<ToggleIconButton isPressed={this.state.isRepeatFromEnd} onImage={repeatFromEndOn} offImage={repeatFromEndOff} onTip={repetitionOffTip} offTip={repeatFromEndTip}
                                                  height={32} width={32} toggleAction={this.toggleRepeatPlayFromEnd} disabled={false} />
                                &nbsp;&nbsp;<span className="animationSec"><label for='animationSec'>{timeDelay}</label>
                                <input id="animationSec" value={(parseFloat(this.state.animationSec)).toFixed(1)} onInput={this.changeSec} onBlur={val=>this.changeSec(val)}style={{maxWidth:'57px',direction:'ltr'}} step=".1" min="1" max="10" type="number"></input></span>
                </span>}
                {hidePlaySlider?<span></span>:<Slider disabled={this.state.isPlaying} reverse={true} style={{zIndex:this.state.zIndex}} className={"objectCloudChoicesSliderDiv "} dots step={1} min={0}
                      marks={marks} max={2} handleStyle={{borderColor: '#b87333'}} value={this.state.playValue} onChange={this.setPlayValue}
                      ></Slider>}&nbsp;&nbsp;
                      {hidePlayButton?<span></span>:<div className="tooltipChromePicker">
                        <Tooltip style={{zIndex:'810'}} visible={this.state.displayColorPicker}
                            trigger={['click']} onVisibleChange={()=>{this.setState({displayColorPicker:!this.state.displayColorPicker})}}
                            overlay={<div><button className="setColor" onClick={()=>this.setState({displayColorPicker:false})}>X</button><ChromePicker className="chromePicker" style={{ zIndex:this.state.zIndex,display:'inlineBlock'}} width="100px" color={this.state.backgroundColor} onChange={(color)=>this.changeBackgroundColor(color)}/></div>}>
                              <div onClick={()=>this.setState({displayColorPicker:!this.state.displayColorPicker,displayTitleColorPicker:false,lastBackgroundColor:this.state.backgroundColor})} 
                                  className="colorSwatch" style={{ borderColor:`rgba(${ this.state.backgroundColor.r }, ${ this.state.backgroundColor.g }, ${ this.state.backgroundColor.b }, ${ this.state.backgroundColor.a }`}}>{backgroundColor.split(" ")[0]}<br/>{backgroundColor.split(" ")[1]}
                              </div>
                        </Tooltip>
                        &nbsp;&nbsp;
                        <Tooltip style={{zIndex:'810'}} visible={this.state.displayTitleColorPicker}
                            trigger={['click']} onVisibleChange={()=>{this.setState({displayTitleColorPicker:!this.state.displayTitleColorPicker})}}
                            overlay={<div><button className="setColor" onClick={()=>this.setState({displayTitleColorPicker:false})}>X</button><ChromePicker className="chromePicker" style={{ zIndex:this.state.zIndex,display:'inlineBlock'}} width="100px" color={this.state.titleColor} onChange={(color)=>this.changeTitleColor(color)}/></div>}>
                              <div onClick={()=>this.setState({displayTitleColorPicker:!this.state.displayTitleColorPicker,displayColorPicker:false, lastTitleColor:this.state.titleColor})}
                                  className="colorSwatch" style={{ borderColor:`rgba(${ this.state.titleColor.r }, ${ this.state.titleColor.g }, ${ this.state.titleColor.b }, ${ this.state.titleColor.a }`}}>{titleColor.split(" ")[0]}<br/>{titleColor.split(" ")[1]}
                              </div>
                        </Tooltip></div>}
                      &nbsp;&nbsp;{hidePlayButton?<span></span>:  <span><label for="loading" >{showLoading}</label>&nbsp;<input type="checkbox" id="loading" name="loading" onChange={this.toggleShowLoadingOverlay} checked={this.state.showLoadingOverlay}/>
                                    </span>}
                      {hidePlayButton?<span></span>:  <span>&nbsp;&nbsp;<label for="title" >{showTitle}</label>&nbsp;<input type="checkbox" id="title" name="title" onChange={this.toggleShowTitle} checked={this.state.showTitle}/>
                                    </span>}
                </div>
                                  </div>
</DialogContentText></DialogContent>

          </Rnd>

    );
  }
}

ObjectCloudViewer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withLocalize(ObjectCloudViewer));