import React, { Fragment, Component, useState } from "react";
//import * as d3 from "d3";
import * as properties from './config/properties.js';
import ForceGraph3D from "3d-force-graph";
import * as THREE from "three";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import { renderToStaticMarkup } from "react-dom/server";
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import SpriteText from 'three-spritetext';
import * as d3 from "d3-force-3d";
import * as util from './newUtil.js';
import {Button} from "reactstrap";
import Legend from "./Legend";


const styles = theme => ({
  toggleContainer: {
    //backgroundColor: 'transparent', //THIS MESSES UP THE 3D FORCE GRAPH BGCOLOR SO COMMENTED OUT - DOESN'T LOOK LIKE THIS SECTION IS USED ANYWAY???
    //padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    alignItems: 'center',
    margin: `auto`,
    background: theme.palette.background.default,
  }
});


const graph = ForceGraph3D();

var quaternion;

var highlightableNodeIds = []; //initialized to empty.  will be set below in two places in the code
//var highlightableNodeIds = new Set(); //initialized to empty.  will be set below in two places in the code
var pathPlayingTimer;
var generationsPlayingTimer;
var animationGeneration = -1;

const personNodeValH = 750;
const personNodeVal = 150;
const familyNodeValH = 100;
const familyNodeVal = 20;

const manNodeColor = "#4242f5";
const womanNodeColor = "#f54287";
const familyNodeColor = "#48f053";

const timelineLgTextHeight = 100;
const timelineSmTextHeight = 50;
const personTextHeightH = 50;

const timelineLinkWidth = 20;
const personLinkWidthH = 15;
const personLinkWidth = 2;

const arrowLengthH = 48;
const arrowLength = 12;

const linkDirectionalParticles = 2;
const particleColorH = 'darkorange';
const particleColor = '#FFFF00';
const particleWidthH = 20;
const particleWidth = 4.5;
const particleSpeedH = 0.025;
const particleSpeed = 0.009;

const darkTheme = {
    name: "dark",
    backgroundColor: "#000000", //the default for 3d force graph
    nodeOpacity: 0.7,
    manNodeColorH: "#9090f5",
    womanNodeColorH: "#f590e5",
    familyNodeColorH: "#a6f0b1",
    headerColor: "#CCCCCC",
    spriteColor: "white",
    personTextHeight: 20,
    planeColor: "#edfaff",
    planeOpacity: 0.1,
    linkOpacity: 0.2, //the default
    linkColorH: "white",
    linkColor: "gray",
    linkDirectionalArrowColor: "#C0C0C0",
    legendBackgroundColor: "#222",
    legendTextColor: "#ccc",
    legendManColor: "#6872ff",
    legendWomanColor: womanNodeColor,
    legendFamilyColor: familyNodeColor,
    legendTimelineColor: "white",
    hoverBackgroundColor: "#222222"
}

const lightTheme = {
    name: "light",
    backgroundColor: "white", //c4dce5 = sky daylight blue
    nodeOpacity: 0.5,
    manNodeColorH: "#0000c0",
    womanNodeColorH: "#c00000",
    familyNodeColorH: "#00c000",
    headerColor: "#333333",
    spriteColor: "black",
    personTextHeight: 25, //black in back of translucent color needs a little more emphasis compared to dark mode
    planeColor: "#120500",
    planeOpacity: 0.20,
    linkOpacity: 0.5,
    linkColorH: "black",
    linkColor: "darkgray",
    linkDirectionalArrowColor: "#404040",
    legendBackgroundColor: "#DDD",
    legendTextColor: "#333",
    legendManColor: manNodeColor,
    legendWomanColor: womanNodeColor,
    legendFamilyColor: "#18c023",
    legendTimelineColor: "black",
    hoverBackgroundColor: "#DDDDDD"
}

const initialCameraPosition = {x:0, y:5700, z:-2000};
const initialLookAtPosition = {x:0, y:5700, z:0};

class YuchsinTree extends Component {
  constructor(props) {
    super(props);

      var isDarkByDefault = this.props.initialDarkMode == null || "FN".indexOf(this.props.initialDarkMode.toUpperCase()) < 0;
      this.setState({isDarkMode: false});
      this.state = {
          showSettings: true,
          isDarkMode: isDarkByDefault,
          colorTheme: isDarkByDefault ? darkTheme : lightTheme,
          showHeader: true,
          showPlanes: true,
          timelinePlanes: this.initializePlanes(isDarkByDefault ? darkTheme : lightTheme),
          showArrows: true,
          showParticles: false,
          isViewerOpenedYet: false
      };


    this.handleInputChange = this.handleInputChange.bind(this);
    this.refreshGraph = this.refreshGraph.bind(this);
    this.setGraphSize = this.setGraphSize.bind(this);
    this.zoomToNode = this.zoomToNode.bind(this);
    this.setCameraPosition = this.setCameraPosition.bind(this);
    this.initializePlanes = this.initializePlanes.bind(this);
    this.createTimelineYearPlane = this.createTimelineYearPlane.bind(this);
    this.reHighlightGraph = this.reHighlightGraph.bind(this);
  }

  initializePlanes(theme) {
      var planes = new Set();
      planes.add(this.createTimelineYearPlane(theme, 1000));
      planes.add(this.createTimelineYearPlane(theme, 2000));
      planes.add(this.createTimelineYearPlane(theme, 3000));
      return planes;
  }

    // this will handle the checkbox controls.
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (name === "isDarkMode") this.toggleColorTheme(value);
        if (name === "showHeader") this.toggleHeader(value);
        if (name === "showPlanes") this.togglePlanes(value);
        if (name === "showArrows") this.setDirectionalArrowLength();
        if (name === "showParticles") this.setParticles();

    }

    handleNodeHover(node, prevNode){

      if(node){
            var divClass = "yuchsin-tree-hover-label-" + this.state.colorTheme.name;

            if (util.left(node.type,8) == "timeline") {
                node.name = `<div class=${divClass}` + `שנים מבריאת העולם` + `: <b>` + node.tanachYear + `</b></div>`;
                return;
            }

            if(!node.labelData){
              node.labelData = {
                name: (node.gender == "FAMILY") ? node.name : node.fullName,
                aliases: node.aliases,
                year: node.tanachYear,
                pasukLocation: node.firstPasukLocation,
                pasukText: node.firstPasukText,
              }
            }

            var captionClass = "yuchsin-tree-hover-" + node.gender + "-" + this.state.colorTheme.name;
            var pasukClass = "yuchsin-tree-pasuk-text-" + this.state.colorTheme.name;

            const fullName = "שם מלא"; //this.props.translate('fullName');
            const aka = "גם ידוע כ"; //this.props.translate('aka');
            const timePeriod = "שנת תקופה המשוערת"; //this.props.translate('timePeriod');
            const firstAppearance = "הופעה ראשונה"; //this.props.translate('firstAppearance');

            //const isHebrew = this.props.activeLanguage && this.props.activeLanguage.code === "he";
//            var directionStyling = { direction: 'ltr !important', textAlign: 'left !important' }
//            if (isHebrew) {
//              directionStyling = { direction: 'rtl !important', textAlign: 'right !important' }
//            }

        //Ari- tried to use setTimeout here to set a delay on hover label
        // node.name = "<div></div>";
        // setTimeout(() => {
            //var name = `<div class='yuchsin-tree-hover-label' style=${directionStyling}>`;
            var name = `<div class=${divClass}>`;
            name += `<span class=${captionClass}>${fullName + ": "}</span>`;
            name += `<span><b>${node.labelData.name}</b></span>`;
            if (node.aliases != null && node.aliases != "") {
                name += `<br /><span class=${captionClass}>${aka + ": "}</span>`;
                name += `<span>${node.labelData.aliases}</span>`;
            }
            if (node.gender != "FAMILY") {
                name += `<br /><span class=${captionClass}>${timePeriod + ": "}</span>`;
                name += `<span>${node.labelData.year}</span>`;
                name += `<br /><span class=${captionClass}>${firstAppearance + ": "}</span>`;
                name += `<span><b>${node.labelData.pasukLocation}</b></span><br />`;
                name += `<span class=${pasukClass}>${'"' + node.labelData.pasukText + '"'}</span>`;
            }
            name += `</div>`;

            node.name = name;
        // }, 500);
        //mje - also need to use a clearTimeout(timer) upon mouseout in conjunction with the otiginal setTimeout()
      }
    }

    refreshGraph(cameraPosition, lookAtPosition, isPreserveHighlighting)
     {
         this.graph = ForceGraph3D()(this.chartRef)
             //      .width(this.chartRef.clientWidth - 100)
             //      .height(window.innerHeight - 200)
             .backgroundColor(this.state.colorTheme.backgroundColor)
             //.nodeVal('level')
             .showNavInfo(false)
             .cameraPosition(cameraPosition ? cameraPosition : initialCameraPosition, lookAtPosition ? lookAtPosition : initialLookAtPosition) //initial view gets 0 second transition and 10px padding
             .cooldownTime(this.props.focusedNode != null && this.props.comparisonNode == null && !this.state.isViewerOpenedYet ? 7500 : 15000) //speed up display time (at the expense of total 3D diagram quality) if loading 3D tree to merely focus on an initial person
             .onEngineStop(() => {
                 if (!this.state.isViewerOpenedYet) { //this is for handling when 3D viewer is pre-loaded with focused node based on URL
                     if (this.props.focusedNode != null)
                         this.setCameraAtNode(this.props.focusedNode, this.props.zoomDistance ? this.props.zoomDistance : 300); //further away than the standard distance of 200 since the user will be seeing the display for the 1st time and will need to get acclimated
                     this.setState({isViewerOpenedYet: true});
                 }
             })
             //@@@@@.nodeVal(node => (node.type == 'person') ? 150 : (node.type == 'family') ? 20 : 0)
             //.nodeVisibility((node) => { return util.left(node.type,8) == "timeline" ? false : true; })
             // .linkVisibility((link) => { return link.type == "timeline" ? false : true; })
             .nodeVisibility((node) => {return true;})
             .linkVisibility((link) => {return true;})
             //.nodeVal((node) => 500 - (node.level * 20))
             //.dagMode('td')
             .dagLevelDistance(100)
             .nodeRelSize(5)
             .nodeOpacity(this.state.colorTheme.nodeOpacity)
             //.nodeAutoColorBy("gender")
             //.linkAutoColorBy("relation")
             //@@@@@.nodeColor(node => (node.gender == 'MAN') ? "#4242f5" : (node.gender == 'WOMAN') ? "#f54287" : (node.type == 'family') ? "#48f053" : "#000000")
             .nodeResolution(20)
             .d3Force('collision', d3.forceCollide(50)) //replace with maxDepth - node.level
             //.d3Force("link").distance((link) => {return link.timelineFlag == "true" ? 500 : 200}).strength(1)
             .d3VelocityDecay(0.3)
/*@@@@@
             .nodeThreeObject(node => {
                 //var text = (node.type == 'family') ? "" : node.name;
                 const sprite = new SpriteText(node.displayCaption);
                 sprite.color = "white";
                 sprite.fontFace = (node.type == 'timeline-lg') ? "Arial" : (node.type == 'timeline-sm') ? "Arial" : "SBL Hebrew"; //Could have used TimesNewRoman but that's not available on Android and would have had to supply a downloadable .woff font for that
                 sprite.fontWeight = "bold";
                 sprite.textHeight = (node.type == 'timeline-lg') ? 100 : (node.type == 'timeline-sm') ? 50 : 20; //replace with node.level
                 return sprite;
             })
*/
             .nodeThreeObjectExtend(true)
             //.linkOpacity(link => (link.type == 'timeline') ? 0.9 : 0.2)
             .linkOpacity(this.state.colorTheme.linkOpacity)
             //@@@@@.linkWidth(link => (link.type == 'timeline') ? 20 : 2)
             //       .onNodeClick(node => {
             //         // Aim at node from outside it
             //         const distance = 40;
             //         const distRatio = 1 + distance/Math.hypot(node.x, node.y, node.z);
             //
             //         Graph.cameraPosition(
             //           { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
             //           node, // lookAt ({ x, y, z })
             //           3000  // ms transition duration
             //         );
             //       });
             //@@@@@.linkDirectionalArrowLength(link => (link.type == 'timeline') ? 0 : 12)
              //.linkDirectionalArrowRelPos(1.1)
             .linkDirectionalArrowColor(() => this.state.colorTheme.linkDirectionalArrowColor)
             .linkDirectionalArrowResolution(20)
             //.linkDirectionalParticles(link => (link.type == 'timeline') ? 0 : 2)
             //@@@@@.linkDirectionalParticles(0) //can toggle, but initialize to off
             //@@@@@.linkDirectionalParticleColor(() => '#FFFF00')
             //@@@@@.linkDirectionalParticleWidth(4.5)
             .linkDirectionalParticleResolution(20)
             //.linkDirectionalParticleSpeed(0.009)
             .onNodeHover((node, prevNode) => this.handleNodeHover(node, prevNode))
             //.onNodeDrag(node => {return util.left(node.type, 8) != "timeline";}) //tried this but didn't prevent node from moving
             .graphData(this.props.data);
//      .onNodeClick(node => {
//          if (node.childLinks.length) {
//              node.collapsed = !node.collapsed; // toggle collapse state
//              this.graph.graphData(getPrunedTree());
//          }
//      });
         //.onNodeHover(node => elem.style.cursor = node && node.childLinks.length ? 'pointer' : null);
         this.graph.d3AlphaDecay(0.02); //default = 0.0228
         // Decrease repel intensity
         //this.graph.d3Force('charge').strength((node) => {return node.tanachYear <= 1000 ? -15 : -250});
         //this.graph.d3Force('charge').strength((node) => {return node.isConnected ? -15 : -225}); //assign stronger charge to connected nodes
         this.graph.d3Force('charge').strength(-225);
         this.graph.d3Force('link').strength(0.9);
         //this.graph.d3Force("link").distance((link) => {return 150 - (link.level)}).strength(1);
         //this.graph.d3Force("link").distance((link) => {
         //    return link.linkYears == "true" ? 800 : 150
         //}).strength(1);

         //this.graph.onNodeClick(node => {  //onNodeClick is very hard to fire since the screen for some reason is not so responsive to this action - have to hit it on exactly the right spot
         this.graph.onNodeClick(node => {
            return util.left(node.type, 8) != "timeline" ? this.zoomToNode(node, true) : null;
         });
         this.graph.onNodeRightClick(node => {
           this.props.setRightClickedNode(node);
         })
         this.graph.onBackgroundRightClick(e => {
          this.props.cancelRightClick();
         })
         this.graph.onLinkRightClick(e => {
          this.props.cancelRightClick();
         })
         // add planes to graph.

         if (this.state.showPlanes)
             this.state.timelinePlanes.forEach(plane => this.graph.scene().add(plane));

         this.setGraphSize();

         if (!isPreserveHighlighting) {
             highlightableNodeIds = [];
             //highlightableNodeIds = new Set();
         }

         this.highlightGraph(); //param : highlightable = empty for default
         //this.highlightGraph(); //will use props to figure out logic of how to properly size and color nodea, linka an text

         //quaternion = this.graph.camera().quaternion; //store initial camera angle info
     }

createTimelineYearPlane(theme, year) {
   const planeGeometry = new THREE.PlaneGeometry(8000, 8000, 1, 1);
   const planeMaterial = new THREE.MeshLambertMaterial({
       color: theme.planeColor,
       opacity: theme.planeOpacity,
       transparent: true,
       side: THREE.DoubleSide
   });//0xFFACAC
   var mesh = new THREE.Mesh(planeGeometry, planeMaterial);
   mesh.position.set(-100, -year * 3 + this.props.yOffset, -100);
   mesh.rotation.set(0.5 * Math.PI, 0, 0);
   return mesh;
}

zoomToNode(node, isOnClick) {
     if (this.props.isGenerationsPlaying || this.props.isPathPlaying) //if animations are playing, don't allow, selecting and zooming nodes
         return;

     // Aim at node from outside it
     if (isOnClick) {
         if (!this.props.findRelationIsOpen)
             this.props.setFocusedNode(node);
         else
             this.props.setComparisonNode(node);
     }
     //this.props.setFocusedNode(node);

     //findRelationIsOpen is checked since don't want to zoom if click on node while trying to find relation path
     else if (!this.props.findRelationIsOpen || !this.props.comparisonNode)
         this.setCameraAtNode(node, 200); //distance=200
}

setCameraAtNode(node, distance) {
     if (node.x != 0 && node.z != 0) {
         const distRatio = 1 + distance/Math.hypot(node.x, 0, node.z);
         //this.graph.camera().quaternion.set({x:node.x * distRatio , y:node.y , z:node.z * distRatio , w:0}); //restore original camera angle
         this.setCameraPosition({ x: node.x * distRatio, y: node.y, z: node.z * distRatio }, node, 2400);
     }
     else
         this.setCameraPosition({ x: 0, y: node.y, z: -distance }, node, 2400); //if x or z are 0 like the family node for Adam / Chava then hypot would be zero resulting in division by zero so this is a fix for that
}



setCameraPosition(newPosition, lookAtPosition, transitionDuration) { //newPosition and lookAtPosition take the form of an {x,y,z} object or an object that has those properties such as a node
     this.graph.cameraPosition(newPosition, lookAtPosition, transitionDuration);
}

    toggleLoading(isShow) {
        document.getElementById("yuchsinTreeLoading").style.display = isShow ? "block" : "none";
    }

    toggleSettings(){
        this.setState((currentState) => ({
            showSettings: !currentState.showSettings,
        }));
    }

    toggleColorTheme(isOn) {
        properties.setDarkMode(isOn); //hack for knowing 3Dtree dark mode status when building a link to that page in YuchsinTreeViewer since the state var for that is below one level in YuchsinTree
        var theme = isOn ? darkTheme : lightTheme;
        this.setState({isDarkMode: isOn, colorTheme: theme, timelinePlanes: this.initializePlanes(theme)}, () => {
            const lastCameraPosition = this.graph.cameraPosition();
            const lastLookAtPosition = lastCameraPosition.lookAt;
            this.refreshGraph(lastCameraPosition, lastLookAtPosition, true);
        });
    }

    toggleHeader(isShow) {
        document.getElementById("yuchsinTreeHeader").style.display = isShow ? "block" : "none";
    }

    togglePlanes(showPlanes) {
      if (showPlanes) this.state.timelinePlanes.forEach(plane => this.graph.scene().add(plane)); // add planes to graph.
      else this.state.timelinePlanes.forEach(plane => this.graph.scene().remove(plane)); // remove planes to graph.
    }

//    toggleArrows(isShow) {
//        this.graph.linkDirectionalArrowLength(link => (isShow & link.type != 'timeline') ? 12 : 0);
//    }
//
//    toggleParticles(isShow) {
//        this.graph.linkDirectionalParticles(link => (isShow && link.type != 'timeline') ? 2 : 0);
//    }

    highlightGraph() { //isOn = true => set the highlight effect / false => clear highlighting
         this.graph.nodeVal(node => this.isHighlightedNode(node.id) ? ((node.type == 'person') ? personNodeValH : (node.type == 'family') ? familyNodeValH : 0) : ((node.type == 'person') ? personNodeVal : (node.type == 'family') ? familyNodeVal : 0));
         this.graph.nodeColor(node => this.isHighlightedNode(node.id) ? (node.gender == 'MAN' ? this.state.colorTheme.manNodeColorH : (node.gender == 'WOMAN' ? this.state.colorTheme.womanNodeColorH : (node.type == 'family' ? this.state.colorTheme.familyNodeColorH : this.state.colorTheme.backgroundColor) )) :
                                                                   (node.gender == 'MAN' ? manNodeColor : (node.gender == 'WOMAN' ? womanNodeColor : (node.type == 'family' ? familyNodeColor : this.state.colorTheme.backgroundColor))));

         this.graph.nodeThreeObject(node => {
             const sprite = new SpriteText(node.displayCaption);
             sprite.color = this.state.colorTheme.spriteColor;
             sprite.fontFace = (node.type == 'timeline-lg') ? "Arial" : (node.type == 'timeline-sm') ? "Arial" : "SBL Hebrew"; //Could have used TimesNewRoman but that's not available on Android and would have had to supply a downloadable .woff font for that
             sprite.fontWeight = "bold";
             sprite.textHeight = (node.type == 'timeline-lg') ? timelineLgTextHeight : (node.type == 'timeline-sm') ? timelineSmTextHeight : (this.isHighlightedNode(node.id) ? personTextHeightH : this.state.colorTheme.personTextHeight); //replace with node.level
             return sprite;
         })

         this.graph.linkColor(link => this.isHighlightedLink(link.source.id, link.target.id) ? this.state.colorTheme.linkColorH : this.state.colorTheme.linkColor);
         this.graph.linkWidth(link => (link.type == 'timeline') ? timelineLinkWidth : this.isHighlightedLink(link.source.id, link.target.id) ? personLinkWidthH : personLinkWidth);

         this.setDirectionalArrowLength();

         this.setParticles();
         this.graph.linkDirectionalParticleColor(link => this.isHighlightedLink(link.source.id, link.target.id) ? particleColorH : particleColor);
         this.graph.linkDirectionalParticleWidth(link => this.isHighlightedLink(link.source.id, link.target.id) ? particleWidthH : particleWidth);
         this.graph.linkDirectionalParticleSpeed(link => this.isHighlightedLink(link.source.id, link.target.id) ? particleSpeedH : particleSpeed);
    }

    setDirectionalArrowLength() {
         this.graph.linkDirectionalArrowLength(link => this.isHighlightedLink(link.source.id, link.target.id) ? arrowLengthH : (link.type != 'timeline' && this.state.showArrows) ? arrowLength : 0);
    }

    setParticles() {
         this.graph.linkDirectionalParticles(link => this.isHighlightedLink(link.source.id, link.target.id) || (link.type != 'timeline' && this.state.showParticles) ? linkDirectionalParticles : 0);
    }

    isHighlightedNode(nodeId) {
         if (highlightableNodeIds.length == 0 && animationGeneration < 0) //this is a global var defined above
         //if (highlightableNodeIds.size == 0 || !highlightableNodeIds.has(nodeId)) //this is a global var defined above
             return false;

         const findRelationIsOpen=this.props.findRelationIsOpen;
         const showAncestors=this.props.showAncestors;
         const showDescendants=this.props.showDescendants;
         const pathNodeIds=this.props.pathNodeIds;
         const ancestorNodeIds=this.props.ancestorNodeIds;
         const descendantNodeIds=this.props.descendantNodeIds;
         const ancestorNodeDepths=this.props.ancestorNodeDepths;
         const descendantNodeDepths=this.props.descendantNodeDepths;
         const maxGenerations= animationGeneration >= 0 ? animationGeneration : this.props.maxGenerations;

         if (findRelationIsOpen && pathNodeIds.includes(nodeId) && animationGeneration < 0) //don't highlight relation path if within an ancestor/descendant animation
             return true;

         var i, depth;
         i = ancestorNodeIds.indexOf(nodeId);
         depth = i >= 0 ? ancestorNodeDepths[i] : 99999;

         var isCheckMultipleGenerations = this.props.isGenerationsAll || animationGeneration < 0;  //only allowc heck for a single generation if within an animation
         if (showAncestors && (isCheckMultipleGenerations ? depth <= maxGenerations : depth == maxGenerations))
             return true;

         i = descendantNodeIds.indexOf(nodeId);
         depth = i >= 0 ? descendantNodeDepths[i] : 99999;

         return showDescendants && (isCheckMultipleGenerations ? depth <= maxGenerations : depth == maxGenerations);
    }

    isHighlightedLink(nodeId1, nodeId2) {
         if (highlightableNodeIds.length == 0 && animationGeneration < 0) //this is a global var defined above
         //if (highlightableNodeIds.size == 0 || !highlightableNodeIds.has(nodeId1) || !highlightableNodeIds.has(nodeId2)) //this is a global var defined above
             return false;

         const findRelationIsOpen=this.props.findRelationIsOpen;
         const showAncestors=this.props.showAncestors;
         const showDescendants=this.props.showDescendants;
         const pathNodeIds=this.props.pathNodeIds;
         const ancestorNodeIds=this.props.ancestorNodeIds;
         const descendantNodeIds=this.props.descendantNodeIds;
         const ancestorNodeDepths=this.props.ancestorNodeDepths;
         const descendantNodeDepths=this.props.descendantNodeDepths;
         const maxGenerations= animationGeneration >= 0 ? animationGeneration : this.props.maxGenerations;

         if (findRelationIsOpen && pathNodeIds.includes(nodeId1) && pathNodeIds.includes(nodeId2) && animationGeneration < 0) //don't highlight relation path if within an ancestor/descendant animation)
             return true;

         var i, j, depth1, depth2;
         i = ancestorNodeIds.indexOf(nodeId1);
         j = ancestorNodeIds.indexOf(nodeId2);
         depth1 = i >= 0 ? ancestorNodeDepths[i] : 99999;
         depth2 = j >= 0 ? ancestorNodeDepths[j] : 99999;

         var isCheckMultipleGenerations = this.props.isGenerationsAll || animationGeneration < 0;  //only allowc heck for a single generation if within an animation
         if (showAncestors && (isCheckMultipleGenerations ? depth1 <= maxGenerations : depth1 == maxGenerations) && (isCheckMultipleGenerations ? depth2 <= maxGenerations : depth2 == maxGenerations))
             return true;

         i = descendantNodeIds.indexOf(nodeId1);
         j = descendantNodeIds.indexOf(nodeId2);
         depth1 = i >= 0 ? descendantNodeDepths[i] : 99999;
         depth2 = j >= 0 ? descendantNodeDepths[j] : 99999;

         return showDescendants && (isCheckMultipleGenerations ? depth1 <= maxGenerations : depth1 == maxGenerations) && (isCheckMultipleGenerations ? depth2 <= maxGenerations : depth2 == maxGenerations);
    }


setGraphSize(){
  if(this.graph){
    var height = this.props.height; //lf showing instruction key at bottom need to adjust canvas height accordingly with minus 10
    var width = this.props.width;
    this.graph.height(height);
    this.graph.width(width);
  }
}

async componentDidUpdate(prevProps) {
  if(this.props.height != prevProps.height || this.props.width != prevProps.width){
    this.setGraphSize();
  }
  // Typical usage (don't forget to compare props):
  if (this.props.data != prevProps.data) {
      //var cameraPosition = initialCameraPosition;
      //var lookAtPosition = initialLookAtPosition;
      //if (this.props.focusedNode != null && !this.props.isViewerOpenedYet) {
      //this.setCameraAtNode(this.props.focusedNode, 300)
      //    cameraPosition = {x:0, y:this.props.focusedNode.fy, z:-2000};
      //    lookAtPosition = {x:0, y:this.props.focusedNode.fy, z:0};
      //}
      //this.refreshGraph(cameraPosition, lookAtPosition); //this should take care of highlighting needs too?!??!
      this.refreshGraph(); //this should take care of highlighting needs too?!??!
      this.toggleLoading(false);
  }
  //the following two things should be mutually exclusive since when click on a 2nd node to set yuchsin path, screen shouldn't zoom in to that 2nd node
  //can't do anything here unless at least selected a single node 1st so must check this.props.focusedNode
  if (this.props.focusedNode && (this.props.findRelationIsOpen != prevProps.findRelationIsOpen || this.props.showAncestors != prevProps.showAncestors || this.props.showDescendants != prevProps.showDescendants ||
      this.props.pathNodeIds != prevProps.pathNodeIds || this.props.ancestorNodeIds != prevProps.ancestorNodeIds || this.props.descendantNodeIds != prevProps.descendantNodeIds ||
      this.props.maxGenerations != prevProps.maxGenerations || this.props.focusedNode != prevProps.focusedNode)) {
      //saw here that Set() is best performing way of building unique list of info https://robkendal.co.uk/blog/2020-02-04-creating-unique-merged-arrays-using-javascripts-set-and-more
      //need to include current state values and previous values since need to turn things off AND on
      ﻿const nodeIds = new Set([...this.props.pathNodeIds, ...prevProps.pathNodeIds,
                               ...this.props.ancestorNodeIds, ...prevProps.ancestorNodeIds,
                               ...this.props.descendantNodeIds, ...prevProps.descendantNodeIds]); //use JS spread notation
      highlightableNodeIds = [...nodeIds];  //Set might not work on older browsers so convert it to new arrary for the global var just in case
      //highlightableNodeIds = nodeIds;  //Set might not work on older browsers so convert it to new arrary for the global var just in case

      this.highlightGraph();
  }

//  if (this.props.focusedNode && prevProps.focusedNode && this.props.focusedNode.id != prevProps.focusedNode.id) {
//      alert("phooi");
//      this.highlightGraph();
//      }

  if (!this.props.findRelationIsOpen && !this.props.comparisonNode && this.props.focusedNode != null && this.props.focusedNode != prevProps.focusedNode) { //&& prevProps.focusedNode != null
      var fn = this.props.focusedNode;
      var pn = prevProps.focusedNode;
      //if (fn.x != null && fn.y != null && fn.z != null && pn.x != null && pn.y != null && pn.z != null ) {
      if (fn.x != null && fn.y != null && fn.z != null) {
          //alert("fn(x:y:z)@@@pn(x:y:z)" + fn.x +":"+ fn.y +":"+ fn.z + "@@@" + pn.x +":"+ pn.y +":"+ pn.z);
          this.zoomToNode(this.props.focusedNode);
      }
  }

  if (this.props.isGenerationsPlaying != prevProps.isGenerationsPlaying) {
      if (this.props.isGenerationsPlaying) {
          var i=0, increment=1, isReverse=false, pos, node;

      ﻿    const depths = new Set([...this.props.ancestorNodeDepths, ...this.props.descendantNodeDepths]); //use JS spread notation
          const generationalDepths = [...depths];  //Set might not work on older browsers so convert it to new arrary for the global var just in case
          var maxDepth = Math.min(Math.max(...generationalDepths), this.props.maxGenerations); //take the lesser of max generations from user settings vs max generation depth discovered in graph

          //1st clear out highlighting
          highlightableNodeIds = [];
          this.highlightGraph();

          generationsPlayingTimer = setInterval(() => {
              if ((!isReverse && i <= maxDepth) || (isReverse && i >= 0)) {
                  animationGeneration = i;
                  this.highlightGraph();
                  i += this.props.isRepeatGenerationsFromEnd ? increment : 1; //catches situation where one changes repeat preference while in the middle of playing
              }
              else if (this.props.isRepeatGenerationsFromStart || this.props.isRepeatGenerationsFromEnd) {
                  isReverse = this.props.isRepeatGenerationsFromEnd && !isReverse;
                  i = isReverse ? maxDepth-1 : this.props.isRepeatGenerationsFromStart ? 0 : 1;
                  increment = isReverse ? -1 : 1;
              }
              else { //no repeat option selected so stop the player
                  clearInterval(generationsPlayingTimer);
                  animationGeneration = -1; //concerned that if do this before clearInterval(), the line above, "animationGeneration = i" might get executed and mess things up
                  this.reHighlightGraph(); //restore highlighting from prevHighlightableNodeIds
                  this.props.toggleGenerationsPlayPause(); //asssuming play was on so will pause
              }
          }, 100); //meed low setting here since rendering of generational info is slow
      }
      else {
          clearInterval(generationsPlayingTimer);
          animationGeneration = -1; //concerned that if do this before clearInterval(), the line above, "animationGeneration = i" might get executed and mess things up
          this.reHighlightGraph(); //restore highlighting from prevHighlightableNodeIds
      }
  }

  if (this.props.isPathPlaying != prevProps.isPathPlaying) {
      if (this.props.isPathPlaying) {
          var i=0, increment=1, isReverse=false, pos, node;

          pathPlayingTimer = setInterval(() => {
              if ((!isReverse && i < this.props.pathNodeIds.length) || (isReverse && i >= 0)) {
                  pos = this.props.data.nodes.findIndex((x) => x.id == this.props.pathNodeIds[i]);
                  this.setCameraAtNode(this.props.data.nodes[pos], 400); //distance=400
                  i += this.props.isRepeatPathFromEnd ? increment : 1; //catches situation where one changes repeat preference while in the middle of playing
              }
              else if (this.props.isRepeatPathFromStart || this.props.isRepeatPathFromEnd) {
                  isReverse = this.props.isRepeatPathFromEnd && !isReverse;
                  i = isReverse ? this.props.pathNodeIds.length-2 : this.props.isRepeatPathFromStart ? 0 : 1;
                  increment = isReverse ? -1 : 1;
              }
              else { //no repeat option selected so stop the player
                  clearInterval(pathPlayingTimer);
                  this.props.togglePathPlayPause(); //asssuming play was on so will pause
              }
          }, 2500);
      }
      else
          clearInterval(pathPlayingTimer);
  }
}

reHighlightGraph() {
      ﻿const nodeIds = new Set([...this.props.pathNodeIds, ...this.props.ancestorNodeIds, ...this.props.descendantNodeIds]); //use JS spread notation
      highlightableNodeIds = [...nodeIds];  //Set might not work on older browsers so convert it to new arrary for the global var just in case
      this.highlightGraph();
}

async componentDidMount() {
    this.props.extGetData("graph"); //this will force the parent component to alter its data which will in turn fire the componentDidUpdate here due to a change in this.props.data
/*
Moshe - the following ar the original values Shlomo was using as of Aug 14
      .nodeVal(150)
      .dagMode('td')
      .nodeRelSize(2)
      .nodeOpacity(0.5)
          sprite.textHeight = 20; //replace with node.level
      .linkWidth(2)
    //this.graph.dagMode('td');
*/
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    //if (!this.props.colorTheme)
    //    this.props.setColorTheme(darkTheme);

    const showSettings = this.props.translate('displaySettings') + ' ▼';
    const hideSettings = this.props.translate('hide') + ' ▲';
    const show = this.props.translate('show');
    const isDarkMode = this.props.translate('isDarkMode');
    const header = this.props.translate('header');
    const dividerPlanes = this.props.translate('dividerPlanes');
    const directionalArrows = this.props.translate('directionalArrows');
    const directionalParticles = this.props.translate('directionalParticles');
    const loading = this.props.translate('loading');

    var directionStyling = { direction: 'ltr', textAlign: 'left', backgroundColor: this.state.colorTheme.legendBackgroundColor }
    if (this.props.activeLanguage && this.props.activeLanguage.code === "he") {
      directionStyling = { direction: 'rtl', textAlign: 'right', backgroundColor: this.state.colorTheme.legendBackgroundColor }
    }

    return (<>
        <Legend colorTheme={this.state.colorTheme} extSetAboutTabIndex={this.props.extSetAboutTabIndex} />
        <div
          className="basic-3d"
          ref={r => (this.chartRef = r)}
          style={{height: this.props.height, width: this.props.width, overflow: "hidden"}}
        />
        <div id="yuchsinTreeHeader" className="yuchsin-tree-header" style={{color: this.state.colorTheme.headerColor}}>
              <label>וְאֵלֶּה רָאשֵׁי אֲבֹתֵיהֶם וְהִתְיַחְשָׂם</label>&nbsp;
              <label className="yuchsin-tree-header-sm">(עזרא ח:א)</label>
        </div>
        <div id="yuchsinTreeLoading" className="yuchsin-tree-loading"><label>{loading}</label></div>
        <div className="yuchsin-tree-controls" style={directionStyling}>
            <p id="settings-button" onClick={() => this.toggleSettings()}>{this.state.showSettings ? hideSettings : showSettings}</p>
            {this.state.showSettings && <div id="settings-content" style={{color: this.state.colorTheme.legendTextColor}}>
          <p>
              <input
                  name="isDarkMode"
                  type="checkbox"
                  checked={this.state.isDarkMode}
                  onChange={this.handleInputChange} />&nbsp;&nbsp;{isDarkMode}
          </p>
          <p>
              <input
                  name="showHeader"
                  type="checkbox"
                  checked={this.state.showHeader}
                  onChange={this.handleInputChange} />&nbsp;&nbsp;{header}
          </p>
          <p>
              <input
                  name="showPlanes"
                  type="checkbox"
                  checked={this.state.showPlanes}
                  onChange={this.handleInputChange} />&nbsp;&nbsp;{dividerPlanes}
          </p>
          <p>
              <input
                  name="showArrows"
                  type="checkbox"
                  checked={this.state.showArrows}
                  onChange={this.handleInputChange} />&nbsp;&nbsp;{directionalArrows}
          </p>
          <p>
              <input
                  name="showParticles"
                  type="checkbox"
                  checked={this.state.showParticles}
                  onChange={this.handleInputChange} />&nbsp;&nbsp;{directionalParticles}
          </p>
        </div>
        }
        </div>
    </>);
  }
}
YuchsinTree.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(withLocalize(YuchsinTree));

//mje - removed onContextMenu={this.props.cancelRightClick}
