import React, { Component } from 'react';
import './css/App.css';
import AppNavBar from './AppNavBar';
import { Link } from 'react-router-dom';
import { Button, Container, Table, NavLink } from 'reactstrap';
import { withLocalize, Translate, getTranslate, getActiveLanguage, LocalizeProvider } from "react-localize-redux";
import translations from "./json/translations.json";
import { renderToStaticMarkup } from "react-dom/server";

class SiteMap extends Component {

 constructor(props) {
    super(props);
    this.state = {

    };
    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });
    this.setLanguage = (code) => {props.setActiveLanguage(code)}
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }

    var userManualHref = (this.props.activeLanguage && this.props.activeLanguage.code === "he") ? "./userManual_he.pdf" : "./userManual.pdf";

    var retVal;
    if(langCode === "he"){
      retVal = <>
        <AppNavBar isWithinSiteMap={true}/>
        <p dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>
          <div>
            <div style={{height: "15px"}} />
            <div style={{fontSize: "20px"}}>&nbsp;&nbsp;&nbsp;<b>מפת האתר של בייס-הספר</b></div>
            <div style={{height: "15px"}} />
            <div style={{fontSize: "18px"}}>&nbsp;&nbsp;&nbsp;<Link className="siteMap" to="/"><b>דף הבית</b></Link></div>

            <Container fluid>
              <table>
                <tr valign="top">
                    <td width="200">
                        <b>כללי</b>
                        <li><Link className="siteMap" to="/contact" className="siteMap">צור קשר אתנו</Link></li>
                        <li><Link className="siteMap" to="/help">עזרה</Link></li>
                        <li><a className="siteMap" href={userManualHref} target="_blank">מדריך למשתמש</a></li>
                        <li><Link className="siteMap" to="/premiumServices">שירותי פרימיום</Link></li>
                        <li>
                            <Link className="siteMap" to="/about">אודות בייס-הספר</Link>
                            <ul>
                                <li><Link className="siteMap" to="/mission">משימה</Link></li>
                                <li><Link className="siteMap" to="/benefit">מי ירוויח?</Link></li>
                                <li><Link className="siteMap" to="/features">תכונות</Link></li>
                            </ul>
                        </li>
                    </td>
                    <td width="200">
                        <b>תכונות</b>
                        <li><Link className="siteMap" to="/parshaViewer">פרשיות התנ"ך</Link></li>
                        <li><Link className="siteMap" to="/tanach">דפדפן התנ"ך</Link></li>
                        <li><Link className="siteMap" to="/lexicon">לקסיקון</Link></li>
                        <li><Link className="siteMap" to="/frequency">שכיחות</Link></li>
                        <li><Link className="siteMap" to="/compare">משווה טקסטים</Link></li>
                        <li><Link className="siteMap" to="/taamParser">מנתח טעמים</Link></li>
                        <li><Link className="siteMap" to="/clouds">ענני אובייקטים</Link></li>
                        <li><Link className="siteMap" to="/3Dtree">אילן יוחסין תנ"כי"</Link></li>
                    </td>
                </tr>
              </table>
            </Container>
          </div>
        </p>

      </>
    } else if (langCode === "en") {
      retVal = <>
        <AppNavBar isWithinSiteMap={true}/>
        <p>
          <div>
            <div style={{height: "15px"}} />
            <div style={{fontSize: "20px"}}>&nbsp;&nbsp;&nbsp;<b>Base Hasefer Site Map</b></div>
            <div style={{height: "15px"}} />
            <div style={{fontSize: "18px"}}>&nbsp;&nbsp;&nbsp;<Link className="siteMap" to="/"><b>Home page</b></Link></div>

            <Container fluid>
              <table>
                <tr valign="top">
                    <td width="200">
                        <b>General</b>
                        <li><Link className="siteMap" to="/contact">Contact Us</Link></li>
                        <li><Link className="siteMap" to="/help">Help</Link></li>
                        <li><a className="siteMap" href={userManualHref} target="_blank">User Manual</a></li>
                        <li><Link className="siteMap" to="/premiumServices">Premium Services</Link></li>
                        <li>
                            <Link className="siteMap" to="/about">About Base Hasefer</Link>
                            <ul>
                                <li><Link className="siteMap" to="/mission">Mission</Link></li>
                                <li><Link className="siteMap" to="/benefit">Who will benefit?</Link></li>
                                <li><Link className="siteMap" to="/features">Features</Link></li>
                            </ul>
                        </li>
                    </td>
                    <td width="200">
                        <b>Features</b>
                        <li><Link className="siteMap" to="/parshaViewer">Parsha Viewer</Link></li>
                        <li><Link className="siteMap" to="/tanach">Tanach Viewer</Link></li>
                        <li><Link className="siteMap" to="/lexicon">Lexicon</Link></li>
                        <li><Link className="siteMap" to="/frequency">Frequency Viewer</Link></li>
                        <li><Link className="siteMap" to="/compare">Text Comparator</Link></li>
                        <li><Link className="siteMap" to="/taamParser">Taam Parser</Link></li>
                        <li><Link className="siteMap" to="/clouds">Object Clouds</Link></li>
                        <li><Link className="siteMap" to="/3Dtree">Tanach Family Tree</Link></li>
                    </td>
                </tr>
              </table>
            </Container>
          </div>

        </p>

      </>
    }
    return retVal;
  }

  render() {
    var isHebrew = this.props.activeLanguage && this.props.activeLanguage.code === "he";
    if (localStorage.getItem("language")) //this overrides above setting
        isHebrew = localStorage.getItem("language") == "he";

    const languageCode = isHebrew ? "he" : "en";
    return <>
      {this.getTranslatedContent(languageCode)}
    </>;
  }
}

export default withLocalize(SiteMap);
