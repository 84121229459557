import React, { Component } from 'react';

class Distances_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif', color: 'black'}}>פרטים
              עבור כרטיסיית
              מרחקים:</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif', color: 'black'}}> (<i>הגדרות
              אלו ייחודיות
              לכל ביטוי
              חיפוש</i>)</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR" style={{fontFamily: '"Arial",sans-serif', color: 'black'}}><img width={624} height={70} id="Picture 28" src="helpDialogImages/image019.png" alt="https://lh5.googleusercontent.com/MtHhguraqaNuSKnGkLuyZcYWgYbZeG9O0doipHR-eudHqKol5MD4gUnLZyU7Z1xN2zD2PfLbf98TBOkHFLZ7B0Ku1Qlmj6-T3Rw7VmXUM9PZHOvC4-t1zygwywlqfBD70DI3yaSD" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בכרטיסייה
            זו יש שש
            הגדרות החלות
            על הביטוי שנבחר
            ומגדירות כמה
            רחוק יכול
            להיות הביטוי
            מהגדרה מבנית
            כלשהי.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הצד
            הימני יגדיר
            כמה רחוק
            מתחילת המבנה
            והצד השמאלי
            יגדיר כמה רחוק
            מקצה המבנה:</span></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מרחק
            המותר בין
            הביטוי לתחילת/סוף
            <b>הפסוק</b> שלו</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מרחק
            המותר בין
            הביטוי לתחילת/סוף
            <b>הפרשה</b> שלו (פתוחה
            או סתומה)</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מרחק
            המותר לתחילת/סוף
            <b>הספר</b> שלו</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בכל
            הגדרה, המשתמש
            יכול לבחור
            באיזו יחידת
            מרחק ישמש היכן
            שרלוונטי: <b>אותיות</b>,
            <b>תיבות</b>, <b>פסוקים</b>
            או <b>פרשיות</b>. יהיה
            בקרת סליידר
            דו-חודי
            המאפשרת
            למשתמש לבחור
            את הקצה הנמוך
            של טווח המרחק
            (כברירת מחדל
            המאפשר מרחק
            אפס) ואת הקצה
            הגבוה של
            הטווח (כברירת
            מחדל מרחק
            אינסופי). אם
            רצוי מספר
            בודד להגדרת
            המרחק ולא
            טווח, יש
            לגרור את כפתורי
            המחוון עד
            ששניהם
            נפגשים באותה
            נקודה.</span></p>
            <br/>
          <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>
אפשר גם ללחוץ על תיבת הטווח המספרי שאתחול ל-0 עד ∞ (סמל האינסוף) ולהקליד מספרי טווח הרצוי. כדי לשמור את השינוי, לחץ מחוץ לתיבה.
          </p><br/>
          <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>
             אם הוזנה יותר ממונח חיפוש אחד, יופיע חלונית טווח מרחק נוספת (ראה צילום למטה). הוא משמש להגדרת מרחקים מותרים בין כל אחד ממונחי החיפוש. לחץ על סימן המינוס (-) כדי לציין
             שהמונח הרשום חייב להופיע לפני המונח שנבחר, סימן הפלוס (+) כדי לציין שהוא מופיע אחרי המונח שנבחר וסמל +/- אם הרצף אינו משנה.
          </p><br/>
          <img width={700} height={200} id="Picture 28" src="helpDialogImages/distances2.jpg" />
        <p className="MsoNormal">&nbsp;</p>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b><span style={{color: 'black'}}>Details for the Distances tab: </span></b><i><span style={{color: 'black'}}>(these settings are unique to each search term)</span></i></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Arial",sans-serif', color: 'black'}}><img width={624} height={70} id="Picture 28" src="helpDialogImages/image019.png" /></span></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">There are six settings on this tab that apply to the selected
          term and define how far the term can be from some structural definition.&nbsp; The
          right side will define how far from the beginning of the structure and the left
          side will define how far from the end of a structure:</p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />How far can the term be from the beginning/end
          of its <b>pasuk</b></p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />How far from the beginning/end of its <b>parsha</b>
          (pesucha or stuma)</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />How far from the beginning/end of its <b>sefer</b></p>
        <p className="MsoNormal">In each setting the user can select what distance unit will
          be used where relevant: <b>letters</b>, <b>words</b>, <b>pesukim</b> or <b>parshios</b>.&nbsp;
          There will be a two-pronged slider control that lets the user select the low
          end of the distance range (by default allowing zero distance) and the high end
          of the range (by default an infinite distance).&nbsp; If a single number is desired
          to define the distance and not a range then one must drag the slider buttons
          until both meet at the same spot.</p><br/>
          <p className="MsoNormal">
             One can also click on the numeric range box which is initialized to 0 עד ∞ (the infinity symbol)
             and type in the exact desired range. To save the change, click outside the box.</p><br/>
          <p className="MsoNormal">
             If more than one search term was entered, another distance range panel will appear (see image below).
             It is used for setting allowed distances between each of the search
             terms. Click the minus sign (-) to indicate that the listed term must occur before the selected term, the plus sign (+) to indicate it occurs after the selected term and the +/- symbol
             if the sequence doesn't matter.</p><br/>
          <img width={700} height={200} id="Picture 28" src="helpDialogImages/distances2.jpg" />

          

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default Distances_Instructions;