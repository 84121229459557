import Auth0Lock from 'auth0-lock';
import * as properties from './config/properties.js';

//mje - Oct 29, 2019 - for obsolete code pertinent to trial and error changes to get login/authorization to work with Auth) and Okta in Oct 2019, see version history of this file in Git


//The following object gets initialized once
//Ari: main lock object is now in Home.js. Leaving this here temporarily
const lock = new Auth0Lock(properties.env.auth0.clientID, properties.env.auth0.domain, {
 language: localStorage.getItem("language"),
 auth: {redirect: true},
 additionalSignUpFields: [
  {name: "full_name", placeholder: "Full name", validator: function(full_name) {return {valid: full_name.length >= 5, hint: "Must have 5 or more characters"};}},
  {name: "phone", placeholder: "Phone", validator: function(phone) {return {valid: phone.length >= 10, hint: "Must have 10 or more characters"};}},
  {name: "address1", placeholder: "Address 1", validator: function(address1) {return {valid: address1.length >= 10, hint: "Must have 10 or more characters"};}},
  {name: "address2", placeholder: "Address 2", validator: function(address2) {return {valid: address2.length >= 10, hint: "Must have 10 or more characters"};}}
],
  theme: {
    logo: 'https://taryaganalytics.weebly.com/uploads/1/1/0/9/110948629/published/bhslogotm_2.jpg',
    primaryColor: '#1E90FF'
  }
});

//Ari: main lock object is now in Home.js. Leaving this here temporarily
//the following gets set a single time on the onject
lock.on('authenticated', function(authResult) {handleAuthentication(authResult, true)});

//this function was only used for some testing
export function refreshSession() {
    var ar = null;
    lock.checkSession({}, function(err, authResult) {
            if (err) {
              // Handle error
              return;
            }
            ar = authResult;
     });
     if (ar != null)
         handleAuthentication(ar, false);
}

export function handleAuthentication(authResult, isRedirect) {
  lock.getUserInfo(authResult.accessToken, function(error, profile) {
    //alert("hunh?");
    if (error) {
      // Handle error
      return;
    }
//idToken, state, refreshToken and idTokenPayload
    //alert(JSON.stringify(authResult.accessToken + ":::" + authResult.idToken + ":::" + authResult.state + ":::" + authResult.refreshToken + ":::" + authResult.idTokenPayload));
    var request = require("request");

    var options = {
      method: 'GET',
      url: properties.env.auth0.apiURL + '/users-by-email',
      qs: {email: profile.email},
      headers: {authorization: 'Bearer ' + properties.env.auth0.apiToken}
    };

    request(options, function (error, response, body) {
      if (error) throw new Error(error);

      const jsonBody = JSON.parse(body);



        request = require("request");

        var options2 = {
          method: 'GET',
          url: properties.env.auth0.apiURL + '/logs',
          page: 1,
          per_page: 1,
          sort: "date-1",
          fields: "date, user_agent",
          qs: {ip: jsonBody[0].last_ip},
          headers: {authorization: 'Bearer ' + properties.env.auth0.apiToken}
        };

        request(options2, function (error, response, body) {
          if (error) throw new Error(error);

            const jsonBody2 = JSON.parse(body);

            localStorage.setItem("user_agent", jsonBody2[0].user_agent);

            localStorage.setItem("last_ip", jsonBody[0].last_ip);

            //alert(profile.last_ip);
            localStorage.setItem("idToken", authResult.idToken);
            localStorage.setItem("accessToken", authResult.accessToken);
            localStorage.setItem("profile", JSON.stringify(profile));
            localStorage.setItem("userInfo", profile.email);
            // Update DOM
            if (isRedirect == null || isRedirect)
                window.location.reload(false); //need to reload page here to have DOM recognize new local storage values (false = reload from cache / true would = reload from server)
            //alert(isRedirect + ':' + profile.email);

        });

    });



  });
}


/*


    "email": "john.doe@gmail.com",
    "email_verified": false,
    "username": "johndoe",
    "phone_number": "+199999999999999",
    "created_at": "",
    "updated_at": "",
    "identities": [
      {
        "connection": "Initial-Connection",
        "user_id": "5457edea1b8f22891a000004",
        "provider": "auth0",
        "isSocial": false
      }
    ],
    "app_metadata": {},
    "user_metadata": {},
    "name": "",
    "nickname": "",
    "last_ip": "",
    "last_login": "",
    "given_name": "",
    "family_name": ""
*/

export function getUserData(profileEmail) {
}

//Ari: main login function is now in Home.js. Leaving this here temporarily
export function login () {
    lock.show();//display the Lock popup widget
}

export function loggedIn () {
    return getProfile() != null;
}

export function setToken(idToken) {localStorage.setItem('idToken', idToken);}
export function getToken() {return localStorage.getItem('idToken');}
export function getAccessToken() {return localStorage.getItem('accessToken');}

export function getProfile() {return localStorage.getItem('profile');}
export function getUserInfo() {return localStorage.getItem('userInfo');}

//Ari: main logout function is now in Home.js. Leaving this here temporarily
export function logout () {
//  alert(localStorage.getItem("user_agent"));
//  if (window.confirm("Are you sure? (all current searches and displays will be cleared)")) {
        lock.logout();

    // Clear user token and profile data from localStorage
        localStorage.removeItem('idToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('profile');
        localStorage.removeItem('userInfo');
//  }
}
