import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import styles from './css/App.css'; //for customizing reactStrap styles
import { withLocalize, Translate } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import translations from "./json/translations.json";

class ToggleIconButton extends Component {
    constructor(props){
        super(props);
        this.state = {}
        this.props.initialize({
          languages: [
            { name: "עברית", code: "he" },
            { name: "English", code: "en" }
          ],
          translation: translations,
          options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
        });
    }

    render(){
        var pic = !this.props.disabled && this.props.isPressed ? this.props.onImage : this.props.offImage;
        var title = this.props.disabled ? null : this.props.isPressed ? this.props.onTip : this.props.offTip;
        var style = this.props.disabled ? null : {cursor: "pointer"};
        return <img src={pic} height={this.props.height} width={this.props.width} title={title} style={style} onClick={this.props.toggleAction} />
    }
}

export default withLocalize(ToggleIconButton);
