import React, { Component } from 'react';

class TextComparatorOptions_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>משווה
              טקסטים</span></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מטרה</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>להציע
            למשתמש מספר
            דרכים להשוות
            בין שתי קבוצות
            שונות של
            פסוקים על מנת
            למצוא קווי
            דמיון שקשה
            לזהות בין
            חלקים
            ואי-התאמות
            בין חלקים
            דומים מאוד.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>זה
            יכול להעניק
            תובנה והערכה
            גם לפשוטו של
            מקרא וגם
            לדרשות חז"ל.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>זה
            יכול גם לעזור
            בניווט
            בקטעים
            מפורטים של תנ"ך
            שבהם מופיעים
            אי התאמות
            קלות במסכת
            סופרים.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אפשרויות
                והגדרות</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ההגדרות
            הסטנדרטיות
            שתוארו קודם
            ישפיעו על מיקוד
            החיפוש
            מבחינת סוג
            המונח
            (אותיות, נקודות,
            טעמים, שורש, גמטריא,
            אתבש).</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ישנן
            ארבע דרכים
            שניתנות
            להשוואה
            ולהנגדה בין
            חלקים בתנ"ך וניתן
            לבחור מבין
            האפשרויות
            הבאות:</span></p>
        <p className="MsoNormal" align="center" dir="RTL" style={{textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={356} height={25} id="Picture 57" src="helpDialogImages/image052.png" /></span></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ביטויים
              משותפים</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - מייצר
            רשימה ממוינת
            המציינת אילו
            מונחים ו/או
            ביטויים
            משותפים בין
            שני חלקים
            נבחרים של תנ"ך
            ואילו מונחים
            נמצאים רק בחלק
            אחד או אחר.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>גם
            מספק הרבה
            נתונים
            סטטיסטיים
            נוספים</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>סדרות
              משותפות</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            מייצרת טבלה
            המכילה את רצף
            ההתאמה
            המקביל הארוך
            ביותר של
            מונחים
            משותפים בין
            שני החלקים
            שנבחרו ע"י
            שימוש בפתרון
            לבעיה המוכרת
            למדעי המחשב
            של מציאת רצף
            משותף ארוך
            ביותר (</span><span dir="LTR">LCS</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />).</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>זה
            מראה כיצד התבנית
            מתבטאת
            בפסוקים זה
            לצד זה.</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הפרשים</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - מציג
            כל אחת משתי
            הבחירות ומדגיש
            את ההבדלים או
            המשותפים
            בהתאם
            לאפשרויות
            שנבחרו.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>זה מריץ את
            מה שמכונה
            בטכנולוגיית
            המחשבים כ"</span><span dir="LTR">Diff</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />" בחלק אחד
            מהתנ"ך כנגד
            השני.</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ענני אבייקטים</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - מציג
מייצר ענני תג מקבילים לכל אחת מב' הבחירות על בסיס מילים, שורשים, גמטריא וכו'
        </span></p>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לפני
            השקת השוואה,
            יש לציין את
            שני החלקים
            השונים של
            התנ"ך
            שמשווים אותם
            על ידי לחיצה
            על כפתורי "<b>בחר
              קטעים</b>"
            מימין ומשמאל
            (הסדר לא משנה)
            ואז לבצע
            בחירות:</span></p>
        <p className="MsoNormal" align="center" dir="RTL" style={{textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={623} height={54} id="Picture 59" src="helpDialogImages/image053.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            לחיצה על
            כפתור "<b>בחר
              קטעים</b>",
            יופיע דיאלוג קופץ
            שייראה בדיוק
            כמו זה של
            כרטיסיית
            המיקום</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בהגדרות
            החיפוש
            המתקדם (ראה
            לעיל)</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
          </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שם
            ניתן לבחור
            קטע אחד עד
            רבים של קטעים
            מסויימים בתנ"ך
            עם האפשרות <b>קטע
              מסויים</b>:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={623} height={16} id="Picture 14" src="helpDialogImages/image023.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>או
            טווח פסוקים
            ספציפי עם האפשרות
            <b>טווח פסוקים</b>:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={624} height={16} id="Picture 40" src="helpDialogImages/image054.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            שנבחר מיקומי
            הפסוקים,
            רשימת סיכום
            של הבחירות
            תופיע בתיבה
            ימנית
            ושמאלית
            באמצע המסך
            (ראה דוגמה
            למעלה).</span></p>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b>Text Comparator / </b><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>משווה
              טקסטים</span></b></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Goal</u></b></p>
        <p className="MsoNormal">To offer the user multiple ways to compare two different
          sets of pesukim in order to find hard to detect similarities of disparate
          sections and discrepancies within very similar sections.&nbsp; This can offer
          insight and appreciation into both <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פשוטו של
            מקרא</span><span dir="LTR" /> <span dir="LTR" />
          and <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>דרשות
            חז"ל</span><span dir="LTR" /><span dir="LTR" />.&nbsp;
          It can also help navigate detailed sections of Tanach where slight
          discrepancies are listed in <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מסכת
            סופרים</span><span dir="LTR" /><span dir="LTR" />.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Options and settings</u></b></p>
        <p className="MsoNormal">The standard settings described earlier will affect a
          search’s focus in terms of term type (letters, nekudos, taamim, shoresh,
          gematria, atbash).</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">There are four methods given for comparing and contrasting
          sections of Tanach and can be selected from the following options:</p>
        <p className="MsoNormal" align="center" style={{textAlign: 'center'}}><img width={356} height={25} id="Picture 57" src="helpDialogImages/image052.png" /></p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ביטויים
              משותפים</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– generates a sorted list indicating which
          terms and or phrases are shared among two selected sections of Tanach and which
          terms are distinct to either section.&nbsp; Also provide lots of extra statistics</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>סדרות
              משותפות</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– generates a grid containing the longest
          parallel matching sequence of shared terms among the two selected sections
          using a solution to the well-known computer science problem of finding a Longest
          Common Subsequence (LCS).&nbsp; It shows how the pattern plays out in the pesukim
          side-by-side</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הפרשים</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– displays each of
          the two selections and highlights differences or commonalities depending upon
          options selected.&nbsp; This runs what is known in computer technology as a “Diff”
          on one section of Tanach against the other.</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ענני אבייקטים</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– generates parallel
             tag clouds for each of the two selections based upon words, shorashim, gematria and more.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">Before launching a comparison, one needs to specify the two
          different sections of Tanach being compared by the following by clicking on the
          “<b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בחר
              קטעים</span></b><span dir="LTR" /><span dir="LTR" />” buttons on the right and on the left (the order doesn’t matter)
          and then making selections:</p>
        <p className="MsoNormal" align="center" style={{textAlign: 'center'}}><img width={623} height={54} id="Picture 59" src="helpDialogImages/image053.jpg" /></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">Once a “<b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>בחר
              קטעים</span></b><span dir="LTR" /><span dir="LTR" />” button is clicked, a popup dialogue will appear that will look
          exactly the same as the Placement tab selection in the Advanced Search settings
          (see above) where one can select one to many sections of named sections of
          Tanach with the <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>קטע
              מסויים</span></b><span dir="LTR" /><span dir="LTR" /> option:</p>
        <p className="MsoNormal"><img width={623} height={16} id="Picture 14" src="helpDialogImages/image023.jpg" /></p>
        <p className="MsoNormal">or a specific range of pesukim with the <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טווח
              פסוקים</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>option:</p>
        <p className="MsoNormal"><img width={624} height={16} id="Picture 40" src="helpDialogImages/image054.jpg" /></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">Once the pasuk location selections are made, a summary list
          of the selections will appear in a right and left box in the middle of the
          screen (see example above).</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default TextComparatorOptions_Instructions;