import React, { Component } from 'react';

class IssuesGeneral extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <ul>
          <li>
            אזור ומנהג - ברירת המחדל הנוכחית היא חוץ לארץ ואשכנזי להגדרות הקריאה.
            עבור יום טוב יופיעו חמשה או ששה עליות ברשימות הבחירה של העלייה (המייצגות יום טוב של יום חול).
            אנו מקווים להציע הגדרות לאפשרויות אחרות בעתיד.
          </li>
          <li>שורש חסר דיוק</li>
          <li>פסק, לגרמיה, ומקף אינם מופיעים בהצגת פסוקים</li>
          <li>טיפול בסטיית פסוקים ותיבות כמו בטעם תחתון\עליון וקרי\כתיב</li>
          <li>
            כיום קיימים בעייות כלליות כשעוסקים בקטעים מקבילים של טעם תחתון\עליון כגון עשרת הדברות ביתרו\ואתחנן והקטע הקצר של מעשה ראובן בפרשת וישלח
          </li>
          <li>
            ישנם גם בעייות בחיפוש ובהצגת מילים בהן קיימת קרי\כתיב. חלק מהם נזכרים להלן בשגיאות הטקסטואליות
          </li>
          <li>
            אי-טיפול בפרשיות המתרחשות באמצע הפסוק: לא מקשר נכון התיבות לכל אחד מהפרשיות המדוברות. זה משפיע גם על ספירת מילים וחישובי גימטריא לכל פסוק. דוגמאות לזה הם פרשיות מהעשרת הדברות ביתרו\ואתחנן וגם שמואל א:ו:טז וכו'
          </li>
          <li>
            ירושלים עם אתנחתא: ישנן עשרות פעמים בהן נראה כי זה לא מתועד כראוי ולכן יכול להשפיע על חיפושים הכוללים טעמים וישפיע גם על אופן ניתוחו של פסוק במסך של מנתח טעמים.
          </li>
        </ul>

      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <ul>
          <li>Locale and Minhag - the current default is chutz laAretz and Ashkenazi for the kria definitions. 
            For Yom Tov, five or six aliyos will appear in the aliya selection lists (representing a weekday yom tov). 
            We hope to offer settings for other options in the future.</li>
          <li>Shoresh lacks precision</li>
          <li>Don't display paseq, legarmei, makaf when displaying pesukim</li>
          <li>Handling divergent pesukim and words such as with taam tachton vs. taam elyon and kri vs. ksiv</li>
          <li>There are currently general issues when dealing with parallel sections of taam tachton vs. taam elyon 
            such as the aseres hadibros in Yisro and in VaEschanan and in the brief section of mayseh Reuven in Vayishlach</li>
          <li>There are also issues in the search and display of words where there is a kri vs. ksiv. Some of those 
            are mentioned below in the Textual Errors</li>
          <li>Mishandling of parshios that occur inside a pasuk: Not associating teivos properly to each one of the 
            parshios in question. This also has an effect on word counts and gematria calculations per pasuk. Examples 
            where this occurs are: parshios of aseres hadibros in Yisro and Va'eschanan and also Shmuel I 6:17, etc.</li>
          <li>Yerushalayim with esnachta: there are dozens of times where this doesn't seem to get recorded properly 
            and therefore can affect searches which include trop and will also affect the way a pasuk is broken down 
            in the Taam Parser.</li>
        </ul>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default IssuesGeneral;