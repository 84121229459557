import React, { Component } from 'react';

class Inspiration extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p>
          במשך מאות ואלפי שנים, רבותינו מימי התנאים והאמוראים ועד ימינו הפצירו אותנו להעמק בתורה.
          השורות הבאות שימשו השראה ל<b>בייס-הספר</b>:
        </p>
        <p style={{direction: 'rtl', textAlign: 'right'}}>
          <b>מסכת אבות ה:כב,כג</b><br />
          הפך בה והפך בה, דכלא בה.....לפום צערא אגרא
        </p>
        <p style={{direction: 'rtl', textAlign: 'right'}}>
          <b>גמ' מנחות כט:</b> <br />
          אדם אחד יש שעתיד להיות בסוף כמה דורות ועקיבא בן יוסף שמו שעתיד <b>לדרוש על כל קוץ וקוץ</b> תילין תילין של הלכות
        </p>
        <p>
          הכלים ש<b>בייס-הספר</b> מציע לצלילה עמוקה זו הם חלק מתהליך שנקרא <b>כריית טקסטים</b>
          - כריית מילים לדפוסים משמעותיים ונאגטים חשובים של מידע.
        </p>
        <p>
          זה קורא לזכור את דברי ה<b>נצי"ב</b>  בהקדמתו ל<b>העמק דבר </b> 
          אשר כמעט ומיישם את אנלוגיית הכרייה לתורה עצמה.
        </p>
        <p>
          הוא כותב שישנן דרגות שונות של הבנת התורה. עצם העובדה שהוא הורה למשה לתרגם את התורה לשבעים לשון מלמדת אותנו שיש אפילו ערך ללמוד
          את התורה בתרגום, גם אם בערך פני השטח. עם זאת, ערך הליבה של לימוד התורה מתחיל כאשר אנו יכולים 
          לבחון ולבדוק כל אות אחת בתורה בצורתה המקורית. לא רק זאת, אלא שהתורה מתוארת כשירה, כך שאפילו כדי 
          לחלץ את משמעותה המוטמעת בפשטות כדי ליידע אותנו על הלכות, אגדה ומוסר של התורה, מחייבת עיון רב.
        </p>
        <p>
          אפילו מעל זה ישנה דרגה עמוקה יותר למי שמספיק מזל, כאשר רמזים מוטמעים בתנ"ך המשמשים לציין דבר מוסתר עמוק מעל ומעבר לפשט.
        </p>
        <p>
          על כך אומר ה<b>נצי"ב</b> שהפסוקים ב<b>משלי</b> באים ללמדנו:
        </p>
        <p style={{direction: 'rtl', textAlign: 'right'}}>
          <i>
            <b>(משלי ב:ד,ה) "אם תבקשנה ככסף וכמטמונים תחפשנה, אז תבין יראת ה' ודעת אלהים תמצא"</b>. פירוש: טבע דבר ה' הוא הפשט המכוסה, ונצרך לחקירה יפה לעמוד עליו — הרי זה דומה לכסף המכוסה בעפרורית מוצא הכסף, ונצרך למצרף והשתדלות להוציא את הכסף מזוקק. אבל סודות התורה, שהיא סגולתה — הרי זה כמו שידוע שיש מטמונים באדמה, וכמו שאמר אשמדאי (גיטין דף ס"ח) על ההוא גברא דהוי יתיב אבי גזא דמלכא, היינו אוצר זהב וכסף טמון, ואין מקום על־פי שכל והשערה לדעת מקום המטמון, אם לא שמתגלה בסיעתא דשמיא
          </i>
        </p>
        <p>
          שהפשט המשובץ של התנ"ך הוא ככסף ממוקש המכוסה בסלע ומשקע של עפרות השטח המקוריות שלו, שם צריך להשקיע מאמץ על התכת וחילוץ הכסף הטהור שלה.
          אולם הידע הנסתר העמוק שהוא הנכס היקר ביותר בתורה, ורק נרמז אליו, משווה לאוצר נסתר שנכלא ונקבר עמוק באדמה.
          אנשים יודעים שדבר כזה קיים, אך אין דרך הגיונית לדעת היכן הוא שוכן אלא אם כן הוא מתגלה באמצעות סיעתא דשמיא.
          ניתנת משל על כך שמציג מעשה שבה מישהו לא יודע שהוא בעצם יושב מעל מקום המחסן הסודי של המלך מונח עמוק מתחת לאדמה.
          <i>
            (אולי בעולם הטבע, זה דומה לאמה של עפרות היושבת עמוק מתחת לאדמה והיתה כרוך בטכניקות כרייה מתקדמות ומסוכנות כדי לחלץ)
          </i>.
        </p>
        <p>
          חיפוש הפשט כחילוץ כסף לא מעובד יביא מישהו להבנת יראת ה' שמיידעת אותנו
          &nbsp;&nbsp; את אגדה, הלכה, ומוסר. חיפוש אחר סודות כאילו הם אוצרות קבורות יביא אפילו
          &nbsp;&nbsp; לגילוי וחשיפת "דעת אלקים", הסודות הנסתרות של התורה. כמו שנאמר ב<b>סנהדרין צט</b> אם אדם
          &nbsp;&nbsp; עמל בתורה אז התורה תעבור בשבילו במקומות אחרים. רש"י מסביר שם שהתורה עצמה
          &nbsp;&nbsp; יעזור לו להבין את עומק דבריו.
        </p>
        <p>
          לבסוף, אף על פי שיש לנו כלים מתקדמים רבים בימינו לשליפת התורה, עלינו לשמור בלבינו "לפום צערא אגרא".
        </p>
        <p>
          העמלה בתורה שהוזכרה לעיל תניב תגמולים גדולים באותה צורה שבה אנשים בזמני בהלות זהב מפורסמים
          &nbsp;&nbsp;  אמצו תנאי מזג אוויר קיצוניים מסכני חיים במרדף אחר העושר. כמה יותר כן
          זה נכון כאשר אנו רודפים אחר האוצר הגדול מכולם.
        </p>
        <p>
          אז ברוך הבא ל<b>בייס-הספר</b>, תהנו מהכלים שאנו מציעים כאן ו.....<b>כרייה שמח!</b>
        </p>

      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <p>
          Over the centuries and millennia, our rabbis from the days of the תנאים and אמוראים through the present 
          have engendered us to take a deep dive into the Torah.  The following lines have served as inspiration for <b>Base HaSefer</b>:
        </p>
        <p style={{direction: 'rtl', textAlign: 'right'}}>
          <b>מסכת אבות ה:כב,כג</b><br />
          הפך בה והפך בה, דכלא בה.....לפום צערא אגרא
        </p>
        <p style={{direction: 'rtl', textAlign: 'right'}}>
          <b>גמ' מנחות כט:</b> <br />
          אדם אחד יש שעתיד להיות בסוף כמה דורות ועקיבא בן יוסף שמו שעתיד <b>לדרוש על כל קוץ וקוץ</b> תילין תילין של הלכות
        </p>
        <p>
          The tools <b>Base HaSefer</b> offers to help with this deep dive are part of a process called <b>text mining</b> 
          - the mining of words for meaningful patterns and valuable nuggets of information.
        </p>
        <p>
          This calls to mind the words of the <b>נצי"ב</b> in his introduction to <b>העמק דבר</b> which very nearly applies 
          the mining analogy to the Torah itself.
        </p>
        <p>
          He states that there are different levels of understanding the Torah.  The very fact that 'ה commanded Moshe 
          to translate the Torah to 70 languages teaches us that there is even a value to studying the Torah in 
          translation, albeit a surface value.  However, the core value of Torah study begins when we can examine and 
          scrutinize each individual letter of the Torah in its original form.  Not only that, but the Torah is 
          described as a שירה, so that even to extract its embedded simple meaning to inform us of the הלכות, אגדה and מוסר of the Torah requires עיון רב.
        </p>
        <p>
          Even above that, there is a deeper level for those who are fortunate enough, where hints are embedded in Tanach 
          that serve to indicate something deeply hidden over and above the פשט.
        </p>
        <p>
          Upon this, the <b>נצי"ב</b> says that the פסוקים in <b>משלי</b> come to teach us:
        </p>
        <p style={{direction: 'rtl', textAlign: 'right'}}>
          <b>(משלי ב:ד,ה) "אם תבקשנה ככסף וכמטמונים תחפשנה, אז תבין יראת ה' ודעת אלהים תמצא"</b>. פירוש: טבע דבר ה' הוא הפשט המכוסה, ונצרך לחקירה יפה לעמוד עליו — הרי זה דומה לכסף המכוסה בעפרורית מוצא הכסף, ונצרך למצרף והשתדלות להוציא את הכסף מזוקק. אבל סודות התורה, שהיא סגולתה — הרי זה כמו שידוע שיש מטמונים באדמה, וכמו שאמר אשמדאי (גיטין דף ס"ח) על ההוא גברא דהוי יתיב אבי גזא דמלכא, היינו אוצר זהב וכסף טמון, ואין מקום על־פי שכל והשערה לדעת מקום המטמון, אם לא שמתגלה בסיעתא דשמיא
        </p>
        <p>
          That the embedded פשט of the Tanach is like mined silver that is covered with the rock and sediment of its 
          original surface ore where one must expend effort on smelting and extracting its pure silver.  However the 
          deep hidden knowledge that is the Torah's most valuable asset, and is only hinted to, is compared to a hidden 
          treasure locked up and buried deeply in the ground. People know that such a thing exists but there's no 
          logical way to know where it lies unless it is revealed via סיעתא דשמיא.  A משל is given for this 
          (in <b>גיטין דף ס"ח</b>) which presents a מעשה where someone doesn't know he's actually sitting above the 
          very place where the king's secret storehouse rests deep underground. <i>(Perhaps in the natural world, this is 
            akin to the mother-lode of ore which sits deep beneath the earth and would involve advanced and often 
            danger-ridden mining techniques to extract)</i>.
        </p>
        <p>
          Seeking out פשט as if extracting unprocessed silver will bring one to understand 'יראת ה which informs us 
          of הלכות, אגדה and מוסר.  Searching for סודות as if they were buried treasures will even lead to the 
          discovery and unearthing of "דעת אלקים", the hidden secrets of the Torah.  As it says in<b> סנהדרין צט</b>, if a person 
          toils in Torah then the Torah will toil for him in other places.  Rashi explains there that the Torah itself 
          will help him understand the depth of its words.
        </p>
        <p>
          Finally, even though we have many advanced tools these days with which to mine the Torah, one must keep in 
          mind "לפום צערא אגרא".
        </p>
        <p>
          The עמלה בתורה mentioned above will yield great rewards in the same way that people in times of famous gold 
          rushes have braved life-threatening and extreme weather conditions in pursuit of riches. How much more so is 
          it true as we pursue the greatest treasure of all.
        </p>
        <p>
          So welcome to <b>Base HaSefer</b>, enjoy the tools we offer here and.....<b>happy mining!</b>
        </p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default Inspiration;