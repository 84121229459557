import React, { Component } from 'react';

class FeaturesSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p /><h5>תמהיל ייחודי של תכונות</h5><p />
        אנו שואפים להציע שילוב של תכונות ייחודיות שקשה למצוא במקומות אחרים.&nbsp;&nbsp;
        נכון להיום אנו יכולים להשיג זאת על ידי הצעת חיפוש בסיסי פשוט אך עוצמתי וחיפוש מתקדם חסון ביותר.&nbsp;&nbsp;
        עם בייס-הספר, ניתן כבר לגשת לדברים הבאים:
        <br /><br />
        <ul>
          <li><b>יכולות חיפוש חזקות</b> - אפשר לחפש נקודות ואפילו טעמי המקרא. לא רק שאפשר לחפש לפי שורש, אלא אפשר אפילו לאתר אנאגרמים וביטויים רגולריים</li>
          <li><b>גמיש</b> - מספר בלתי מוגבל של מונחים ומספר בלתי מוגבל של תווים חופשיים</li>
          <li><b>היקף</b> - מזהה לא רק זרמי תווים - למשל מספרים, מבני פסוק ופרשה, מבנה טעמי מקרא</li>
          <li><b>דיוק</b> - הגדרות מרחק, תדירות, תנאי רמת מילים ורמת פסוק, מסנני טווח לפי טווחי פסוקים ולפי קטעים מסוימים</li>
          <li><b>ממשק משתמש עשיר ואינטואיטיבי</b> - התאמת גופן, מניפולציה של רשימות תוצאות, מספר כרטיסיות, תוכן שניתן לשנות את הגודל</li>
          <li><b>ניתן להרחבה ולשימוש חוזר</b> - שמירת וטעינת הגדרות חיפוש ורשימות תוצאות, העתקה והדבקה עוצמתיים לאפליקציות אחרות, שילוב עם תכונות אתר אחרות, למשל דפדפן התנ"ך או המנתח טעמים</li>
        </ul>

      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <p /><h5>A Unique Mix of Features</h5><p />
        We strive to offer a mix of unique features that are hard to find elsewhere.&nbsp;&nbsp;
        To date, we are able to accomplish this by offering a simple, yet powerful basic search and an extremely robust advanced search.&nbsp;&nbsp;
        With Base HaSefer, one can already access the following:
        <br /><br />
        <ul>
          <li><b>Robust search capabilities</b> - one can search for nekudos and even taamei hamikra. Not only is it possible to search by shoresh, but one can even detect anagrams and regular expressions</li>
          <li><b>Flexible</b> - unlimited number of terms and an unlimited number of wildcards</li>
          <li><b>Scope</b> - recognizes not merely character streams - eg numerics, pasuk parsha struct, taam structure</li>
          <li><b>Precision</b> - distance defs, frequency, word-level and pasuk-level conditions, range filters by pasuk range and by named sections</li>
          <li><b>Rich and intuitive user interface</b> - font adjust, manipulate result lists, multiple tabs, resizable content</li>
          <li><b>Extensible, reusable</b> - save and load search definitions and result lists, powerful copy and paste to other apps, integrate with other site features eg. viewers</li>
        </ul>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default FeaturesSearch;