import React, { Component } from 'react';
import styles from './css/App.css'; //for customizing reactStrap styles
import * as properties from './config/properties.js';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withLocalize, Translate } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import translations from "./json/translations.json";
import {withRouter} from 'react-router-dom';
import './css/AboutDialog.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { Rnd } from "react-rnd";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

import Mission from './dialogTexts/about/Mission';
import Inspiration from './dialogTexts/about/Inspiration';
import WhoWillBenefitInstitutions from './dialogTexts/about/WhoWillBenefitInstitutions';
import WhoWillBenefitIndividuals from './dialogTexts/about/WhoWillBenefitIndividuals';
import FeaturesSearch from './dialogTexts/about/FeaturesSearch';
import FeaturesContent from './dialogTexts/about/FeaturesContent';
import FeaturesGeneral from './dialogTexts/about/FeaturesGeneral';
import FeaturesServices from './dialogTexts/about/FeaturesServices';
import FeaturesFuture from './dialogTexts/about/FeaturesFuture';
import Sources from './dialogTexts/about/Sources';
import IssuesReport from './dialogTexts/about/IssuesReport';
import IssuesTechnical from './dialogTexts/about/IssuesTechnical';
import IssuesSpecific from './dialogTexts/about/IssuesSpecific';
import IssuesText from './dialogTexts/about/IssuesText';
import IssuesGeneral from './dialogTexts/about/IssuesGeneral';
import Team from './dialogTexts/about/Team';
import HistoryBirth from './dialogTexts/about/HistoryBirth';
import HistoryReboot from './dialogTexts/about/HistoryReboot';
import HistoryTaryag from './dialogTexts/about/HistoryTaryag';
import HistoryHelp from './dialogTexts/about/HistoryHelp';
import HistoryBHS from './dialogTexts/about/HistoryBHS';
import HistoryBeta from './dialogTexts/about/HistoryBeta';
import HistoryFull from './dialogTexts/about/HistoryFull';
import HakarasHatov from './dialogTexts/about/HakarasHatov';
import TermsOfService from './dialogTexts/about/TermsOfService';
import VersionInformation from './dialogTexts/about/VersionInformation';
import ContactInfo from './dialogTexts/about/ContactInfo';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil

class AboutDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      panelIsOpenData: {
        versionInformation: false,
        mission: false,
        inspiration: false,
        team: false,
        termsOfService: false,
        contact: false,
        hakarasHatov: false,
        whoWillBenefit_institutions: false,
        whoWillBenefit_individuals: false,
        history_birth: false,
        history_reboot: false,
        history_taryag: false,
        history_help: false,
        history_bhs: false,
        history_beta: false,
        history_full: false,
        issues_technical: false,
        issues_specific: false,
        issues_text: false,
        issues_general: false,
        issues_report: false,
        sources: false,
        features: false,
        features_search: false,
        features_content: false,
        features_general: false,
        features_services: false,
        features_future: false,
      },
      zIndex: 801
    };

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true }
    });

    this.props.addTranslation(translations);

    this.toggleAboutDialog = this.toggleAboutDialog.bind(this);

  }

  handleCopyLink() {
    var params = ["tab=" + this.props.tabIndex];
    this.props.handleCopyLink("about", params, this.props.isFullScreen(this.props.x, this.props.y, this.props.heightNum,this.props.widthNum));
  }

  handleFullscreen(){
    const x = 0;
    const y = 0;
    const heightNum = window.innerHeight;
    const widthNum = window.innerWidth;
    this.props.setSize(widthNum, heightNum, x, y)
  }
  handleRestoreScreenSize(){
    this.props.resetPosition()
  }

  handleTitleDoubleClick(e){
    //if clicked on button, cancel
    var currElem = e.target;
    while(!currElem.className.split(" ").includes("about-dialog-title")){
      if(currElem.className.split(" ").includes("about-dialog-open-close-all-button")){
        return false;
      }
      currElem = currElem.parentElement;
    }
    //change size
    const isFullscreen = this.props.widthNum === window.innerWidth && this.props.heightNum === window.innerHeight && this.props.x === 0 && this.props.y === 0;
    if(isFullscreen) {
      this.handleRestoreScreenSize();
    } else {
      this.handleFullscreen();
    }
  }

  toggleAboutDialog(boolean) {
      this.setState({ aboutDialogOpen: boolean });
  }

  toggleExpansionPanel(panelName){
    var panelIsOpenData = this.state.panelIsOpenData;
    panelIsOpenData[panelName] = !panelIsOpenData[panelName];
    this.setState({
      panelIsOpenData
    })
  }

  expandAll(){
    var panelIsOpenData = this.state.panelIsOpenData
    var panels = Object.keys(panelIsOpenData);
    for(let i = 0; i < panels.length; i++){
      panelIsOpenData[panels[i]] = true;
    }
    this.setState({
      panelIsOpenData
    })
  }

  collapseAll(){
    var panelIsOpenData = this.state.panelIsOpenData
    var panels = Object.keys(panelIsOpenData);
    for(let i = 0; i < panels.length; i++){
      panelIsOpenData[panels[i]] = false;
    }
    this.setState({
      panelIsOpenData
    })
  }
  
  // NO LONGER PULLING TEXTS FROM .TXT FILES
  // fetchContentFile(topicName, languageCode) { 
  //   const file = require("./dialogTexts/about/" + topicName + "_" + languageCode + ".txt"); //need an extension that's not HTML since in that case, the require() will generate an error since it's not expecting an HTML fragment

  //     var sectionText;
  //     var rawFile = new XMLHttpRequest();
  //     rawFile.open("GET", file, false);
  //     rawFile.onreadystatechange = () => {
  //         if (rawFile.readyState === 4) {
  //             if (rawFile.status === 200 || rawFile.status == 0)
  //                 sectionText = rawFile.responseText;
  //         }
  //     };
  //     rawFile.send(null);
  //     return sectionText;
  // }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.isOpen !== this.props.isOpen){
      if(!this.props.isOpen){
        this.collapseAll();
      } else {

        this.showOnTop();

        if (this.props.isFirstTimeOpened) {
            if (this.props.aboutOpen) //indicates that launched about popup directly from URL
                this.expandAll();
            if (this.props.initialFullScreen && "TY".indexOf(this.props.initialFullScreen.toUpperCase()) >= 0)
                this.handleFullscreen();
            this.props.extSetFirstTimeOpened();
        }

      }
    }
    else if (util.isForceOpenAboutDialog) //popup has not flipped from open/close and v.v.
        this.showOnTop();
  }

  onClose(){
    this.props.onClose();
  }

  findHighestZIndex(elem) {
    var elems = document.getElementsByTagName("*");
    var highest = 0;
    for (var i = 0; i < elems.length; i++)
    {
      var zindex=document.defaultView.getComputedStyle(elems[i],null).getPropertyValue("z-index");
      if ((zindex > highest) && (zindex != 'auto') && (zindex != 9998) && (zindex != 100000090))
      {
        highest = zindex;
      }
    }
    return parseFloat(highest);
  }

  showOnTop(){
    this.setState({
      zIndex: this.findHighestZIndex() + 1
    })
  }

  handleClick(e){
    this.showOnTop();
  }
  
  render() {
    const { classes } = this.props;
    const aboutTR = this.props.translate("about.caption");
    const openAllTR = this.props.translate("openAll");
    const closeAllTR = this.props.translate("closeAll");
    const fullScreen = this.props.translate("fullScreen");
    const restore = this.props.translate("restore");
    const close = this.props.translate('close');
    const copyLink = this.props.translate('copyLink');

    const version = this.props.translate("version");
    const mission = this.props.translate("mission");
    const inspirationTR = this.props.translate("inspiration");
    const versionInformation = this.props.translate("versionInformation");
    const team = this.props.translate("team");
    const history = this.props.translate("history");
    const timeline = this.props.translate("timeline");
    const contact = this.props.translate("contact");
    const hakarasHatov = this.props.translate("hakarasHatov");

    const history_birth = this.props.translate("history_birth");
    const history_reboot = this.props.translate("history_reboot");
    const history_taryag = this.props.translate("history_taryag");
    const history_help = this.props.translate("history_help");
    const history_bhs = this.props.translate("history_bhs");
    const history_beta = this.props.translate("history_beta");
    const history_full = this.props.translate("history_full");

    const issues_technical = this.props.translate("issues_technical");
    const issues_specific = this.props.translate("issues_specific");
    const issues_text = this.props.translate("issues_text");
    const issues_general = this.props.translate("issues_general");
    const issues_report = this.props.translate("issues_report");

    const sources = this.props.translate("sources");

    const features = this.props.translate("features");
    const features_search = this.props.translate("features_search");
    const features_content = this.props.translate("features_content");
    const features_general = this.props.translate("features_general");
    const features_services = this.props.translate("features_services");
    const features_future = this.props.translate("features_future");

    const whoWillBenefit = this.props.translate("whoWillBenefit");
    const whoWillBenefit_institutions = this.props.translate("whoWillBenefit_institutions");
    const whoWillBenefit_individuals = this.props.translate("whoWillBenefit_individuals");

    const termsOfService = this.props.translate("termsOfService");
    const issues = this.props.translate("issues");
    const issuesFull = this.props.translate("issuesFull");

    const isHebrew = this.props.activeLanguage && this.props.activeLanguage.code === "he";
    const languageCode = isHebrew ? "he" : "en";

    // NO LONGER USING INNERHTML PROPERTY TO POPULATE TEXTS
    // const versionInformationInnerHTML = this.fetchContentFile("versionInformation", languageCode);
    // const missionInnerHTML = this.fetchContentFile("mission", languageCode);
    // const inspirationInnerHTML = this.fetchContentFile("inspiration", languageCode);
    // const teamInnerHTML = this.fetchContentFile("team", languageCode);
    // const termsOfServiceInnerHTML = this.fetchContentFile("termsOfService", languageCode);
    // const contactInnerHTML = this.fetchContentFile("contact", languageCode);
    // const hakarasHatovInnerHTML = this.fetchContentFile("hakarasHatov", languageCode);

    // const history_birthInnerHTML = this.fetchContentFile("historyBirth", languageCode);
    // const history_rebootInnerHTML = this.fetchContentFile("historyReboot", languageCode);
    // const history_taryagInnerHTML = this.fetchContentFile("historyTaryag", languageCode);
    // const history_helpInnerHTML = this.fetchContentFile("historyHelp", languageCode);
    // const history_bhsInnerHTML = this.fetchContentFile("historyBHS", languageCode);
    // const history_betaInnerHTML = this.fetchContentFile("historyBeta", languageCode);

    // const issues_technicalInnerHTML = this.fetchContentFile("issuesTechnical", languageCode);
    // const issues_specificInnerHTML = this.fetchContentFile("issuesSpecific", languageCode);
    // const issues_textInnerHTML = this.fetchContentFile("issuesText", languageCode);
    // const issues_generalInnerHTML = this.fetchContentFile("issuesGeneral", languageCode);
    // const issues_reportInnerHTML = this.fetchContentFile("issuesReport", languageCode);

    // const sourcesInnerHTML = this.fetchContentFile("sources", languageCode);

    // const features_searchInnerHTML = this.fetchContentFile("featuresSearch", languageCode);
    // const features_contentInnerHTML = this.fetchContentFile("featuresContent", languageCode);
    // const features_generalInnerHTML = this.fetchContentFile("featuresGeneral", languageCode);
    // const features_servicesInnerHTML = this.fetchContentFile("featuresServices", languageCode);
    // const features_futureInnerHTML = this.fetchContentFile("featuresFuture", languageCode);

    // const whoWillBenefit_institutionsInnerHTML = this.fetchContentFile("whoWillBenefitInstitutions", languageCode);
    // const whoWillBenefit_individualsInnerHTML = this.fetchContentFile("whoWillBenefitIndividuals", languageCode);

    var directionStyling = {direction: 'ltr', textAlign: 'left'};
    if(isHebrew){
        directionStyling = {direction: 'rtl', textAlign: 'right'};
    }
    return <Rnd
      size={{ width: `${this.props.widthNum}px`,  height: `${this.props.heightNum}px` }}
      position={{ x: this.props.x, y: this.props.y }}
      onDragStop={(e, d) => { 
        this.props.setPosition(d.x, d.y)
        // this.setState({ x: d.x, y: d.y }) 
      }}
      onClick={(e) => {this.handleClick()}}
      onResize={(e, direction, ref, delta, position) => {
        this.props.setSize(
          parseInt(ref.style.width.slice(0, -1)),
          parseInt(ref.style.height.slice(0, -1)),
          position.x,
          position.y
        )
        // this.setState({
        //   widthNum: parseInt(ref.style.width.slice(0, -1)),
        //   heightNum: parseInt(ref.style.height.slice(0, -1)),
        //   // columnDefs: this.getColumnDefs(),  //switched from ag grid to html table for easier editting
        //   ...position,
        // }, () => {
        //   // this.setRowHeights()
        // });
      }}

      style = {{
        background: 'black !important',
        visibility: this.props.isOpen ? 'visible' : 'hidden',
        boxShadow: '0 6px 12px rgba(255,255,255,0.64), 0 6px 12px rgba(255,255,255,0.92)',
        borderRadius: '5px',
        zIndex: this.state.zIndex,
        direction: directionStyling.direction,
        textAlign: directionStyling.textAlign
      }}
      className="about-rnd"
      cancel = '.about-dialog-content-root, .about-dialog-resize-buttons'
      minWidth={375}
      minHeight={220}
      bounds='#root'
    >

    <div className="about-dialog-resize-buttons"
      style={{
        position: 'absolute',
        right: isHebrew ? 'unset' : 0,
        left: isHebrew ? 0 : 'unset',
        direction: isHebrew ? 'ltr' : 'rtl',
        top: 0,
        borderBottomLeftRadius: isHebrew ? 0 : '3px',
        borderBottomRightRadius: isHebrew ? '3px' : 0,
      }}
    >
      <Button size="small" onClick={() => {this.onClose()}} color="primary" className="about-dialog-resize-button" title={close}> 
        <CloseIcon />
      </Button>
      <Button className="about-dialog-resize-button" onClick={() => {this.handleCopyLink()}} title={copyLink}>
        <LinkIcon />
      </Button>
      <Button className="about-dialog-resize-button" onClick={() => {this.handleFullscreen()}} title={fullScreen}>
        <FullscreenIcon />
      </Button>
      <Button className="about-dialog-resize-button" onClick={() => {this.handleRestoreScreenSize()}} title={restore}> 
        <FullscreenExitIcon />
      </Button>
    </div>

    <DialogTitle id="about-dialog-title"
        classes={{root: "about-dialog-title"}}
        onDoubleClick={(e) => {this.handleTitleDoubleClick(e)}}
      >
        <div style={{position: 'relative'}}>
          <div className="about-dialog-title-text-parent">
            {aboutTR}
          </div>
          <div className="about-dialog-expand-colapse-parent"
            style={{
              right: isHebrew ? "unset" : "0",
              left: isHebrew ? "0" : "unset",
              fontSize: isHebrew ? "15px" : "unset",
            }}
          >
            <Button size="small" variant="outlined" onClick={() => {this.expandAll()}} className="about-dialog-open-close-all-button"
              style={{
                fontSize: isHebrew ? "inherit" : "13px",
              }}
              onDoubleClick={() => {return false}}
            >
              <AddBoxIcon />&nbsp;{openAllTR}
            </Button>
            <Button size="small" variant="outlined" onClick={() => {this.collapseAll()}} className="about-dialog-open-close-all-button"
              style={{
                fontSize: isHebrew ? "inherit" : "13px",
              }} 
              onDoubleClick={() => {return false}}
            >
              <IndeterminateCheckBoxIcon />&nbsp;{closeAllTR}
            </Button>
          </div>
        </div>

      </DialogTitle>

      <DialogContent classes={{
        root: 'about-dialog-content-root'
      }}>

      <Tabs selectedIndex={this.props.tabIndex} onSelect={(i) => this.props.extSetAboutTabIndex(i)}>
          <div className="about-dialog-tabs-container" style={{height: `${this.props.heightNum - 88}px`}}>

            {/* Search for tab panels by id: "about-panel-{snake-name}"  */}
            <div>
              <TabList>
                <Tab>{mission}</Tab>
                <Tab>{whoWillBenefit}</Tab>
                <Tab>{features}</Tab>
                <Tab>{sources}</Tab>
                <Tab>{issues}</Tab>
                <Tab>{team}</Tab>
                <Tab>{history}</Tab>
                <Tab>{hakarasHatov}</Tab>
                <Tab>{termsOfService}</Tab>
                <Tab>{versionInformation}</Tab>
                <Tab>{contact}</Tab>
              </TabList>
            </div>

            <div className="about-dialog-tab-panels-parent">
              <TabPanel id="about-panel-mission">

                <h3>{mission}</h3>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('mission')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.mission}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{mission}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <Mission langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('inspiration')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.inspiration}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{inspirationTR}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <Inspiration langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

              </TabPanel>

              <TabPanel id="about-panel-who-will-benefit">
                <h3>{whoWillBenefit}</h3>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('whoWillBenefit_institutions')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.whoWillBenefit_institutions}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{whoWillBenefit_institutions}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <WhoWillBenefitInstitutions langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('whoWillBenefit_individuals')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.whoWillBenefit_individuals}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{whoWillBenefit_individuals}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <WhoWillBenefitIndividuals langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </TabPanel>

              <TabPanel id="about-panel-features">
                  <h3>{features}</h3>
                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('features_search')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.features_search}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{features_search}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <FeaturesSearch langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('features_content')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.features_content}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{features_content}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <FeaturesContent langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('features_general')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.features_general}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{features_general}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <FeaturesGeneral langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('features_services')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.features_services}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{features_services}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <FeaturesServices langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('features_future')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.features_future}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{features_future}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <FeaturesFuture langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </TabPanel>

              <TabPanel id="about-panel-sources">
                <h3>{sources}</h3>
                <Typography className="about-dialog-expansion-panel">
                  <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                    <Sources langCode={isHebrew ? "he" : "en"} />
                  </div>
                </Typography>
              </TabPanel>

              <TabPanel id="about-panel-known-issues">
                <h3>{issuesFull}</h3>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('issues_report')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.issues_report}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{issues_report}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                      <IssuesReport langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('issues_technical')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.issues_technical}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{issues_technical}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <IssuesTechnical langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('issues_specific')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.issues_specific}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{issues_specific}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <IssuesSpecific langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('issues_text')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.issues_text}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{issues_text}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <IssuesText langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('issues_general')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.issues_general}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{issues_general}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <IssuesGeneral langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

              </TabPanel>

              <TabPanel id="about-panel-team">
                <h3>{team}</h3>
                <Typography className="about-dialog-expansion-panel">
                  <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                    <Team langCode={isHebrew ? "he" : "en"} />
                  </div>
                </Typography>
              </TabPanel>

              <TabPanel id="about-panel-history">
                <h3>{timeline}</h3>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('history_birth')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.history_birth}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{history_birth}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <HistoryBirth langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('history_reboot')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.history_reboot}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{history_reboot}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <HistoryReboot langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('history_taryag')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.history_taryag}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{history_taryag}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <HistoryTaryag langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('history_help')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.history_help}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{history_help}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <HistoryHelp langCode={isHebrew ? "he" : "en"} /> 
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('history_bhs')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.history_bhs}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{history_bhs}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <HistoryBHS langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('history_beta')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.history_beta}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{history_beta}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <HistoryBeta langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  onChange={() => this.toggleExpansionPanel('history_full')}
                  classes={{
                    root: "about-dialog-expansion-panel"
                  }}
                  expanded={this.state.panelIsOpenData.history_full}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      root: "about-dialog-expansion-panel-summary"
                    }}
                  >
                    <Typography variant="h6" className={classes.heading}>{history_full}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                        <HistoryFull langCode={isHebrew ? "he" : "en"} />
                      </div>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </TabPanel>

              <TabPanel id="about-panel-hakaras-hatov">
                <h3>{hakarasHatov}</h3>
                <Typography className="about-dialog-expansion-panel">
                  <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                    <HakarasHatov langCode={isHebrew ? "he" : "en"} />
                  </div>
                </Typography>
              </TabPanel>

              <TabPanel id="about-panel-terms-of-service">
                <h3>{termsOfService}</h3>
                <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                  <TermsOfService langCode={isHebrew ? "he" : "en"} />
                </div>
              </TabPanel>

              <TabPanel id="about-panel-versionInformation">
                <h3>{versionInformation}</h3>
                <b>Base HaSefer: {sessionStorage.getItem("version")}</b><br /><br />
                <Typography className="about-dialog-expansion-panel">
                  <div style={{fontSize: isHebrew ? "16px" : "unset"}} >
                    <VersionInformation langCode={isHebrew ? "he" : "en"} />
                  </div>
                </Typography>
              </TabPanel>

              <TabPanel id="about-panel-contact">
                <h3>{contact}</h3>
                <div style={{fontSize: isHebrew ? "16px" : "unset"}}>
                  <ContactInfo langCode={isHebrew ? "he" : "en"} />
                </div>
              </TabPanel>
            </div>

          </div>
        </Tabs>

        </DialogContent>
      </Rnd>

  }
}
AboutDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(withLocalize(AboutDialog)));