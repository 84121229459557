import React, { Component } from 'react';

class Login_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כניסה
              </span></u></b><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> לביס</span></u></b><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> הספר</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>נכון
            לעכשיו, אין שום
            תכונות של
            האתר מוגבלות
            על ידי כניסה,
            אך זה לא תמיד
            יהיה המצב.
            בשלב מסוים
            בעתיד, מומלץ
            להיכנס לפני
            השימוש
            במערכת.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><img src="helpDialogImages/image010.png" align="left" hspace={12} /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            שתלחץ על
            כפתור ה<b>כניסה </b>
            בסרגל הניווט,
            הקופץ משמאל
            יופיע במרכז
            המסך. הזן את
            שם המשתמש שלך
            (דוא"ל) ואת
            הסיסמה ולחץ על
            "<b>כניסה</b>".</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אם
            עדיין אינך
            משתמש רשום,
            תוכל ללחוץ על
            הכרטיסייה "<b>הרשמה</b>"
            והיא תפתח את
            הקופץ המופיע
            משמאל לקצה.
            הזן את הדוא"ל
            והסיסמה
            הרצויה. הזן
            גם את שמך
            המלא, מספר הטלפון
            והכתובת שלך (2
            שורות).</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            שנכנסת או
            נרשמת, תקבל
            הודעת אישור
            קצרה ואז תוכל
            להמשיך
            להשתמש
            במערכת.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כתובת
            הדוא"ל שלך
            תופיע כעת לצד
            כפתור <b>יציאה</b>.
            כשאתה לוחץ על
            הכפתור כדי
            להתנתק, תופיע
            הודעת אזהרה
            שתאבד את
            התוצאות
            הנוכחיות בהן
            השתמשת על
            המסכים מכיוון
            שהמערכת
            תרענן את
            עצמה.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal">&nbsp;</p>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b><u>Logging into Base HaSefer</u></b></p>
        <p className="MsoNormal">At the present time, no features of the site are restricted
          by login but that will not always be the case.&nbsp; At some future point, it will
          be recommended to log in prior to using the system.</p>
        <p className="MsoNormal"><img src="helpDialogImages/image010.png" align="right" hspace={12} /></p>
        <p className="MsoNormal">Once you click the <b>Login</b> button on the Navigation
          bar, the popup to the right will appear in the center of the screen.&nbsp; Enter
          your user name (email) and password and click “<b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כניסה</span></b><span dir="LTR" /><span dir="LTR" />” to login.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">If you are not yet a registered user, you may click on the “<b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הרשמה</span></b><span dir="LTR" /><span dir="LTR" />” tab and it will open the popup appearing
          to the far right.&nbsp; Enter your desired email and password.&nbsp; Also enter your full
          name, telephone number, and address (2 lines).</p>
        <p className="MsoNormal" align="right" style={{textAlign: 'right'}}>&nbsp;</p>
        <p className="MsoNormal">Once you have logged in or registered, you will receive a
          brief confirmation message and then you will be able to resume using the system.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">Your email address will now appear next to a <b>Logout </b>
          button.&nbsp; When you click that button to logout, a warning message will appear
          saying that you will lose any current results that you have been using on the
          screens as the system will refresh itself.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default Login_Instructions;