import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import { Button, Popover, Typography, MenuItem } from '@material-ui/core';
import translations from "./json/translations.json";
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

//PROPS USED: onChange, value, menuItems, activeLanguageCode, disabled, selectOrLoading, type

class PlacementSelector2D extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      open: false
    }

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });
    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}

    this.handleSelect = this.handleSelect.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleSelect(event) {
    if(event.target.value === undefined){
      event.target.value = 0
    }
    this.props.onChange(event);
    this.handleClose();
  }

  handleOpen(e) {
    this.setState({anchorEl: e.currentTarget, open: true});
  } 
  
  handleClose() {
    this.setState({anchorEl: null, open: false});
  }

  render() {
    const fakeThis = this;
    const anchorEl = this.state.anchorEl;
    var selectOrLoading = this.props.selectOrLoading
    var displayVal = selectOrLoading;
    var menuItems = this.props.menuItems
    if(["placementRendererRangeSefer", "taamParserSefer"].includes(this.props.type)){
      menuItems.map((item, key) => {
        menuItems[key].id = menuItems[key].seferId;
        menuItems[key].value = menuItems[key].seferName;
      })
    } else if (["placementRendererRangePerek", "taamParserPerek"].includes(this.props.type)) {
      menuItems.map((item, key) => {
        menuItems[key].id = menuItems[key].perekNum;
        menuItems[key].value = menuItems[key].perekValue;
      })
    } else if (["placementRendererRangePasuk", "taamParserPasuk"].includes(this.props.type)) {
      menuItems.map((item, key) => {
        menuItems[key].id = menuItems[key].pasukNum;
        menuItems[key].value = menuItems[key].pasukValue;
      })
    }

    const value = this.props.value
    var selectedItem = menuItems.find(item => {
      return item.id === value
    })
    if(selectedItem){
      displayVal = selectedItem.value
    }

    var isLetterLabel = false;
    if(["placementRendererRangePerek","placementRendererRangePasuk", "taamParserPerek", "taamParserPasuk", "number"].includes(this.props.type)){
      isLetterLabel = true;
    }

    var isTaamParser = false;
    if(["taamParserSefer", "taamParserPerek", "taamParserPasuk"].includes(this.props.type)){
      isTaamParser = true;
    }

    var directionStyling = {
      direction: 'ltr',
      textAlign: 'left'
    }
    if(localStorage.getItem("language") === 'he'){
      directionStyling = {
        direction: 'rtl',
        textAlign: 'right'
      }
    }

    var labelClassName = "placement-selector-2D-label";
    if(this.props.isTextComparator){
      labelClassName = "text-comparator-options-select-label"
    } else if(isLetterLabel){
      labelClassName = "placement-selector-2D-letter-label";
    } else if (this.props.isTanachViewer && this.props.isNamedSection){
      labelClassName = "placement-selector-2D-label-tanach-viewer-named-section"
    } else if (this.props.type === "textComparator"){
      labelClassName = "text-comparator-options-select-label"
    }

    var rowCount = 1;
    if(isLetterLabel){
      rowCount = Math.floor(Math.sqrt(menuItems.length));
    } else {
      rowCount = Math.ceil(Math.sqrt(menuItems.length*4));
    }
    if(this.props.isTextComparator){
      rowCount = 10;
    }
    if(rowCount > 20){
      rowCount = 20;
    }

    return <span className="placement-selector-2D-selector" style={{direction: directionStyling.direction}}>
      <Button className="placement-selector-2D-button" onClick={(e) => {this.handleOpen(e)}} disabled={this.props.disabled}>
        <Typography
          variant="h6"
          className={labelClassName}
          style={{
            color: `${this.props.disabled ? "#8a999e" : "black"}`,
            textAlign: directionStyling.textAlign,
            fontWeight: `${isTaamParser ? "bold" : "400"}`
          }}
        >
          {displayVal}</Typography >
          <ArrowDropDown className="placement-selector-2D-icon" style={{color: '#757575'}}/>

      </Button>

          <Popover
            open={this.state.open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            elevation={1}
            className="placement-selector-2D-popover"
          >
            {selectOrLoading && <MenuItem className="menuItemHeader" value={0} onClick={(e) => {fakeThis.handleSelect(e)}}>
              {selectOrLoading}
            </MenuItem>}
            <div className={"placement-selector-2D-columns-parent-rtl"}>
                {menuItems.map((menuItem, key) => {
                  var menuItemsArr = [];
                  for(let i = 0; i < rowCount; i++){
                    if(menuItems[key+i]){
                      menuItemsArr.push(
                        <MenuItem className={"placement-selector-2D-menuItem-rtl"} value={menuItems[key+i].id} onClick={(e) => {fakeThis.handleSelect(e)}}
                          style={{
                            direction: `${this.props.isTextComparator && localStorage.getItem('language') === "en" ? "ltr" : "rtl" }`,
                          }}
                        >{menuItems[key+i].value}</MenuItem>
                      )
                    }
                  }
                  if(key%rowCount === 0){
                    return <div>
                      {menuItemsArr}
                    </div>;
                  }
                  
                })}
            </div>
          </Popover>
        </span>;
       }
}

export default withLocalize(PlacementSelector2D);
