import React, { Component } from 'react';

class IncludeExclude_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif', color: 'black'}}>פרטים
              עבור כרטיסיית
              הגדרות פסוק:</span></b><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif', color: 'black'}}> (אלו
              הגדרות
              כלליות ואינן לכל
              ביטוי)</span></i></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>&nbsp;</p>
        <p className="MsoNormal"><span style={{fontFamily: '"Arial",sans-serif', color: 'black'}}><img width={664} height={200} id="Picture 27" src="helpDialogImages/pasukSettings.png" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שש
            התיבות
            המרובות-בחירות
            בעמוד זה
            קובעות לפי
            הפסוק אם
            אותיות,
            נקודות
            וטעמים
            מסוימים חייבים
            להיות נוכחים
            (הצד הימני)
            והאם אותיות, נקודות
            וטעמים
            מסוימים
            צריכים להיעדר
            (הצד השמאלי).</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>&nbsp;</p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>
התיבה שמתחת לתיבות מרובה-בחירה "לכלול ולהוציא" מכילה מפרטים למספר המילים, האותיות, הטעמים והנקודות שיש בכל פסוק, מילה או אות. המשתמש יכול גם לבחור טווח ספציפי עבור גימטריא ואתבש עבור כל פסוק, מילה או אות. ניתן לשנות את כל ההגדרות הללו באמצעות חלקת כפתורי קצה הטווח לנקודה הרצויה או לחיצה על תיבות הטקסט של הטווח והקלדה ישיר לערכי הטווח המספרי.
        </p>

      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b><span style={{color: 'black'}}>Details for the Pasuk
         Settings tab: </span></b><i><span style={{color: 'black'}}>(these are general
              settings and are not per term)</span></i></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><span style={{fontFamily: '"Arial",sans-serif', color: 'black'}}><img width={664} height={200} id="Picture 27" src="helpDialogImages/pasukSettings.png" /></span></p>
        <p className="MsoNormal">The six multi-select boxes on this page determine per pasuk
          whether certain letters, nekudos and taamim are required to be present (the
          right side) and whether certain letters, nekudos and taamim should not be
          present (the left side).</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">The box below the Include &amp; Exclude multi-select boxes contains specifications for the number of words, letters, taamim, and nekudos to be found in each
          pasuk, word or letter. The user can also choose a specific range for gematria and atbash for each pasuk, word or letter. All of these settings can be changed via sliding the range edge buttons to the desired point or clicking on the range text boxes and typing the numberic range entries directly.</p>
      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default IncludeExclude_Instructions;