import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';
import './css/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css'; //added by mje Dec 30 2018
import * as properties from './config/properties.js';

//import * as fs from 'file-system'
//import * as fs from 'fs-thenable'
//import { cert } from './ssl/basehasefer_com.crt';
//import { ca } from './ssl/basehasefer_com.ca-bundle';
//import { key } from './ssl/domain-key.txt';
function readFile(fileName) {
//    const response = await fetch(fileName);
//    const text = await response.text();
    var text = '';
    var rawFile = new XMLHttpRequest();
    rawFile.open("GET", fileName, false);
    rawFile.onreadystatechange = function ()
    {
        if(rawFile.readyState === 4)
        {
            if(rawFile.status === 200 || rawFile.status == 0)
            {
                var text = rawFile.responseText;
            }
        }
    }
    rawFile.send(null);

    alert(text);
    return text;
}

//mje - Oct 29, 2019 - for obsolete code pertinent to trial and error changes to get login/authorization to work with Auth) and Okta in Oct 2019, see version history of this file in Git

//the https - ssl certificate is only relevant to the production environment at basehasefer.com
if (properties.env.environmentName == 'jun-kee') {
    //see https://www.namecheap.com/support/knowledgebase/article.aspx/9705/33/installing-an-ssl-certificate-on-nodejs

    const fs = new FileReader(); //require('fs-thenable');
    const https = require('https');
    const http = require('http');

    const hostname = 'basehasefer.com';
    const httpsPort = 443;
    const httpPort = 80;

    let options = {
       cert: readFile('../public/basehasefer_com.crt'), //generated by Namecheap/Comodo/Sectigo
       ca: readFile('../public/basehasefer_com.ca-bundle'), //generated by Namecheap/Comodo/Sectigo
       key: readFile('../public/basehasefer_com.key') //copy of domain-key.txt generated by https://zerossl.com/free-ssl/#csr - this matched work in conjunction with the crt file above at https://decoder.link/matcher
    };

    //alert(options.key);

    const httpsServer = https.createServer(options, (req, res) => {
       res.statusCode = 200;
       res.setHeader('Content-Type', 'text/html');
       res.end("<h1>HTTPS server running</h1>");
    });

    const httpServer = http.createServer((req, res) => {
       res.statusCode = 301;
       res.setHeader('Location', 'https://${hostname}${req.url}');
       res.end(); // make sure to call send() or end() to send the response
    });

    httpsServer.listen(httpsPort, hostname);
    httpServer.listen(httpPort, hostname);
}

//this uses the installed https://www.npmjs.com/package/graceful-fs and is done in order to avoid production errors occurring in mid-March 2020 such as the following:
/*
    Error: EMFILE: too many open files, open 'C:\Work\TaryagAnalytics\projects-Git\code\basehaseferclient\basehasefer\build\static\media\historyHelp_he.0e4e3fc0.txt'
    Emitted 'error' event at:
        at lazyFs.open (internal/fs/streams.js:115:12)
        at FSReqWrap.oncomplete (fs.js:141:20)
*/
var realFs = require('fs')
var gracefulFs = require('graceful-fs')
gracefulFs.gracefulify(realFs)

// A function that routes the user to the right place
// after login

ReactDOM.render(<HashRouter><App /></HashRouter>,
 document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
