import React, { Component } from 'react';

class IssuesReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p>
          אנא דווח על שגיאות או באגים ל
          <b>info@taryaganalytics.com</b>
        </p>

      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <p>Please report any errors or bugs to <b>info@taryaganalytics.com</b>.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default IssuesReport;