import React, { Component } from 'react';

class AdvancedSettings_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><img width={328} height={280} src="helpDialogImages/image016.png" align="left" hspace={12} /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פרטי
              השטח הקבוע:</span></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            הקלדת בטויי
            חיפוש בסרגל בטויי
            החיפוש, האזור
            עבור ה<b>בורר
              בטויי חיפוש</b>
            יאוכלס
            אוטומטית
            במונחים אלה. לחיצה
            על אחד
            מהמונחים
            בתיבה זו תמקד
            את ההגדרות
            הדיאלוג
            המתקדמת
            הספציפיות
            לבטוי לאלה הרלוונטיים
            לבטוי שנבחר.&nbsp;לחיצה
            על בטוי תציג
            גם דגשי
            הגדרות עיקריים
            עבור בטוי זה
            באזור
            "לתצוגה
            בלבד". לחיצה
            על סמל העריכה
            משמאל לכמה
            מאותם דגשי הגדרות
            תאלץ את
            הכרטיסייה
            "נקודות
            וטעמים" להיבחר
            ולהופיע.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ההגדרות
            הבאות לכל מונח
            מופיעות
            באזור הקבוע:</span></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><img width={317} height={133} src="helpDialogImages/image017.png" align="left" hspace={12} /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לשלול?</span></b><span dir="LTR" /><span dir="LTR"><span dir="LTR" /> – </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מציין
            שיש למצוא
            התאמות רק
            כאשר הבטוי
            שנבחר
            וההגדרות
            המשויכות אליו
            <b><u>לא</u> </b>נמצא</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מבחן
              אותיות סוף?</span></b><span dir="LTR" /><span dir="LTR"><span dir="LTR" /> – </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>האם
            החיפוש צריך
            להיות רגיש אם
            האותיות </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מנצפ"ך</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
            הוזנו
            כאותיות
            סופיות או
            לא-סופיות.</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מספר
              מופעים</span></b><span dir="LTR" /><b><span dir="LTR"><span dir="LTR" /> –</span></b><span dir="LTR"> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אם
            הסכום הזה הוא
            &gt;= </span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />1</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> אז זה
            מציין שלפחות
            מספר זה של
            חזרות על המונח
            צריך להיות
            נוכח בפסוק
            כדי שייחשב
            כתוצאה.</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שינויות</span></b><span dir="LTR" /><b><span lang="HE" dir="LTR"><span dir="LTR" /> </span></b><b><span dir="LTR">–</span></b><span dir="LTR"> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לחיצה על
            זה תציע רשימה
            של
            טרנספורמציות
            אפשריות
            לבחור עבור
            הבטוי. <b>1)</b> כל <b>אנגרמה
            </b>של הבטוי או
            התבנית שהוזן
            ייחשב להתאמה.
            <b>2)</b> גם הבטוי
            עצמו וגם ה<b>הפך</b>
            של הבטוי
            ייחשבו
            להתאמה. <b>3) </b>אפילו
            אם </span><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שורש</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> לא
            נבחרה
            בהגדרות
            החיפוש
            הרגילות
            בסרגל הימני,
            בטוי זה ייחשב
            כשורש לצורכי
            החיפוש. <b>4) </b></span><b><span dir="LTR">Regex</span></b><span dir="RTL" /><b><span style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
            </span></b><span dir="LTR" /><span dir="LTR"><span dir="LTR" />–</span><span dir="RTL" /><span style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
          </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>סליחה
            שציינתי את זה
          </span><span dir="LTR" style={{fontFamily: '"Segoe UI Emoji",sans-serif'}}>😊</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> – האמרה
            הישנה אומרת <b><i>יש
                אנשים, כאשר
                הם מתמודדים
                עם בעיה,
                חושבים "אני
                יודע, אני
                אשתמש
                בביטויים רגילים".
                עכשיו יש להם
                שתי בעיות. </i></b>ביטויים
            רגילים (רעיון
            שתוכנה
            הושאלה מעולם הבלשנות)
            הם דרך
            עוצמתית
            והדוקה מאוד
            להורות למחשב
            להתאים דברים
            לדפוס מפורט
            ספציפי של אותיות,
            מספרים
            ותווים אחרים.
            עם זאת,
            החוקים
            והתחביר אינם
            נפוצים אפילו
            על ידי
            מתכנתים
            ותיקים למחשבים.
            ישנם שפע כלים
            באינטרנט
            המאפשרים לך
            לבנות "</span><span dir="LTR">regex</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />" באופן
            אינטואיטיבי. אנו
            עשויים בסופו
            של דבר לשמש
            בכמה עוזרי </span><span dir="LTR">regex</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> באתר בייס-הספר.
            דוגמא אחת
            מאוד פשוטה
            היא ש</span><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>נבוכד[נר]אצר
            </span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ייחשב
            להתאים גם ל</span><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>נבוכדנאצר</span></b><span dir="LTR" /><b><span lang="HE" dir="LTR"><span dir="LTR" /> </span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>וגם ל</span><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>נבוכדראצר</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>. הנה
            לך! עכשיו אתה
            יודע יותר
            מהאדם הממוצע.
            בחירה בזה
            תביא לכך
            שהמונח שהוזן
            יתפרש כ-</span><span dir="LTR">Regex</span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />.</span></p>
        <p className="MsoNormal">&nbsp;</p>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b>Details of the fixed area:</b><img width={328} height={280} src="helpDialogImages/image016.png" align="right" hspace={12} /></p>
        <p className="MsoNormal">Once you type search terms into the search term box, the
          area for the <b>search term selector</b> will automatically get populated with
          these terms.&nbsp; Clicking on any of the terms in this box will focus the advanced
          dialogue’s term-based settings to the ones pertinent to the selected term.&nbsp;
          Clicking on a term will also display major setting highlights for that term in
          the “for display only” area. Clicking on the edit icon to the left of some of
          those setting highlights will force Nekudos &amp; Taamim tab to be selected and
          displayed.&nbsp; The following actual per-term settings appear in the fixed area:</p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><img width={317} height={132} src="helpDialogImages/image017.png" align="right" hspace={12} /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>לשלול?</span></b><span dir="LTR" /><span dir="LTR" /> – indicates that matches should be found
          only when the selected term and associated settings are <b><u>not</u></b>
          present</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מבחן
              אותיות סוף?</span></b><span dir="LTR" /><span dir="LTR" /> – should the search be sensitive to
          whether <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מנצפ"ך</span><span dir="LTR" /><span dir="LTR" /> letters have been entered as end or
          non-end letters.</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מספר
              מופעים</span><span dir="LTR" /><span dir="LTR" /> –</b> if this amount is &gt; 1 then it indicates that at least
          this # of repetitions of the term need to be present in a pasuk for it to be
          considered a match.</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שינויות</span><span dir="LTR" /><span dir="LTR" /> –</b> clicking on this will offer a list
          of possible transformations to select for the term. <b>1)</b> any <b>anagram </b>
          of the term or pattern entered will be considered a match&nbsp; <b>2)</b> both the
          term itself and the <b>reverse</b> of the term will be considered a match&nbsp; <b>3)
          </b> even if <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שורש</span></b><span dir="LTR" /><span dir="LTR" /> is not selected in the standard search
          settings in the right-bar, this term will be considered a word-root for search
          purposes&nbsp; <b>4) Regex</b> – sorry I mentioned this <span style={{fontFamily: '"Segoe UI Emoji",sans-serif'}}>😊</span> – the old saying goes <b><i>Some
              people, when confronted with a problem, think “I know, I'll use regular
              expressions”.&nbsp; Now they have two problems.&nbsp; </i></b>Regular expressions (an
          idea that software borrowed from the world of linguistics) are a very powerful
          and terse way of instructing a computer to match things against a specific
          detailed pattern of letters, numbers and other characters.&nbsp; However, the rules
          and syntax are not commonly mastered even by veteran computer programmers.&nbsp;
          There are plenty of online tools that let intuitively let one build “regex”.&nbsp;
          Some regex helpers might eventually be incorporated into Base Hasefer.&nbsp; One
          very simple example to handle is that<b> </b><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>נבוכד[נר]אצר</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>will be considered
          a match against either <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>נבוכדנאצר</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>OR </b><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>נבוכדראצר</span></b><span dir="LTR" /><span dir="LTR" />.&nbsp; There you go! Now you know more than the
          average person.&nbsp; Selecting this will mean that the term entered shall be
          interpreted as a Regex.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default AdvancedSettings_Instructions;