import React, { Component, useState } from 'react';
import {Checkbox} from 'primereact/checkbox';
import { Select, MenuItem } from '@material-ui/core';

import { withLocalize } from "react-localize-redux"
import { Menu, Item, Separator,animation, Submenu,theme, MenuProvider,contextMenu } from 'react-contexify';

class TaamParserContextMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            xPos:0,// this.props.xPos+300,
            yPos:0,// this.props.yPos-600,
            showMenu: false,
            exactness:"",
            scope:"",
            clicked:false,
        }
        this.findHighestZIndex = this.findHighestZIndex.bind(this);
        this.showOnTop = this.showOnTop.bind(this);
        this.closeContextMenu = this.closeContextMenu.bind(this);
    }
    clicked(e){
        e.preventDefault()
    }
    closeContextMenu(){
        var searchObj = {structure:this.state.clicked,exactness:this.state.exactness,scope:this.state.scope}
        this.props.search(searchObj)
        this.props.closeContextMenu()
    }

    findHighestZIndex(elem) {
        var elems = document.getElementsByTagName("*");
        var highest = 0;
        for (var i = 0; i < elems.length; i++)
        {
          var zindex=document.defaultView.getComputedStyle(elems[i],null).getPropertyValue("z-index");
          if ((zindex > highest) && (zindex != 'auto') && (zindex != 9998) && (zindex != 100000090))
          {
            highest = zindex;
          }
        }
        return parseFloat(highest);
    }
    
    showOnTop(){
        this.setState({
            zIndex: this.findHighestZIndex() + 1
        });
    }
    handleItemClick(){
        alert("clicked")
    }
    
    

    render() {
        const taamStructure=this.props.translate("taamStructure")
        const exactnessLocation=this.props.translate("exactnessLocation")
        const anyPlace=this.props.translate("anyPlace")
        const sameDepth=this.props.translate("sameDepth")
        const samePointTreeStructure=this.props.translate("samePointTreeStructure")
        const taamimOnly=this.props.translate("taamimOnly")
        const wordLettering=this.props.translate("wordLettering")
        const wordsWithVowels=this.props.translate("wordsWithVowels")
        const wordRoots=this.props.translate("wordRoots")
        const scope=this.props.translate("scope")
        const search=this.props.translate("searchTaamParser")

        const isHebrew = this.props.activeLanguage && this.props.activeLanguage.code === "he";
        var menuStyling = { zIndex: this.state.zIndex, position:'absolute',direction:'ltr'}
        if(isHebrew){
            menuStyling = {direction: 'rtl', zIndex: this.state.zIndex, position:'absolute'}
        }
    
        return(
            <Menu className='context-menuTaamParser' id={this.props.menuId} style={menuStyling} onShown={() => { this.showOnTop(); }}>
            {/* <div className='context-menuTaamParser' id={this.props.menuId+"-"} style={menuStyling} > */}
                {/* <Item onClick={e=>contextMenu.show({id:'context-menuTaamParser',event:e})}> */}
                {/* <> */}
                <Item disabled className='context-menu-checkbox'><div onClick={()=>this.setState({clicked:!this.state.clicked})}><Checkbox checked={this.state.clicked} id='include-mekorot-checkbox'  />
                                            <label htmlFor="include-mekorot-checkbox" style={{ whiteSpace: 'normal', lineHeight: '1', display: 'inline'}} >
                                            &nbsp;{taamStructure}
                                            </label></div></Item>
                                            {/* <Separator /></> */}
                   {/* <> */}
                       <Item disabled className='context-menu-checkbox'style={{margin:'0',height:'25px',textAlign:'center'}}><label  for={'exactnessDropdown'}>{exactnessLocation}</label></Item>
                                      <Item disabled className='context-menu-checkbox '>
                            <select id='exactnessDropdown'
                                value={this.state.exactness}
                                onChange={(value) => {this.setState({exactness:value.target.value})}}
                                // name="sortOrder"
                                style={{
                                    minWidth: '240px',outline:'black solid 1px',display:'block'}}
                                //     fontWeight: 'normal',
                                //     fontSize: '14px'
                                // }}
                            >
                                <option value={'anyPlace'}>{anyPlace}</option>
                                <option value={'sameDepth'}>{sameDepth}</option>
                                <option value={'samePlaceInTreeStructure'}>{samePointTreeStructure}</option>
                            </select></Item>
                            {/* <Separator /></>
                            <> */}
                            <Item disabled className='context-menu-checkbox'style={{margin:'0',height:'25px',textAlign:'center'}}><label for={'scopeDropdown'}>{scope}</label></Item>
                            <Item disabled className='context-menu-checkbox '>
                            <select id='scopeDropdown'
                                value={this.state.scope}
                                onChange={(value) => {this.setState({scope:value.target.value})}}
                                // name="sortOrder"
                                style={{
                                    minWidth: '240px',outline:'black solid 1px',display:'block'}}
                                //     color: 'bla',
                                //     fontWeight: 'normal',
                                //     fontSize: '14px'
                                // }}
                            >
                                <option value={'TaamOnly'}>{taamimOnly}</option>
                                <option value={'wordLettering'}>{wordLettering}</option>
                                <option value={'wordsWithVowels'}>{wordsWithVowels}</option>
                                <option value={'wordRoots'}>{wordRoots}</option>
                                </select></Item>
                            {/* <Separator /></> */}
                <Item disabled className='context-menu-checkbox'><button style={{margin:'5px auto'}} onClick={this.closeContextMenu}>{search}</button></Item>
            {/* </div> */}
            </Menu>

        )

    }
}export default withLocalize(TaamParserContextMenu)