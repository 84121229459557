import React, { Component } from 'react';

class ParshaViewer_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פרשיות
              התנ"ך</span></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span dir="LTR">&nbsp;</span></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מטרה</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאפשר
            למשתמש לראות
            רשימה של מידע
            סיכום על כל
            פרשה (פתוחה
            או סתומה)
            בתנ"ך עם
            אפשרויות
            למיון לפי
            עמודות שונות
            ולרדת לעומק לבחון
            את הטקסט של
            פרשה בודדת או
            אפילו של
            פרשיות
            מרובות.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            בחירת מסך זה
            תופיע רשימה אינפורמטיבית
            של כל פרשיות
            פתוחות
            וסתומות בתנ"ך:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={623} height={131} id="Picture 1" src="helpDialogImages/image032.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פרטי
                העמודה</span></u></b></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="RTL" /><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />#</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - מספר
            אינדקס של
            פרשה המציין
            בצורה מספרית
            היכן הפרשה
            מתרחשת בכל
            התנ"ך</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ספר</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - איזה
            ספר מכיל את
            הפרשה</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>קריאה</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            (רלוונטי רק
            לחומש) איזה פרשת
            השבוע מכיל
            פתוחה\סתומה
            זו</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>סוג</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            מציין את סוג
            הפרשה - יכול
            להיות ראש
            ספר, פתוחה,
            סתומה</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מקום</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            פרק:פסוק שבו
            מתחיל הפרשה
            ופרק:פסוק שבו
            הפרשה מסתיים</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פסוקים</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - כמות
            הפסוקים
            הכלולה בפרשה</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תיבות</span></b><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />- &nbsp;&nbsp;&nbsp;&nbsp;" &nbsp;&nbsp;&nbsp;&nbsp;ה</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תיבות</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; " </span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אותיות</span></b><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />- &nbsp;&nbsp;&nbsp;" &nbsp;&nbsp;&nbsp;&nbsp;האותיות</span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />"&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            " </span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>גימטריה</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - הגמטריא
            הכוללת של כל
            מילה בפרשה</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תיבה
              ראשונה</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - המילה
            הראשונה של
            הפרשה</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שורש
              ראשון</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - שורש
            המילה
            הראשונה של
            הפרשה</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תיבה
              אחרונה</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - המילה
            האחרונה של
            הפרשה</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מילה
              מנחת</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
            - צורת המילה
            הנפוצה ביותר
            בפרשה</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מקרים</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - מספר
            הפעמים
            שהמילה המנחת
            מופיעה בפרשה</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>סינון</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אם ברצונך
            להתמקד
            בחלקים
            ספציפיים
            בתנ"ך, ניתן להתאים
            רשימה זו על
            ידי בחירה
            ברשימה
            מוגבלת של
            ספרים ממסנן
            הספרים בצד
            ימין ולחיצה
            על "עדכן".</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מיון</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לחיצה
            על אחת
            מהעמודות
            תמיין את
            השורות לפי סדר
            המיון
            האלפביתי או
            המספרי
            הרלוונטי של
            אותה עמודה.
            לחיצה נוספת
            על העמודה
            תהפוך את
            הסדר.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שינוי
                גודל הרשימה</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בתחתית
            הטבלה יהיה פס
            אופקי עם חץ
            גלילה כלפי מעלה/מטה
            באמצע:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={70} height={27} id="Picture 2" src="helpDialogImages/image033.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כשאתה
            מרחף מעל
            הסרגל, יופיע
            סמן העכבר הבא
            לגרור:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={155} height={32} id="Picture 8" src="helpDialogImages/image034.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>גרור
            ושחרר את הסמן
            בכיוון למטה
            או למעלה כדי להגדיל
            או לכווץ את
            הרשימה.
            הרשימה לא
            תתכווץ קטנה
            יותר מהגודל
            הראשוני בו
            היא הופיעה.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הרחבת
                שורת פרשה כדי
                להציג את
                תוכנו</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>העמודה
            הימנית ביותר
            של כל שורה
            תכיל אייקון
            או </span><span dir="LTR"><img width={18} height={19} id="Picture 3" src="helpDialogImages/image035.png" /></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />&nbsp;כדי
            להרחיב את
            שורת הסיכום
            כדי לחשוף את
            טקסט הפרשה או
          </span><span dir="LTR"><img width={18} height={19} id="Picture 17" src="helpDialogImages/image036.png" /></span><span dir="LTR">&nbsp;</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>להתכווץ
            לטקסט כך שרק
            שורת הסיכום
            תופיע.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={623} height={123} id="Picture 22" src="helpDialogImages/image037.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פרשיות
            אחת עד רבות
            יכולות להיות
            פתוחות בו זמנית.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            פתיחת פרשה
            כלשהי, תבחין
            בלחצני
            האפשרויות
            החדשים הבאים
            מופיעים בראש
            הטבלה מימין לכפתור
            ה"עדכן":</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={29} height={26} id="Picture 24" src="helpDialogImages/image038.png" /></span><span dir="LTR">&nbsp;</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מאפשרת
            תצוגת הזרם של
            הפסוקים (כפי
            שנראה לעיל),
            שם הטקסט של
            פסוק אחד זורם
            אחרי הקודם
            ללא הפסקה
            בשורה</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={29} height={27} id="Picture 30" src="helpDialogImages/image039.png" /></span><span dir="LTR">&nbsp;</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מאפשר
            תצוגת רשת
            טבלתית של
            הפסוקים כך:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={623} height={146} id="Picture 32" src="helpDialogImages/image040.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כפתורי
          </span><b><span dir="LTR" style={{fontFamily: '"Franklin Gothic Heavy",sans-serif'}}> A+</span></b><span dir="LTR"> </span><span dir="RTL" /><span style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />&nbsp;</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ו</span><span dir="LTR" /><b><span lang="HE" dir="LTR" style={{fontFamily: '"Franklin Gothic Heavy",sans-serif'}}><span dir="LTR" /> </span></b><b><span dir="LTR" style={{fontFamily: '"Franklin Gothic Heavy",sans-serif'}}>A-</span></b><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" /> יגדילו או
            יכווצו את
            גודל הגופן של
            הפסוקים.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כאשר
            מוצגים
            פסוקים
            בתצוגת "זרם",
            הלחצנים הבאים
            מאפשרים
            תצוגה
            רב-פאנלים זה
            לצד זה של הגרסאות
            הבאות של
            הפסוקים.</span></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תנ"ך</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            תצוגה
            סטנדרטי של
            הפסוקים כולל
            נקודות וטעמי
            המקרא</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תרגום</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - כרגע
            לחומש בלבד,
            התרגום
            אונקלוס</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><i><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></i><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כתב סת"ם</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - טקסט
            הפסוקים
            המוצג בגופן
            המשומש בספרי
            תורה, תפילין
            ומזוזות <i>(אנו
              מקווים להציע
              אפשרויות
              לסגנונות
              אחרים לגופן
              זה בעתיד)</i></span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כתב
              עברי</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
            - נוסח
            הפסוקים
            המוצג בגופן כתב
            עברי כפי
            שנדון בגמ'
            סנהדרין כא:
            -כב..</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הרב
            מנחם מ. כשר ב<b><i>כתב
                התורה
                ואותיותיה </i></b><i>פרק
              יד</i> (מופיע ממש
            לפני פרשת
            תזריע ב"תורה
            שלמה" שלו)
            מציע רשימה של
            מקרים שבהם
            חז"ל דורשים
            מאותיות
            דומות למראה,
            כאשר הדמיון
            נמצא למעשה
            בין אותיות
            הכתב עברי</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span dir="RTL" /><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />(אין
              הסכמה על דרך
              יחידה לייצג
              את צורת
              האותיות הללו.
              אפשרויות
              לסגנונות
              גופנים אחרים
              עשויים להיות
              מוצעים בעתיד
              וזו צריכה
              להיחשב
              כתכונה
              ניסיוני)</span></i></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>גמטריא</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
זרם של מספרים המייצג את הגימטריות הרגילות של כל מילה בפסוק
            </span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אתבש</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
זרם של מספרים עם ערכי גמטריית-אתבש
            </span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={622} height={224} id="Picture 42" src="helpDialogImages/image041.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כפתור
          </span><span dir="LTR"><img width={68} height={28} id="Picture 23" src="helpDialogImages/image042.png" /></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />&nbsp;מכווץ
            את כל הפרשיות
            הפתוחות כך
            שטבלת הסיכום
            המקורית
            מוצגת עבור כל
            הפרשיות.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><img width={303} height={271} src="helpDialogImages/image043.jpg" align="left" hspace={12} /></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>חפירה
                עמוקה יותר עם
                תפריט ההקשר</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ברוב
            המקומות
            (ובתקווה
            בעתיד, כולם)
            באתר המציגים
            תוכן פסוק,
            לחיצה
            באמצעות לחצן
            העכבר הימני
            על המסך,
            במיוחד לאחר
            בחירת אותיות
            או מילים עם
            העכבר, יציג
            תפריט קופץ עם
            מספר
            אפשרויות
            מועילות:</span></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>חפש עם
              מודגש</span></b><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}> - הפעל
            חיפוש עם
            הטקסט שנבחר</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>העתק
              מודגש לסרגל
              החיפוש</span></b><span dir="LTR" /><b><span lang="HE" dir="LTR" style={{fontSize: '10.0pt'}}><span dir="LTR" /> </span></b><span dir="RTL" /><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />- העתק את
            הטקסט שנבחר </span><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>לסרגל
            החיפוש</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>העתק
              מודגש ללוח</span></b><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}> -
            העתק את הטקסט
            שנבחר ללוח
            המערכת
            (להדבקה במקום
            אחר)</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>פתח פסוקים ב...
              </span></b><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}> - פתח את הפסוק או הפסוקים שנבחרו בבחירה של מציגים שונים
            </span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginLeft: '0in', textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginLeft: '0in', textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>חלק
            מהפונקציונליות
            הזו יוסבר
            במקום אחר, שם הדברים
            מוסברים
            בפירוט.</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginLeft: '0in', textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שים
            לב שכל אפשרות
            בתפריט ההקשר
            המכילה סמל חץ
          </span><span dir="LTR"><img width={10} height={16} id="Picture 46" src="helpDialogImages/image044.png" /></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />&nbsp;מציינת
            שעליך ללחוץ
            על אפשרות זו
            כדי לפתוח את
            תפריט המשנה
            שלה. לדוגמה:</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><img width={332} height={166} src="helpDialogImages/image045.jpg" align="left" hspace={12} /></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginLeft: '0in', textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בבחירת
            &nbsp;<b>חפש עם מודגש</b>,&nbsp;
            יוצגו לך שני
            חלקים:</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>חפש
              כציטוט</span></b><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}> -
            תיבת סימון
            המציינת אם
            לחפש את הטקסט
            שנבחר בתוך
            ציטוטים
            כפולים ו-</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>בחר
            באחד מ:
            <b>
            אותיות
            &nbsp;</b>
            לחיפוש
            אותיות בלבד
            מתוך הטקסט
            שנבחר או <b>אותיות,
              נקודות</b> כדי
            לחפש עם
            אותיות
            ונקודות או <b>אותיות,
            נקודות, טעמים </b>
            לחיפוש בטקסט
            באופן מלא,
            כולל טעמי
            המקרא.</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בבחירת
            &nbsp;<b>העתק מודגש
              לסרגל </b></span><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>החיפוש</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>,
            יוצגו
            אפשרויות
            דומות מאוד
            שבעצם פועלות
            באותה צורה אך
            במקום הניסוח <b>חפש
              כציטוט</b>, תראה
            את הנוסח: <b>העתק
              כציטוט</b></span></p>

        <p className="MsoNormal">&nbsp;</p>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><img width={332} height={52} src="helpDialogImages/image046.jpg" align="left" hspace={12} /></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בבחירת
            <b>&nbsp;
            העתק מודגש
              ללוח</b>, אותן
            שלוש
            אפשרויות
            בחירה
            תחתונות
            יופיעו ביחס
            לאותיות,
            נקודות
            וטעמים, אך
            שתי תיבות סימון
            עליונות
            יופיעו שונות מתיבות
            הסימון
            הקודמות.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בעת העתקת
            טקסט פסוק
            ללוח, יש לך את
            האפשרות <b>כלול
              מקורות
              כציטוטים
              </b>&nbsp;
            שתכלול הפניה
            לספר, פרק
            ופסוק של
            הטקסט בטקסט
            שהועתק.</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בחירה ב
            &nbsp;<b>שימוש
              בכינויים
              </b>&nbsp;
            תגרום להעתקה
            של שם הויה כה'.</span></p>
        <p className="MsoNormal">&nbsp;</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in'}}><img src="helpDialogImages/image047.jpg" align="left" hspace={12} /></p>
        <p className="MsoNormal">
        בבחירת פתח פסוקים ב..., יוצגו שלש אפשרויות:
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight: '.25in', textIndent: '-.25in', color:'#A6A6A6'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>
            <b>
            דפדפן התנ"ך
            </b>&nbsp;
             - מושבת למסך זה
             </span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginRight: '.25in', textIndent: '-.25in'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>
            <b>
            משווה טקסטים
            </b>&nbsp;
- נתח את הפסוק שנבחר על פי הכללים של טעמי המקרא
            </span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginRight: '.25in', textIndent: '-.25in', color:'#A6A6A6'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>
            <b>
            מנתח טעמים
            </b>&nbsp;
             - מושבת למסך זה
            </span></p>

        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b>Parsha Viewer / </b><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשיות
              התנ"ך</span></b></p>
        <p className="MsoNormal"><b>&nbsp;</b></p>
        <p className="MsoNormal"><b><u>Goal</u></b></p>
        <p className="MsoNormal">To let the user see a list of summary information about
          every single parsha pesucha or stuma in Tanach with options for sorting by
          various columns and for drilling down to examine the actual text of a single
          parsha or even multiple parshios.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">Upon choosing this screen, an informational listing of all
          parshios pesucha and stuma in the Tanach will appear:</p>
        <p className="MsoNormal"><img width={623} height={131} id="Picture 1" src="helpDialogImages/image032.jpg" /></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Column Details</u></b></p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b>#</b> - <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשה</span><span dir="LTR" /><span dir="LTR" /> index number indicating numerically where
          the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשה</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>occurs in the entire
          Tanach</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ספר</span></b><span dir="LTR" /><span dir="LTR" /> – which <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ספר</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>contains the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשה</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>קריאה</span></b><span dir="LTR" /><span dir="LTR" /> – (only relevant for <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>חומש</span><span dir="LTR" /><span dir="LTR" />) which <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשת
            השבוע</span><span dir="LTR" /><span dir="LTR" />
          contains this <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פתוחה\סתומה</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>סוג</span></b><span dir="LTR" /><span dir="LTR" /> – indicates the type of <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשה</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– can be <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ראש
            ספר, פתוחה,
            סתומה</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מקום</span></b><span dir="LTR" /><span dir="LTR" /> – the range of which <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרק:פסוק</span><span dir="LTR" /><span dir="LTR" /> that <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשה</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>starts and which <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרק:פסוק</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשה</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>ends</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פסוקים</span></b><span dir="LTR" /><span dir="LTR" /> – the amount of <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פסוקים</span><span dir="LTR" /><span dir="LTR" /> contained in the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשה</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>תיבות</span></b><span dir="LTR" /><span dir="LTR" /> – “&nbsp;&nbsp; “&nbsp;&nbsp; words&nbsp; “&nbsp;&nbsp;&nbsp; “</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>אותיות</span></b><span dir="LTR" /><span dir="LTR" /> – “&nbsp;&nbsp; “&nbsp;&nbsp; letters&nbsp; “&nbsp;&nbsp;&nbsp; “</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>גמטריה</span></b><span dir="LTR" /><span dir="LTR" /> – the total <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>גמטריה</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>of every single word
          in the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשה</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>תיבה
              ראשונה</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>– the first word of the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשה</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שורש
              ראשון</span></b><span dir="LTR" /><span dir="LTR" /> – the root of the first word of the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשה</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>תיבה
              אחרונה</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>– the last word of the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשה</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מילה מנחת</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>– the word form
          most commonly used in the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשה</span></p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מקרים</span></b><span dir="LTR" /><span dir="LTR" /> – the number of times the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מילה
            מנחת</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>appears in the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשה</span></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Filtering</u></b></p>
        <p className="MsoNormal">If one wants to focus on specific sections of Tanach then
          this list can be adjusted by selecting a limited list of sefarim from the sefer
          search filter on the right and clicking “update”.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Sorting</u></b></p>
        <p className="MsoNormal">Clicking on any of the columns will sort the rows by the
          relevant alphabetical or numerical sort order of that column. Clicking the
          column again will reverse the order.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Resizing the list</u></b></p>
        <p className="MsoNormal">At the bottom of the list grid there will be a horizontal
          bar with a up/down scroll arrows in the middle:<img width={70} height={27} id="Picture 2" src="helpDialogImages/image033.jpg" /></p>
        <p className="MsoNormal">When you&nbsp; hover over the bar, the following drag-drop mouse
          cursor will appear:</p>
        <p className="MsoNormal"><img width={155} height={32} id="Picture 8" src="helpDialogImages/image034.jpg" /></p>
        <p className="MsoNormal">Drag and drop the cursor in a down or up direction to
          enlarge or shrink the list.&nbsp; The list will not shrink smaller than the initial
          size in which it appeared.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Expanding a parsha line to view its content</u></b></p>
        <p className="MsoNormal">The rightmost column of each row will contain an icon either
          to <img width={18} height={19} id="Picture 11" src="helpDialogImages/image035.png" />&nbsp;expand a summary line to reveal
          the parsha text or to <img width={18} height={19} id="Picture 16" src="helpDialogImages/image036.png" />&nbsp;contract the text so that only
          the summary line will appear.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><img width={623} height={123} id="Picture 22" src="helpDialogImages/image037.jpg" /></p>
        <p className="MsoNormal">One to many parshios can be open at the same time.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">Once any parsha is opened, you will notice the following new
          option buttons appearing at the top of the grid to the right of the Update
          button:</p>
        <p className="MsoNormal"><img width={29} height={26} id="Picture 24" src="helpDialogImages/image038.png" />&nbsp;enables the stream view of the
          pesukim (as seen above), where the text of one pasuk flows after the previous
          one without a line break</p>
        <p className="MsoNormal"><img width={29} height={27} id="Picture 30" src="helpDialogImages/image039.png" />&nbsp;enables a tabular grid view of
          the pesukim like this:</p>
        <p className="MsoNormal"><img width={623} height={146} id="Picture 32" src="helpDialogImages/image040.jpg" /></p>
        <p className="MsoNormal">The <b><span style={{fontFamily: '"Franklin Gothic Heavy",sans-serif'}}>A+ </span></b>
          and <b><span style={{fontFamily: '"Franklin Gothic Heavy",sans-serif'}}>A- </span></b>
          buttons will enlarge or shrink the font size of the pesukim.</p>
        <p className="MsoNormal">While pesukim are displayed in “stream” view, the following
          buttons enable side by side multi-panel display of the following renderings of
          the pesukim</p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>תנ"ך</span></b><span dir="LTR" /><span dir="LTR" /> – a standard view of the pesukim with nekudos and taamei
          hamikra included</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>תרגום</span></b><span dir="LTR" /><span dir="LTR" /> – for Chumash-only at present, the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>תרגום
            אונקלוס</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כתב סת"ם</span></b><span dir="LTR" /><span dir="LTR" /> – the text of the pesukim presented in a
          font used <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ספרי
            תורה, תפילין
            ומזוזות</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span><i>(we hope to offer options for other styles
            of this font in the future)</i></p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כתב עברי</span></b><span dir="LTR" /><span dir="LTR" /> - the text of the pesukim presented in a <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כתב
            עברי</span><span dir="LTR" /><span dir="LTR" />
          font as discussed in <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>גמ'
            סנהדרין כא:-כב.</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>.&nbsp; Rav Menachem M.
          Kasher in <i><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כתב
              התורה
              ואותיותיה
              פרק יד</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></i>(appearing just before <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פרשת
            תזריע</span><span dir="LTR" /><span dir="LTR" />
          in his <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>תורה
            שלמה</span><span dir="LTR" /><span dir="LTR" />)
          suggests a list of instances where <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>חז"ל</span><span dir="LTR" /><span dir="LTR" /> are <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>דורש</span><span dir="LTR" /><span dir="LTR" /> from similar looking letters, that the
          similarities are actually between the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כתב עברי</span><span dir="LTR" /><span dir="LTR" /> letters&nbsp; <i>(there is no consensus on a
            single way to represent the form of these letters.&nbsp; Options for other font
            styles might be offered in the future and this is to be considered an
            experimental feature)</i></p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>גמטריא</span></b><span dir="LTR" /><span dir="LTR" /> – a stream of numbers representing the standard gematria of each word in a pasuk
        </p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>אתבש</span></b><span dir="LTR" /><span dir="LTR" /> – a stream of numbers with the <i>atbash</i> gematria values
        </p>

        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><img width={623} height={224} id="Picture 42" src="helpDialogImages/image041.jpg" /></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">The <img width={68} height={28} id="Picture 41" src="helpDialogImages/image042.png" />&nbsp;button collapses all open
          parsha sections so that the original summary grid is displayed for all
          parshios.</p>
        <p className="MsoNormal"><img width={303} height={271} src="helpDialogImages/image043.jpg" align="right" hspace={12} /></p>
        <p className="MsoNormal"><b><u>Digging deeper with the Context Menu</u></b></p>
        <p className="MsoNormal">In most (and hopefully in the future, all) places in the
          site that display pasuk content, right-clicking on the screen, especially after
          selecting letters or words with the mouse, will display a popup menu with a
          number of helpful options:</p>
        <p className="MsoListParagraphCxSpFirst" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>חפש עם
              מודגש</span></b><span dir="LTR" /><b><span lang="HE" style={{fontSize: '10.0pt'}}><span dir="LTR" /> </span></b><span style={{fontSize: '10.0pt'}}>– run a search with the selected text</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>העתק
              מודגש לסרגל
              החיפוש</span></b><span dir="LTR" /><b><span lang="HE" style={{fontSize: '10.0pt'}}><span dir="LTR" /> </span></b><span style={{fontSize: '10.0pt'}}>– copy the selected text into the search box</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>העתק מודגש
              ללוח</span></b><span dir="LTR" /><b><span lang="HE" style={{fontSize: '10.0pt'}}><span dir="LTR" /> </span></b><span style={{fontSize: '10.0pt'}}>– copy the selected text to the system clipboard (for
            pasting elsewhere)</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>
פתח פסוקים ב...
              </span></b><span dir="LTR" /><b><span lang="HE" style={{fontSize: '10.0pt'}}><span dir="LTR" /> </span></b>
              <span style={{fontSize: '10.0pt'}}>– open the selected pasuk or pesukim in a choice of various viewers</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in'}}><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '0in'}}>Some of this
          functionality will be explained elsewhere where those things are explained in
          detail.</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in'}}>&nbsp;</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '0in'}}>Note that any
          context menu option that contains an arrow symbol <img width={10} height={16} id="Picture 44" src="helpDialogImages/image044.png" />&nbsp;denotes that
          you need to click that option in order to open its sub-menu.&nbsp; For example:</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in'}}><img width={332} height={166} src="helpDialogImages/image045.jpg" align="right" hspace={12} /></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '0in'}}>when selecting <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>חפש
              עם מודגש</span></b><span dir="LTR" /><span dir="LTR" />, you will be presented with two sections:</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>חפש
              כציטוט</span></b><span dir="LTR" /><span style={{fontSize: '10.0pt'}}><span dir="LTR" /> – a checkbox indicating whether
            to search for the selected text within double quotes</span><span dir="RTL" /><span dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
          </span><span dir="LTR" /><span style={{fontSize: '10.0pt'}}><span dir="LTR" />&nbsp;AND</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><span style={{fontSize: '10.0pt'}}>Select one
            of: </span><b><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>אותיות</span></b><span dir="LTR" /><span style={{fontSize: '10.0pt'}}><span dir="LTR" /> to search
            with letters-only from the selected text OR </span><b><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>אותיות,
              נקודות</span></b><span dir="LTR" /><span style={{fontSize: '10.0pt'}}><span dir="LTR" /> to search with letters and
            nekudos OR </span><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}><b>אותיות,
              נקודות, טעמים</b></span><span dir="LTR" /><span style={{fontSize: '10.0pt'}}><span dir="LTR" /> to fully
            search the text even including </span><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>טעמי
            המקרא</span></p>
        <p className="MsoListParagraphCxSpLast" style={{marginLeft: '.25in'}}>&nbsp;</p>
        <p className="MsoNormal">When selecting <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>העתק עם
              מודגש לסרגל
              החיפוש</span></b><span dir="LTR" /><span dir="LTR" />, you will be presented with very similar options that basically
          work the same way but instead of the wording <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>חפש
              כציטוט</span></b><span dir="LTR" /><span dir="LTR" />, you will see the wording: <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>העתק
              כציטוט</span></b></p>
        <p className="MsoNormal"><img width={332} height={52} src="helpDialogImages/image046.jpg" align="right" hspace={12} /></p>
        <p className="MsoNormal">When selecting <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>העתק
              מודגש ללוח</span></b><span dir="LTR" /><span dir="LTR" />, the same three bottom selection options
          will appear re: letter, nekudos and taamim, but two top checkboxes will appear that
          are different from the previous check boxes.&nbsp; When copying pasuk text to the
          clipboard, you have the option <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>כלול
              מקורות
              כציטוטים</span></b><span dir="LTR" /><span dir="LTR" /> which will include a reference to the
          pasuk’s sefer, perek # and pasuk # in the copied text.&nbsp; Checking <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>השתמש
              בכינויים</span></b><span dir="LTR" /><span dir="LTR" /> will cause <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שם
            הויה </span><span dir="LTR" /><span dir="LTR" />&nbsp;to
          be copied as <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ה'</span><span dir="LTR" /><span dir="LTR" />.</p>
        <p className="MsoNormal">&nbsp;</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in'}}><img src="helpDialogImages/image047.jpg" align="right" hspace={12} /></p>
        <p className="MsoNormal">
        When selecting <b><span dir="RTL">פתח פסוקים ב...</span></b>, you will be presented with three choices:
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in', color:'#A6A6A6'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>דפדפן התנ"ך
              </span></b><span dir="LTR" /><span style={{fontSize: '10.0pt'}}><span dir="LTR" /> – disabled for this screen</span><span dir="RTL" /><span dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
          </span><span dir="LTR" /><span style={{fontSize: '10.0pt'}}><span dir="LTR" /></span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>מנתח טעמים
              </span></b><span dir="LTR" /><span style={{fontSize: '10.0pt'}}><span dir="LTR" /> – parse the selected pasuk according to the rules of טעמי המקרא</span><span dir="RTL" /><span dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
          </span><span dir="LTR" /><span style={{fontSize: '10.0pt'}}><span dir="LTR" /></span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in', color:'#A6A6A6'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>משווה טקסטים
              </span></b><span dir="LTR" /><span style={{fontSize: '10.0pt'}}><span dir="LTR" /> – disabled for this screen</span><span dir="RTL" /><span dir="RTL" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />
          </span><span dir="LTR" /><span style={{fontSize: '10.0pt'}}><span dir="LTR" /></span></p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default ParshaViewer_Instructions;