import React, { Component } from 'react';

class Diff_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אפשרויות
                ותוצאות
                ספציפיות
                לשיטת
                ההשוואה של </span></u></b><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הפרשים</span></u></b><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>:</span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span dir="LTR"><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תוכל
            לבחור
            אפשרויות על
            ידי לחיצה על
            כפתור ההגדרות
            הבאה</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={153} height={28} id="Picture 65" src="helpDialogImages/image062.png" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><img width={203} height={233} src="helpDialogImages/image063.png" align="left" hspace={12} /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לחיצה
            על <b>הגדרות
              תצוגה</b> תגרום
            להגדרות
            ותיבות סימון
            אלו משמאל להופיע:</span></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>יחידה</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - איזו
            רמת גרגיריות
            תנתח ההשוואה?
            אותיות? מילים?
            פסוקים?</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הדגש
              אם</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
            - מה צריכה
            ההשוואה
            להדגיש? קווי
            דמיון או הבדלים?</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תצוגה
              מפוצלת</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            מסומנת תביא
            לפיצול
            השוואה בין
            פסוקים זה לזה,
            ואילו ביטול
            הסימון יראה
            את זה כרשימה.</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הסר
              את המרווח</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - הסר
            את המרחב
            הלבן?</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הסר
              מעברי קו</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - הסר
            הפרדות קו? זה
            מועיל על מנת
            להשוות בין דברים
            שחוצים את
            גבולות הפסוק</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הסר
              טעמי המקרא</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            למנוע הצגת
            והשוואת טעמי
            המקרא?</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הסר
              ניקוד</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - למנוע
            הצגת והשוואת
            נקודות?</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.25in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אפשר
              גלילה עצמאית</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - אפשר
            גלילה עצמאית
            בקטעי התנ"ך
            הימני והשמאלי?</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לאחר
            שתלחץ על
            כפתור "עדכן",
            יופיע סוג
            הטבלה הבא </span><i><span lang="HE" style={{fontSize: '10.0pt', fontFamily: '"Arial",sans-serif'}}>(החישוב
              ייקח זמן רב
              יותר להשוואה
              בין חלקים גדולים
              יותר)</span></i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR"><img width={622} height={171} id="Picture 64" src="helpDialogImages/image064.jpg" /></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בפינה
            הימנית
            העליונה
            והשמאלית העליונה,</span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>תיבה
            תציג את ה<b>מספר
              הכולל של
              מונחים</b>
            הכלולים
            בבחירת
            הפסוקים של כל
            צד</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מתחת
            לזה, טבלה
            תציג שתי
            קבוצות של
            העמודות הבאות
            מימין לשמאל:</span></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="RTL" /><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />#</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - מספר
            אינדקס
            המציין את
            מיקום הפסוק
            ברצף כולו</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פסוק</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> -
            המיקום
            (ספר:פרק:פסוק)
            של כל פסוק</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>טקסט</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> - טקסט
            הפסוקים
            המכיל מונחים
            מודגשים
            מההשוואה</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>כמה
                קטעים טובים
                לנסות את
                פונקציונלית
                ה- </span></u></b><b><u><span dir="LTR">Diff</span></u></b><span dir="RTL" /><b><u><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />:</span></u></b></p>
        <p className="MsoListParagraphCxSpFirst" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>התיאורים
            הדוריים
            המתחילים עם "<b>ואלה
              תולדת בני נח</b>"
            שיש להם
            הקבלות ב</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בראשית
              י:א</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
            עד <b>בראשית י:לב
            </b>ו<b>דברי הימים א
              א:ד </b>עד <b>דברי הימים
              א א:נד</b></span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>ההשוואה
            הידועה בין
            שני הרישומים
            של העשרת הדברות
            ב-</span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שמות
              כ:ב</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
            עד <b>שמות כ:יג </b>ו<b>דברים
              ה:ו</b> עד <b>דברים ה:יז</b></span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>הדילמה
            המפורסמת של <b>מִגְדּוֹל
              לעומת
              מַגְדִּל</b>
            שיש לנו בסוף
            ברכת המזון
            כפי שהובאה
            בשתי הגרסאות
            של <b>שירת דוד</b> <i>(ראו
              חלק מזה
              בצילום המסך
              למעלה)</i></span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>שמואל
              ב כב:א</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>
            עד <b>שמואל ב כב:נא
            </b>ו<b>תהלים יח:א</b> עד
            <b>תהלים יח:נא</b></span></p>
        <p className="MsoListParagraphCxSpMiddle" dir="RTL" style={{marginTop: '0in', marginRight: '.5in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>פעמיים
            שניתנו לנו
            תיאור ארוך של
            האופן שבו <b>סנחריב
              תקף את
              ירושלים</b> ב-</span></p>
        <p className="MsoListParagraphCxSpLast" dir="RTL" style={{marginTop: '0in', marginRight: '1.0in', marginBottom: '.0001pt', marginLeft: '0in', textAlign: 'right', textIndent: '-.25in', direction: 'rtl', unicodeBidi: 'embed'}}><span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="RTL" /><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>מלכים
              ב יח:יג</span></b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}> עד <b>מלכים ב
              כ:יט</b> ו<b>ישעיהו
              לו:א</b> עד <b>ישעיהו
              לט:ח</b></span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><i><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>השניים
                האחרונים של
                הדוגמאות
                הללו מוזכרים בפירוט
                במסכת סופרים
                ח:א,ב</span></i></b></p>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b><u>Options and results specific to the </u></b><b><u><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הפרשים</span><span dir="LTR" /><span dir="LTR" /> comparison method:</u></b></p>
        <p className="MsoNormal">You will be able to select options by clicking on the
          following settings button</p>
        <p className="MsoNormal"><img width={153} height={28} id="Picture 65" src="helpDialogImages/image062.png" /></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><img width={203} height={233} src="helpDialogImages/image063.png" align="left" hspace={12} />Clicking <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הגדרות
              תצוגה</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span></b>(display settings) will cause the following settings
          and checkboxes on the right to appear:</p>
        <p className="MsoListParagraphCxSpFirst" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>יחידה</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– which level of
          granularity shall the comparison analyze? Letters? Words? Pesukim? </p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הדגש אם</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– what should the
          comparison highlight? Similarities or Differences?</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>תצוגה מפוצלת</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– checked will result
          in a split, side by side comparison of pesukim, while uncheck will show it as stacked</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הסר את המרווח</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– Remove whitespace?</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הסר מעברי קו</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– Remove line
          separations? This is helpful in order to compare things that cross over pasuk
          boundaries</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הסר טעמי המקרא</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– Hide <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעמי המקרא</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>from being displayed
          and compared?</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>הסר ניקוד</span></b><span dir="LTR" /><span dir="LTR" /> - Hide <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>נקודות</span><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>from being displayed
          and compared?</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>אפשר גלילה
              עצמאית</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– Enable independent scrolling of the
          right and left Tanach sections?</p>
        <p className="MsoListParagraphCxSpLast" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />Once you click the “update” button the
          following type of grid will appear <i><span style={{fontSize: '10.0pt'}}>(the calculation
              will take a longer time for a comparison of larger sections)</span></i>:</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><img width={623} height={171} id="Picture 64" src="helpDialogImages/image064.jpg" /></p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">In the top-right and top-left, a box will display <b>total
            terms</b> contained in each side’s pasuk section</p>
        <p className="MsoNormal">Beneath that, a grid will display two sets of the following
          columns from right to left:</p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><span dir="RTL" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><span dir="RTL" />#</span></b><span dir="LTR" /><span dir="LTR" /> - index number indicating the pasuk’s
          position within the entire sequence</p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>פסוק</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– the <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ספר:פרק:פסוק</span><span dir="LTR" /><span dir="LTR" /> location of each pasuk</p>
        <p className="MsoListParagraphCxSpLast" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" /><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טקסט</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span>– the text of pesukim containing highlighted
          terms from the comparison</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><u>Some good sections for trying out the Diff functionality:</u></b></p>
        <p className="MsoListParagraphCxSpFirst" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />The generational descriptions starting with “<b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ואלה
              תולדת בני נח</span></b><span dir="LTR" /><span dir="LTR" />” which have parallels in</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '1.0in', textIndent: '-.25in'}}>
          <span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;</span></span>
          <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>
            <b>בראשית י:א</b>
            <span> עד </span>
            <b>בראשית י:לב</b>
          </span>
          &nbsp;and&nbsp;
          <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>
            <b>דברי הימים א א:ד</b>
            <span> עד </span>
            <b>דברי הימים א א:נד</b>
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />The well-known comparison between the two
          listings of the<span dir="RTL" style={{fontFamily: '"Arial",sans-serif'}} /> <b><span dir="RTL" lang="HE">עשרת
              הדברות </span></b>&nbsp;in&nbsp;</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '1.0in', textIndent: '-.25in'}}>
          <span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;</span></span>
          <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>
            <b>שמות כ:ב</b>
            <span> עד </span>
            <b>שמות כ:יג</b>
          </span>
          &nbsp;and&nbsp;
          <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>
            <b>דברים ה:ו</b>
            <span> עד </span>
            <b>דברים ה:יז</b>
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />The famous <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מִגְדּוֹל</span></b><span dir="LTR" /><b><span dir="LTR" /> vs. </b><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מַגְדִּל</span></b><span dir="LTR" /><span dir="LTR" /> dilemma we have at the end of <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ברכת
            המזון</span><span dir="LTR" /> <span dir="LTR" />
          as brought in the two renditions of <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שירת דוד</span></b><span dir="LTR" /><span lang="HE"><span dir="LTR" /> </span><i>(see a portion of
            this in the screen shot above)</i></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '1.0in', textIndent: '-.25in'}}>
          <span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;</span></span>
          <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>
            <b>שמואל ב כב:א</b>
            <span> עד </span>
            <b>שמואל ב כב:נא</b>
          </span>
          &nbsp;and&nbsp;
          <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>
            <b>תהלים יח:א</b>
            <span> עד </span>
            <b>תהלים יח:נא</b>
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle" style={{textIndent: '-.25in'}}><span style={{fontFamily: 'Symbol'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span dir="LTR" />The two times we are given a lengthy
          description of how <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>סנחריב</span><span dir="LTR" /><span dir="LTR" /> comes to attack </b><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ירושלים</span></b><span dir="LTR" /><span dir="LTR" /> in</p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft: '1.0in', textIndent: '-.25in'}}>
          <span style={{fontFamily: '"Courier New"'}}>o<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;</span></span>
          <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>
            <b>מלכים ב יח:יג</b>
            <span> עד </span>
            <b>מלכים ב כ:יט</b>
          </span>
          &nbsp;and&nbsp;
          <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>
            <b>ישעיהו לו:א</b>
            <span> עד </span>
            <b>ישעיהו לט:ח</b>
          </span>
        </p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal"><b><i>The final two of these examples are actually mentioned
              in detail in </i></b><b><i><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>מסכת
                סופרים ח:א,ב</span></i></b></p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default Diff_Instructions;