import React, { useState, useEffect } from 'react';
import './css/App.css';
import { createSpeechlySpeechRecognition } from '@speechly/speech-recognition-polyfill';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { IconButton } from '@material-ui/core';
import MicRounded from '@material-ui/icons/MicRounded';

const Dictaphone = ({ voiceInputOn, voiceInputOff, size, textId, bufferCallback, changeCallback }) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const [counter, setCounter] = useState(0);
  const [before, setBefore] = useState("");
  const [after, setAfter] = useState("");
  const [prevTranscript, setPrevTranscript] = useState("");

  useEffect(() => {
     if(listening && transcript != null) {
         if (counter == 0 && prevTranscript == "") { //initialize values
            setCounter(counter + 1);
            var el = document.getElementById(textId);
            var start = el.selectionStart;
            var end = el.selectionEnd;
            var text = el.value;
            setBefore(text.substring(0, start));
            setAfter(text.substring(end, text.length));
            //console.log("initialized listening, counter = " + counter);
         }

         if (transcript != prevTranscript) {
             setPrevTranscript(transcript);
             bufferCallback(before + transcript + after);
         }
     }
     else if (!listening) {
         setCounter(0);
         //console.log("done listening");
         if (!listening && prevTranscript != "") {
             changeCallback(before, prevTranscript, after);
             setPrevTranscript("");
         }
     }
  });


  if (!browserSupportsSpeechRecognition) {
      const appId = 'babbe1ce-0715-4e98-9044-2cdf7d85557b'; //Speechly App ID that I created
      const SpeechlySpeechRecognition = createSpeechlySpeechRecognition(appId);
      SpeechRecognition.applyPolyfill(SpeechlySpeechRecognition);
      console.log("Using Speechly polyfill for a non-Chrome browser.")
      //return <span>Browser doesn't support speech recognition.</span>;
  }

/*
each of these require being called with parentheses () to work
stopListening (async) - Turn the microphone off, but still finish processing any speech in progress.
abortListening (async) - Turn the microphone off, and cancel the processing of any speech in progress. (doesn't seem to work any different!)
*/
  return (
    <div>
      <IconButton style={{ padding: '10' }} title={listening ? voiceInputOff : voiceInputOn} onClick={() => listening ? SpeechRecognition.stopListening() : SpeechRecognition.startListening({ continuous: false, language: 'he' })}>
          <MicRounded fontSize={size} color={listening ? 'primary' : 'disabled'} />
      </IconButton>
    </div>
  );
};
export default Dictaphone;
