import React, {Component, useState} from "react";
import {Button} from "reactstrap";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import "./css/Legend.css";
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import SearchIcon from '@material-ui/icons/Search';
import ancestors from './images/ancestorsOn.jpg';
import descendants from './images/descendantsOn.jpg';
import nodeConnect from './images/nodeConnectOrange.jpg';
import SettingsIcon from '@material-ui/icons/Settings';


class Legend extends Component {

    constructor(props){
        super(props);
        this.state={
            textDisplay: true,
        }
        this.setAboutTabIndex = this.setAboutTabIndex.bind(this);
    }

    setAboutTabIndex(i) {
        util.setForceOpenAboutDialog(true);
        this.props.extSetAboutTabIndex(i);
    }

    toggleLegend(){
        this.setState((currentState) => ({
            textDisplay: !currentState.textDisplay,
        }));
    }

    render() {
        var directionStyling = { direction: 'ltr', textAlign: 'left', backgroundColor: this.props.colorTheme.legendBackgroundColor }
        if (this.props.activeLanguage && this.props.activeLanguage.code === "he") {
          directionStyling = { direction: 'rtl', textAlign: 'right', backgroundColor: this.props.colorTheme.legendBackgroundColor }
        }

        const showInfo = this.props.translate('showInfoAndInstructions') + ' ▼';
        const hideInfo = this.props.translate('hide') + ' ▲';
        const key = this.props.translate('key');
        const whatsThis = this.props.translate('whatsThis');
        const whatsThisContent = this.props.translate('whatsThisContent');
        const whatsThisComment = this.props.translate('whatsThisComment');
        const mouseControls = this.props.translate('mouseControls');
        const manNode = "● " + this.props.translate('man');
        const womanNode = "● " + this.props.translate('woman');
        const familyNode = "● " + this.props.translate('family');
        const timelineSpine = "# " + this.props.translate('timelineSpine');
        const clickNode = this.props.translate('clickNode');
        const hoverNode = this.props.translate('hoverNode');
        const scroll = this.props.translate('scroll');
        const leftDrag = this.props.translate('leftDrag');
        const rightDrag = this.props.translate('rightDrag');
        const zoomNode = this.props.translate('zoomNode');
        const personInfo = this.props.translate('personInfo');
        const zoomInOut = this.props.translate('zoomInOut');
        const rotateView = this.props.translate('rotateView');
        const panView = this.props.translate('panView');
        const controlBar = this.props.translate('controlBar');
        const resizeClose = this.props.translate('resizeClose');
        const resizeCloseDetail = this.props.translate('resizeCloseDetail');
        const chooseName = this.props.translate('chooseName');
        const chooseNameDetail = this.props.translate('chooseNameDetail');
        const searchName = this.props.translate('searchName');
        const searchNameDetail1 = this.props.translate('searchNameDetail1');
        const searchNameDetail2 = this.props.translate('searchNameDetail2');
        const selectSections = this.props.translate('selectSections');
        const selectSectionsDetail = this.props.translate('selectSectionsDetail');
        const comingFeatures = this.props.translate('comingFeatures');
        const pesukimFeature = this.props.translate('pesukimFeature');
        const pesukimDetail = this.props.translate('pesukimDetail');
        const relationsFeature = this.props.translate('relationsFeature');
        const relationsDetail1 = this.props.translate('relationsDetail1');
        const relationsDetail2 = this.props.translate('relationsDetail2');
        const relationsDetail3 = this.props.translate('relationsDetail3');
        const ancestorsDescendantsFeature = this.props.translate('ancestorsDescendantsFeature');
        const ancestorsDescendantsDetail1 = this.props.translate('ancestorsDescendantsDetail1');
        const ancestorsDescendantsDetail2 = this.props.translate('ancestorsDescendantsDetail2');
        const ancestorsDescendantsDetail3 = this.props.translate('ancestorsDescendantsDetail3');
        const ancestorsDescendantsDetail4 = this.props.translate('ancestorsDescendantsDetail4');
        const integrationFeature = this.props.translate('integrationFeature');
        const integrationDetail = this.props.translate('integrationDetail');
        const general = this.props.translate('general');
        const disclaimer3D = this.props.translate('disclaimer3D');
        const help3D = this.props.translate('help3D');
        const sources = this.props.translate('sources');
        const sources3Dshort1 = this.props.translate('sources3Dshort1');
        const sources3Dshort2 = this.props.translate('sources3Dshort2');
        const signOff = this.props.translate('signOff');

        return (
            <div className="yuchsin-viewer-legend" id="legend" style={directionStyling}>
                <p id="legend-button" onClick={() => this.toggleLegend()}>{this.state.textDisplay ? hideInfo : showInfo}</p>
                {this.state.textDisplay && <div id={"legend-content-" + this.props.colorTheme.name} style={{color: this.props.colorTheme.legendTextColor}}>
                    <h3>{key}</h3>
                        <p style={{textAlign: "center", fontSize: "17px"}}>
                            <b style={{color: this.props.colorTheme.legendManColor}}>{manNode}</b>&nbsp;&nbsp;
                            <b style={{color: this.props.colorTheme.legendWomanColor}}>{womanNode}</b>&nbsp;&nbsp;
                            <b style={{color: this.props.colorTheme.legendFamilyColor}}>{familyNode}</b>
                        </p>
                        <p style={{textAlign: "center", fontSize: "17px"}}><b style={{color: this.props.colorTheme.legendTimelineColor}}>{timelineSpine}</b></p>
                    <hr/>
                    <h5>{mouseControls}</h5>
                    <p><b>{scroll}</b> - {zoomInOut}</p>
                    <p><b>{leftDrag}</b> - {rotateView}</p>
                    <p><b>{rightDrag}</b> - {panView}</p>
                    <p><b>{clickNode}</b> - {zoomNode}</p>
                    <p><b>{hoverNode}</b> - {personInfo}</p>
                    <hr/>
                    <h5>{whatsThis}</h5>
                    <p>{whatsThisContent} <span style={{fontSize: "12px"}}><i>{whatsThisComment}</i></span></p>
                    <hr/>
                    <h5>{controlBar}</h5>
                    <p><b>{resizeClose}</b> - {resizeCloseDetail}</p><br />
                    <p><b>{chooseName}</b> - {chooseNameDetail}</p><br />
                    <p><b>{searchName}</b> - {searchNameDetail1} <SearchIcon /> {searchNameDetail2}</p><br />
                    <p><b>{ancestorsDescendantsFeature}</b> - {ancestorsDescendantsDetail1} <img src={ancestors} height="24" width="24" align="center" />&nbsp;
                          {ancestorsDescendantsDetail2} <img src={descendants} height="24" width="24" align="center" />
                          {ancestorsDescendantsDetail3} <SettingsIcon />
                          {ancestorsDescendantsDetail4} </p><br />
                    <p><b>{relationsFeature}</b> - {relationsDetail1} <img src={nodeConnect} height="15" width="39" align="center" /> {relationsDetail2}<br /><br />{relationsDetail3}</p><br />
                    <p><b>{selectSections}</b> - {selectSectionsDetail}</p>
                    <hr/>
                    <h5>{comingFeatures}</h5>
                    <p><b>{integrationFeature}</b> - {integrationDetail}</p>
                    <hr/>
                    <h5>{general}</h5>
                    <p><i>{disclaimer3D}</i></p>
                    <br />
                    <p>{help3D}</p>
                    <br />
                    <p>{sources3Dshort1} <a href="#" onClick={() => {this.setAboutTabIndex(3)}}>{sources}</a> {sources3Dshort2}
                        <img src={require("./images/info.png")} width="20" height="20" />.
                    </p>
                    <br />
                    <p>{signOff}</p>
                    <br />
                </div>
                }
            </div>
        )
    }
}
export default withLocalize(Legend);
