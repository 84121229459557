import React, { Component, MenuItem } from 'react';
import logo from './images/bhsLogo_v4black.jpg';
import ta from './images/taLogoReverse_v2.jpg';
import wikitext from './images/Wikisource-logo.png';
//import prototyping from './images/prototyping.jpg';
import './css/App.css';
import styles from './css/App.css'; //for customizing reactStrap styles
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Button, ButtonToolbar, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { IconButton, Menu, Popover, MenuItem as MaterialMenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import BarChartIcon from '@material-ui/icons/BarChart';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import MapIcon from '@material-ui/icons/Map';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { withLocalize, Translate, getTranslate, getActiveLanguage } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import LanguageToggle from './LanguageToggle';
import translations from "./json/translations.json";
import {withRouter} from 'react-router-dom';
import LoginButtons from './LoginButtons';
import AboutDialog from './AboutDialog';
import HelpDialog from './HelpDialog';
import PremiumServicesDialog from './PremiumServicesDialog';
import HomeIcon from '@material-ui/icons/Home';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import * as properties from './config/properties.js';

//mje - Oct 29, 2019 - for obsolete code pertinent to trial and error changes to get login/authorization to work with Auth) and Okta in Oct 2019, see version history of this file in Git

//function checkAuthentication() {
//  const authenticated = await this.props.auth.isAuthenticated();
//  if (authenticated && !this.state.userinfo) {
//    const userinfo = await this.props.auth.getUser();
//    alert("hoobuoy");
//    this.setState({ userinfo });
//  }
//}

class AppNavBar extends Component {

//export default class AppNavBar extends Component {
  constructor(props) {
    super(props);

    //this.toggle = this.toggle.bind(this);

    this.state = {
      searchOpen: false,
      topicSearchOpen: false,
      reportOpen: false,
      staticContentOpen: false,
      keilimOpen: false,
      appMenuAnchorEl: null,
      aboutX: 0,
      aboutY: 0,
      aboutWidthNum: 0,
      aboutHeightNum: 0,
      helpDialogStartupTopic: null,
      helpX: 0,
      helpY: 0,
      helpWidthNum: 0,
      helpHeightNum: 0,
      premiumServicesDialogOpen: false,
    };

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true }
    });

    this.props.addTranslation(translations);

    this.openAppMenu = this.openAppMenu.bind(this);
    this.closeAppMenu = this.closeAppMenu.bind(this);
    this.handleMenuSelection = this.handleMenuSelection.bind(this);
    this.toggleAboutDialog = this.toggleAboutDialog.bind(this);
    this.resetAboutOnWindowResize = this.resetAboutOnWindowResize.bind(this);
    this.toggleHelpDialog = this.toggleHelpDialog.bind(this);
    this.resetHelpOnWindowResize = this.resetHelpOnWindowResize.bind(this);
    this.setAboutTabIndex = this.setAboutTabIndex.bind(this);

//    this.setStateSynchronous = this.setStateSynchronous.bind(this);

  }
    //todo - this also gets done in Legend.js - move logic up to a higher level so don't repeat code
    setAboutTabIndex(i) {
        util.setForceOpenAboutDialog(true);
        this.props.extSetAboutTabIndex(i);
    }

    toggleAboutDialog(boolean) {
      this.closeAppMenu()
      if(boolean === true){
        this.resetAboutPosition();
      }
      localStorage.setItem("helpDialogStartupTopic", "topBar");
      this.props.toggleAboutDialog(boolean);
    }

    setAboutSize(widthNum, heightNum, x, y){
      this.setState({
        aboutWidthNum: widthNum,
        aboutHeightNum: heightNum,
        aboutX: x,
        aboutY: y
      })
    }

    setAboutPosition(x, y){
      this.setState({
        aboutX: x,
        aboutY: y
      })
    }

    resetAboutPosition(callback){
      const widthNum = window.innerWidth > 1000 ? 800 : window.innerWidth - 200;
      const heightNum = window.innerHeight > 700 ? 650 : window.innerHeight - 50;
      const homeScrollContainer = document.getElementById("home-scroll-container");
      this.setState({
        aboutWidthNum: widthNum,
        aboutHeightNum: window.innerHeight > 700 ? 650 : window.innerHeight - 50,
        aboutX: ((window.innerWidth - widthNum) / 2) + homeScrollContainer.scrollLeft,
        aboutY: (window.innerHeight - heightNum) / 2,
      }, () => {
        if(callback){
          callback();
        }
      })
    }

    toggleHelpDialog(boolean) {
      this.closeAppMenu()
      if(boolean === true){
        this.resetHelpPosition(() => {
          this.setState({ helpDialogStartupTopic: localStorage.getItem("helpDialogStartupTopic") }, () => {});
        });
      }
      this.props.toggleHelpDialog(boolean);
    }

    togglePremiumServicesDialog(boolean) {
      this.closeAppMenu()
      this.setState({ premiumServicesDialogOpen: boolean });
    }

    setHelpSize(widthNum, heightNum, x, y){
      this.setState({
        helpWidthNum: widthNum,
        helpHeightNum: heightNum,
        helpX: x,
        helpY: y
      })
    }

    setHelpPosition(x, y){
      this.setState({
        helpX: x,
        helpY: y
      })
    }

    resetHelpPosition(callback){
      const widthNum = window.innerWidth > 1000 ? 800 : window.innerWidth - 200;
      const heightNum = window.innerHeight > 700 ? 650 : window.innerHeight - 50;
      const homeScrollContainer = document.getElementById("home-scroll-container");
      this.setState({
        helpWidthNum: widthNum,
        helpHeightNum: window.innerHeight > 700 ? 650 : window.innerHeight - 50,
        helpX: ((window.innerWidth - widthNum) / 2) + homeScrollContainer.scrollLeft,
        helpY: (window.innerHeight - heightNum) / 2,
      }, () => {
        if(callback){
          callback();
        }
      })
    }

    openAppMenu(event){
        this.setState({appMenuAnchorEl: event.currentTarget});
        localStorage.setItem("helpDialogStartupTopic", "topBar");
    }

    closeAppMenu(event){
        this.setState({appMenuAnchorEl: null});
    }

    handleMenuSelection(menuSelection){
      this.closeAppMenu()
      var fakeThis = this;
      if (menuSelection === 'home'){
        this.props.extOpenDockedViewer("StartMenu");
      }
      else if(menuSelection === 'parshaViewer'){
          localStorage.setItem("helpDialogStartupTopic", "parshaViewer");
          this.props.extOpenDockedViewer("ParshaViewer"); //assigning window.location.href will not reload for a click-through URL with /#/
      }
      else if(menuSelection === 'lexicon'){
        this.props.toggleLexicon(true);
      }
      else if (menuSelection === 'frequencyViewer'){
          localStorage.setItem("helpDialogStartupTopic", "frequencyViewer");
          this.props.extOpenDockedViewer("FrequencyViewer");
      }
      else if (menuSelection === 'textComparator') {
          localStorage.setItem("helpDialogStartupTopic", "textComparatorOptions");
          this.props.extOpenDockedViewer("TextComparatorViewer");
      }
      else if (menuSelection === 'taamParser'){
          localStorage.setItem("helpDialogStartupTopic", "taamParser");
          this.props.extOpenTaamParser();
      }
      else if (menuSelection === 'tanachViewer'){
          localStorage.setItem("helpDialogStartupTopic", "tanachViewer");
          this.props.toggleTanachViewer(true, 1);
      }
      else if (menuSelection === 'objectClouds'){
        localStorage.setItem("helpDialogStartupTopic", "objectCloudViewer");
        this.props.toggleObjectCloudViewer(true);
      }
      else if (menuSelection === 'yuchsinTree'){
        localStorage.setItem("helpDialogStartupTopic", "yuchsinTreeViewer");
        this.props.toggleYuchsinTreeViewer(true);
      }
      else if (menuSelection === 'adminControls'){
          window.location.href = properties.env.applicationURL+"/#/admin"; //fix this later - see other window.location.href comments above
      }
      else if (menuSelection === 'premiumServices'){
          this.togglePremiumServicesDialog(true);
      }

    }

    isAboutFullyVisible () {
      const el = document.getElementsByClassName("about-rnd")[0]
      const percentVisible = 100;
      //check vertical
      var isVertVisible = false;
      let
        rect = el.getBoundingClientRect(),
        windowHeight = (window.innerHeight || document.documentElement.clientHeight);
      if(!(
        Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100)) < percentVisible ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
      )){
        isVertVisible = true;
      }
      //check horizontal
      var isHorizVisible = true;
      if(rect.x + this.state.aboutWidthNum > window.innerWidth){
        isHorizVisible = false;
      }
      
      return isVertVisible && isHorizVisible;
    };

    resetAboutOnWindowResize(){
      const isFullyVisible = this.isAboutFullyVisible();
      if(!isFullyVisible){
        this.resetAboutPosition()
      }
    }

    isHelpFullyVisible () {
      const el = document.getElementsByClassName("help-rnd")[0]
      const percentVisible = 100;
      //check vertical
      var isVertVisible = false;
      let
        rect = el.getBoundingClientRect(),
        windowHeight = (window.innerHeight || document.documentElement.clientHeight);
      if(!(
        Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100)) < percentVisible ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
      )){
        isVertVisible = true;
      }
      //check horizontal
      var isHorizVisible = true;
      if(rect.x + this.state.helpWidthNum > window.innerWidth){
        isHorizVisible = false;
      }
      
      return isVertVisible && isHorizVisible;
    };

    resetHelpOnWindowResize(){
      const isFullyVisible = this.isHelpFullyVisible();
      if(!isFullyVisible){
        this.resetHelpPosition()
      }
    }



    componentDidMount() {
      window.addEventListener("resize", () => {
        this.resetAboutOnWindowResize()
        this.resetHelpOnWindowResize()
      });
    }

    componentDidUpdate(prevProps) {
      if (util.isForceOpenAboutDialog) {
          if (!this.state.aboutDialogOpen) {
              this.toggleAboutDialog(true); //open the About dialogue if this index was changed from anywhere in UI since a change indicates an interest to view
          }
          util.setForceOpenAboutDialog(false);
      }

      if (this.props.aboutDialogOpen && !prevProps.aboutDialogOpen && (!this.props.initialFullScreen || "TY".indexOf(this.props.initialFullScreen.toUpperCase()) < 0))
        this.resetAboutPosition();

      if (this.props.helpDialogOpen && !prevProps.helpDialogOpen && (!this.props.initialFullScreen || "TY".indexOf(this.props.initialFullScreen.toUpperCase()) < 0))
        this.resetHelpPosition();

      if(this.props.beta && !prevProps.beta){
        clearTimeout(this.timer);
        this.setState({beta:true},()=>{
          this.timer = setTimeout(()=>{
            this.setState({beta:false})
          }, 15000);
        })
      }
    }

//    setStateSynchronous(stateUpdate) {
//        return new Promise(resolve => {
//            this.setState(stateUpdate, () => resolve());
//        });
//    }


//  toggle() {
//    this.setState({
//      isOpen: !this.state.isOpen
//    });
//  }
//  toggle() {
//    this.setState(prevState => ({
//      searchOpen: !prevState.searchOpen,
//      topicSearchOpen: !prevState.topicSearchOpen,
//      reportOpen: !prevState.reportOpen,
//      keilimOpen: !prevState.keilimOpen
//    }));
//  }


//color="dark" dark               <NavLink className="navbar-header"  </NavLink>

  render() {
    const { classes } = this.props;
    const menu = this.props.translate("menu.caption");
    const help = this.props.translate("help.caption");
    const about = this.props.translate("about.caption");
    const linkedIn = this.props.translate("linkedIn");
    const blogger = this.props.translate("blogger");
    const facebook = this.props.translate("facebook");
    const twitter = this.props.translate("twitter");
    const contact = this.props.translate("contact");

    var directionStyling = {direction: 'ltr', textAlign: 'left'};
    var isHebrew=false;
    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
        directionStyling = {direction: 'rtl', textAlign: 'right'};
        isHebrew = true;
    }

    var innerWidth = window.innerWidth;
    var isWideScreen = innerWidth > 1625;
    var isWithinSiteMap = this.props.isWithinSiteMap;

    const isAdmin = this.props.userRole === "Administrator";

    return      <div className = "navbar-header" expand="md" width>
    <table id='navbar-outer-table' helpTopic="topBar">
        <tbody>
            <tr>
                <td align="left" className="nav-bar-side-cell nav-bar-left-side">
                      <table align="left" className = "navbar-caption" width="100%">
                          <tbody>
                              <tr>
                                  <td align="left" width="126px">
                                      <table width="126px">
                                          <tbody>
                                              <tr>
                                                <td align="center" colspan="2">
                                                    <Translate id="poweredBy" />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td width="126px" align="center">
                                                      <td width="88px" align="center">
                                                          <NavLink className="navbar-caption" title={this.props.translate("taryagAnalytics")} href="http://taryaganalytics.com" target="_blank">
                                                              <img src={ta} height="48" width="88" className="company-logo" alt="companyLogo" align="center" />
                                                          </NavLink> <br /><br />
                                                      </td>
                                                      <td width="38px" align="center">
                                                          <NavLink className="navbar-caption" title="ויקיטקסט: מקרא על פי המסורה" href="https://he.wikisource.org/wiki/משתמש:Dovi/מקרא_על_פי_המסורה#ראש" target="_blank">
                                                              <img src={wikitext} height="36" width="38" className="navbar-logo" alt="wikiText-Torah" align="center" />
                                                          </NavLink> <br />
                                                      </td>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </td>
                                  <td width="calc(100%-158px)" align="center" id="navbar-messages">
                                    {this.props.serverMessage && //moved BETA TEST banner message to commented info at bottom of this file in case need to reinstate at some point
                                      <div className="navbar-messages-parent">
                                        <div className="navbar-server-messages-parent" style={{directionStyling}}>
                                          <div>
                                            {this.props.serverMessage} 
                                          </div>
                                        </div>
                                      </div>
                                    }
                                    {this.state.beta && 
                                      <span className='betaNotice'><span className='container_row'><span className='layer1'>&nbsp;&nbsp;&nbsp;</span><span className='betaNoticeSub'>&nbsp;</span></span></span>
                                    }                                            
                                    {!this.props.serverMessage && //placeholder for now - moved BETA TEST banner message to commented info at bottom of this file in case need to reinstate at some point
                                      <div style={{ padding: '10px 0', display:'inline-block'}}>
                                        <span className="baseHaseferText">BASE HASEFER</span><span style={{ color: 'white', fontSize: '26px', verticalAlign: '-75%'}}>®</span>
                                      </div>}
                                      {this.state.beta && 
                                      <span title={`${this.props.translate("betaHover1")}\n${this.props.translate("betaHover2")}\n${this.props.translate("betaHover3")}\n${this.props.translate("betaHover4")}\n${this.props.translate("betaHover5")}\n${this.props.translate("betaHover6")}`} className='betaNotice'><span className='container_row'><span className='layer1'>β</span><span className='betaNoticeSub'>eta</span></span></span>
                                      }                                            
                                    
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                </td>
                <td style={{width: '560px', minWidth: '560px', maxWidth: '560px', padding: '0'}} align="center">
                      <table align="center" className = "navbar-component">
                          <tbody>
                              <tr>
                                    <td className="navbar-half-pasuk-cell">
                                        <div align="center" style={{textAlign: 'right', paddingRight: "10px"}} className = "navbar-component2 navbar-half-pasuk-div"><Translate id="tevakshena" />
                                          אָז תָּבִין יִרְאַת ה' וְדַעַת אֱלֹקִים תִּמְצָא:
                                        </div>
                                    </td>
                                  <td width="33px" style={{minWidth: '33px', maxWidth: '33px'}} >
                                      <table align="right">
                                          <tbody>
                                              <tr className = "navbar-component">
                                                  <td align="center">לפום</td>
                                              </tr>
                                              <tr className = "navbar-component">
                                                  <td align="center">צערא</td>
                                              </tr>
                                              <tr className = "navbar-component">
                                                  <td align="center">אגרא</td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </td>
                                  <td width="230px" style={{minWidth: '230px', maxWidth: '230px'}} helpTopic="layout">
                                      <Link to="/"><img src={logo} className="App-logo" alt="logo" align="center"/></Link>
                                  </td>
                                  <td width="56px" style={{minWidth: '56px', maxWidth: '56px'}} >
                                      <table align="left">
                                          <tbody>
                                              <tr className = "navbar-component">
                                                  <td align="center">הפך בה</td>
                                              </tr>
                                              <tr className = "navbar-component">
                                                  <td align="center">והפך בה</td>
                                              </tr>
                                              <tr className = "navbar-component">
                                                  <td align="center">דכלא בה</td>
                                              </tr>
                                          </tbody>
                                      </table>

                                  </td>
                                  <td className="navbar-half-pasuk-cell">
                                      <div align="center" style={{textAlign: 'left', paddingLeft: "4px"}} className = "navbar-component2 navbar-half-pasuk-div"><Translate id="tevakshena" />
                                        אִם תְּבַקְשֶׁנָּה כַכָּסֶף וְכַמַּטְמוֹנִים תַּחְפְּשֶׂנָּה
                                      </div>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                </td>
                <td align="right" className="nav-bar-side-cell nav-bar-right-side">
                  <div id="navbar-right-items-parent">
                 {(!isWithinSiteMap) &&<>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      className="btn btn-dark navbar-right-item"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      helpTopic="menuButton"
                      title={menu}
                      onClick={this.openAppMenu}
                    >
                      <img src={require("./images/menu.svg")} width="30" height="30" />
                    </IconButton>
                    <Popover
                      id="app-nav-popover"
                      anchorEl={this.state.appMenuAnchorEl}
                      keepMounted
                      open={Boolean(this.state.appMenuAnchorEl)}
                      onClose={(e) => {this.closeAppMenu(e)}}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                    >

                      <div id="main-menu-outer-div" style={{direction: directionStyling.direction}}>
                        <div id="main-menu-panel-1" className="main-menu-panel">
                          <DropdownItem onClick={() => {this.handleMenuSelection('home')}} className="dropdownItem" style={directionStyling} >
                            <div className='appMenuIcon'>
                                <HomeIcon></HomeIcon>
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <Translate id="homePage" className='appMenuItemText'/>
                          </DropdownItem>
                          <div className='app-nav-divider'></div>
                          <DropdownItem onClick={() => {this.handleMenuSelection('parshaViewer')}} className="dropdownItem" style={directionStyling} helpTopic="parshaViewer">
                            <div className='appMenuIcon'>
                                <img src={require("./images/closed-book.svg")} width="21" height="21" />
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <Translate id="parshaViewer" className='appMenuItemText'/>
                          </DropdownItem>
                          <div className='app-nav-divider'></div>
                          <DropdownItem onClick={() => {this.handleMenuSelection('tanachViewer')}} className="dropdownItem" style={directionStyling} helpTopic="tanachViewer">
                              <div className='appMenuIcon'>
                                <img src={require("./images/menu_book-24px.svg")} width="21" height="21" />
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <Translate id="tanachViewer" className='appMenuItemText'/>
                          </DropdownItem>
                          <div className='app-nav-divider'></div>
                          <DropdownItem onClick={() => {this.handleMenuSelection('lexicon')}} className="dropdownItem" style={directionStyling}>
                              <div className='appMenuIcon'>
                                  <img src={require("./images/lexicon.jpg")} width="21" height="21" />
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <Translate id="lexicon" className='appMenuItemText'/>
                          </DropdownItem>
                          <div className='app-nav-divider'></div>
                          <DropdownItem onClick={() => {this.handleMenuSelection('frequencyViewer')}} className="dropdownItem" style={directionStyling} helpTopic="frequencyViewer">
                              <div className='appMenuIcon'>
                                  <BarChartIcon />
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <Translate id="frequencyMenuItem.caption" className='appMenuItemText'/>
                          </DropdownItem>
                          {!isWideScreen&&<><div className='app-nav-divider'></div>
                          <DropdownItem onClick={() => {this.handleMenuSelection('textComparator')}} className="dropdownItem" style={directionStyling} helpTopic="textComparatorOptions">
                              <div className='appMenuIcon'>
                                  <CompareArrowsIcon width="21" height="21"  />
                              </div>
                              &nbsp;&nbsp;
                              <Translate id="textComparator.caption" className='appMenuItemText'/>
                          </DropdownItem></>}
                          {/* <div className='app-nav-divider'></div>
                          <DropdownItem onClick={() => {this.handleMenuSelection('taamParser')}} className="dropdownItem" style={directionStyling} helpTopic="taamParser">
                              <div className='appMenuIcon'>
                                  <img src={require("./images/icons8-hierarchy-96.png")} width="21" height="21" />
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <Translate id="taamParser" className='appMenuItemText'/>
                          </DropdownItem>
                          <div className='app-nav-divider'></div>
                          <DropdownItem onClick={() => {this.handleMenuSelection('objectClouds')}} className="dropdownItem" style={directionStyling}>
                              <div className='appMenuIcon'>
                                  <img src={require("./images/iconfinder_cloud_126565.png")} width="21" height="21" />
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <Translate id="objectClouds" className='appMenuItemText'/>
                          </DropdownItem>
                          <div className='app-nav-divider'></div>
                          <DropdownItem onClick={() => {this.handleMenuSelection('yuchsinTree')}} className="dropdownItem" style={directionStyling}>
                              <div className='appMenuIcon'>
                                  <img src={require("./images/3d-connections.png")} width="21" height="21" />
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <Translate id="yuchsinTree" className='appMenuItemText'/>
                          </DropdownItem> */}
                        </div>
                        {/* {(isAdmin || !isWideScreen) && */}
                          <div id="main-menu-panel-2" className="main-menu-panel">
                            {(!isWideScreen) &&<>
                              <DropdownItem onClick={() => {this.toggleHelpDialog(true)}} className="dropdownItem" style={directionStyling} helpTopic="helpButton">
                                  <div className='appMenuIcon'>
                                      <img src={require('./images/help_white.jpg')} width="21" height="21" />
                                  </div>
                                  &nbsp;&nbsp;&nbsp;
                                  <Translate id="help.caption" className='appMenuItemText'/>
                              </DropdownItem>
                              <div className='app-nav-divider'></div>
                              <DropdownItem onClick={() => {this.toggleAboutDialog(true)}} className="dropdownItem" style={directionStyling}>
                                  <div className='appMenuIcon'>
                                      <img src={require('./images/info.png')} width="21" height="21" />
                                  </div>
                                  &nbsp;&nbsp;&nbsp;
                                  <Translate id="about.caption" className='appMenuItemText'/>
                              </DropdownItem>
                              <div className='app-nav-divider'></div>
                              <DropdownItem onClick={() => {this.setAboutTabIndex(10)}} className="dropdownItem" style={directionStyling}>
                                  <div className='appMenuIcon'>
                                      <img src={require('./images/email_white.jpg')} width="21" height="21" />
                                  </div>
                                  &nbsp;&nbsp;&nbsp;
                                  <Translate id="contact" className='appMenuItemText'/>
                              </DropdownItem>
                              <div className='app-nav-divider'></div>
                            </>}

                            <div>
                              {/* <DropdownItem onClick={() => {this.handleMenuSelection('premiumServices')}} className="dropdownItem" style={directionStyling}>
                                  <div className='appMenuIcon'>
                                      <img src={require("./images/premiumServicesTransparent.png")} width="21" height="17" />
                                  </div>
                                  &nbsp;&nbsp;&nbsp;
                                  <Translate id="premiumServices" className='appMenuItemText'/>

                              </DropdownItem>
                              <div className='app-nav-divider'></div> */}
                          {isWideScreen&&<><DropdownItem onClick={() => {this.handleMenuSelection('textComparator')}} className="dropdownItem" style={directionStyling} helpTopic="textComparatorOptions">
                              <div className='appMenuIcon'>
                                  <CompareArrowsIcon width="21" height="21"  />
                              </div>
                              &nbsp;&nbsp;
                              <Translate id="textComparator.caption" className='appMenuItemText'/>
                          </DropdownItem>
                          <div className='app-nav-divider'></div></>}
                          <DropdownItem onClick={() => {this.handleMenuSelection('taamParser')}} className="dropdownItem" style={directionStyling} helpTopic="taamParser">
                              <div className='appMenuIcon'>
                                  <img src={require("./images/icons8-hierarchy-96.png")} width="21" height="21" />
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <Translate id="taamParser" className='appMenuItemText'/>
                          </DropdownItem>
                          <div className='app-nav-divider'></div>
                          <DropdownItem onClick={() => {this.handleMenuSelection('objectClouds')}} className="dropdownItem" style={directionStyling} helpTopic="objectCloudViewer">
                              <div className='appMenuIcon'>
                                  <img src={require("./images/iconfinder_cloud_126565.png")} width="21" height="21" />
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <Translate id="objectClouds" className='appMenuItemText'/>
                          </DropdownItem>
                          <div className='app-nav-divider'></div>
                          <DropdownItem onClick={() => {this.handleMenuSelection('yuchsinTree')}} className="dropdownItem" style={directionStyling}>
                              <div className='appMenuIcon'>
                                  <img src={require("./images/3d-connections.png")} width="21" height="21" />
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <Translate id="yuchsinTree" className='appMenuItemText'/>
                          </DropdownItem>                              
                          <div className='app-nav-divider'></div>
                          <DropdownItem onClick={() => {this.handleMenuSelection('premiumServices')}} className="dropdownItem" style={directionStyling}>
                              <div className='appMenuIcon'>
                                  <img src={require("./images/premiumServicesTransparent.png")} width="21" height="17" />
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <Translate id="premiumServices" className='appMenuItemText'/>
                          </DropdownItem>
                          <div className='app-nav-divider'></div>
                          <a href="/#/sitemap" style={{textDecoration: "none"}}>
                              <DropdownItem className="dropdownItem" style={directionStyling}>
                                  <div className='appMenuIcon'>
                                      <MapIcon width="21" height="21"  />
                                  </div>
                                  &nbsp;&nbsp;&nbsp;
                                  <Translate id="siteMap" className='appMenuItemText'/>
                              </DropdownItem>
                          </a>
                            </div>

                            {isAdmin && <>
                                <div className='app-nav-divider'></div>
                                <DropdownItem onClick={() => {this.handleMenuSelection('adminControls')}} className="dropdownItem" style={directionStyling}>
                                    <div className='appMenuIcon'>
                                        <img src={require("./images/toggleSettings.svg")} width="21" height="21" />
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <Translate id="adminPage" className='appMenuItemText'/>
                                </DropdownItem>
                              </>
                            }
                          </div>
                        {/* } */}
                      </div>
                    </Popover>

                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      className="btn btn-dark navbar-right-item navbar-disappering-items"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      helpTopic="helpButton"
                      title={help}
                      onClick={() => {this.props.toggleHelpDialog(true)}}
                    >
                      <img src={require("./images/help.jpg")} width="30" height="30" />
                    </IconButton>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      className="btn btn-dark navbar-right-item navbar-disappering-items"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      title={about}
                      onClick={() => {this.toggleAboutDialog(true)}}
                    >
                      <img src={require("./images/info.png")} width="30" height="30" />
                    </IconButton>

                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      className="btn btn-dark navbar-right-item navbar-disappering-items"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      title={contact}
                      onClick={() => {this.setAboutTabIndex(10)}}
                    >
                      <img src={require("./images/email.jpg")} />
                    </IconButton>

                    <LoginButtons className="navbar-right-item" login={this.props.login} logout={this.props.logout} userInfo={this.props.userInfo} authenticated={this.props.authenticated} />
                            </>}

                    <LanguageToggle className="navbar-right-item" />

                        <table className="socialButtonGroup" align="center">
                            <tbody>
                                <tr>
                                    <td width="40px" align="center">&nbsp;</td>
                                    <td align="center">
                                        <IconButton
                                          edge="start"
                                          color="inherit"
                                          aria-label="menu"
                                          className="btn btn-dark socialButtonGroup-item"
                                          aria-controls="simple-menu"
                                          aria-haspopup="true"
                                          title={linkedIn}
                                          onClick={() => {window.open("https://www.linkedin.com/company/taryag-analytics", "_blank")}}
                                        >
                                          <img src={require("./images/linkedIn_blk_40px.jpg")} width="21" height="21" />
                                        </IconButton>
                                        <IconButton
                                          edge="start"
                                          color="inherit"
                                          aria-label="menu"
                                          className="btn btn-dark socialButtonGroup-item"
                                          aria-controls="simple-menu"
                                          aria-haspopup="true"
                                          title={blogger}
                                          onClick={() => {window.open("https://basehasefer.blogspot.com", "_blank")}}
                                        >
                                          <img src={require("./images/blogger_blk_40px.png")} width="21" height="21" />
                                        </IconButton>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="40px" align="center">&nbsp;</td>
                                    <td align="center">
                                        <IconButton
                                          edge="start"
                                          color="inherit"
                                          aria-label="menu"
                                          className="btn btn-dark socialButtonGroup-item"
                                          aria-controls="simple-menu"
                                          aria-haspopup="true"
                                          title={facebook}
                                          onClick={() => {window.open("https://www.facebook.com/Base-Hasefer-104584254894724", "_blank")}}
                                        >
                                          <img src={require("./images/facebook_blk_40px.jpg")} width="21" height="21" />
                                        </IconButton>
                                        <IconButton
                                          edge="start"
                                          color="inherit"
                                          aria-label="menu"
                                          className="btn btn-dark socialButtonGroup-item"
                                          aria-controls="simple-menu"
                                          aria-haspopup="true"
                                          title={twitter}
                                          onClick={() => {window.open("https://twitter.com/basehasefer", "_blank")}}
                                        >
                                          <img src={require("./images/twitterRoundedSquare_blk_40px.png")} width="21" height="21" />
                                        </IconButton>

                                    </td>
                                </tr>
                            </tbody>
                        </table>

                  </div>
                 </td>
            </tr>
          </tbody>
      </table>

      <AboutDialog 
        isOpen={this.props.aboutDialogOpen} aboutOpen={this.props.aboutOpen}
        onClose={() => {this.toggleAboutDialog(false)}}
        version={sessionStorage.getItem("version")}
        setSize={(widthNum, heightNum, x, y) => {this.setAboutSize(widthNum, heightNum, x, y)}}
        setPosition={(x, y) => {this.setAboutPosition(x, y)}} resetPosition={() => {this.resetAboutPosition()}}
        x={this.state.aboutX} y={this.state.aboutY} widthNum={this.state.aboutWidthNum} heightNum={this.state.aboutHeightNum}
        tabIndex={this.props.aboutTabIndex} extSetAboutTabIndex={this.props.extSetAboutTabIndex}
        isFirstTimeOpened={this.props.isFirstTimeOpened} extSetFirstTimeOpened={this.props.extSetFirstTimeOpened}
        initialFullScreen={this.props.initialFullScreen} isFullScreen={this.props.isFullScreen} handleCopyLink={this.props.handleCopyLink}
      />

      <HelpDialog
        isOpen={this.props.helpDialogOpen}
        onClose={() => {this.toggleHelpDialog(false)}}
        setSize={(widthNum, heightNum, x, y) => {this.setHelpSize(widthNum, heightNum, x, y)}}
        setPosition={(x, y) => {this.setHelpPosition(x, y)}} resetPosition={() => {this.resetHelpPosition()}}
        x={this.state.helpX} y={this.state.helpY} widthNum={this.state.helpWidthNum} heightNum={this.state.helpHeightNum}
        startupTopic={this.props.helpDialogStartupTopic}
        isFirstTimeOpened={this.props.isFirstTimeOpened} extSetFirstTimeOpened={this.props.extSetFirstTimeOpened}
        initialFullScreen={this.props.initialFullScreen} isFullScreen={this.props.isFullScreen} handleCopyLink={this.props.handleCopyLink}
      />

      <PremiumServicesDialog
        isOpen={this.state.premiumServicesDialogOpen}
        onClose={() => {this.togglePremiumServicesDialog(false)}}
        handleCopyLink={this.props.handleCopyLink}
      />
</div>;
/*
      <img src={prototyping} className="App-logo" alt="prototyping" align="center" onClick='alert("shuzbug")' />



MJE here - playing around with potential Help and Settings buttons
import help from './images/help.jpg';
import settings from './images/settings.jpg';

<td width = "10%" align="left">
  <td>
  <NavLink title={this.props.translate("settings")} href="https://docs.google.com/document/d/19LrNu99o_Hh9Qso6jOSd_cOAMYJhADXvuAlh2h50NSc/edit?usp=sharing">
      <img src={settings} height="30" width="30" className="company-logo" alt="settings" align="center" />
  </NavLink> <br /><br />
  </td>
  <td>
  <NavLink title={this.props.translate("help")} href="https://docs.google.com/document/d/19LrNu99o_Hh9Qso6jOSd_cOAMYJhADXvuAlh2h50NSc/edit?usp=sharing">
      <img src={help} height="30" width="30" className="company-logo" alt="help" align="center" />
  </NavLink> <br /><br />
  </td>
</td>



                                                      <Dropdown direction="down" isOpen={this.state.searchOpen} toggle={() => { this.setState({ searchOpen: !this.state.searchOpen }); }}>
                                                        <DropdownToggle caret className="dropdownToggle"><Translate id="generalSearch.caption" />&nbsp;</DropdownToggle>
                                                        <DropdownMenu className="dropdownItem">
                                                          <DropdownItem href="/" className="dropdownItem"><Translate id="simpleSearch.caption" /></DropdownItem>
                                                          <DropdownItem divider className="dropdownItemFuture" />
                                                          <DropdownItem disabled href="/" className="dropdownItemFuture"><Translate id="advancedSearch.caption" /></DropdownItem>
                                                        </DropdownMenu>
                                                      </Dropdown>



                                                        <ButtonToolbar>

    <Dropdown>
      <DropdownToggle caret>
        Dropdown
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Header</DropdownItem>
        <DropdownItem disabled>Action</DropdownItem>
        <DropdownItem>Another Action</DropdownItem>
        <DropdownItem divider />
        <DropdownItem>Another Action</DropdownItem>
      </DropdownMenu>
    </Dropdown>
                                                        </ButtonToolbar>

                                                          <Dropdown id="dropdown-custom-2">
                                                            <Button>mix it up style-wise</Button>
                                                            <Dropdown.Menu>
                                                              <MenuItem eventKey="1">Action</MenuItem>
                                                              <MenuItem eventKey="2">Another action</MenuItem>
                                                              <MenuItem eventKey="3" active>
                                                                Active Item
                                                              </MenuItem>
                                                              <MenuItem divider />
                                                              <MenuItem eventKey="4">Separated link</MenuItem>
                                                            </Dropdown.Menu>
                                                          </Dropdown>

                                                          <Dropdown id="dropdown-custom-2">
                                                            <Button bsStyle="info">mix it up style-wise</Button>
                                                            <Dropdown.Toggle bsStyle="success" />
                                                            <Dropdown.Menu className="super-colors">
                                                              <MenuItem eventKey="1">Action</MenuItem>
                                                              <MenuItem eventKey="2">Another action</MenuItem>
                                                              <MenuItem eventKey="3" active>
                                                                Active Item
                                                              </MenuItem>
                                                              <MenuItem divider />
                                                              <MenuItem eventKey="4">Separated link</MenuItem>
                                                            </Dropdown.Menu>
                                                          </Dropdown>
                                                        </ButtonToolbar>

                                                          <Dropdown id="dropdown-custom-1">

                                                            <Dropdown.Toggle>
                                                              Pow! Zoom!
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="super-colors">
                                                              <MenuItem eventKey="1">Action</MenuItem>
                                                              <MenuItem eventKey="2">Another action</MenuItem>
                                                              <MenuItem eventKey="3" active>
                                                                Active Item
                                                              </MenuItem>
                                                              <MenuItem divider />
                                                              <MenuItem eventKey="4">Separated link</MenuItem>
                                                            </Dropdown.Menu>
                                                          </Dropdown>

                                                  <td align="right" width="36%"></td>
                                                  <td align="right" width="9%"><Link to="/Finders" className="navbar-caption">כלים</Link></td>
                                                  <td align="right" width="17%"><Link to="/Finders" className="navbar-caption">דיווח ותרשימים</Link></td>
                                                  <td align="right" width="17%"><Link to="/Finders" className="navbar-caption">חיפושים נושאיים</Link></td>
                                                  <td align="right" width="21%"><Link to="/Finders" className="navbar-caption"><b>חיפוש תנ&quot;ך כללי</b></Link></td>


 <NavbarBrand className = "header-large" tag={Link} to="/">בייס הספר</NavbarBrand>


     <Navbar className = "navbar-header" expand="md">
      <Collapse isOpen={this.state.isOpen} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem  className="navbar-text" >
            <i>powered (<span className="bsd">בס&quot;ד)</span> by</i>
          </NavItem>
          <NavItem>
            <NavLink className="navbar-header" href="http://taryaganalytics.com">
              <img src={ta} height="40" width="95" className="companyLogo" alt="companyLogo" align="center" /></NavLink>
          </NavItem>
          <span>&nbsp;&nbsp;</span>
          <NavItem>
            <NavLink className="navbar-header" href="https://he.wikisource.org/wiki/מקראות_גדולות">WikiSource תורה</NavLink>
          </NavItem>
        </Nav>
      </Collapse>

      <table align="center" className = "navbar-component" width="40%">
          <tbody>
              <tr>
                  <td width="23%" align="center"><img src={prototyping} className="App-logo" alt="prototyping" align="center"/></td>
                  <td width="23%">
                      <table align="right">
                          <tbody>
                              <tr className = "navbar-component">
                                  <td align="center">לפום</td>
                              </tr>
                              <tr className = "navbar-component">
                                  <td align="center">צערא</td>
                              </tr>
                              <tr className = "navbar-component">
                                  <td align="center">אגרא</td>
                              </tr>
                          </tbody>
                      </table>
                  </td>
                  <td width="8%" align="center"><img src={logo} className="App-logo" alt="logo" align="center"/></td>
                  <td width="46%">
                      <table align="left">
                          <tbody>
                              <tr className = "navbar-component">
                                  <td align="center">הפך בה</td>
                              </tr>
                              <tr className = "navbar-component">
                                  <td align="center">והפך בה</td>
                              </tr>
                              <tr className = "navbar-component">
                                  <td align="center">דכלא בה</td>
                              </tr>
                          </tbody>
                      </table>
                  </td>
              </tr>
          </tbody>
      </table>
      <table align="center" className = "navbar-text" width="15%">
          <tbody>
              <tr>
                  <td>
                     <Button color="link"><Link to="/Finders">חיפושים נושאיים</Link></Button>
                     <Button color="link"><Link to="/Finders"><b>חיפוש תנ&quot;ך כללי</b></Link></Button>
                  </td>
              </tr>
          </tbody>
      </table>


      <NavbarToggler onClick={this.toggle}/>
      <NavbarBrand className = "navbar-header-large" tag={Link} to="/">בייס הספר</NavbarBrand>

                                                        <DropdownMenu className="dropdownItem">
                                                          <DropdownItem href="/Finders" className="dropdownItem"><Translate id="parshios.caption" /></DropdownItem>
                                                          <DropdownItem divider className="dropdownItem" />
                                                          <DropdownItem href="/Finders" className="dropdownItem"><Translate id="pesukim.caption" /></DropdownItem>
                                                        </DropdownMenu>




    </Navbar>; */
  }
}
//might need withRouter too
AppNavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

//export default withRouter(withLocalize(AppNavBar));
export default withStyles(styles)(withRouter(withLocalize(AppNavBar)));


/*

//                                        <div className="beta-test-message-parent">
//                                          <div style={{ padding: '10px 0'}}>
//                                            <span className="betaText">BETA<br/>TES</span><span className="betaUpsideDown">T</span>
//                                          </div>
//                                        </div>

//                                      <div style={{ padding: '10px 0'}}>
//                                        <span className="betaText">BETA<br/>TES</span><span className="betaUpsideDown">T</span>
//                                      </div>

                                                        <ButtonToolbar className="float-right">

                                                      <Dropdown direction="down" isOpen={this.state.keilimOpen} toggle={() => { this.setState({ keilimOpen: !this.state.keilimOpen }); }}>
                                                        <DropdownToggle caret className="dropdownToggle"><Translate id="tools.caption" />&nbsp;</DropdownToggle>
                                                        <DropdownMenu className="dropdownItemFuture">
                                                          <DropdownItem className="dropdownItem" onClick={() => {this.props.extOpenTaamParser(1)}}><Translate id="taamParser" /></DropdownItem>
                                                          <DropdownItem disabled href="/" className="dropdownItemFuture" ><Translate id="textAnnotator.caption" /></DropdownItem>
                                                        </DropdownMenu>
                                                      </Dropdown>
                                                      <Dropdown direction="down" isOpen={this.state.staticContentOpen} toggle={() => { this.setState({ staticContentOpen: !this.state.staticContentOpen }); }}>
                                                        <DropdownToggle caret className="dropdownToggle"><Translate id="staticContent.caption" />&nbsp;</DropdownToggle>
                                                        <DropdownMenu className="dropdownItem">
                                                            <DropdownItem href={this.baseURL+"/#/"} className="dropdownItem"><Translate id="parshios.caption" /></DropdownItem>
                                                        </DropdownMenu>
                                                      </Dropdown>
                                                      <Dropdown direction="down" isOpen={this.state.reportOpen} toggle={() => { this.setState({ reportOpen: !this.state.reportOpen }); }}>
                                                        <DropdownToggle caret className="dropdownToggle"><Translate id="reportsCharts.caption" />&nbsp;</DropdownToggle>
                                                        <DropdownMenu className="dropdownItem">
                                                            <DropdownItem href={this.baseURL+"/#/frequencyViewer"} className="dropdownItem"><Translate id="frequency.caption" /></DropdownItem>
                                                        </DropdownMenu>
                                                      </Dropdown>
                                                      <Dropdown direction="down" isOpen={this.state.topicSearchOpen} toggle={() => { this.setState({ topicSearchOpen: !this.state.topicSearchOpen }); }}>
                                                        <DropdownToggle caret className="dropdownToggle"><Translate id="topicalSearch.caption" />&nbsp;</DropdownToggle>
                                                        <DropdownMenu className="dropdownItemFuture">
                                                          <DropdownItem disabled href="/" className="dropdownItemFuture"><Translate id="similarFinder.caption" /></DropdownItem>
                                                          <DropdownItem divider className="dropdownItemFuture" />
                                                          <DropdownItem disabled href="/" className="dropdownItemFuture"><Translate id="associatedPhraseFinder.caption" /></DropdownItem>
                                                          <DropdownItem divider className="dropdownItemFuture" />
                                                          <DropdownItem disabled href="/" className="dropdownItemFuture"><Translate id="lcsFinder.caption" /></DropdownItem>
                                                          <DropdownItem divider className="dropdownItemFuture" />
                                                          <DropdownItem href={this.baseURL+"/#/textComparator"} className="dropdownItem"><Translate id="textComparator.caption" /></DropdownItem>
                                                          <DropdownItem divider className="dropdownItemFuture" />
                                                          <DropdownItem disabled href="/" className="dropdownItemFuture"><Translate id="mayseAvos.caption" /></DropdownItem>
                                                        </DropdownMenu>
                                                      </Dropdown>
                                                        </ButtonToolbar>

*/