import React, { Component } from 'react';

class POS_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal"><b>פרטים עבור כרטיסיית חלקי דיבור ועוד:</b> <i><span
        style={{color: 'black'}}>(הגדרות אלו ייחודיות לכל ביטוי חיפוש)</span></i></p>

        <p className="MsoNormal"><span style={{fontSize: '12.0pt', fontFamily:'"Times New Roman",serif'}}><img
        width={624} height={130} id="Picture 58"
        src="helpDialogImages/partsOfSpeech.jpg" /></span></p>

        <p className="MsoNormal">תיבות מרובות-בחירה, תיבות סימון ובקרות מחוון מופיעות עבור הגדרות להלן:</p>

        <p className="MsoListParagraphCxSpFirst" style={{marginRight:'.5in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt',fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span><b>&nbsp;&nbsp;&nbsp;&nbsp;
סוג מילה ולשון
        </b> (<i>מימין עליון</i>)</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
סוג מילה - בחר אחד או יותר מחלקי הדיבור, למשל. שם עצם, פועל, שם תואר. זה גם מציע בחירה "לא מסווג" שתכלול מילים שטרם סווגו בבסיס נתונים שלנו.
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
לשון – בחר עברית או ארמית
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.5in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt',fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span><b>&nbsp;&nbsp;&nbsp;&nbsp;
הגדרות נטייה לגוף המילה ולסיומת שלה
          </b> (<i>מימין תחתון</i>)
         </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
בנין - (רק לפועלים) - בחר אחד או יותר מ: פעל, נפעל, הפעיל, וכו'
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
זמן - (רק לפועלים) – עבר, הווה, עתיד, צווי
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
מספר – יחיד, רבים, זוגי
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
גוף – מדבר, נוכח, נסתר
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
מין – זכר, נקבה
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.5in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt',fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span><b>&nbsp;&nbsp;&nbsp;&nbsp;
מוספיות – קידומיות, סיומיות, ותוכיות
        </b> (<i>
מאמצע עליון
        </i>)</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
קידומת – בחר ללא קידומת (X), כל קידומיות (*), או סוג קידומת אחד או יותר
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
סיומת – תיבת סימון שמרשה בחירת כן (סימן ביקורת), לא (X) או לא נוגע (ריק)
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
תוכית – (אותיות שנוספו לאמצע מילה) – כן, לא, לא נוגע
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.5in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt',fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span><b>&nbsp;&nbsp;&nbsp;&nbsp;
סוג אבייקט \ סוג מספרי
        </b> (<i>
ממרכז אמצע
        </i>)
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
סוג אבייקט – בחר אחד או יותר מ: איש, אשה, עיר, עם, וכו'
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
מספרים – מספר (כללי), יסודי, סדורי, שברירי
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.5in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt',fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><b>מחוון הגדרות חיפוש</b> (<i>למטה במרכז</i>)<br/>
מתחת לתפריטים הנפתחים יש מחוון דומה מאוד לזה שבסרגל ההגדרות מימין. לאפשרות זו יש אותן אפשרויות,
 אך היא חלה רק על המילה שנבחרה עבור הבחירות הללו.
  אם המילה שצוינה חייבת להיות בעלת רמת הגדרת חיפוש שונה,
   סמן את תיבת הסימון מימין למחוון ובחר את הרמה הרצויה.
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.5in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt',fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span><b>&nbsp;&nbsp;&nbsp;&nbsp;
הגדרות נוספות
        </b> (<i>
משמאל
        </i>)-
כל ההגדרות הללו ניתנות לשנות באמצעות החלקת הכפתורים לנקודה הרצויה או לחיצה על מספר והקלדת המספר הרצוי.
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
שכיחות - בחר טווח למספר הפעמים שהביטוי יכול להתרחש בתנ"ך
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
אותיות - טווח מותר של מספר אותיות במונח
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
נקודות - טווח מותר של מספר נקודות במונח
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
תנועות - טווח מותר של מספר תנועות במונח
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
גמטריא – טווח מותרי לערכי גמטריא רגילים לביטוי
        </p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginRight:'.9in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>
        </span></span>&nbsp;&nbsp;&nbsp;
אתבש - טווח מותרי לערכי גמטריא אתבש
        </p>

        <p className="MsoNormal"><span style={{fontSize:'12.0pt', fontFamily:'"Times New Roman",serif'}}>&nbsp;</span></p>

        <p className="MsoNormal"><i>
הערה: נכון ליציאת הכרטיסיית "חלקי דיבור" ביוני 2020, מידע דקדוקי וחלקי דיבור אינו מסווג ומדויק במלואו. לעת עתה, חלק זה של הכרטיסייה צריך להיחשב כניסיוני.
        </i></p>

      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b>Details for the Parts of Speech++ tab:</b> <i><span
        style={{color: 'black'}}>(these settings are unique to each search term)</span></i></p>

        <p className="MsoNormal"><span style={{fontSize: '12.0pt', fontFamily:'"Times New Roman",serif'}}><img
        width={624} height={130} id="Picture 58"
        src="helpDialogImages/partsOfSpeech.jpg" /></span></p>

        <p className="MsoNormal">Drop-down multi-select boxes, check boxes and slider controls
        appear for the following settings:</p>

        <p className="MsoListParagraphCxSpFirst" style={{textIndent:'-.25in'}}><span style={{fontSize:'12.0pt',fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><b>Word type and Language</b> (<i>upper right</i>)</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Word type – select one or more parts of
        speech eg. noun, verb, adjective. This also offers an “uncategorized” selection
        to include words that haven’t yet been categorized in our database.</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Language – can select Hebrew and/or Aramaic</p>

        <p className="MsoListParagraphCxSpMiddle" style={{textIndent:'-.25in'}}><span style={{fontSize:'12.0pt',fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><b>Word inflection settings for both the
        main body of a word and for its suffix</b> (<i>lower</i> <i>right</i>)</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Binyan (action/voice) - (verbs only) - select
        one or more of pa’al, nif’al, hif’il, etc.</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Tense – (verbs only) eg. past, present,
        future,.</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Number – eg. singular, plural.</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Person – first, second, third.</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Gender – male, female.</p>

        <p className="MsoListParagraphCxSpMiddle" style={{textIndent:'-.25in'}}><span style={{fontSize:'12.0pt',fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><b>Affixes – prefixes, suffixes and</b> <b>infixes</b> (<i>upper middle</i>)</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Prefix – select none (X), all (*),<span
        dir="RTL"></span><span dir="RTL" style={{fontFamily:'"Times New Roman",serif'}}><span
        dir="RTL"></span> </span>or one or more types of prefixes.</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Suffix – checkbox which allows one to
        specify Yes (check), No (X) or irrelevant (blank).</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Infix (letters added to the middle of a word)
        - checkbox for Yes, No, irrelevant.</p>

        <p className="MsoListParagraphCxSpMiddle" style={{textIndent:'-.25in'}}><span style={{fontSize:'12.0pt',fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><b>Named objects / Numeric word categories</b> (<i>centered middle</i>)</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Object category – select one or more of Man,
        Woman, City, Nation, etc.</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Number category – general number, cardinal,
        ordinal, fractional.</p>

        <p className="MsoListParagraphCxSpMiddle" style={{textIndent:'-.25in'}}><span style={{fontSize:'12.0pt',fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><b>Search Definitions Slider</b> (<i>lower middle</i>)<br/>Below the dropdowns is a slider very similar to the one in the Settings Bar on the right. This one has the same choices,
        but it applies only to the word that is selected for these selections. If the specified word must have a different search definition level, check the
        checkbox to the right of the slider and choose the desired level.</p>

        <p className="MsoListParagraphCxSpMiddle" style={{textIndent:'-.25in'}}><span style={{fontSize:'12.0pt',fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><b>Other settings</b> (<i>left</i>)- All of these settings can be changed via sliding the buttons to the desired point or clicking on a number and typing the desired number.</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Frequency – select a range for the number of
        times the term can occur in Tanach.</p>

        <p className="MsoListParagraphCxSpLast" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Letters – allowable range of number of
        letters in the term.</p>

        <p className="MsoListParagraphCxSpLast" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Nekudos – allowable range of number of
        nekudos in the term.</p>

        <p className="MsoListParagraphCxSpLast" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Vowels – allowable range of number of
        vowels in the term.</p>
        
        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Gematria – allowable range of standard
        gematria values for the term.</p>

        <p className="MsoListParagraphCxSpMiddle" style={{marginLeft:'1.0in', textIndent:'-.25in'}}><span style={{fontSize:'12.0pt', fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
        </span></span>Atbash – allowable range of atbash gematria.</p>

        <p className="MsoNormal"><span style={{fontSize:'12.0pt', fontFamily:'"Times New Roman",serif'}}>&nbsp;</span></p>

        <p className="MsoNormal"><i>Note: as of the initial release of this tab in June 2020,
        grammatical and part-of-speech information is not fully categorized and accurate.
        For now, this portion of the tab should be considered experimental.</i></p>

      </>
    }
    return retVal;
  }

  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default POS_Instructions;