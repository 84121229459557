import React, { Component } from 'react';

class Team extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p /><h5>&nbsp;&nbsp;חברים עיקריים</h5><p />
        <ul>
          <li>
            <b>
              משה אסקוט:
            </b>&nbsp;
            מייסד ואדריכל תוכנה ראשי
          </li>
          <li>
            <b>
              סטייסי אסקוט:
            </b>&nbsp;
            עוזרת תמיכה בכירה
          </li>
          <li>
            <b>
              עדינה אסקוט:
            </b>&nbsp;
            מתמחה בתכנות
          </li>
        </ul>
        <p /><h5>&nbsp;&nbsp;חברי עבר</h5><p />
        <ul>
          <li>
            <b>
              מרים אסקוט:
            </b>&nbsp;
            עוזרת מחקר, הזנת נתונים ויצירה
          </li>
          <li>
            <b>
              ארי אברמוביץ:
            </b>&nbsp;
            מתמחה בתכנות (קיץ תשע"ט), מפתח תוכנה (אלול תשע"ט - אייר תש"פ), סגן (אב-אלול תש"פ)
          </li>
          <li>
            <b>
              יעקב לוונשטיין:
            </b>&nbsp;
            מתמחה בתכנות (קיץ תשע"ט)
          </li>
          <li>
            <b>
              ישראל לוונטל:
            </b>&nbsp;
            מתמחה בתכנות (קיץ תשע"ט)
          </li>
          <li>
            <b>
              שלמה גורדון:
            </b>&nbsp;
            מתמחה בתכנות (קיץ תש"פ)
          </li>
          <li>
            <b>
              הרב שמחה שאום:
            </b>&nbsp;
            מתמחה בתכנות (קיץ תש"פ)
          </li>
          <li>
            <b>
              שם טוב שפירא:
            </b>&nbsp;
            מפתח תוכנה (תמוז-אב תש"פ)
          </li>
          <li>
            <b>
              אהרן פארנטראג:
            </b>&nbsp;
            מתמחה בתכנות (קיץ תש"פ)
          </li>
        </ul>
      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <p /><h5>&nbsp;&nbsp;Principal Members</h5><p />
        <ul>
          <li><b>Moshe Escott</b>: founder and chief software architect</li>
          <li><b>Stacey Escott</b>: senior support assistant</li>
          <li><b>Adina Escott</b>: programming intern</li>
        </ul>
        <p /><h5>&nbsp;&nbsp;Past Members</h5><p />
        <ul>
          <li><b>Miriam Escott</b>: research, data entry and creative assistant</li>
          <li><b>Ari Abramowitz</b>: programming intern (Summer 2019); software developer (Sep. 2019 - Apr. 2020); relief pitcher (Aug. 2020)</li>
          <li><b>Yaakov Lowenstein</b>: programming intern (Summer 2019)</li>
          <li><b>Yisrael Lowenthal</b>: programming intern (Summer 2019)</li>
          <li><b>Shlomo Gordon</b>: programming intern (Summer 2020)</li>
          <li><b>Rabbi Simcha Schaum</b>: programming intern (Summer 2020)</li>
          <li><b>Shem Tov Shapiro</b>: software developer (Jul. 2020)</li>
          <li><b>Aaron Farntrog</b>: programming intern (Summer 2020)</li>
        </ul>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default Team;