import React, { Component } from 'react';

class RightBar_Instructions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>לוח
              הגדרות חיפוש</span></b></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>
כל ההגדרות הללו חלות על החיפוש הבא ולא ישנו את מראה התוצאות הנוכחיות. על מנת להחיל את השינויים על קבוצת התוצאות, לחץ על סמל החיפוש בשורת החיפוש כדי לקבל את התוצאות החדשות עם שינויי ההגדרות.
        </p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>&nbsp;</p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><img width={143} height={212} src="helpDialogImages/image011.jpg" align="left" hspace={12} /><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בצד
            ימין של המסך
            מופיעה
            בחירת הגדרות
            שונות החלות
            לא רק על
            חיפושים
            בתנ"ך אלא גם
            על כל אחד
            ממסכי העמודי
            תוכן. החלק
            העליון מכיל
            בחירה אחת עד
            רבות של
            הרכיבים שיש
            לקחת בחשבון</span><span dir="LTR" /><span lang="HE" dir="LTR"><span dir="LTR" /> </span><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>בחיפוש,
            דוח או רישום:</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal">המחוון הגדרות חיפוש קובע את רמת הדיוק שהמשתמש ירצה עבור מונחי החיפוש.
        <br/>יש ארבע אפשרויות.
        <br/>1) מדויק- המילים בתוצאות צריכות להתאים בדיוק למונח החיפוש.
        <br/>2) חצי מדויק- המילים בתוצאות יכולות לכלול קידומות וסיומות יחד עם מונח החיפוש.
        <br/>3) אותיות- המילים צריכות להתאים לאותיות של מונח החיפוש.
        <br/>4) מעורפל- מילים יכולות להיחשב כהתאמה אם יש בהן את האותיות של מונח החיפוש באיזה סדר.
        </p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>דרך
            אחת לחיפוש
            היא על ידי צורת
            <b> תיבה</b> גולמית
            ע"פ <a name="_Hlk35479055">אותיות</a>,
            נקודות
            וטעמים.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><a name="_Hlk35526348"><b><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>אותיות
              </span></b></a><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>-
            שוקל את
            האותיות
            הגולמיות של
            מילה. בחירת <b>נקודות </b>
            תשקול נקודות
            ו<b>טעמים </b>
            תשקול טעמי
            המקרא. ניתן
            לבחור כל אחד
            מאלה בעצמם או
            בשילוב עם האחר.
            דרך חלופית
            לחיפוש היא על
            ידי <b>שורש</b>.
            דרך חלופית
            נוספת היא
            חיפוש לפי <b>גימטריא </b>
            שיכולה להיות <b>רגיל </b>
            או <b>אתבש</b>.
            ברירת המחדל
            היא חיפוש לפי
            אותיות בלבד.</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}>
מתחת לזה, יש תפריט נפתח לבחירה כדי לשלוט במיון תוצאות החיפוש. ברירת המחדל היא הופעה. בחירה בערך מיון אחר תמיין את קבוצת תוצאות החיפוש הבאה בהתאם. מתחת לתפריט הנפתח יש סליידר כך שהמשתמש יכול לבחור בין מיון התוצאות בסדר עולה או יורד.
        </p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR">&nbsp;</span></p>
        <p className="MsoNormal" dir="RTL" style={{textAlign: 'right', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontFamily: '"Arial",sans-serif'}}>החלק
            התחתון של
            הגדרות
            החיפוש מכיל
            מסנן ע"י עצי
            בחירה. כברירת
            מחדל, כל ספרי
            תנ"ך מסומנים
            ונכללים בחיפושים,
            אך ניתן לבחור
            ספרים
            ספציפיים או
            קבוצות ספרים
            ולהרחיב את
            הבחירות הללו
            על ידי לחיצה
            על החצים
            המשולשים.</span></p>
        <p className="MsoNormal">&nbsp;</p>
        
      </>
    } else if (langCode === "en") {
      retVal = <>

        <p className="MsoNormal"><b>Search settings panel</b></p>
        <p className="MsoNormal">All of these settings apply to the next search and will not change the appearance of the current results.
        In order to apply the changes to the set of results, click the search icon in the search bar to get the new results with the settings changes.</p>
        <p className="MsoNormal">&nbsp;</p>
<p className="MsoNormal"><img width={143} height={282} src="helpDialogImages/rightBarSettings.jpg" align="right" hspace={12} />To the
          right of the screen appear various setting selections that apply not only to
          Tanach searches but also to each of the page content screens. The top part
          contains a one to many selections of what components to factor into a search,
          report or listing:</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">The Search Definitions slider sets the level of exactness the user would like for the search terms.
        <br/>There are four choices. 
        <br/>1) Exact- The words in the results should match the search term exactly.
        <br/>2) Semi Exact- The words in the results can include prefixes and suffixes along with the search term.
        <br/>3) Letters- The words should match the letters of the search term.
        <br/>4) Fuzzy- Words can be considered a match if they have the letters of the search term in any order.</p>

        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">One way of searching is by raw <b>word</b> form via Letters, Nekudos and Taamim (L,
          N, T)</p>
        <p className="MsoNormal"><b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>אותיות</span></b><span dir="LTR" /><span dir="LTR" /> – considers the raw letters of a word (L).&nbsp;
          Selecting <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>נקודות</span></b><span dir="LTR" /><span dir="LTR" /> will consider vowels (N) and <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>טעמים</span></b><span dir="LTR" /><span dir="LTR" /> will consider trop (T).&nbsp; Each of these can
          be selected on their own or in conjunction with the other. An alternate way of
          searching is by <b><span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>שורש</span></b><span dir="LTR" /><span dir="LTR" /> or word root (S).&nbsp; Another alternate way
          is to search by <b>gematria</b> (G) which can be <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><b>רגיל</b></span><span dir="LTR" /><span dir="LTR" /> (standard) or <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}><b>אתבש</b></span><span dir="LTR" /><span dir="LTR" /> (atbash). The default is to search by
          letters only.</p>
        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">Underneath that, there is a select dropdown to control the sorting of the search results. The default is Appearance.
        Choosing a different sort value will sort the next set of search results accordingly. Below the dropdown is a slider so that the user can choose 
        between sorting the results in ascending order or descending order. </p>

        <p className="MsoNormal">&nbsp;</p>
        <p className="MsoNormal">The bottom part of the search setting contains a drill-down
          search filter.&nbsp; By default, all <span lang="HE" dir="RTL" style={{fontFamily: '"Arial",sans-serif'}}>ספרי
            תנ"ך</span><span dir="LTR" /><span dir="LTR" />
          &nbsp;are checked and included in searches but one can pick and choose by individual
          sefarim and by groups of sefarim and expanding these selections by clicking on
          the triangular arrows. </p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default RightBar_Instructions;