import React, { Component } from 'react';

class FeaturesServices extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <i>לפעמים למשתמש יהיה צורך מאוד ספציפי שלא ניתן לבצע על ידי האתר שלנו.
          אנו מציעים את השירותים הבאים בתשלום:</i><br /><br />
        <ul>
          <li><b>חיפוש יחיד מותאם</b>: אם נוודא כי ניתן לבצע את החיפוש באמצעות האתר, אנו נייעץ כיצד לבצע את החיפוש. אם לא, נוכל לבצע חיפוש נגד בסיס הנתונים הפנימי שלנו תמורת תשלום.</li>
          <li><b>מחקר בהתאמה אישית</b>: אם הצורך מסתכם בפרויקט בניגוד לחיפוש בודד (למשל ליצור מערך דוחות), נוכל להציע לבצע את המחקר מול בסיס הנתונים הפנימי שלנו תמורת תשלום.</li>
          <li>
            <b>בקשת תכונה מזורזת</b>: יש לנו רשימה של תכונות אפשריות שייקח לנו שנים לפתח ולהוסיף למערכת. למשתמש עשוי להיות רעיון או צורך בזמן מסוים המתאים למטרות בייס-הספר.
            אם תתקבל בקשה כזו, אנו נחייב תשלום עבור יישום התכונה בתאריך מוסכם.
          </li>
        </ul>
        <i>תוכלו ליצור איתנו קשר ב <b>info@taryaganalytics.com</b> עם בקשות לשירותים מותאמים אישית.</i>

      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <i>Sometimes a user will have a very specific need which can't be performed by our site. 
          We offer the following paid services:</i><br /><br />
        <ul>
          <li><b>Custom single search</b>: If we determine that the search can be done via the web site, we will advise how to perform the search.  If not, then we can do the search against our internal database for a fee.</li>
          <li><b>Custom research</b>: If the need amounts to a project as opposed to a single search (eg. create a set of reports), then we can offer to do the research against our internal database for a fee.</li>
          <li>
            <b>Expedited feature request</b>: We have a list of possible features that would take us years to develop and add to the system. A user might have a particular timely idea or need that would fit into the goals of Base HaSefer. 
            If such a request is made, we would charge a fee for implementing the feature by an agreed-upon date.
          </li>
        </ul>
        <i>You may contact us at <b>info@taryaganalytics.com</b> with requests for custom services.</i>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default FeaturesServices;