import React, { Component, useState } from "react";
import "./css/App.css";
import * as util from "./newUtil.js"; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import {
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Popover
} from "@material-ui/core";
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';

import { makeStyles } from "@material-ui/core/styles";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const styles = (theme) => ({
  toggleContainer: {
    height: 28,
    backgroundColor: "transparent",
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    alignItems: "center",
    margin: `auto`,
    background: theme.palette.background.default,
  },
});

class YuchsinSectionSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textSelectorDialogOpen: false,
      renderVals: {
        textSelector: [],
      },
      textSelectorData: [],
      sourcesPopoverIsOpen: false,
      sourcesPopoverAnchorEl: null
    };

    this.textSelectorGridReady = this.textSelectorGridReady.bind(this);
    this.addTextSelectorRow = this.addTextSelectorRow.bind(this);
    this.extAddTextSelectorRow = this.extAddTextSelectorRow.bind(this);
    this.deleteTextSelectorRow = this.deleteTextSelectorRow.bind(this);
    this.extDeleteTextSelectorRow = this.extDeleteTextSelectorRow.bind(this);
    this.extModifyTextSelectorRow = this.extModifyTextSelectorRow.bind(this);
    this.modifyTextSelectorRow = this.modifyTextSelectorRow.bind(this);
    this.getRenderVals = this.getRenderVals.bind(this);
    this.isNewRowData = this.isNewRowData.bind(this);
    this.setRenderVals = this.setRenderVals.bind(this);

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" },
      ],
      translation: translations,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage: this.props.activeLanguage
          ? this.props.activeLanguage.code
          : "en",
      },
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {
      props.setActiveLanguage(code);
    };
  }

  textSelectorGridReady = (params) => {
    this.props.textSelectorGridReady(params);
  };

  toggleSourcesPopover(e, bool) {
    var sourcesPopoverAnchorEl = null;
    if (e) {
      sourcesPopoverAnchorEl = e.currentTarget.parentElement
    };
    this.setState({ sourcesPopoverIsOpen: bool, sourcesPopoverAnchorEl })
  }

  addTextSelectorRow() {
    this.props.addTextSelectorRow();
  }

  extAddTextSelectorRow = () => {
    this.addTextSelectorRow();
  };

  deleteTextSelectorRow(extState) {
    this.props.deleteTextSelectorRow(extState);
  }

  extDeleteTextSelectorRow = (extState) => {
    this.deleteTextSelectorRow(extState);
  };

  extModifyTextSelectorRow = (extState) => {
    this.modifyTextSelectorRow(extState);
  };

  modifyTextSelectorRow(extState) {
    this.props.modifyTextSelectorRow(extState);
  }

  async toggleTextSelectorDialog(bool) {
    if (bool === false) await this.setRenderVals();
    this.setState({ textSelectorDialogOpen: bool });
  }

  isNewRowData() {
    var prevTextSelectorDataStr = JSON.stringify(this.state.textSelectorData);
    var newTextSelectorDataStr = JSON.stringify(this.props.textSelectorData);
    var newTextSelectorData =
      prevTextSelectorDataStr !== newTextSelectorDataStr;
    if (newTextSelectorData) {
      this.setState({
        textSelectorData: this.props.textSelectorData,
      });
      return true;
    } else {
      return false;
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.isNewRowData()) {
      await this.setRenderVals();
    }
  }

  async setRenderVals() {
    const fakeThis = this;
    var renderVals = {
      textSelector: [],
    };

    await this.props.textSelectorData.map(async function (e, index) {
      var currentRow = fakeThis.props.textSelectorData[index];
      var currRenderVals = await fakeThis.getRenderVals(currentRow);
      renderVals.textSelector.push(currRenderVals);
    });

    this.setState({
      renderVals: renderVals,
    });
  }

  async getRenderVals(textDataRow) {
    if (textDataRow) {
      if (!!textDataRow.then) {
        // when filling in selector automatically from context menu, textDataRow is a promise and this needs to wait for the response
        textDataRow = await textDataRow;
      }
      var renderVals = this.props.getRenderVals(textDataRow);
      return renderVals;
    }
  }

  render() {
    const { textSelectorData } = this.props;
    const selectSections = this.props.translate("selectSections");
    const expand = this.props.translate("expand");
    const close = this.props.translate("close");

    const fakeThis = this;
    var isHebrew = localStorage.getItem("language") === "he";

    var directionStyling = { direction: "ltr", textAlign: "left" };
    var langDirection = "ltr";
    if (isHebrew) {
      directionStyling = { direction: "rtl", textAlign: "right" };
      langDirection = "rtl";
    }

    return (
      <span>
        <Paper
          style={{
            width: "fit-content",
            display: "inline-block",
          }}
          elevation={1}
          id="yuchsin-tree-sources-paper"
        >
          <Button
            variant="contained"
            color="default"
            className="yuchsin-tree-source-selector-button"
            type="submit"
            style={{ margin: "0 5px" }}
            onClick={() => this.toggleTextSelectorDialog(true)}
          >
            {selectSections}
          </Button>
          <div style={{ width: 150, display: "inline-block" }}>
            <span className="yuchsin-tree-mini-source">

              {this.props.textSelectorData.map(function (e, index) {
                var currentRow = fakeThis.props.textSelectorData[index];
                var renderVals =
                  fakeThis.state.renderVals.textSelector[index] || [];

                if (currentRow && currentRow.type && currentRow.val1) {
                  return (
                    <Typography
                      style={{
                        display: "inline",
                      }}
                      key={index}
                    >
                      <span className="yuchsin-tree-mini-source">
                        {renderVals[0] || ""}
                        {renderVals[1] || ""}
                        {renderVals[2] || ""}
                        <b>
                          {renderVals[3] || ""}
                          {renderVals[4] || ""}
                        </b>
                      </span>
                    </Typography>
                  );
                } else if (currentRow && currentRow.type === false) {
                  return (
                    <Typography
                      style={{
                        display: "inline",
                      }}
                      key={index}
                    >
                      <span className="yuchsin-tree-mini-source">
                        <b>{`${renderVals[0] || ""} ${renderVals[1] ||
                          ""}:${renderVals[2] || ""}`}</b>
                        <small> עד </small>
                        <b>{`${renderVals[3] || ""} ${renderVals[4] ||
                          ""}:${renderVals[5] || ""}`}</b>
                      </span>
                    </Typography>
                  );
                }
              })}

            </span>

          </div>

          <Button title={expand} style={{ minWidth: 0, padding: '3px' }} onClick={(e) => { this.toggleSourcesPopover(e, true) }}>
            <UnfoldMoreIcon />
          </Button>
          <Popover
            open={this.state.sourcesPopoverIsOpen}
            anchorEl={this.state.sourcesPopoverAnchorEl}
            onClose={() => { this.toggleSourcesPopover(null, false) }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: isHebrew ? 'right' : 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: isHebrew ? 'right' : 'left',
            }}
            className="yuchsin-tree-source-selector-popover"
          >
            <div style={{ width: '460px' }}>

              <table style={{ width: "100%", direction: isHebrew ? "rtl" : "ltr" }}>
                <tr>
                  <td style={{ textAlign: isHebrew ? "right" : "left" }}>
                    <Button
                      variant="contained"
                      color="default"
                      className="yuchsin-tree-source-selector-button"
                      type="submit"
                      style={{ margin: "0 5px" }}
                      onClick={() => this.toggleTextSelectorDialog(true)}
                    >
                      {selectSections}
                    </Button>
                  </td>
                  <td style={{ textAlign: isHebrew ? "left" : "right" }}>
                    <Button title={close} style={{ minWidth: 0, padding: '3px' }} onClick={(e) => { this.toggleSourcesPopover(null, false) }}>
                      <UnfoldLessIcon />
                    </Button>
                  </td>
                </tr>
              </table>
              <div
                style={{
                  height: "80px",
                  overflow: "auto",
                  padding: "0px 40px 10px 10px",
                  width: "100%",
                }}>

                <ul style={{ marginBottom: 0 }}>
                  {this.props.textSelectorData.map(function (e, index) {
                    var currentRow = fakeThis.props.textSelectorData[index];
                    var renderVals =
                      fakeThis.state.renderVals.textSelector[index] || [];

                    if (currentRow && currentRow.type && currentRow.val1) {
                      var renderString = ``;
                      return (
                        <li
                          key={index}
                          style={{
                            direction: "rtl",
                            lineHeight: "20px",
                            textAlign: "right",
                          }}
                        >
                          <Typography style={{ lineHeight: 1 }} variant="subtitle1">
                            <small>
                              {renderVals[0] || ""}
                              {renderVals[1] || ""}
                              {renderVals[2] || ""}
                            </small>
                            <b>
                              {renderVals[3] || ""}
                              {renderVals[4] || ""}
                            </b>
                          </Typography>
                        </li>
                      );
                    } else if (currentRow && currentRow.type === false) {
                      return (
                        <li
                          key={index}
                          style={{
                            direction: "rtl",
                            lineHeight: "20px",
                            textAlign: "right",
                          }}
                        >
                          <Typography style={{ lineHeight: 1 }} variant="subtitle1">
                            <b>{`${renderVals[0] || ""} ${renderVals[1] ||
                              ""}:${renderVals[2] || ""}`}</b>
                            <small> עד </small>
                            <b>{`${renderVals[3] || ""} ${renderVals[4] ||
                              ""}:${renderVals[5] || ""}`}</b>
                          </Typography>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          </Popover>
        </Paper>

        <Dialog
          open={this.state.textSelectorDialogOpen}
          onClose={() => {
            this.toggleTextSelectorDialog(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"xl"}
          style={directionStyling}
        >
          <DialogContent>
            <div
              className="ag-theme-balham"
              style={{
                height: "205px",
                width: "1170px",
                maxWidth: "calc(100vw - 150px)",
              }}
            >
              <AgGridReact
                id="text1"
                ref="agGrid"
                rowData={textSelectorData}
                onGridReady={this.textSelectorGridReady}
                enableFilter={false}
                frameworkComponents={this.state.frameworkComponents}
                headerHeight={28}
                rowHeight={40}
                overlayNoRowsTemplate={"<span></span>"}
                enableRtl="true"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                this.toggleTextSelectorDialog(false);
              }}
              autoFocus
            >
              <Translate id="close" />
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    );
  }
}

YuchsinSectionSelector.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withLocalize(YuchsinSectionSelector));
