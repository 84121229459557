import React, { Component } from 'react';

class WhoWillBenefitIndividuals extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p /><h5>חוקרים ומחברי ספרים</h5><p />
        <ul>
          <li>פחות יגיעה וזמן שהוקדש לחיפושים ידניים</li>
          <li>קבל גישה למידע שאי אפשר להגיע אליו</li>
        </ul>
        <p /><h5>מחנכים</h5><p />
        <ul>
          <li>פיתוח רעיונות ושאלות חידון</li>
          <li>שפר את ההוראה והפך את השיעור למעניין</li>
          <li>הוציאו והבהירו נקודות ושיעורים</li>
        </ul>
        <p /><h5>הורים</h5><p />
        <ul>
          <li>להציע לילדים אפשרויות תוכנה טובות המחנכות ומעוררות מחשבה</li>
        </ul>
        <p /><h5>תלמידים</h5><p />
        <ul>
          <li>כלי למידה מהנה שיכול לעזור בשיעורי בית ולעודד חקר</li>
          <li>הכינו לבחינות</li>
        </ul>
        <p /><h5>כללי</h5><p />
        <ul>
          <li>כלי מהנה לטריוויה בתורה</li>
          <li>חזור את פרשת השבוע עם נוף כפול של תורה ותרגום</li>
          <li>קבל תובנות חדשות, הכין דברי תורה, ווארטים, וכו
            '</li>
        </ul>
        
      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <p /><h5>Researchers and mechabrei seforim</h5><p />
        <ul>
          <li>Less tedium and time spent on manual searches</li>
          <li>Get access to unreachable information</li>
        </ul>
        <p /><h5>Educators such as rebbeim and teachers</h5><p />
        <ul>
          <li>Develop ideas and quiz questions</li>
          <li>Enhance teaching and make class interesting</li>
          <li>Bring out and clarify points and lessons</li>
        </ul>
        <p /><h5>Parents</h5><p />
        <ul>
          <li>Offer children good software choices which educate and provoke thought</li>
        </ul>
        <p /><h5>Students</h5><p />
        <ul>
          <li>A fun learning tool which can help with homework and encourage exploration</li>
          <li>Prepare for tests</li>
        </ul>
        <p /><h5>General</h5><p />
        <ul>
          <li>A fun tool for Torah trivia</li>
          <li>Be maavir the sedra with multi-view</li>
          <li>Gain new insights, prepare divrei Torah, vorts, etc....</li>
        </ul>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default WhoWillBenefitIndividuals;