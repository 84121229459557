import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { Link, withRouter } from 'react-router-dom';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import LoadingOverlay from 'react-loading-overlay';
import { Paper, Typography, Dialog, Button, MenuItem, TextField, Popover } from '@material-ui/core';
import TextComparatorSectionSelector from './TextComparatorSectionSelector';
import {Spinner} from 'primereact/spinner';
import * as properties from './config/properties.js';
import { Menu, Item, Separator, Submenu, MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import ContextMenu from './ContextMenu';
import TaamParser from './TaamParser';
import ObjectCloud from './ObjectCloud';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Slider from 'rc-slider';

const createSliderWithTooltip = Slider.createSliderWithTooltip;

const styles = theme => ({
  toggleContainer: {
    height: 28,
    backgroundColor: 'transparent',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    alignItems: 'center',
    margin: `auto`,
    background: theme.palette.background.default,
  },
});

//todo this is repeated elsewhere - combine it
function encodeFilterIds(nodes, nodeString) {
    var nodeStr = (nodeString != undefined) ? nodeString : "0".repeat(44);
    nodes.forEach((node)=>{
        if (node.tableName == "Sefer" && node.isChecked)
            nodeStr = util.stuff(nodeStr, "1", node.id-1, 1);
        if(node.children){
            nodeStr = encodeFilterIds(node.children, nodeStr);
        }
    })
    return nodeStr;
}

function BaseParameters() {
  this.source = null;
  this.clientIP = null;
  this.morphInflections = null;
  this.filterIds = null;
  this.limit = 2100;
  this.minimumFrequency = 1;
  this.skipTerms = [];
  this.nGrams = null;
  this.sortResultsBy = null;
  this.resultsToDisplay = null;
  this.namedLocations =  []; //NamedLocation()
  this.namedLocations2 =  []; //NamedLocation()
  this.pasukRangeLocations = []; //PerekPasukRange()
  this.pasukRangeLocations2 = []; //PerekPasukRange()
  this.textSource = 1;
}

function NamedLocation(kriaId, aliyaId) {
  this.kriaId =  kriaId; //int
  this.aliyaId = aliyaId; //int
}

function PerekPasukRange(seferId1, perekNum1, pasukNum1, seferId2, perekNum2, pasukNum2) {
  this.seferId1 =  seferId1; //int
  this.perekNum1 = perekNum1;  //int
  this.pasukNum1 =  pasukNum1; //int
  this.seferId2 = seferId2;  //int
  this.perekNum2 =  perekNum2; //int
  this.pasukNum2 = pasukNum2;  //int
}

class TextComparatorCloudViewer extends Component {

 constructor(props) {
    super(props);
    this.state = {
      clientIP: '',
      defaultLanguage: '',
      text1DialogOpen: false,
      text2DialogOpen: false,
      textToCopy: 'bitui',
      useKinuim: false,
      searchAsQuote: false,
      includeMekorot: false,
      contextMenuMakor: "",
      contextMenuText: "",
      taamParseData: null,
      taamParserOpen: false,
      taamParserPasukId: null,
      taamParserLocation: null,
      contextMenuIsAPasuk: false,
      searchSettingsIsOpen: false,
      searchSettingsAnchorEl: null,
      wordsToSkipAnchorEl: null,
      wordsToSkipIsOpen: false,
      maxObjects: 100,
      maxObjectsDisplay: '∞',
      minFrequency: 1,
      minFrequencyDisplay: 1
    };

    this.refresh = this.refresh.bind(this);
    this.text1GridReady = this.text1GridReady.bind(this);
    this.addText1Row = this.addText1Row.bind(this);
    this.extAddText1Row = this.extAddText1Row.bind(this);
    this.deleteText1Row = this.deleteText1Row.bind(this);
    this.extDeleteText1Row = this.extDeleteText1Row.bind(this);
    this.extModifyText1Row = this.extModifyText1Row.bind(this);
    this.modifyText1Row = this.modifyText1Row.bind(this);
    this.text2GridReady = this.text2GridReady.bind(this);
    this.addText2Row = this.addText2Row.bind(this);
    this.extAddText2Row = this.extAddText2Row.bind(this);
    this.deleteText2Row = this.deleteText2Row.bind(this);
    this.extDeleteText2Row = this.extDeleteText2Row.bind(this);
    this.extModifyText2Row = this.extModifyText2Row.bind(this);
    this.modifyText2Row = this.modifyText2Row.bind(this);
    this.getRenderVals = this.getRenderVals.bind(this);
    this.handleText1DialogOpen = this.handleText1DialogOpen.bind(this);
    this.handleText1DialogClose = this.handleText1DialogClose.bind(this);
    this.handleText2DialogOpen = this.handleText2DialogOpen.bind(this);
    this.handleText2DialogClose = this.handleText2DialogClose.bind(this);
    this.onCellContextMenu = this.onCellContextMenu.bind(this);
    this.toggleUseKinuim = this.toggleUseKinuim.bind(this);
    this.toggleIncludeMekorot = this.toggleIncludeMekorot.bind(this);
    this.toggleSearchAsQuote = this.toggleSearchAsQuote.bind(this);
    this.openTaamParser = this.openTaamParser.bind(this);
    this.closeTaamParser = this.closeTaamParser.bind(this);
    this.handleOpenSearchSettings = this.handleOpenSearchSettings.bind(this);
    this.handleCloseSearchSettings = this.handleCloseSearchSettings.bind(this);
    this.handleCloseWordsToSkip = this.handleCloseWordsToSkip.bind(this);
    this.setMaxObjects = this.setMaxObjects.bind(this);
    this.setMinFrequency = this.setMinFrequency.bind(this);
    this.saveText1Data = this.saveText1Data.bind(this);
    this.saveText2Data = this.saveText2Data.bind(this);

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}
  }

  handleSelectWordToSkip(e){
    var val = e.target.attributes.value.value // e.target.value isn't working properly here
    var wordsToSkip = this.props.cloudViewerData.wordsToSkip;
    if(val === 'clearAll'){
      wordsToSkip = [];
      this.setOtherWordsToSkip({target:{value:""}})
    } else {
      if(wordsToSkip.includes(val)){
        var valIndex = wordsToSkip.indexOf(val);
        wordsToSkip.splice(valIndex, 1);
        //remove from other words
        if(this.props.cloudViewerData.otherWordsToSkip.includes(val)){
          var otherWords = this.props.cloudViewerData.otherWordsToSkip;
          var valIndex = otherWords.indexOf(val);
          otherWords.splice(valIndex, 1);
          this.props.extUpdateCloudViewerData({
            otherWordsToSkip: otherWords
          })
        }
      } else {
        wordsToSkip.push(val);
      }
    }
    wordsToSkip = wordsToSkip.sort()
    this.props.extUpdateCloudViewerData({ wordsToSkip })
  }

  setOtherWordsToSkip(e){
    var wordsString = e.target.value;
    var wordsArr = wordsString.split(" ")
    //remove old words
    var oldWords = this.props.cloudViewerData.otherWordsToSkip;
    oldWords.map(word => {
      if(!wordsArr.includes(word)){
        var e = {target: {attributes: {value: {value: word}}}}
        this.handleSelectWordToSkip(e)
      }
    })
    //add new words
    wordsArr.map(word => {
      if(!this.props.cloudViewerData.wordsToSkip.includes(word)){
        var e = {target: {attributes: {value: {value: word}}}}
        this.handleSelectWordToSkip(e)
      }
    })
    this.props.extUpdateCloudViewerData({
      otherWordsToSkip: wordsArr
    })
  }

  handleOpenSearchSettings(e){
    this.setState({
      searchSettingsIsOpen: true,
      searchSettingsAnchorEl: e.currentTarget
    })
  }

  handleCloseSearchSettings(){
    this.setState({
      searchSettingsIsOpen: false,
      searchSettingsAnchorEl: null
    })
  }
  
  handleOpenWordsToSkip(e){
    this.setState({ wordsToSkipAnchorEl: e.currentTarget, wordsToSkipIsOpen: true })
  }

  handleCloseWordsToSkip(){
    this.setState({ wordsToSkipAnchorEl: null, wordsToSkipIsOpen: false })
  }
  async openTaamParser(pasukLocation){
    this.props.extOpenTaamParser(pasukLocation) 
    const body = await this.props.extFetchTaamParseData(pasukLocation)
    this.setState({taamParseData: body})
    this.setState({taamParserOpen: true, taamParserPasukId: body.pasukId, taamParserLocation: body.pasukLocation, taamParserOpen: false}, () => {
        this.setState({taamParserOpen: true})
    })
  }

  closeTaamParser(){
    this.props.extCloseTaamParser()
    this.setState({taamParserOpen: false})
  }

  toggleSearchAsQuote(){
    this.setState({ searchAsQuote: !this.state.searchAsQuote }, () => {
      // this.formatDiffTable();
    })
  }

    text1GridReady = params => {
      this.props.text1GridReady(params);
    };

    text2GridReady = params => {
      this.props.text2GridReady(params);
    };

    addText1Row() {
      this.props.addText1Row();
    }

    addText2Row() {
      this.props.addText2Row();
    }

    extAddText1Row = () => {
        this.props.addText1Row();
    }

    extAddText2Row = () => {
        this.props.addText2Row();
    }

    deleteText1Row(extState) {
      this.props.deleteText1Row(extState);
    }

    deleteText2Row(extState) {
      this.props.deleteText2Row(extState);
    }

    extDeleteText1Row = (extState) => {
        this.props.deleteText1Row(extState);
    }

    extDeleteText2Row = (extState) => {
        this.props.deleteText2Row(extState);
    }

    extModifyText1Row = (extState) => {
        this.props.modifyText1Row(extState);
    }

    extModifyText2Row = (extState) => {
        this.props.modifyText2Row(extState);
    }

    modifyText1Row(extState) {
      this.props.modifyText1Row(extState);
    }

    modifyText2Row(extState) {
      this.props.modifyText2Row(extState);
    }

    handleText1DialogOpen() {
        this.setState({ text1DialogOpen: true })
    }

    handleText1DialogClose() {
        this.setState({ text1DialogOpen: false })
    }

    handleText2DialogOpen() {
        this.setState({ text2DialogOpen: true })
    }

    handleText2DialogClose() {
        this.setState({ text2DialogOpen: false })
    }

   getRenderVals(textDataRow){
        var renderVal1, renderVal2, renderVal3, renderVal4, renderVal5, renderVal6
        var renderVals
        if(textDataRow.type){ //is named section
            var selectedVal1 = textDataRow.valList1.filter(obj => {return obj.id === textDataRow.val1});
            var selectedVal2 = textDataRow.valList2.filter(obj => {return obj.id === textDataRow.val2});
            var selectedVal3 = textDataRow.valList3.filter(obj => {return obj.id === textDataRow.val3});
            var selectedVal4 = textDataRow.valList4.filter(obj => {return obj.id === textDataRow.val4});
            var selectedVal5 = textDataRow.valList5.filter(obj => {return obj.id === textDataRow.val5});

            if(selectedVal1[0]){ renderVal1 = selectedVal1[0].value+' > '}
            if(selectedVal2[0]){ renderVal2 = selectedVal2[0].value+' > '}
            if(selectedVal3[0]){ renderVal3 = selectedVal3[0].value+' > '}
            if(selectedVal4[0]){ renderVal4 = selectedVal4[0].value+' > '}
            if(selectedVal5[0]){ renderVal5 = selectedVal5[0].value+' > '}

            var renderVals = [renderVal1, renderVal2, renderVal3, renderVal4, renderVal5]

            for(let i = 4; i >= 0; i--){
                if(renderVals[i]){
                    renderVals[i] = renderVals[i].substring(0, renderVals[i].length - 3)
                    break
                }
                //if it is defined, set the one after it as the last value
            }

        } else { //is pasuk range
            var selectedVal1 = textDataRow.valList1.filter(obj => {return obj.seferId === textDataRow.val1});
            var selectedVal2 = textDataRow.valList2.filter(obj => {return obj.perekNum === textDataRow.val2});
            var selectedVal3 = textDataRow.valList3.filter(obj => {return obj.pasukNum === textDataRow.val3});
            var selectedVal4 = textDataRow.valList4.filter(obj => {return obj.seferId === textDataRow.val4});
            var selectedVal5 = textDataRow.valList5.filter(obj => {return obj.perekNum === textDataRow.val5});
            var selectedVal6 = textDataRow.valList6.filter(obj => {return obj.pasukNum === textDataRow.val6});

            if(selectedVal1[0]){ renderVal1 = selectedVal1[0].seferName}
            if(selectedVal2[0]){ renderVal2 = selectedVal2[0].perekValue}
            if(selectedVal3[0]){ renderVal3 = selectedVal3[0].pasukValue}
            if(selectedVal4[0]){ renderVal4 = selectedVal4[0].seferName}
            if(selectedVal5[0]){ renderVal5 = selectedVal5[0].perekValue}
            if(selectedVal6[0]){ renderVal6 = selectedVal6[0].pasukValue}

            renderVals = [renderVal1, renderVal2, renderVal3, renderVal4, renderVal5, renderVal6]
        }

        return renderVals
   }

  getNewBaseParameters(){
    var baseParams = new BaseParameters();
    var filterIds = encodeFilterIds(this.props.extFilterData);
    filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
    filterIds = util.right("00000000000"+filterIds,11);
    baseParams.nGrams = this.state.nGrams;
    baseParams.sortResultsBy = this.state.sortResultsByChosen;
    baseParams.resultsToDisplay = this.state.resultsToDisplay;
    baseParams.source = 'bhsWebApp';
    baseParams.filterIds = filterIds;
    baseParams.morphInflections = this.props.extSearchDefData; //str
    baseParams.isReverse = this.state.direction === 'reverse';
    baseParams.limit = this.state.maxObjects < 100 ? this.state.maxObjects : 2100;
    baseParams.minimumFrequency = this.state.minFrequency;
    baseParams.skipTerms = this.state.wordsToSkip;
    baseParams.namedLocations = [];
    baseParams.pasukRangeLocations = [];
    baseParams.textSource = this.props.textSource==='targum'?2:1;

    return baseParams;
  }

  async refresh() {
    this.props.extUpdateCloudViewerData({ isLoading: true }, async () => {
      var baseParams1 = this.getNewBaseParameters();
      var baseParams2 = this.getNewBaseParameters();

      var e, i;
      for (i = 0; i < this.props.text1Data.length-1; i++) { //last position in placementRowData is never populated
          e = this.props.text1Data[i];
          if (e.type) {
            // baseParams.namedLocations.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
            baseParams1.namedLocations.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
          }
          else {
            // baseParams.pasukRangeLocations.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
            baseParams1.pasukRangeLocations.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
          }
      }
  
      for (i = 0; i < this.props.text2Data.length-1; i++) { //last position in placementRowData is never populated
          e = this.props.text2Data[i];
          if (e.type) {
            // baseParams.namedLocations2.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
            baseParams2.namedLocations.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
          }
          else {
            // baseParams.pasukRangeLocations2.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
            baseParams2.pasukRangeLocations.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
          }
      }

      //this should call a new web service (getObjectCloudData)
      // that should return a data object similar to the one returned by getTextBlock along with a "data" object formatted as follows:
      //        const data = [
      //          { text: 'Hey', value: 1000 },
      //          { text: 'lol', value: 200 },
      //          { text: 'first impression', value: 800 },
      //          { text: 'very cool', value: 1000000 },
      //          { text: 'duck', value: 10 },
      //        ];
      // cloudData1 and cloudData2 should be set equal to the appropriate cloud data objects
      // textBlock1Data and textBlock2Data should be set to the other obejct retuned by the service

      const response1 = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getFrequencies', {method: 'post', body: JSON.stringify(baseParams1)});
      const serverData1 = await response1.json();

      const response2 = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getFrequencies', {method: 'post', body: JSON.stringify(baseParams2)});
      const serverData2 = await response2.json();

      var data1 = [];
      var wordCount1 = 0;

      if (baseParams1.morphInflections != 'N' && baseParams1.morphInflections != 'T' && baseParams1.morphInflections != 'NT') {
          serverData1.map((entry) => {
              data1.push({
                text: entry.testField,
                value: entry.totalAppearances
              })
              wordCount1 += entry.totalAppearances;
          })
      }
      else {
          serverData1.map((entry) => {
              data1.push({
                text: this.padWordCloudText(entry.testField, baseParams1.morphInflections),
                value: entry.totalAppearances
              })
              wordCount1 += entry.totalAppearances;
          })
      }

      var cloudData1 = data1; //await this.getCloudData(baseParams1)


      var data2 = [];
      var wordCount2 = 0;

      if (baseParams2.morphInflections != 'N' && baseParams2.morphInflections != 'T' && baseParams2.morphInflections != 'NT') {
          serverData2.map((entry) => {
              data2.push({
                text: entry.testField,
                value: entry.totalAppearances
              })
              wordCount2 += entry.totalAppearances;
          })
      }
      else {
          serverData2.map((entry) => {
              data2.push({
                text: this.padWordCloudText(entry.testField, baseParams2.morphInflections),
                value: entry.totalAppearances
              })
              wordCount2 += entry.totalAppearances;
          })
      }

      var cloudData2 = data2; //await this.getCloudData(baseParams2)

      //this.setTextBlock(textBlock1Data, 1)
      //this.setTextBlock(textBlock2Data, 2)
    
      this.props.extUpdateCloudViewerData({
        isLoading: false,
        termTotal1Accurate: true,
        termTotal2Accurate: true,
        //textBlock1Data: textBlock1Data,
        //textBlock2Data: textBlock2Data,
        cloudData1: cloudData1,
        cloudData2: cloudData2,
      })

        this.props.extUpdateCloudViewerData({
          //textBlock1: retVal,
          //sourceList1: sourceList,
          totalTerms1: wordCount1,
        }, () => {
          // this.setSourceCells();
        })

        this.props.extUpdateCloudViewerData({
          //textBlock2: retVal,
          //sourceList2: sourceList,
          totalTerms2: wordCount2,
        }, () => {
          // this.setSourceCells();
        })

    })
  }

padWordCloudText(text, morphInflections) {
    var newText = '';
    for (let i = 0; i < text.length; i++)
        newText += (morphInflections == 'T' ? 'א' : 'ת') + text[i];
    return newText;
}

  //mje - looks like as of 6/22/2020 this doesn't get called anymore
  async getCloudData(baseParams){ //THIS WILL NEED TO BE RECONFIGURED AFTER NEW WEB SERVICE IS CREATED
    const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getTextBlock', {method: 'post', body: JSON.stringify(baseParams)});
    const body = await response.json();

    var text = "";

    body.map((pesukimCollection) => {
      pesukimCollection.map((pasukData => {
        var pasukText = pasukData.t1 ? pasukData.t1 : pasukData.text;
        var pasukWordsArr = pasukText.split(" ");
        pasukWordsArr.map((word) => {
          var cleanedWord = this.removeTaamim(this.removeNikud(word));
          if(this.props.cloudViewerData.wordsToSkip.indexOf(cleanedWord) === -1){
            var formattedWord = word;
            if(baseParams.morphInflections.indexOf("L") === -1){
              formattedWord = this.removeLetters(formattedWord)
            }
            if(baseParams.morphInflections.indexOf("N") === -1){
              formattedWord = this.removeNikud(formattedWord)
            }
            if(baseParams.morphInflections.indexOf("T") === -1){
              formattedWord = this.removeTaamim(formattedWord)
            }
            text = text.concat(" " + formattedWord);
          }
        })
      }))
    })

    var data = [];
    var textArr = text.split(" ");
    textArr.map((word) => {
      var wordIndex = data.map(e => e.text).indexOf(word);
      if(wordIndex === -1){
        data.push({
          text: word,
          value: 1
        })
      } else {
        data[wordIndex].value++
      }
    })

    return data;
  }

  removeNikud(str){
    for (let i = str.length-1; i >= 0; i--){
        if('ְֱֲֳִֵֶַָֹֺֻּֽ־ׇֿׁׂׅׄ'.includes(str[i])){
            str = str.slice(0,i).concat(str.slice(i+1,str.length))
        }
    }
    return str;
  }
  
  removeTaamim(str){
    for (let i = str.length-1; i >= 0; i--){
        if('ֽ֑֖֛֢֣֤֥֦֧֪֚֭֮֒֓֔֕֗֘֙֜֝֞֟֠֡֨֩֫֬֯׀'.includes(str[i])){
            str = str.slice(0,i).concat(str.slice(i+1,str.length))
        }
    }
    return str;
  }

  removeLetters(str){ // replaces all letters with ת
    for (let i = str.length-1; i >= 0; i--){
        if("קראטוןםפשדגכעיחלךףזסבהנמצתץ".includes(str[i])){
            str = str.slice(0,i).concat("ת").concat(str.slice(i+1,str.length))
        }
    }
    return str;
  }

    onCellContextMenu(e){
      this.setState({useKinuim: false, includeMekorot: false})
      this.styleContextMenu(e);
      this.setTextToCopy(e);
    }

    setTextToCopy(e){
      var textToCopy = '';
      var makor = '';
      var text = '';

      //bubble up until td
      var parentCell = e.target;
      while(parentCell.nodeName !== "TD"){
        parentCell = parentCell.parentNode
      }
      //find column
      var columnIndex = parentCell.cellIndex;
      //move to appropriate source cell
      var sourceCell = parentCell;
      if([0, 3].includes(columnIndex)){
        //move left
        sourceCell = parentCell.nextSibling;
      } else if ([5, 2].includes(columnIndex)) {
        //move right
        sourceCell = parentCell.previousSibling;
      }
      //get source
      makor = sourceCell.children[0].innerHTML;

      if(window.getSelection().toString()){
        textToCopy = 'highlighted';
        text = window.getSelection().toString();
      } else {
        textToCopy = 'pasuk';
        var textCell = parentCell;
        if([0, 3].includes(columnIndex)){
          //move 2 left
          textCell = parentCell.nextSibling.nextSibling;
        } else if ([4, 1].includes(columnIndex)) {
          //move 1 left
          textCell = parentCell.nextSibling;
        }
        if(textCell){
          text = textCell.textContent;
        }
      }

      this.setState({
        textToCopy,
        contextMenuMakor: makor,
        contextMenuText: text,
        contextMenuIsAPasuk: text.length > 0,
      })
    }

    styleContextMenu(e){
      const { innerWidth } = window;
      var submenus = document.getElementsByClassName('react-contexify__submenu');
      for(let i = 0; i < submenus.length; i++){
        var submenu = submenus[i];
        var submenuWidth = parseInt(submenu.style.minWidth.slice(0, submenu.style.minWidth.length-2));
        if(this.props.activeLanguage.code === 'he'){
          if(e.clientX > submenuWidth){
            submenu.style.left = '';
            submenu.style.right = '100%';
          } else {
            submenu.style.left = '100%';
            submenu.style.right = '';
          }
        } else if(this.props.activeLanguage.code === 'en'){
          if(innerWidth-e.clientX-300 > submenuWidth){
            submenu.style.left = '100%';
            submenu.style.right = '';
          } else {
            submenu.style.left = '';
            submenu.style.right = '100%';
          }
        }
      }
    }

    toggleUseKinuim(){
        this.setState({ useKinuim: !this.state.useKinuim })
    }
    toggleIncludeMekorot(){
      this.setState({ includeMekorot: !this.state.includeMekorot })
  }

    setTextBlock(textBlockData, sideNum){
      var retVal = "";
      var sourceList = [];
      for(let i = 0; i < textBlockData.length; i++) { // loop through sections
        var currSection = textBlockData[i];
        for(let j = 0; j < currSection.length; j++) { // loop through psukim in section
          var currPasuk = currSection[j];
          var pasukText = typeof(currPasuk.kriaName) === 'string' ? currPasuk.t1 : currPasuk.text;
          var location = currPasuk.location;
          sourceList.push(location)
          var locationArray = location.split(":");
          var seferName = locationArray[0];
          retVal += pasukText + " \n";
        }
      }

      var justWords = retVal;
      while(justWords.indexOf("\n") !== -1){
        justWords = justWords.replace("\n", "")
      }
      while(justWords.indexOf("  ") !== -1){
        justWords = justWords.replace("  ", " ")
      }

      var wordCount = justWords.split(" ").length - 1;
      if(sideNum === 1) {
        this.props.extUpdateCloudViewerData({
          textBlock1: retVal,
          sourceList1: sourceList,
          totalTerms1: wordCount,
        }, () => {
          // this.setSourceCells();
        })
      } else {
        this.props.extUpdateCloudViewerData({
          textBlock2: retVal,
          sourceList2: sourceList,
          totalTerms2: wordCount,
        }, () => {
          // this.setSourceCells();
        })
      }

      return retVal;
    }

    formatTextBlock(textBlock){
      var retVal = textBlock;
      if(this.props.diffData.removeLineBreaks){
        retVal = this.removeLineBreaks(retVal)
      }
      if(this.props.diffData.removeWhiteSpace){
        retVal = this.removeWhiteSpace(retVal)
      }
      if(this.props.diffData.removeTaameiMikra){
        retVal = this.removeTaamim(retVal)
      }
      if(this.props.diffData.removeNikud){
        retVal = this.removeNikud(retVal)
      }
      return retVal;
    }

    handleDoubleClick(e){
      var parentCell = e.target;
      while(parentCell.nodeName !== "TD" && parentCell.nodeName !== "TH" ){
        parentCell = parentCell.parentNode
      }
      if(parentCell.nodeName === "TD"){ // ignoring clicks on header cells
        var columnIndex = parentCell.cellIndex;
        var sourceCell = parentCell;
        if([0, 3].includes(columnIndex)){
          sourceCell = parentCell.nextSibling;
        } else if ([5, 2].includes(columnIndex)) {
          sourceCell = parentCell.previousSibling;
        }
        var location = sourceCell.children[0].innerHTML;
        if(location.length > 0){ //ignoring clicks on empty cells
          this.props.toggleTanachViewer(true, location, null);
          if (window.getSelection) { // un-highlight the text that was clicked on
              window.getSelection().removeAllRanges();
          } else if (document.selection) {
              document.selection.empty();
          }
        }
      }
    }

  getDisplayValue(value){
    if(value === 100){
      value = '∞'
    }
    return `${value}`
  }

  setMaxObjects(val){
      var dispVal = this.getDisplayValue(val);
      this.setState({maxObjects: val, maxObjectsDisplay: dispVal});
  }

  setMinFrequency(val){
      this.setState({minFrequency: val, minFrequencyDisplay: val});
  }
  saveText1Data(data){
    this.setState({vals1:data})//saving to state to refresh the page when the data changes
  }
  saveText2Data(data){
    this.setState({vals2:data})//saving to state to refresh the page when the data changes
  }

  render() {
    const { classes } = this.props;
    const {isLoading, textBlock1, textBlock2} = this.props.cloudViewerData;
    const {totalTerms1, totalTerms2, termTotal1Accurate, termTotal2Accurate} = this.props.wordCountData;
    const loadingText = this.props.translate("loading");
    const wordsToSkip = this.props.translate("wordsToSkip");
    const select = this.props.translate("select");
    const clearAll = this.props.translate("clearAll");
    const otherWords = this.props.translate("otherWords");
    const searchSettings = this.props.translate("searchSettings");
    const maxObjects = this.props.translate("maxObjects");
    const minFrequency = this.props.translate("minFrequency");
    const refreshDisabled = this.props.text1Data.length<2 || this.props.text2Data.length<2 || 
                (this.props.text1Data[0].type && this.props.text1Data[0].val4===0) || 
                (this.props.text2Data[0].type && this.props.text2Data[0].val4===0) || 
                ((!this.props.text1Data[0].type) && (this.props.text1Data[0].val3===0 || this.props.text1Data[0].val6===0)) || 
                ((!this.props.text2Data[0].type) && (this.props.text2Data[0].val3===0 || this.props.text2Data[0].val6===0))

    const fakeThis = this;
    var isHebrew = localStorage.getItem('language') === 'he';

    var viewerTableHeightNum = parseInt(this.props.viewerHeight.substring(0, this.props.viewerHeight.length - 2)) - 159;
    if(this.props.openResults.length > 0){
        viewerTableHeightNum -= 12;
    }
    const viewerTableHeight = `${viewerTableHeightNum}px`

    var directionStyling = {direction: 'ltr', textAlign: 'left'};
    var langDirection = 'ltr'
    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
        directionStyling = {direction: 'rtl', textAlign: 'right'};
        langDirection = 'rtl';
    }

    var totalTerms1Paper = <Paper elevation={1} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0'}}>
           <Typography variant='body1'>{this.props.translate("totalTerms")}: <b>{totalTerms1}</b></Typography>
       </Paper>
    if(!termTotal1Accurate){
        totalTerms1Paper = <Paper elevation={0} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0', backgroundColor: '#d7d7d7' }}>
           <Typography variant='body1' style={{color: "#9f9f9f"}} >{this.props.translate("totalTerms")}: <b>{totalTerms2}</b></Typography>
       </Paper>
    }

    var totalTerms2Paper = <Paper elevation={1} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0'}}>
           <Typography variant='body1'>{this.props.translate("totalTerms")}: <b>{totalTerms2}</b></Typography>
       </Paper>
    if(!termTotal2Accurate){
        totalTerms2Paper = <Paper elevation={0} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0', backgroundColor: '#d7d7d7' }}>
           <Typography variant='body1' style={{color: "#9f9f9f"}} >{this.props.translate("totalTerms")}: <b>{totalTerms2}</b></Typography>
       </Paper>
    }

    var searchSettingsLabelCellStyle = {
      textAlign: directionStyling.textAlign
    }
    var searchSettingsInputCellStyle = {
      textAlign: isHebrew ? "left" : "right"
    }

    const wordsToSkipVal = this.props.cloudViewerData.wordsToSkip;
    var noWordsToSkip = wordsToSkipVal.length === 0 || (wordsToSkipVal.length === 1 && wordsToSkipVal[0] === "");

    var wordsToSkipMenuItems = [
      { id: 'אל', value: 'אל' },
      { id: 'אשר', value: 'אשר' },
      { id: 'את', value: 'את' },
      { id: 'גם', value: 'גם' },
      { id: 'כי', value: 'כי' },
      { id: 'כל', value: 'כל' },
      { id: 'לא', value: 'לא' },
      { id: 'על', value: 'על' },
    ];

    return (<span helpTopic="diff">
          <div className={classes.toggleContainer} style={{direction: langDirection, paddingTop: "0px"}}>
            <Paper elevation={0} style={{padding: '3px', display: 'block'}}>

              <Button variant="default" style={{ verticalAlign: '-8px'}} className="LCS-open-settings-button" onClick={(e) => {this.handleOpenSearchSettings(e)}}>
                <SettingsIcon/>&nbsp;{searchSettings}
              </Button>&nbsp;

              <Popover
                open={this.state.searchSettingsIsOpen}
                anchorEl={this.state.searchSettingsAnchorEl}
                onClose={this.handleCloseSearchSettings}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: `${isHebrew ? "right" : "left"}`,
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: `${isHebrew ? "right" : "left"}`,
                }}
                elevation={1}
                className="placement-selector-2D-popover"
                style={{
                  direction: `${isHebrew ? 'rtl' : 'ltr'}`,
                }}
                classes={{ paper: "LCS-search-settings-popover-paper" }}
              >

                <table className="LCS-search-setting-parent-table">
                  {/* <tr>
                    <td style={searchSettingsLabelCellStyle}>
                      <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{matchDistance}:</Typography>
                    </td>
                    <td style={searchSettingsInputCellStyle}>
                      <Typography variant='body1' className='LCS-distance-label'>{this.getDisplayVals(matchDistanceVals)[0]}</Typography>
                      <Range reverse={isHebrew} className='LCS-distance-slider' min={1} step={1} included={false} tipFormatter={value => this.getDisplayValue(value)} value={matchDistanceVals} allowCross={false} onChange={this.setMatchDistance}
                        style={{
                          marginLeft: isHebrew ? '-4px' : '16px',
                          marginRight: isHebrew ? '12px' : '-10px',
                        }}
                      />
                      <Typography variant='body1' className='LCS-distance-label'>{this.getDisplayVals(matchDistanceVals)[1]}</Typography>
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td style={searchSettingsLabelCellStyle}>
                      <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{allowedWordLength}:</Typography>
                    </td>
                    <td style={searchSettingsInputCellStyle}>
                      <Typography variant='body1' className='LCS-distance-label'>{this.getDisplayVals(wordLengthVals)[0]}</Typography>
                      <Range reverse={isHebrew} className='LCS-word-length-slider' max={11} min={1} step={1} included={false} tipFormatter={value => this.getDisplayValue(value)} value={wordLengthVals} allowCross={false} onChange={this.setWordLength}
                        style={{
                          marginLeft: isHebrew ? '-4px' : '16px',
                          marginRight: isHebrew ? '12px' : '-10px',
                        }}
                      />
                      <Typography variant='body1' className='LCS-distance-label'>{this.getDisplayVals(wordLengthVals)[1]}</Typography>
                    </td>
                  </tr> */}
                  <tr>
                    <td style={searchSettingsLabelCellStyle}>
                      <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{maxObjects}:</Typography>
                    </td>
                    <td style={searchSettingsInputCellStyle}>
                      <Typography variant='body1' className='LCS-distance-label'>{1}</Typography>
                      <Slider reverse={isHebrew} className='LCS-distance-slider' min={1} max={100} step={1} included={false} tipFormatter={value => this.getDisplayValue(value)} value={this.state.maxObjects} onChange={this.setMaxObjects}
                        style={{
                          marginLeft: isHebrew ? '-4px' : '16px',
                          marginRight: isHebrew ? '12px' : '-10px',
                        }}
                      />
                      <Typography variant='body1' className='LCS-distance-label'>{this.state.maxObjectsDisplay}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td style={searchSettingsLabelCellStyle}>
                      <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{minFrequency}:</Typography>
                    </td>
                    <td style={searchSettingsInputCellStyle}>
                      <Typography variant='body1' className='LCS-distance-label'>{this.state.minFrequencyDisplay}</Typography>
                      <Slider reverse={isHebrew} className='LCS-distance-slider' min={1} max={100} step={1} included={false} tipFormatter={value => this.getDisplayValue(value)} value={this.state.minFrequency} onChange={this.setMinFrequency}
                        style={{
                          marginLeft: isHebrew ? '-4px' : '16px',
                          marginRight: isHebrew ? '12px' : '-10px',
                        }}
                      />
                      <Typography variant='body1' className='LCS-distance-label'>{100}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td style={searchSettingsLabelCellStyle}>
                      <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{wordsToSkip}:</Typography>
                    </td>
                    <td style={searchSettingsInputCellStyle}>
                      
                      <span style={{direction: directionStyling.direction}}>
                        <Button className="placement-selector-2D-button" onClick={(e) => {this.handleOpenWordsToSkip(e)}}>
                          <Typography
                            variant="h6"
                            className="placement-selector-2D-label"
                            style={{
                              color: `${this.props.disabled ? "#8a999e" : "black"}`,
                              textAlign: directionStyling.textAlign,
                              fontWeight: `${false ? "bold" : "400"}`
                            }}
                          >
                            {noWordsToSkip ? select : wordsToSkipVal.join(" ")}
                          </Typography >
                          <ArrowDropDownIcon className="placement-selector-2D-icon" style={{color: '#757575'}}/>
                        </Button>

                          <Popover
                            open={this.state.wordsToSkipIsOpen}
                            anchorEl={this.state.wordsToSkipAnchorEl}
                            onClose={this.handleCloseWordsToSkip}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: `${isHebrew ? "right" : "left"}`,
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: `${isHebrew ? "right" : "left"}`,
                            }}
                            elevation={1}
                            className="placement-selector-2D-popover"
                            style={{
                              direction: `${isHebrew ? 'rtl' : 'ltr'}`
                            }}
                            classes={{ paper: "LCS-search-settings-popover-paper" }}
                          >
                            <MenuItem className="LCS-select-menu-item" value="clearAll" onClick={(e) => {fakeThis.handleSelectWordToSkip(e)}}
                              style={{
                                direction: `${isHebrew ? 'rtl' : 'ltr'}`
                              }}
                            >
                              {clearAll}
                            </MenuItem>
                            <div className="placement-selector-2D-columns-parent-rtl">
                                {wordsToSkipMenuItems.map((menuItem, key) => {
                                  var menuItemsArr = [];
                                  for(let i = 0; i < 1; i++){
                                    if(wordsToSkipMenuItems[key+i]){
                                      menuItemsArr.push(
                                        <MenuItem className="placement-selector-2D-menuItem-rtl" value={wordsToSkipMenuItems[key+i].id} onClick={(e) => {fakeThis.handleSelectWordToSkip(e)}}
                                          style={{
                                            direction: `${this.props.isTextComparator && localStorage.getItem('language') === "en" ? "ltr" : "rtl" }`,
                                            backgroundColor: `${wordsToSkipVal.includes(wordsToSkipMenuItems[key+i].id) ? "lightgrey" : "" }`,
                                          }}
                                        >{wordsToSkipMenuItems[key+i].value}</MenuItem>
                                      )
                                    }
                                  }
                                  if(key%1 === 0){
                                    return <div>
                                      {menuItemsArr}
                                    </div>;
                                  }
                                  
                                })}
                            </div>
                            <Typography variant='body1' className='LCS-other-words-label'
                              style={{
                                direction: `${isHebrew ? 'rtl' : 'ltr'}`,
                                textAlign: `${isHebrew ? 'right' : 'left'}`
                              }}
                            >
                              {otherWords}:
                            </Typography>
                            <TextField
                              id="outlined-size-small"
                              variant="outlined"
                              size="small"
                              value={this.props.cloudViewerData.otherWordsToSkip.join(" ")}
                              onChange={(e) => {this.setOtherWordsToSkip(e)}}
                              InputProps={{ classes: { input: 'LCS-words-to-skip-textfield' } }}
                            />
                          </Popover>
                        </span>
                    </td>
                  </tr>
                </table>
              </Popover>

              <Button variant="contained" disabled={refreshDisabled} color="primary" className="parsha-viewer-control-button" type="submit" style={{margin: '0 5px'}} onClick={this.refresh}><Translate id="refresh" /></Button>
            </Paper>
          </div>
          <TextComparatorSectionSelector 
            isLoadingResults={isLoading}
            totalTerms1={totalTerms1}
            termTotal1Accurate={termTotal1Accurate}
            totalTerms2={totalTerms2}
            termTotal2Accurate={termTotal2Accurate}
            text1Data={this.props.text1Data}
            text2Data={this.props.text2Data}
            text1GridReady={this.text1GridReady}
            addText1Row={this.addText1Row}
            extAddText1Row={this.extAddText1Row}
            deleteText1Row={this.deleteText1Row}
            extDeleteText1Row={this.extDeleteText1Row}
            extModifyText1Row={this.extModifyText1Row}
            modifyText1Row={this.modifyText1Row}
            text2GridReady={this.text2GridReady}
            addText2Row={this.addText2Row}
            extAddText2Row={this.extAddText2Row}
            deleteText2Row={this.deleteText2Row}
            extDeleteText2Row={this.extDeleteText2Row}
            extModifyText2Row={this.extModifyText2Row}
            modifyText2Row={this.modifyText2Row}
            getRenderVals={this.getRenderVals}
            isFilingSelector={this.props.isFillingSelector}
            saveText1Data={this.saveText1Data}
            saveText2Data={this.saveText2Data}
          />

        <div
          style={{
            height: viewerTableHeight
          }}
          className="diff-viewer-loading-parent"
        >
          <LoadingOverlay
            active={isLoading}
            spinner
            text={loadingText}
            style={{
              height: '100%'
            }}
          >
            <MenuProvider className="context-menu" id='diff-viewer-context-menu'
              style={{
                maxHeight: viewerTableHeight,
                width: '1520px',
                maxWidth: 'calc(100vw - 400px)',
                minWidth: '968px',
              }}
            >
              <div
                style={{
                  textAlign: 'left',
                  maxHeight: viewerTableHeight,
                  overflowY: `hidden`,
                  overflowX: 'hidden',
                  '&*': {
                    marginBottom: '10px'
                  } 
                }}
                onContextMenu={(e) => {this.onCellContextMenu(e)}}
                onDoubleClick={(e) => {this.handleDoubleClick(e)}}
              >
                {/* {(!isIndependentScroll || (!isSplitView && (textBlock1.length > 0 || textBlock2.length > 0))) &&
                  <ReactDiffViewer
                    oldValue={this.formatTextBlock(textBlock1)}
                    newValue={this.formatTextBlock(textBlock2)}
                    splitView={isSplitView}
                    compareMethod={compareMethod}
                    showDiffOnly={false}
                    className="diff"
                  />
                } */}
                {/* {(isIndependentScroll && isSplitView) && <> */}
                  <table className="independent-scroll-diff-parent-table">
                    <tbody>
                      <tr style={{width: "100%"}}>
                        <td valign="top" style={{width: "50%"}}>
                        <div
                          style={{
                            textAlign: 'left',
                            maxHeight: viewerTableHeight,
                            overflowY: `hidden`,
                            overflowX: 'hidden',
                            direction: "ltr",
                            // '&*': {
                            //   marginBottom: '10px'
                            // } 
                          }}
                          className="object-cloud-parent" 
                        >
                          <ObjectCloud
                            text={textBlock1}
                            data={this.props.cloudViewerData.cloudData1}
                            height={viewerTableHeightNum}
                            width={450}
                          />
                        </div>
                        </td>
                      <td valign="top" style={{width: "50%"}}>
                        <div
                          style={{
                            textAlign: 'left',
                            maxHeight: viewerTableHeight,
                            overflowY: `hidden`,
                            overflowX: 'hidden',
                            direction: "ltr",
                            // '&*': {
                            //   marginBottom: '10px'
                            // } 
                          }}
                          className="object-cloud-parent" 
                        >
                          <ObjectCloud
                            text={textBlock2}
                            data={this.props.cloudViewerData.cloudData2}
                            height={viewerTableHeightNum}
                            width={450}
                          />
                        </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                {/* </>} */}

              </div>
          </MenuProvider>
          <ContextMenu menuId='diff-viewer-context-menu' menuType='textComparatorObjectCloud' className='special-context-menu' resultsApi={this.resultsApi}
                          textToCopy={fakeThis.state.textToCopy} extCopySelectedToSearchbar={fakeThis.props.extCopySelectedToSearchbar}
                          extSearchWithSelected={fakeThis.props.extSearchWithSelected} extToggleUseKinuim={fakeThis.toggleUseKinuim}
                          useKinuim={fakeThis.state.useKinuim} extToggleIncludeMekorot={fakeThis.toggleIncludeMekorot} includeMekorot={fakeThis.state.includeMekorot}
                          extToggleSearchAsQuote={fakeThis.toggleSearchAsQuote} searchAsQuote={fakeThis.state.searchAsQuote}
                          extOpenTaamParser={fakeThis.openTaamParser} currentFreq={this.state.currentFreq} 
                          makor={this.state.contextMenuMakor} text={this.state.contextMenuText} isAPasuk={this.state.contextMenuIsAPasuk}
                          toggleTanachViewer={((boolean, pasukId, resultsApi) => {fakeThis.props.toggleTanachViewer(boolean, pasukId, resultsApi)})}
                          extSetTextSource={this.props.extSetTextSource} extOpenLexiconViewer={this.props.extOpenLexiconViewer}/>
          </LoadingOverlay>
        </div>

        {/* <Dialog
          open={this.state.taamParserOpen}
          onClose={this.closeTaamParser}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
            <TaamParser data={this.state.taamParseData} open={this.state.taamParserOpen} closeTaamParser={this.closeTaamParser}
                    focusedCellRowLocation={null} extOpenTaamParser={this.openTaamParser}
                    pasukId={this.state.taamParserPasukId} pasukLocation={this.state.taamParserLocation}
                    resultsApi={this.frequencyApi} />
        </Dialog> */}
        </span>
    );
  }
}

TextComparatorCloudViewer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withLocalize(TextComparatorCloudViewer));
