import React, { Component } from 'react';
import styles from './css/App.css'; //for customizing reactStrap styles
import * as properties from './config/properties.js';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withLocalize, Translate } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import translations from "./json/translations.json";
import {withRouter} from 'react-router-dom';
import './css/AboutDialog.css';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';

function content_en() {
  return <span>
         <i>We at Taryag Analytics offer the following paid services:</i><br /><br />
         <ul>
         <li><b>Custom single search</b>: If we determine that the search can be done via the web site, we will advise how to perform the search.  If not, then we can do the search against our internal database for a fee.</li>
            <li><b>Custom research</b>: If the need amounts to a project as opposed to a single search (eg. create a set of reports), then we can offer to do the research against our internal database for a fee.</li>
            <li>
              <b>Expedited feature request</b>: We have a list of possible features that would take us years to develop and add to the system. A user might have a particular timely idea or need that would fit into the goals of Base HaSefer.
              If such a request is made, we would charge a fee for implementing the feature by an agreed-upon date.
            </li>
          </ul>
          <i>You may contact us at <b>info@taryaganalytics.com</b> with requests for custom services.</i></span>;
}

function content_he() {
  return <span>
  <i>
  אנו בחברת תרי"ג אנליטיקס מציעים את השירותים הבאים בתשלום:
  </i><br /><br />
    <ul>
        <li><b>חיפוש יחיד מותאם</b>: אם נוודא כי ניתן לבצע את החיפוש באמצעות האתר, אנו נייעץ כיצד לבצע את החיפוש. אם לא, נוכל לבצע חיפוש נגד בסיס הנתונים הפנימי שלנו תמורת תשלום.</li>
        <li><b>מחקר בהתאמה אישית</b>: אם הצורך מסתכם בפרויקט בניגוד לחיפוש בודד (למשל ליצור מערך דוחות), נוכל להציע לבצע את המחקר מול בסיס הנתונים הפנימי שלנו תמורת תשלום.</li>
        <li>
          <b>בקשת תכונה מזורזת</b>: יש לנו רשימה של תכונות אפשריות שייקח לנו שנים לפתח ולהוסיף למערכת. למשתמש עשוי להיות רעיון או צורך בזמן מסוים המתאים למטרות בייס-הספר.
          אם תתקבל בקשה כזו, אנו נחייב תשלום עבור יישום התכונה בתאריך מוסכם.
        </li>
    </ul>
  <i>תוכלו ליצור איתנו קשר ב <b>info@taryaganalytics.com</b> עם בקשות לשירותים מותאמים אישית.</i></span>;
}

class PremiumServicesDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
    };

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true }
    });

    this.props.addTranslation(translations);

  }

  onClose(){
    this.props.onClose();
  }

  handleCopyLink() {
    this.props.handleCopyLink("premiumServices");
  }
  
  render() {
    const { classes } = this.props;
    const premiumServicesTR = this.props.translate("premiumServices");
    const close = this.props.translate('close');
    const copyLink = this.props.translate('copyLink');

    const isHebrew = this.props.activeLanguage && this.props.activeLanguage.code === "he";

    var directionStyling = {direction: 'ltr', textAlign: 'left'};


    var contentHTML = content_en();

    if(isHebrew){
        directionStyling = {direction: 'rtl', textAlign: 'right'};
        contentHTML = content_he();
    }
    return       <Dialog
      open={this.props.isOpen}
      onClose={() => {this.onClose()}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={directionStyling}
      helpTopic="topBar"
    >
        <div className="help-dialog-resize-buttons"
          style={{
            position: 'absolute',
            right: isHebrew ? 'unset' : 0,
            left: isHebrew ? 0 : 'unset',
            direction: isHebrew ? 'ltr' : 'rtl',
            top: 0,
            borderBottomLeftRadius: isHebrew ? 0 : '3px',
            borderBottomRightRadius: isHebrew ? '3px' : 0,
          }}
        >
          <Button size="small" onClick={() => {this.onClose()}} color="primary" className="help-dialog-resize-button" title={close}>
            <CloseIcon />
          </Button>
          <Button className="help-dialog-resize-button" onClick={() => {this.handleCopyLink()}} title={copyLink}>
            <LinkIcon />
          </Button>
        </div>
      <DialogTitle id="alert-dialog-title">{premiumServicesTR}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {contentHTML}
        </DialogContentText>
      </DialogContent>
    </Dialog>

  }
}
PremiumServicesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(withLocalize(PremiumServicesDialog)));