import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { Link, withRouter } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import { Button as BootstrapButton } from 'reactstrap';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import LoadingOverlay from 'react-loading-overlay';
import { Paper, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, List, ListItem, ListItemText, FormControl, Select, MenuItem, FormHelperText, InputLabel, FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PlacementRenderer from './PlacementRenderer';
import {Spinner} from 'primereact/spinner';
import Bookmarks from '@material-ui/icons/Bookmarks';
import * as properties from './config/properties.js';
import {Checkbox} from 'primereact/checkbox';
import { Menu, Item, Separator, Submenu, MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import ContextMenu from './ContextMenu';
import PlacementSelector2D from './PlacementSelector2D';
import TextComparatorSectionSelector from './TextComparatorSectionSelector';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const styles = theme => ({
  toggleContainer: {
    height: 28,
    backgroundColor: 'transparent',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    alignItems: 'center',
    margin: `auto`,
    background: theme.palette.background.default,
  },
});

//todo this is repeated elsewhere - combine it
function encodeFilterIds(nodes, nodeString) {
    var nodeStr = (nodeString != undefined) ? nodeString : "0".repeat(44);
    nodes.forEach((node)=>{
        if (node.tableName == "Sefer" && node.isChecked)
            nodeStr = util.stuff(nodeStr, "1", node.id-1, 1);
        if(node.children){
            nodeStr = encodeFilterIds(node.children, nodeStr);
        }
    })
    return nodeStr;
}

function getAlternateRowStyle(params) {
    if (params.node.rowIndex % 2 === 0 && !isOutlier(params)) {
        return {background: '#F0F0F0'};
    } else if (params.node.rowIndex % 2 === 0 && isOutlier(params)){
        return {background: '#c4cfff'};
    } else if (params.node.rowIndex % 2 === 1 && isOutlier(params)){
        return {background: '#d9e0ff'};
    }
}

function isOutlier(params) {
    if(!params.data.termInstances1 || !params.data.termInstances2){
        return true;
    }
    return false;
}

function namedSection(params) {
  return '<Spinner name="ns" id="ns" value={1} min={1} max={10} />';
}

function pasukRange(params) {
  return <Spinner name="pr" id="pr"  value={1} min={1} max={10} />;
}

function BaseParameters() {
  this.source = null;
  this.clientIP = null;
  this.morphInflections = null;
  this.filterIds = null;
  this.nGrams = null;
  this.sortResultsBy = null;
  this.resultsToDisplay = null;
  this.namedLocations =  []; //NamedLocation()
  this.namedLocations2 =  []; //NamedLocation()
  this.pasukRangeLocations = []; //PerekPasukRange()
  this.pasukRangeLocations2 = []; //PerekPasukRange()
  this.textSource = 1;
}

function NamedLocation(kriaId, aliyaId) {
  this.kriaId =  kriaId; //int
  this.aliyaId = aliyaId; //int
}

function PerekPasukRange(seferId1, perekNum1, pasukNum1, seferId2, perekNum2, pasukNum2) {
  this.seferId1 =  seferId1; //int
  this.perekNum1 = perekNum1;  //int
  this.pasukNum1 =  pasukNum1; //int
  this.seferId2 = seferId2;  //int
  this.perekNum2 =  perekNum2; //int
  this.pasukNum2 = pasukNum2;  //int
}

class TextComparatorViewer extends Component {

//webServiceURL = properties.env.webServiceURL;

 constructor(props) {
    super(props);
    this.state = {
      clientIP: '',
      columnDefs: this.getGridColumns(),
      defaultLanguage: '',
      text1DialogOpen: false,
      text2DialogOpen: false,
      textToCopy: 'bitui',
      useKinuim: false,
      searchAsQuote: false,
      includeMekorot: false,
      searchedMorphInflections: this.props.morphInflections,
      // isLoading: false, // moved these up to TextComparatorViewer
      // rowData: [],
      // totalTerms1: 0,
      // totalTerms2: 0,
      // termTotal1Accurate: true,
      // termTotal2Accurate: true,
      // nGrams: 1,
      // sortResultsByChosen: 1,
      // sortResultsByCurrent: 1,
      // resultsToDisplay: 1,
    };

    this.refresh = this.refresh.bind(this);
    this.text1GridReady = this.text1GridReady.bind(this);
    this.addText1Row = this.addText1Row.bind(this);
    this.extAddText1Row = this.extAddText1Row.bind(this);
    this.deleteText1Row = this.deleteText1Row.bind(this);
    this.extDeleteText1Row = this.extDeleteText1Row.bind(this);
    this.extModifyText1Row = this.extModifyText1Row.bind(this);
    this.modifyText1Row = this.modifyText1Row.bind(this);
    this.text2GridReady = this.text2GridReady.bind(this);
    this.addText2Row = this.addText2Row.bind(this);
    this.extAddText2Row = this.extAddText2Row.bind(this);
    this.deleteText2Row = this.deleteText2Row.bind(this);
    this.extDeleteText2Row = this.extDeleteText2Row.bind(this);
    this.extModifyText2Row = this.extModifyText2Row.bind(this);
    this.modifyText2Row = this.modifyText2Row.bind(this);
    this.getRenderVals = this.getRenderVals.bind(this);
    this.handleText1DialogOpen = this.handleText1DialogOpen.bind(this);
    this.handleText1DialogClose = this.handleText1DialogClose.bind(this);
    this.handleText2DialogOpen = this.handleText2DialogOpen.bind(this);
    this.handleText2DialogClose = this.handleText2DialogClose.bind(this);
    this.handleResultsToDisplayChange = this.handleResultsToDisplayChange.bind(this);
    this.handleNGramsChange = this.handleNGramsChange.bind(this);
    this.handleSortResultsByChosenChange = this.handleSortResultsByChosenChange.bind(this);
    this.getGridColumns = this.getGridColumns.bind(this);
    this.getGridColumnClass = this.getGridColumnClass.bind(this);
    this.isCurrentSortColumn = this.isCurrentSortColumn.bind(this);
    this.onCellContextMenu = this.onCellContextMenu.bind(this);
    this.toggleUseKinuim = this.toggleUseKinuim.bind(this);
    this.toggleSearchAsQuote = this.toggleSearchAsQuote.bind(this);
    this.saveText1Data = this.saveText1Data.bind(this);
    this.saveText2Data = this.saveText2Data.bind(this);


    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}
  }

  toggleSearchAsQuote(){
    this.setState({ searchAsQuote: !this.state.searchAsQuote })
  }

    isCurrentSortColumn(columnFieldName){
      const sortResultsByCurrent = this.props.commonWordsData.sortResultsByCurrent
        if(this.state){
            if(sortResultsByCurrent === 1 && ['termInstances1', 'termInstances2', 'percentBlock1', 'percentBlock2'].includes(columnFieldName)){
                return true;
            } else if (sortResultsByCurrent === 2 && ['termInstancesTanach1', 'termInstancesTanach2', 'percentBlock1VsTanach', 'percentBlock2VsTanach'].includes(columnFieldName)) {
                return true;
            } else if (sortResultsByCurrent === 3 && ['termInstancesSeferGroup1', 'termInstancesSeferGroup2', 'percentBlock1VsSeferGroup', 'percentBlock2VsSeferGroup'].includes(columnFieldName)) {
                return true;
            }
        }
        return false;
    }

    getGridColumnClass(columnFieldName, params) {
        var cellClass = 'row-thin-small';
        if(columnFieldName === 'term'){
            var cellClass = 'row-header-large';
        } else if (columnFieldName === 'rowNum'){
            var cellClass = 'row-header-center';
        }
        if(this.isCurrentSortColumn(columnFieldName)){
            cellClass = cellClass.concat(' sort-column');
        }

        if(params){
            if(!params.data.termInstances1 || !params.data.termInstances2){
                cellClass = cellClass.concat(' outlier-row')

            }
        }
        return cellClass;
    }

    getGridColumns(){
        var fakeThis = this
        return [
                   {headerName: "% במסומנים", field: "percentBlock1VsSefer", width: 130, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock1VsSeferGroup", params)}} ,
                   {headerName: "בספרים המסומנים", field: "termInstancesSefer1", width: 121, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstancesSeferGroup1", params)}} ,
                   {headerName: "% בתנ\"ך", field: "percentBlock1VsTanach", width: 105, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock1VsTanach", params)}} ,
                   {headerName: "בתנ\"ך", field: "termInstancesTanach1", width: 84, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstancesTanach1", params)}} ,
                   {headerName: "% בקטעים", field: "percentBlock1", width: 117, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock1", params)}} ,
                   {headerName: "הופעות בקטעים", field: "termInstances1", width: 97, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstances1", params)}} ,
                   {headerName: "#", field: "rowNum", width: 60, headerClass: "ag-header-cell-center", cellClass: function(params) {return fakeThis.getGridColumnClass("rowNum", params)}, filter: 'agNumberColumnFilter'},
                   {headerName: "בטוי", field: "term", width: 130, cellClass: function(params) {return fakeThis.getGridColumnClass("term", params)}, cellRenderer: function(params) {return util.decorateNekudosTaamim(params.data.term);}},
                   {headerName: "הופעות בקטעים", field: "termInstances2", width: 97, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstances2", params)}} ,
                   {headerName: "% בקטעים", field: "percentBlock2", width: 117, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock2", params)}} ,
                   {headerName: "בתנ\"ך", field: "termInstancesTanach2", width: 84, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstancesTanach2", params)}} ,
                   {headerName: "% בתנ\"ך", field: "percentBlock2VsTanach", width: 105, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock2VsTanach", params)}} ,
                   {headerName: "בספרים המסומנים", field: "termInstancesSefer2", width: 121, cellClass: function(params) {return fakeThis.getGridColumnClass("termInstancesSeferGroup2", params)}} ,
                   {headerName: "% במסומנים", field: "percentBlock2VsSefer", width: 130, cellClass: function(params) {return fakeThis.getGridColumnClass("percentBlock2VsSeferGroup", params)}} ,
               ];
    }

    resultsGridReady = params => {
      this.resultsApi = params.api;
      this.resultsColumnApi = params.columnApi;
    };

    text1GridReady = params => {
        this.props.text1GridReady(params)
        //     this.props.text1Api.setRowData(this.props.text1Data);

        //     this.props.text1Api.setColumnDefs(
        //         [{headerName: this.props.translate("addLines"), headerClass: 'row-thin-center', field: "namedSection", width: 1168, cellClass: "row-thin-small", cellRendererFramework: PlacementRenderer, cellRendererParams:
        //             {
        //              extModifyPlacementRow: this.extModifyText1Row,
        //              extAddPlacementRow: this.extAddText1Row,
        //              extDeletePlacementRow: this.extDeleteText1Row,
        //              translate: this.props.translate, //do I need to pass this here?
        //          },
        //    //cellClass: function(params) {return params.data.type ? 'placementGridLine' : 'rangeGridLine';},
        //          }]
        //    );
        //    if (this.props.text1Data.length > 0)
        //    this.props.text1Api.setFocusedCell(0, "namedSection", null);




//       this.text1Api = params.api;
//       this.text1ColumnApi = params.columnApi;

//       this.text1Api.setRowData(this.props.text1Data);

// //following CSS classes seem to get overridden below in PlacementRenderer by placementGridLine and rangeGridLine
//       this.text1Api.setColumnDefs(
//                [{headerName: this.props.translate("addLines"), headerClass: 'row-thin-center', field: "namedSection", width: 1168, cellClass: "row-thin-small", cellRendererFramework: PlacementRenderer, cellRendererParams:
//                    {
//                     extModifyPlacementRow: this.extModifyText1Row,
//                     extAddPlacementRow: this.extAddText1Row,
//                     extDeletePlacementRow: this.extDeleteText1Row,
//                     translate: this.props.translate, //do I need to pass this here?
//                 },
//           //cellClass: function(params) {return params.data.type ? 'placementGridLine' : 'rangeGridLine';},
//                 }]
//           );
//       if (this.props.text1Data.length > 0)
//           this.text1Api.setFocusedCell(0, "namedSection", null);
//       // var instances = this.refs.agGrid.gridOptions.api.getCellRendererInstances(params);
    };

    text2GridReady = params => {
        this.props.text2GridReady(params)
//       this.text2Api = params.api;
//       this.text2ColumnApi = params.columnApi;
//       this.text2Api.setRowData(this.props.text2Data);

// //following CSS classes seem to get overridden below in PlacementRenderer by placementGridLine and rangeGridLine
//       this.text2Api.setColumnDefs(
//                [{headerName: this.props.translate("addLines"), headerClass: 'row-thin-center', field: "namedSection", width: 1168, cellClass: "row-thin-small", cellRendererFramework: PlacementRenderer, cellRendererParams:
//                    {
//                     extModifyPlacementRow: this.extModifyText2Row,
//                     extAddPlacementRow: this.extAddText2Row,
//                     extDeletePlacementRow: this.extDeleteText2Row,
//                     translate: this.props.translate, //do I need to pass this here?
//                 },
//           //cellClass: function(params) {return params.data.type ? 'placementGridLine' : 'rangeGridLine';},
//                 }]
//           );
//       if (this.props.text2Data.length > 0)
//           this.text2Api.setFocusedCell(0, "namedSection", null);
      // var instances = this.refs.agGrid.gridOptions.api.getCellRendererInstances(params);
    };

    addText1Row() {
        this.props.addText1Row()
        // var newRow = {type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []};
        // var newData = this.props.text1Data;
        // newData.push(newRow);
        // this.setState( {text1Data: newData}, () => {this.text1Api.updateRowData({ add: [newRow] })});
    }

    addText2Row() {
        this.props.addText2Row()
        // var newRow = {type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []};
        // var newData = this.props.text2Data;
        // newData.push(newRow);
        // this.setState( {text2Data: newData}, () => {this.text2Api.updateRowData({ add: [newRow] })});
    }

    extAddText1Row = () => {
        this.props.addText1Row();
    }

    extAddText2Row = () => {
        this.props.addText2Row();
    }

    deleteText1Row(extState) {
        this.props.deleteText1Row(extState);



        // this.props.extUpdateCommonWordsData({termTotal1Accurate: false})
        // var selected = this.text1Api.getFocusedCell();
        // var i = selected.rowIndex;
        // if (i == this.text1Api.getLastDisplayedRow()) { //then just clear
        //     this.modifyText1Row(extState);
        //     //selected.setSelected(false);
        // }
        // else {
        //     var selectedData = this.text1Api.getSelectedRows();
        //     var newData = this.props.text1Data;
        //     newData.splice(i,1);
        //     this.setState( {text1Api: newData}, () => {this.text1Api.updateRowData({ remove: selectedData }) });
        // }
    }

    deleteText2Row(extState) {
        this.props.deleteText2Row(extState)
        // this.props.extUpdateCommonWordsData({termTotal2Accurate: false})
        // var selected = this.text2Api.getFocusedCell();
        // var i = selected.rowIndex;
        // if (i == this.text2Api.getLastDisplayedRow()) { //then just clear
        //     this.modifyText2Row(extState);
        //     //selected.setSelected(false);
        // }
        // else {
        //     var selectedData = this.text2Api.getSelectedRows();
        //     var newData = this.props.text2Data;
        //     newData.splice(i,1);
        //     this.setState( {text2Api: newData}, () => {this.text2Api.updateRowData({ remove: selectedData }) });
        // }
    }

    extDeleteText1Row = (extState) => {
        this.props.deleteText1Row(extState);
    }

    extDeleteText2Row = (extState) => {
        this.props.deleteText2Row(extState);
    }

    extModifyText1Row = (extState) => {
        this.props.modifyText1Row(extState);
    }

    extModifyText2Row = (extState) => {
        this.props.modifyText2Row(extState);
    }

    modifyText1Row(extState) {
        this.props.modifyText1Row(extState)

        // this.props.extUpdateCommonWordsData({termTotal1Accurate: false})

        // var isAddRow = (extState.type && extState.val4 != this.props.text1Data.val4 && extState.val4 != 0) || (!extState.type && extState.val3 != this.props.text1Data.val3 && extState.val3 != 0 && extState.val6 != this.state.text1Data.val6 && extState.val6 != 0);
        // var selected = this.props.text1Api.getFocusedCell();

        // var i = selected.rowIndex;
        // var rowValues = {type: extState.type, val1: extState.val1, val2: extState.val2, val3: extState.val3, val4: extState.val4, val5: extState.val5, val6: extState.val6,
        //                                       valList1: extState.valList1, valList2: extState.valList2, valList3: extState.valList3, valList4: extState.valList4, valList5: extState.valList5, valList6: extState.valList6 };
        // var newData = this.props.text1Data;
        // newData[i] = rowValues;

        // this.setState( {text1Data: newData}, () => {
        //     if (i == this.props.text1Api.getLastDisplayedRow() && isAddRow) {
        //         this.props.addText1Row();
        //     }
        // });

    }

    modifyText2Row(extState) {
        this.props.modifyText2Row(extState)

        // this.props.extUpdateCommonWordsData({termTotal2Accurate: false})

        // var isAddRow = (extState.type && extState.val4 != this.props.text2Data.val4 && extState.val4 != 0) || (!extState.type && extState.val3 != this.props.text2Data.val3 && extState.val3 != 0 && extState.val6 != this.state.text2Data.val6 && extState.val6 != 0);
        // var selected = this.text2Api.getFocusedCell();

        // var i = selected.rowIndex;
        // var rowValues = {type: extState.type, val1: extState.val1, val2: extState.val2, val3: extState.val3, val4: extState.val4, val5: extState.val5, val6: extState.val6,
        //                                       valList1: extState.valList1, valList2: extState.valList2, valList3: extState.valList3, valList4: extState.valList4, valList5: extState.valList5, valList6: extState.valList6 };
        // var newData = this.props.text2Data;
        // newData[i] = rowValues;

        // this.setState( {text2Data: newData}, () => {
        //     if (i == this.text2Api.getLastDisplayedRow() && isAddRow) {
        //         this.addText2Row();
        //     }
        // });
    }

    handleText1DialogOpen() {
        this.setState({ text1DialogOpen: true })
    }

    handleText1DialogClose() {
        this.setState({ text1DialogOpen: false })
    }

    handleText2DialogOpen() {
        this.setState({ text2DialogOpen: true })
    }

    handleText2DialogClose() {
        this.setState({ text2DialogOpen: false })
    }

   getRenderVals(textDataRow){
        var renderVal1, renderVal2, renderVal3, renderVal4, renderVal5, renderVal6
        var renderVals
        if(textDataRow.type){ //is named section
            var selectedVal1 = textDataRow.valList1.filter(obj => {return obj.id === textDataRow.val1});
            var selectedVal2 = textDataRow.valList2.filter(obj => {return obj.id === textDataRow.val2});
            var selectedVal3 = textDataRow.valList3.filter(obj => {return obj.id === textDataRow.val3});
            var selectedVal4 = textDataRow.valList4.filter(obj => {return obj.id === textDataRow.val4});
            var selectedVal5 = textDataRow.valList5.filter(obj => {return obj.id === textDataRow.val5});

            if(selectedVal1[0]){ renderVal1 = selectedVal1[0].value+' > '}
            if(selectedVal2[0]){ renderVal2 = selectedVal2[0].value+' > '}
            if(selectedVal3[0]){ renderVal3 = selectedVal3[0].value+' > '}
            if(selectedVal4[0]){ renderVal4 = selectedVal4[0].value+' > '}
            if(selectedVal5[0]){ renderVal5 = selectedVal5[0].value+' > '}

            var renderVals = [renderVal1, renderVal2, renderVal3, renderVal4, renderVal5]

            for(let i = 4; i >= 0; i--){
                if(renderVals[i]){
                    renderVals[i] = renderVals[i].substring(0, renderVals[i].length - 3)
                    break
                }
                //if it is defined, set the one after it as the last value
            }

        } else { //is pasuk range
            var selectedVal1 = textDataRow.valList1.filter(obj => {return obj.seferId === textDataRow.val1});
            var selectedVal2 = textDataRow.valList2.filter(obj => {return obj.perekNum === textDataRow.val2});
            var selectedVal3 = textDataRow.valList3.filter(obj => {return obj.pasukNum === textDataRow.val3});
            var selectedVal4 = textDataRow.valList4.filter(obj => {return obj.seferId === textDataRow.val4});
            var selectedVal5 = textDataRow.valList5.filter(obj => {return obj.perekNum === textDataRow.val5});
            var selectedVal6 = textDataRow.valList6.filter(obj => {return obj.pasukNum === textDataRow.val6});

            if(selectedVal1[0]){ renderVal1 = selectedVal1[0].seferName}
            if(selectedVal2[0]){ renderVal2 = selectedVal2[0].perekValue}
            if(selectedVal3[0]){ renderVal3 = selectedVal3[0].pasukValue}
            if(selectedVal4[0]){ renderVal4 = selectedVal4[0].seferName}
            if(selectedVal5[0]){ renderVal5 = selectedVal5[0].perekValue}
            if(selectedVal6[0]){ renderVal6 = selectedVal6[0].pasukValue}

            renderVals = [renderVal1, renderVal2, renderVal3, renderVal4, renderVal5, renderVal6]
        }

        return renderVals
   }

   handleResultsToDisplayChange(e){
        this.props.extUpdateCommonWordsData({resultsToDisplay: e.target.value})
   }

    handleNGramsChange(e){
        this.props.extUpdateCommonWordsData({nGrams: e.target.value})
    }

    handleSortResultsByChosenChange(e){
        this.props.extUpdateCommonWordsData({sortResultsByChosen: e.target.value})
    }

  async componentDidMount() {
      this.setLanguage(localStorage.getItem("language"));
  }

  async refresh() {
    this.props.extUpdateCommonWordsData({ isLoading: true }, async () => {
      var baseParams = new BaseParameters();
      baseParams.nGrams = this.props.commonWordsData.nGrams;
      baseParams.sortResultsBy = this.props.commonWordsData.sortResultsByChosen;
      baseParams.resultsToDisplay = this.props.commonWordsData.resultsToDisplay;
      baseParams.source = 'bhsWebApp';
  
      var filterIds = encodeFilterIds(this.props.extFilterData);
      filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
      filterIds = util.right("00000000000"+filterIds,11);
  
      baseParams.filterIds = filterIds;
      baseParams.morphInflections = this.props.extSearchDefData; //str
      baseParams.textSource = this.props.textSource==='targum'?2:1;

      var e, i;
      for (i = 0; i < this.props.text1Data.length-1; i++) { //last position in placementRowData is never populated
          e = this.props.text1Data[i];
          if (e.type)
              baseParams.namedLocations.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
          else
              baseParams.pasukRangeLocations.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
      }
  
      for (i = 0; i < this.props.text2Data.length-1; i++) { //last position in placementRowData is never populated
          e = this.props.text2Data[i];
          if (e.type)
              baseParams.namedLocations2.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
          else
              baseParams.pasukRangeLocations2.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
      }
  
      const colDefs = this.getGridColumns();
  
      const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getTextComparison', {method: 'post', body: JSON.stringify(baseParams)});
      const body = await response.json();
  
  
      var totalTerms1 = this.state.totalTerms1;
      var totalTerms2 = this.state.totalTerms2;
      if(body[0]){
          totalTerms1 = body[0].totalBlock1;
          totalTerms2 = body[0].totalBlock2;
      }
  
      var rowData = body;
      //reformat
      for(let i = 0; i < rowData.length; i++){
          rowData[i].termInstances1 = (!!rowData[i].termInstances1) ? rowData[i].termInstances1 : 0;
          rowData[i].termInstances2 = (!!rowData[i].termInstances2) ? rowData[i].termInstances2 : 0;
  
          rowData[i].percentBlock1 = this.formatPercentages(rowData[i].percentBlock1);
          rowData[i].percentBlock2 = this.formatPercentages(rowData[i].percentBlock2);
          rowData[i].percentBlock1VsTanach = this.formatPercentages(rowData[i].percentBlock1VsTanach);
          rowData[i].percentBlock2VsTanach = this.formatPercentages(rowData[i].percentBlock2VsTanach);
          rowData[i].percentBlock1VsSefer = this.formatPercentages(rowData[i].percentBlock1VsSefer);
          rowData[i].percentBlock2VsSefer = this.formatPercentages(rowData[i].percentBlock2VsSefer);
      }
  
      this.props.extUpdateCommonWordsData({rowData: []}, () => {
          this.props.extUpdateCommonWordsData({
            sortResultsByCurrent: baseParams.sortResultsBy,
            rowData: rowData,
            isLoading: false,
            totalTerms1: totalTerms1,
            totalTerms2: totalTerms2,
            termTotal1Accurate: true,
            termTotal2Accurate: true
          });
          this.setState({ searchedMorphInflections: this.props.morphInflections })
      })
    })
  }

  formatPercentages(rawPercent){
        var roundedPercent = `${Math.round(100*rawPercent)/100}`


        if(roundedPercent.indexOf('.') === -1 || !roundedPercent){
            roundedPercent = `${roundedPercent || 0}.00%`
        } else if(roundedPercent.indexOf('.') === roundedPercent.length-2){
            roundedPercent = roundedPercent.concat('0%')
        } else {
            roundedPercent = roundedPercent.concat('%')
        }

        return roundedPercent
//        var decimalCount = 0;
//        for (let i = roundedPercent.length-1; i >= 0; i--){
//            if(roundedPercent[i] !== '.'){
//                decimalCount ++
//            } else if (roundedPercent[i] === '.'){
//                var zeroesToAdd = 2-decimalCount;
//                for(let j = 1; j <= zeroesToAdd; j++ ){
//                    roundedPercent = roundedPercent.concat('0');
//                }
//                roundedPercent = roundedPercent.concat('%');
//                return roundedPercent;
//            }
//        }
        //if no decimal
//        return `${roundedPercent || 0}.00%`
  }

onCellContextMenu(e){
        this.setState({useKinuim: false, includeMekorot: false})
        const { innerWidth } = window;
        //style context menu
        var submenus = document.getElementsByClassName('react-contexify__submenu');
        for(let i = 0; i < submenus.length; i++){
            var submenu = submenus[i];
            var submenuWidth = parseInt(submenu.style.minWidth.slice(0, submenu.style.minWidth.length-2));
            if(this.props.activeLanguage.code === 'he'){
                if(e.event.clientX > submenuWidth){
                    submenu.style.left = '';
                    submenu.style.right = '100%';
                } else {
                    submenu.style.left = '100%';
                    submenu.style.right = '';
                }
            } else if(this.props.activeLanguage.code === 'en'){
                if(innerWidth-e.event.clientX-300 > submenuWidth){
                    submenu.style.left = '100%';
                    submenu.style.right = '';
                } else {
                    submenu.style.left = '';
                    submenu.style.right = '100%';
                }
            }
        }


        //set text to copy
        var textToCopy = '';

        var focusedCellRowIndex = null ;
        if(this.resultsApi.getFocusedCell()){
            var focusedCellRowIndex = this.resultsApi.getFocusedCell().rowIndex;
        }
        var focusedCellRowText = null;
        if((typeof(focusedCellRowIndex) === 'number') && this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex]){
            focusedCellRowText = this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].data.text;
        }

        //focused = row of focused cell; highlighted = highlighted text; selected = all selected rows;
        if(this.resultsApi.getFocusedCell() && !window.getSelection().toString()){
            textToCopy = 'bitui';
        } else if(window.getSelection().toString()){
            textToCopy = 'highlighted';
        }
        var word = e.event.target.innerText.split(" ")
        word = word[1] && word[1].length>0? null : word.join("")
        if(!util.isHebrew(word)) word=null
        this.setState({textToCopy,wordToCopy:word})
    }

    toggleUseKinuim(){
        this.setState({ useKinuim: !this.state.useKinuim })
    }
    saveText1Data(data){
      this.setState({vals1:data})//saving to state to refresh the page when the data changes
    }
    saveText2Data(data){
      this.setState({vals2:data})//saving to state to refresh the page when the data changes
    }

  render() {
    const { classes } = this.props;
    const text1Data = this.props.text1Data;
    const text2Data = this.props.text2Data;

    const isLoading = this.props.commonWordsData.isLoading;
    const {totalTerms1, totalTerms2, termTotal1Accurate, termTotal2Accurate} = this.props.wordCountData;

    const loadingText = this.props.translate("loading");
    const nGrams = this.props.translate("nGrams");
    const fakeThis = this;

    var viewerTableHeightNum = parseInt(this.props.viewerHeight.substring(0, this.props.viewerHeight.length - 2)) - 159;
    if(this.props.openResults.length > 0){
        viewerTableHeightNum -= 12;
    }
    const viewerTableHeight = `${viewerTableHeightNum}px`

    const refreshDisabled = this.props.text1Data.length<2 || this.props.text2Data.length<2 || 
                (this.props.text1Data[0].type && this.props.text1Data[0].val4===0) || 
                (this.props.text2Data[0].type && this.props.text2Data[0].val4===0) || 
                ((!this.props.text1Data[0].type) && (this.props.text1Data[0].val3===0 || this.props.text1Data[0].val6===0)) || 
                ((!this.props.text2Data[0].type) && (this.props.text2Data[0].val3===0 || this.props.text2Data[0].val6===0))
    var directionStyling = {};
    var langDirection = 'ltr'
    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
        directionStyling = {direction: 'rtl', textAlign: 'center'};
        langDirection = 'rtl';
    }

    var totalTerms1Paper = <Paper elevation={1} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0'}}>
           <Typography variant='body1'>{this.props.translate("totalTerms")}: <b>{totalTerms1}</b></Typography>
       </Paper>
    if(!termTotal1Accurate){
        totalTerms1Paper = <Paper elevation={0} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0', backgroundColor: '#d7d7d7' }}>
           <Typography variant='body1' style={{color: "#9f9f9f"}} >{this.props.translate("totalTerms")}: <b>{totalTerms1}</b></Typography>
       </Paper>
    }

    var totalTerms2Paper = <Paper elevation={1} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0'}}>
           <Typography variant='body1'>{this.props.translate("totalTerms")}: <b>{totalTerms2}</b></Typography>
       </Paper>
    if(!termTotal2Accurate){
        totalTerms2Paper = <Paper elevation={0} style={{height: '37px', marginTop: '7px', direction: langDirection, padding: '8.5px 0', backgroundColor: '#d7d7d7' }}>
           <Typography variant='body1' style={{color: "#9f9f9f"}} >{this.props.translate("totalTerms")}: <b>{totalTerms2}</b></Typography>
       </Paper>
    }

//garbage comment

    return (<span helpTopic="commonTerms">
          <div className={classes.toggleContainer} style={{direction: langDirection, paddingTop: "0px"}}>
            <Paper elevation={0} style={{ padding: '3px', display: 'block'}}>
                      <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{this.props.translate("sortByFrequency")}:</Typography>

                      <FormControl className={classes.formControl} style={{margin: '0 5px', direction: langDirection, verticalAlign: 0}}>
                        <PlacementSelector2D onChange={this.handleSortResultsByChosenChange} value={this.props.commonWordsData.sortResultsByChosen}
                          activeLanguageCode={localStorage.getItem("language")} disabled={false} selectOrLoading={null} isTextComparator={true}
                          menuItems={[
                            { id: 1, value: this.props.translate("selectedSections") },
                            { id: 2, value: this.props.translate("allTanach") },
                            { id: 3, value: this.props.translate("selectedSefarim") },
                          ]} 
                        />
                      </FormControl>
                      <div width='100px' style={{display:'inline', width: '500px'}}></div>
                      <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{nGrams}:</Typography>
                      <FormControl className={classes.formControl} style={{margin: '0 5px', verticalAlign: 0}}>
                        <PlacementSelector2D onChange={this.handleNGramsChange} value={this.props.commonWordsData.nGrams} type="number" isTextComparator={true}
                          activeLanguageCode={localStorage.getItem("language")} disabled={false} selectOrLoading={null}
                          menuItems={[
                            { id: 1, value: 1 },
                            { id: 2, value: 2 },
                            { id: 3, value: 3 },
                            { id: 4, value: 4 },
                            { id: 5, value: 5 },
                            { id: 6, value: 6 },
                            { id: 7, value: 7 },
                            { id: 8, value: 8 },
                            { id: 9, value: 9 },
                            { id: 10, value: 10 },
                          ]} 
                        />
                      </FormControl>
                      <Typography variant='body1' style={{display:'inline', fontWeight: 'bold'}}>{this.props.translate("resultsToDisplay")}:</Typography>
                      <FormControl className={classes.formControl} style={{margin: '0 5px', verticalAlign: 0}}>
                        <PlacementSelector2D onChange={this.handleResultsToDisplayChange} value={this.props.commonWordsData.resultsToDisplay}
                          activeLanguageCode={localStorage.getItem("language")} disabled={false} selectOrLoading={null} isTextComparator={true}
                          menuItems={[
                            { id: 1, value: this.props.translate("allResults") },
                            { id: 2, value: this.props.translate("onlyMatches") },
                            { id: 3, value: this.props.translate("onlyNonMatches") },
                          ]} 
                        />
                      </FormControl>
                    <Button disabled={refreshDisabled} variant="contained" color="primary" className="parsha-viewer-control-button" type="submit" style={{margin: '0 5px'}} onClick={this.refresh}><Translate id="refresh" /></Button>
                  </Paper>
          </div>

          <TextComparatorSectionSelector 
            isLoadingResults={isLoading}
            totalTerms1={totalTerms1}
            termTotal1Accurate={termTotal1Accurate}
            totalTerms2={totalTerms2}
            termTotal2Accurate={termTotal2Accurate}
            text1Data={this.props.text1Data}
            text2Data={this.props.text2Data}
            text1GridReady={this.text1GridReady}
            addText1Row={this.props.addText1Row}
            extAddText1Row={this.extAddText1Row}
            deleteText1Row={this.deleteText1Row}
            extDeleteText1Row={this.extDeleteText1Row}
            extModifyText1Row={this.extModifyText1Row}
            modifyText1Row={this.modifyText1Row}
            text2GridReady={this.text2GridReady}
            addText2Row={this.addText2Row}
            extAddText2Row={this.extAddText2Row}
            deleteText2Row={this.deleteText2Row}
            extDeleteText2Row={this.extDeleteText2Row}
            extModifyText2Row={this.extModifyText2Row}
            modifyText2Row={this.modifyText2Row}
            getRenderVals={this.getRenderVals}
            isFilingSelector={this.props.isFillingSelector}
            saveText1Data={this.saveText1Data}
            saveText2Data={this.saveText2Data}
          />

        <div style={{margin: 'auto', width: 'fit-content'}}>
          <LoadingOverlay
            active={isLoading}
            spinner
            text={loadingText}
          >
            <MenuProvider className="context-menu" id='text-comparator-viewer-context-menu'
                  style={{
                    height: viewerTableHeight,
                    width: '1520px',
                    maxWidth: 'calc(100vw - 400px)',
                    minWidth: '968px',
                  }}
            >
              <div
                className="ag-theme-balham"
                id="text-comparator-grid"
                style={{
                  height: viewerTableHeight,
                  width: '1520px',
                  maxWidth: 'calc(100vw - 400px)',
                  minWidth: '968px',
                }}

                  >
                  <AgGridReact
                      onGridReady={this.resultsGridReady}
                      defaultColDef={{resizable: true}}
                      columnDefs={this.state.columnDefs}
                      rowData={this.props.commonWordsData.rowData}
                      getRowStyle = {getAlternateRowStyle}
                      enableColResize={true}
                      enableSorting={true}
                      enableFilter={true}
                      onCellContextMenu={(e) => {fakeThis.onCellContextMenu(e)}}
                      enableRtl="true"
                      headerHeight={40}>
                  </AgGridReact>
              </div>
          </MenuProvider>
          <ContextMenu menuId='text-comparator-viewer-context-menu' menuType='textComparator' className='special-context-menu' resultsApi={this.resultsApi}
                          textToCopy={fakeThis.state.textToCopy} extCopySelectedToSearchbar={fakeThis.props.extCopySelectedToSearchbar}
                          extSearchWithSelected={fakeThis.props.extSearchWithSelected} extToggleUseKinuim={fakeThis.toggleUseKinuim}
                          useKinuim={fakeThis.state.useKinuim} extToggleIncludeMekorot={fakeThis.toggleIncludeMekorot} includeMekorot={fakeThis.state.includeMekorot}
                          extToggleSearchAsQuote={fakeThis.toggleSearchAsQuote} searchAsQuote={fakeThis.state.searchAsQuote}
                          extOpenTaamParser={fakeThis.openTaamParser} currentFreq={this.state.currentFreq} hasRowData={fakeThis.props.commonWordsData.rowData.length > 0} 
                          morphInflections={this.state.searchedMorphInflections} extSetTextSource={this.props.extSetTextSource}
                          extOpenLexiconViewer={this.props.extOpenLexiconViewer}wordToCopy={this.state.wordToCopy}/>
          </LoadingOverlay>
        </div>
        </span>
    );
  }
}

TextComparatorViewer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withLocalize(TextComparatorViewer));


/*
      <div className='sweet-loading'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={isLoading}
        />


      </div>
*/