import React, { Component } from 'react';

class HistoryBirth extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>

        <p>
          הרעיון התחיל בתחילת 2013 בשבת בבוקר כשהלכתי לבית
          הכנסת עם בני. הוא שאל אותי שאלה היפותטית עם מספר תנאים ואני עניתי 
          שאם היה לנו בסיס נתונים יחסי של התנ"ך, נוכל בקלות לשאול במאגר מידע לקבלת תשובה.
        </p>
        <p>התרגשתי והתחלתי לתכנן בסיס נתונים כזה. בשלביו הראשונים צברתי השראה מהקריאה כי 
          פרויקט שו"ת בר אילן מקורו בדיון נוסף בשבת מוקדם בהרבה במיניאפוליס, מינסוטה, 
          בראשית 1963, שם נשאל המייסד אביעזרי פרנקל, "מה מחשב יכול לעשות למדעי היהדות?"</p>
        <p>לאחר שהעיצוב הבסיסי שלי הושלם, התחלתי לאכלס אותו בכמה טבלאות 
          בדיקה. עדיין לא היה לי שום מושג אמיתי כיצד אייבא את 
          טקסט התנ"ך למאגר ואיך הייתי מגדיר מידע בסיסי חשוב כמו שורשי מילים.</p>

      </>
    } else if (langCode === "en") {
      retVal = <>
 
        <p>The concept started in early 2013 on a Shabbos morning walk to shul with my son.  He asked me a hypothetical 
          question with a number of conditions and I answered that if we had a relational database of the Tanach, 
          we could easily query the database for an answer.</p>
        <p>I got excited and started designing such a database. In its early stages, I gained added inspiration from 
          reading about how the Bar Ilan Responsa project had its origins in another much earlier Shabbos discussion 
          in Minneapolis, MN in early 1963 where founder Aviezri Fraenkel was asked, "What can a computer do for 
          Jewish studies?"</p>
        <p>Once my basic design was complete, I started populating it with some lookup tables.  I still had no real 
          idea as to how I would import the text of the Tanach into the database and how I would define important 
          underlying information such as word roots.</p>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default HistoryBirth;